import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getCookie } from '../../Helper/CookieHelper';
import Service from "../../Networking/networkutils";
import { errorMessage } from '../../Helper/ErrorMessage';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class SecondaryRoles extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
            dayCust: {},
            roleAll: [],
        }
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        this.setState({ isLoading: true })
        Service.get('/bb/api/user/workhours/?uid=' + this.props.selectedUser.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                // console.log(res.data.lenght === 0)

                // if (res.data.length === 0) {
                //     // console.log("Initiating default hours")
                //     this.autoInputValue()
                // } else {
                //     this.setState({ isLoading: false, data: res.data })
                // }
                this.setState({ isLoading: false, data: res.data })

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    autoInputValue() {
        var input = []
        var eachHour = 0
        if (this.props.selectedUser.office_loc === 0) {
            eachHour = 7.5
        } else if (this.props.selectedUser.office_loc === 1 || this.props.selectedUser.office_loc === 2) {
            eachHour = 8
        }
        for (let i = 0; i < 5; i++) {
            input.push({
                created_by: user.id,
                uid: this.props.selectedUser.id,
                day: i + 1,
                hours: eachHour
            })

        }

        Service.post('/bb/api/user/workhours/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: input
        })
            .then(res => {
                this.fetchOps();
                this.props.enqueueSnackbar('Default hours added!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                this.fetchOps();
                errorMessage(this.props, error)
            });
    }

    dayExist(postData) {
        var goon = true;
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            console.log(element.day, postData.day)
            if ((!element.id || element.id !== postData.id) && element.uid === this.props.selectedUser.id && element.day === parseInt(postData.day)) {
                goon = false;
                this.props.enqueueSnackbar('Data with same day already exist!', {
                    variant: 'warning'
                });
                break;
            }
        }
        return goon
    }

    postData(postData) {

        var goon = true;
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if ((!element.id || element.id !== postData.id) && element.uid === this.props.selectedUser.id && element.day === parseInt(postData.day)) {
                goon = false;
                break;
            }
        }

        if (goon) {
            postData.created_by = user.id;
            postData.uid = this.props.selectedUser.id
            Service.post('/bb/api/user/workhours/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData
            })
                .then(res => {
                    this.fetchOps();
                    this.props.enqueueSnackbar('Data saved successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    this.fetchOps();
                    errorMessage(this.props, error)
                });
        } else {
            this.props.enqueueSnackbar('Data with same day already exist!', {
                variant: 'warning'
            });
        }

    }

    putData(putData) {
        var goon = true;
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if (element.id !== putData.id && element.uid === this.props.selectedUser.id && element.day === parseInt(putData.day)) {
                goon = false;
                break;
            }
        }

        if (goon) {
            Service.put('/bb/api/user/workhours/' + putData.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: putData
            })
                .then(res => {
                    this.props.enqueueSnackbar('Data saved successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    this.fetchOps();
                    errorMessage(this.props, error)
                });
        } else {
            this.props.enqueueSnackbar('Data with same day already exist!', {
                variant: 'warning'
            });
        }

    }

    deleteData(putData) {
        Service.delete('/bb/api/user/workhours/' + putData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.props.enqueueSnackbar('Data deleted successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                this.fetchOps();
                errorMessage(this.props, error)
            });
    }


    render() {
        return (
            <div>
                <MaterialTable
                    title={this.props.selectedUser.last_name + ", " + this.props.selectedUser.first_name}
                    columns={[

                        {
                            title: 'Day', field: 'day',
                            lookup: { 0: 'Sunday', 1: 'Monday', 2: 'Tuesday', 3: 'Wednesday', 4: 'Thursday', 5: 'Friday', 6: 'Saturday' },
                        },
                        {
                            title: 'Hours', field: 'hours',
                        },

                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        padding: "dense",
                    }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (this.dayExist(newData)) {
                                        this.setState({ save: false });
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            newData.roles = [];
                                            data.push(newData);
                                            this.postData(newData);
                                            return { ...prevState, data };
                                        });
                                    }

                                }, 60);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData && this.dayExist(newData)) {
                                        this.setState({ save: false });
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            this.putData(newData);
                                            return { ...prevState, data };
                                        });
                                    }
                                }, 60);

                            }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState({ save: false });
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        this.deleteData(oldData)
                                        return { ...prevState, data };
                                    });
                                }, 60);
                            }),
                    }}

                />
            </div>
        )
    }
}

export default withSnackbar(SecondaryRoles)