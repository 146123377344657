import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import RateByYear from './RateByYear';
import Service from "../../Networking/networkutils";
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor, onlyUniqueArr } from '../../Helper/CommonThings';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { comparer } from '../../Helper/CommonThings';
import { withSnackbar } from 'notistack';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import { Autocomplete } from '@material-ui/lab';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
    },
});

class AllRateTabs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            value: 0,
            years: [],
            years_text: [],
            data: [],
            open: false,
            openChangeDefault: false,
            openApprovalStatus: false,
            selectedYears: 0,
            availableYears: [],
            rateCardTemplate: [],
            defaultYear: 2020,
            selectedDefault: 2020,
            isLoading: true,
            selectApprovalStatus: this.props.value.approval_status,
            rateCardYears: [],
            selectedRateCard: null,
            rates: []
        }
    }

    componentDidMount() {
        this.fetchOps()
    }

    fetchRateCards() {
        Service.get(`/bb/api/tender/ratecard/template/?tender=${this.props.value.subproject_pid}`, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                let rateCardYears = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (!this.state.years.includes(parseInt(element.year))) {
                        rateCardYears.push(element)
                    }
                }
                this.setState({
                    rateCardYears: rateCardYears
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchOps() {
        Service.get('/bb/api/tender/rate/?pid=' + this.props.value.id + "&allyears=1", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var years = []
                var years_text = []

                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.default) {
                        years_text.push("Default: " + element.year)
                        years.push(element.year)
                        this.setState({
                            defaultYear: element.year,
                            selectedDefault: element.year,
                            value: i,
                        })
                    } else {
                        years_text.push(element.year)
                        years.push(element.year)
                    }
                }

                this.setState({
                    years: years,
                    years_text: years_text,
                    data: res.data,
                    isLoading: false
                }, () => {
                    this.fetchRateCards()
                })

                Service.get('/bb/api/tender/template/ratecard/', {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res1 => {
                        var all_years = []
                        for (let i = 0; i < res1.data.length; i++) {
                            const element = res1.data[i];
                            all_years.push(element.year)
                        }
                        // console.log(all_years)
                        all_years = all_years.filter(onlyUniqueArr)
                        // console.log(all_years, years)
                        all_years = all_years.filter(comparer(years))
                        // console.log(all_years)
                        this.setState({
                            availableYears: all_years,
                            rateCardTemplate: res1.data
                        })
                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });

            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    onRateCardChange = (event, values) => {
        this.setState({ selectedRateCard: values }, () => {
            if (values !== null) {
                this.fetchRates(this.state.selectedRateCard)
            }
        });
    }

    fetchRates(data) {
        Service.get(`/bb/api/tender/ratecard/template/detail/?tender_ratecard_template=${data.id}`, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                this.setState({
                    rates: res.data,
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    render() {

        const { classes } = this.props;

        const handleChange = (event, newValue) => {
            this.setState({
                value: newValue
            })
        };

        const handleClose = () => {
            this.setState({
                open: false
            })
        };

        const handleDefaultClose = () => {
            this.setState({
                openChangeDefault: false
            })
        };

        const handleApprovalStatusClose = () => {
            this.setState({
                selectApprovalStatus: this.props.value.approval_status,
                openApprovalStatus: false
            })
        };

        const handleSelectChange = (event) => {
            this.setState({
                selectedYears: event.target.value
            })

        };

        const handleDefaultChange = (event) => {
            this.setState({
                selectedDefault: event.target.value
            })

        };

        const handleApprovalStatus = (event) => {
            this.setState({
                selectApprovalStatus: event.target.value
            })

        };

        const handleSubmit = (event) => {

            if (this.state.selectedYears === 1) {
                if (!this.state.selectedRateCard) {
                    this.props.enqueueSnackbar('Please select the rate card!', {
                        variant: 'warning'
                    });
                } else {
                    this.setState({
                        open: false,
                        isLoading: true
                    })
                    let rates_data = []
                    for (let i = 0; i < this.state.rates.length; i++) {
                        const element = this.state.rates[i];
                        if (element) {
                            let data = {
                                id: element.id,
                                year: element.year,
                                desig: element.designation,
                                grade: element.grade,
                                costin: element.rate_in,
                                costuk: element.rate_uk,
                                remark: '',
                                created_by: user.email,
                                date_created: new Date()
                            }
                            rates_data.push(data)
                        }
                    }
                    Service.post("/bb/api/tender/rate/", {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: {
                            t_pid: this.props.value.id,
                            t_pname: this.props.value.name,
                            created_by: user.email,
                            t_rates: rates_data,
                            default: false,
                            year: this.state.selectedRateCard.year,
                            remarks: [
                                {
                                    date: new Date(),
                                    remark: 'default rates (initiated from Tender Rate Card)'
                                }
                            ]
                        },
                    })
                        .then(res => {
                            this.setState({
                                selectedYears: 0,
                                isLoading: false

                            });
                            this.fetchOps()
                        })

                        .catch(error => {
                            console.log(error)
                            errorMessage(this.props, error)
                        });
                }

            } else if (this.state.selectedYears > 0) {
                this.setState({
                    open: false,
                    isLoading: true
                })
                let rates_data = []
                for (let i = 0; i < this.state.rateCardTemplate.length; i++) {
                    const element = this.state.rateCardTemplate[i];
                    if (element.year === this.state.selectedYears) {
                        rates_data.push(element)
                    }

                }
                Service.post("/bb/api/tender/rate/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: {
                        t_pid: this.props.value.id,
                        t_pname: this.props.value.name,
                        created_by: user.email,
                        t_rates: rates_data,
                        default: false,
                        year: this.state.selectedYears,
                        remarks: [
                            {
                                date: new Date(),
                                remark: 'default rates (initiated for project)'
                            }
                        ]
                    },
                })
                    .then(res => {
                        this.setState({
                            selectedYears: 0,
                            isLoading: false

                        });
                        this.fetchOps()
                    })

                    .catch(error => {
                        console.log(error)
                        errorMessage(this.props, error)
                    });
            }
        };

        const handleDefaultSubmit = (event) => {
            this.setState({
                openChangeDefault: false,
                isLoading: true
            })

            if (this.state.selectedDefault !== this.state.defaultYear) {

                var post_data = []

                for (let i = 0; i < this.state.data.length; i++) {
                    const element = this.state.data[i];
                    if (element.year === this.state.defaultYear) {
                        element.default = false
                        post_data.push({
                            id: element.id,
                            default: element.default
                        })
                    }
                    if (element.year === this.state.selectedDefault) {
                        element.default = true
                        post_data.push({
                            id: element.id,
                            default: element.default
                        })
                    }
                }

                // console.log(post_data)

                Service.patch("/bb/api/tender/rate/bulk_update/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: post_data,
                })
                    .then(res => {
                        console.log(res.data)

                        this.setState({
                            defaultYear: this.state.selectedDefault,
                            isLoading: false

                        });
                        this.fetchOps()
                    })

                    .catch(error => {
                        console.log(error)
                        errorMessage(this.props, error)
                    });
            }

        };

        const handleApprovalStatusSubmit = (event) => {
            this.props.value.approval_status = this.state.selectApprovalStatus
            Service.patch("/bb/api/tender/project/" + this.props.value.id + "/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: this.props.value,
            })
                .then(res => {
                    this.setState({
                        openApprovalStatus: false
                    })
                    this.props.enqueueSnackbar("Status Changed!", {
                        variant: 'success'
                    });
                })

                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        };

        return (
            <div className={classes.root}>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <Box display="flex" flexDirection="row-reverse" p={1} m={1} >
                            <Button style={{ marginLeft: '10px' }} variant="outlined" color="primary" onClick={() => {
                                this.setState({
                                    open: true
                                })
                            }}>Add Rate Card from template</Button>
                            <Button style={{ marginLeft: '10px' }} variant="outlined" color="primary" onClick={() => {
                                this.setState({
                                    openChangeDefault: true
                                })
                            }}>Change default estimate rate card</Button>
                            {(this.props.value.type === 1) ? (
                                <Button variant="outlined" color="primary" onClick={() => {
                                    this.setState({
                                        openApprovalStatus: true
                                    })
                                }}>Change DS Status</Button>
                            ) : (null)}

                        </Box>
                        <AppBar position="static" color="default">
                            <Tabs value={this.state.value} onChange={handleChange} aria-label="simple tabs example"
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                {this.state.years.map((value, index) =>
                                    <Tab label={this.state.years_text[index]} {...a11yProps(index)} />
                                )}

                            </Tabs>
                        </AppBar>
                        {this.state.years.map((value, index) =>
                            <TabPanel value={this.state.value} index={index}>
                                <RateByYear rates={this.state.data[index]} />
                            </TabPanel>
                        )}

                        <Dialog open={this.state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Import Rate Card</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Select the year of rate card template to import
                                </DialogContentText>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-helper-label">Select Import Year</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={this.state.selectedYears}
                                        onChange={handleSelectChange}
                                    >
                                        <MenuItem value={0}>
                                            <em>None</em>
                                        </MenuItem>
                                        {this.state.availableYears.map((value, index) =>
                                            <MenuItem value={value}>{value}</MenuItem>
                                        )}
                                        <MenuItem value={1}>
                                            <em>Custom</em>
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText>select the year to import</FormHelperText>
                                </FormControl>

                                {this.state.selectedYears === 1 ?
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={this.state.rateCardYears}
                                        style={{ marginTop: '10px', minWidth: 500 }}
                                        onChange={this.onRateCardChange}
                                        getOptionLabel={(option) => option.name + ' - ' + option.year}
                                        renderInput={(params) => <TextField {...params} label="Select Rate Card" variant="outlined" />}
                                    /> : null}

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={handleSubmit} color="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.openChangeDefault} onClose={handleDefaultClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Change Default</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Select the year to set as default
                                </DialogContentText>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-helper-label">Select Default Rate Card</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={this.state.selectedDefault}
                                        onChange={handleDefaultChange}
                                    >

                                        {this.state.years.map((value, index) =>
                                            <MenuItem value={value}>{value}</MenuItem>
                                        )}

                                    </Select>
                                    <FormHelperText>select the year to set default</FormHelperText>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDefaultClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={handleDefaultSubmit} color="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.openApprovalStatus} onClose={handleApprovalStatusClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Change Status</DialogTitle>
                            <DialogContent>

                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-helper-label">Select Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={this.state.selectApprovalStatus}
                                        onChange={handleApprovalStatus}
                                    >

                                        <MenuItem value={0}>Not Approved</MenuItem>
                                        <MenuItem value={1}>Approved</MenuItem>

                                    </Select>
                                    <FormHelperText>change approval status</FormHelperText>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleApprovalStatusClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={handleApprovalStatusSubmit} color="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}


            </div>
        )
    }
}

export default withStyles(useStyles)(withSnackbar(AllRateTabs))