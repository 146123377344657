import MaterialTable from 'material-table'
import React, { Component } from 'react'
import Service from '../Networking/networkutils';
import { getCookie } from '../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import { errorMessage } from '../Helper/ErrorMessage';
import Autocomplete from '@material-ui/lab/Autocomplete';
import UserRequestForm from '../UserRequest/UserRequestForm';
import { BootstrapDialogTitleV4 } from '../Helper/DialogTitleM5';
import { getMode } from '../MainDashBoard/ColorTheme/ColorTheme';
import { Link } from '@mui/material';

const token = getCookie("bb_ts_token")

class UserReg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roles: [],
            roleInput: null,
            approveDialog: false,
            rejectDialog: false,
            name: '',
            rowData: {},
            isLoading: true,
            approveDisabled: false,
            rejectDisabled: false,
            editData: null,
            editDialog: false,
            companyLookup: {},
        }
        this.reload = this.reload.bind(this)
    }

    getData() {
        this.setState({ isLoading: true })
        Service.get(`/bb/api/user/registration/request/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data
                }, () => {
                    this.setState({
                        isLoading: false
                    })
                })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    getRole() {
        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ roles: res.data })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    approveFunc(rowData) {
        this.setState({
            approveDialog: true,
            name: rowData.first_name,
            rowData: rowData,
            editData: rowData
        })
    }

    rejectFunc(rowData) {
        this.setState({
            rejectDialog: true,
            rowData: rowData
        })
    }

    handleApprovalClose() {
        this.setState({
            approveDialog: false,
            roleInput: null
        })
    }

    handleRejectClose() {
        this.setState({
            rejectDialog: false
        })
    }

    approval() {
        if (this.state.roleInput === null) {
            this.props.enqueueSnackbar('Please Select Role', {
                variant: 'warning'
            });
        } else {
            this.setState({ approveDisabled: true })
            let data = {
                "req_id": this.state.rowData.id,
                "status": 1,
                "role_id": this.state.roleInput.id
            }
            // data.role_in_team = ;
            Service.post('/bb/api/user/registration/request/new_user_approve_api/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            })
                .then(res => {
                    this.setState({
                        approveDialog: false,
                        isLoading: true,
                        approveDisabled: false
                    }, () => {
                        this.getData()
                    })
                    this.props.enqueueSnackbar('User Approved Successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    if (error && error.status === 406) {
                        this.props.enqueueSnackbar(error.data.message, {
                            variant: 'warning'
                        });
                    } else {
                        errorMessage(this.props, error)
                    }
                    this.setState({ approveDisabled: false })
                });
        }
    }

    reject() {
        this.setState({ rejectDisabled: true })
        let data = {
            "req_id": this.state.rowData.id,
            "status": 2,
        }
        Service.post('/bb/api/user/registration/request/new_user_reject_api/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.setState({
                    rejectDialog: false,
                    isLoading: true,
                    rejectDisabled: false
                }, () => {
                    this.getData()
                })
                this.props.enqueueSnackbar('User Rejected Successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ rejectDisabled: false })
                errorMessage(this.props, error)
            });
    }
    reload() {
        this.getData()
        this.setState({ editDialog: false, editData: null })
    }

    componentDidMount() {
        this.getData()
        this.getRole()
        this.fetchCompany()
    }

    fetchCompany() {
        Service.get('/bb/api/company/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var companyLookup = {}
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    companyLookup[element.id] = element.name
                }
                this.setState({ companyLookup: companyLookup })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }


    render() {
        return (
            <div>
                <MaterialTable
                    title={'User Reg'}
                    isLoading={this.state.isLoading}
                    columns={[
                        {
                            title: 'First Name', field: 'first_name'
                        },
                        {
                            title: 'Last Name', field: 'last_name'
                        },
                        {
                            title: 'Email', field: 'email'
                        },
                        {
                            title: 'Designation', field: 'designation'
                        },
                        {
                            title: 'Office Location', field: 'office_loc', lookup: { 1: 'India', 0: 'UK', 2: 'SL' }
                        },
                        {
                            title: 'SBU', field: 'company', lookup: this.state.companyLookup
                        },
                        {
                            title: 'Join Date', field: 'join_date', type: 'date'
                        },
                        {
                            title: 'Requested By', render: rowData => rowData.requested_by_last_name + ", " + rowData.requested_by_first_name
                        },
                        {
                            title: 'Status', field: 'status',
                            lookup: { 0: 'Pending', 1: "Approved", 2: "Rejected" }
                        },
                        {
                            title: 'Actioned By', render: rowData => rowData.status > 0 ? rowData.approved_by_last_name + ", " + rowData.approved_by_first_name : null
                        }
                    ]}
                    options={{
                        paging: false,
                        actionsColumnIndex: -1
                    }}
                    data={this.state.data}
                    actions={[
                        rowData => ({
                            disabled: rowData.status !== 0,
                            icon: () => <EditIcon />,
                            tooltip: 'edit',
                            onClick: (event, rowData) => { this.setState({ editData: rowData }, () => { this.setState({ editDialog: true }) }) },
                        }),
                        rowData => ({
                            disabled: rowData.status !== 0,
                            icon: () => <DoneIcon />,
                            tooltip: 'Approve',
                            onClick: (event, rowData) => { this.approveFunc(rowData) },
                        }),
                        rowData => ({
                            disabled: rowData.status !== 0,
                            icon: () => <CloseIcon />,
                            tooltip: 'Reject',
                            onClick: (event, rowData) => { this.rejectFunc(rowData) },
                        }),
                    ]}
                />
                <Dialog open={this.state.approveDialog}>
                    <DialogTitle>
                        <Box display={'flex'} flexDirection={'row'} width={'100%'}>
                            <Box flexGrow={1}>
                                <Typography variant='h5'>Please Select {this.state.name}'s Role</Typography>
                            </Box>
                            <Box>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        window.open('https://faqs.bbparam.com/paramfaqs/paramforms')
                                    }}
                                    color={getMode() ? 'secondary' : 'primary'}
                                >
                                    FAQ's
                                </Link>
                            </Box>
                        </Box></DialogTitle>
                    <DialogContent>
                        <Box marginTop={'10px'} margin={2}>
                            <Autocomplete
                                id="combo-box-demo"
                                value={this.state.roleInput}
                                options={this.state.roles}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newInputValue) => {
                                    if (newInputValue === null) {
                                        this.setState({ roleInput: null })
                                    } else {
                                        this.setState({ roleInput: newInputValue })
                                    }
                                }}
                                style={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Choose User Role" variant="outlined"
                                    fullWidth />}
                            />
                            <Box>
                                <UserRequestForm approveDialog={this.state.editData} />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' onClick={() => this.handleApprovalClose()}>Close</Button>
                        <Button disabled={this.state.approveDisabled} color='primary' onClick={() => this.approval()}>Approve</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.rejectDialog}>
                    {/* <DialogTitle>Please Select {this.state.name}'s Role</DialogTitle> */}
                    <DialogTitle>
                        Are you sure, do you want to reject this request?
                    </DialogTitle>
                    <DialogActions>
                        <Button color='primary' onClick={() => this.handleRejectClose()}>Close</Button>
                        <Button disabled={this.state.rejectDisabled} color='primary' onClick={() => this.reject()}>Reject</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.editDialog}>
                    <BootstrapDialogTitleV4 onClose={() => this.setState({ editDialog: false, editData: null })}>
                        Edit Request
                    </BootstrapDialogTitleV4>
                    <DialogContent>
                        <UserRequestForm editData={this.state.editData} dialogClose={() => this.setState({ editDialog: false, editData: null })} reload={this.reload} />
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(UserReg)
