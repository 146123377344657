import React, { Component } from 'react'
import MultiProgress from "react-multi-progress";
import { getGreencolor, getOrangecolor, getProgBgColor } from '../../MainDashBoard/ColorTheme/ColorTheme';


class SubTenderProgressBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            total: 0,
            done: 0
        }
    }
    componentDidMount() {
        this.calcHours()
    }

    calcHours() {
        var done = (this.props.subtasks_done / this.props.subtasks_total) * 100
        var total = 100 - ((this.props.subtasks_done / this.props.subtasks_total) * 100)
        this.setState({ done, total })
    }
    render() {
        return (
            <MultiProgress
                transitionTime={1.2}
                elements={[
                    {
                        value: this.state.done,
                        color: getGreencolor(),
                    },
                    {
                        value: this.state.total,
                        color: getOrangecolor(),
                    },

                ]}
                height={10}
                backgroundColor={getProgBgColor()}
            />
        )
    }
}

export default SubTenderProgressBar