import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import { LoopCircleLoading } from 'react-loadingg';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { blue } from '@material-ui/core/colors';
import moment from "moment";
// import ManageWBS from '../../Manage/TenderManage/ManageWBS';
import { getCookie } from '../../Helper/CookieHelper';
import ApprovalWBS from './TenderManage/ApprovalWBS';
import ReactGA from 'react-ga';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

// var dialogmsg = "";
var submitdata = null;
class TenderProjects extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            rates: this.props.rates,
            data2: this.props.roles,
            open: false,
            isLoading: true,
            allData: 'null',
            openEdit: false,
            openCalc: false,
            key: 0,
            subproj: [],
            textDialogData : '',
            openText: false,
            dialogTitle: '',
        }
    }


    componentDidMount() {

        var pageView = window.location.pathname;
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        var dataD = [];
        for (let i = 0; i < this.props.projs.length; i++) {
            const element = this.props.projs[i];
            if (element.is_subproject && element.subproject_pid === this.props.value.id) {
                dataD.push(element)
            }
        }
        this.setState({
            data: dataD,
            isLoading: false,
        });
    }

    fetchOps() {
        Service.get(`/bb/api/tender/project/?audit_status=10`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var dataD = [];
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.is_subproject && element.subproject_pid === this.props.value.id) {
                        dataD.push(element)
                    }
                }
                this.setState({
                    data: dataD,
                    isLoading: false,
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        Service.get('/bb/api/tender/tasks/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({ data2: JSON.parse(JSON.stringify(res.data)), isLoading4: false, });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        Service.get('/bb/api/tender/rate/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                if (res.data.length === 0) {
                    this.props.enqueueSnackbar('Goto Rates tab to initiate default rates!', {
                        variant: 'warning'
                    });
                } else {

                    this.setState({
                        isLoading2: false,
                        rates: res.data,
                    });
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    giveaccess() {

        var json = {
            email: submitdata.email,
            is_staff: true,
            is_lead: true,
            is_admin: !submitdata.is_admin,
        }

        Service.put("/bb/api/tender/project/" + submitdata.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: json,
        })
            .then(res => {

                this.setState({ open: false, });
                // console.log(res.data)
                this.props.enqueueSnackbar('Permission changed for ' + submitdata.email, {
                    variant: 'success'
                });
                this.fetchOps();
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }
    costCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_pid === rowData.id) {
                cost = cost + this.costCalc2(element)
            }
        }
        return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
    }

    costCalc2(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return data1.hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costuk;
                }
            }
        }
        return 0;
    }

    hourCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_pid === rowData.id) {
                cost = cost + parseFloat(element.hours);
            }
        }
        return cost.toFixed(2);
    }

    dateParse(date) {
        var formatDate = moment(date).format('DD/MM/YYYY');
        // console.log(formatDate)
        if (formatDate === 'Invalid date') {
            return 'NA';
        } else {
            return formatDate;
        }
    }


    render() {
        const { classes } = this.props;

        const handleClose2 = () => {
            this.setState({ openCalc: false, });
        };

        const handleCloseText = () => {
            this.setState({ openText: false, });
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                        <div>
                            <MaterialTable
                                title="Deliverables Schedule"
                                columns={[
                                    {
                                        title: 'Deliverables Type', field: 'type',
                                        lookup: { 0: 'Budget', 1: 'Change', },
                                    },
                                    {
                                        title: 'Deliverables Schedule', field: 'name', headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 },
                                    },
                                    {
                                        title: 'From', field: 'from_date',
                                        render: rowData => this.dateParse(rowData.from_date)
                                    },
                                    {
                                        title: 'To', field: 'to_date',
                                        render: rowData => this.dateParse(rowData.to_date)
                                    },
                                    {
                                        title: 'Budgeted Hours', field: 'hour',
                                        render: rowData => <Typography>{this.hourCalc(rowData)}</Typography>
                                    },
                                    {
                                        title: 'Budgeted Amount', field: 'amount',
                                        render: rowData => <Typography>{this.costCalc(rowData)}</Typography>
                                    },

                                ]}
                                data={this.state.data}
                                actions={[
                                    rowData => ({
                                        icon: 'functions',
                                        tooltip: 'Summary',
                                        onClick: (event, rowData) => { this.setState({ allData: rowData, openCalc: true, }) },
                                    }),
                                    rowData => ({
                                        icon: 'details',
                                        tooltip: 'Scope Summary',
                                        onClick: (event, rowData) => { this.setState({ dialogTitle: 'Scope Summary', textDialogData: rowData.scope_summary, openText: true, }) },
                                    }),
                                    rowData => ({
                                        icon: 'comment',
                                        tooltip: 'Comment',
                                        onClick: (event, rowData) => { this.setState({ dialogTitle: 'Comment', textDialogData: rowData.comments, openText: true, }) },
                                    }),
                                    rowData => ({
                                        icon: 'assessment',
                                        tooltip: 'Mini Report',
                                        onClick: (event, rowData) => { window.open('/ds/mini/report/' + rowData.id + '/' + this.props.value.id) },
                                    }),
                                ]}
                                options={{
                                    padding: "dense",
                                    // actionsColumnIndex: -1
                                }}

                            />

                            <Dialog fullScreen open={this.state.openCalc} onClose={handleClose2} TransitionComponent={Transition}>
                                <AppBar className={classes.appBar}>
                                    <Toolbar>
                                        <IconButton edge="start" color="inherit" onClick={handleClose2} aria-label="close">
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography variant="h6" className={classes.title}>
                                            Summary for {this.state.allData.name}
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                <ApprovalWBS value={this.state.allData} />
                            </Dialog>

                            <Dialog open={this.state.openText} fullwidth="true" maxWidth="xl" onClose={handleCloseText} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">{this.state.dialogTitle}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        {this.state.textDialogData}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseText} color="primary">
                                        Close
                                    </Button>

                                </DialogActions>
                            </Dialog>

                        </div>
                    )}
            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(TenderProjects));