import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import moment from 'moment'
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { withStyles, Box, Button, List, ListItem, ListItemIcon, ListItemText, LinearProgress } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { LoopCircleLoading } from 'react-loadingg';
// import MaterialTable from 'material-table'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SendIcon from '@material-ui/icons/Send';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getCookie } from '../Helper/CookieHelper';
import ReactGA from 'react-ga';
import PersonIcon from '@material-ui/icons/Person';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { errorMessage } from '../Helper/ErrorMessage';
import BoxLoading from 'react-loadingg/lib/BoxLoading';
import WaveLoading from 'react-loadingg/lib/WaveLoading';
import { getDarkMode, getPrimaryColor, tenderTaskStatus } from '../Helper/CommonThings';
import { Alert } from '@material-ui/lab';

const token = getCookie("bb_ts_token");
var stweek = null;
var endweek = null;
var start_date_text = null
var end_date_text = null
var currweek = [];
// var autocompdata = '';


const user = JSON.parse(getCookie("bb_ts_user"));
var currentTimesheetUserId = user ? user.id : null

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#00b300',
        },
        secondary: {
            main: '#cca300',
        },
        error: {
            main: "#ff0000",
        },
        type: getDarkMode(),
    }
})

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

class WorkHours extends Component {

    constructor(props) {
        super();

        this.state = {
            data: [],
            data1: [],
            tabledata: [],

            columns: [
                { title: 'Emp Name', field: 'uname' },
                { title: 'Team', field: 'gname' },
                { title: 'Projects', field: 'proj_name' },

            ],
            originalData: [],
            openCal: false,
            toDia: [[], []],
            toDia2: [[], []],
            isLoading: true,
            selectDate: new Date(),
            openSub: false,
            adddata_all_temp: [],
            adddata: [],
            postComment: null,
            is_lock: false,
            is_submit: false,
            is_submit_all: false,
            id_approve: false,
            is_reject: false,
            isLoadingTable: false,
            recStart: stweek,
            recStop: endweek,
            wbs: [],
            project: [],
            pdmData: [],
            bid_data: [],
            disableSubmit: false,
            disableSubmit2: false,
            disableSubmit3: false,
            disableSubmit4: false,
            prep_task: [],
            prep_overheads: [],
            overheads_proj_tasks_text: [],
            progress_percen: 0,
            progress_show: false,
            cancel_button: false,
            progress_share: 0,
            allProj_name: '',
            overheadPDMdata: [],
            pdmWBSData: [],
            openLoading: false,
            preFetchGids: null,
            waitSubmit: true,
            waitSubmit1: true,
            alertData: null,
            defaultPlan: null
        }

    }

    componentDidMount() {
        this.props.enqueueSnackbar("Ensure to fill the 'Comments' for each time item.", {
            variant: 'info'
        });

        var pageView = window.location.pathname + "/#TimesheetRequest";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        stweek = moment().startOf('isoWeek');
        endweek = moment().endOf('isoWeek');
        if (this.props.openPrevWeek) {
            stweek = stweek.subtract(1, 'weeks')
            endweek = endweek.subtract(1, 'weeks')
            this.setState({
                recStart: stweek,
                recStop: endweek,
            })
            this.fetchOps(stweek, endweek);
        } else {
            this.setState({
                recStart: stweek,
                recStop: endweek,
            })
            this.fetchOps(stweek, endweek);
        }

    }

    componentWillUnmount() {
        currentTimesheetUserId = user ? user.id : null
    }

    getTeamRole(id) {
        if (!id) {
            return false;
        }
        for (let i = 0; i < this.props.useraccess.length; i++) {
            const element = this.props.useraccess[i];
            if (element.gid === id && (element.team_role === 1)) {
                return true;
            }
        }
        return false;
    }

    getLMSAccess(element) {
        // console.log(this.props.access, element)
        if (this.props.access && this.props.access.access && this.props.access.access.l18a && this.props.access.access.l18a.l18a_a && [66, 67, 77, 78].includes(element.tid)) {
            return false
        }
        return true
    }

    fetchOps(start_date, end_date) {
        // alert("run")
        this.setState({
            recStart: moment(start_date),
            recStop: moment(end_date),
            isLoadingTable: true,
            disableSubmit: false,
            disableSubmit2: false,
            disableSubmit3: false,
            disableSubmit4: false,
            waitSubmit: true,
            waitSubmit1: true
        })
        // console.log(start_date)
        // console.log(end_date)
        if (this.props.timesheetOption === 1) {
            start_date = start_date.subtract(1, 'days')
            end_date = end_date.subtract(1, 'days')
        }
        var start_date1 = moment(start_date).utc()
        var end_date1 = moment(end_date).utc()
        start_date1.subtract(1, 'days')
        end_date1.subtract(1, 'days')
        // let time = moment('23:59', 'HH:mm');
        // let time1 = moment('23:59', 'HH:mm');
        // start_date1.set({
        //     hour: time.get('hour'),
        //     minute: time.get('minute'),
        //     // second: time.get('second')
        // });
        // end_date1.set({
        //     hour: time1.get('hour'),
        //     minute: time1.get('minute'),
        //     // second: time1.get('second')
        // });
        // console.log(start_date1.format('YYYY-MM-DD HH:mm:ss'))
        // console.log(end_date1.format('YYYY-MM-DD HH:mm:ss'))

        var url1 = "/bb/api/team/tasks/?uid=" + currentTimesheetUserId + "&start_date=" + start_date1.format('DD-MM-YYYY HH:mm:ss') + "&end_date=" + end_date1.format('DD-MM-YYYY HH:mm:ss')

        if (this.props.value) {
            var userdub = JSON.parse(localStorage.getItem("staff_to_see"));
            console.log(userdub)
            currentTimesheetUserId = userdub.uid
            url1 = "/bb/api/team/tasks/?uid=" + userdub.uid + "&gid=" + this.props.value.id + "&start_date=" + start_date1.format('DD-MM-YYYY HH:mm:ss') + "&end_date=" + end_date1.format('DD-MM-YYYY HH:mm:ss')
        }

        var gids = '0'

        Service.get(url1, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.fetchContractedHrs(currentTimesheetUserId, start_date1.add(1, 'days'), end_date1)
                // alert(res.data.length)
                var datapull = [];
                var nonZeroHoursData = res.data.filter((item) => item.actual_hours > 0);



                for (let i = 0; i < nonZeroHoursData.length; i++) {
                    const element = nonZeroHoursData[i];
                    var date = moment(element.start).local().format('DD/MM/YYYY')
                    var stime = moment(element.start).format('HH mm A');
                    var etime = moment(element.end).format('HH mm A');
                    var whours = moment.duration(moment(element.end).diff(moment(element.start)));
                    var whours1 = ''
                    // console.log(parseFloat(whours.asHours()))
                    if (parseFloat(whours.asHours()) < 0.9) {
                        whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                    } else {
                        whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                    }

                    // var status = '';
                    // if (element.is_approved === 1) {
                    //     status = "[Approved]"
                    // } else if (element.is_approved === 2) {
                    //     status = "[Rejected]"
                    // } else if (element.is_approved === 0 && element.is_submitted === 1) {
                    //     status = "[Submited]"
                    // }
                    if (element.is_tender === 1 && (element.is_submitted === 0) && this.getLMSAccess(element)) {
                        datapull.push({
                            id: element.id,
                            uid: element.uid,
                            gid: element.gid,
                            tid: element.tid,
                            pid: element.pid,
                            ttid: element.ttid,
                            tpid: element.tpid,
                            twbsid: element.twbsid,
                            trolesid: element.trolesid,
                            tmapid: element.tmapid,
                            uemail: element.uemail,
                            uname: element.uname_first + " " + element.uname_last,
                            gname: element.gname,
                            tname: element.tname,
                            tproj_name: element.tproj_name,
                            ttname: element.ttdecs ? element.ttdecs : element.ttname,
                            tuemail: element.tuemail,
                            twbsname: element.twbsname,
                            trolename: element.trolename,
                            ttender_name: element.ttender_name,
                            ttender_id: element.ttenderid,
                            title: element.tproj_name + "(" + element.ttdecs ? element.ttdecs : element.ttname + " - " + element.ttdecs + ")",
                            proj_name: element.proj_name,
                            work_detail: element.work_detail,
                            comments: element.comments,
                            date: date,
                            start: new Date(element.start),
                            end: new Date(element.end),
                            is_locked: element.is_locked,
                            is_submitted: element.is_submitted,
                            is_approved: element.is_approved,
                            strstart: element.start,
                            strend: element.end,
                            stime: stime,
                            etime: etime,
                            estimated_hours: element.estimated_hours,
                            created_by: element.created_by,
                            whours: whours1,
                            actual_hours: element.actual_hours,
                            is_tender: element.is_tender,
                            task_hold: element.task_hold,
                            ttstatus: element.ttstatus,
                            comment_mandatory: element.comment_mandatory
                        });
                    } else if (element.is_tender === 0 && (element.is_submitted === 0) && this.getLMSAccess(element)) {
                        datapull.push({
                            id: element.id,
                            uid: element.uid,
                            gid: element.gid,
                            tid: element.tid,
                            pid: element.pid,
                            ttid: element.ttid,
                            tpid: element.tpid,
                            twbsid: element.twbsid,
                            trolesid: element.trolesid,
                            tmapid: element.tmapid,
                            uemail: element.uemail,
                            uname: element.uname_first + " " + element.uname_last,
                            gname: element.gname,
                            tname: element.tname,
                            tproj_name: element.tproj_name,
                            ttname: element.ttdecs ? element.ttdecs : element.ttnamee,
                            tuemail: element.tuemail,
                            twbsname: element.twbsname,
                            trolename: element.trolename,
                            ttender_name: element.ttender_name,
                            ttender_id: element.ttenderid,
                            title: element.proj_name + "(" + element.tname + ")",
                            proj_name: element.proj_name,
                            work_detail: element.work_detail,
                            comments: element.comments,
                            date: date,
                            start: new Date(element.start),
                            end: new Date(element.end),
                            is_locked: element.is_locked,
                            is_submitted: element.is_submitted,
                            is_approved: element.is_approved,
                            strstart: element.start,
                            strend: element.end,
                            stime: stime,
                            etime: etime,
                            estimated_hours: element.estimated_hours,
                            created_by: element.created_by,
                            whours: whours1,
                            actual_hours: element.actual_hours,
                            is_tender: element.is_tender,
                            task_hold: element.task_hold,
                            ttstatus: element.ttstatus,
                            comment_mandatory: element.comment_mandatory
                        });
                    }
                    gids = gids + "," + element.gid
                }
                // console.log(datapull)
                this.setState({ data: datapull, originalData: nonZeroHoursData, preFetchGids: gids }, () => {
                    if (moment().isoWeekday() === 7) {
                        stweek = start_date;
                        endweek = end_date;

                        this.handleColumns2(stweek.add(1, 'days').startOf('isoWeek'), endweek.subtract(1, 'days').endOf('isoWeek'))
                    } else {
                        stweek = start_date;
                        endweek = end_date;
                        this.handleColumns2(stweek.add(1, 'days').startOf('isoWeek'), endweek.subtract(1, 'days').endOf('isoWeek'))
                    }

                })

                // if (datapull.length > 0) {
                // Service.get("/bb/api/users", {
                //     headers: {
                //         Authorization: "Token " + token,
                //     },
                // })
                //     .then(res => {
                //         var tonewusr = [];
                //         for (let i = 0; i < res.data.length; i++) {
                //             const element = res.data[i];

                //             tonewusr.push({
                //                 name: element.email + " (" + element.first_name + " " + element.last_name + ")",
                //                 rname: element.first_name + " " + element.last_name,
                //                 remail: element.email,
                //                 email: element.email,
                //                 first_name: element.first_name,
                //                 last_name: element.last_name,
                //                 id: element.id,
                //                 // gid: this.props.value.id,
                //             });
                //         }
                //         // console.log("users", res)
                //         this.setState({
                //             adddata_all_temp: tonewusr,

                //         }, () => {
                //             this.setState({ disableSubmit3: false })
                //             // this.checkPDM();
                //             // console.log(res4.data)
                //         })

                //     })
                //     .catch(error => {
                //         errorMessage(this.props, error)
                //     });
                // Service.get("/bb/api/team/access/?gid=" + gids + "&team_role=1,2", {
                //     headers: {
                //         Authorization: "Token " + token,
                //     },
                // })
                //     .then(resUserAccess => {

                //         var uids = "0"

                //         for (let i = 0; i < resUserAccess.data.length; i++) {
                //             const element = resUserAccess.data[i];

                //             uids = uids + "," + element.uid;

                //         }

                //         var tonewusr = [];
                //         for (let i = 0; i < resUserAccess.data.length; i++) {
                //             const element = resUserAccess.data[i];

                //             tonewusr.push({
                //                 name: element.uid_data.email + " (" + element.uid_data.first_name + " " + element.uid_data.last_name + ")",
                //                 rname: element.uid_data.first_name + " " + element.uid_data.last_name,
                //                 remail: element.uid_data.email,
                //                 email: element.uid_data.email,
                //                 first_name: element.uid_data.first_name,
                //                 last_name: element.uid_data.last_name,
                //                 id: element.uid_data.id,
                //                 gid: element.gid,
                //             });
                //         }
                //         // console.log("teamaccess", resUserAccess)
                //         this.setState({
                //             adddata: tonewusr,

                //         }, () => {
                //             this.setState({ disableSubmit4: false })
                //             // this.checkPDM();
                //             // console.log(res4.data)
                //         })


                //     })
                //     .catch(error => {
                //         // console.log(error)
                //         errorMessage(this.props, error)
                //     });

                // var wbs_id = "0"

                // for (let i = 0; i < datapull.length; i++) {
                //     const element = datapull[i];
                //     if (element.twbsid) {
                //         wbs_id = wbs_id + "," + element.twbsid
                //     }
                // }

                // // console.log(wbs_id)

                // Service.get('/bb/api/tender/ohlwbs/?id=' + wbs_id, {
                //     headers: {
                //         Authorization: "Token " + token,
                //     },
                // })
                //     .then(res2 => {
                //         // console.log(res2.data)
                //         this.setState({
                //             wbs: res2.data
                //         })

                //         var pid_data = '0'
                //         for (let i = 0; i < res2.data.length; i++) {
                //             const element = res2.data[i];
                //             pid_data = pid_data + "," + element.pid_data.subproject_pid
                //         }

                //         var pdmData = []
                //         var pdmWBSData = []
                //         Service.get('/bb/api/tender/pdm/?pid=' + pid_data + "&timesheet_access=1", {
                //             headers: {
                //                 Authorization: "Token " + token,
                //             },
                //         })
                //             .then(res1 => {
                //                 pdmData = res1.data

                //                 var pdmid = "0"
                //                 for (let i = 0; i < pdmData.length; i++) {
                //                     const element = pdmData[i];
                //                     pdmid = pdmid + "," + element.id
                //                 }

                //                 Service.get('/bb/api/tender/wbsmanager/?tenderpdm=' + pdmid, {
                //                     headers: {
                //                         Authorization: "Token " + token,
                //                     },
                //                 })
                //                     .then(reswbs => {

                //                         pdmWBSData = reswbs.data
                //                         // console.log("wbsmanager", reswbs)
                //                         this.setState({
                //                             // bid_data: res4.data,
                //                             pdmData: pdmData,
                //                             pdmWBSData: pdmWBSData
                //                         }, () => {
                //                             this.setState({ disableSubmit2: false })
                //                             // this.checkPDM();
                //                             // console.log(res4.data)
                //                         })

                //                     })
                //                     .catch(error => {
                //                         errorMessage(this.props, error)
                //                     });

                //             })
                //             .catch(error => {
                //                 errorMessage(this.props, error)
                //             });

                //         Service.get('/bb/api/tender/project/?id=' + pid_data, {
                //             headers: {
                //                 Authorization: "Token " + token,
                //             },
                //         })
                //             .then(res3 => {
                //                 this.setState({
                //                     project: res3.data
                //                 })
                //                 var tbid_id = '0'
                //                 for (let i = 0; i < res3.data.length; i++) {
                //                     const element = res3.data[i];
                //                     if (element.from_tenderbid) {
                //                         tbid_id = tbid_id + ',' + element.from_tenderbid
                //                     }
                //                 }

                //                 Service.get('/bb/api/tender/bid/?id=' + tbid_id, {
                //                     headers: {
                //                         Authorization: "Token " + token,
                //                     },
                //                 })
                //                     .then(res4 => {
                //                         // console.log("bid", res4)
                //                         this.setState({
                //                             bid_data: res4.data,

                //                         }, () => {
                //                             this.setState({ disableSubmit: false })
                //                             // this.checkPDM();
                //                             // console.log(res4.data)
                //                         })
                //                     })
                //                     .catch(error => {
                //                         errorMessage(this.props, error)
                //                     });

                //             })
                //             .catch(error => {
                //                 errorMessage(this.props, error)
                //             });

                //     })
                //     .catch(error => {
                //         errorMessage(this.props, error)
                //     });

                // }

            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });

    }

    commentsMandatoryCheck() {
        var data = this.state.data
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.comment_mandatory && !element.comments) {
                return false
            }
        }
        return true
    }

    preSubmitFetch() {
        if (this.commentsMandatoryCheck()) {
            this.setState({
                openLoadingCharge: true,
                waitSubmit: true
            })
            this.getWeekPlan()
            // alert("run")
            Service.get("/bb/api/users", {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    var tonewusr = [];
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];

                        tonewusr.push({
                            name: element.email + " (" + element.first_name + " " + element.last_name + ")",
                            rname: element.first_name + " " + element.last_name,
                            remail: element.email,
                            email: element.email,
                            first_name: element.first_name,
                            last_name: element.last_name,
                            id: element.id,
                            // gid: this.props.value.id,
                        });
                    }
                    // console.log("users", res)
                    this.setState({
                        adddata_all_temp: tonewusr,
                        disableSubmit3: true,

                    }, () => {
                        this.preSplitPdmTask()
                    })

                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
            Service.get("/bb/api/team/access/?gid=" + this.state.preFetchGids + "&team_role=1,2", {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(resUserAccess => {

                    var uids = "0"

                    for (let i = 0; i < resUserAccess.data.length; i++) {
                        const element = resUserAccess.data[i];

                        uids = uids + "," + element.uid;

                    }

                    var tonewusr = [];
                    for (let i = 0; i < resUserAccess.data.length; i++) {
                        const element = resUserAccess.data[i];

                        tonewusr.push({
                            name: element.uid_data.email + " (" + element.uid_data.first_name + " " + element.uid_data.last_name + ")",
                            rname: element.uid_data.first_name + " " + element.uid_data.last_name,
                            remail: element.uid_data.email,
                            email: element.uid_data.email,
                            first_name: element.uid_data.first_name,
                            last_name: element.uid_data.last_name,
                            id: element.uid_data.id,
                            gid: element.gid,
                        });
                    }
                    // console.log("teamaccess", resUserAccess)
                    this.setState({
                        adddata: tonewusr,
                        disableSubmit4: true,

                    }, () => {
                        this.preSplitPdmTask()
                    })


                })
                .catch(error => {
                    // console.log(error)
                    errorMessage(this.props, error)
                });

            var wbs_id = "0"

            for (let i = 0; i < this.state.data.length; i++) {
                const element = this.state.data[i];
                if (element.twbsid) {
                    wbs_id = wbs_id + "," + element.twbsid
                }
            }

            // console.log(wbs_id)

            Service.get('/bb/api/tender/ohlwbs/?id=' + wbs_id, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res2 => {
                    // console.log(res2.data)
                    this.setState({
                        wbs: res2.data
                    })

                    var pid_data = '0'
                    for (let i = 0; i < res2.data.length; i++) {
                        const element = res2.data[i];
                        pid_data = pid_data + "," + element.pid_data.subproject_pid
                    }

                    var pdmData = []
                    var pdmWBSData = []
                    Service.get('/bb/api/tender/pdm/?pid=' + pid_data + "&timesheet_access=1", {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res1 => {
                            pdmData = res1.data

                            var pdmid = "0"
                            for (let i = 0; i < pdmData.length; i++) {
                                const element = pdmData[i];
                                pdmid = pdmid + "," + element.id
                            }

                            Service.get('/bb/api/tender/wbsmanager/?tenderpdm=' + pdmid, {
                                headers: {
                                    Authorization: "Token " + token,
                                },
                            })
                                .then(reswbs => {

                                    pdmWBSData = reswbs.data
                                    // console.log("wbsmanager", reswbs)
                                    this.setState({
                                        // bid_data: res4.data,
                                        pdmData: pdmData,
                                        pdmWBSData: pdmWBSData,
                                        disableSubmit2: true,
                                    }, () => {
                                        this.preSplitPdmTask()
                                    })

                                })
                                .catch(error => {
                                    errorMessage(this.props, error)
                                });

                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                        });

                    Service.get('/bb/api/tender/project/?id=' + pid_data, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res3 => {
                            this.setState({
                                project: res3.data
                            })
                            var tbid_id = '0'
                            for (let i = 0; i < res3.data.length; i++) {
                                const element = res3.data[i];
                                if (element.from_tenderbid) {
                                    tbid_id = tbid_id + ',' + element.from_tenderbid
                                }
                            }

                            Service.get('/bb/api/tender/bid/?id=' + tbid_id, {
                                headers: {
                                    Authorization: "Token " + token,
                                },
                            })
                                .then(res4 => {
                                    // console.log("bid", res4)
                                    this.setState({
                                        bid_data: res4.data,
                                        disableSubmit: true,

                                    }, () => {

                                        this.preSplitPdmTask()
                                    })
                                })
                                .catch(error => {
                                    errorMessage(this.props, error)
                                });

                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                        });

                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        } else {
            this.props.enqueueSnackbar("Please fill the comments for the mandatory items.", {
                variant: 'warning'
            });
        }
    }

    handleColumns(stweek1, endweek1) {
        currweek = [];
        var date = '';
        var date1 = '';
        var date2 = '';
        if (this.props.timesheetOption === 1) {
            date = stweek1.subtract(2, 'days');
            date1 = stweek1.format('DD/MM/YYYY');
            date2 = endweek1.subtract(2, 'days');
        } else {
            date = stweek1.subtract(1, 'days');
            date1 = stweek1.format('DD/MM/YYYY');
            date2 = endweek1.subtract(1, 'days');
        }

        currweek.push(moment(stweek1));
        currweek.push(moment(date2));
        var columns = [
            { title: 'Emp Name', field: 'uname' },
            // { title: 'Projects', field: 'proj_name' },
            // { title: 'Tasks', field: 'tname' },
        ];
        //console.log(this.state.dupCol)
        start_date_text = date.format("DD MMM (ddd)")
        while (date2 >= date) {

            end_date_text = date.format("DD MMM (ddd)")

            const ccc = date1;
            columns.push(
                {
                    title: date.format("DD MMM (ddd)"), field: date1,
                    render: rowData => <ThemeProvider theme={bbColorTheme}><Typography color={(parseInt(rowData[ccc]) < 4) ? ("error") : (parseInt(rowData[ccc]) > 7) ? ("primary") : ("secondary")} >{rowData[ccc]}</Typography></ThemeProvider>
                }
            )
            date1 = date.add(1, 'days').format('DD/MM/YYYY')

        }

        columns.push(
            {

                title: "Total", field: 'total', filtering: false,
            }
        )

        var statedata = this.state.data1;
        var doneuid = [];
        var toTData = [];
        var userelement = [];

        for (let i = 0; i < statedata.length; i++) {
            var userelement2 = [];
            const element = statedata[i];

            if (i === 0) {
                doneuid.push(element.uid);
                for (let j = 0; j < statedata.length; j++) {
                    const element1 = statedata[j];
                    if (element.uid === element1.uid) {

                        userelement2.push(element1)
                    }
                }
            } else {
                var goodtogo = true;
                for (let k = 0; k < doneuid.length; k++) {
                    const element3 = doneuid[k];

                    if (element3 === element.uid) {
                        goodtogo = false;
                    }
                }

                if (goodtogo) {
                    doneuid.push(element.uid);
                    for (let j = 0; j < statedata.length; j++) {
                        const element1 = statedata[j];
                        if (element.uid === element1.uid) {

                            userelement2.push(element1)
                        }

                    }
                }
            }

            if (userelement2.length !== 0) {
                userelement.push(userelement2)
            }

        }

        //date
        for (let i = 0; i < userelement.length; i++) {
            var whdate = [];
            var wh = [];
            var teams = [];
            var proj = [];
            var tasks = [];
            const element = userelement[i];
            for (let j = 0; j < element.length; j++) {
                const element1 = element[j];
                var goodtogo1 = true;
                for (let k = 0; k < whdate.length; k++) {
                    const element2 = whdate[k];
                    if (element2 === element1.date) {
                        goodtogo1 = false;
                    }

                }

                if (goodtogo1) {
                    // console.log('Total 111 time is')
                    whdate.push(element1.date);
                    var hours = []
                    for (let m = 0; m < element.length; m++) {
                        const element3 = element[m];
                        if (element3.date === element1.date) {
                            hours.push(element3[element3.date])
                        }
                        var teamgo = true;
                        for (let n = 0; n < teams.length; n++) {
                            const element4 = teams[n];
                            if (element4 === element3.gname) {
                                teamgo = false;
                            }
                        }
                        if (teamgo) {
                            teams.push(element3.gname)
                        }
                        var projgo = true;
                        for (let n = 0; n < proj.length; n++) {
                            const element4 = proj[n];
                            if (element3.is_tender === 0) {
                                if (element4 === element3.proj_name) {
                                    projgo = false;
                                }
                            } else {
                                if (element4 === element3.ttender_name + " - " + element3.tproj_name) {
                                    projgo = false;
                                }
                            }

                        }
                        //console.log(element3.proj_name)
                        if (projgo) {
                            if (element3.is_tender === 0) {
                                proj.push(element3.proj_name)
                            } else {
                                proj.push(element3.ttender_name + " - " + element3.tproj_name)
                            }

                        }
                        var taskgo = true;
                        for (let n = 0; n < tasks.length; n++) {
                            const element4 = tasks[n];
                            if (element3.is_tender === 0) {
                                if (element4 === element3.tname) {
                                    taskgo = false;
                                }
                            } else {
                                if (element4 === element3.ttname) {
                                    taskgo = false;
                                }
                            }

                        }
                        //console.log(element3.proj_name)
                        if (taskgo) {
                            if (element3.is_tender === 0) {
                                tasks.push(element3.tname)
                            } else {
                                tasks.push(element3.ttname)
                            }

                        }
                    }
                    // console.log(hours)
                    const totalDurations = hours.slice(1)
                        .reduce((prev, cur) => moment.duration(cur).add(prev),
                            moment.duration(hours[0]))
                    wh.push(moment.utc(totalDurations.asMilliseconds()).format("HH:mm"))
                }

            }

            // console.log(element.pid)
            var jsoncons = {
                id: element[0].id,
                uid: element[0].uid,
                gid: element[0].gid,
                pid: element[0].pid,
                tid: element[0].tid,
                ttid: element[0].ttid,
                tpid: element[0].tpid,
                twbsid: element[0].twbsid,
                trolesid: element[0].trolesid,
                tmapid: element[0].tmapid,
                tname: tasks.join(),
                tproj_name: element[0].tproj_name,
                ttname: element[0].ttname,
                tuemail: element[0].tuemail,
                twbsname: element[0].twbsname,
                trolename: element[0].trolename,
                uemail: element[0].uemail,
                uname: element[0].uname,
                gname: teams.join(),
                title: element[0].title,
                proj_name: proj.join(),
                work_detail: element[0].work_detail,
                start: element[0].start,
                end: element[0].end,
                date: element[0].date,
                stime: element[0].stime,
                etime: element[0].etime,
                whours: element[0].whours,
                created_by: element[0].created_by,
                is_tender: element.is_tender,
            }
            for (let k = 0; k < whdate.length; k++) {
                const element = whdate[k];
                jsoncons[element] = wh[k];
            }
            // console.log(jsoncons)

            const totalDurations11 = wh.slice(1)
                .reduce((prev, cur) => moment.duration(cur).add(prev),
                    moment.duration(wh[0]));
            var minin = (moment.duration(totalDurations11.asMilliseconds()));
            var minin2 = moment.utc(totalDurations11.asMilliseconds()).format("mm")
            jsoncons["total"] = parseInt(minin.asHours()) + ":" + minin2;
            toTData.push(jsoncons);
        }

        var todata = [];
        todata.push(columns)
        todata.push(toTData)
        // console.log(todata)
        this.setState({
            toDia: todata,
        }, () => {
            this.setState({
                isLoading: false,
                isLoadingTable: false,
                waitSubmit: false
            })
        })

    }

    handleColumns2(stweek1, endweek1) {

        var d1 = moment(stweek1)
        var d2 = moment(endweek1)
        var date = '';
        var date1 = '';
        var date2 = '';
        if (this.props.timesheetOption === 1) {
            date = moment(stweek1).subtract(2, 'days');
            date1 = moment(date).format('DD/MM/YYYY');
            date2 = moment(endweek1).subtract(2, 'days');
        } else {
            date = moment(stweek1).subtract(1, 'days');
            date1 = moment(date).format('DD/MM/YYYY');
            date2 = moment(endweek1).subtract(1, 'days');
        }



        var columns = [
            {
                title: 'Projects', field: 'ttender_name', headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }, editable: 'never',
                render: rowData => (rowData.proj_name ? rowData.proj_name : rowData.ttender_name),
                customFilterAndSearch: (term, rowData) => {
                    return (rowData.proj_name ? rowData.proj_name : rowData.ttender_name).toLowerCase().includes(term.toLowerCase())
                }
            },
            {
                title: 'Tasks', field: 'tname', headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }, editable: 'never',
                render: rowData => (rowData.tname ? rowData.tname : (rowData.ttdecs ? rowData.ttdecs : rowData.ttname)),
                customFilterAndSearch: (term, rowData) => {
                    return (rowData.tname ? rowData.tname : (rowData.ttdecs ? rowData.ttdecs : rowData.ttname)).toLowerCase().includes(term.toLowerCase())
                }
            },
            {
                title: 'Status', field: 'task_hold', editable: 'never',
                render: rowData => (!rowData.task_hold && rowData.task_hold !== 0) ? "-" : rowData.task_hold ? <h4 style={{ color: 'red' }}>On Hold</h4> : tenderTaskStatus(rowData.ttstatus),
                customFilterAndSearch: (term, rowData) => {
                    return (!rowData.task_hold && rowData.task_hold !== 0) ? "-" : rowData.task_hold ? <h4 style={{ color: 'red' }}>On Hold</h4> : tenderTaskStatus(rowData.ttstatus).toLowerCase().includes(term.toLowerCase())
                }
            },
            // { title: 'Tasks', field: 'tname', headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }, },
            { title: 'Comment', field: 'comments', headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }, },
            { title: 'Hours Expended', field: 'actual_hours', editable: 'never', },
        ];
        // console.log(this.state.data, date1)
        var statedata = this.state.data;
        var jsonDa = [];
        var is_submit = false;
        var is_submit_all = true;
        var is_reject = false;

        while (date2 >= date) {

            const ccc = date1;
            // console.log(ccc)
            columns.push(
                {
                    title: date.format("DD MMM (ddd)"), field: date1, filtering: false, defaultSort: 'desc', editable: 'never',
                    render: rowData => rowData[ccc]
                }
            )

            for (let i = 0; i < statedata.length; i++) {
                const element = statedata[i];
                if (element.date === date1) {
                    var jsoncons = {
                        id: element.id,
                        uid: element.uid,
                        gid: element.gid,
                        pid: element.pid,
                        tid: element.tid,
                        ttid: element.ttid,
                        tpid: element.tpid,
                        twbsid: element.twbsid,
                        trolesid: element.trolesid,
                        tproj_name: element.tproj_name,
                        ttname: element.ttname,
                        tuemail: element.tuemail,
                        twbsname: element.twbsname,
                        trolename: element.trolename,
                        tmapid: element.tmapid,
                        tname: element.tname,
                        uemail: element.uemail,
                        uname: element.uname,
                        gname: element.gname,
                        title: element.title,
                        ttender_name: element.ttender_name,
                        ttender_id: element.ttender_id,
                        proj_name: element.proj_name,
                        work_detail: element.work_detail,
                        comments: element.comments,
                        is_locked: element.is_locked,
                        is_submitted: element.is_submitted,
                        is_approved: element.is_approved,
                        start: element.start,
                        end: element.end,
                        date: element.date,
                        stime: element.stime,
                        etime: element.etime,
                        whours: element.whours,
                        created_by: element.created_by,
                        [element.date]: element.whours,
                        actual_hours: element.actual_hours,
                        is_tender: element.is_tender,
                        task_hold: element.task_hold,
                        ttstatus: element.ttstatus,
                        comment_mandatory: element.comment_mandatory
                    }

                    if (element.is_submitted === 1) {
                        is_submit = true;
                    } else {
                        is_submit_all = false;
                    }

                    if (element.is_approved === 2) {
                        is_reject = true;
                    }

                    jsonDa.push(jsoncons)
                }
            }

            date1 = date.add(1, 'days').format('DD/MM/YYYY')

        }
        // console.log(this.state.data.length === 0 && this.state.originalData.length !== 0)

        if (this.state.data.length !== this.state.originalData.length) {

            is_submit = true;
            is_submit_all = false;
        }

        if (this.state.data.length === 0 && this.state.originalData.length !== 0) {

            is_submit_all = true;
        }

        var todata = [];

        todata.push(columns);
        todata.push(jsonDa)

        //    console.log(todata)

        this.setState({
            toDia2: todata,
            data1: jsonDa,
            is_submit: is_submit,
            is_submit_all: is_submit_all,
            is_reject: is_reject,

        }, () => {

            this.handleColumns(d1, d2)
        })

    }

    preChangeTaskStatus() {
        // console.log(this.state.prep_overheads.length, this.checkPDmselct())
        // console.log(this.state.overheadPDMdata)
        if ((this.state.prep_overheads.length !== 0 && this.checkPDmselct())) {

            this.props.enqueueSnackbar('Fill all the fields to continue!', {
                variant: 'error'
            });

        } else {
            this.changeTaskStatus()
        }
    }

    changeTaskStatus() {
        if (this.state.defaultPlan) {
            this.agreePlan(this.state.defaultPlan)
        }
        this.preHandleSubmit(0)
        // var upTask = [];
        // for (let i = 0; i < this.state.data1.length; i++) {
        //     const element = this.state.data1[i];
        //     upTask.push({
        //         id: element.id,
        //         is_submitted: 1,
        //     });
        // }

        // Service.patch("/bb/api/team/tasks/bulk_update", {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        //     data: upTask,
        // })
        //     .then(res => {
        //         this.preHandleSubmit(0)

        //     })
        //     .catch(error => {
        //         this.fetchOps(this.state.recStart, this.state.recStop);
        //         this.setState({ isLoading: false, openSub: false, cancel_button: false, progress_show: false })
        //         errorMessage(this.props, error)
        //     });
    }


    handleSubmit(pdm_uid_data, here_tasks, interger_num) {

        // console.log(pdm_uid_data)
        // console.log(here_tasks)
        // console.log(interger_num)

        // if (interger_num !== null) {
        //     this.preHandleSubmit(interger_num + 1)
        // } else {
        //     this.fetchOps(this.state.recStart, this.state.recStop);
        //     this.setState({ isLoading: true, openSub: false, cancel_button: false, progress_show: false, })
        //     this.props.enqueueSnackbar('Approval Request sent successfully', {
        //         variant: 'success'
        //     });
        // }


        // var userdub = JSON.parse(localStorage.getItem("staff_to_see"));
        var dataTo = [];
        var temp1 = [];
        temp1.push(this.state.toDia2[0])
        temp1.push(here_tasks)
        dataTo.push(this.state.toDia);
        dataTo.push(temp1);

        // console.log(dataTo)

        var gnames = []

        for (let i = 0; i < here_tasks.length; i++) {
            const element = here_tasks[i];
            gnames.push(element.gname)
        }

        gnames = [...new Set(gnames)];

        var postData = {
            uid: here_tasks[0].uid,
            // gid: this.props.value.id,
            request_type: 0,
            gname: gnames.join(),
            uemail: here_tasks[0].uemail,
            uname: here_tasks[0].uname,
            comment: this.state.postComment,
            list_tasks: dataTo,
            approve_status: 0,
            from_date: currweek[0],
            to_date: currweek[1],
            from_date_format: currweek[0].format('DD/MM/YYYY'),
            to_date_format: currweek[1].format('DD/MM/YYYY'),
            request_to_id: pdm_uid_data.id,
            request_to_name: pdm_uid_data.last_name + ", " + pdm_uid_data.first_name,
            request_to: pdm_uid_data.email,
            created_by: user.email,
            domain: window.location.host,
        }
        if ((this.state.is_submit && !this.state.is_submit_all) || this.state.is_reject) {
            postData.request_type = 1;
        }

        // console.log(postData)

        return postData

        // Service.post("/bb/api/task/approve/", {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        //     data: postData,
        // })
        //     .then(res => {
        //         // console.log(interger_num !== null)
        //         if (interger_num !== null) {
        //             this.preHandleSubmit(interger_num + 1)
        //         } else {
        //             this.fetchOps(this.state.recStart, this.state.recStop);
        //             this.setState({ isLoading: true, openSub: false, cancel_button: false, progress_show: false,  })
        //             this.props.enqueueSnackbar('Approval Request sent successfully', {
        //                 variant: 'success'
        //             });
        //         }
        //         // this.fetchOps(this.state.recStart, this.state.recStop);

        //     })
        //     .catch(error => {
        //         console.log(error)
        //         this.fetchOps(this.state.recStart, this.state.recStop);
        //         // console.log(error)
        //         this.setState({ isLoading: false, openSub: false, cancel_button: false, progress_show: false })
        //         errorMessage(this.props, error)
        //     });


    }


    handleOverHeadSubmitSubmit(pdm_uid_data, here_tasks, interger_num) {

        // console.log(pdm_uid_data)
        // console.log(here_tasks)
        // console.log(interger_num)

        // if (interger_num !== null) {
        //     this.preHandleSubmit(interger_num + 1)
        // } else {
        //     this.fetchOps(this.state.recStart, this.state.recStop);
        //     this.setState({ isLoading: true, openSub: false, cancel_button: false, progress_show: false, })
        //     this.props.enqueueSnackbar('Approval Request sent successfully', {
        //         variant: 'success'
        //     });
        // }


        // var userdub = JSON.parse(localStorage.getItem("staff_to_see"));
        var dataTo = [];
        var temp1 = [];
        temp1.push(this.state.toDia2[0])
        temp1.push(here_tasks[interger_num])
        dataTo.push(this.state.toDia);
        dataTo.push(temp1);

        var postData = {
            uid: here_tasks[interger_num][0].uid,
            // gid: this.props.value.id,
            request_type: 0,
            gname: here_tasks[interger_num][0].gname,
            uemail: here_tasks[interger_num][0].uemail,
            uname: here_tasks[interger_num][0].uname,
            comment: this.state.postComment,
            list_tasks: dataTo,
            approve_status: 0,
            from_date: currweek[0],
            to_date: currweek[1],
            from_date_format: currweek[0].format('DD/MM/YYYY'),
            to_date_format: currweek[1].format('DD/MM/YYYY'),
            request_to_id: pdm_uid_data[interger_num].id,
            request_to_name: pdm_uid_data[interger_num].last_name + ", " + pdm_uid_data[interger_num].first_name,
            request_to: pdm_uid_data[interger_num].email,
            created_by: user.email,
            domain: window.location.host,
        }
        if ((this.state.is_submit && !this.state.is_submit_all) || this.state.is_reject) {
            postData.request_type = 1;
        }

        return postData

        // Service.post("/bb/api/task/approve/", {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        //     data: postData,
        // })
        //     .then(res => {
        //         // console.log(interger_num !== null)
        //         if ((interger_num + 1) !== here_tasks.length) {
        //             this.handleOverHeadSubmitSubmit(this.state.overheadPDMdata, this.state.prep_overheads, interger_num + 1)
        //         } else {
        //             this.fetchOps(this.state.recStart, this.state.recStop);
        //             this.setState({ isLoading: true, openSub: false, cancel_button: false, progress_show: false, :})
        //             this.props.enqueueSnackbar('Approval Request sent successfully', {
        //                 variant: 'success'
        //             });
        //         }
        //         // this.fetchOps(this.state.recStart, this.state.recStop);

        //     })
        //     .catch(error => {
        //         console.log(error)
        //         this.fetchOps(this.state.recStart, this.state.recStop);
        //         // console.log(error)
        //         this.setState({ isLoading: false, openSub: false, cancel_button: false, progress_show: false })
        //         errorMessage(this.props, error)
        //     });


    }

    submitAllTimesheets(postData) {
        Service.post("/bb/api/task/approve/submit_timesheet/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: postData,
        })
            .then(res => {
                this.fetchOps(this.state.recStart, this.state.recStop);
                this.setState({ isLoading: true, openSub: false, cancel_button: false, progress_show: false, openLoading: false, })
                this.props.enqueueSnackbar('Approval Request sent successfully', {
                    variant: 'success'
                });
                // this.fetchOps(this.state.recStart, this.state.recStop);

            })
            .catch(error => {
                console.log(error)
                this.fetchOps(this.state.recStart, this.state.recStop);
                // console.log(error)
                this.setState({ isLoading: false, openSub: false, cancel_button: false, progress_show: false, openLoading: false })
                errorMessage(this.props, error)
            });

    }


    onTagsChange = (event, values, index) => {
        // console.log(values, 0)
        var temp = this.state.overheadPDMdata
        temp[index] = values
        this.setState({
            overheadPDMdata: temp
        })
    }

    preSplitPdmTask() {
        if (this.state.disableSubmit && this.state.disableSubmit2 && this.state.disableSubmit3 && this.state.disableSubmit4) {
            this.splitPdmTask()
        }
    }

    splitPdmTask() {

        // console.log("split", this.state.bid_data)
        // console.log(this.state.disableSubmit, this.state.disableSubmit2, this.state.disableSubmit3, this.state.disableSubmit4)
        var prep_task = [];
        var wbs_task = [];
        var overheads = [];
        var overheads_all = [];
        var all_wbs = '0';
        var all_gid = '0';

        var all_gid_name = '';

        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if (element.twbsid) {
                all_wbs = all_wbs + "," + element.twbsid;
            } else {
                overheads_all.push(element)
            }
            all_gid = all_gid + "," + element.gid;

        }

        // console.log(all_gid)

        all_wbs = Array.from(new Set(all_wbs.split(',')));
        all_gid = Array.from(new Set(all_gid.split(',')));

        // console.log(all_gid)

        for (let i = 1; i < all_gid.length; i++) {
            const elementGid = all_gid[i];
            for (let j = 0; j < this.state.data.length; j++) {
                const element1 = this.state.data[j];
                if (element1.ttid && element1.gid === parseInt(elementGid)) {
                    if (all_gid_name === '') {
                        all_gid_name = element1.gname
                    } else {
                        all_gid_name = all_gid_name + "," + element1.gname
                    }
                }
            }
        }
        // console.log(all_gid_name)

        // var gname_temp = []
        all_gid_name = Array.from(new Set(all_gid_name.split(','))).toString();
        // console.log(all_gid_name)

        // console.log(all_gid_name)

        for (let i = 1; i < all_wbs.length; i++) {
            const element = all_wbs[i];
            var wbs_get = {
                wbs_id: parseInt(element),
                tasks: [],
            }
            for (let j = 0; j < this.state.data.length; j++) {
                const element1 = this.state.data[j];
                if (element1.twbsid === parseInt(element)) {
                    wbs_get.tasks.push(element1)
                }
            }
            wbs_task.push(wbs_get)
        }

        // console.log(wbs_task)

        for (let i = 0; i < wbs_task.length; i++) {
            const element = wbs_task[i];
            for (let j = 0; j < this.state.wbs.length; j++) {
                const element1 = this.state.wbs[j];
                if (element.wbs_id === element1.id) {
                    element.wbs_data = element1;
                    break;
                }
            }
        }

        for (let i = 0; i < wbs_task.length; i++) {
            const element = wbs_task[i];
            element.pdm = []
            element.wbsmanager = []
            for (let j = 0; j < this.state.project.length; j++) {
                const element1 = this.state.project[j];
                if (element.wbs_data.pid_data.subproject_pid === element1.id) {
                    element.project = element1;
                    break;
                }
            }
            for (let j = 0; j < this.state.pdmData.length; j++) {
                const element1 = this.state.pdmData[j];
                if (element.wbs_data.pid_data.subproject_pid === element1.pid) {
                    element.pdm.push(element1);
                }
            }
            for (let j = 0; j < this.state.pdmWBSData.length; j++) {
                const element1 = this.state.pdmWBSData[j];
                if (element.wbs_data.id === element1.wbsid) {
                    element.wbsmanager.push(element1);
                }
            }
        }

        for (let i = 0; i < wbs_task.length; i++) {
            const element = wbs_task[i];
            for (let j = 0; j < this.state.bid_data.length; j++) {
                const element1 = this.state.bid_data[j];
                if (element.project.from_tenderbid && element.project.from_tenderbid === element1.id) {
                    element.upcometender = element1;
                    break;
                }
            }
        }

        // console.log(wbs_task)

        for (let i = 0; i < wbs_task.length; i++) {
            const element = wbs_task[i];
            // console.log(element.wbsmanager.length)
            if (element.wbsmanager.length !== 0) {
                for (let j = 0; j < element.pdm.length; j++) {
                    const element1 = element.pdm[j];
                    if (element1.id === element.wbsmanager[0].tenderpdm && !(currentTimesheetUserId === element1.uid)) {
                        if (element1.frwd_uid_data) {
                            element.pdm_uid = element1.frwd_uid_data
                            break;
                        } else if (element1.uid_data) {
                            element.pdm_uid = element1.uid_data
                            break;
                        }
                    }
                }
            } else {
                for (let j = 0; j < element.pdm.length; j++) {
                    const element1 = element.pdm[j];
                    if (element.wbs_data.type === element1.pdm_type && [1, 2].includes(element1.allocation_access) && !(currentTimesheetUserId === element1.uid)) {
                        if (element1.frwd_uid_data) {
                            element.pdm_uid = element1.frwd_uid_data
                            break;
                        } else if (element1.uid_data) {
                            element.pdm_uid = element1.uid_data
                            break;
                        }
                    } else if (element1.pdm_type === "all_type" && [2].includes(element1.allocation_access) && !(currentTimesheetUserId === element1.uid)) {
                        if (element1.frwd_uid_data) {
                            element.pdm_uid = element1.frwd_uid_data
                            break;
                        } else if (element1.uid_data) {
                            element.pdm_uid = element1.uid_data
                            break;
                        }
                    }
                }
            }

            if (!element.pdm_uid && element.upcometender && element.upcometender.pdm_data) {
                element.pdm_uid = element.upcometender.pdm_data
            }
        }

        for (let i = 0; i < wbs_task.length; i++) {
            const element = wbs_task[i];
            var goon = true;

            for (let j = 0; j < prep_task.length; j++) {
                const element1 = prep_task[j];
                if (element.pdm_uid && element.pdm_uid.id === element1.uid) {
                    element1.tasks = element1.tasks.concat(element.tasks)
                    goon = false;
                }
            }
            if (element.pdm_uid && goon) {
                prep_task.push({
                    uid: element.pdm_uid.id,
                    uid_data: element.pdm_uid,
                    tasks: element.tasks,
                })
            } else if (!element.pdm_uid) {
                overheads_all = overheads_all.concat(element.tasks)
            }
        }

        // console.log(prep_task)
        // console.log(overheads)

        for (let i = 0; i < prep_task.length; i++) {
            const element = prep_task[i];
            var proj_tasks_text = ''
            for (let j = 0; j < element.tasks.length; j++) {
                const element1 = element.tasks[j];
                if (j === 0) {
                    proj_tasks_text = (element1.proj_name ? element1.proj_name : element1.ttender_name)
                } else {
                    proj_tasks_text = proj_tasks_text + "," + (element1.proj_name ? element1.proj_name : element1.ttender_name)

                }
            }
            proj_tasks_text = Array.from(new Set(proj_tasks_text.split(','))).toString();
            element.proj_tasks_text = proj_tasks_text;
        }


        var proj_tasks_text1_all = []
        var overheadPDMdata = []
        var overheadPdmUsers = []
        if (overheads_all.length !== 0) {
            for (let i = 1; i < all_gid.length; i++) {
                const element = all_gid[i];
                var proj_tasks_text1 = ''
                var overhead_temp = []
                var overheadpdm_temp = []
                overheadPDMdata.push(null)
                for (let j = 0; j < overheads_all.length; j++) {
                    const element1 = overheads_all[j];

                    if (element1.gid === parseInt(element)) {

                        if (proj_tasks_text1 === '') {
                            proj_tasks_text1 = (element1.proj_name ? element1.proj_name : element1.ttender_name)

                        } else {
                            proj_tasks_text1 = proj_tasks_text1 + "," + (element1.proj_name ? element1.proj_name : element1.ttender_name)

                        }

                        overhead_temp.push(element1)
                    }
                }
                if (this.getTeamRole(parseInt(element))) {
                    overheadpdm_temp = (this.state.adddata_all_temp)
                } else {
                    for (let j = 0; j < this.state.adddata.length; j++) {
                        const element1 = this.state.adddata[j];
                        if (element1.gid === parseInt(element)) {
                            overheadpdm_temp.push(element1)
                        }
                    }
                }

                // console.log("HERE HERE")
                // console.log(overhead_temp)
                // console.log(overhead_temp.length)
                if (overhead_temp.length !== 0) {
                    overheadPdmUsers.push(overheadpdm_temp)
                    overheads.push(overhead_temp)
                    proj_tasks_text1 = Array.from(new Set(proj_tasks_text1.split(','))).toString();
                    proj_tasks_text1_all.push(proj_tasks_text1)
                }

            }
        }


        // for (let j = 0; j < overheads_all.length; j++) {
        //     const element1 = overheads_all[j];
        //     if (j === 0) {
        //         proj_tasks_text1 = (element1.proj_name ? element1.proj_name : element1.ttender_name)
        //     }
        //     else {
        //         proj_tasks_text1 = proj_tasks_text1 + "," + (element1.proj_name ? element1.proj_name : element1.ttender_name)

        //     }
        // }

        // console.log(prep_task)
        // console.log(overheads)
        // console.log(overheadPDMdata)

        this.setState({
            prep_overheads: overheads,
            prep_task: prep_task,
            overheads_proj_tasks_text: proj_tasks_text1_all,
            progress_share: 100 / (prep_task.length + 1),
            allProj_name: all_gid_name,
            overheadPdmUsers: overheadPdmUsers,
            overheadPDMdata: overheadPDMdata,
        }, () => {
            setTimeout(() => { this.setState({ openSub: true, openLoadingCharge: false, waitSubmit: false }) }, 500);

        })
    }

    checkPDmselct() {
        // console.log(this.state.overheadPDMdata)
        var goon = false
        for (let i = 0; i < this.state.prep_overheads.length; i++) {
            const element = this.state.overheadPDMdata[i];
            // console.log(element, "Test")
            if (element === null) {
                goon = true
                break
            }
        }
        return goon
    }

    preHandleSubmit() {

        var submitTImesheetData = []
        // console.log(i)
        if ((this.state.prep_overheads.length !== 0 && this.checkPDmselct())) {

            this.props.enqueueSnackbar('Fill all the fields to continue!', {
                variant: 'error'
            });

        } else {
            this.setState({ cancel_button: true, openLoading: true })
            // if (i !== 0) {
            //     this.setState({ progress_percen: this.state.progress_percen + this.state.progress_share })
            // } else {
            //     this.setState({ progress_percen: 0, progress_show: true, cancel_button: true })
            // }
            // if (this.state.prep_task.length === i) {
            //     if (this.state.prep_overheads.length === 0) {
            //         this.setState({ isLoading: false, openSub: false, cancel_button: false, progress_show: false, })
            //         this.fetchOps(this.state.recStart, this.state.recStop);
            //     } else {
            //         this.handleOverHeadSubmitSubmit(this.state.overheadPDMdata, this.state.prep_overheads, 0)
            //     }

            // } else {
            //     this.handleSubmit(this.state.prep_task[i].uid_data, this.state.prep_task[i].tasks, i)
            // }
            for (let i = 0; i < this.state.prep_task.length; i++) {
                const element = this.state.prep_task[i];
                submitTImesheetData.push(this.handleSubmit(element.uid_data, element.tasks, i))
            }
            for (let i = 0; i < this.state.prep_overheads.length; i++) {
                // const element = this.state.prep_overheads[i];
                submitTImesheetData.push(this.handleOverHeadSubmitSubmit(this.state.overheadPDMdata, this.state.prep_overheads, i))
            }
            // console.log(submitTImesheetData)
            var submitTImesheetDataFinal = []
            for (let i = 0; i < submitTImesheetData.length; i++) {
                const element = submitTImesheetData[i];
                for (let j = i + 1; j < submitTImesheetData.length; j++) {
                    const element1 = submitTImesheetData[j];
                    if (element && element1 && element.request_to_id === element1.request_to_id) {
                        if (!(element.gname.includes(element1.gname))) {
                            element.gname += "," + element1.gname
                        }
                        // element.timesheet_layout = 1
                        element.list_tasks[1][1] = element.list_tasks[1][1].concat(element1.list_tasks[1][1])
                        delete submitTImesheetData[j]
                    }
                }
                if (element) {
                    element.timesheet_layout = 1
                    submitTImesheetDataFinal.push(element)
                }
            }
            console.log(submitTImesheetDataFinal, this.state.data, this.state.toDia2)
            this.submitAllTimesheets(submitTImesheetDataFinal)
        }
    }

    getWeekPlan() {
        // if (user.office_loc === 1) {
        Service.get(`/bb/api/user/work/plan/user_plan_for_week/?user_id=${currentTimesheetUserId}&date=${moment().format('YYYY-MM-DD')}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

            })
            .catch(error => {
                if (error && error.status === 404) {
                    this.getPlans()
                } else if (error && error.status === 451) {
                    // Skip
                } else {
                    errorMessage(this.props, error)
                    console.log(error)
                }
            })
        // }
    }

    getPlans() {
        Service.put(`/bb/api/user/work/plan/allowed_plan_for_user/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                date: moment().format('YYYY-MM-DD'),
                user_id: currentTimesheetUserId
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.default) {
                        this.setState({ defaultPlan: element })
                        break
                    }
                }
            })
            .catch(e => {
                errorMessage(this.props, e)
                console.log(e)
            })
    }

    agreePlan(plan) {
        Service.put(`/bb/api/user/work/plan/work_plan_change_api/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                user_id: currentTimesheetUserId,
                date: moment().format('YYYY-MM-DD'),
                plan_id: plan.id,
            },
        })
            .then(res => {

            })
            .catch(error => {
                if (error && error.status === 406) {
                    this.props.enqueueSnackbar('Not allowed', {
                        variant: 'warning'
                    });
                } else {
                    errorMessage(this.props, error)
                    console.log(error)
                }
            })
    }

    fetchContractedHrs(uid, start, end) {
        Service.get(`/bb/api/task/approve/timesheet_notification/?uid=${uid}&start=${moment(start).format('YYYY-MM-DD')}&end=${moment(end).format('YYYY-MM-DD')}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.total_hours !== res.data.actual_planned) {
                    this.setState({ alertData: res.data, waitSubmit1: true })
                } else {
                    this.setState({ alertData: null, waitSubmit1: false })
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
                console.log(error)
            })
    }

    handleChange = (e, index) => {
        let data = this.state.toDia2;
        data[1][index]['comments'] = e.target.value;
        this.setState({ toDia2: data }, () => {
            var datas = this.state.data
            var toDia2 = this.state.toDia2
            for (let i = 0; i < datas.length; i++) {
                const element = datas[i];
                for (let j = 0; j < toDia2[1].length; j++) {
                    const element1 = toDia2[1][j];
                    if (element.id === element1.id) {
                        element.comments = element1.comments
                    }
                }
            }
            this.setState({ data: datas })
        });
    }

    render() {

        // const handleDateChange = (date) => {
        //     // console.log(this.state.selectTime1)
        //     this.setState({ selectDate: date });

        //     stweek = moment(date);
        //     endweek = moment(date);

        //     if (stweek.isoWeekday() === 7) {
        //         stweek.add(1, 'days');
        //         endweek.add(1, 'days');
        //     }

        //     this.fetchOps(moment(stweek).startOf('isoWeek'), moment(endweek).endOf('isoWeek'));

        // };

        const handleClose = () => {
            this.setState({ openSub: false })
        };

        // const handleComm = (e) => {
        //     this.setState({ pos1tComment: e.target.value })
        // };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        {!this.state.alertData ? null :
                            <Box display='flex' flexDirection={'column'}>
                                <Box padding={'2px'} style={{ width: '100%' }}>
                                    <Alert severity="warning" action={
                                        <Box display={'flex'} flexDirection='row' >
                                            {this.state.alertData.actual_planned > 0 && !(this.state.is_submit_all && this.state.originalData.length !== 0) && this.state.waitSubmit1 ?
                                                <Button color="inherit" size="small" onClick={() => this.setState({ waitSubmit1: false })}>
                                                    Continue
                                                </Button> : null}
                                            {/* <Button color="inherit" size="small" style={{ marginLeft: '5px' }} onClick={()=>this.props.goback()}>
                                                Go to Calendar
                                            </Button> */}
                                        </Box>

                                    }>
                                        {'You have planned ' + this.state.alertData.actual_planned + ' hours instead of your weekly contracted ' + this.state.alertData.total_hours + ' hours.'}
                                    </Alert>
                                </Box>
                                {this.state.alertData.pending_bank_holiday ?
                                    <Box marginTop='2px' padding={'2px'} style={{ width: '100%' }}>
                                        <Alert severity="info">Please note - you are missing to fill timesheet for bank holiday falls in the particular week. Please put timesheet for bank holiday and submit it
                                        </Alert>
                                    </Box> : null}
                            </Box>}
                        <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper" alignItems={'center'}>
                            <Button
                                color="secondary"
                                startIcon={<ArrowBackIosIcon />}
                                style={{ marginRight: '10px' }}
                                onClick={() => { stweek.subtract(1, 'weeks'); endweek.subtract(1, 'weeks'); this.fetchOps(moment(stweek).startOf('isoWeek'), moment(endweek).endOf('isoWeek')) }}
                            >
                                Prev Week
                            </Button>
                            <Button
                                color="secondary"
                                style={{ marginLeft: '10px', marginRight: '10px' }}
                                endIcon={<ArrowForwardIosIcon />}
                                onClick={() => { stweek.add(1, 'weeks'); endweek.add(1, 'weeks'); this.fetchOps(moment(stweek).startOf('isoWeek'), moment(endweek).endOf('isoWeek')) }}
                            >
                                Next Week
                            </Button>
                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Or select date of a Week"
                                    format="dd/MM/yyyy"
                                    style={{ marginLeft: '10px' }}
                                    value={this.state.selectDate1}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider> */}
                            {(this.state.toDia2[1].length === 0) ? (
                                (!(this.state.is_submit_all && this.state.originalData.length !== 0)) ? (
                                    <ThemeProvider theme={bbColorTheme} ><Typography style={{ marginTop: '25px', marginLeft: '10px', marginRight: '10px' }} color="secondary" >{"No Tasks available to Submit timesheet for " + start_date_text + " - " + end_date_text}</Typography></ThemeProvider>
                                ) : (
                                    <ThemeProvider theme={bbColorTheme} ><Typography style={{ marginTop: '25px', marginLeft: '10px', marginRight: '10px' }} color="primary" >{"Time Sheet submitted for " + start_date_text + " - " + end_date_text}</Typography></ThemeProvider>
                                )

                            ) : (
                                <Box display='flex' flexDirection={'row'} justifyContent="center" alignItems={'center'}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{ marginLeft: '10px', marginRight: '10px' }}
                                        endIcon={<SendIcon />}
                                        disabled={this.state.waitSubmit || this.state.waitSubmit1}
                                        onClick={() => {

                                            this.preSubmitFetch()

                                        }}
                                    >
                                        {((this.state.is_submit && !this.state.is_submit_all) || this.state.is_reject) ? ("Submit TimeSheet [Edited]") : ("Submit TimeSheet")}
                                    </Button>
                                    {this.state.waitSubmit1 ? <Box color='red'>Please read the above alert to enable this submit action</Box> : null}
                                </Box>
                            )}

                        </Box>
                        {
                            (this.state.isLoadingTable) ? (<LinearProgress />) : (
                                <div>
                                    {
                                        this.state.toDia2[1].length > 0 ?
                                            <div>
                                                <ThemeProvider theme={bbColorTheme} >
                                                    <TableContainer component={Paper}>
                                                        <Table aria-label="customized table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {this.state.toDia2[0].map((row) => (
                                                                        <StyledTableCell key={row.title} align="center">{row.title}</StyledTableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {this.state.toDia2[1].map((row, i) => (
                                                                    <StyledTableRow key={row.proj_name}>
                                                                        <StyledTableCell component="th" align="center" scope="row">
                                                                            {row.proj_name ? row.proj_name : row.ttender_name}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {(row.tname ? row.tname : (row.ttdecs ? row.ttdecs : row.ttname))}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {(!row.task_hold && row.task_hold !== 0) ? "-" : row.task_hold ? <h4 style={{ color: 'red' }}>On Hold</h4> : tenderTaskStatus(row.ttstatus)}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            <TextField
                                                                                error={(!row['comments'] && row.comment_mandatory) ? true : false}
                                                                                id="demo-helper-text-aligned-no-helper"
                                                                                style={{ width: '100%' }}
                                                                                margin='dense'
                                                                                value={row['comments']}
                                                                                onChange={(e) => this.handleChange(e, i)}
                                                                                variant='outlined'
                                                                                helperText={(!row['comments'] && row.comment_mandatory) ? "*comment is mandatory for this item" : ""}
                                                                            />

                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row['actual_hours']}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row[this.state.toDia2[0][5].field]}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row[this.state.toDia2[0][6].field]}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row[this.state.toDia2[0][7].field]}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row[this.state.toDia2[0][8].field]}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row[this.state.toDia2[0][9].field]}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row[this.state.toDia2[0][10].field]}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row[this.state.toDia2[0][11].field]}
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>

                                                    </TableContainer>
                                                </ThemeProvider>
                                                {/* <MaterialTable
                                        title="Task Overview"
                                        columns={this.state.toDia2[0]}
                                        data={this.state.toDia2[1]}
                                        isLoading={this.state.isLoadingTable}
                                        options={{
                                            padding: "dense",
                                            filtering: true,
                                            exportButton: { csv: true },
                                            pageSize: this.state.toDia2[1].length < 11 ? this.state.toDia2[1].length : 10,
                                            pageSizeOptions: [10, 20, 50],
                                            // exportPdf: () => alert('This feature is disabled. Data can only be exported as a CSV.')
                                        }}
                                        editable={{
                                            onBulkUpdate: changes =>
                                                new Promise((resolve, reject) => {
                                                    setTimeout(() => {
                                                        resolve();
                                                        const data = this.state.toDia2[1];
                                                        for (let i = 0; i < data.length; i++) {
                                                            if (changes[i.toString()]) {
                                                                // debugger
                                                                const element = changes[i.toString()];
                                                                // if (!element.newData?.quatity) {
                                                                //     element.newData.quatity = 1;
                                                                // }
                                                                data[data.indexOf(element.oldData)] = element.newData;
                                                            }
                                                        }
                                                        this.forceUpdate()
                                                    }, 1000);
                                                }),

                                        }}

                                    /> */}
                                                <ThemeProvider theme={bbColorTheme} >
                                                    <TableContainer style={{ marginTop: '15px' }} component={Paper}>
                                                        <Table aria-label="customized table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {this.state.toDia[0].map((row) => (
                                                                        <StyledTableCell key={row.title} align="center">{row.title}</StyledTableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {this.state.toDia[1].map((row) => (
                                                                    <StyledTableRow key={row.uname}>
                                                                        <StyledTableCell component="th" align="center" scope="row">
                                                                            {row.uname}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row[this.state.toDia[0][1].field]}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row[this.state.toDia[0][2].field]}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row[this.state.toDia[0][3].field]}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row[this.state.toDia[0][4].field]}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row[this.state.toDia[0][5].field]}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row[this.state.toDia[0][6].field]}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row[this.state.toDia[0][7].field]}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row["total"]}
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>

                                                    </TableContainer>
                                                </ThemeProvider>
                                            </div> :
                                            <Box marginTop={"200px"} display={'flex'} flexDirection={'row'} fontSize={18} justifyContent={'center'} width={'100%'}>
                                                No unsubmitted items to display!
                                            </Box>
                                    }
                                </div>
                            )
                        }

                        <Dialog open={this.state.openSub} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Submit your Time Sheet</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Timesheets will be sent for approval to the respective project EM/DDMs.
                                </DialogContentText>
                                <List>
                                    {
                                        this.state.prep_task.map((value) =>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <PersonIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={value.uid_data.last_name + ", " + value.uid_data.first_name}
                                                    secondary={value.proj_tasks_text}
                                                />
                                            </ListItem>
                                        )
                                    }
                                </List>
                                {(this.state.prep_overheads.length !== 0) ? (
                                    <div style={{ width: '100%' }}>

                                        <List>
                                            {
                                                this.state.prep_overheads.map((value, index) =>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <PersonIcon />
                                                        </ListItemIcon>

                                                        <ListItemText
                                                            primary={"Non Billable - " + value[0].gname}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        // className={classes.inline}
                                                                        color="textPrimary"
                                                                    >
                                                                        {this.state.overheads_proj_tasks_text[index]}
                                                                    </Typography>
                                                                    <Autocomplete
                                                                        id={"combo-" + index}
                                                                        fullWidth
                                                                        style={{ marginTop: '15px', width: '350px' }}
                                                                        value={this.state.overheadPDMdata[index]}
                                                                        options={this.state.overheadPdmUsers[index]}
                                                                        getOptionLabel={(option) => option.name}
                                                                        getOptionSelected={(option, value) => value !== '' && option.id === value.id}
                                                                        onChange={(e, v) => this.onTagsChange(e, v, index)}
                                                                        renderInput={(params) => <TextField {...params} label="Request to" variant="outlined" />}
                                                                    />
                                                                </React.Fragment>
                                                            }
                                                        />

                                                        {/* this.state.overheads_proj_tasks_text[index] */}



                                                    </ListItem>
                                                )
                                            }

                                        </List>
                                    </div>
                                ) : (null)}
                            </DialogContent>
                            <DialogActions>
                                {(this.state.progress_show) ? (<CircularProgressWithLabel value={this.state.progress_percen} />) : (null)}
                                <Button disabled={this.state.cancel_button} onClick={handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button disabled={this.state.cancel_button} onClick={e => this.preChangeTaskStatus()} color="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog maxWidth="sm" open={this.state.openLoading} aria-labelledby="form-dialog-title">
                            {/* <DialogTitle id="customized-dialog-title" onClose={handleFeedbackClose}>
                                    Additional Hours Request
                                </DialogTitle> */}
                            <DialogContent>

                                <Box m={5} p={2}>

                                    <BoxLoading />

                                </Box>

                            </DialogContent>

                        </Dialog>

                        <Dialog maxWidth="sm" open={this.state.openLoadingCharge} aria-labelledby="form-dialog-title">
                            {/* <DialogTitle id="customized-dialog-title" onClose={handleFeedbackClose}>
                                    Additional Hours Request
                                </DialogTitle> */}
                            <DialogContent>

                                <Box m={5} p={2}>

                                    <WaveLoading size='large' speed={1} />

                                </Box>

                            </DialogContent>

                        </Dialog>

                    </div >
                )
                }
            </div >

        )
    }
}

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="static" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and static variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

export default withStyles(useStyles)(withSnackbar(WorkHours));