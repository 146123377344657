import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import { errorMessage } from '../Helper/ErrorMessage';
import {
    Button,
    Grid,
    Checkbox,
    TextField,
    Box,
    AppBar,
    Tabs,
    Tab
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { getCookie } from '../Helper/CookieHelper';
import ReactTooltip from 'react-tooltip';
import MaterialTable from 'material-table';
import { LabelSeries, RadialChart } from 'react-vis';
import { getDarkMode, getPrimaryColor } from '../Helper/CommonThings';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

var filterData = []

const DIVERGING_COLOR_SCALE = ["#8bc597", "#009933", "#5097d1", "#8b64a1", "#ffc981", '#ABBF66', "#CC837D", "#d97c85", "#d66afc", "#0785af", "#404d30"]

const LABEL_STYLE = {
    fontSize: '15px',
    textAnchor: 'middle',
    fill: getDarkMode() === 'dark' ? '#fff' : '#000'
};

const token = getCookie("bb_ts_token");

class KPIEVPReport extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            regionData: [],
            clientData: [],
            leadDisciplineData: [
                { 'name': 'OHL', value: 'ohl' },
                { 'name': 'Cabling', value: 'cabling' },
                { 'name': 'Substation', value: 'substation' },
                { 'name': 'Multi Utilities', value: 'multi_utilities' },
                { 'name': 'EDC', value: 'edc' }
            ],
            EMData: [],
            projectsData: [],
            selectedRegion: [],
            selectedClient: [],
            selectedLeadDiscipline: [],
            selectedEm: [],
            viewTabsErrorStatus: [],
            viewTabs: [],
            kpiDashboardData: null,
            openDataView: false,
            tabSelected: 0,
            tableData: [],
            chartData: [{ angle: 1 }],
            isTableLoading: true,
            tooltipNode: null,
            chartData2: [{ angle: 1 }],
        }
    }

    componentDidMount() {
        this.getRegion();
        this.getClient();
        this.getEM();
    }

    getRegion() {
        Service.get2('/bb/api/region/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({ regionData: res.data })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    getClient() {
        Service.get2('/bb/api/clients/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ clientData: res.data, })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    // getLeadDiscipline(){
    //     Service.get2('/bb/api/roles/', {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res => {
    //             this.setState({ leadDisciplineData: res.data, })
    //         })
    //         .catch(error => {
    //            errorMessage(this.props, error)
    //         });
    // }

    getEM() {
        Service.get2('/bb/api/users/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ EMData: res.data, isLoading: false })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    filterChange(name, value) {
        this.setState({
            [name]: value
        })
    }

    getReport() {
        // console.log(this.state.selectedClient)
        var url = "/bb/api/report/kpi/kpi_report/"
        var url1 = '/bb/api/report/kpi/kpi_view_data/'
        var firstParam = true
        var clientParam = "client=0"
        var clientParamExist = false
        var emParam = "em=0"
        var emParamExist = false
        var regionParam = "region=0"
        var regionParamExist = false
        var disciplineParam = "discipline=0"
        var disciplineParamExist = false
        for (let i = 0; i < this.state.selectedClient.length; i++) {
            const element = this.state.selectedClient[i];
            clientParamExist = true
            clientParam += "," + element.id
        }
        for (let i = 0; i < this.state.selectedEm.length; i++) {
            const element = this.state.selectedEm[i];
            emParamExist = true
            emParam += "," + element.id
        }
        for (let i = 0; i < this.state.selectedRegion.length; i++) {
            const element = this.state.selectedRegion[i];
            regionParamExist = true
            regionParam += "," + element.id
        }
        for (let i = 0; i < this.state.selectedLeadDiscipline.length; i++) {
            const element = this.state.selectedLeadDiscipline[i];
            disciplineParamExist = true
            disciplineParam += "," + element.value
        }

        if (clientParamExist) {
            if (firstParam) {
                firstParam = false
                url += "?" + clientParam
                url1 += "?" + clientParam
            } else {
                url += "&" + clientParam
                url1 += "&" + clientParam
            }
        }
        if (emParamExist) {
            if (firstParam) {
                firstParam = false
                url += "?" + emParam
                url1 += "?" + emParam
            } else {
                url += "&" + emParam
                url1 += "&" + emParam
            }
        }
        if (regionParamExist) {
            if (firstParam) {
                firstParam = false
                url += "?" + regionParam
                url1 += "?" + regionParam
            } else {
                url += "&" + regionParam
                url1 += "&" + regionParam
            }
        }
        if (disciplineParamExist) {
            if (firstParam) {
                firstParam = false
                url += "?" + disciplineParam
                url1 += "?" + disciplineParam
            } else {
                url += "&" + disciplineParam
                url1 += "&" + disciplineParam
            }
        }

        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    kpiDashboardData: res.data
                })
                var currentTabData = this.processData(res.data)

                Service.get2(url1, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res1 => {
                        // console.log(res.data)
                        var tableData = []
                        for (let i = 0; i < res1.data.length; i++) {
                            const element = res1.data[i];
                            if (element.error === currentTabData.currentErrorCode && element.spi >= 0.9) {
                                tableData.push(element)
                            }
                        }

                        for (let i = 0; i < res1.data.length; i++) {
                            const element = res1.data[i];
                            var contPreParam = false
                            if (res.data.project_contain_preparam.length) {
                                for (let j = 0; j < res.data.project_contain_preparam.length; j++) {
                                    const element1 = res.data.project_contain_preparam[j];
                                    if (element.tender === element1.tenderid) {
                                        contPreParam = true;
                                        if (element.spi < res.data.spi_threshold) {
                                            if (currentTabData.radialChartData2[0].spi) {
                                                currentTabData.radialChartData2[0].spi += 1
                                            } else {
                                                currentTabData.radialChartData2[0].spi = 1
                                            }
                                        }

                                    }
                                }
                            }
                            if (element.spi < res.data.spi_threshold) {
                                if (!contPreParam) {
                                    if (currentTabData.radialChartData2[1].spi) {
                                        currentTabData.radialChartData2[1].spi += 1
                                    } else {
                                        currentTabData.radialChartData2[1].spi = 1
                                    }
                                }
                            }

                        }
                        // console.log(currentTabData.radialChartData2)
                        this.setState({
                            kpiViewData: res1.data,
                            tableData: tableData,
                            isTableLoading: false,
                            chartData2: currentTabData.radialChartData2,
                        })
                    })
                    .catch(error => {
                        // console.log(error)
                        errorMessage(this.props, error)

                    });
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)

            });


    }

    processData(kpiData) {
        // var chartData = {
        //     title: 'KPI',
        //     color: DIVERGING_COLOR_SCALE[0],
        //     children: [
        //         {
        //             name: "Perfect EVP (" + kpiData.perfect_evp + ")",
        //             size: kpiData.perfect_evp,
        //             color: DIVERGING_COLOR_SCALE[1],
        //             children: [
        //                 {
        //                     name: "SPI threshold (" + kpiData.perfect_evp_below_spi_threshold + ")",
        //                     size: kpiData.perfect_evp_below_spi_threshold,
        //                     color: DIVERGING_COLOR_SCALE[1],
        //                 }
        //             ]
        //         },
        //         {
        //             name: "Date unavailable (" + kpiData.evp_with_date_issue + ")",
        //             size: kpiData.evp_with_date_issue,
        //             color: DIVERGING_COLOR_SCALE[2],
        //             children: [
        //                 {
        //                     name: "SPI threshold (" + kpiData.evp_with_date_issue_below_spi_threshold + ")",
        //                     size: kpiData.evp_with_date_issue_below_spi_threshold,
        //                     color: DIVERGING_COLOR_SCALE[2],
        //                 }
        //             ]
        //         },
        //         {
        //             name: "Calculation Error (" + kpiData.evp_with_calc_issue + ")",
        //             size: kpiData.evp_with_calc_issue,
        //             color: DIVERGING_COLOR_SCALE[3],
        //             children: [
        //                 {
        //                     name: "SPI threshold (" + kpiData.evp_with_calc_issue_below_spi_threshold + ")",
        //                     size: kpiData.evp_with_calc_issue_below_spi_threshold,
        //                     color: DIVERGING_COLOR_SCALE[3],
        //                 }
        //             ]
        //         },
        //         {
        //             name: "Wrong Dates (" + kpiData.evp_with_date_calc_issue + ")",
        //             size: kpiData.evp_with_date_calc_issue,
        //             color: DIVERGING_COLOR_SCALE[4],
        //             children: [
        //                 {
        //                     name: "SPI threshold (" + kpiData.evp_with_date_calc_issue_below_spi_threshold + ")",
        //                     size: kpiData.evp_with_date_calc_issue_below_spi_threshold,
        //                     color: DIVERGING_COLOR_SCALE[4],
        //                 }
        //             ]
        //         },
        //         {
        //             name: "Unknown ERROR (" + kpiData.unknown_issue + ")",
        //             size: kpiData.unknown_issue,
        //             color: DIVERGING_COLOR_SCALE[5],

        //         }
        //     ]
        // }
        var viewTabs = []
        var radialChartData = []
        var radialChartData2 = []
        var viewTabsErrorStatus = []

        if (kpiData.perfect_evp) {
            viewTabs.push("SPI above 0.9 (" + kpiData.perfect_evp_above_spi_threshold + ")")
            viewTabsErrorStatus.push(0)
            radialChartData.push({
                angle: kpiData.perfect_evp_above_spi_threshold,
                label: "SPI above 0.9 (" + kpiData.perfect_evp_above_spi_threshold + ")",
                color: DIVERGING_COLOR_SCALE[1],
                definition: "No errors reported in the dates, % completes, SPI calculations",
                
                // radius: kpiData.perfect_evp
            })
        }
        if (kpiData.perfect_evp) {
            viewTabs.push("SPI below 0.9 (" + ( kpiData.perfect_evp_below_spi_threshold) + ")")
            viewTabsErrorStatus.push(0)
            radialChartData.push({
                angle: kpiData.perfect_evp_below_spi_threshold,
                label: "SPI below 0.9 (" + (kpiData.perfect_evp_below_spi_threshold) + ")",
                color: DIVERGING_COLOR_SCALE[4],
                definition: "No errors reported in the dates, % completes, SPI calculations",
                
                // radius: kpiData.perfect_evp
            })
        }
        if (kpiData.evp_with_date_issue) {
            viewTabs.push("Date unavailable (" + kpiData.evp_with_date_issue + ")")
            viewTabsErrorStatus.push(1)
            radialChartData.push({
                angle: kpiData.evp_with_date_issue,
                label: "Date unavailable (" + kpiData.evp_with_date_issue + ")",
                color: DIVERGING_COLOR_SCALE[2],
                definition: "Either Baseline or Actual/Expected dates are not available. Run the EVP curve, to know the list of tasks",
                spi: kpiData.evp_with_date_issue_below_spi_threshold,
                // radius: kpiData.evp_with_date_issue
            })
        }
        if (kpiData.evp_with_calc_issue) {
            viewTabs.push("Calculation Error (" + kpiData.evp_with_calc_issue + ")")
            viewTabsErrorStatus.push(2)
            radialChartData.push({
                angle: kpiData.evp_with_calc_issue,
                label: "Calculation Error (" + kpiData.evp_with_calc_issue + ")",
                color: DIVERGING_COLOR_SCALE[3],
                definition: "Contact: support@bbparam.com",
                spi: kpiData.evp_with_calc_issue_below_spi_threshold,
                // radius: kpiData.evp_with_calc_issue
            })
        }
        if (kpiData.evp_with_date_calc_issue) {
            viewTabs.push("Wrong Dates (" + kpiData.evp_with_date_calc_issue + ")")
            viewTabsErrorStatus.push(3)
            radialChartData.push({
                angle: kpiData.evp_with_date_calc_issue,
                label: "Wrong Dates (" + kpiData.evp_with_date_calc_issue + ")",
                color: DIVERGING_COLOR_SCALE[5],
                definition: "Actual Start date is incorrectly updated when compared with the actual start of timesheet information for few tasks",
                spi: kpiData.evp_with_date_calc_issue_below_spi_threshold,
                // radius: kpiData.evp_with_date_calc_issue
            })
        }
        if (kpiData.unknown_issue) {
            viewTabs.push("Unknown ERROR (" + kpiData.unknown_issue + ")")
            viewTabsErrorStatus.push(4)
            radialChartData.push({
                angle: kpiData.unknown_issue,
                label: "Unknown ERROR (" + kpiData.unknown_issue + ")",
                color: DIVERGING_COLOR_SCALE[6],
                definition: "None of the above. We will look into the issue.",
                spi: null,
                // radius: kpiData.unknown_issue
            })
        }
        if (kpiData.project_contain_preparam.length) {
            viewTabs.push("Legacy Project (" + kpiData.project_contain_preparam.length + ")")
            viewTabsErrorStatus.push(5)
            radialChartData2.push({
                angle: kpiData.project_contain_preparam.length,
                label: "Legacy Project (" + kpiData.project_contain_preparam.length + ")",
                color: DIVERGING_COLOR_SCALE[4],
                definition: "Projects containing Pre-Param data",
                spi: null,
                // radius: kpiData.unknown_issue
            })
        }
        if (kpiData.total) {
            radialChartData2.push({
                angle: kpiData.total - kpiData.project_contain_preparam.length,
                label: "Non-Legacy Project (" + (kpiData.total - kpiData.project_contain_preparam.length) + ")",
                color: DIVERGING_COLOR_SCALE[1],
                definition: "Projects don't contain Pre-Param data",
                spi: null,
                // radius: kpiData.unknown_issue
            })
        }

        this.setState({
            // data: chartData,
            viewTabs: viewTabs,
            viewTabsErrorStatus: viewTabsErrorStatus,
            chartData: radialChartData,
            chartData2: radialChartData2,
        })

        return {
            currentErrorCode: viewTabsErrorStatus[0],
            radialChartData2: radialChartData2,
        }

    }

    findFilterValue(field) {
        for (let i = 0; i < filterData.length; i++) {
            const element = filterData[i];
            // console.log(field === element.column.field)
            if (field === element.column.field) {
                return element.value
            }
        }
        return null
    }

    render() {

        const handleTabChange = (event, newValue) => {
            var tableData = []
            if (this.state.viewTabsErrorStatus[newValue] === 5) {

                for (let i = 0; i < this.state.kpiDashboardData.project_contain_preparam.length; i++) {
                    const element = this.state.kpiDashboardData.project_contain_preparam[i];
                    for (let j = 0; j < this.state.kpiViewData.length; j++) {
                        const element1 = this.state.kpiViewData[j];
                        if (element.tenderid === element1.tender) {
                            tableData.push(element1)
                        }
                    }

                }
                this.setState({
                    tabSelected: newValue,
                    tableData: tableData
                })

                
            } else if (this.state.viewTabsErrorStatus[newValue] === 0) {
                for (let i = 0; i < this.state.kpiViewData.length; i++) {
                    const element = this.state.kpiViewData[i];
                    if (element.error === 0 && newValue === 0 && element.spi >= 0.9) {
                        tableData.push(element)
                    } else if (element.error === 0 && newValue === 1 && element.spi < 0.9) {
                        tableData.push(element)
                    }
                }
                this.setState({
                    tabSelected: newValue,
                    tableData: tableData
                })
            } else {
                for (let i = 0; i < this.state.kpiViewData.length; i++) {
                    const element = this.state.kpiViewData[i];
                    if (element.error === this.state.viewTabsErrorStatus[newValue]) {
                        tableData.push(element)
                    }
                }
                this.setState({
                    tabSelected: newValue,
                    tableData: tableData
                })
                
            }


        };

        return (
            <div>
                {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                    <Grid container style={{ marginBottom: '20px' }} spacing={2}>
                        <Grid item xs={3}>
                            <Autocomplete
                                multiple
                                id="region"
                                options={this.state.regionData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.filterChange('selectedRegion', value)}
                                value={this.state.selectedRegion}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Region" placeholder="Region" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                multiple
                                id="client"
                                options={this.state.clientData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.filterChange('selectedClient', value)}
                                value={this.state.selectedClient}
                                size="small"
                                getOptionSelected={(option, value) => {
                                    if (option) {
                                        return (option.id === value.id);
                                    }
                                    return false;
                                }}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Client" placeholder="Client" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                multiple
                                id="lead-discipline"
                                options={this.state.leadDisciplineData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.filterChange('selectedLeadDiscipline', value)}
                                value={this.state.selectedLeadDiscipline}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Lead Discipline" placeholder="Lead Discipline" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                multiple
                                id="em"
                                options={this.state.EMData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => (option.last_name + ", " + option.first_name)}
                                onChange={(e, value) => this.filterChange('selectedEm', value)}
                                value={this.state.selectedEm}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {(option.last_name + ', ' + option.first_name)}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="EM" placeholder="EM" />
                                )}
                            />
                        </Grid>

                        <Grid item xs={2} >
                            <Button
                                variant="contained"
                                // size="medium"
                                color="primary"
                                onClick={() => this.getReport()}
                            >Get Report</Button>
                        </Grid>

                    </Grid>
                }
                {this.state.kpiDashboardData ?
                    <div>
                        <Box
                            sx={{
                                display: 'flex',
                                bgcolor: 'background.paper',
                            }}
                        >
                            <Box sx={{ bgcolor: 'grey.300' }}>
                                <div data-tip data-for='happyFace'>
                                    <RadialChart
                                        data-tip data-for='happyFace'
                                        animation
                                        data={this.state.chartData}
                                        width={300}
                                        height={300}
                                        showLabels={true}
                                        colorType="literal"
                                        innerRadius={25}
                                        radius={135}
                                        labelsStyle={{
                                            fontSize: 11,
                                            fontWeight: "bold",
                                            fill: getDarkMode() === 'dark' ? '#fff' : '#000'
                                            // letterSpacing: 0.4
                                        }}
                                        onValueMouseOver={node => {

                                            this.setState({

                                                tooltipNode: node,
                                                //   data: updateData(this.state.data, pathAsMap)
                                            });
                                        }}
                                    >
                                        <LabelSeries
                                            data-tip data-for='happyFace'
                                            data={[{ x: 0, y: -.075, label: 'EVP', style: LABEL_STYLE }]}
                                        />

                                    </RadialChart>
                                </div>
                                <div data-tip data-for='happyFace'>
                                    <RadialChart
                                        data-tip data-for='happyFace'
                                        animation
                                        data={this.state.chartData2}
                                        width={300}
                                        height={300}
                                        showLabels={true}
                                        colorType="literal"
                                        innerRadius={35}
                                        radius={135}
                                        labelsStyle={{
                                            fontSize: 11,
                                            fontWeight: "bold",
                                            fill: getDarkMode() === 'dark' ? '#fff' : '#000'
                                            // letterSpacing: 0.4
                                        }}
                                        onValueMouseOver={node => {

                                            this.setState({

                                                tooltipNode: node,
                                                //   data: updateData(this.state.data, pathAsMap)
                                            });
                                        }}
                                    >
                                        <LabelSeries
                                            data-tip data-for='happyFace'
                                            data={[{ x: 0, y: -.075, label: 'Legacy', style: LABEL_STYLE }]}
                                        />

                                    </RadialChart>
                                </div>
                                {this.state.tooltipNode ?
                                    <ReactTooltip id='happyFace' type='error'>
                                        <Box display="flex" flexDirection="row" >
                                            <Box fontWeight="fontWeightBold">{this.state.tooltipNode.label}</Box>
                                        </Box>
                                        {(this.state.tooltipNode.spi === null) ? (null) : (
                                            <Box display="flex" flexDirection="row" >
                                                <Box fontStyle="italic">{"Projects in SPI threshold: " + this.state.tooltipNode.spi}</Box>
                                            </Box>
                                        )}

                                        <Box display="flex" sx={{ maxWidth: 300 }} m={.5} flexDirection="row" >
                                            <Box flexWrap="wrap" >
                                                {this.state.tooltipNode.definition}
                                            </Box>
                                        </Box>

                                    </ReactTooltip>
                                    : null}
                            </Box>
                            <Box sx={{ p: 1, bgcolor: 'grey.300', flexGrow: 1 }}>
                                <AppBar position="static">
                                    <Tabs variant="scrollable" scrollButtons="auto" value={this.state.tabSelected} onChange={handleTabChange} aria-label="simple tabs example">
                                        {this.state.viewTabs.map((text, index) => (
                                            <Tab label={text} {...a11yProps(index)} />
                                        ))}


                                    </Tabs>
                                </AppBar>

                                <MaterialTable
                                    // style={{ minWidth: this.state.tableWidth }}
                                    columns={[
                                        {
                                            title: 'Discipline', field: 'tender_data.tenderbid_data.lead_dicipline',
                                            headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                            // defaultFilter: this.findFilterValue("tenderbid_data.lead_dicipline"),
                                            lookup: { 'ohl': 'OHL', 'cabling': 'Cabling', 'substation': 'Substation', 'multi_utilities': 'Multi Utilities', 'edc': 'EDC' }
                                        },
                                        {
                                            title: 'Project', field: 'tender_data.tender_name',
                                            // defaultFilter: this.findFilterValue("tender_name"), 
                                            headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 },
                                        },
                                        {
                                            title: 'EM', field: 'tender_data.pdm',
                                            customFilterAndSearch: (term, rowData) => {
                                                return ((rowData.tender_data.tenderbid_data && rowData.tender_data.tenderbid_data.pdm_data) ? (rowData.tender_data.tenderbid_data.pdm_data.last_name + ", " + rowData.tender_data.tenderbid_data.pdm_data.first_name) : (null)).toLowerCase().includes(term.toLowerCase())
                                            },
                                            // defaultFilter: this.findFilterValue("tender_data.pdm"),
                                            render: rowData => (rowData.tender_data.tenderbid_data && rowData.tender_data.tenderbid_data.pdm_data) ? (rowData.tender_data.tenderbid_data.pdm_data.last_name + ", " + rowData.tender_data.tenderbid_data.pdm_data.first_name) : (null)
                                        },
                                        {
                                            title: 'Client', field: 'tender_data.tenderbid_data.client_name',
                                            // defaultFilter: this.findFilterValue("tender_data.tenderbid_data.client_name"),
                                            // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                        },
                                        {
                                            title: 'Region', field: 'tender_data.tenderbid_data.region_name',
                                            // defaultFilter: this.findFilterValue("tender_data.tenderbid_data.region_name"),
                                            // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                        },
                                        {
                                            title: '% updated on', field: 'last_percentage_complete_on', type: 'date'
                                            // defaultFilter: this.findFilterValue("tenderbid_data.region_name"),
                                            // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                        },
                                        {
                                            title: 'SPI', field: 'spi',
                                            // defaultFilter: this.findFilterValue("tenderbid_data.region_name"),
                                            // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                        },
                                        {
                                            title: 'CPI', field: 'cpi',
                                            // defaultFilter: this.findFilterValue("tenderbid_data.region_name"),
                                            // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                        },

                                    ]}
                                    isLoading={this.state.isTableLoading}
                                    data={this.state.tableData}
                                    options={{
                                        paging: false,
                                        padding: "dense",
                                        filtering: true,
                                        doubleHorizontalScroll: true,

                                        toolbar: false
                                    }}
                                    onFilterChange={(filter) => { filterData = filter }}
                                    actions={[

                                        rowData => ({
                                            icon: 'equalizer',
                                            tooltip: "EVP",
                                            hidden: !((rowData.evp && ((rowData.tenderbid_data.pdm === user.id && (access && access.access && access.access.l4a.l4a_x)) || (access && access.access && access.access.l4a.l4a_y))) || (access && access.access && access.access.l4a.l4a_z)),
                                            onClick: (event, rowData) => { window.open("/report/evp/" + rowData.tender_data.id) },
                                        }),


                                    ]}
                                />
                            </Box>

                        </Box>




                    </div>
                    : null}
            </div>
        )
    }
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default withSnackbar(KPIEVPReport);