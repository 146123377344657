import React, { Component } from 'react'
import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import Service from "../../Networking/networkutils";
import WBSRolesTemplate from './WBSRolesTemplate';
import { Typography } from '@material-ui/core';
import { getCookie } from '../../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
class WBSTemplate extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: this.props.value.tasks,
            openAdd: false,
            projName: '',
            projId: '',
            isLoading: false,
            save: true,
        }
    }

    giveaccess(putData) {

        var pageView = window.location.pathname + "/#Deliverables Template";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        this.setState({ isLoading: true, });

        var projData = this.props.value;
        projData.tasks = putData;

        // console.log(projData)

        Service.put("/bb/api/tender/template/ohlwbs/" + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
            data: projData,
        })
            .then(res => {
                // console.log(res.data)
                this.props.enqueueSnackbar('Saved successfully', {
                    variant: 'success'
                });
                this.setState({ isLoading: false })
            })
            .catch(error => {
                // console.log(error)
               errorMessage(this.props, error)
                this.setState({ isLoading: false })
            });
    }

    numOff(data1) {
        try {
            return data1.roles.length;
        } catch (error) {
            return 0;
        }
    }

    render() {


        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                        <div>
                            {/* <Box display="flex" flexDirection="row-reverse" p={1} m={1} bgcolor="background.paper">
                                <Button variant="contained" color="secondary" disabled = {this.state.save} onClick={e => { this.giveaccess(this.state.data) }} style={{ marginBottom: '10px' }}>Save</Button>
                            </Box> */}
                            <MaterialTable
                                title="Manage WBS Tasks"
                                columns={[
                                    { title: 'Task', field: 'name', },
                                    {
                                        title: 'Number of Roles', field: 'no_of_roles', editable: 'never',
                                        render: rowData => <Typography>{this.numOff(rowData)}</Typography>
                                    },
                                ]}
                                data={this.state.data}
                                options={{
                                    padding: "dense",
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 50],
                                    actionsColumnIndex: -1
                                }}
                                detailPanel={rowData => {
                                    if (!rowData.roles) {
                                        rowData.roles = [];
                                    }
                                    return (
                                        <WBSRolesTemplate value={rowData} onSaveReq={e => { rowData.roles = e; this.giveaccess(this.state.data) }} roles={rowData.roles} />
                                    )
                                }}
                                actions={[
                                    {
                                        icon: 'save',
                                        tooltip: 'Save Data',
                                        isFreeAction: true,
                                        disabled: this.state.save,
                                        onClick: () => { this.giveaccess(this.state.data) },
                                    },

                                ]}
                                editable={((access && access.access && access.access.l9a.l9a_b) && (access && access.access && !access.access.l9a.l9a_c)) ? ({
                                    onRowAdd: newData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState({save: false});
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    newData.roles = [];
                                                    data.push(newData);
                                                    return { ...prevState, data };
                                                });
                                            }, 60);
                                        }),
                                    
                                }) : ((access && access.access && !access.access.l9a.l9a_b) && (access && access.access && access.access.l9a.l9a_c)) ? ({
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    this.setState({save: false});
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        return { ...prevState, data };
                                                    });
                                                }
                                            }, 60);
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState({save: false});
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    
                                                    return { ...prevState, data };
                                                });
                                            }, 60);
                                        }),
                                }) : ((access && access.access && access.access.l9a.l9a_b) && (access && access.access && access.access.l9a.l9a_c)) ? ({
                                    onRowAdd: newData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState({save: false});
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    newData.roles = [];
                                                    data.push(newData);
                                                    return { ...prevState, data };
                                                });
                                            }, 60);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    this.setState({save: false});
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        return { ...prevState, data };
                                                    });
                                                }
                                            }, 60);
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState({save: false});
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    
                                                    return { ...prevState, data };
                                                });
                                            }, 60);
                                        }),
                                }) : (null)}
                            />

                        </div>
                    )}

            </div>
        )
    }
}

export default withSnackbar(WBSTemplate);