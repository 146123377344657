import React, { Component } from 'react'
import { Box, Button, TextField } from '@material-ui/core'

class SearchKeyEditor extends Component {

    constructor(props) {
        super(props)

        this.state = {
            search_key: props.value.search_key
        }
    }

    handleDataChange(e) {
        if (e.target.id === 'searchkey') {
            this.setState({ search_key: e.target.value })
        }
    }

    updateData() {
        var data = this.props.value
        data.search_key = this.state.search_key
        this.props.updateData(data)
    }

    render() {
        return (
            <div style={{ margin: '10px' }}>
                <Box display='flex' justifyContent='center' >
                    <TextField
                        variant='standard'
                        id="searchkey"
                        label="Search Key"
                        style={{ width: '100%' }}
                        value={this.state.search_key}
                        onChange={e => this.handleDataChange(e)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button onClick={() => this.updateData()}>Save</Button>
                </Box>
            </div>
        )
    }
}

export default SearchKeyEditor