import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { Button, } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
// import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Moment from 'react-moment';
import 'moment-timezone';
import { LoopCircleLoading } from 'react-loadingg';
import GroupAccess from './GroupAccess';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import { getPrimaryColor } from '../Helper/CommonThings';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));
var dialogmsg = "";
var submitdata = null;
class TeamManage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            tabledata: [],
            openCreate: false,
            openAlert: false,
            teamname: '',
            teamdecs: '',
            disablecreate: true,
            isLoading: true,
        }
    }


    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        localStorage.removeItem("staff_to_see")
        // console.log((!(access && access.access && access.access.l13a && access.access.l13a.l13a_b)))
        var url = "/bb/api/team/access/"
        if (!(access && access.access && access.access.l13a && access.access.l13a.l13a_b)) {
            url = url + "?uid=" + user.id + "&team_role=1";
            Service.get(url, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {

                    var ids = 0;

                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        if (i === 0) {
                            ids = element.gid;
                        } else {
                            ids = ids + "," + element.gid;
                        }
                    }

                    Service.get("/bb/api/teams/?id=" + ids, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res => {
                            var toTab = [];
                            var toTab1 = [];
                            for (let i = 0; i < res.data.length; i++) {
                                const element = res.data[i];
                                if (element.is_active) {
                                    toTab.push(element);
                                }
                                if (!element.is_active) {
                                    toTab1.push(element);
                                }

                            }
                            this.setState({
                                data: res.data,
                                tabledata: toTab.concat(toTab1),
                                isLoading: false,
                            });
                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                        });

                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        } else {
            Service.get("/bb/api/teams/", {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    // var toTab = [];
                    // var toTab1 = [];

                    this.setState({
                        data: res.data,
                        tabledata: res.data,
                        isLoading: false,
                    });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    postData() {

        var usecr = '';
        var unique = true;
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if (element.name === this.state.teamname) {
                unique = false;
                usecr = element.created_by;
            }
        }

        if (unique) {
            this.setState({
                openCreate: false,
                isLoading: true,
            });
            var json = {
                name: this.state.teamname,
                decs: this.state.teamdecs,
                team_role: 1,
                created_by: user.email,
            }

            Service.post("/bb/api/teams/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: json,
            })
                .then(res => {
                    // console.log(res.data)
                    // this.props.enqueueSnackbar('Group Created', {
                    //     variant: 'success'
                    // });
                    // this.fetchOps();

                    var post2 = {
                        "name": user.first_name + " " + user.last_name,
                        "email": user.email,
                        "gid": res.data.id,
                        "uid": user.id,
                        "created_by": user.email
                    }

                    Service.post("/bb/api/team/access/", {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: post2,
                    })
                        .then(res => {
                            // console.log(res.data)
                            this.props.enqueueSnackbar('Group Created', {
                                variant: 'success'
                            });
                            this.fetchOps();
                            this.setState({
                                openCreate: false,
                                isLoading: false,
                            });

                        })
                        .catch(error => {
                            // console.log(error)
                            errorMessage(this.props, error)
                        });

                })
                .catch(error => {
                    // console.log(error)
                    errorMessage(this.props, error)
                });
        } else {
            this.props.enqueueSnackbar('Team with Group name ' + this.state.teamname + ' already created by ' + usecr, {
                variant: 'error'
            });
        }

    }

    giveaccess() {

        this.setState({
            isLoading: true,
        });

        var json = {
            is_active: !submitdata.is_active,
        }

        Service.patch("/bb/api/teams/" + submitdata.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: json,
        })
            .then(res => {

                this.setState({ openAlert: false, });
                // console.log(res.data)
                this.props.enqueueSnackbar('Status changed for ' + submitdata.name, {
                    variant: 'success'
                });
                this.fetchOps();
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    putData(data) {
        data.email_rem = parseInt(data.email_rem)
        Service.put("/bb/api/teams/" + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        })
            .then(res => {
                this.props.enqueueSnackbar('Data successfully updated!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
                this.fetchOps();
            });
    }

    handleDialog(type, data) {
        submitdata = data;
        if (type) {
            dialogmsg = "Do you want to activate " + data.name;
        } else {
            dialogmsg = "Do you want to deactivate " + data.name;
        }
        this.setState({ openAlert: true, });
    }

    handleText(e) {
        if (e.target.id === 'name') {
            this.setState({ teamname: e.target.value, });

        } if (e.target.id === 'decs') {
            this.setState({ teamdecs: e.target.value, });
        }
    }

    render() {

        const handleClose = () => {
            this.setState({ openAlert: false, openCreate: false, });
        };

        const handleCreate = () => {
            this.postData();
        };

        const handleCreateDialog = () => {
            this.setState({ openCreate: true, });
        };
       
        return (
            <div>
                {
                    (this.state.isLoading) ? (
                        <LoopCircleLoading color={getPrimaryColor()} />
                    ) : (
                        <div>
                            {/* {(access && access.access && access.access.l13a && access.access.l13a.l13a_b) ? (
                                    <Box display="flex" flexDirection="row-reverse" p={1} m={1} bgcolor="background.paper">
                                        <Button variant="contained" onClick={handleCreateDialog} color="primary" style={{ marginBottom: '10px' }}>Create Team</Button>
                                    </Box>
                                ) : (null)} */}
                            {/* <Box display="flex" flexDirection="row-reverse" p={1} m={1} bgcolor="background.paper">
                                    <Button variant="contained" onClick={handleCreateDialog} color="primary" style={{ marginBottom: '10px' }}>Create Team</Button>
                                </Box> */}
                            <MaterialTable
                                title="Manage Teams"
                                columns={[
                                    { title: 'Name', field: 'name', editable: false, },
                                    { title: 'Description', field: 'decs', editable: false, },
                                    // { title: 'Created By', field: 'created_by' },
                                    {
                                        title: 'Reminder Email', field: 'email_rem',
                                        lookup: { 0: 'No Email', 1: 'Weekly Email Reminder', },
                                    },
                                    {
                                        field: 'date_created',
                                        title: 'Date Created',
                                        editable: false,
                                        render: rowData => <Moment local format="YYYY/MM/DD hh:mm A">{rowData.date_created}</Moment>
                                    }
                                ]}
                                data={this.state.tabledata}
                                actions={(access && access.access && access.access.l13a && access.access.l13a.l13a_c) ? ([
                                    rowData => ({
                                        icon: 'play_circle_filled',
                                        tooltip: 'Activate',
                                        onClick: (event, rowData) => this.handleDialog(true, rowData),
                                        disabled: rowData.is_active
                                    }),
                                    rowData => ({
                                        icon: 'stop',
                                        tooltip: 'Deactivate',
                                        onClick: (event, rowData) => this.handleDialog(false, rowData),
                                        disabled: !rowData.is_active
                                    }),
                                    (access && access.access && access.access.l13a && access.access.l13a.l13a_b) ? ({
                                        icon: 'add_box',
                                        tooltip: 'Create Team',
                                        isFreeAction: true,
                                        onClick: handleCreateDialog
                                    }) : (null),
                                ]) : (null)}
                                options={{
                                    padding: "dense",
                                    actionsColumnIndex: -1,
                                    paging: false,
                                }}
                                editable={(access && access.access && access.access.l13a && access.access.l13a.l13a_b) ? ({

                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    // this.setState({ save: false });
                                                    this.setState(prevState => {
                                                        const tabledata = [...prevState.tabledata];
                                                        tabledata[tabledata.indexOf(oldData)] = newData;
                                                        this.putData(newData);
                                                        return { ...prevState, tabledata };
                                                    });
                                                }
                                            }, 60);
                                            // this.updateAccess(oldData, newData);
                                        }),

                                }) : (null)}
                                detailPanel={rowData => {
                                    return (
                                        <GroupAccess value={rowData} />
                                    )
                                }}
                            />
                        </div>
                    )
                }

                <Dialog
                    open={this.state.openCreate}
                    TransitionComponent={Transition}
                    keepMounted
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Create Team"}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Team Name"
                            type="text"
                            variant="outlined"
                            value={this.state.teamname}
                            onChange={e => this.handleText(e)}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="decs"
                            label="Description"
                            type="text"
                            variant="outlined"
                            value={this.state.teamdecs}
                            onChange={e => this.handleText(e)}
                            multiline
                            rows="5"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button color="primary" onClick={handleCreate}>
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openAlert}
                    TransitionComponent={Transition}
                    keepMounted
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Are you sure?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {dialogmsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            No
                        </Button>
                        <Button color="primary" onClick={e => this.giveaccess()}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(TeamManage);