import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
// import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import MaterialTable from 'material-table'
import moment from 'moment';

const token = getCookie("bb_ts_token");
// const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class FutureRequirement extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.fetchData(this.props.futureRequirement)
    }

    fetchData(futureRequirement) {
        var data = []
        for (let i = 0; i < futureRequirement.length; i++) {
            const element = futureRequirement[i];
            if (element.gid === this.props.team.id && element.id !== null) {
                element.date_of_req_date_format = moment(element.date_of_req, 'YYYY-MM-DD').toDate()
                data.push(element)
            }
        }
        this.setState({ data: data })
    }

    postData(postData) {
        Service.post('/bb/api/budget/future/requirement/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: postData,
        })
            .then(res => {
                this.props.enqueueSnackbar('Designation added successfully', {
                    variant: 'success'
                });
               
                var temp = this.props.futureRequirement
                temp.push(res.data)

                this.fetchData(temp)
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    putData(putData) {
        Service.put('/bb/api/budget/future/requirement/' + putData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: putData,
        })
            .then(res => {
                for (let i = 0; i < this.props.futureRequirement.length; i++) {
                    var element = this.props.futureRequirement[i];
                    if (element.id === res.data.id) {
                        element.role = res.data.role
                        element.role_data = res.data.role_data
                        element.date_of_req = res.data.date_of_req
                        element.skills_needed = res.data.skills_needed
                        break
                    }
                }
                this.props.enqueueSnackbar('Changes Saved', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    deleteData(deleteData) {
        Service.delete('/bb/api/budget/future/requirement/' + deleteData.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            
        })
            .then(res => {
                
                for (let i = 0; i < this.props.futureRequirement.length; i++) {
                    var element = this.props.futureRequirement[i];
                    if (element.id === res.data.id) {
                        element.id = null
                        
                    }
                }
                
                this.props.enqueueSnackbar('Deleted', {
                    variant: 'success'
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title="Future Requirements"
                    columns={[
                        {
                            title: "Role",
                            field: "role",
                            lookup: this.props.rolesLookup
                        },
                       
                        {
                            title: "Start Date",
                            field: "date_of_req_date_format",
                            type: 'date'
                        },
                        {
                            title: "Skills Required",
                            field: "skills_needed",
                        }

                    ]}
                    data={this.state.data}
                    options={{
                        padding: "dense",
                        paging: false,
                    }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        newData.gid = this.props.team.id;
                                        newData.created_by = user.id;
                                        newData.office_loc = 1
                                        newData.updated_by = user.id;
                                        newData.date_of_req = moment(newData.date_of_req_date_format).format('YYYY-MM-DD')
                                        this.postData(newData);
                                        data.push(newData);
                                        // this.postData(newData)
                                        return { ...prevState, data };
                                    });
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            newData.date_of_req = moment(newData.date_of_req_date_format).format('YYYY-MM-DD')
                                            newData.updated_by = user.id;
                                            data[data.indexOf(oldData)] = newData;
                                            this.putData(newData)
                                            return { ...prevState, data };
                                        });
                                    }
                                }, 600);
                            }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    // this.setState({ saved: false });
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        this.deleteData(oldData)
                                        return { ...prevState, data };
                                    });
                                }, 600);
                            }),
                    }}
                />
            </div>
        )
    }
}

export default withSnackbar(FutureRequirement)