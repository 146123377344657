import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TaskLevelAllocation2 from './TaskLevelAllocation2';
import SecuredProjectRoles from '../SecuredProjectRoles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import FindUserByRole from '../../FindUserByRole/FindUserByRole';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function TaskLevelTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [searchUserRoles, setSearchUserRoles] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setSearchUserRoles(false);
    };

    return (
        <div className={classes.root}>
            <Box display="flex" alignItems="center">
                <Box p={1} flexGrow={1}>
                    <AppBar position="static">
                        <Tabs indicatorColor='secondary' textColor='inherit' value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="Task Level Allocation" {...a11yProps(0)} />
                            <Tab label="Manage Task Roles" {...a11yProps(1)} />
                            {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                        </Tabs>
                    </AppBar>
                </Box>
                <Box p={1}>
                    <Button variant="contained" color="primary" onClick={() => setSearchUserRoles(true)} >Find User Role</Button>
                </Box>

            </Box>

            <TabPanel value={value} index={0}>
                <TaskLevelAllocation2 value={props.value} usersData={props.usersData} task={props.value} rolesLookUp={props.allRoles} projectLook={props.projectLook}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <SecuredProjectRoles value={props.value} task={props.value} rolesLookUp={props.allRoles} projectLook={props.projectLook} />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
            <Dialog
                open={searchUserRoles}
                fullWidth
                maxWidth={'sm'}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="customized-dialog-title2" >
                    Find Role of a user
                </DialogTitle>
                <DialogContent>
                    <FindUserByRole />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}
