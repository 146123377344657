import React, { Component } from 'react'
import Board from "react-trello";
import { createTheme } from '@mui/material/styles';
import { getDarkMode, getPrimaryColor, getSecondaryColor } from '../Helper/CommonThings';
import { Avatar, Box, Button, Chip, CircularProgress, DialogContent, Divider, IconButton, TextField, } from '@material-ui/core';
import { screenSizeCheck } from '../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { getLaneWidth, getPriority, } from '../Helper/KanbanHelper';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import { getMode } from '../MainDashBoard/ColorTheme/ColorTheme';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@mui/material';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Service from '.././Networking/networkutils'
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import { BootstrapDialogTitleV4, BootstrapDialogV4 } from '../Helper/DialogTitleM5';
import Slide from '@material-ui/core/Slide';
import CustomCard from './CustomCard';
import CustomAddCard from './CustomAddCard';
import AddCardLink from './AddCardLink';
// import DownloadIcon from '@material-ui/icons/CloudDownload';

const token = getCookie("bb_ts_token");

const useStyles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    small: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        backgroundColor: getMode() ? getSecondaryColor() : getPrimaryColor(),
        color: getMode() ? getPrimaryColor() : '#fff'
    },
});

const theme = createTheme({
    palette: {
        type: getDarkMode(),
        background: {
            light: {
                paper: '#fff',
                default: '#fafafa',
                primary: '#e6e6e6'
            },
            dark: {
                paper: '#424242',
                default: '#303030',
                primary: "#595959",
                secondary: '#a6a6a6'
            }
        },
        text: {
            light: {
                primary: '#000',
                50: '#fafafa',
                100: '#f5f5f5',
                200: '#eeeeee',
                300: '#e0e0e0',
                400: '#bdbdbd',
                500: '#9e9e9e',
                600: '#757575',
                700: '#616161',
                800: '#424242',
                900: '#212121',
                A100: '#d5d5d5',
                A200: '#aaaaaa',
                A400: '#303030',
                A700: '#616161',
            },
            dark: {
                primary: '#ffffff',
                50: '#fafafa',
                100: '#f5f5f5',
                200: '#eeeeee',
                300: '#e0e0e0',
                400: '#bdbdbd',
                500: '#9e9e9e',
                600: '#757575',
                700: '#616161',
                800: '#424242',
                900: '#212121',
                A100: '#d5d5d5',
                A200: '#aaaaaa',
                A400: '#303030',
                A700: '#616161',
            }
        }
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class TenderSubtasks extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: null,
            openDialog: false,
            archieveDialogue: false,
            dialogueData: null,
            isLoading: true,
            dialogueLoading: true,
            progress: [],
            archievedData: [],
            addIconDisabled: false,
            removeIconDisabled: false,
            comments: '',
            partialComments: [],
            fullComments: [],
            originalData: null,
            updated_card: null,
            priorityLookup: null,
            lanesLookup: null,
            taskDetails: null,
            titleData: null,
            completedData: null,
            boardHeight: (window.innerHeight * 0.9) + "px"
        }
        this.dialogueData = this.dialogueData.bind(this)
        this.addCard = this.addCard.bind(this)
        this.alertMsg = this.alertMsg.bind(this)
        this.userUpdate = this.userUpdate.bind(this)
        this.openSubTender = this.openSubTender.bind(this)
    }

    componentDidMount() {
        // console.log(this.props.team_lead)
        this.fetchData()
        this.getLanes()

        var priority = getPriority()
        var priorityLookup = {}
        for (let i = 0; i < priority.length; i++) {
            const element = priority[i];
            priorityLookup[element.key] = element.name
        }

        var boardHeight = ''
        if (screenSizeCheck()) {
            boardHeight = this.props.user_id ? (window.innerHeight * 0.9) + "px" : (window.innerHeight * 0.86) + "px"
        } else {
            boardHeight = this.props.user_id ? (window.innerHeight * 0.93) + "px" : (window.innerHeight * 0.91) + "px"
        }
        this.setState({ priorityLookup, boardHeight })
    }

    getLanes() {
        Service.get('/bb/api/tender/subtask/status/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var lanesLookup = {}
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    lanesLookup[element.id] = element.name
                }
                this.setState({ lanesLookup })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchData() {
        var laneStyle = {
            backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.dark.primary : theme.palette.background.light.primary,
            color: theme.palette.type === 'dark' ? theme.palette.text.dark.primary : theme.palette.text.light.primary,
            width: "250px"
        }
        var url = ''
        if (this.props.user_id) {
            url = `/bb/api/tender/subtask/before_completion_based_on_users/?user_id=${this.props.user_id}`
        } else if (this.props.task_id) {
            url = `/bb/api/tender/subtask/before_completion_based_on_task/?task_id=${this.props.task_id}`
        } else if (this.props.tender_id) {
            url = `/bb/api/tender/subtask/before_completion_based_on_tender/?tender_id=${this.props.tender_id}`
        } else if (this.props.ds_id) {
            url = `/bb/api/tender/subtask/before_completion_based_on_ds/?ds_id=${this.props.ds_id}`
        } else if (this.props.subTaskData) {
            url = `/bb/api/tender/subtask/before_completion_based_on_subtask/?subtask_id=${this.props.subTaskData.id}`
        } else if (this.props.todaySummary) {
            url = `/bb/api/tender/subtask/before_completion_today_summary/?uid=${this.props.todaySummary}`
        } else if (this.props.teamSummary) {
            url = `/bb/api/tender/subtask/before_completion_lead_based_today_summary/?uid=${this.props.teamSummary}`
        }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var progress = []
                laneStyle.width = getLaneWidth(res.data.lanes.length)
                for (let i = 0; i < res.data.lanes.length; i++) {
                    const lane = res.data.lanes[i];
                    lane.style = laneStyle
                    progress.push({ laneID: lane.id, name: lane.title })
                }
                if (this.state.completedData) {
                    for (let i = 0; i < res.data.lanes.length; i++) {
                        const element = res.data.lanes[i];
                        if (element.id === this.state.completedData.id) {
                            res.data.lanes[i].cards = this.state.completedData.cards
                        }
                    }
                }
                this.setState({
                    data: res.data,
                    isLoading: false,
                    progress: progress
                })
                if ((this.props.task_id) || (this.props.tender_id) || (this.props.ds_id)) {
                    this.getTaskDetails()
                }
                setTimeout(() => {
                    this.getCompleted()
                }, 100);
            })
            .catch(error => {
                if (error && error.status === 406) {
                    this.props.enqueueSnackbar('No team members available!', {
                        variant: 'warning'
                    });
                } else {
                    errorMessage(this.props, error)
                }

            });
    }

    getCompleted() {
        var url = ''
        if (this.props.user_id) {
            url = `/bb/api/tender/subtask/only_completion_based_on_users/?user_id=${this.props.user_id}`
        } else if (this.props.task_id) {
            url = `/bb/api/tender/subtask/only_completion_based_on_task/?task_id=${this.props.task_id}`
        } else if (this.props.tender_id) {
            url = `/bb/api/tender/subtask/only_completion_based_on_tender/?tender_id=${this.props.tender_id}`
        } else if (this.props.ds_id) {
            url = `/bb/api/tender/subtask/only_completion_based_on_ds/?ds_id=${this.props.ds_id}`
        } else if (this.props.subTaskData) {
            url = `/bb/api/tender/subtask/only_completion_based_on_subtask/?subtask_id=${this.props.subTaskData.id}`
        } else if (this.props.todaySummary) {
            url = `/bb/api/tender/subtask/only_completion_today_summary/?uid=${this.props.todaySummary}`
        } else if (this.props.teamSummary) {
            url = `/bb/api/tender/subtask/only_completion_lead_based_today_summary/?uid=${this.props.teamSummary}`
        }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ completedData: res.data })
                var data = this.state.data
                for (let i = 0; i < data.lanes.length; i++) {
                    const element = data.lanes[i];
                    if (element.id === res.data.id) {
                        data.lanes[i].cards = res.data.cards
                    }
                }
                this.setState({ data })
            })
            .catch(error => {
                if (error && (error.status === 406 || (error.response && error.response.status === 406))) {
                    return null
                } else {
                    errorMessage(this.props, error)
                }
            });
    }

    getTaskDetails() {
        var url = ''
        if (this.props.task_id) {
            url = `/bb/api/tender/wbs/tasks/get_task_details/?task_id=${this.props.task_id}`
        } else if (this.props.tender_id) {
            url = `/bb/api/tender/wbs/tasks/get_task_details/?tender_id=${this.props.tender_id}`
        } else if (this.props.ds_id) {
            url = `/bb/api/tender/wbs/tasks/get_task_details/?ds_id=${this.props.ds_id}`
        }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.task_list) {
                    for (let i = 0; i < res.data.task_list.length; i++) {
                        const element = res.data.task_list[i];
                        if (this.props.tender_id) {
                            element.detail_name = element.ds_name + " - " + element.id + " " + (element.decs ? element.decs : element.name)
                        } else if (this.props.ds_id) {
                            element.detail_name = element.id + " " + (element.decs ? element.decs : element.name)
                        }
                    }
                }
                if (this.props.task_id) {
                    this.setState({ taskDetails: res.data, })
                } else {
                    this.setState({
                        titleData: res.data
                    })
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    dialogueData(data) {
        this.setState({ openDialog: true })
        Service.get(`/bb/api/tender/subtask/get_subtask_data/?card_id=${data.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let comments = []
                if (res.data.comments.length < 5) {
                    comments = res.data.comments
                } else {
                    for (let i = 0; i < 5; i++) {
                        const element = res.data.comments[i];
                        if (element) {
                            comments.push(element)
                        }
                    }
                }
                this.setState({
                    dialogueData: res.data,
                    dialogueLoading: false,
                    partialComments: comments,
                    fullComments: res.data.comments,
                    originalData: res.data
                })
            })
            .catch(error => {
                this.setState({ openDialog: false, dialogueLoading: false })
                errorMessage(this.props, error)
            });
    }

    addCard(cardItems, laneId) {
        var data = this.state.data
        for (let i = 0; i < data.lanes.length; i++) {
            const element = data.lanes[i];
            if (element.id === laneId) {
                // element.cards.push(cardItems)
                element.cards = [...[cardItems], ...element.cards]
            }
        }
        this.setState({ data })
    }

    onCardMoveAcrossLanes(oldLane, newLane, cardId) {
        let data = this.state.data
        let card = null
        for (let i = 0; i < data.lanes.length; i++) {
            const laneItem = data.lanes[i];
            if (laneItem.id === oldLane.toString()) {
                for (let j = 0; j < laneItem.cards.length; j++) {
                    const cardItem = laneItem.cards[j];
                    if (cardItem.id === cardId.toString()) {
                        card = laneItem.cards[j]
                        laneItem.cards.splice(j, 1)
                        break
                    }
                }
            }
        }
        for (let i = 0; i < data.lanes.length; i++) {
            const laneItem = data.lanes[i];
            if (laneItem.id === newLane.toString()) {
                card.laneId = newLane.toString()
                laneItem.cards = [...[card], ...laneItem.cards]
                break
            }
        }
        this.setState({ data, isLoading: true }, () => { this.setState({ isLoading: false }) })

        //update from api
        Service.get(`/bb/api/tender/subtask/status/status_change/?id=${cardId}&moved_to=${newLane}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
            })
            .catch(error => {
                this.fetchData()
                errorMessage(this.props, error)
            });
    }

    alertMsg(msg, variant) {
        this.props.enqueueSnackbar(msg, {
            variant: variant
        });
    }

    userUpdate(card) {
        var data = this.state.data
        for (let i = 0; i < data.lanes.length; i++) {
            const laneItem = data.lanes[i];
            for (let j = 0; j < laneItem.cards.length; j++) {
                const cardItem = laneItem.cards[j];
                if (cardItem.id === card.id) {
                    laneItem.cards[j] = card
                    break
                }
            }
        }
        this.setState({ data })
    }

    archievedData() {
        var url = ''
        if (this.props.task_id) {
            url = `/bb/api/tender/subtask/get_archived_data/?task_id=${this.props.task_id}`
        } else if (this.props.tender_id) {
            url = `/bb/api/tender/subtask/get_archived_data/?tender_id=${this.props.tender_id}`
        } else if (this.props.ds_id) {
            url = `/bb/api/tender/subtask/get_archived_data/?ds_id=${this.props.ds_id}`
        } else if (this.props.subTaskData) {
            url = `/bb/api/tender/subtask/get_archived_data/?subtask_id=${this.props.subTaskData.id}`
        }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    archievedData: res.data,
                    archieveDialogue: true
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    unArchive(data) {
        Service.get(`/bb/api/tender/subtask/un_archived/?card_id=${data.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.archievedData()
            })
            .catch(error => {
                console.log(error)
                this.props.alertMsg('Something went wrong!', 'error');
            });
    }

    addUserDialogue(value, card_id) {
        this.setState({ addIconDisabled: true })
        Service.get(`/bb/api/tender/subtask/add_user/?user_id=${value.user_id}&card_id=${card_id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let comments = []
                if (res.data.comments.length < 5) {
                    comments = res.data.comments
                } else {
                    for (let i = 0; i < 5; i++) {
                        const element = res.data.comments[i];
                        if (element) {
                            comments.push(element)
                        }
                    }
                }
                var data = this.state.dialogueData
                data['users'] = res.data.users
                data['suggested_users'] = res.data.suggested_users
                this.setState({
                    dialogueData: data,
                    addIconDisabled: false,
                    updated_card: res.data,
                    partialComments: comments,
                    fullComments: res.data.comments,
                })
            })
            .catch(error => {
                this.setState({ addIconDisabled: false })
                errorMessage(this.props, error)
            });
    }

    removeUserDialogue(value, card_id) {
        this.setState({ removeIconDisabled: true })
        Service.get(`/bb/api/tender/subtask/remove_user/?user_id=${value.user_id}&card_id=${card_id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let comments = []
                if (res.data.comments.length < 5) {
                    comments = res.data.comments
                } else {
                    for (let i = 0; i < 5; i++) {
                        const element = res.data.comments[i];
                        if (element) {
                            comments.push(element)
                        }
                    }
                }
                var data = this.state.dialogueData
                data['users'] = res.data.users
                data['suggested_users'] = res.data.suggested_users
                this.setState({
                    dialogueData: data,
                    removeIconDisabled: false,
                    updated_card: res.data,
                    partialComments: comments,
                    fullComments: res.data.comments,
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
                this.setState({ removeIconDisabled: false })
            });
    }

    addComments(card_id) {
        if (this.state.comments) {
            Service.post(`/bb/api/tender/subtask/comments/subtask_comments/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    comment: this.state.comments,
                    card_id: card_id
                }
            })
                .then(res => {
                    let comments = []
                    if (res.data.comments.length < 5) {
                        comments = res.data.comments
                    } else {
                        for (let i = 0; i < 5; i++) {
                            const element = res.data.comments[i];
                            if (element) {
                                comments.push(element)
                            }
                        }
                    }
                    this.setState({
                        partialComments: comments,
                        comments: '',
                        fullComments: res.data.comments
                    })
                    this.props.enqueueSnackbar('Comments Added successfully!', {
                        variant: 'success'
                    });

                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        } else {
            this.props.enqueueSnackbar('Please Add a Comment', {
                variant: 'warning'
            });
        }
    }

    viewAllComments() {
        let data = this.state.fullComments
        let partialComments = this.state.partialComments
        partialComments = data
        this.setState({ partialComments })
    }

    handleTextChange(e) {
        if (e.target.id === 'description') {
            let data = this.state.dialogueData
            data['description'] = e.target.value
            this.setState({
                dialogueData: data,
            })
        } else if (e.target.id === 'subtaskname') {
            let data = this.state.dialogueData
            data['title'] = e.target.value
            this.setState({
                dialogueData: data,
            })
        } else if (e.target.id === 'comments') {
            this.setState({
                comments: e.target.value,
            })
        }
    }

    editCardDetails(card_id) {

        if (!this.state.dialogueData.title) {
            this.props.enqueueSnackbar('Sub Task Name should not be Empty!', {
                variant: 'warning'
            });
        } else if (this.state.dialogueData.planned_end && this.state.dialogueData.planned_start) {
            if ((moment(this.state.dialogueData.planned_end).format('YYYY-MM-DD')) >= (moment(this.state.dialogueData.planned_start).format('YYYY-MM-DD'))) {
                Service.patch('/bb/api/tender/subtask/subtask_edit_api/', {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: {
                        id: card_id,
                        description: this.state.dialogueData.description,
                        priority: this.state.dialogueData.priority,
                        planned_start: (moment(this.state.dialogueData.planned_start).format('YYYY-MM-DD')),
                        planned_end: (moment(this.state.dialogueData.planned_end).format('YYYY-MM-DD')),
                        moved_to: this.state.dialogueData.laneTitle,
                        name: this.state.dialogueData.title
                    }
                })
                    .then(res => {
                        let data = this.state.data
                        if (this.state.originalData.laneId === res.data.laneId) {
                            for (let i = 0; i < data.lanes.length; i++) {
                                const laneItem = data.lanes[i];
                                if (laneItem.id === res.data.laneId) {
                                    for (let j = 0; j < laneItem.cards.length; j++) {
                                        const cardItem = laneItem.cards[j];
                                        if (cardItem.id === res.data.id) {
                                            laneItem.cards[j] = res.data
                                            break
                                        }
                                    }
                                }
                            }
                        } else {
                            for (let i = 0; i < data.lanes.length; i++) {
                                const laneItem = data.lanes[i];
                                if (laneItem.id === this.state.originalData.laneId) {
                                    for (let j = 0; j < laneItem.cards.length; j++) {
                                        const cardItem = laneItem.cards[j];
                                        if (cardItem.id === this.state.originalData.id) {
                                            laneItem.cards.splice(j, 1)
                                            break
                                        }
                                    }
                                }
                            }
                            for (let i = 0; i < data.lanes.length; i++) {
                                const laneItem = data.lanes[i];
                                if (laneItem.id === res.data.laneId) {
                                    // laneItem.cards.push(res.data)
                                    laneItem.cards = [...[res.data], ...laneItem.cards]
                                    break
                                }
                            }
                        }

                        this.setState({
                            data: data, openDialog: false, dialogueData: null, originalData: null, partialComments: [], fullComments: [], isLoading: true
                        }, () => { this.setState({ isLoading: false }) });
                        this.props.enqueueSnackbar('Data Saved Successfully!', {
                            variant: 'success'
                        });
                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });
            } else {
                this.props.enqueueSnackbar('End dates are not lesser than Start dates!', {
                    variant: 'warning'
                });
            }
        } else {
            this.props.enqueueSnackbar('Dates are Required!', {
                variant: 'warning'
            });
        }
    }

    openSubTender(data) {
        this.props.openSubTender(data)
    }

    openTodaySummary(id) {
        this.props.openTodaySummary(id)
    }

    // downloadExcel() {
    //     Service.get('/bb/api/tender/subtask/before_completion_based_on_users/', {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res => {

    //         })
    //         .catch(error => {
    //             errorMessage(this.props, error)
    //         });
    // }

    render() {
        const classes = this.props.classes

        const handleDialogueClose = (e) => {
            if (this.state.updated_card) {
                let data = this.state.data
                for (let i = 0; i < data.lanes.length; i++) {
                    const laneItem = data.lanes[i];
                    if (laneItem.id === this.state.updated_card.laneId) {
                        for (let j = 0; j < laneItem.cards.length; j++) {
                            const cardItem = laneItem.cards[j];
                            if (cardItem.id === this.state.updated_card.id) {
                                laneItem.cards[j] = this.state.updated_card
                                break
                            }
                        }
                    }
                }
                this.setState({ data, openDialog: false, dialogueData: null })
            } else {
                this.setState({ openDialog: false, dialogueData: null })
            }
        }

        const handleArchieveDialogueClose = (e) => {
            this.setState({ archieveDialogue: false })
            this.fetchData()
        }

        const components = {
            Card: (props) => <CustomCard
                openSubTender={this.openSubTender}
                props={props}
                dialogueData={this.dialogueData}
                data={this.state.data}
                alertMsg={this.alertMsg}
                userUpdate={this.userUpdate}
                classes={classes}
                user_id={this.props.user_id ? this.props.user_id : null}
                subTaskData={this.props.subTaskData ? this.props.subTaskData : null}
                todaySummary={this.props.todaySummary ? this.props.todaySummary : null}
                teamSummary={this.props.teamSummary ? this.props.teamSummary : null} />,
            NewCardForm: (props) => <CustomAddCard
                props={props}
                data={this.state.data}
                addCard={this.addCard}
                alertMsg={this.alertMsg}
                task_id={this.props.task_id}
                subTaskData={this.props.subTaskData ? this.props.subTaskData : null}
                titleData={this.state.titleData}
                task_details={this.state.taskDetails} />,
            AddCardLink: (props) => <AddCardLink props={props} data={this.state.data} />
        }

        const handleStartChange = (date) => {
            let data = this.state.dialogueData
            data['planned_start'] = date
            this.setState({
                dialogueData: data,
            })
        }

        const handleEndChange = (date) => {
            let data = this.state.dialogueData
            data['planned_end'] = date
            this.setState({
                dialogueData: data,
            })
        }

        const handlePriorityChange = (event) => {
            let data = this.state.dialogueData
            data['priority'] = event.target.value
            this.setState({ dialogueData: data, });
        }

        const handleProgressChange = (event) => {
            let data = this.state.dialogueData
            data['laneTitle'] = event.target.value
            this.setState({ dialogueData: data, });
        }

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <><Box display='flex' width={'100%'} flexDirection="row-reverse" marginTop={'5px'} alignItems='center'>

                        {this.props.task_id || this.props.subTaskData || this.props.ds_id || this.props.tender_id ?
                            <Link onClick={() => this.archievedData()} style={{ marginRight: '10px' }} color={getMode() ? 'secondary' : 'primary'}>
                                Archived Sub Tasks
                            </Link>
                            : null}
                        {
                            this.props.user_id ?
                                <Link onClick={() => this.openTodaySummary(this.props.user_id)} style={{ marginRight: '10px' }} color={getMode() ? 'secondary' : 'primary'}>
                                    MySubtasks(Today)
                                </Link>
                                : null
                        }
                        {
                            this.props.team_lead && this.props.team_lead.lead_in_teams !== 0 && this.props.user_id ?
                                <Link onClick={() => this.props.openTeamSummary()} style={{ marginRight: '10px' }} color={getMode() ? 'secondary' : 'primary'}>
                                    TeamSubtasks(Today)
                                </Link>
                                : null
                        }
                        {/* {this.props.task_id || this.props.subTaskData || this.props.ds_id || this.props.tender_id ?
                            <Button style={{ marginRight: '10px' }}
                                color={getMode() ? 'secondary' : 'primary'}
                                endIcon={<DownloadIcon />}
                                onClick={() => this.downloadExcel()}
                                size='small'
                            >
                                Download
                            </Button> : null} */}
                    </Box>
                        <Board
                            data={this.state.data}
                            cardDragClass="draggingCard"
                            draggable={false}
                            // customCardLayout
                            components={components}
                            editable
                            style={{ backgroundColor: theme.palette.type === 'dark' ? 'transparent' : theme.palette.background.light.default }}
                            onCardMoveAcrossLanes={(oldLane, newLane, cardId) => this.onCardMoveAcrossLanes(oldLane, newLane, cardId)}
                            onCardAdd={(card, laneid) => this.addCard(card, laneid)}
                        >
                        </Board>
                        <div>
                            <Dialog fullWidth={true} maxWidth={'md'} open={this.state.openDialog} onClose={handleDialogueClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
                                {this.state.dialogueLoading ?
                                    <Box display='flex' flexDirection={'row'} justifyContent='center'><CircularProgress /></Box>
                                    :
                                    // <EditCardDetails
                                    //     progress={this.state.progress}
                                    //     cardDetails={this.state.dialogueData ? this.state.dialogueData : null}
                                    //     closeDialog={handleDialogueClose}
                                    //     partialComments={this.state.partialComments}
                                    //     fullComments={this.state.fullComments}
                                    //     originalData={this.state.originalData}
                                    // />
                                    <>
                                        <AppBar position="static" color="primary">
                                            <Toolbar>
                                                <Box display='flex' flexDirection={'row'} width={'100%'}>
                                                    <Box flexGrow={1}>
                                                        <Typography variant='h6'>{this.state.dialogueData ? this.state.dialogueData.title : null}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Button color='secondary' onClick={() => this.editCardDetails(this.state.dialogueData ? this.state.dialogueData.id : null)}>save</Button>
                                                    </Box>
                                                    <Box>
                                                        <Button color='secondary' onClick={handleDialogueClose}>
                                                            close
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Toolbar>
                                        </AppBar>
                                        <DialogContent>
                                            <Box display='flex' flexDirection='column' m={1}>
                                                <Box display='flex' flexDirection='row' style={{ width: '100%' }} justifyContent='center'>
                                                    <TextField
                                                        id="subtaskname"
                                                        label="Sub Task Name"
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => this.handleTextChange(e)}
                                                        value={this.state.dialogueData ? this.state.dialogueData.title : ''}
                                                    />
                                                </Box>
                                                <Divider variant="middle" style={{ marginTop: '15px', marginBottom: '15px' }} />
                                                <Box display='flex' flexDirection='row' >
                                                    <Box>
                                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div>
                                                                    <IconButton style={{ marginLeft: '5px' }} {...bindTrigger(popupState)}>
                                                                        <GroupAddIcon fontSize='small' />
                                                                    </IconButton>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'center',
                                                                            horizontal: 'left',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}
                                                                    // onClose={() => this.updatedResponse(popupState)}
                                                                    >
                                                                        <Box m={2} width={300} maxHeight={screenSizeCheck() ? 500 : 600}>
                                                                            <Box display={'flex'} flexDirection={'column'}>
                                                                                {/* <Box width={'100%'}><TextField id="standard-basic" label="Type a name" style={{ width: '100%' }} /></Box> */}
                                                                                <Box >
                                                                                    <Box fontWeight={'bold'}>Assigned</Box>
                                                                                    <Box mt={1}>
                                                                                        {this.state.dialogueData ?
                                                                                            this.state.dialogueData.users.length !== 0 ?
                                                                                                <List dense>
                                                                                                    {this.state.dialogueData.users.map((value, i) =>
                                                                                                        <ListItem key={i}>
                                                                                                            <ListItemAvatar>
                                                                                                                <Avatar sx={{ bgcolor: '#99CFBB' }} ><Box fontWeight={'bold'} fontSize={'10px'}>{value.first_name.charAt(0) + value.last_name.charAt(0)}</Box></Avatar>
                                                                                                            </ListItemAvatar>
                                                                                                            <ListItemText
                                                                                                                primary={value.last_name + ", " + value.first_name}
                                                                                                            />
                                                                                                            <ListItemSecondaryAction>
                                                                                                                <IconButton edge="end" aria-label="delete" onClick={() => this.removeUserDialogue(value, this.state.dialogueData ? this.state.dialogueData.id : null)} disabled={this.state.removeIconDisabled}>
                                                                                                                    <CloseIcon fontSize='small' />
                                                                                                                </IconButton>
                                                                                                            </ListItemSecondaryAction>
                                                                                                        </ListItem>
                                                                                                    )}
                                                                                                </List> :
                                                                                                'There is no assigned users' : null
                                                                                        }
                                                                                    </Box>
                                                                                </Box>

                                                                                <Box mt={1}>
                                                                                    <Box fontWeight={'bold'}>Suggestions</Box>
                                                                                    <Box mt={1}>
                                                                                        {(this.state.dialogueData) && (this.state.dialogueData.suggested_users.length !== 0) ?
                                                                                            <List dense>
                                                                                                {this.state.dialogueData.suggested_users.map((value, i) =>
                                                                                                    <ListItem key={i}>
                                                                                                        <ListItemAvatar>
                                                                                                            <Avatar sx={{ bgcolor: '#99CFBB' }} ><Box fontWeight={'bold'} fontSize={'10px'}>{value.first_name.charAt(0) + value.last_name.charAt(0)}</Box></Avatar>
                                                                                                        </ListItemAvatar>
                                                                                                        <ListItemText
                                                                                                            primary={value.last_name + ", " + value.first_name}
                                                                                                        />
                                                                                                        <ListItemSecondaryAction>
                                                                                                            <IconButton edge="end" aria-label="delete" onClick={() => this.addUserDialogue(value, this.state.dialogueData ? this.state.dialogueData.id : null)} disabled={this.state.addIconDisabled}>
                                                                                                                <AddIcon fontSize='small' />
                                                                                                            </IconButton>
                                                                                                        </ListItemSecondaryAction>
                                                                                                    </ListItem>
                                                                                                )}
                                                                                            </List>
                                                                                            : 'Users assigned to the tasks are displayed here'}
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState>
                                                    </Box>
                                                    <Box className={classes.root}>
                                                        {this.state.dialogueData ? this.state.dialogueData.users.map((ele, i) => {
                                                            return (<li key={i}>
                                                                <Chip
                                                                    avatar={<Avatar>{ele.first_name.charAt(0) + '' + ele.last_name.charAt(0)}</Avatar>}
                                                                    label={ele.first_name}
                                                                    color={"default"}
                                                                    className={classes.chip}
                                                                    onDelete={() => this.removeUserDialogue(ele, this.state.dialogueData ? this.state.dialogueData.id : null)}
                                                                />
                                                            </li>
                                                            )
                                                        }) : null}
                                                    </Box>
                                                </Box>
                                                <Divider variant="middle" style={{ marginTop: '15px', marginBottom: '15px' }} />
                                                <Box display='flex' flexDirection='row' mt={1} justifyContent='left'>
                                                    <Box display='flex' flexDirection='column' marginLeft={'5px'} marginRight={'30px'}>
                                                        <Box>
                                                            <FormControl variant="outlined" style={{ width: 300 }}>
                                                                <InputLabel id="demo-simple-select-outlined-label">Progress</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="priority"
                                                                    value={this.state.dialogueData ? this.state.dialogueData.laneTitle : ''}
                                                                    onChange={handleProgressChange}
                                                                    label="Priority">
                                                                    {this.state.progress.map((value, i) =>
                                                                        <MenuItem key={i} value={value.name}>{value.name}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Box>
                                                    <Box display='flex' flexDirection='column' marginLeft={'5px'} >
                                                        <Box>
                                                            <FormControl variant="outlined" style={{ width: 300 }}>
                                                                <InputLabel id="demo-simple-select-outlined-label">Priority</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="priority"
                                                                    value={this.state.dialogueData ? this.state.dialogueData.priority : ''}
                                                                    onChange={handlePriorityChange}
                                                                    label="Priority">
                                                                    <MenuItem value={null}>None</MenuItem>
                                                                    {getPriority().map((value, i) =>
                                                                        <MenuItem key={i} value={value.key}>{value.name}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box display='flex' flexDirection='row' mt={1} justifyContent='left'>
                                                    <Box display='flex' flexDirection='column' marginLeft={'5px'} marginRight={'30px'}>
                                                        <Box>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardDatePicker
                                                                    variant='outlined'
                                                                    margin="normal"
                                                                    format="dd/MM/yyyy"
                                                                    value={this.state.dialogueData ? this.state.dialogueData.planned_start : null}
                                                                    onChange={handleStartChange}
                                                                    clearable
                                                                    fullWidth
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'Planned start',
                                                                    }}
                                                                    label='Planned Start'
                                                                    style={{ width: 300 }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Box>
                                                    </Box>
                                                    <Box display='flex' flexDirection='column' marginLeft={'5px'}>
                                                        <Box>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardDatePicker
                                                                    variant='outlined'
                                                                    margin="normal"
                                                                    format="dd/MM/yyyy"
                                                                    value={this.state.dialogueData ? this.state.dialogueData.planned_end : null}
                                                                    onChange={handleEndChange}
                                                                    clearable
                                                                    fullWidth
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'Planned end',
                                                                    }}
                                                                    label='Planned End'
                                                                    style={{ width: 300 }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Divider variant="middle" style={{ marginTop: '15px', marginBottom: '15px' }} />
                                                <Box display='flex' flexDirection='row' mt={1} style={{ width: '100%' }} justifyContent='space-around'>
                                                    <Box display='flex' flexDirection='column' style={{ width: '100%' }} >
                                                        <Box style={{ width: '100%' }} justifyContent='center'>
                                                            <TextField
                                                                id="description"
                                                                label="Description"
                                                                multiline
                                                                rows={4}
                                                                variant="outlined"
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => this.handleTextChange(e)}
                                                                value={this.state.dialogueData ? this.state.dialogueData.description !== null ? this.state.dialogueData.description : '' : ''}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Divider variant="middle" style={{ marginTop: '15px', marginBottom: '15px' }} />
                                                <Box display='flex' flexDirection='row' style={{ width: '100%' }}>
                                                    <Box display='flex' flexDirection='column' style={{ width: '100%' }} >
                                                        <Box fontWeight={'bold'}>
                                                            Comments
                                                        </Box>
                                                        <Box style={{ width: '100%' }} justifyContent='center' mt={1}>
                                                            <TextField
                                                                id="comments"
                                                                label="Type a comment..."
                                                                multiline
                                                                rows={2}
                                                                variant="outlined"
                                                                style={{ width: '100%' }}
                                                                value={this.state.comments}
                                                                onChange={(e) => this.handleTextChange(e)}
                                                            />
                                                        </Box>
                                                        <Box display='flex' width={'100%'} flexDirection="row-reverse" marginRight={'10px'} marginTop={'5px'}>
                                                            <Button color={getMode() ? 'secondary' : 'primary'} onClick={() => this.addComments(this.state.dialogueData ? this.state.dialogueData.id : null)}>Add Comments</Button>
                                                        </Box>
                                                        <Box style={{ width: '100%' }}>
                                                            {this.state.partialComments.length !== 0 ? this.state.partialComments.map((value, i) =>
                                                                <Box key={i} marginTop='10px' marginBottom={'5px'} display={'flex'} flexDirection='column'>
                                                                    <Box display={'flex'} flexDirection='row' alignItems={'center'}>
                                                                        <Box display={'flex'} flexDirection='row' flexGrow={1} alignItems={'center'}>
                                                                            <Box>
                                                                                <Avatar sx={{ bgcolor: '#99CFBB' }} className={classes.small}><Box fontWeight={'bold'} fontSize={'9px'} >{value.first_name.charAt(0) + value.last_name.charAt(0)}</Box></Avatar>
                                                                            </Box>
                                                                            <Box marginLeft="10px" fontWeight={500}>{value.first_name + ", " + value.last_name}</Box>
                                                                        </Box>
                                                                        <Box>{moment(value.date_created).format(' DD MMM YYYY, h:mm')}</Box>
                                                                    </Box>
                                                                    <Box marginLeft='37px'>
                                                                        {value.comment}
                                                                    </Box>
                                                                </Box>
                                                            ) : null
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                {
                                                    this.state.partialComments.length !== 0 && this.state.partialComments.length >= 5 ?
                                                        <>
                                                            <Divider variant="middle" style={{ marginTop: '15px', marginBottom: '15px' }} />
                                                            <Box display='flex' flexDirection='row' style={{ width: '100%' }} justifyContent='center'>
                                                                <Button color={getMode() ? 'secondary' : 'primary'} onClick={() => this.viewAllComments()}>View All Comments</Button>
                                                            </Box>
                                                        </>
                                                        : null
                                                }

                                            </Box>
                                        </DialogContent>
                                    </>
                                }
                            </Dialog>

                            <BootstrapDialogV4 open={this.state.archieveDialogue} fullWidth maxWidth={'md'} aria-labelledby="customized-dialog-title" TransitionComponent={Transition}>
                                <BootstrapDialogTitleV4 id="form-dialog-title" onClose={handleArchieveDialogueClose}>
                                    Archived Data
                                </BootstrapDialogTitleV4>
                                <DialogContent>
                                    <MaterialTable
                                        title=""
                                        columns={[
                                            { title: 'SubTask', field: 'name' },
                                            { title: 'Planned Start', field: 'planned_start' },
                                            { title: 'Planned End', field: 'planned_end' },
                                            {
                                                title: 'Priority', field: 'priority',
                                                lookup: this.state.priorityLookup
                                            },
                                            {
                                                title: 'Status', field: 'subtask_status_id',
                                                lookup: this.state.lanesLookup
                                            },
                                        ]}
                                        data={this.state.archievedData}
                                        actions={[
                                            rowData => ({
                                                icon: 'unarchive',
                                                tooltip: 'UnArchive',
                                                onClick: (event, rowData) => { this.unArchive(rowData) }
                                            }),
                                            rowData => ({
                                                icon: 'edit',
                                                tooltip: 'Edit',
                                                onClick: (event, rowData) => { this.dialogueData(rowData) }
                                            }),
                                        ]}
                                        options={{
                                            padding: "dense",
                                            pageSize: 10,
                                            pageSizeOptions: [10, 20, 50],
                                            filtering: true,
                                            actionsColumnIndex: -1
                                        }}
                                    />
                                </DialogContent>
                            </BootstrapDialogV4>
                        </div>
                    </>
                )}
            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(TenderSubtasks))
