import React, { Component } from 'react'
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Box, Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';

class DownloadReport extends Component {

    // componentDidMount() {
    //     this.downloadData()
    // }

    s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    getWbsData(data) {
        let tasks_data = data.tasks_data
        let wbs_data = [];
        let wbs_data_id = [];
        for (let i = 0; i < tasks_data.length; i++) {
            // wbs_data.push(tasks_data[i].t_wbs_data)
            if (wbs_data_id.indexOf(tasks_data[i].t_wbs_data.id) === -1) {
                wbs_data_id.push(tasks_data[i].t_wbs_data.id)
                wbs_data.push(tasks_data[i].t_wbs_data)
            }
        }
        console.log(wbs_data)
        // let f = wbs_data_id.filter(onlyUniqueArr)
        // console.log(f)
        // for (let i = 0; i < f.length; i++) {
        //     const element = f[i];
        //     for (let j = 0; j < wbs_data.length; j++) {
        //         const element1 = wbs_data[j];

        //         if (element === element1.id) {
        //             act_wbs_data.push(element1)
        //             break;
        //         }

        //     }
        // }
        // console.log(act_wbs_data)
        return wbs_data
    }

    totalCalc(key, arr) {
        var total = 0
        for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
            total += element[key]
        }
        return total
    }

    usageCalc(key) {
        return this.props.value.usage_history.length > 0 ? this.props.value.usage_history[0][key] : 0
    }

    typeCheck(ele) {
        if (ele.type === 0) return 'Budget'
        else if (ele.type === 1) return 'Change'
        else return ''
    }

    checkApproval(ele) {
        if (ele.type === 0) return '-'
        else if (ele.type === 1) {
            if (ele.approval_status === 0) return "Not Approved"
            else if (ele.approval_status === 1) return "Approved"
            else return ""
        }
        else return ""
    }

    usageHistorySheet(data) {
        let param_date = data.usage_history[0].date_into_param ? moment(data.usage_history[0].date_into_param).format("DD/MM/YYYY") : 'Not Available';
        // console.log(param_date)
        return {

            '!ref': "A1:J" + 7,
            'A1': { t: "s", v: "Date Started in PaRaM : " + param_date },
            'A2': { t: "s", v: "The data in the subsequent tabs are 'In Param' data only, it does not show the 'Before Param' data" },


            'A4': { t: "s", v: "" },
            'B4': { t: "s", v: "Budget Hours" },
            'C4': { t: "s", v: "Budget Cost" },
            'D4': { t: "s", v: "Hours to date" },
            'E4': { t: "s", v: "Cost to date" },
            'F4': { t: "s", v: "Cost to Come" },
            'G4': { t: "s", v: "At Completion Hours" },
            'H4': { t: "s", v: "At Completion Cost" },
            'I4': { t: "s", v: "Variance Cost" },
            'J4': { t: "s", v: "Earned Value" },
            


            'A5': { t: "s", v: 'Before Param' },
            'B5': { t: "n", v: this.usageCalc("est_hours") },
            'C5': { t: "n", v: this.usageCalc("est_cost") },
            'D5': { t: "n", v: this.usageCalc("actual") },
            'E5': { t: "n", v: this.usageCalc("actual_cost") },
            'F5': { t: "n", v: 0 },
            'G5': { t: "n", v: this.usageCalc("rev_hours") },
            'H5': { t: "n", v: this.usageCalc("rev_cost") },
            'I5': { t: "n", v: this.usageCalc("rev_cost") - this.usageCalc("est_cost") },
            'J5': { t: "n", v: this.usageCalc("earned_value") },
            
            


            'A6': { t: "s", v: 'In Param' },
            'B6': { t: "n", v: this.totalCalc("est_hours", data.ds_data) },
            'C6': { t: "n", v: this.totalCalc("est_cost", data.ds_data) },
            'D6': { t: "n", v: this.totalCalc("actual", data.ds_data) },
            'E6': { t: "n", v: this.totalCalc("actual_cost", data.ds_data) },
            'F6': { t: "n", v: this.totalCalc("cost_to_complete", data.ds_data) },
            'G6': { t: "n", v: this.totalCalc("rev_hours", data.ds_data) },
            'H6': { t: "n", v: this.totalCalc("rev_cost", data.ds_data) },
            'I6': { t: "n", v: this.totalCalc("delta_cost", data.ds_data) },
            'J6': { t: "n", v: this.totalCalc("earned_value", data.ds_data) },
            
            


            'A7': { t: "s", v: 'Total' },
            'B7': { t: "n", v: this.totalCalc("est_hours", data.ds_data) + this.usageCalc("est_hours") },
            'C7': { t: "n", v: this.totalCalc("est_cost", data.ds_data) + this.usageCalc("est_cost") },
            'D7': { t: "n", v: this.totalCalc("actual", data.ds_data) + this.usageCalc("actual") },
            'E7': { t: "n", v: this.totalCalc("actual_cost", data.ds_data) + this.usageCalc("actual_cost") },
            'F7': { t: "n", v: this.totalCalc("cost_to_complete", data.ds_data) + 0 },
            'G7': { t: "n", v: this.totalCalc("rev_hours", data.ds_data) + this.usageCalc("rev_hours") },
            'H7': { t: "n", v: this.totalCalc("rev_cost", data.ds_data) + this.usageCalc("rev_cost") },
            'I7': { t: "n", v: this.totalCalc("delta_cost", data.ds_data) + (this.usageCalc("rev_cost") - this.usageCalc("est_cost")) },
            'J7': { t: "n", v: this.totalCalc("earned_value", data.ds_data) + this.usageCalc("earned_value") },
            
            
        }
    }

    userSheet(data) {
        let user_sheet = {
            '!ref': "A1:O" + (data.timesheet_data.length + 1),
            '!cols': [
                { wch: 18 },
                { wch: 18 },
                { wch: 18 },
                { wch: 18 },
                { wch: 12 },
                { wch: 18 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 }
            ],
            'A1': { t: "s", v: "User Name" },
            'B1': { t: "s", v: "Deliverables Schedule ID" },
            'C1': { t: "s", v: "Deliverables Schedule" },
            'D1': { t: "s", v: "WBS ID" },
            'E1': { t: "s", v: "WBS" },
            'F1': { t: "s", v: "Task ID" },
            'G1': { t: "s", v: "Task Name" },
            'H1': { t: "s", v: "Allocated Hours" },
            'I1': { t: "s", v: "Hours to date" },
            'J1': { t: "s", v: "Cost" }
        }

        for (let i = 0; i < data.timesheet_data.length; i++) {
            const element = data.timesheet_data[i];
            user_sheet["A" + (i + 2)] = { t: "s", v: element.first_name + ' ' + element.last_name }
            user_sheet["B" + (i + 2)] = { t: "s", v: element.sheet_data.tpid }
            user_sheet["C" + (i + 2)] = { t: "s", v: element.sheet_data.tproj_name }
            user_sheet["D" + (i + 2)] = { t: "s", v: element.sheet_data.twbsid }
            user_sheet["E" + (i + 2)] = { t: "s", v: element.sheet_data.twbsname }
            user_sheet["F" + (i + 2)] = { t: "n", v: element.sheet_data.ttid }
            user_sheet["G" + (i + 2)] = { t: "s", v: element.sheet_data.ttdecs ? element.sheet_data.ttdecs : element.sheet_data.ttname }
            user_sheet["H" + (i + 2)] = { t: "n", v: element.allocated_this_task }
            user_sheet["I" + (i + 2)] = { t: "n", v: element.hours_worked }
            user_sheet["J" + (i + 2)] = { t: "n", v: element.cost }
        }
        return user_sheet
    }

    downloadData() {
        var data = this.props.value;
        // console.log('report data ==>', data)
        var sheets = ["Summary", "Cost Summary", "DS Level Summary", "Task Level Summary", "User Level Summary"]

        var sheetData = {}

        var exportData = {}

        // var dsData = {}

        // dsData.header = {
        //     'id': 'Deliverables Schedule ID',
        //     'name': 'Deliverables Schedule',
        //     'est_hours': "Estimated Hours",
        //     'est_cost': "Estimated Cost",
        //     'rev_hours': "Revised Hours",
        //     'rev_cost': "Revised Cost",
        //     'delta_cost': "Delta Cost",
        //     'allocated': "Allocated Hours",
        //     'actual': "Actual Hours",
        //     'earned_value': "Earned Value",
        //     'ev_percentage': "EV (%)"
        // }

        let wbs_data = this.getWbsData(data)
        // console.log(wbs_data)  

        let summary = []
        for (let i = 0; i < wbs_data.length; i++) {
            let obj = { ...wbs_data[i] }
            let eh_estHr = 0;
            let eh_actHr = 0;
            let eh_revHr = 0;
            let cad_estHr = 0;
            let cad_actHr = 0;
            let cad_revHr = 0;
            let budget_cost = 0;
            let cost_to_date = 0;
            let cost_to_complete = 0;
            let est_cost = 0;
            let variance = 0;


            for (let j = 0; j < data.roles_data.length; j++) {
                let role = data.roles_data[j]
                if (wbs_data[i].id === role.t_tasks_data.t_wbs_data.id) {
                    if (
                        role.grade.toLowerCase() === 'a' ||
                        role.grade.toLowerCase() === 'b' ||
                        role.grade.toLowerCase() === 'c'
                    ) {
                        eh_estHr += role.hours
                        eh_revHr += role.rev_hours
                    }
                    if (
                        role.grade.toLowerCase() === 'd' ||
                        role.grade.toLowerCase() === 'e' ||
                        role.grade.toLowerCase() === 'f' ||
                        role.grade.toLowerCase() === 'g'
                    ) {
                        cad_estHr += role.hours
                        cad_revHr += role.rev_hours
                    }
                }
            }
            for (let k = 0; k < data.timesheet_data.length; k++) {
                let user = data.timesheet_data[k]
                if (wbs_data[i].id === user.sheet_data.twbsid) {
                    if (
                        user.role_grade.toLowerCase() === 'a' ||
                        user.role_grade.toLowerCase() === 'b' ||
                        user.role_grade.toLowerCase() === 'c'
                    ) {
                        eh_actHr += user.hours_worked
                    }
                    if (
                        user.role_grade.toLowerCase() === 'd' ||
                        user.role_grade.toLowerCase() === 'e' ||
                        user.role_grade.toLowerCase() === 'f' ||
                        user.role_grade.toLowerCase() === 'g'
                    ) {
                        cad_actHr += user.hours_worked
                    }
                }
            }
            for (let l = 0; l < data.tasks_data.length; l++) {
                let task = data.tasks_data[l]
                if (wbs_data[i].id === task.t_wbs_data.id) {
                    budget_cost += task.est_cost;
                    cost_to_date += task.actual_cost;
                    cost_to_complete += task.cost_to_complete
                    est_cost += task.rev_cost;
                }
            }
            let eh_htc = eh_revHr - eh_actHr;
            let cad_htc = cad_revHr - cad_actHr;
            // cost_to_complete = budget_cost - cost_to_date;
            variance = est_cost - budget_cost;

            obj = {
                ...obj,
                eh_estHr,
                eh_actHr,
                eh_htc,
                cad_estHr,
                cad_actHr,
                cad_htc,
                budget_cost,
                cost_to_date,
                est_cost,
                cost_to_complete,
                variance
            };
            summary.push(obj)
        }

        // console.log('summary', summary)

        sheetData["Cost Summary"] = {
            '!ref': "A1:Q" + (summary.length + 2),
            'A1': { t: "s", v: "Deliverables Schedule ID" },
            'B1': { t: "s", v: "Budget / Change" },
            'C1': { t: "s", v: "Approved / Not Approved" },
            'D1': { t: "s", v: "Deliverables Schedule" },
            'E1': { t: "s", v: "WBS ID" },
            'F1': { t: "s", v: "WBS" },
            'G1': { t: "s", v: "EH - Budget Hours" },
            'H1': { t: "s", v: "EH - Hours to date" },
            'I1': { t: "s", v: "EH - Hours TC" },
            'J1': { t: "s", v: "CAD - Budget Hours" },
            'K1': { t: "s", v: "CAD - Hours to date" },
            'L1': { t: "s", v: "CAD - Hous TC" },
            'M1': { t: "s", v: "Budget Cost" },
            'N1': { t: "s", v: "Cost To Date" },
            'O1': { t: "s", v: "CTC *" },
            'P1': { t: "s", v: "Est. Cost At Completion *" },
            'Q1': { t: "s", v: "Variance" },
        }

        for (let i = 0; i < summary.length; i++) {
            const element = summary[i];
            sheetData["Cost Summary"]["A" + (i + 2)] = { t: "s", v: element.pid_data.id }
            sheetData["Cost Summary"]["B" + (i + 2)] = { t: "s", v: this.typeCheck(element.pid_data) }
            sheetData["Cost Summary"]["C" + (i + 2)] = { t: "s", v: this.checkApproval(element.pid_data) }
            sheetData["Cost Summary"]["D" + (i + 2)] = { t: "s", v: element.pid_data.name }
            sheetData["Cost Summary"]["E" + (i + 2)] = { t: "s", v: element.id }
            sheetData["Cost Summary"]["F" + (i + 2)] = { t: "s", v: element.name }
            sheetData["Cost Summary"]["G" + (i + 2)] = { t: "n", v: element.eh_estHr }
            sheetData["Cost Summary"]["H" + (i + 2)] = { t: "n", v: element.eh_actHr }
            sheetData["Cost Summary"]["I" + (i + 2)] = { t: "n", v: element.eh_htc }
            sheetData["Cost Summary"]["J" + (i + 2)] = { t: "n", v: element.cad_estHr }
            sheetData["Cost Summary"]["K" + (i + 2)] = { t: "n", v: element.cad_actHr }
            sheetData["Cost Summary"]["L" + (i + 2)] = { t: "n", v: element.cad_htc }
            sheetData["Cost Summary"]["M" + (i + 2)] = { t: "n", v: element.budget_cost }
            sheetData["Cost Summary"]["N" + (i + 2)] = { t: "n", v: element.cost_to_date }
            sheetData["Cost Summary"]["O" + (i + 2)] = { t: "n", v: element.cost_to_complete }
            sheetData["Cost Summary"]["P" + (i + 2)] = { t: "n", v: element.est_cost }
            sheetData["Cost Summary"]["Q" + (i + 2)] = { t: "n", v: element.variance }
        }


        sheetData["Cost Summary"]["G" + (summary.length + 2)] = { t: "n", v: this.totalCalc("eh_estHr", summary) }
        sheetData["Cost Summary"]["H" + (summary.length + 2)] = { t: "n", v: this.totalCalc("eh_actHr", summary) }
        sheetData["Cost Summary"]["I" + (summary.length + 2)] = { t: "n", v: this.totalCalc("eh_htc", summary) }
        sheetData["Cost Summary"]["J" + (summary.length + 2)] = { t: "n", v: this.totalCalc("cad_estHr", summary) }
        sheetData["Cost Summary"]["K" + (summary.length + 2)] = { t: "n", v: this.totalCalc("cad_actHr", summary) }
        sheetData["Cost Summary"]["L" + (summary.length + 2)] = { t: "n", v: this.totalCalc("cad_htc", summary) }
        sheetData["Cost Summary"]["M" + (summary.length + 2)] = { t: "n", v: this.totalCalc("budget_cost", summary) }
        sheetData["Cost Summary"]["N" + (summary.length + 2)] = { t: "n", v: this.totalCalc("cost_to_date", summary) }
        sheetData["Cost Summary"]["O" + (summary.length + 2)] = { t: "n", v: this.totalCalc("cost_to_complete", summary) }
        sheetData["Cost Summary"]["P" + (summary.length + 2)] = { t: "n", v: this.totalCalc("est_cost", summary) }
        sheetData["Cost Summary"]["Q" + (summary.length + 2)] = { t: "n", v: this.totalCalc('variance', summary) }


        // console.log(sheetData)
        sheetData["Summary"] = this.usageHistorySheet(data)


        sheetData["DS Level Summary"] = {
            '!ref': "A1:N" + (data.ds_data.length + 1),
            'A1': { t: "s", v: "Deliverables Schedule ID" },
            'B1': { t: "s", v: "Budget / Change" },
            'C1': { t: "s", v: "Approved / Not Approved" },
            'D1': { t: "s", v: "Deliverables Schedule" },
            'E1': { t: "s", v: "Budget Hours" },
            'F1': { t: "s", v: "Budget Cost" },
            'G1': { t: "s", v: "Hours to date" },
            'H1': { t: "s", v: "Cost to date" },
            'I1': { t: "s", v: "Cost to Come" },
            'J1': { t: "s", v: "At Completion Hours" },
            'K1': { t: "s", v: "At Completion Cost" },
            'L1': { t: "s", v: "Variance Cost" },
            'M1': { t: "s", v: "Earned Value" },
            'N1': { t: "s", v: "EV (%)" },
        }

        for (let i = 0; i < data.ds_data.length; i++) {
            const element = data.ds_data[i];
            sheetData["DS Level Summary"]["A" + (i + 2)] = { t: "n", v: element.id }
            sheetData["DS Level Summary"]["B" + (i + 2)] = { t: "s", v: this.typeCheck(element) }
            sheetData["DS Level Summary"]["C" + (i + 2)] = { t: "s", v: this.checkApproval(element) }
            sheetData["DS Level Summary"]["D" + (i + 2)] = { t: "s", v: element.name }
            sheetData["DS Level Summary"]["E" + (i + 2)] = { t: "n", v: element.est_hours }
            sheetData["DS Level Summary"]["F" + (i + 2)] = { t: "n", v: element.est_cost }
            sheetData["DS Level Summary"]["G" + (i + 2)] = { t: "n", v: element.actual }
            sheetData["DS Level Summary"]["H" + (i + 2)] = { t: "n", v: element.actual_cost }
            sheetData["DS Level Summary"]["I" + (i + 2)] = { t: "n", v: element.cost_to_complete }
            sheetData["DS Level Summary"]["J" + (i + 2)] = { t: "n", v: element.rev_hours }
            sheetData["DS Level Summary"]["K" + (i + 2)] = { t: "n", v: element.rev_cost }
            sheetData["DS Level Summary"]["L" + (i + 2)] = { t: "n", v: element.delta_cost }
            sheetData["DS Level Summary"]["M" + (i + 2)] = { t: "n", v: element.earned_value }
            sheetData["DS Level Summary"]["N" + (i + 2)] = { t: "n", v: element.ev_percentage }
        }

        sheetData["Task Level Summary"] = {
            '!ref': "A1:Q" + (data.tasks_data.length + 1),
            'A1': { t: "s", v: "Deliverables Schedule ID" },
            'B1': { t: "s", v: "Budget / Change" },
            'C1': { t: "s", v: "Approved / Not Approved" },
            'D1': { t: "s", v: "Deliverables Schedule" },
            'E1': { t: "s", v: "WBS ID" },
            'F1': { t: "s", v: "WBS" },
            'G1': { t: "s", v: "Task ID" },
            'H1': { t: "s", v: "Task" },
            'I1': { t: "s", v: "Budget Hours" },
            'J1': { t: "s", v: "Budget Cost" },
            'K1': { t: "s", v: "Hours to date" },
            'L1': { t: "s", v: "Cost to date" },
            'M1': { t: "s", v: "Cost to Come" },
            'N1': { t: "s", v: "At Completion Hours" },
            'O1': { t: "s", v: "At Completion Cost" },
            'P1': { t: "s", v: "Variance Cost" },
            'Q1': { t: "s", v: "Earned Value" },
            'R1': { t: "s", v: "EV (%)" },
        }

        for (let i = 0; i < data.tasks_data.length; i++) {
            const element = data.tasks_data[i];
            sheetData["Task Level Summary"]["A" + (i + 2)] = { t: "n", v: element.t_pid }
            sheetData["Task Level Summary"]["B" + (i + 2)] = { t: "s", v: this.typeCheck(element.t_wbs_data.pid_data) }
            sheetData["Task Level Summary"]["C" + (i + 2)] = { t: "s", v: this.checkApproval(element.t_wbs_data.pid_data) }
            sheetData["Task Level Summary"]["D" + (i + 2)] = { t: "s", v: element.t_wbs_data.pid_data.name }
            sheetData["Task Level Summary"]["E" + (i + 2)] = { t: "s", v: element.t_wbs_data.id }
            sheetData["Task Level Summary"]["F" + (i + 2)] = { t: "s", v: element.t_wbs_data.name }
            sheetData["Task Level Summary"]["G" + (i + 2)] = { t: "n", v: element.id }
            sheetData["Task Level Summary"]["H" + (i + 2)] = { t: "s", v: element.decs ? element.decs : element.name }
            sheetData["Task Level Summary"]["I" + (i + 2)] = { t: "n", v: element.est_hours }
            sheetData["Task Level Summary"]["J" + (i + 2)] = { t: "n", v: element.est_cost }
            sheetData["Task Level Summary"]["K" + (i + 2)] = { t: "n", v: element.actual }
            sheetData["Task Level Summary"]["L" + (i + 2)] = { t: "n", v: element.actual_cost }
            sheetData["Task Level Summary"]["M" + (i + 2)] = { t: "n", v: element.cost_to_complete }
            sheetData["Task Level Summary"]["N" + (i + 2)] = { t: "n", v: element.rev_hours }
            sheetData["Task Level Summary"]["O" + (i + 2)] = { t: "n", v: element.rev_cost }
            sheetData["Task Level Summary"]["P" + (i + 2)] = { t: "n", v: element.delta_cost }
            sheetData["Task Level Summary"]["Q" + (i + 2)] = { t: "n", v: element.earned_value }
            sheetData["Task Level Summary"]["R" + (i + 2)] = { t: "n", v: element.ev_percentage }
        }

        sheetData["User Level Summary"] = this.userSheet(data)

        exportData = {
            'SheetNames': sheets,
            'Sheets': sheetData
        }

        // console.log(exportData)

        var blob = new Blob([this.s2ab(XLSX.write(exportData, { bookType: 'xlsx', type: 'binary' }))], { type: "application/vnd.ms-excel" });
        saveAs(blob, this.props.projName + " Project Report.xlsx");

    }

    render() {
        return (
            <div>
                <Box display="flex" justifyContent="center" m={2} p={3} bgcolor="background.paper">
                    <Button
                        variant="contained"
                        color="secondary"
                        // className={classes.button}
                        startIcon={<GetAppIcon />}
                        onClick={() => { this.downloadData() }}
                    >
                        Download Report
      </Button>
                </Box>

            </div>
        )
    }
}

export default DownloadReport