import { Box } from '@mui/material';
import React, { Component } from 'react';

class PageRemoved extends Component {
    render() {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Box p={1} display="flex" justifyContent="center">
                        <img style={{ marginBottom: '200px', maxWidth: '50%', alignContent: 'center' }} alt={'Balfour Beatty'} src={process.env.PUBLIC_URL + '/logo512.png'} />
                    </Box>
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    height: '300px',
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <Box p={1} display="flex" justifyContent="center" fontWeight="fontWeightBold" fontSize="h5.fontSize">
                        Page Not Found. Please contact support@bbparam.com.
                    </Box>
                </div>
            </div>
        );
    }
}

export default PageRemoved;