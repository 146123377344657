import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import OverHeadsChangeTable from './OverHeadsChangeTable';
// import { AppBar } from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class OverheadsNonBillableChange extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: 0,
            years: [],
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        var years = []

        // var current_year = moment().year()
        var start_year = moment().year() - 1
        var end_year = moment().year() + 1

        // if (current_year === 2023) {
        //     start_year = 2023
        // }

        for (let index = start_year; index <= end_year; index++) {
            years.push(index)
        }
        let value = years.length - 2
        this.setState({
            years: years,
            value
        })
    }
    render() {

        const handleChange = (event, newValue) => {
            this.setState({
                value: newValue
            })
        };
        return (
            <div>
                <AppBar position="relative" color="default">
                    <Tabs value={this.state.value} onChange={handleChange} aria-label="simple tabs example"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {this.state.years.map((value, index) =>
                            <Tab label={this.state.years[index]} {...a11yProps(index)} />
                        )}
                    </Tabs>
                </AppBar>
                {this.state.years.map((value, index) =>
                    <TabPanel value={this.state.value} index={index}>
                        <OverHeadsChangeTable year={value} disableEdit={value === (moment().year() - 1)} />
                    </TabPanel>
                )}
            </div>
        );
    }
}

export default withSnackbar(OverheadsNonBillableChange);