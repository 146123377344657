import { Box, } from '@material-ui/core'
import React, { Component } from 'react'
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../Helper/CommonThings';

class BroadCastPlayer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            gameURL: '',
            ScoreURL: ''
        }
    }

    componentDidMount() {
        if (this.props.data) {
            // var scoreurl = this.props.data.support_url.split(';')[1]
            this.setState({
                //  ScoreURL: scoreurl,
                isLoading: false,
                gameURL: this.props.data.tournament_link
            })
        } else {

        }
    }

    render() {
        return (
            <div style={{ height: "95%" }}>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) :
                    <Box display='flex' flexDirection='row' justifyContent={'center'} height='100%'>
                        <iframe src={this.state.gameURL} frameBorder="0" title="Owncast" height={(window.innerHeight * 0.98) + "px"} width={(window.innerHeight * 1.645) + "px"} referrerpolicy="origin" scrolling="no" allowfullscreen></iframe>
                        {/* <iframe src={this.state.ScoreURL}
                            style={{ top: '0px', left: '0%', width: '90%', position: 'absolute' }}
                            title="Owncast" frameBorder="0" referrerpolicy="origin"
                            scrolling="no">
                        </iframe> */}
                    </Box>
                }
            </div>
        )
    }
}
export default BroadCastPlayer