import React, { Component } from 'react'
import Service from "../../Networking/networkutils";
import moment from 'moment'
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../../Helper/CookieHelper';
import MaterialTable from 'material-table'
import { errorMessage } from '../../Helper/ErrorMessage';
// import AddIcon from '@material-ui/icons/Add';
// import TeamUserTaskMap from '../TeamUserTaskMap';
import { withStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { getDarkMode, getPrimaryColor } from '../../Helper/CommonThings';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import MuiAlert from '@material-ui/lab/Alert';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#00b300',

        },
        secondary: {
            main: '#cca300',
        },
        error: {
            main: "#ff0000",
        },
        type: getDarkMode(),
    }
})

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });
const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

class WorkHours extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            tabledata: [],

            columns: [
                { title: 'Emp Name', field: 'uname' },
                { title: 'Team', field: 'gname' },
                { title: 'Projects', field: 'proj_name' },

            ],
            date1Selected: false,
            date2Selected: false,
            date1: moment().add(1, 'days').startOf('isoWeek').subtract(1, 'days'),
            date2: moment().add(3, 'weeks').add(1, 'days').endOf('isoWeek').subtract(1, 'days'),
            open: false,
            toDia: [[], []],
            isLoading: true,
            isLoading2: true,
            mapData: {},
            assignTask: [],
            assignTask2: [],
            assignCal: [],
            allRoles: {},
        }

    }


    componentDidMount() {

        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var allRoles = {}
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    allRoles[element.id] = element.name
                }
                this.setState({ allRoles: allRoles })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });


        var start_date1 = moment().add(1, 'days').startOf('isoWeek').subtract(1, 'days');
        var end_date1 = moment().add(3, 'weeks').add(1, 'days').endOf('isoWeek').subtract(1, 'days');
        start_date1.subtract(1, 'days')
        end_date1.add(1, 'days')
        let time = moment('12:00', 'HH:mm');
        let time1 = moment('12:00', 'HH:mm');
        start_date1.set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: time.get('second')
        });
        end_date1.set({
            hour: time1.get('hour'),
            minute: time1.get('minute'),
            second: time1.get('second')
        });
        Service.get("/bb/api/team/tasks/?gid=" + this.props.value.id + "&start__gte=" + start_date1.format('YYYY-MM-DD HH:mm:ss') + "&start__lte=" + end_date1.format('YYYY-MM-DD HH:mm:ss'), {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                var datapull = [];
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    var date = moment(element.start).format('DD/MM/YYYY')
                    var stime = moment(element.start).format('HH mm A');
                    var etime = moment(element.end).format('HH mm A');
                    var whours = moment.duration(moment(element.end).diff(moment(element.start)));
                    var whours1 = ''
                    if (parseFloat(whours.asHours()) < 0.9) {
                        whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                    } else {
                        whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                    }
                    datapull.push({
                        id: element.id,
                        uid: element.uid,
                        gid: element.gid,
                        tid: element.tid,
                        pid: element.pid,
                        ttid: element.ttid,
                        tpid: element.tpid,
                        twbsid: element.twbsid,
                        trolesid: element.trolesid,
                        tmapid: element.tmapid,
                        uemail: element.uemail,
                        uname: element.uname_first + " " + element.uname_last,
                        gname: element.gname,
                        tname: element.tname,
                        tproj_name: element.tproj_name,
                        ttender_name: element.ttender_name,
                        ttname: element.ttname,
                        tuemail: element.tuemail,
                        twbsname: element.twbsname,
                        trolename: element.trolename,
                        title: element.tproj_name + "(" + element.ttname + " - " + element.ttdecs + ")",
                        proj_name: element.proj_name,
                        work_detail: element.work_detail,
                        comments: element.comments,
                        date: date,
                        uid_data: element.uid_data,
                        [date]: whours1,
                        start: new Date(element.start),
                        end: new Date(element.end),
                        is_locked: element.is_locked,
                        is_submitted: element.is_submitted,
                        is_approved: element.is_approved,
                        strstart: element.start,
                        strend: element.end,
                        stime: stime,
                        etime: etime,
                        estimated_hours: element.estimated_hours,
                        created_by: element.created_by,
                        whours: whours1,
                        is_tender: element.is_tender,
                    });
                }


                // console.log(datapull)

                this.setState({ data: datapull, isLoading: false }, () => { this.handleColumns() });

            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
        Service.get("/bb/api/tender/user/task/?uid=" + this.props.alluid + "&end__gte=" + start_date1.format('YYYY-MM-DD HH:mm:ss'), {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                Service.get("/bb/api/tasks/?est_date_from__gte=" + start_date1.format('YYYY-MM-DD HH:mm:ss') + "&est_date_from__lte=" + end_date1.format('YYYY-MM-DD HH:mm:ss') + "&est_date_to__gte=" + start_date1.format('YYYY-MM-DD HH:mm:ss') + "&est_date_to__lte=" + end_date1.format('YYYY-MM-DD HH:mm:ss'), {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res2 => {

                        Service.get("/bb/api/team/tasks/?gid=" + this.props.value.id + "&uid=" + this.props.alluid + "&start__gte=" + start_date1.format('YYYY-MM-DD HH:mm:ss') + "&start__lte=" + end_date1.format('YYYY-MM-DD HH:mm:ss'), {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res1 => {
                                this.setState({ assignTask: res.data, assignTask2: res2.data, assignCal: res1.data, isLoading2: false })
                            })
                            .catch(error => {
                                errorMessage(this.props, error)
                            });

                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });

            })
            .catch(error => {
                errorMessage(this.props, error)
            });


        // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
    }

    calPlanned(rowD) {
        // console.log(rowD)
        var tot = 0;
        for (let i = 0; i < this.state.assignTask.length; i++) {
            const element = this.state.assignTask[i];
            if (element.user_id === rowD.uid) {
                tot = tot + element.planed_hours;
            }
        }

        var tot1 = 0;
        for (let i = 0; i < this.state.assignTask.length; i++) {
            const element = this.state.assignTask[i];
            // console.log(rowD)
            if (element.role === rowD.uid_data.role_id) {
                tot1 = tot1 + element.hours;
            }
        }

        tot = tot + tot1;

        var asData = 0;
        for (let i = 0; i < this.state.assignCal.length; i++) {
            const element = this.state.assignCal[i];
            if (element.uid === rowD.uid && element.is_tender === 1) {
                asData = asData + (moment.duration(moment(element.end).diff(moment(element.start)))).asHours();
            }
        }

        // console.log((tot - asData))

        if ((tot - asData) < 0) {
            return 0;
        } else {
            return (tot - asData).toFixed(1);
        }

    }

    handleColumns() {
        // var date = moment(this.state.date1)
        // var date1 = moment(this.state.date1).format('DD/MM/YYYY');
        // var date2 = moment(this.state.date2);
        var columns = [
            { title: 'Employee Name', field: 'uname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
            {
                title: 'Role', field: 'uid_data.role_id', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                lookup: this.state.allRoles,
            },
            { title: 'Projects', field: 'proj_name', headerStyle: { minWidth: 450 }, cellStyle: { minWidth: 450 }, },
            // { title: 'Tasks', field: 'tname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
            {
                title: 'Unplanned Allocation', field: 'hours_unplanned',
                render: rowData => this.calPlanned(rowData)
            },
            {
                title: moment().add(1, 'days').startOf('isoWeek').subtract(1, 'days').format("DD MMM") + " - " + moment().add(1, 'days').endOf('isoWeek').subtract(1, 'days').format("DD MMM"), field: 'week1',
                headerStyle: { minWidth: 170 }, cellStyle: { minWidth: 170 },
                render: rowData => <ThemeProvider theme={bbColorTheme}><Typography color={(parseInt(rowData['week1']) < 24) ? ("error") : (parseInt(rowData['week1']) > 32) ? ("primary") : ("secondary")} >{rowData['week1']}</Typography></ThemeProvider>
            },
            {
                title: moment().add(1, 'weeks').add(1, 'days').startOf('isoWeek').subtract(1, 'days').format("DD MMM") + " - " + moment().add(1, 'weeks').add(1, 'days').endOf('isoWeek').subtract(1, 'days').format("DD MMM"), field: 'week2',
                headerStyle: { minWidth: 170 }, cellStyle: { minWidth: 170 },
                render: rowData => <ThemeProvider theme={bbColorTheme}><Typography color={(parseInt(rowData['week2']) < 24) ? ("error") : (parseInt(rowData['week2']) > 32) ? ("primary") : ("secondary")} >{rowData['week2']}</Typography></ThemeProvider>
            },
            {
                title: moment().add(2, 'weeks').add(2, 'days').startOf('isoWeek').subtract(1, 'days').format("DD MMM") + " - " + moment().add(2, 'weeks').add(1, 'days').endOf('isoWeek').subtract(1, 'days').format("DD MMM"), field: 'week3',
                headerStyle: { minWidth: 170 }, cellStyle: { minWidth: 170 },
                render: rowData => <ThemeProvider theme={bbColorTheme}><Typography color={(parseInt(rowData['week3']) < 24) ? ("error") : (parseInt(rowData['week3']) > 32) ? ("primary") : ("secondary")} >{rowData['week3']}</Typography></ThemeProvider>
            }
        ];
        //console.log(this.state.dupCol)
        // while (date2 >= date) {

        //     const ccc = date1;
        //     columns.push(
        //         {
        //             title: date.format("DD MMM"), field: date1,
        //             render: rowData => <ThemeProvider theme={bbColorTheme}><Typography color={(parseInt(rowData[ccc]) < 4) ? ("error") : (parseInt(rowData[ccc]) > 7) ? ("primary") : ("secondary")} >{rowData[ccc]}</Typography></ThemeProvider>
        //         }
        //     )
        //     date1 = date.add('days', 1).format('DD/MM/YYYY')

        // }

        var statedata = this.state.data;
        var doneuid = [];
        var toTData = [];
        var userelement = [];

        for (let i = 0; i < statedata.length; i++) {
            var userelement2 = [];
            const element = statedata[i];

            if (i === 0) {
                doneuid.push(element.uid);
                for (let j = 0; j < statedata.length; j++) {
                    const element1 = statedata[j];
                    if (element.uid === element1.uid) {

                        userelement2.push(element1)
                    }
                }
            } else {
                var goodtogo = true;
                for (let k = 0; k < doneuid.length; k++) {
                    const element3 = doneuid[k];

                    if (element3 === element.uid) {
                        goodtogo = false;
                    }
                }

                if (goodtogo) {
                    doneuid.push(element.uid);
                    for (let j = 0; j < statedata.length; j++) {
                        const element1 = statedata[j];
                        if (element.uid === element1.uid) {

                            userelement2.push(element1)
                        }

                    }
                }
            }

            if (userelement2.length !== 0) {
                userelement.push(userelement2)
            }

        }

        //date
        for (let i = 0; i < userelement.length; i++) {
            var whdate = [];
            var wh = [];
            var teams = [];
            var proj = [];
            var tasks = [];
            const element = userelement[i];
            for (let j = 0; j < element.length; j++) {
                const element1 = element[j];
                var goodtogo1 = true;
                for (let k = 0; k < whdate.length; k++) {
                    const element2 = whdate[k];
                    if (element2 === element1.date) {
                        goodtogo1 = false;
                    }
                }

                if (goodtogo1) {
                    // console.log('Total 111 time is')
                    whdate.push(element1.date);
                    var hours = []
                    for (let m = 0; m < element.length; m++) {
                        const element3 = element[m];
                        if (element3.date === element1.date) {
                            hours.push(element3[element3.date])
                        }
                        var teamgo = true;
                        for (let n = 0; n < teams.length; n++) {
                            const element4 = teams[n];
                            if (element4 === element3.gname) {
                                teamgo = false;
                            }
                        }
                        if (teamgo) {
                            teams.push(element3.gname)
                        }
                        var projgo = true;
                        for (let n = 0; n < proj.length; n++) {
                            const element4 = proj[n];
                            if (element3.is_tender === 0) {
                                if (element4 === element3.proj_name) {
                                    projgo = false;
                                }
                            } else {
                                if (element4 === element3.ttender_name) {
                                    projgo = false;
                                }
                            }

                        }
                        //console.log(element3.proj_name)
                        if (projgo) {
                            if (element3.is_tender === 0) {
                                proj.push(element3.proj_name)
                            } else {
                                proj.push(element3.ttender_name)
                                // + " - " + element3.tproj_name)
                            }

                        }
                        var taskgo = true;
                        for (let n = 0; n < tasks.length; n++) {
                            const element4 = tasks[n];
                            if (element3.is_tender === 0) {
                                if (element4 === element3.tname) {
                                    taskgo = false;
                                }
                            } else {
                                if (element4 === element3.ttname) {
                                    taskgo = false;
                                }
                            }

                        }
                        //console.log(element3.proj_name)
                        if (taskgo) {
                            if (element3.is_tender === 0) {
                                tasks.push(element3.tname)
                            } else {
                                tasks.push(element3.ttname)
                            }

                        }
                    }
                    // console.log(teams)
                    const totalDurations = hours.slice(1)
                        .reduce((prev, cur) => moment.duration(cur).add(prev),
                            moment.duration(hours[0]))
                    // wh.push(moment.utc(totalDurations.asMilliseconds()).format("HH:mm"))
                    wh.push(totalDurations)
                }

            }
            var jsoncons = {
                id: element[0].id,
                uid: element[0].uid,
                gid: element[0].gid,
                pid: element.pid,
                tid: element.tid,
                tname: tasks.join(),
                uemail: element[0].uemail,
                uname: element[0].uname,
                uid_data: element[0].uid_data,
                gname: teams.join(),
                title: element[0].title,
                proj_name: proj.join(),
                work_detail: element[0].work_detail,
                start: element[0].start,
                end: element[0].end,
                date: element[0].date,
                stime: element[0].stime,
                etime: element[0].etime,
                whours: element[0].whours1,
                created_by: element[0].created_by,
            }
            var week1 = 0, week2 = 0, week3 = 0;
            for (let k = 0; k < whdate.length; k++) {
                const element = whdate[k];
                jsoncons[element] = wh[k];
            }
            // console.log(jsoncons)
            var data1week = moment().add(1, 'days').startOf('isoWeek').subtract(1, 'days');
            for (let i = 0; i < 7; i++) {
                if (week1 === 0 && jsoncons[data1week.format('DD/MM/YYYY')]) {
                    week1 = jsoncons[data1week.format('DD/MM/YYYY')]
                } else if (jsoncons[data1week.format('DD/MM/YYYY')]) {
                    // console.log(week1)
                    week1 = week1.add(jsoncons[data1week.format('DD/MM/YYYY')])
                }
                data1week = data1week.add(1, 'days');
            }
            // console.log(week1)
            if (week1 !== 0) {
                // var timemom = moment.utc(week1.asMilliseconds())
                var hourshere = Math.floor(week1.asHours())
                var minshere = Math.floor(week1.asMinutes()) - hourshere * 60;
                if (minshere === 0) {
                    minshere = "00"
                }
                jsoncons['week1'] = hourshere + ":" + minshere;
            }

            var data2week = moment().add(1, 'weeks').add(1, 'days').startOf('isoWeek').subtract(1, 'days');
            for (let i = 0; i < 7; i++) {
                if (week2 === 0 && jsoncons[data2week.format('DD/MM/YYYY')]) {
                    week2 = jsoncons[data2week.format('DD/MM/YYYY')]
                } else if (jsoncons[data2week.format('DD/MM/YYYY')]) {

                    week2 = week2.add(jsoncons[data2week.format('DD/MM/YYYY')])
                }
                data2week = data2week.add(1, 'days');
            }
            // console.log(week2)
            if (week2 !== 0) {
                // var timemom2 = moment.utc(week2.asMilliseconds())
                var hourshere2 = Math.floor(week2.asHours())
                var minshere2 = Math.floor(week2.asMinutes()) - hourshere2 * 60;
                if (minshere2 === 0) {
                    minshere2 = "00"
                }
                jsoncons['week2'] = hourshere2 + ":" + minshere2;
            }

            var data3week = moment().add(2, 'weeks').add(1, 'days').startOf('isoWeek').subtract(1, 'days');
            for (let i = 0; i < 7; i++) {
                if (week3 === 0 && jsoncons[data3week.format('DD/MM/YYYY')]) {
                    week3 = jsoncons[data3week.format('DD/MM/YYYY')]
                } else if (jsoncons[data3week.format('DD/MM/YYYY')]) {

                    week3 = week3.add(jsoncons[data3week.format('DD/MM/YYYY')])
                }
                data3week = data3week.add(1, 'days');
            }
            // console.log(week3)
            if (week3 !== 0) {
                // var timemom3 = moment.utc(week3.asMilliseconds())
                var hourshere3 = Math.floor(week3.asHours())
                var minshere3 = Math.floor(week3.asMinutes()) - hourshere3 * 60;
                if (minshere3 === 0) {
                    minshere3 = "00"
                }
                jsoncons['week3'] = hourshere3 + ":" + minshere3;
            }

            toTData.push(jsoncons);
        }


        var todata = [];
        todata.push(columns)
        todata.push(toTData)
        //console.log(todata)
        this.setState({
            toDia: todata
        })

    }

    render() {

        // const { classes } = this.props;

        // const handleClose = () => {
        //     this.setState({
        //         open: false,

        //     })
        // };

        // const handleDate1Change = (date) => {
        //     //console.log(moment(date).isoWeekday())
        //     this.setState({
        //         date1: date,
        //         date1Selected: true,

        //     }, () => { this.handleColumns() })
        // };

        // const handleDate2Change = (date) => {
        //     this.setState({
        //         date2: date,
        //         date2Selected: true,
        //     }, () => { this.handleColumns() })
        // };

        const classes = this.props.classes
        return (
            <div>
                {(this.state.isLoading || this.state.isLoading2) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <Dialog fullScreen open={this.props.open} onClose={this.props.OnClose} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.props.onClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        3 Week look ahead
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Alert severity="info">Click on the Employee name to view their Calendar!</Alert>
                            <MaterialTable
                                title="Workload - 3 Week Lookahead"
                                columns={this.state.toDia[0]}
                                data={this.state.toDia[1]}
                                options={{
                                    padding: "dense",
                                    // filtering: true,
                                    exportButton: { csv: true },
                                    // exportButton: true,
                                    pageSize: 10,
                                }}
                                onRowClick={(event, rowData, togglePanel) => this.props.launchAuto(rowData.uid, rowData.gid)}
                            // components={{
                            //     Toolbar: props => (
                            //         <div>
                            //             Click below the Employee name to view their Calendar
                            //         </div>
                            //     ),
                            // }}
                            />

                        </Dialog>


                        {/* <Dialog fullScreen open={this.state.open} onClose={handleClose} TransitionComponent={Transition}>
                                <AppBar className={classes.appBar}>
                                    <Toolbar>
                                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography variant="h6" className={classes.title}>
                                            {"Task Mapping for " + this.state.mapData.uname}
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                
                            </Dialog> */}
                    </div>
                )}

            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(WorkHours));