import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { getCookie } from '../Helper/CookieHelper';
import Service from '../Networking/networkutils';
import { errorMessage } from '../Helper/ErrorMessage';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete'

const user = JSON.parse(getCookie("bb_ts_user"));
const token = getCookie("bb_ts_token");

var autocompdata = '';

class AllocateRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addHours: 0,
            area: 'in',
            addRoleDisabled: false,
            roles: [],
            taskData: this.props.value,
            data: this.props.data
        };
    }

    componentDidMount() {
        this.getRoles();
    }

    addRole() {
        if (this.state.addHours && this.state.addHours > 0) {

            var roleExist = false;

            if (autocompdata !== '') {
                for (let i = 0; i < this.state.data.length; i++) {
                    const element = this.state.data[i];
                    if (element.t_role_id === autocompdata.id && element.country === this.state.area) {
                        roleExist = true;
                        break;
                    }
                }

                if (!roleExist) {
                    var postData = {};
                    // var data1 = this.state.taskData;
                    postData.t_pid = this.state.taskData.t_pid;
                    postData.t_wbs = this.state.taskData.t_ohlwbs;
                    postData.t_tasks = this.state.taskData.id;
                    postData.t_role = autocompdata.id;
                    postData.remark = '';
                    postData.created_by = user.email;
                    postData.name = autocompdata.name;
                    postData.grade = autocompdata.grade.toLowerCase();
                    postData.hours = 0;
                    postData.req_hours = this.state.addHours;
                    postData.country = this.state.area;
                    postData.goon = 1;
                    postData.hours_rev_history = [{
                        user: user.id,
                        user_name: user.first_name + " " + user.last_name,
                        start: this.state.addHours,
                        date_modified: new Date(),
                        rev_new: this.state.addHours,
                    }]

                    // console.log(postData)
                    // 
                    this.setState({ addRoleDisabled: true })
                    Service.post("/bb/api/tender/tasks/rolesrevhistory/", {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: postData,
                    })
                        .then(res => {
                            this.setState({
                                addRoleDisabled: false
                            })
                            // console.log(res.data)
                            this.props.enqueueSnackbar('Roles updated successfully', {
                                variant: 'success'
                            });
                            if (this.props.closeDialog) {
                                this.props.closeDialog(true);
                            }
                        })
                        .catch(error => {
                            this.setState({ addRoleDisabled: false })
                            console.log(error)
                            errorMessage(this.props, error)
                        });
                } else {
                    this.setState({
                        isLoading: false,
                        openAdd: false,

                    })
                    if (this.props.closeDialog) {
                        this.props.closeDialog(false);
                    }
                    this.props.enqueueSnackbar('Role with same country configuration already exist!', {
                        variant: 'warning'
                    });
                }

            } else {
                this.props.enqueueSnackbar('Select Role to Add', {
                    variant: 'warning'
                });
            }
        } else {
            this.props.enqueueSnackbar('Hours must be greater than 0!', {
                variant: 'warning'
            });
        }
    }

    getRoles() {
        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.length === 0) {
                    this.props.enqueueSnackbar('Goto Roles tab to initiate default roles!', {
                        variant: 'warning'
                    });
                } else {
                    this.setState({
                        roles: res.data,
                    });
                }
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    onTagsChange = (event, values) => {
        autocompdata = values;
    }

    render() {

        const handleAddHours = (e) => {
            this.setState({ addHours: e.target.value })
        };

        return (
            <div>
                <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                >
                    <Autocomplete
                        id="combo-box-demo"
                        options={this.state.roles}
                        getOptionLabel={(option) => option.name}
                        onChange={this.onTagsChange}
                        style={{ marginTop: '15px' }}
                        renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
                    />
                    <FormControl variant="outlined" style={{ width: '100%', marginTop: '15px' }}>
                        <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.area}
                            onChange={e => { this.setState({ area: e.target.value }) }}
                            label="Country"
                        >
                            <MenuItem value="in">India</MenuItem>
                            <MenuItem value="uk">UK</MenuItem>
                            <MenuItem value="sl">Srilanka</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        id="hours"
                        type={'number'}
                        label="Hours"
                        variant="outlined"
                        style={{ width: '100%', marginTop: '15px' }}
                        value={this.state.addHours}
                        onChange={handleAddHours}
                    />
                    <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        marginTop='15px'
                        justifyContent='flex-end'
                    >
                        <Button size='small' variant='contained' color="primary" onClick={() => {
                            if (this.props.closeDialog) {
                                this.props.closeDialog(false);
                            }
                        }} style={{ marginRight: "10px" }}>
                            Cancel
                        </Button>
                        <Button size='small' variant='contained' disabled={this.state.addRoleDisabled} onClick={(e) => { this.addRole() }} color="primary">
                            Add Role
                        </Button>
                    </Box>
                </Box>
            </div>
        );
    }
}

export default withSnackbar(AllocateRole);