import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { Chart } from "react-google-charts";
// import { LoopCircleLoading } from 'react-loadingg';
import { Box } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { getDarkMode, getPrimaryColor } from '../../Helper/CommonThings';
import { LoopCircleLoading } from 'react-loadingg';

const useStyles = (theme) => ({
    loaderWrapper: {
        position: 'fixed',
        background: '#fff',
        height: '100%',
        width: '100%',
        'z-index': '999'
    }
});

class DemandVsCapacityGraph extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            chartData: [],
            labels: [],
            demand: [],
            capacity: [],
            otherdemand: [],
            revenue: [],
            potentialRevenue: [],
            maxPointOfChart: 10,
            maxPointOfChartY: 10,
            isLoading: true,
        }
    }

    componentDidMount() {
        var data = this.props.data;

        let chartData = []
        var labels = []
        var demand = []
        var capacity = []
        var otherdemand = []
        var revenue = []
        var potentialRevenue = []
        var maxPointOfChart = 0
        var maxPointOfChartY = 0
        if (this.props.timeUnit === 'months') {
            // chartData.push(['Month', 'Demand', 'Capacity'])
            // data['month_data'].forEach(element => {
            //     let arr = [element.name, element.demand, element.capacity]
            //     chartData.push(arr)
            // });
            for (let i = 0; i < data['month_data'].length; i++) {
                const element = data['month_data'][i];
                if (element) {
                    labels.push(element.name)
                    demand.push((element.demand).toFixed(1))
                    capacity.push(element.capacity.toFixed(1))
                    otherdemand.push(element.other_demand.toFixed(1))
                    revenue.push(element.demand_cost.toFixed(1))
                    potentialRevenue.push(element.capacity_cost.toFixed(1))
                    if ((element.demand + element.other_demand) > maxPointOfChart) {
                        maxPointOfChart = element.demand + element.other_demand
                    }
                    if ((element.capacity) > maxPointOfChart) {
                        maxPointOfChart = element.capacity
                    }
                    if ((element.demand_cost + element.capacity_cost) > maxPointOfChartY) {
                        maxPointOfChartY = element.demand_cost + element.capacity_cost
                    }
                }
            }
            maxPointOfChart += maxPointOfChart * .08
            maxPointOfChartY += maxPointOfChartY * .08
            this.setState({ labels: labels, demand: demand, capacity: capacity, otherdemand: otherdemand, revenue: revenue, potentialRevenue, maxPointOfChart, maxPointOfChartY })
        }
        if (this.props.timeUnit === 'weeks') {
            // chartData.push(['Weeks', 'Demand', 'Capacity'])
            // data['week_data'].forEach(element => {
            //     let arr = ['W' + element.week_number, element.demand, element.capacity]
            //     chartData.push(arr)
            // });
            for (let i = 0; i < data['week_data'].length; i++) {
                const element = data['week_data'][i];
                if (element) {
                    labels.push('W' + element.week_number)
                    demand.push((element.demand).toFixed(1))
                    capacity.push(element.capacity.toFixed(1))
                    otherdemand.push(element.other_demand.toFixed(1))
                    revenue.push(element.demand_cost.toFixed(1))
                    potentialRevenue.push(element.capacity_cost.toFixed(1))
                    if ((element.demand + element.other_demand) > maxPointOfChart) {
                        maxPointOfChart = element.demand + element.other_demand
                    }
                    if ((element.capacity) > maxPointOfChart) {
                        maxPointOfChart = element.capacity
                    }
                    if ((element.demand_cost + element.capacity_cost) > maxPointOfChartY) {
                        maxPointOfChartY = element.demand_cost + element.capacity_cost
                    }
                }
            }
            maxPointOfChart += maxPointOfChart * .08
            maxPointOfChartY += maxPointOfChartY * .08
            this.setState({ labels: labels, demand: demand, capacity: capacity, otherdemand: otherdemand, revenue: revenue, potentialRevenue, maxPointOfChart, maxPointOfChartY })
        }

        this.setState({ chartData: chartData, isLoading: false })
    }

    showRevenue() {
        if (this.revenue && this.revenue.current) {
            this.setState({ isLoading: true }, () => {
                setTimeout(() => {
                    this.setState({ isLoading: false })
                }, 100);
            })
        }
    }

    showGraph() {
        var options = {
            series: [
                {
                    name: "Capacity",
                    type: "column",
                    data: this.state.capacity
                },
                {
                    name: "Other Demand",
                    type: "area",
                    data: this.state.otherdemand
                }, {
                    name: "Demand",
                    type: "area",
                    data: this.state.demand
                },
            ],
            chart: {
                type: "line",
                height: 350,
                stacked: false,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            stroke: {
                width: [0, 2, 5, 5, 5],
                curve: 'smooth'
            },
            theme: {
                mode: getDarkMode() === 'dark' ? 'dark' : 'light',
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: "bottom" // top, center, bottom
                    },
                    columnWidth: this.props.timeUnit === 'months' ? '30%' : '50%',
                    horizontal: false
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                    if (seriesIndex !== 0) {
                        if (w.config.series[2].data[dataPointIndex] < (w.config.series[1].data[dataPointIndex] * 0.1)) {
                            if (seriesIndex === 2) {
                                var returnVal = ""
                                if (w.config.series[1].data[dataPointIndex] > 0.01) {
                                    returnVal = w.config.series[1].data[dataPointIndex]
                                }
                                if (w.config.series[2].data[dataPointIndex] > 0.01) {
                                    if (w.config.series[1].data[dataPointIndex] > 0.01) {
                                        returnVal += ", " + w.config.series[2].data[dataPointIndex]
                                    } else {
                                        returnVal = w.config.series[2].data[dataPointIndex]
                                    }
                                }
                                return returnVal
                            } else if (seriesIndex === 1) {
                                return ""
                            }
                        }
                    }
                    if (val) {
                        return val;
                    }
                    return '';
                },
                distributed: true,
                style: {
                    // fontSize: "12px",
                    colors: [getDarkMode() === 'dark' ? "#F5F5F5" : "#707070"]
                }
            },
            fill: {
                opacity: [0.25, 0.45, 0.45, 1, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },
            labels: this.state.labels,
            yaxis: [
                {
                    seriesName: 'Capacity',
                    showAlways: true,
                    opposite: false,
                    title: {
                        text: "Demand/Capacity",
                    },
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                    },
                    min: 0,
                    max: this.state.maxPointOfChart
                },
                {
                    seriesName: 'Other Demand',
                    showAlways: true,
                    show: false,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                    },
                    opposite: false,
                    min: 0,
                    max: this.state.maxPointOfChart
                },
                {
                    seriesName: 'Demand',
                    showAlways: true,
                    show: false,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                    },
                    opposite: false,
                    min: 0,
                    max: this.state.maxPointOfChart
                },

            ],
            legend: {
                horizontalAlign: 'left',
                offsetX: 40
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y;
                        }
                        return y;
                    }
                }
            }
        };

        if (this.props.showRevenue) {
            options.series.push({
                name: "Revenue",
                type: "line",
                data: this.state.revenue,
            }, {
                name: "Revenue at 80% Productivity",
                type: "line",
                data: this.state.potentialRevenue
            })
            options.yaxis.push(
                {
                    seriesName: 'Revenue',
                    showAlways: true,
                    opposite: true,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                    },
                    title: {
                        text: "Revenue (in £)",
                    },
                    min: 0,
                    max: this.state.maxPointOfChartY
                }, {
                seriesName: 'Revenue at 80% Productivity',
                showAlways: true,
                show: false,
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                },
                min: 0,
                max: this.state.maxPointOfChartY
            },
            )
        }
        return <div style={{ width: '100%' }}><ReactApexChart options={options} series={options.series} height={'450px'} width={'100%'} type="bar" /></div>
    }

    render() {
        const roleId = this.props.data.id
        return (
            <div>
                {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                    <React.Fragment>
                        <div style={{ width: '100%' }} id={'dvc' + roleId}>
                            <Box display="flex" p={1} bgcolor="background.paper">
                                {this.props.data ?
                                    this.showGraph()
                                    :
                                    <h2>No demand available</h2>}
                            </Box>
                        </div>
                    </React.Fragment>}
            </div>

        )
    }
}

export default withStyles(useStyles)(DemandVsCapacityGraph)