import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import Service from '../Networking/networkutils'

const token = getCookie("bb_ts_token");

class EMReqHis extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      data: []
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    Service.get("/bb/api/tender/hours/request/?uid=" + this.props.uid + "&ttid=" + this.props.value.id, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        this.setState({
          data: res.data,
          isLoading: false,
        })
      })
      .catch(error => {
        // console.log(error)
        errorMessage(this.props, error)
      });
  }

  putData(data) {
    // console.log(data)
    Service.get("/bb/api/tender/hours/request/" + data.id + "/", {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        if (res.data.status === 0) {
          Service.put("/bb/api/tender/hours/request/" + data.id + "/", {
            headers: {
              Authorization: "Token " + token,
            },
            data: data,
          })
            .then(res => {
              this.props.enqueueSnackbar('Updated Successfully!', {
                variant: 'success'
              });
            })
            .catch(error => {
              // console.log(error)
              errorMessage(this.props, error)
            });
        } else {
          this.props.enqueueSnackbar('Failed!', {
            variant: 'error'
          });
        }

      })
      .catch(error => {
        // console.log(error)
        errorMessage(this.props, error)
      });
  }

  render() {
    return (
      <div>
        <MaterialTable
          title={"Request History for " + (this.props.value.decs ? this.props.value.decs : this.props.value.name)}
          columns={[
            {
              title: 'Hours', field: 'hours_needed',
              // lookup: this.state.projectLookUp
              // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
            },
            {
              title: 'Reason Type', field: 'reason_type', editable: 'never',
              lookup: { 0: 'NA!', 1: 'Rework - external', 2: 'Rework - internal', 3: 'Additional info received', 4: 'Change in original scope', 5: 'Incorrect Estimate', 6: 'Extended work scope', 7: 'Increase within budget', },
            },
            {
              title: 'Comment', field: 'comment', editable: 'never',
              // lookup: this.state.projectLookUp
              // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
            },
            {
              title: 'Requested Date', field: 'date_created', type: 'date', defaultSort: 'desc', editable: 'never',
              // lookup: this.state.projectLookUp
              // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
            },

            {
              title: 'Status', field: 'status', editable: 'never',
              lookup: { 0: 'Pending',  1: 'Approved', 2: 'Rejected' },
            },
            {
              title: 'Approved By', field: 'approved_by_data.first_name', editable: 'never',
              // render: rowData => rowData.approved_by_data ? rowData.approved_by_data.first_name : null
            },

          ]}
          data={this.state.data}
          isLoading={this.state.isLoading}
          options={{
            padding: "dense",
            doubleHorizontalScroll: true,
            paging: false

          }}
          editable={{
            isEditable: rowData => rowData.status === 0,
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                if (newData.hours_needed) {
                  if (parseFloat(newData.hours_needed) <= 100) {
                    this.putData(newData)
                    setTimeout(() => {
                      resolve();
                      if (oldData) {
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }, 60);
                  } else {
                    setTimeout(() => {
                      resolve();
                      if (oldData) {
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = oldData;
                          return { ...prevState, data };
                        });
                      }
                    }, 60);
                    this.props.enqueueSnackbar('Not more than 100 hours is allowed in single request!', {
                      variant: 'warning'
                    });
                  }
                } else {
                  this.props.enqueueSnackbar("Request hours can't be empty!", {
                    variant: 'warning'
                  });
                }

              }),
          }}
        />
      </div>
    )
  }
}

export default withSnackbar(EMReqHis)
