import React, { Component } from 'react'
import { withSnackbar } from 'notistack'
import Service from "../../Networking/networkutils";
import { getCookie } from '../../Helper/CookieHelper';
import MaterialTable from 'material-table';
import { Box } from '@material-ui/core';
import Notes from './Notes';
import { errorMessage } from '../../Helper/ErrorMessage';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
class Tracker extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            userData: [],
            edc_contact_list: {},
        }
    }


    componentDidMount() {
        Service.get('/bb/api/tender/bidtracker/?lead_dicipline=edc', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data })
            })
            .catch(error => {
                // console.log(error)
               errorMessage(this.props, error)
            });

        Service.get(`/bb/api/users/?office_loc=1`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var contact = {};
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    contact[element.id] = element.last_name + ", " + element.first_name;
                }
                this.setState({ edc_contact_list: contact })
            })
            .catch(error => {
               errorMessage(this.props, error)
            });
    }

    putOps(data) {

        if (data.tracker) {
            Service.put(`/bb/api/tender/opportunity/tracker/` + data.tracker, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data.tracker_data,
            })
                .then(res => {
                    data.tracker = res.data.id;
                    data.tracker_data = res.data;
                    this.props.enqueueSnackbar('Data updated successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        } else {
            Service.post(`/bb/api/tender/opportunity/tracker/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data.tracker_data,
            })
                .then(res => {
                    // console.log(res.data)
                    data.tracker = res.data.id;
                    data.tracker_data = res.data;
                    Service.put(`/bb/api/tender/bidtracker/` + data.id, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: data,
                    })
                        .then(res1 => {
                            this.props.enqueueSnackbar('Data updated successfully!', {
                                variant: 'success'
                            });
                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                        });

                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    render() {
        return (
            <div>
                <MaterialTable
                    key={3}
                    title="Opportunity Tracker"
                    columns={[
                        {
                            title: 'EDC Discipline', field: 'edc_dicipline', editable: 'never',
                            headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                            lookup: { 'mep': 'MEP', 'bim': 'BIM', 'dig': 'DIG', 'pln': 'PLN', 'csd': 'CSD', 'gis': 'GIS' }
                        },
                        {
                            title: 'Status', field: 'tracker_data.status',
                            lookup: {
                                0: 'To Pursue', 1: 'Pursuing', 3: 'Await Response', 4: 'Closed. No Response', 5: 'Final Stage',
                                6: 'Lost', 7: 'Lost - No Budget', 8: 'Completed',
                            },
                        },
                        {
                            title: 'Client', field: 'client_name', editable: 'never',
                        },

                        {
                            title: 'Opportunity Name', field: 'opportunity', editable: 'never',

                        },

                        {
                            title: 'EDC Contact', field: 'tracker_data.edc_contact',
                            lookup: this.state.edc_contact_list,
                        },

                        {
                            title: 'Appx Fee (£)', field: 'tracker_data.appx_fee', type: 'numeric',

                        },
                        {
                            title: 'UK Contact', field: 'tracker_data.uk_contact',
                        },

                    ]}
                    data={this.state.data}
                    detailPanel={rowData => {
                        return (
                            <Box m={1} p={1}>
                                <Notes value={rowData} />
                            </Box>
                        )
                    }}
                    options={{
                        padding: "dense",
                        pageSize: 20,
                        pageSizeOptions: [10, 20, 50],
                    }}
                    editable={(access && access.access && access.access.l8a.l8a_b) ? (
                        {
                            onRowUpdate: (newData, oldData) =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        if (oldData) {
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                this.putOps(newData)
                                                return { ...prevState, data };
                                            });
                                        }
                                    }, 600);
                                }),
                        }
                    ) : (null)}
                />
            </div>
        )
    }
}

export default withSnackbar(Tracker);