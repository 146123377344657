import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import DvsCReport from './DvsCReportToolbar';
import { Button, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../../Helper/CookieHelper';
import Service from "../../Networking/networkutils";
import { errorMessage } from '../../Helper/ErrorMessage';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DownloadDialog from './DownloadDialog';
import { getPrimaryColor } from '../../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
// const user = JSON.parse(getCookie("bb_ts_user"));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
    appBar: {
        position: 'fixed',
        top: 0,
    },
    formContainer: {
        marginTop: '64px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
});

var projectNames = ""

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class DvsCHome extends React.Component {
    constructor() {
        super()
        this.state = {
            modelOpen: false,
            data: [],
            selectedProjects: [],
            reportModal: false,
            opportunity: [],
            subSector: [],
            subVerticle: [],
            ownerRegion: [],
            ownerDelivery: [],
            selectedSubSector: [],
            selectedSubVerticle: [],
            selectedOwnerRegion: [],
            selectedOwnerDelivery: [],
            isLoading: true,
            regionData: [],
            clientData: [],
            leadDisciplineData: [
                { 'name': 'OHL', value: 'ohl' },
                { 'name': 'Cabling', value: 'cabling' },
                { 'name': 'Substation', value: 'substation' },
                { 'name': 'Multi Utilities', value: 'multi_utilities' },
                { 'name': 'Technical', value: 'technical' },
                { 'name': 'EDC', value: 'edc' }
            ],
            EMData: [],
            projectsData: [],
            selectedRegion: [],
            selectedClient: [],
            selectedLeadDiscipline: [],
            selectedStage: [],
            stageData: [
                { value: 0, name: 'Stage 2 - Tender Development' },
                { value: 10, name: 'Stage 5 - Mobilising' },
                { value: 11, name: 'Stage 1a - Tender Mobilising' }
            ],
            downloadDialog: false,
            BillableList: [{
                id: 0,
                name: "Non Billable"
            },
            {
                id: 1,
                name: "Billable"
            }],
            selectedBillable: [{
                id: 0,
                name: "Non Billable"
            }, {
                id: 1,
                name: "Billable"
            }],
        }
        this.downloadDialogClose = this.downloadDialogClose.bind(this)
    }

    componentDidMount() {
        this.getRegion();
        this.getProjects();

    }

    getRegion() {
        Service.get2('/bb/api/region/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({ regionData: res.data })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }


    getProjects() {
        var url = ""
        var typesdata = []
        if (access.access.l4a) {
            if (access.access.l4a.l4a_g) {
                typesdata.push("ohl")
            }
    
            if (access.access.l4a.l4a_h) {
                typesdata.push("cabling")
            }
    
            if (access.access.l4a.l4a_i) {
                typesdata.push("substation")
            }
    
            if (access.access.l4a.l4a_m) {
                typesdata.push("edc")
            }
    
            if (access.access.l4a.l4a_l) {
                typesdata.push("multi_utilities")
            }
            if (access.access.l4a.l4a_af) {
                typesdata.push("technical")
            }
        }
        
        if (access.access.l4a && access.access.l4a.l4a_d) {
            url = "/bb/api/tender/project/get_project_by_access/?audit_status=0,10&filter_type=2"
        } else {
            if (typesdata.length > 0) {
                url = "/bb/api/tender/project/get_project_by_access/?audit_status=0,10&filter_type=1&filter_data=" + typesdata.toString()
            } else {
                url = "/bb/api/tender/project/get_project_by_access/?audit_status=0,10&filter_type=2"
            }
            
        }
        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.projectsData = res.data

                this.setState({
                    // projectsData: res.data,
                    isLoading: false
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    projectSelected(e, value) {
        value.forEach(ele => {
            if (ele.id === 0) {
                value = this.state.projectsData;
            }
        })
        this.setState({ selectedProjects: value });
    }


    closeReport = () => {
        this.setState({ reportModal: false })
    }

    setValue(e, value) {
        // console.log(value)
        value.forEach(ele => {
            if (ele.name === "Select All") {
                // this.setState({selectedFilter : this.state.opportunity})
                value = this.state.opportunity
            }
        })
        this.setState({ selectedFilter: value });
    }

    filterChange = (name, value) => {
        this.setState({
            [name]: value
        }, () => this.filterProjects())
    }

    filterProjects() {

        if (
            (!this.state.selectedRegion || !this.state.selectedRegion.length) &&
            (!this.state.selectedLeadDiscipline || !this.state.selectedLeadDiscipline.length) &&
            (!this.state.selectedStage || !this.state.selectedStage.length)
        ) {
            this.setState({ projectsData: [], selectedProjects: [] })
            return;
        }

        let allProjects = [...this.projectsData]
        let filter1 = [];
        let filter2 = [];
        let filter3 = [];
        if (!this.state.selectedRegion || !this.state.selectedRegion.length) {
            filter1 = [...this.projectsData]
        }
        else {
            allProjects.forEach(ele => {
                for (let i = 0; i < this.state.selectedRegion.length; i++) {
                    if (this.state.selectedRegion[i].id === ele.tenderbid_data.region) { filter1.push(ele) }
                }
            })
        }

        if (!this.state.selectedLeadDiscipline || !this.state.selectedLeadDiscipline.length) {
            filter2 = [...filter1]
        }
        else {
            filter1.forEach(ele => {
                for (let i = 0; i < this.state.selectedLeadDiscipline.length; i++) {
                    if (this.state.selectedLeadDiscipline[i].value === ele.tenderbid_data.lead_dicipline) { filter2.push(ele) }
                }
            })
        }

        if (!this.state.selectedStage || !this.state.selectedStage.length) {
            filter3 = [...filter2]
        }
        else {
            filter2.every(ele => {
                let continueLoop = true
                for (let i = 0; i < this.state.selectedStage.length; i++) {
                    if (this.state.selectedStage[i].value === ele.audit_status) { filter3.push(ele) }
                    if (this.state.selectedStage[i].value === 11) {
                        filter3 = this.projectsData;
                        continueLoop = false
                        break;
                    }
                }
                return continueLoop;
            })
        }
        filter3.unshift({ id: 0, name: 'Select All', tender_name: 'Select All' });
        this.setState({ projectsData: filter3 })
    }

    viewReport = () => {

        this.setState({ isLoading: true })
        let pid = [];
        let billable = [];
        this.state.selectedProjects.forEach(ele => pid.push(ele.id));
        this.state.selectedBillable.forEach(ele => billable.push(ele.id));

        Service.post2("/bb/api/report/view/demand_vs_capacity_view/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: { pid: pid, audit_status: [0, 10, 11], billable: billable }
        })
            .then(res => {
                for (let i = 0; i < this.state.selectedProjects.length; i++) {
                    const element = this.state.selectedProjects[i];
                    if (i !== 0) {
                        projectNames += ", " + element.tender_name
                    } else {
                        projectNames = element.tender_name
                    }
                }
                this.setState({ data: res.data, isLoading: false, reportModal: true })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false })
            })
    }

    downloadReport = () => {


        this.setState({ downloadDialog: true });


        // this.setState({ isLoading: true })
        // let pid = [];
        // this.state.selectedProjects.forEach(ele => pid.push(ele.id));
        // Service.post("/bb/api/report/view/demand_vs_capacity_view/", {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        //     data: { pid: pid, audit_status: [0, 10, 11] }
        // })
        //     .then(res => {
        //         this.setState({ data: res.data, isLoading: false, downloadDialog: true })
        //     })
        //     .catch(error => {
        //         this.props.enqueueSnackbar('Something went wrong!', {
        //             variant: 'error'
        //         });
        //         this.setState({ isLoading: false })
        //     })
    }

    downloadDialogOpen = () => {
        this.setState({ downloadDialog: true })
    }

    downloadDialogClose = () => {
        this.setState({ downloadDialog: false })
    }

    render() {
        return (<>
            {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                <div>
                    <Grid container style={{ marginBottom: '20px' }} spacing={2}>
                        <Grid item sm={4}>
                            <Autocomplete
                                multiple
                                id="region"
                                options={this.state.regionData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.filterChange('selectedRegion', value)}
                                value={this.state.selectedRegion}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Region" placeholder="Region" />
                                )}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <Autocomplete
                                multiple
                                id="lead-discipline"
                                options={this.state.leadDisciplineData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.filterChange('selectedLeadDiscipline', value)}
                                value={this.state.selectedLeadDiscipline}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Lead Discipline" placeholder="Lead Discipline" />
                                )}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <Autocomplete
                                multiple
                                id="stage"
                                options={this.state.stageData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => (option.name)}
                                onChange={(e, value) => this.filterChange('selectedStage', value)}
                                value={this.state.selectedStage}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {(option.name)}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Stage" placeholder="Stage" />
                                )}
                            />
                        </Grid>
                        <Grid item sm={9}>
                            <Autocomplete
                                multiple
                                id="project"
                                options={this.state.projectsData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.tender_name}
                                onChange={(e, value) => this.projectSelected(e, value)}
                                value={this.state.selectedProjects}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                getOptionSelected={(option, value) => {
                                    // console.log(option, value)
                                    return (option.id === value.id || value.id === 0)
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Project" placeholder="Project" />
                                )}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <Autocomplete
                                multiple
                                id="billable"
                                options={this.state.BillableList}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.filterChange('selectedBillable', value)}
                                value={this.state.selectedBillable}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                getOptionSelected={(option, value) => {
                                    // console.log(option, value)
                                    return (option.id === value.id)
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Billable" placeholder="Billable" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.downloadReport}
                                disabled={this.state.selectedProjects.length === 0}
                                style={{ marginRight: '30px' }}
                                endIcon={<DownloadIcon />}
                            >Download Report</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.viewReport}
                                disabled={this.state.selectedProjects.length === 0}
                                startIcon={<VisibilityIcon />}

                            >View Report</Button>

                        </Grid>
                    </Grid>

                    {this.state.reportModal ?
                        <Dialog fullScreen
                            open={this.state.reportModal}
                            onClose={() => this.setState({ reportModal: false })}
                            TransitionComponent={Transition}>
                            <DvsCReport
                                closeReport={this.closeReport}
                                data={this.state.data}
                                projectNames={projectNames}
                                projects={this.state.selectedProjects}
                                billable={this.state.selectedBillable}
                            />
                        </Dialog>
                        : null}

                    {this.state.downloadDialog ?
                        <DownloadDialog
                            open={this.state.downloadDialog}
                            downloadDialogClose={this.downloadDialogClose}
                            data={this.state.data}
                            projects={this.state.selectedProjects}
                            billable={this.state.selectedBillable}
                        />
                        : null}
                </div>
            }
        </>)
    }
}

export default withSnackbar(withStyles(useStyles)(DvsCHome))