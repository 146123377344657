import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import UserTaskTable from './UserTasksTable';
import GanttHome from './UserWorkloadGantt/GanttHome';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                // <Box p={3}>
                <Typography>{children}</Typography>
                // </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
    },
}));

export default function UserTaskStatusView(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        props.multiUser ?
            <div style={{ overflow: 'hidden' }}>
                <div style={{ marginTop: '10px' }}>
                    <GanttHome taskData={props.data} allUsers={props.allUsers} uid={props.uid} />
                </div>
            </div>
            :
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="Task Status View"
                    >
                        <Tab label="Gantt View" {...a11yProps(0)} />
                        <Tab label="Table View" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <div style={{ overflow: 'hidden' }}>
                            <div style={{ marginTop: '10px' }}>
                                <GanttHome taskData={props.data} allUsers={props.allUsers} uid={props.uid} />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <UserTaskTable
                            data={props.data}
                            dataAll={props.dataAll}
                            status={props.status}
                            projectLookUp={props.projectLookUp}
                            allUsers={props.allUsers}
                            uid={props.uid}
                            viewAll={props.viewAll}
                        />
                    </TabPanel>
                </SwipeableViews>
            </div>

    );
}