import React, { Component } from 'react'
import MultiProgress from "react-multi-progress";
import { getGreencolor, getOrangecolor, getProgBgColor } from '../../MainDashBoard/ColorTheme/ColorTheme';

class StackedBudgetCost extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            budgetCost: 0,
            costAtComp: 0,
        }
    }
    componentDidMount() {
        this.calcCost()
    }

    calcCost() {
        var converted_cost = 0
        var budgetCost = 0
        var costAtComp = 0
        if (this.props.data.budget_cost < this.props.data.cost_at_completion) {
            converted_cost = 100 / this.props.data.cost_at_completion
            budgetCost = converted_cost * this.props.data.budget_cost
            costAtComp = converted_cost * this.props.data.cost_at_completion
            costAtComp = 100 - budgetCost
        } else {
            converted_cost = 100 / this.props.data.budget_cost
            budgetCost = converted_cost * this.props.data.budget_cost
            costAtComp = converted_cost * this.props.data.cost_at_completion
            costAtComp = 100 - costAtComp
            budgetCost = 100 - costAtComp
        }
        this.setState({ budgetCost: budgetCost, costAtComp: costAtComp })
    }
    render() {
        return (
            <MultiProgress
                transitionTime={1.2}
                elements={[
                    {
                        value: this.state.budgetCost,
                        color: getOrangecolor(),
                    },
                    {
                        value: this.state.costAtComp,
                        color: this.props.data.budget_cost < this.props.data.cost_at_completion ? '#b30000' : getGreencolor(),
                    },
                ]}
                height={5}
                backgroundColor={getProgBgColor()}
            />
        )
    }
}

export default StackedBudgetCost