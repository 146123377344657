import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import UsersTimesheets from './UsersTimesheets';
// import TeamsTimesheets from './TeamsTimesheets';
import UserTasks from '../UserTasks';
import TeamUsersTasks from '../TeamUsersTasks';
// import TimesheetSummary from './TimesheetSummary';
import PersonIcon from '@material-ui/icons/Person';
import GroupsIcon from '@material-ui/icons/Group';
import { getCookie } from '../../Helper/CookieHelper';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import MembersWorkload from '../MembersWorkload';

const user = JSON.parse(getCookie("bb_ts_user"));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    marginTop: '25px'
  },
}));

export default function TeamWorkloadTab(props) {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const classes = useStyles();
  const theme = useTheme();
  var defaultTab = 0
  if (params.workLoadReportTab) {
    defaultTab = parseInt(params.workLoadReportTab)
  }

  const [value, setValue] = React.useState(defaultTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // console.log(!params.workLoadReportTab, params.workLoadReportTab)
  if (('URLSearchParams' in window) && (!params.workLoadReportTab || (parseInt(params.workLoadReportTab) !== value))) {
    var searchParams = new URLSearchParams(window.location.search)
    searchParams.set("workLoadReportTab", value);
    var hash = window.location.hash
    var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;
    window.history.pushState(null, '', newRelativePathQuery);
  }

  return (
    <div className={classes.root}>
      {/* <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Item One" />
          <Tab label="Item Two" />
          <Tab label="Item Three" />
        </Tabs>
      </Box> */}

      <AppBar position="static"
      // style={{backgroundColor: "#000066"}}
      // color="default"
      // variant='contained'
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          centered
        >
          <Tab label="Your Workload" {...a11yProps(0)} icon={<PersonIcon />} />
          <Tab label="Team Workload" {...a11yProps(1)} icon={<GroupsIcon />} />
          <Tab label="Member's Workload" {...a11yProps(2)} icon={<AssignmentIndIcon />} />

          {/* <Tab label="Weekly Timesheet Summary Report" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction} style={{ minHeight: '100px' }} >
          <UserTasks teamLeadData={props.value} uid={user.id} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction} style={{ minHeight: '100px' }}  >
          <TeamUsersTasks value={props.value} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction} style={{ minHeight: '100px' }}  >
          <MembersWorkload value={props.value} />
        </TabPanel>

        {/* <TabPanel value={value} index={2} dir={theme.direction}>
          <TimesheetSummary />
        </TabPanel> */}
      </SwipeableViews>
    </div>
  );
}
