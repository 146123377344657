import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import moment from 'moment'
import { withSnackbar } from 'notistack';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import WorkHoursDialog from './WorkHoursDialog';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../Helper/CookieHelper';
// import MaterialTable from 'material-table';
import UserTasks from './UserTasks';
import { errorMessage } from '../Helper/ErrorMessage';
import TeamWorkloadTab from './TeamLoadReport/TeamWorkloadTab';
import { getDarkMode, getPrimaryColor } from '../Helper/CommonThings';
import { DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import Link from '@material-ui/core/Link';
import FeedbackView from '../Feedback/FeedbackView';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#00b300',

        },
        secondary: {
            main: '#cca300',
        },
        error: {
            main: "#ff0000",
        },
        type: getDarkMode(),
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    root: {
        width: '100%',
        marginTop: '20px',
        textAlign: 'center'
    },
});

class WorkHours extends Component {

    constructor(props) {
        super()
        this.feedback = React.createRef();
        this.state = {
            data: [],
            tabledata: [],

            columns: [
                { title: 'Emp Name', field: 'uname' },
                { title: 'Team', field: 'gname' },
                { title: 'Projects', field: 'proj_name' },

            ],
            date1Selected: false,
            date2Selected: false,
            date1: moment(),
            date2: moment(),
            openCal: false,
            toDia: [[], []],
            isLoading: false,
            isLoading2: true,
            toDia12: [[], []],
            teamLeadData: null,
            userWeekData: [],
            showGraph: false,
            graphLoad: false,
            openRating: false,
        }

    }


    componentDidMount() {
        window.addEventListener('beforeunload', this.componentCleanup);
        // if (user.is_lead) {
        //     Service.get("/bb/api/team/tasks/", {
        //         headers: {
        //             Authorization: "Token " + token,
        //         },
        //     })
        //         .then(res => {
        //             //console.log(res.data)
        //             var datapull = [];
        //             for (let i = 0; i < res.data.length; i++) {
        //                 const element = res.data[i];
        //                 var date = moment(element.start).format('DD/MM/YYYY')
        //                 var stime = moment(element.start).format('HH mm A');
        //                 var etime = moment(element.end).format('HH mm A');
        //                 var whours = moment.duration(moment(element.end).diff(moment(element.start)));
        //                 var whours1 = ''
        //                 if (parseInt(whours.asHours()) === 0) {
        //                     whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
        //                 } else {
        //                     whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
        //                 }
        //                 datapull.push({
        //                     id: element.id,
        //                     uid: element.uid,
        //                     gid: element.gid,
        //                     pid: element.pid,
        //                     tid: element.tid,
        //                     tname: element.tname,
        //                     uemail: element.uemail,
        //                     uname: element.uname_last + ', ' + element.uname_first,
        //                     gname: element.gname,
        //                     title: element.title,
        //                     proj_name: element.proj_name,
        //                     work_detail: element.work_detail,
        //                     start: new Date(element.start),
        //                     end: new Date(element.end),
        //                     date: date,
        //                     stime: stime,
        //                     etime: etime,
        //                     whours: whours1,
        //                     created_by: element.created_by,
        //                     [date]: whours1,
        //                 });
        //             }

        //             this.setState({ data: datapull, isLoading: false }, () => { this.handleColumns() });

        //         })
        //         .catch(error => {
        //             // console.log(error)
        //             this.props.enqueueSnackbar('Something went wrong!', {
        //                 variant: 'error'
        //             });
        //         });
        // } else if (user.is_staff) {

        //     Service.get(`/bb/api/teams/`, {
        //         headers: {
        //             Authorization: "Token " + token,
        //         },
        //     })
        //         .then(res => {
        //             var ids1 = '0';
        //             var staffteam = [];
        //             for (let i = 0; i < res.data.length; i++) {
        //                 const element = res.data[i];
        //                 ids1 = ids1 + "," + element.id;
        //                 staffteam.push(element.id);
        //             }


        //             Service.get("/bb/api/team/access/?uid=" + user.id, {
        //                 headers: {
        //                     Authorization: "Token " + token,
        //                 },
        //             })
        //                 .then(res => {

        //                     var ids2 = "0";
        //                     var ids3 = "0";

        //                     for (let i = 0; i < res.data.length; i++) {
        //                         const element = res.data[i];
        //                         var goodtogo = true;
        //                         for (let j = 0; j < staffteam.length; j++) {
        //                             const element1 = staffteam[j];
        //                             if (element1 === element.gid) {
        //                                 goodtogo = false;
        //                             }
        //                         }
        //                         if (goodtogo) {
        //                             ids2 = ids2 + "," + element.gid;
        //                             ids3 = ids3 + "," + element.uid;
        //                         }

        //                     }

        //                     Service.get("/bb/api/team/tasks/?gid=" + ids1, {
        //                         headers: {
        //                             Authorization: "Token " + token,
        //                         },
        //                     })
        //                         .then(res => {
        //                             //console.log(res.data)
        //                             var datapull = [];
        //                             for (let i = 0; i < res.data.length; i++) {
        //                                 const element = res.data[i];
        //                                 var date = moment(element.start).format('DD/MM/YYYY')
        //                                 var stime = moment(element.start).format('HH mm A');
        //                                 var etime = moment(element.end).format('HH mm A');
        //                                 var whours = moment.duration(moment(element.end).diff(moment(element.start)));
        //                                 var whours1 = ''
        //                                 if (parseInt(whours.asHours()) === 0) {
        //                                     whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
        //                                 } else {
        //                                     whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
        //                                 }
        //                                 datapull.push({
        //                                     id: element.id,
        //                                     uid: element.uid,
        //                                     gid: element.gid,
        //                                     pid: element.pid,
        //                                     tid: element.tid,
        //                                     tname: element.tname,
        //                                     uemail: element.uemail,
        //                                     uname: element.uname_last + ', ' + element.uname_first,
        //                                     gname: element.gname,
        //                                     title: element.title,
        //                                     proj_name: element.proj_name,
        //                                     work_detail: element.work_detail,
        //                                     start: new Date(element.start),
        //                                     end: new Date(element.end),
        //                                     date: date,
        //                                     stime: stime,
        //                                     etime: etime,
        //                                     whours: whours1,
        //                                     created_by: element.created_by,
        //                                     [date]: whours1,
        //                                 });
        //                             }


        //                             Service.get("/bb/api/team/tasks/?gid=" + ids2 + "&uid=" + ids3, {
        //                                 headers: {
        //                                     Authorization: "Token " + token,
        //                                 },
        //                             })
        //                                 .then(res => {
        //                                     //console.log(res.data)
        //                                     for (let i = 0; i < res.data.length; i++) {
        //                                         const element = res.data[i];
        //                                         var date = moment(element.start).format('DD/MM/YYYY')
        //                                         var stime = moment(element.start).format('HH mm A');
        //                                         var etime = moment(element.end).format('HH mm A');
        //                                         var whours = moment.duration(moment(element.end).diff(moment(element.start)));
        //                                         var whours1 = ''
        //                                         if (parseInt(whours.asHours()) === 0) {
        //                                             whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
        //                                         } else {
        //                                             whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
        //                                         }
        //                                         datapull.push({
        //                                             id: element.id,
        //                                             uid: element.uid,
        //                                             gid: element.gid,
        //                                             pid: element.pid,
        //                                             tid: element.tid,
        //                                             tname: element.tname,
        //                                             uemail: element.uemail,
        //                                             uname: element.uname_last + ', ' + element.uname_first,
        //                                             gname: element.gname,
        //                                             title: element.title,
        //                                             proj_name: element.proj_name,
        //                                             work_detail: element.work_detail,
        //                                             start: new Date(element.start),
        //                                             end: new Date(element.end),
        //                                             date: date,
        //                                             stime: stime,
        //                                             etime: etime,
        //                                             whours: whours1,
        //                                             created_by: element.created_by,
        //                                             [date]: whours1,
        //                                         });
        //                                     }

        //                                     // console.log(datapull)
        //                                     this.setState({ data: datapull, isLoading: false })

        //                                 })
        //                                 .catch(error => {
        //                                     //  console.log(error)
        //                                     this.props.enqueueSnackbar('Something went wrong!', {
        //                                         variant: 'error'
        //                                     });
        //                                 });

        //                         })
        //                         .catch(error => {
        //                             // console.log(error)
        //                             this.props.enqueueSnackbar('Something went wrong!', {
        //                                 variant: 'error'
        //                             });
        //                         });

        //                 })
        //                 .catch(error => {
        //                     this.props.enqueueSnackbar('Something went wrong!', {
        //                         variant: 'error'
        //                     });
        //                 });

        //         })
        //         .catch(error => {
        //             this.props.enqueueSnackbar('Something went wrong!', {
        //                 variant: 'error'
        //             });
        //         });
        // } else {
        //     Service.get("/bb/api/team/tasks/?uid=" + user.id, {
        //         headers: {
        //             Authorization: "Token " + token,
        //         },
        //     })
        //         .then(res => {
        //             var datapull = [];
        //             //console.log(res.data)
        //             for (let i = 0; i < res.data.length; i++) {
        //                 const element = res.data[i];
        //                 var date = moment(element.start).format('DD/MM/YYYY')
        //                 var stime = moment(element.start).format('HH mm A');
        //                 var etime = moment(element.end).format('HH mm A');
        //                 var whours = moment.duration(moment(element.end).diff(moment(element.start)));
        //                 var whours1 = ''
        //                 if (parseInt(whours.asHours()) === 0) {
        //                     whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
        //                 } else {
        //                     whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
        //                 }
        //                 datapull.push({
        //                     id: element.id,
        //                     uid: element.uid,
        //                     gid: element.gid,
        //                     pid: element.pid,
        //                     tid: element.tid,
        //                     tname: element.tname,
        //                     uemail: element.uemail,
        //                     uname: element.uname_last + ', ' + element.uname_first,
        //                     gname: element.gname,
        //                     title: element.title,
        //                     proj_name: element.proj_name,
        //                     work_detail: element.work_detail,
        //                     start: new Date(element.start),
        //                     end: new Date(element.end),
        //                     date: date,
        //                     stime: stime,
        //                     etime: etime,
        //                     whours: whours1,
        //                     created_by: element.created_by,
        //                     [date]: whours1,
        //                 });
        //             }

        //             //console.log(datapull)
        //             this.setState({ data: datapull, isLoading: false })

        //         })
        //         .catch(error => {
        //             // console.log(error)
        //             this.props.enqueueSnackbar('Something went wrong!', {
        //                 variant: 'error'
        //             });
        //         });
        // }


        //New method

        Service.get("/bb/api/team/access/?uid=" + user.id + "&team_role=1,2", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    teamLeadData: res.data
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        // this.preFetchOps()
    }

    componentCleanup() { // this will hold the cleanup code
        // whatever you want to do when the component is unmounted or page refreshes
        let searchParams = new URLSearchParams(window.location.search)
        searchParams.delete('workLoadReportTab')
        var hash = window.location.hash
        var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;
        window.history.pushState(null, '', newRelativePathQuery);
    }

    componentWillUnmount() {
        this.componentCleanup()
    }

    // preFetchOps() {
    //     var datapull = []
    //     var start_date1 = moment().startOf('isoWeek').subtract(1, 'days')
    //     var end_date1 = moment().add(2, 'weeks').endOf('isoWeek').subtract(1, 'days')
    //     Service.get("/bb/api/team/tasks/?uid=" + user.id + "&start__gte=" + start_date1.format('YYYY-MM-DD HH:mm:ss') + "&start__lte=" + end_date1.format('YYYY-MM-DD HH:mm:ss'), {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res => {
    //             //console.log(res.data)
    //             for (let i = 0; i < res.data.length; i++) {
    //                 const element = res.data[i];
    //                 var date = moment(element.start).format('DD/MM/YYYY')
    //                 var stime = moment(element.start).format('HH mm A');
    //                 var etime = moment(element.end).format('HH mm A');
    //                 var whours = moment.duration(moment(element.end).diff(moment(element.start)));
    //                 var whours1 = ''
    //                 if (parseInt(whours.asHours()) === 0) {
    //                     whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
    //                 } else {
    //                     whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
    //                 }
    //                 datapull.push({
    //                     id: element.id,
    //                     uid: element.uid,
    //                     gid: element.gid,
    //                     tid: element.tid,
    //                     pid: element.pid,
    //                     ttid: element.ttid,
    //                     tpid: element.tpid,
    //                     twbsid: element.twbsid,
    //                     trolesid: element.trolesid,
    //                     tmapid: element.tmapid,
    //                     uemail: element.uemail,
    //                     uname: element.uname_first + " " + element.uname_last,
    //                     gname: element.gname,
    //                     tname: element.tname,
    //                     tproj_name: element.tproj_name,
    //                     ttender_name: element.ttender_name,
    //                     ttname: element.ttname,
    //                     tuemail: element.tuemail,
    //                     twbsname: element.twbsname,
    //                     trolename: element.trolename,
    //                     title: element.tproj_name + "(" + element.ttname + " - " + element.ttdecs + ")",
    //                     proj_name: element.proj_name,
    //                     work_detail: element.work_detail,
    //                     comments: element.comments,
    //                     date: date,
    //                     uid_data: element.uid_data,
    //                     [date]: whours1,
    //                     start: new Date(element.start),
    //                     end: new Date(element.end),
    //                     is_locked: element.is_locked,
    //                     is_submitted: element.is_submitted,
    //                     is_approved: element.is_approved,
    //                     strstart: element.start,
    //                     strend: element.end,
    //                     stime: stime,
    //                     etime: etime,
    //                     estimated_hours: element.estimated_hours,
    //                     created_by: element.created_by,
    //                     whours: whours1,
    //                     is_tender: element.is_tender,
    //                 });
    //             }

    //             // console.log(datapull)
    //             this.setState({ data: datapull }, () => {
    //                 this.handleColumns(start_date1, end_date1, 'toDia12')
    //             })

    //         })
    //         .catch(error => {
    //             //  console.log(error)
    //             errorMessage(this.props, error)
    //         });
    // }

    fetchOps() {


        let start_date = moment().subtract(3, 'months').startOf('week');
        let end_date = moment()
        // this.setState({ isLoading: true })
        var start_date1 = start_date
        var end_date1 = end_date
        start_date1.subtract(1, 'days')
        end_date1.add(1, 'days')
        let time = moment('12:00', 'HH:mm');
        let time1 = moment('12:00', 'HH:mm');
        start_date.set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: time.get('second')
        });
        end_date.set({
            hour: time1.get('hour'),
            minute: time1.get('minute'),
            second: time1.get('second')
        });

        var url = "/bb/api/team/access/?uid=" + user.id;
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res1 => {
                var leadid = "0"
                var memid = "0"
                // var memgid = "0"
                for (let i = 0; i < res1.data.length; i++) {
                    const element12 = res1.data[i];
                    if (element12.team_role === 1 || element12.team_role === 2) {
                        leadid = leadid + "," + element12.gid
                    } else {
                        memid = memid + "," + element12.gid
                    }
                }

                // console.log(leadid)
                // console.log(memid)

                Service.get("/bb/api/team/tasks/?uid=" + user.id + "&start__gte=" + start_date1.format('YYYY-MM-DD HH:mm:ss') + "&start__lte=" + end_date1.format('YYYY-MM-DD HH:mm:ss'), {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res => {
                        //console.log(res.data)
                        var datapull = [];
                        for (let i = 0; i < res.data.length; i++) {
                            const element = res.data[i];
                            var date = moment(element.start).format('DD/MM/YYYY')
                            var stime = moment(element.start).format('HH mm A');
                            var etime = moment(element.end).format('HH mm A');
                            var whours = moment.duration(moment(element.end).diff(moment(element.start)));
                            var whours1 = ''
                            if (parseFloat(whours.asHours()) < 0.9) {
                                whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                            } else {
                                whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                            }
                            datapull.push({
                                id: element.id,
                                uid: element.uid,
                                gid: element.gid,
                                tid: element.tid,
                                pid: element.pid,
                                ttid: element.ttid,
                                tpid: element.tpid,
                                twbsid: element.twbsid,
                                trolesid: element.trolesid,
                                tmapid: element.tmapid,
                                uemail: element.uemail,
                                uname: element.uname_first + " " + element.uname_last,
                                gname: element.gname,
                                tname: element.tname,
                                tproj_name: element.tproj_name,
                                ttender_name: element.ttender_name,
                                ttname: element.ttname,
                                tuemail: element.tuemail,
                                twbsname: element.twbsname,
                                trolename: element.trolename,
                                title: element.tproj_name + "(" + element.ttname + " - " + element.ttdecs + ")",
                                proj_name: element.proj_name,
                                work_detail: element.work_detail,
                                comments: element.comments,
                                date: date,
                                uid_data: element.uid_data,
                                [date]: whours1,
                                start: new Date(element.start),
                                end: new Date(element.end),
                                is_locked: element.is_locked,
                                is_submitted: element.is_submitted,
                                is_approved: element.is_approved,
                                strstart: element.start,
                                strend: element.end,
                                stime: stime,
                                etime: etime,
                                estimated_hours: element.estimated_hours,
                                created_by: element.created_by,
                                whours: whours1,
                                is_tender: element.is_tender,
                            });
                        }


                        Service.get("/bb/api/team/tasks/?gid=" + memid + "&uid=" + user.id + "&start__gte=" + start_date1.format('YYYY-MM-DD HH:mm:ss') + "&start__lte=" + end_date1.format('YYYY-MM-DD HH:mm:ss'), {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res => {
                                //console.log(res.data)
                                for (let i = 0; i < res.data.length; i++) {
                                    const element = res.data[i];
                                    var date = moment(element.start).format('DD/MM/YYYY')
                                    var stime = moment(element.start).format('HH mm A');
                                    var etime = moment(element.end).format('HH mm A');
                                    var whours = moment.duration(moment(element.end).diff(moment(element.start)));
                                    var whours1 = ''
                                    if (parseFloat(whours.asHours()) < 0.9) {
                                        whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                                    } else {
                                        whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                                    }
                                    datapull.push({
                                        id: element.id,
                                        uid: element.uid,
                                        gid: element.gid,
                                        tid: element.tid,
                                        pid: element.pid,
                                        ttid: element.ttid,
                                        tpid: element.tpid,
                                        twbsid: element.twbsid,
                                        trolesid: element.trolesid,
                                        tmapid: element.tmapid,
                                        uemail: element.uemail,
                                        uname: element.uname_first + " " + element.uname_last,
                                        gname: element.gname,
                                        tname: element.tname,
                                        tproj_name: element.tproj_name,
                                        ttender_name: element.ttender_name,
                                        ttname: element.ttname,
                                        tuemail: element.tuemail,
                                        twbsname: element.twbsname,
                                        trolename: element.trolename,
                                        title: element.tproj_name + "(" + element.ttname + " - " + element.ttdecs + ")",
                                        proj_name: element.proj_name,
                                        work_detail: element.work_detail,
                                        comments: element.comments,
                                        date: date,
                                        uid_data: element.uid_data,
                                        [date]: whours1,
                                        start: new Date(element.start),
                                        end: new Date(element.end),
                                        is_locked: element.is_locked,
                                        is_submitted: element.is_submitted,
                                        is_approved: element.is_approved,
                                        strstart: element.start,
                                        strend: element.end,
                                        stime: stime,
                                        etime: etime,
                                        estimated_hours: element.estimated_hours,
                                        created_by: element.created_by,
                                        whours: whours1,
                                        is_tender: element.is_tender,
                                    });
                                }

                                // console.log(datapull, 'data to show')
                                this.setState({ data: datapull }, () => {
                                    // this.handleColumns(this.state.date1, this.state.date2, 'toDia')
                                    let sdate = moment();
                                    let allWeek = [];
                                    let start_date2 = moment(sdate).subtract(3, 'months').startOf('week').isoWeekday(6);
                                    while (start_date2.isBefore(sdate)) {
                                        // console.log(start_date2.format('DD MMM YYYY'))
                                        let week = [];
                                        for (let i = 0; i < 7; i++) {
                                            week.push(start_date2.format('DD/MM/YYYY'))
                                            start_date2.add('d', 1);
                                        }
                                        allWeek.push(week)
                                    }
                                    // console.log(allWeek)


                                    let usersHours = []
                                    for (let i = 0; i < datapull.length; i++) {
                                        let each = datapull[i];
                                        if (user.id === each.uid) {
                                            usersHours.push(each)
                                        }
                                    }
                                    // console.log(usersHours, 'userHours');

                                    let UserWeekData = [];

                                    for (let i = 0; i < allWeek.length; i++) {
                                        let each = allWeek[i];
                                        let obj = {
                                            week_start: each[0],
                                            week_end: each[each.length - 1],
                                            project: [],
                                            overHeads: [],

                                        }
                                        for (let j = 0; j < usersHours.length; j++) {
                                            let eachUserHours = usersHours[j];
                                            if (each.indexOf(moment(eachUserHours.start).format('DD/MM/YYYY')) !== -1) {
                                                if (eachUserHours.tproj_name) obj.project.push(Number(String(eachUserHours.whours).split(':')[0]));
                                                else obj.overHeads.push(Number(String(eachUserHours.whours).split(':')[0]))
                                            }
                                        }
                                        const reducer = (accumulator, currentValue) => accumulator + currentValue;
                                        let p_count = 0;
                                        if (obj.project.length > 0) p_count = obj.project.reduce(reducer)
                                        let o_count = 0;
                                        if (obj.overHeads.length > 0) o_count = obj.overHeads.reduce(reducer)
                                        obj.p_count = p_count
                                        obj.o_count = o_count
                                        UserWeekData.push(obj)
                                    }

                                    // console.log(UserWeekData)
                                    this.setState({ userWeekData: UserWeekData, showGraph: true, isLoading: false, graphLoad: false })

                                })

                            })
                            .catch(error => {
                                //  console.log(error)
                                errorMessage(this.props, error)
                            });

                    })
                    .catch(error => {
                        // console.log(error)
                        errorMessage(this.props, error)
                    });


            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        // Service.get('/bb/api/teams/?email='+user.email, {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        // })
        //     .then(res => {
        //         console.log(res.data)
        //         var ids1 = '0';
        //         var staffteam = [];
        //         for (let i = 0; i < res.data.length; i++) {
        //             const element = res.data[i];
        //             ids1 = ids1 + "," + element.id;
        //             staffteam.push(element.id);
        //         }

        //         if (res.data.length === 0) {
        //             Service.get("/bb/api/team/tasks/?uid=" + user.id + "&start__gte=" + start_date1.format('YYYY-MM-DD HH:mm:ss') + "&start__lte=" + end_date1.format('YYYY-MM-DD HH:mm:ss'), {
        //                 headers: {
        //                     Authorization: "Token " + token,
        //                 },
        //             })
        //                 .then(res => {
        //                     var datapull = [];
        //                     //console.log(res.data)
        //                     for (let i = 0; i < res.data.length; i++) {
        //                         const element = res.data[i];
        //                         var date = moment(element.start).format('DD/MM/YYYY')
        //                         var stime = moment(element.start).format('HH mm A');
        //                         var etime = moment(element.end).format('HH mm A');
        //                         var whours = moment.duration(moment(element.end).diff(moment(element.start)));
        //                         var whours1 = ''
        //                         if (parseInt(whours.asHours()) === 0) {
        //                             whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
        //                         } else {
        //                             whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
        //                         }
        //                         datapull.push({
        //                             id: element.id,
        //                             uid: element.uid,
        //                             gid: element.gid,
        //                             tid: element.tid,
        //                             pid: element.pid,
        //                             ttid: element.ttid,
        //                             tpid: element.tpid,
        //                             twbsid: element.twbsid,
        //                             trolesid: element.trolesid,
        //                             tmapid: element.tmapid,
        //                             uemail: element.uemail,
        //                             uname: element.uname_first + " " + element.uname_last,
        //                             gname: element.gname,
        //                             tname: element.tname,
        //                             tproj_name: element.tproj_name,
        //                             ttender_name : element.ttender_name,
        //                             ttname: element.ttname,
        //                             tuemail: element.tuemail,
        //                             twbsname: element.twbsname,
        //                             trolename: element.trolename,
        //                             title: element.tproj_name + "(" + element.ttname + " - " + element.ttdecs + ")",
        //                             proj_name: element.proj_name,
        //                             work_detail: element.work_detail,
        //                             comments: element.comments,
        //                             date: date,
        //                             uid_data: element.uid_data,
        //                             [date]: whours1,
        //                             start: new Date(element.start),
        //                             end: new Date(element.end),
        //                             is_locked: element.is_locked,
        //                             is_submitted: element.is_submitted,
        //                             is_approved: element.is_approved,
        //                             strstart: element.start,
        //                             strend: element.end,
        //                             stime: stime,
        //                             etime: etime,
        //                             estimated_hours: element.estimated_hours,
        //                             created_by: element.created_by,
        //                             whours: whours1,
        //                             is_tender: element.is_tender,
        //                         });
        //                     }

        //                     //console.log(datapull)
        //                     this.setState({ data: datapull }, () => {
        //                         this.handleColumns()
        //                     })

        //                 })
        //                 .catch(error => {
        //                     // console.log(error)
        //                     this.props.enqueueSnackbar('Something went wrong!', {
        //                         variant: 'error'
        //                     });
        //                 });
        //         } else {

        //             Service.get("/bb/api/team/access/?uid=" + user.id, {
        //                 headers: {
        //                     Authorization: "Token " + token,
        //                 },
        //             })
        //                 .then(res => {

        //                     var ids2 = "0";
        //                     var ids3 = "0";

        //                     for (let i = 0; i < res.data.length; i++) {
        //                         const element = res.data[i];
        //                         var goodtogo = true;
        //                         for (let j = 0; j < staffteam.length; j++) {
        //                             const element1 = staffteam[j];
        //                             if (element1 === element.gid) {
        //                                 goodtogo = false;
        //                             }
        //                         }
        //                         if (goodtogo) {
        //                             ids2 = ids2 + "," + element.gid;
        //                             ids3 = ids3 + "," + element.uid;
        //                         }

        //                     }


        //                 })
        //                 .catch(error => {
        //                     this.props.enqueueSnackbar('Something went wrong!', {
        //                         variant: 'error'
        //                     });
        //                 });


        //         }

        //     })
        //     .catch(error => {
        //         this.props.enqueueSnackbar('Something went wrong!', {
        //             variant: 'error'
        //         });
        //     });
    }

    handleColumns(date11, date22, type) {
        var date = moment(date11)
        var date1 = moment(date11).format('DD/MM/YYYY');
        var date2 = moment(date22);
        var columns = [];
        if (type === 'toDia') {
            columns = [
                { title: 'Emp Name', field: 'uname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
                { title: 'Team', field: 'gname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
                { title: 'Projects', field: 'proj_name', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
                { title: 'Tasks', field: 'tname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
            ]
        } else {
            columns = [
                // { title: 'Emp Name', field: 'uname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
                { title: 'Team', field: 'gname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
                { title: 'Projects', field: 'proj_name', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
                { title: 'Tasks', field: 'tname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
            ]
        }
        //console.log(this.state.dupCol)
        while (date2 >= date) {

            const ccc = date1;
            columns.push(
                {
                    title: date.format("DD MMM"), field: date1,
                    render: rowData => <ThemeProvider theme={bbColorTheme}><Typography color={(parseInt(rowData[ccc]) < 4) ? ("error") : (parseInt(rowData[ccc]) > 7) ? ("primary") : ("secondary")} >{rowData[ccc]}</Typography></ThemeProvider>
                }
            )
            date1 = date.add(1, 'days').format('DD/MM/YYYY')

        }

        var statedata = this.state.data;
        var doneuid = [];
        var toTData = [];
        var userelement = [];

        for (let i = 0; i < statedata.length; i++) {
            var userelement2 = [];
            const element = statedata[i];

            if (i === 0) {
                doneuid.push(element.uid);
                for (let j = 0; j < statedata.length; j++) {
                    const element1 = statedata[j];
                    if (element.uid === element1.uid) {

                        userelement2.push(element1)
                    }
                }
            } else {
                var goodtogo = true;
                for (let k = 0; k < doneuid.length; k++) {
                    const element3 = doneuid[k];

                    if (element3 === element.uid) {
                        goodtogo = false;
                    }
                }

                if (goodtogo) {
                    doneuid.push(element.uid);
                    for (let j = 0; j < statedata.length; j++) {
                        const element1 = statedata[j];
                        if (element.uid === element1.uid) {

                            userelement2.push(element1)
                        }

                    }
                }
            }

            if (userelement2.length !== 0) {
                userelement.push(userelement2)
            }

        }

        //date
        for (let i = 0; i < userelement.length; i++) {
            var whdate = [];
            var wh = [];
            var teams = [];
            var proj = [];
            var tasks = [];
            const element = userelement[i];
            for (let j = 0; j < element.length; j++) {
                const element1 = element[j];
                var goodtogo1 = true;
                for (let k = 0; k < whdate.length; k++) {
                    const element2 = whdate[k];
                    if (element2 === element1.date) {
                        goodtogo1 = false;
                    }

                }

                if (goodtogo1) {
                    // console.log('Total 111 time is')
                    whdate.push(element1.date);
                    var hours = []
                    for (let m = 0; m < element.length; m++) {
                        const element3 = element[m];
                        if (element3.date === element1.date) {
                            hours.push(element3[element3.date])
                        }
                        var teamgo = true;
                        for (let n = 0; n < teams.length; n++) {
                            const element4 = teams[n];
                            if (element4 === element3.gname) {
                                teamgo = false;
                            }
                        }
                        if (teamgo) {
                            teams.push(element3.gname)
                        }
                        var projgo = true;
                        for (let n = 0; n < proj.length; n++) {
                            const element4 = proj[n];
                            if (element3.is_tender === 0) {
                                if (element4 === element3.proj_name) {
                                    projgo = false;
                                }
                            } else {
                                if (element4 === element3.ttender_name + " - " + element3.tproj_name) {
                                    projgo = false;
                                }
                            }

                        }
                        //console.log(element3.proj_name)
                        if (projgo) {
                            if (element3.is_tender === 0) {
                                proj.push(element3.proj_name)
                            } else {
                                proj.push(element3.ttender_name + " - " + element3.tproj_name)
                            }

                        }
                        var taskgo = true;
                        for (let n = 0; n < tasks.length; n++) {
                            const element4 = tasks[n];
                            if (element3.is_tender === 0) {
                                if (element4 === element3.tname) {
                                    taskgo = false;
                                }
                            } else {
                                if (element4 === (element3.ttdecs ? element3.ttdecs : element3.ttname)) {
                                    taskgo = false;
                                }
                            }

                        }
                        //console.log(element3.proj_name)
                        if (taskgo) {
                            if (element3.is_tender === 0) {
                                tasks.push(element3.tname)
                            } else {
                                tasks.push(element3.ttdecs ? element3.ttdecs : element3.ttname)
                            }

                        }
                    }
                    // console.log(teams)
                    const totalDurations = hours.slice(1)
                        .reduce((prev, cur) => moment.duration(cur).add(prev),
                            moment.duration(hours[0]))
                    wh.push(moment.utc(totalDurations.asMilliseconds()).format("HH:mm"))
                }

            }
            var jsoncons = {
                id: element[0].id,
                uid: element[0].uid,
                gid: element[0].gid,
                pid: element.pid,
                tid: element.tid,
                tname: tasks.join(),
                uemail: element[0].uemail,
                uname: element[0].uname,
                gname: teams.join(),
                title: element[0].title,
                proj_name: proj.join(),
                work_detail: element[0].work_detail,
                start: element[0].start,
                end: element[0].end,
                date: element[0].date,
                stime: element[0].stime,
                etime: element[0].etime,
                whours: element[0].whours1,
                created_by: element[0].created_by,
            }
            for (let k = 0; k < whdate.length; k++) {
                const element = whdate[k];
                jsoncons[element] = wh[k];
            }
            toTData.push(jsoncons);
        }

        var todata = [];
        todata.push(columns)
        todata.push(toTData)
        // console.log(type === 'toDia')
        if (type === 'toDia') {
            this.setState({
                toDia: todata,
                openCal: true,
                isLoading: false,
                isLoading2: false
            })
        } else {
            this.setState({
                toDia12: todata,
                isLoading: false,
                isLoading2: false
            })
        }


    }

    accordionChange = (e, v) => {
        // console.log(v)
        if (v === true && this.state.userWeekData.length === 0) {
            this.setState({ graphLoad: true }, () => this.fetchOps())
        }
    }


    // submitFeedback() {
    //     if (this.state.feedbackComment === '') {
    //         this.props.enqueueSnackbar('Add comment!', {
    //             variant: 'warning'
    //         })
    //     } else if (this.state.valueRating === -1) {
    //         this.props.enqueueSnackbar('Add Rating!', {
    //             variant: 'warning'
    //         })
    //     } else {
    //         this.setState({ openRating: false })
    //         this.setState({ isLoading: true, });
    //         var json = {
    //             uid: user.id,
    //             rating: this.state.valueRating,
    //             feedback: this.state.feedbackComment,
    //             created_by: user.email,
    //         }
    //         this.setState({ openRating: false, feedbackComment: '' });
    //         // console.log(json)
    //         Service.post("/bb/api/feedback/", {
    //             headers: {
    //                 Authorization: "Token " + token,
    //             },
    //             data: json,
    //         })
    //             .then(res => {
    //                 // console/.log(res.data)
    //                 this.setState({ isLoading: false });
    //                 this.props.enqueueSnackbar('Thank you for your suggestions/comments. It is valuable for us.', {
    //                     variant: 'success'
    //                 });
    //             })
    //             .catch(error => {
    //                 this.setState({ isLoading: false });
    //                 errorMessage(this.props, error)
    //             });
    //     }
    // }
    //Feedback Dialog
    handleFeedbackClose = (e) => {
        this.setState({ openRating: false })
    }

    render() {

        const { classes } = this.props;

        const handleCalClose = () => {
            this.setState({
                openCal: false,

            })
        };

        // const handleDate1Change = (date) => {
        //     //console.log(moment(date).isoWeekday())
        //     this.setState({
        //         date1: moment(date),
        //         date1Selected: true,

        //     })
        // };

        // const handleDate2Change = (date) => {
        //     this.setState({
        //         date2: moment(date),
        //         date2Selected: true,
        //     })
        // };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid alignItems="center" container justify="space-around">
                                <Box fontWeight="500" fontSize={16}>Custom Workload Report</Box>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="From"
                                    format="dd/MM/yyyy"
                                    value={this.state.date1}
                                    onChange={handleDate1Change}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog2"
                                    label="To"
                                    format="dd/MM/yyyy"
                                    value={this.state.date2}
                                    onChange={handleDate2Change}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <Button variant="contained" onClick={() => { this.fetchOps(this.state.date1, this.state.date2) }} color="primary">Submit</Button>
                            </Grid>
                        </MuiPickersUtilsProvider> */}

                        {/* <MaterialTable
                                title="My 3 Week Lookahead"
                                columns={this.state.toDia12[0]}
                                data={this.state.toDia12[1]}
                                isLoading={this.state.isLoading2}
                                style={{ marginTop: '25px' }}
                                options={{
                                    filtering: true,
                                    exportButton: { csv: true },
                                    paging: false,
                                    pageSize: 1,
                                    doubleHorizontalScroll: true,
                                }}
                            /> */}

                        {(this.state.teamLeadData) ?
                            ((this.state.teamLeadData.length > 0) ? (<TeamWorkloadTab value={this.state.teamLeadData} />) : (<UserTasks teamLeadData={this.state.teamLeadData} uid={user.id} />))
                            : (null)}

                        {/* {(this.state.teamLeadData) ? <TeamUsersTasks value={this.state.teamLeadData} /> : null} */}

                        {/* {this.state.showGraph ? <UserWorkloadGraph data={this.state.userWeekData} /> : null} */}

                        <div className={classes.root}>
                            {/* <Accordion onChange={this.accordionChange}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>Productivity</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {this.state.graphLoad ? <CircleLoading color={getPrimaryColor()} style={{ position: 'relative', margin: 'auto' }} /> :
                                        <UserWorkloadGraph data={this.state.userWeekData} />
                                    }
                                </AccordionDetails>
                            </Accordion> */}

                            {/* <Button color='primary'> Have any suggestions? Feel free to share with us. Click here..</Button> */}
                            <Link component="button"
                                variant="body2"
                                onClick={() => {
                                    this.setState({ openRating: true });
                                }}> Have any suggestions? Feel free to share with us. Click here.</Link>
                        </div>
                        <Dialog fullScreen open={this.state.openCal} onClose={handleCalClose} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleCalClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Work Hours
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <WorkHoursDialog value={this.state.toDia} />
                        </Dialog>


                        <Dialog open={this.state.openRating} fullWidth onClose={this.handleFeedbackClose} aria-labelledby="form-dialog-title">
                            <DialogTitle style={{ paddingTop: '25px' }} id="form-dialog-title">Feedback</DialogTitle>
                            <DialogContent>
                                <DialogContentText style={{ paddingTop: '5px' }} id="alert-dialog-slide-description">
                                    Provide your feedback and experience with the application.
                                </DialogContentText>
                                <FeedbackView value={100} ref={this.feedback} handleClose={this.handleFeedbackClose} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleFeedbackClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={e => {
                                    this.setState({ openRating: false })
                                    this.feedback.current.submitFeedback()
                                }} color="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}
            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(WorkHours));