import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper'
import { errorMessage } from '../Helper/ErrorMessage'
import Service from '../Networking/networkutils'
import SearchKeyEditor from './SearchKeyEditor'

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));


class ManageTutorials extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
            search_key: ''
        }
        this.putData = this.putData.bind(this)
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        Service.get(`/bb/api/tutorials/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    validityCheck(data) {
        var valid = true
        if (!data.name) {
            this.props.enqueueSnackbar('Name field is empty!', {
                variant: 'warning'
            });
            valid = false
        }
        if (!data.description) {
            this.props.enqueueSnackbar('Description field is empty!', {
                variant: 'warning'
            });
            valid = false
        }
        if (!data.url) {
            this.props.enqueueSnackbar('URL field is empty!', {
                variant: 'warning'
            });
            valid = false
        }
        if (!data.thumbnail) {
            this.props.enqueueSnackbar('Thumbnail field is empty!', {
                variant: 'warning'
            });
            valid = false
        }
        if (!data.access) {
            this.props.enqueueSnackbar('Access field is empty!', {
                variant: 'warning'
            });
            valid = false
        }
        return valid
    }

    postData(data) {
        if (this.validityCheck(data)) {
            const datas = {
                name: data.name,
                description: data.description,
                url: data.url,
                access: data.access,
                search_key: '',
                thumbnail: data.thumbnail,
                created_by: user.id,
            }
            Service.post(`/bb/api/tutorials/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: datas
            })
                .then(res => {
                    this.fetchData();
                    this.props.enqueueSnackbar('Tutorial Added Successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    putData(newData) {

        if (this.validityCheck(newData)) {
            this.setState({ isLoading: true })

            Service.put(`/bb/api/tutorials/` + newData.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: newData
            })
                .then(res => {
                    this.setState({ isLoading: false });
                    // this.fetchData();
                    this.props.enqueueSnackbar('Data saved successfully!', {
                        variant: 'success'
                    });

                })
                .catch(error => {
                    errorMessage(this.props, error)
                    this.fetchData()
                });
        }

    }

    deleteData(data) {
        this.setState({ isLoading: true })

        Service.delete(`/bb/api/tutorials/` + data.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ isLoading: false });
                // this.fetchData();
                this.props.enqueueSnackbar('Data deleted successfully!', {
                    variant: 'success'
                });

            })
            .catch(error => {
                errorMessage(this.props, error)
                this.fetchData()
            });
    }

    render() {

        return (
            <div>
                <MaterialTable
                    title="Tutorials"
                    columns={[
                        {
                            title: 'Name', field: 'name',
                        },
                        {
                            title: 'Desription', field: 'description',
                        },
                        {
                            title: 'URL', field: 'url',
                        },
                        {
                            title: 'Thumbnail', field: 'thumbnail',
                        },
                        {
                            title: 'Access', field: 'access',
                        },
                        {
                            title: 'Status', field: 'is_active',
                            lookup: { 'true': 'Active', 'false': 'Inactive' }
                        },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        paging: false,
                        actionsColumnIndex: -1,
                        search: true,
                        filtering: true,
                        padding: "dense",
                    }}
                    detailPanel={rowData => {
                        return (
                            <SearchKeyEditor value={rowData} updateData={this.putData} />
                        )
                    }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                if (this.validityCheck(newData)) {
                                    setTimeout(() => {
                                        resolve();
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            newData.created_by = user.email;
                                            this.postData(newData);
                                            data.push(newData);
                                            return { ...prevState, data };
                                        });
                                    }, 600);
                                } else {
                                    resolve();
                                }
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                if (this.validityCheck(newData)) {
                                    setTimeout(() => {
                                        resolve();
                                        if (oldData) {
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                this.setState({ save: false });
                                                return { ...prevState, data };
                                            });
                                            this.putData(newData)
                                        }
                                    }, 60);
                                } else {
                                    resolve();
                                }
                            }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        return { ...prevState, data };
                                    });
                                    this.deleteData(oldData)
                                }, 600);
                            }),
                    }}
                />
            </div>
        )
    }
}

export default withSnackbar(ManageTutorials)