import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getCookie } from '../../Helper/CookieHelper';
import Service from "../../Networking/networkutils";
import { errorMessage } from '../../Helper/ErrorMessage';
var CryptoJS = require("crypto-js");

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class TenderFeedbackRequest extends Component {

    constructor(props) {
        super()

        this.state = {
            openFeedback: false,
            to: '',
            body: '',
            sub: '',
            data: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        this.fetchOps()
    }

    fetchOps() {
        Service.get('/bb/api/tender/feedback/?pid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({
                    data: res.data,
                    isLoading: false,
                })
            })
            .catch(error => {
               errorMessage(this.props, error)
            });
    }

    postData() {
        this.setState({
            isLoading: true,
            openFeedback: false,
        })
        // console.log(postData.uid_mod.split('d')[1])
        if (!validateEmail(this.state.to) && this.state.sub !== '' && this.state.body !== '') {
            this.props.enqueueSnackbar('Fill all detail!', {
                variant: 'warning'
            });
        } else {

            var postData = {}
            postData.uid = user.id
            postData.pid = this.props.value.id
            postData.email = this.state.to
            postData.pass_code = CryptoJS.lib.WordArray.random(16).words[0]
            postData.email_content = {
                to: this.state.to,
                sub: this.state.sub,
                body: this.state.body,
            }

            Service.post('/bb/api/tender/feedback/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData
            })
                .then(res => {
                    var body = this.state.body
                    var body1 = "Click on the link to submit you feedback : https://" + window.location.hostname + "/tender/project/feedback/" + res.data.id + "\n\nPass Code (please ender while filling feedback): " + postData.pass_code + "\n\nFor further queries, please contact : " + user.email + "\n\n" + body
                    Service.patch("/bb/api/mail/send_mail", {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: [{
                            sub: this.state.sub,
                            to: this.state.to,
                            body: body1,
                            reply_to: [],
                        }],
                    })
                        .then(res1 => {
                            this.props.enqueueSnackbar('Request send successfully!', {
                                variant: 'success'
                            });
                        })
                        .catch(error => {
                            console.log(error)
                            this.props.enqueueSnackbar('Unable to send mail request!', {
                                variant: 'warning'
                            });

                        });
                        
                    this.fetchOps();

                })
                .catch(error => {
                    console.log(error)
                    this.fetchOps();
                    errorMessage(this.props, error)
                });

        }

    }

    handleTextChange(e) {
        if (e.target.id === 'to') {
            this.setState({
                to: e.target.value,
            })
        } else if (e.target.id === 'sub') {
            this.setState({
                sub: e.target.value,
            })
        } else if (e.target.id === 'body') {
            this.setState({
                body: e.target.value,
            })
        }
    }

    render() {

        const handleFeedbackClose = () => {
            this.setState({ openFeedback: false, });

        };

        return (
            <div>
                <MaterialTable
                    title={(this.props.value.tender_name ? this.props.value.tender_name : this.props.value.name) + " Feedbacks"}
                    columns={[

                        {
                            title: 'Requested By', field: 'uid_data.first_name', editable: false,
                        },
                        {
                            title: 'To', field: 'email',
                        },
                        {
                            title: 'Subject', field: 'email_content.sub',
                        },
                        {
                            title: 'Date Created', field: 'date_created', type: 'date'
                        },

                    ]}
                    data={this.state.data}
                    options={{
                        padding: "dense",
                        actionsColumnIndex: -1,
                        search: true,
                        // filtering: true
                    }}
                    isLoading={this.state.isLoading}
                    actions={[
                        {
                            icon: 'add_box',
                            tooltip: 'Request Feedback',
                            isFreeAction: true,
                            // disabled: this.state.save,
                            onClick: () => {
                                this.setState({
                                    openFeedback: true
                                })
                            },
                        },
                        rowData => ({
                            icon: 'launch',
                            tooltip: 'View Feedback',
                            disabled: rowData.is_locked !== 1,
                            onClick: (event, rowData) => { window.open('/tender/project/viewfeedback/' + rowData.id) },
                        }),
                    ]}
                // editable={{
                //     onRowAdd: newData =>
                //         new Promise(resolve => {
                //             setTimeout(() => {
                //                 resolve();
                //                 this.setState({ save: false });
                //                 this.setState(prevState => {
                //                     const data = [...prevState.data];
                //                     newData.roles = [];
                //                     data.push(newData);
                //                     this.postData(newData);
                //                     return { ...prevState, data };
                //                 });
                //             }, 60);
                //         }),
                //     // onRowUpdate: (newData, oldData) =>
                //     //     new Promise(resolve => {
                //     //         setTimeout(() => {
                //     //             resolve();
                //     //             if (oldData) {
                //     //                 this.setState({ save: false });
                //     //                 this.setState(prevState => {
                //     //                     const data = [...prevState.data];
                //     //                     data[data.indexOf(oldData)] = newData;
                //     //                     this.putData(newData);
                //     //                     return { ...prevState, data };
                //     //                 });
                //     //             }
                //     //         }, 60);

                //     //     }),
                //     // onRowDelete: oldData =>
                //     //     new Promise(resolve => {
                //     //         setTimeout(() => {
                //     //             resolve();
                //     //             this.setState({ save: false });
                //     //             this.setState(prevState => {
                //     //                 const data = [...prevState.data];
                //     //                 data.splice(data.indexOf(oldData), 1);
                //     //                 this.deleteData(oldData)
                //     //                 return { ...prevState, data };
                //     //             });
                //     //         }, 60);
                //     //     }),
                // }}
                />
                <Dialog open={this.state.openFeedback} fullWidth={true} maxWidth="md" onClose={handleFeedbackClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title" onClose={handleFeedbackClose}>
                        Feedback Request
                                </DialogTitle>
                    <DialogContent>

                        <Box display="flex" flexDirection="row" p={1} m={1}>
                            <TextField id="to" fullWidth={true} label="To (Email):" onChange={(e) => this.handleTextChange(e)} variant="outlined" />

                        </Box>
                        <Box display="flex" flexDirection="row" p={1} m={1}>
                            <TextField id="sub" fullWidth={true} label="Subject" onChange={(e) => this.handleTextChange(e)} variant="outlined" />

                        </Box>
                        <Box display="flex" flexDirection="row" p={1} m={1}>
                            <TextField id="body" fullWidth={true} label="Body" onChange={(e) => this.handleTextChange(e)} multiline
                                rows={4} variant="outlined" />

                        </Box>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleFeedbackClose} color="primary">
                            Close
                    </Button>

                        <Button onClick={() => this.postData()} color="primary">
                            Submit Request
                    </Button>

                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default withSnackbar(TenderFeedbackRequest)