import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../../Helper/CookieHelper';
import Service from "../../Networking/networkutils";
import WBSList from './WBSList';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class WBSListControl extends Component {

    constructor(props) {
        super()

        this.state = {
            pidPdmData: [],
            left: [],
            right: [],
            isLoading: true,
        }
        this.postData = this.postData.bind(this)
        this.deleteData = this.deleteData.bind(this)
    }

    componentDidMount() {
        Service.get('/bb/api/tender/wbsmanager/?pid=' + this.props.value.id + "&uid=" + this.props.ddm.uid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                Service.get('/bb/api/tender/wbsmanager/?pid=' + this.props.value.id, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res1 => {

                        this.setState({
                            pidPdmData: res1.data
                        })

                        Service.get(`/bb/api/tender/project/?subproj=` + this.props.value.id + '&audit_status=10', {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res2 => {

                                var pids = "0"
                                for (let i = 0; i < res2.data.length; i++) {
                                    const element = res2.data[i];
                                    pids = pids + "," + element.id;
                                }
                                var url = '/bb/api/tender/ohlwbs/?pid=' + pids

                                // if (this.props.ddm.pdm_type !== 'all_type') {
                                //     var url = '/bb/api/tender/ohlwbs/?pid=' + pids + "&type=" + this.props.ddm.pdm_type
                                // }

                                Service.get(url, {
                                    headers: {
                                        Authorization: "Token " + token,
                                    },
                                })
                                    .then(res3 => {
                                        var left = [], right = [];
                                        for (let i = 0; i < res3.data.length; i++) {
                                            const element = res3.data[i];
                                            var goon1 = true
                                            for (let k = 0; k < res1.data.length; k++) {
                                                const element1 = res1.data[k];
                                                if (element.id === element1.wbsid && element1.uid !== this.props.ddm.uid) {
                                                    goon1 = false
                                                    break;
                                                }
                                            }
                                            if (goon1) {
                                                var goon = true
                                                for (let j = 0; j < res.data.length; j++) {
                                                    const element1 = res.data[j];
                                                    if (element.id === element1.wbsid) {
                                                        goon = false
                                                        break;
                                                    }
                                                }
                                                if (goon) {
                                                    if ((this.props.ddm.pdm_type === 'all_type' || this.props.ddm.pdm_type === element.type)) {
                                                        left.push(element)
                                                    }

                                                } else {
                                                    right.push(element)
                                                }
                                            }

                                        }
                                        // console.log(left)
                                        // console.log(right)
                                        this.setState({
                                            left: left,
                                            right: right,
                                            isLoading: false,
                                        })
                                    })
                                    .catch(error => {
                                        // console.log(error)
                                        errorMessage(this.props, error)
                                    });


                            })
                            .catch(error => {
                                // console.log(error)
                                errorMessage(this.props, error)
                            });

                    })
                    .catch(error => {
                        // console.log(error)
                        errorMessage(this.props, error)
                    });


            })
            .catch(error => {
                console.log(error)
               errorMessage(this.props, error)
            });
    }

    postData(arrData) {
        var postData = []
        for (let i = 0; i < arrData.length; i++) {
            const element = arrData[i];
            postData.push({
                pid: this.props.value.id,
                wbsid: element.id,
                uid: this.props.ddm.uid,
                tenderpdm: this.props.ddm.id,
                created_by: user.id
            })
        }
        Service.post('/bb/api/tender/wbsmanager/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: postData
        })
            .then(res => {
                this.setState({
                    pidPdmData: this.state.pidPdmData.concat(res.data)
                })
                this.props.enqueueSnackbar('Added successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                // console.log(error)
               errorMessage(this.props, error)
            });
    }

    deleteData(arrData) {
        var id = []
        var slice_index = []
        // console.log(this.state.pidPdmData)
        for (let j = 0; j < arrData.length; j++) {
            const element1 = arrData[j];
            for (let i = 0; i < this.state.pidPdmData.length; i++) {
                const element = this.state.pidPdmData[i];
                if (element1.id === element.wbsid && element.uid === this.props.ddm.uid) {
                    id.push(element)
                    slice_index.push(i)
                }
            }
        }

        // console.log(id)
        
        Service.delete('/bb/api/tender/wbsmanager/bulk_delete/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: id
        })
            .then(res => {
                var pidPdmData = this.state.pidPdmData
                for (let i = 0; i < slice_index.length; i++) {
                    const element = slice_index[i];
                    pidPdmData.splice(element, 1)
                }
                this.setState({pidPdmData})
                this.props.enqueueSnackbar('Deleted successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                // console.log(error)
               errorMessage(this.props, error)
            });
    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                        <WBSList value={this.props.value} left={this.state.left} right={this.state.right} postData={this.postData} deleteData={this.deleteData} />
                    )}
            </div>
        )
    }
}

export default withSnackbar(WBSListControl)