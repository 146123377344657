import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import { getPrimaryColor } from '../Helper/CommonThings';
import { errorMessage } from '../Helper/ErrorMessage';
import { isMobile, screenSizeCheck } from '../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import Service from '../Networking/networkutils'
import moment from 'moment';
import { getMode } from '../MainDashBoard/ColorTheme/ColorTheme';
import { AppBar, Dialog, Toolbar, } from '@mui/material'
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Card, CardActionArea, CardContent, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { LoopCircleLoading } from 'react-loadingg';
import BroadCastReactPlayer from './BroadCastReactPlayer';
import BroadCastPlayer from './BroadCastPlayer';
import { InputAdornment, TextField, } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CompletedBroadCast extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            copyData: [],
            isLoading: true,
            openBroadCast: false,
            openCast: false,
            broadCastData: null
        }
    }
    componentDidMount() {
        this.fetchData()
        // this.refreshData = setInterval(() => {
        //     this.fetchData()
        // }, (1000 * 300));
    }

    fetchData() {
        var url = ''
        if (window.location.href.includes('2022')) {
            url = `/bb/api/broadcast/?end__year=${2022}`
        } else {
            // url = `/bb/api/broadcast/?end__year=${2023}`
            url = `/bb/api/broadcast/?end__year=${2024}`
        }
        var data = []
        Service.get(url)
            .then(res => {
                var current_time = moment().format('YYYY-MM-DD HH:mm:ss')
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (moment(current_time).isSameOrAfter(element.end)) {
                        data.push(element)
                    }
                }
                this.setState({
                    data: data,
                    copyData: data,
                    isLoading: false
                })
            })
            .catch(error => {
                if (this.refreshData) {
                    clearInterval(this.refreshData)
                }
                errorMessage(this.props, error)
            });
    }

    liveCheck(start, end) {
        var current_time = moment().format('YYYY-MM-DD HH:mm:ss')
        var start_time = moment(start).format('YYYY-MM-DD HH:mm:ss')
        var end_time = moment(end).format('YYYY-MM-DD HH:mm:ss')
        // console.log(current_time, start_time, end_time)
        if (moment(current_time).isSameOrAfter(start_time) && moment(current_time).isSameOrBefore(end_time)) {
            return 'live'
        } else if (moment(current_time).isSameOrBefore(start_time)) {
            return 'upcoming'
        } else if (moment(current_time).isSameOrAfter(end_time)) {
            return 'completed'
        } else {
            return null
        }
    }

    allowCLick(value) {
        var current_time = moment().format('YYYY-MM-DD HH:mm:ss')
        var start_time = moment(value.start).subtract(1, 'hours').format('YYYY-MM-DD HH:mm:ss')
        var end_time = moment(value.end).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss')

        if ((moment(current_time).isSameOrAfter(start_time) && moment(current_time).isSameOrBefore(end_time))) {
            this.setState({ broadCastData: value, openCast: true })
        } else if (moment(current_time).isSameOrAfter(end_time)) {
            this.setState({ broadCastData: value, openBroadCast: true })
        } else {
            this.setState({ broadCastData: value, openBroadCast: true })
        }
    }

    handleSearchChange(data) {
        const data_lowercase = data.toLowerCase()
        var search_words = data_lowercase.split(" ");
        search_words = search_words.filter(n => n)
        var filtered_data = []
        if (search_words.length === 0) {
            filtered_data = this.state.copyData
        }

        for (let i = 0; i < this.state.copyData.length; i++) {
            const element = this.state.copyData[i];
            var num_of_passed = false
            for (let j = 0; j < search_words.length; j++) {
                const el = search_words[j];
                num_of_passed = element.name.toLowerCase().includes(el)
                if (!num_of_passed) {
                    break
                }
            }
            if (num_of_passed) {
                filtered_data.push(element)
            }
        }
        this.setState({ data: filtered_data })
    }

    // componentWillUnmount() {
    //     clearInterval(this.refreshData)
    // }


    render() {
        const handleBroadCastClose = () => {
            this.setState({ openBroadCast: false })
        };
        const handleCastClose = () => {
            this.setState({ openCast: false })
        };
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) :
                    <div>
                        <Box display='flex' justifyContent={'center'} marginTop={'10px'}>
                            <TextField
                                autoFocus
                                autoComplete='off'
                                size='small'
                                placeholder="Search"
                                id="search"
                                onChange={(e) => this.handleSearchChange(e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                }}
                            />
                        </Box>
                        {this.state.data.length !== 0 ?
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={'5px'}>
                                {this.state.data.map((value) =>
                                    <Grid item xs={isMobile() ? 12 : screenSizeCheck() ? 4 : 3}>
                                        <Card style={{ margin: '10px', }} elevation={3} className='gamecard'>
                                            <CardActionArea onClick={() => this.allowCLick(value)}>
                                                <CardContent>
                                                    <Box display='flex' flexDirection='column' >
                                                        <Box display='flex' flexDirection='row' alignItems={'center'}>
                                                            <Box marginRight="10px"  >
                                                                <Typography variant='h5'>
                                                                    {value.name}
                                                                </Typography>
                                                            </Box>
                                                            <Box >
                                                                {(value?.support_url && value.support_url !== null) ?
                                                                    <p style={{ marginRight: '5px', fontSize: '12px', borderRadius: '3px ', backgroundColor: 'blue', color: 'white', padding: '3px' }}>
                                                                        Recorded
                                                                    </p> : (value.support_url === null) ? < p
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            this.props.enqueueSnackbar('Playback unavailable', {
                                                                                variant: 'info'
                                                                            })
                                                                        }}
                                                                        style={{ marginRight: '5px', fontSize: '12px', borderRadius: '3px ', backgroundColor: 'green', color: 'white', padding: '3px', cursor: 'pointer' }}>
                                                                        Completed
                                                                    </p> : null}
                                                            </Box>
                                                        </Box>

                                                        <Box display='flex' flexDirection={'row'} alignItems='center'>
                                                            <Typography variant='subtitle1'>
                                                                Date of Game: {moment(value.start).format('DD-MM-YYYY')}
                                                            </Typography>
                                                        </Box>
                                                        <Box display='flex' flexDirection={'row'} alignItems='center' >
                                                            <Box display='flex' flexDirection={'row'} alignItems='center' >
                                                                <Typography variant='body2'>
                                                                    Timing: {moment(value.start).format('hh:mm A')} - {moment(value.end).format('hh:mm A')}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Divider variant="middle" style={{ marginTop: '5px' }} />
                                                    <Box display='flex' flexDirection={'row'} mt={1}>
                                                        {!value.final_announcement ?
                                                            <Box>
                                                                <Typography variant='caption' >
                                                                    Live Streaming will start 15 mins before from the game schedule
                                                                </Typography>
                                                            </Box> :
                                                            <Box >
                                                                <Typography variant='caption' color={getMode() ? 'secondary' : 'primary'}>
                                                                    {value.final_announcement}
                                                                </Typography>
                                                            </Box>}
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )}
                            </Grid> :
                            <Box display='flex' justifyContent={'center'} style={{ height: '500px' }} alignItems={'center'} fontSize={'16px'} fontWeight={'bold'}>No Games</Box>}

                        <div>
                            <Dialog fullScreen open={this.state.openBroadCast} onClose={handleBroadCastClose} TransitionComponent={Transition}>
                                <AppBar position='fixed' color="transparent" elevation={0} >
                                    <Toolbar>
                                        <IconButton edge="start" color="inherit" onClick={handleBroadCastClose} aria-label="close">
                                            <CloseIcon color="error" />
                                        </IconButton>
                                        <Typography variant="h6" color='primary'>
                                            {this.state.broadCastData ? this.state.broadCastData.name : null}
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                <BroadCastReactPlayer data={this.state.broadCastData} />
                            </Dialog>
                            <Dialog fullScreen open={this.state.openCast} onClose={handleCastClose} TransitionComponent={Transition}>
                                <AppBar position='fixed' color="transparent" elevation={0} >
                                    <Toolbar>
                                        <IconButton edge="start" color="inherit" onClick={handleCastClose} aria-label="close">
                                            <CloseIcon color="error" />
                                        </IconButton>
                                        <Typography variant="h6" color='primary'>
                                            {this.state.broadCastData ? this.state.broadCastData.name : null}
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                <BroadCastPlayer data={this.state.broadCastData} />
                            </Dialog>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default withSnackbar(CompletedBroadCast)
