import { Box, Button, Card, CardActionArea, CardContent, DialogTitle, Divider, Grid, Slide } from '@material-ui/core';
import moment from 'moment';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../../Helper/CommonThings';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getMode } from '../../MainDashBoard/ColorTheme/ColorTheme';
import Service from '../../Networking/networkutils'
import { Dialog, DialogContent } from '@material-ui/core'

const token = getCookie("bb_ts_token");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class WorkPlans extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      workPlans: [],
      expanded: false,
      selectedPlan: null,
      openSelectedPlan: false,
    }
  }

  componentDidMount() {
    this.getPlans()
  }

  getPlans() {
    Service.put(`/bb/api/user/work/plan/allowed_plan_for_user/`, {
      headers: {
        Authorization: "Token " + token,
      },
      data: {
        date: moment(this.props.date).format('YYYY-MM-DD'),
        user_id: this.props.uid
      },
    })
      .then(res => {
        if (this.props.currentPlan) {
          for (let i = 0; i < res.data.length; i++) {
            const element = res.data[i];
            if (element.id === this.props.currentPlan.work_plan) {
              element.isCurrentPlan = true
              element.planUpto = moment(this.props.currentPlan.start_date).add(1, 'days').format("MMM DD") + " - " + moment(this.props.currentPlan.end_date).subtract(1, 'days').format("MMM DD")
            } else {
              element.isCurrentPlan = false
            }
          }
        }
        this.setState({
          workPlans: res.data,
          isLoading: false
        });
      })
      .catch(e => {
        errorMessage(this.props, e)
        console.log(e)
      })
  }

  selectedPlan(plan) {
    this.setState({ openSelectedPlan: true, selectedPlan: plan, })
  }

  agreePlan(plan) {
    Service.put(`/bb/api/user/work/plan/work_plan_change_api/`, {
      headers: {
        Authorization: "Token " + token,
      },
      data: {
        user_id: this.props.uid,
        date: moment(this.props.date).format('YYYY-MM-DD'),
        plan_id: plan.id,
      },
    })
      .then(res => {
        this.props.selectedPlan(plan)
        this.setState({ openSelectedPlan: false, selectedPlan: null })
      })
      .catch(error => {
        if (error && error.status === 406) {
          this.props.enqueueSnackbar('Not allowed', {
            variant: 'warning'
          });
        } else {
          errorMessage(this.props, error)
          console.log(error)
        }
      })
  }

  render() {
    const handlePlanClose = () => {
      this.setState({
        openSelectedPlan: false,
        selectedPlan: null
      })
    };

    return (
      this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
        <div>
          {
            this.state.workPlans.length !== 0 ?
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                {this.state.workPlans.map((value, i) =>
                  <Grid item xs={6} key={i}>
                    <Card style={{ margin: '10px', padding: '5px' }} elevation={3} className='card' >
                      <CardActionArea onClick={() => this.selectedPlan(value)} >
                        <CardContent >
                          <Box flexDirection="column" display="flex" >
                            <Box flexDirection="row" display="flex" alignItems={'center'} marginBottom={value.isCurrentPlan ? "2px" : '15px'}>
                              <Box fontSize={'20px'} fontWeight={500} flexGrow={1} >
                                {value.name}
                              </Box>
                              <Box>
                                {value.isCurrentPlan ?
                                  <p style={{ fontSize: '10px', borderRadius: '3px', backgroundColor: getMode() ? '#f5f5f0' : '#DCDCDC', color: getMode() ? 'black' : 'black', padding: '3px' }}>
                                    Current Plan {"(" + value.planUpto + ")"}
                                  </p>
                                  : !value.allow_to_book ?
                                    <p style={{ fontSize: '10px', borderRadius: '3px', backgroundColor: getMode() ? '#ff9999' : '#ffb3b3', color: getMode() ? 'black' : 'black', padding: '3px' }}>
                                    Unavailable
                                    </p>
                                    : null}
                              </Box>
                            </Box>
                            <Box fontSize={'16px'}>
                              {value.decs}
                            </Box>
                            <Box marginTop='10px' fontSize={'14px'}>
                              <b>Note:</b> {value.note}
                            </Box>
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )}
              </Grid> :
              <Box display='flex' justifyContent={'center'} style={{ height: '500px' }} alignItems={'center'} fontSize={'16px'} fontWeight={'bold'}>No WorkPlans</Box>
          }

          <Dialog fullWidth maxWidth="xs" onClose={handlePlanClose} aria-labelledby="customized-dialog-title" open={this.state.openSelectedPlan} TransitionComponent={Transition}>
            <DialogTitle id="customized-dialog-title" onClose={handlePlanClose} >
              <Box fontSize={'20px'} >
                {this.state.selectedPlan ? this.state.selectedPlan.name : null}
              </Box>
            </DialogTitle>
            <DialogContent>
              {this.state.selectedPlan ?
                <Box flexDirection="column" display="flex" >
                  <Box marginBottom={'5px'}>
                    {this.state.selectedPlan.decs}
                  </Box>
                  <Box marginTop='5px' marginBottom={'5px'}>
                    <b>Note:</b> {this.state.selectedPlan.note}
                  </Box>
                  <Divider />
                  <Box marginTop={'10px'} display="flex" flexGrow={1} justifyContent='space-evenly' alignItems='left' marginBottom={'5px'}>
                    <Box display='flex' flexDirection={'column'} justifyContent='left'>
                      <Box >
                        <b>No of Weeks:</b> {this.state.selectedPlan.duration_in_week}
                      </Box>
                      <Box marginTop='5px'>
                        <b>Hours per day:</b> {this.state.selectedPlan.per_day_hour}
                      </Box>
                    </Box>
                    <Box display='flex' flexDirection={'column'} justifyContent='left'>
                      <Box >
                        <b>Total Hours:</b> {this.state.selectedPlan.hours}
                      </Box>
                      <Box marginTop='5px'>
                        <b>Working Days:</b> {this.state.selectedPlan.days}
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                  <Box marginTop='10px' display='flex' flexDirection={'row-reverse'}>
                    <Button onClick={(e) => { this.agreePlan(this.state.selectedPlan) }} size='small' disabled={!this.state.selectedPlan.allow_to_book} color={getMode() ? 'secondary' : 'primary'}>Agree</Button>
                    <Button onClick={() => this.setState({ selectedPlan: null, openSelectedPlan: false })} size='small' color={getMode() ? 'secondary' : 'primary'}>Disagree</Button>
                  </Box>
                </Box>
                : null}
            </DialogContent>
          </Dialog>
        </div>
    )
  }
}
export default withSnackbar(WorkPlans)
