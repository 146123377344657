import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import MaterialTable from 'material-table';
import { Dialog, DialogTitle, DialogContent, Box, DialogActions, Button, Typography, CircularProgress } from '@material-ui/core';
import RoleMapping from '../Estimate/Tender/RoleMapping';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import SecuredProjectRoles from './SecuredProjectRoles';
// import WorkIcon from '@material-ui/icons/Work';
// import TaskLevelAllocation2 from './TaskLevelAllocation/TaskLevelAllocation2';
import TaskLevelTabs from './TaskLevelAllocation/TaskLevelTabs';
import WorkIcon from '@material-ui/icons/Work';
import ReactTooltip from 'react-tooltip';
import { errorMessage } from '../Helper/ErrorMessage';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import FindUserByRole from '../FindUserByRole/FindUserByRole';
import { getPrimaryColor } from '../Helper/CommonThings';

// const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

const findDuplicates = (arr) => {
    let sorted_arr = arr.slice().sort(); // You can define the comparing function here. 
    // JS by default uses a crappy string compare.
    // (we use slice to clone the array so the
    // original array won't be modified)
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] === sorted_arr[i]) {
            results.push(sorted_arr[i]);
        }
    }
    return results;
}
const token = getCookie("bb_ts_token");
class UserTaskMap extends Component {

    constructor(props) {
        super()

        this.state = {
            roles: [],
            deliSch: [],
            isLoading: true,
            tasks: [],
            wbs: [],
            mapped: [],
            rolesTemplate: [],
            columns: [],
            open: false,
            mapRoles: {},
            mapTask: {},
            openPDM: false,
            allData: null,
            searchUserRoles: false,
            usersData: [],
        }
    }

    componentDidMount() {

        Service.get(`/bb/api/users/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res5 => {

                this.setState({ usersData: res5.data, })

            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });

        this.fetchOps();
    }

    fetchOps() {
        var url = `/bb/api/tender/project/?subproj=` + this.props.value.id + "&audit_status=10&no_sc=1"

        if (this.props.stage1a) {
            url = `/bb/api/tender/project/?subproj=` + this.props.value.id + "&audit_status=11&no_sc=1"
        }
        // console.log(this.props.type)
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                var rolesParam = '0';
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    rolesParam = rolesParam + ',' + element.id;
                }

                var deliData = res.data;

                this.setState({ deliSch: deliData })

                var wbsurl = `/bb/api/tender/ohlwbs/?pid=` + rolesParam

                if (this.props.type && this.props.type.allocation_access !== 3) {
                    if (this.props.type.allocation_access === 1) {
                        wbsurl = wbsurl + '&type=' + this.props.type.pdm_type
                    }
                    this.fetchOpsCont(wbsurl)
                } else if (this.props.type && this.props.type.allocation_access === 3) {
                    Service.get('/bb/api/tender/wbsmanager/?pid=' + this.props.value.id + '&uid=' + user.id, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(respro => {
                            wbsurl = `/bb/api/tender/ohlwbs/?id=0`

                            for (let i = 0; i < respro.data.length; i++) {
                                const element = respro.data[i];
                                wbsurl = wbsurl + "," + element.wbsid
                            }

                            this.fetchOpsCont(wbsurl)
                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                        });

                } else {
                    this.fetchOpsCont(wbsurl)
                }

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchOpsCont(wbsurl) {
        Service.get(wbsurl, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res0 => {

                // console.log(res0.data)

                var wbsParam = '0';
                for (let i = 0; i < res0.data.length; i++) {
                    const element = res0.data[i];
                    wbsParam = wbsParam + ',' + element.id;
                }

                Service.get(`/bb/api/tender/tasks/rolestasks/?wbs=` + wbsParam, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res2 => {

                        var taskArr = []
                        for (let i = 0; i < res2.data.length; i++) {
                            const element = res2.data[i];
                            element.req_hours = element.hours + element.req_hours
                            taskArr.push(element.t_tasks)
                        }

                        taskArr = taskArr.filter(onlyUnique)

                        for (let i = 0; i < taskArr.length; i++) {
                            const element = taskArr[i];
                            var roleArr = []
                            var roleArrIndex = [];
                            for (let j = 0; j < res2.data.length; j++) {
                                const element1 = res2.data[j];
                                // console.log(element1)
                                if (element1 && element === element1.t_tasks) {
                                    roleArr.push(element1.t_role)
                                    roleArrIndex.push(j)
                                }
                            }
                            var roleArr1 = findDuplicates(roleArr)

                            for (let k = 0; k < roleArr1.length; k++) {
                                const element2 = roleArr1[k];
                                var hoursinAllRole = 0
                                var found1index = -1;
                                for (let j = 0; j < roleArrIndex.length; j++) {
                                    const element1 = res2.data[roleArrIndex[j]];

                                    if (element1 && element === element1.t_tasks && element2 === element1.t_role && found1index !== -1) {
                                        hoursinAllRole = hoursinAllRole + element1.req_hours
                                        delete res2.data[roleArrIndex[j]]
                                    } else if (element1 && element === element1.t_tasks && element2 === element1.t_role && found1index === -1) {
                                        hoursinAllRole = hoursinAllRole + element1.req_hours
                                        found1index = roleArrIndex[j]
                                    }
                                }
                                res2.data[found1index].all_hours_to_this_role = hoursinAllRole
                            }

                            for (let m = 0; m < roleArrIndex.length; m++) {
                                const element2 = roleArrIndex[m];
                                if (res2.data[element2] && !(res2.data[element2].all_hours_to_this_role || res2.data[element2].all_hours_to_this_role === 0)) {
                                    res2.data[element2].all_hours_to_this_role = res2.data[element2].req_hours
                                }
                            }

                        }

                        // console.log(res2.data)

                        this.setState({ roles: res2.data, })

                        Service.get('/bb/api/roles/', {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res4 => {
                                this.setState({ rolesTemplate: res4.data }, () => {
                                    this.compileData()
                                })

                            })
                            .catch(error => {
                                errorMessage(this.props, error)
                            });



                        // Service.get(`/bb/api/tender/user/task/?pid=` + rolesParam, {
                        //     headers: {
                        //         Authorization: "Token " + token,
                        //     },
                        // })
                        //     .then(res3 => {

                        //         Service.get('/bb/api/roles/', {
                        //             headers: {
                        //                 Authorization: "Token " + token,
                        //             },
                        //         })
                        //             .then(res4 => {
                        //                 // console.log(re78s.data)
                        //                 this.setState({ rolesTemplate: res4.data }, () => {
                        //                     this.compileData()
                        //                 })
                        //             })
                        //             .catch(error => {
                        //                 this.props.enqueueSnackbar('Something went wrong!', {
                        //                     variant: 'error'
                        //                 });
                        //             });

                        //         this.setState({ mapped: res3.data, })

                        //     })
                        //     .catch(error => {
                        //         this.props.enqueueSnackbar('Something went wrong!', {
                        //             variant: 'error'
                        //         });
                        //     });

                    })
                    .catch(error => {
                        console.log(error)
                        errorMessage(this.props, error)
                    });



                this.setState({ wbs: res0.data, })

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    compileData() {
        // console.log(this.state.wbs)
        // console.log(this.state.tasks)
        // console.log(this.state.roles)
        // console.log(this.state.mapped)

        var tasks = [];
        var onRole = [];
        var columnRoles = [];

        for (let i = 0; i < this.state.roles.length; i++) {
            const element = this.state.roles[i];

            var taskPro = true;
            for (let j = 0; j < tasks.length; j++) {
                const element1 = tasks[j];
                if (element && element.t_tasks === element1.id) {
                    taskPro = false;
                }
            }

            if (element && taskPro) {
                tasks.push(element.t_tasks_data)
            }

            var rolesPro = true;
            for (let j = 0; j < onRole.length; j++) {
                const element1 = onRole[j];
                if (element && element.t_role === element1) {
                    rolesPro = false;
                }
            }

            if (element && rolesPro) {
                onRole.push(element.t_role)
            }

        }

        for (let i = 0; i < onRole.length; i++) {
            const element = onRole[i];
            for (let j = 0; j < this.state.rolesTemplate.length; j++) {
                const element1 = this.state.rolesTemplate[j];
                if (element === element1.id) {
                    columnRoles.push(element1)
                }
            }
        }

        var viewPid = []
        for (let i = 0; i < tasks.length; i++) {
            const element = tasks[i];
            viewPid.push({ 'ttid': element.id })
            for (let j = 0; j < this.state.roles.length; j++) {
                const element1 = this.state.roles[j];
                if (element1 && element.id === element1.t_tasks) {
                    var json_key = element1.t_role.toString();
                    element[json_key] = element1;
                }
            }

            for (let j = 0; j < this.state.wbs.length; j++) {
                const element1 = this.state.wbs[j];
                if (element.t_ohlwbs === element1.id) {
                    element.wbs = element1;
                    element.t_wbs_data = element1;
                }
            }

            for (let j = 0; j < this.state.deliSch.length; j++) {
                const element1 = this.state.deliSch[j];
                if (element.t_pid === element1.id) {
                    element.ds = element1;
                }
            }


            var est_hrs = 0;
            for (let j = 0; j < this.state.roles.length; j++) {
                const element1 = this.state.roles[j];
                // console.log(element1.t_tasks + "   " + element.id)
                if (element1 && element1.t_tasks === element.id) {
                    est_hrs = est_hrs + element1.all_hours_to_this_role;
                }
            }
            element.est_hrs = est_hrs;

        }

        var tempTasks = []
        for (let taskIndex = 0; taskIndex < tasks.length; taskIndex++) {
            const taskItem = tasks[taskIndex];
            if (taskItem.est_hrs !== 0) {
                tempTasks.push(taskItem)
            }
        }
        tasks = tempTasks

        var columns = [];

        columns.push({
            title: 'Deliverables Schedule',
            field: 'ds.name',
            headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
        })

        columns.push({
            title: 'WBS',
            field: 'wbs.name',
            headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
        })
        columns.push({
            title: 'ID', field: 'id', editable: 'never',

        })

        columns.push({
            title: 'Task',
            field: 'name',
            headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            render: rowData =>  rowData.decs ? rowData.decs : rowData.name,
        })

        columns.push({
            title: 'Revised Hours',
            field: 'est_hrs',
            // type: 'date',

        })

        columns.push({
            title: 'Allocated Hours',
            field: 'pld_hrs',
            defaultSort: (this.state.checkedB) ? ('desc') : (null),
            render: rowData => (rowData.pld_hrs || rowData.pld_hrs === 0) ? (<Typography>{rowData.pld_hrs}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)

            // type: 'date',

        })

        columns.push({
            title: 'Actual Hours',
            field: 'actual_hours',
            render: rowData => (rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{rowData.actual_hours}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)
        })

        // columns.push({
        //     title: 'Assigned',
        //     field: 'assign_users',
        //     render: rowData => (rowData.assign_users || rowData.assign_users === '') ? (<Typography>{rowData.assign_users}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)
        // })

        // columns.push({
        //     title: 'Task Level Allocation',
        //     field: 'dumm',
        //     // tyfiltering: false,
        //     render: rowData => <IconButton onClick={() => { rowData.t_wbs_data = rowData.wbs; this.setState({ allData: rowData, openTaskLevelAllocation: true, }) }}><WorkIcon /></IconButton>
        // })

        // columns.push({
        //     title: 'Task Level Allocation',
        //     field: 'dumm',
        //     // tyfiltering: false,
        //     render: rowData =>
        //         <div>
        //             <IconButton data-tip data-for={'happyFace' + rowData.id} onClick={() => { rowData.t_wbs_data = rowData.wbs; this.setState({ allData: rowData, openTaskLevelAllocation: true, }) }}><WorkIcon /></IconButton>
        //             <ReactTooltip id={'happyFace' + rowData.id} type='error'>
        //                 <spam>{(rowData.assign_users || rowData.assign_users === '') ? (<Box fontSize={10}>{rowData.assign_users}</Box>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)}</spam>
        //             </ReactTooltip>
        //         </div>
        // })

        // ## Role Column Add
        // for (let i = 0; i < columnRoles.length; i++) {
        //     const element = columnRoles[i];
        //     columns.push({
        //         title: element.name,
        //         field: element.id + ".name",
        //         filtering: false,
        //         render: rowData => (rowData[(element.id).toString()]) ? (<AddIcon onClick={() => { this.setState({ open: true, mapRoles: rowData[(element.id).toString()], mapTask: rowData }) }} />) : (null)
        //     })
        // }

        this.setState({ tasks: tasks, columns: columns, isLoading: false }, () => {
            if (viewPid.length !== 0) {
                
                Service.patch("/bb/api/team/tasks/get_acthours/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: viewPid,
                })
                    .then(res12 => {

                        // console.log(res12.data)

                        for (let j = 0; j < res12.data.length; j++) {
                            const element13 = res12.data[j];
                            for (let i = 0; i < tasks.length; i++) {
                                const element12 = tasks[i];
                                if (element12.id === element13.id) {
                                    element12.actual_hours = timeToDecimal(element13.hours_actual)
                                    element12.pld_hrs = element13.allocated
                                    break;
                                }
                                // console.log(timeToDecimal(element13.hours_actual) !== 0)
                            }

                        }
                        // console.log(tasks)
                        this.setState({

                            tasks: tasks,

                        })
                        Service.patch("/bb/api/tender/wbs/tasks/get_assignusers/", {
                            headers: {
                                Authorization: "Token " + token,
                            },
                            data: viewPid,
                        })
                            .then(res21 => {
                                // console.log(res21.data)

                                for (let j = 0; j < tasks.length; j++) {
                                    const element13 = tasks[j];
                                    var names = ''
                                    for (let i = 0; i < res21.data.length; i++) {
                                        const element12 = res21.data[i];
                                        if (element12.id === element13.id) {
                                            for (let k = 0; k < element12.users.length; k++) {
                                                const element14 = element12.users[k];
                                                if (names !== '') {
                                                    names = names + ", " + element14.first_name + "(" + element14.hours + ")"
                                                } else {
                                                    names = element14.first_name + "(" + element14.hours + ")"
                                                }
                                            }
                                        }
                                        // console.log(timeToDecimal(element13.hours_actual) !== 0)
                                    }
                                    element13.assign_users = names

                                }
                                this.setState({

                                    tasks: tasks,

                                })
                            })
                            .catch(error => {
                                this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                                    variant: 'error'
                                });

                            });
                    })
                    .catch(error => {
                        this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                            variant: 'error'
                        });

                    });
            }
        })

    }

    render() {

        const handleClose = () => {
            this.setState({ open: false, openPDM: false, openTaskLevelAllocation: false, searchUserRoles: false })
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                        <div>
                            <Box p={3}>
                                <MaterialTable
                                    title="Roles"
                                    columns={this.state.columns}
                                    data={this.state.tasks}
                                    options={{
                                        padding: "dense",
                                        filtering: true,
                                        headerStyle: { position: "sticky", top: 0 },
                                        // maxBodyHeight: Math.round(window.screen.height - 80),
                                        pageSize: 20,
                                        pageSizeOptions: [10, 20, 50],
                                        search: false,

                                    }}
                                    actions={[
                                        rowData => ({
                                            icon: () =>
                                                <div>
                                                    <WorkIcon data-tip data-for={'happyFace' + rowData.id} />
                                                    <ReactTooltip id={'happyFace' + rowData.id} type='error' backgroundColor={getPrimaryColor()}>
                                                        {(rowData.assign_users || rowData.assign_users === '') ? (<Box fontSize={10}>{rowData.assign_users}</Box>) : (<CircularProgress color="#a5ff00" style={{ height: '15px', width: '15px' }} />)}
                                                    </ReactTooltip>
                                                </div>,
                                            // tooltip: 'Task Level Allocation',
                                            disabled: !(this.state.usersData.length && ((access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e))),
                                            onClick: (event, rowData) => { rowData.t_wbs_data = rowData.wbs; this.setState({ allData: rowData, openTaskLevelAllocation: true, }) },
                                        }),
                                        {
                                            icon: () =>
                                                <Button variant="contained" color="primary" >Find User Role</Button>,
                                            isFreeAction: true,
                                            onClick: () => { this.setState({ searchUserRoles: true }) },
                                        },
                                        {
                                            icon: 'refresh',
                                            tooltip: "Refresh",
                                            isFreeAction: true,
                                            onClick: () => { this.setState({ isLoading: true }); this.fetchOps(); },
                                            // onClick={() => { }}
                                        },
                                    ]}
                                />
                            </Box>
                            <Dialog onClose={handleClose} fullwidth="true" maxWidth="xl" aria-labelledby="customized-dialog-title" open={this.state.open}>
                                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                                    {this.state.mapRoles.name + " (" + this.state.mapRoles.all_hours_to_this_role + " hours)"}
                                </DialogTitle>
                                <DialogContent dividers>
                                    <RoleMapping value={this.state.mapRoles} task={this.state.mapTask} />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                open={this.state.openPDM}
                                fullWidth={true}
                                maxWidth={'xl'}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogContent>
                                    <SecuredProjectRoles value={this.state.allData} task={this.state.allData} />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Close
                    </Button>

                                </DialogActions>
                            </Dialog>
                            <Dialog
                                open={this.state.openTaskLevelAllocation}
                                fullWidth={true}
                                maxWidth={'xl'}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle id="customized-dialog-title2" >
                                    {(this.state.allData) ? this.props.value.tender_name + " -> " + this.state.allData.id + " " + this.state.allData.name : null}
                                </DialogTitle>
                                <DialogContent>
                                    <TaskLevelTabs value={this.state.allData} usersData={this.state.usersData} />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Close
                    </Button>

                                </DialogActions>
                            </Dialog>
                            <Dialog
                                open={this.state.searchUserRoles}
                                fullWidth
                                maxWidth={'sm'}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle id="customized-dialog-title2" >
                                    Find Role of a user
                                </DialogTitle>
                                <DialogContent>
                                    <FindUserByRole />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Close
                    </Button>

                                </DialogActions>
                            </Dialog>
                        </div>
                    )}
            </div>
        )
    }
}

function addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec ? 1 : null
    if (len) {
        return parseFloat(Number(num).toFixed(len))
    } else {
        return parseFloat(Number(num))
    }
}

function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export default withSnackbar(UserTaskMap);