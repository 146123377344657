import React from 'react'
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';

class DemandReport extends React.Component {

    constructor() {
        super()
        this.state = {
            columns: [],
            data: [],
            totalDataMonth: [],
            dataLength: 0,
            isLoading: true
        }
    }

    componentDidMount() {
        let filter = this.props.periodType;
        let allRolesSwitch = this.props.allRole;
        let data = this.props.data;
        // console.log('render')

        let week_columns = [
            { title: 'Role', field: 'role', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 } }
        ]
        let month_column = [...week_columns];

        // let totalDataMonth = [];

        if (filter === 'weeks') {
            for (let i = 0; i < data[0]['week_data'].length; i++) {
                week_columns.push(
                    {
                        title: 'W' + data[0]['week_data'][i]['week_number'],
                        field: data[0]['week_data'][i].start_date,
                        render: (rowData) => rowData[data[0]['week_data'][i].start_date] === 0 ? '' : rowData[data[0]['week_data'][i].start_date]
                    }
                )
            }
        }


        if (filter === 'months') {
            for (let i = 0; i < data[0]['month_data'].length; i++) {
                month_column.push(
                    {
                        title: data[0]['month_data'][i]['name'],
                        field: data[0]['month_data'][i].start_date,
                        render: (rowData) => rowData[data[0]['month_data'][i].start_date] === 0 ? '' : rowData[data[0]['month_data'][i].start_date]
                    }
                )
                // totalDataMonth.push(data[0]['month_data'][i].name)
            }
        }

        let demandWeekData = [];
        let demandMonthData = [];
        let demandRoles = [];

        let totalDataForMonth = { role: 'Total' }
        let totalDataForWeek = { role: 'Total' }
        for (let i = 0; i < data.length; i++) {
            let role = data[i];
            if (allRolesSwitch === false) {
                if (role.hours_req_per_day !== 0) {
                    demandRoles.push(role.id)
                    if (filter === 'weeks') {
                        // For demand week
                        let role_demand_week = { role: role.name }
                        for (let j = 0; j < role.week_data.length; j++) {
                            role_demand_week = { ...role_demand_week, [role.week_data[j].start_date]: (role.week_data[j].demand % 1 !== 0) ? Number(role.week_data[j].demand).toFixed(2) : role.week_data[j].demand }
                            if (totalDataForWeek.hasOwnProperty(role.week_data[j].start_date)) {
                                let add = Number(totalDataForWeek[role.week_data[j].start_date]) + Number(role.week_data[j].demand)
                                totalDataForWeek = { ...totalDataForWeek, ...{ [role.week_data[j].start_date]: add === 0 ? '' : add.toFixed(2) } }
                            }
                            else {
                                totalDataForWeek = { ...totalDataForWeek, ...{ [role.week_data[j].start_date]: Number(role.week_data[j].demand).toFixed(2) } }
                            }
                        }
                        demandWeekData.push(role_demand_week);
                    }

                    if (filter === 'months') {
                        // For demand month
                        let role_demand_month = { role: role.name }
                        for (let j = 0; j < role.month_data.length; j++) {
                            role_demand_month = { ...role_demand_month, [role.month_data[j].start_date]: (role.month_data[j].demand % 1 !== 0) ? Number(role.month_data[j].demand).toFixed(2) : role.month_data[j].demand }
                            if (totalDataForMonth.hasOwnProperty(role.month_data[j].start_date)) {
                                let add = Number(totalDataForMonth[role.month_data[j].start_date]) + Number(role.month_data[j].demand)
                                totalDataForMonth = { ...totalDataForMonth, ...{ [role.month_data[j].start_date]: add === 0 ? '' : add.toFixed(2) } }
                            }
                            else {
                                totalDataForMonth = { ...totalDataForMonth, ...{ [role.month_data[j].start_date]: Number(role.month_data[j].demand).toFixed(2) } }
                            }
                        }
                        demandMonthData.push(role_demand_month)
                    }
                }
            }
            if (allRolesSwitch === true) {
                if (filter === 'weeks') {
                    // For demand week
                    let role_demand_week = { role: role.name }
                    for (let j = 0; j < role.week_data.length; j++) {
                        role_demand_week = { ...role_demand_week, [role.week_data[j].start_date]: (role.week_data[j].demand % 1 !== 0) ? Number(role.week_data[j].demand).toFixed(2) : role.week_data[j].demand }
                        if (totalDataForWeek.hasOwnProperty(role.week_data[j].start_date)) {
                            let add = Number(totalDataForWeek[role.week_data[j].start_date]) + Number(role.week_data[j].demand)
                            totalDataForWeek = { ...totalDataForWeek, ...{ [role.week_data[j].start_date]: add === 0 ? '' : add.toFixed(2) } }
                        }
                        else {
                            totalDataForWeek = { ...totalDataForWeek, ...{ [role.week_data[j].start_date]: Number(role.week_data[j].demand).toFixed(2) } }
                        }
                    }
                    demandWeekData.push(role_demand_week);


                }

                if (filter === 'months') {
                    // For demand month
                    let role_demand_month = { role: role.name }
                    for (let j = 0; j < role.month_data.length; j++) {
                        role_demand_month = { ...role_demand_month, [role.month_data[j].start_date]: (role.month_data[j].demand % 1 !== 0) ? Number(role.month_data[j].demand).toFixed(2) : role.month_data[j].demand }
                        if (totalDataForMonth.hasOwnProperty(role.month_data[j].start_date)) {
                            let add = Number(totalDataForMonth[role.month_data[j].start_date]) + Number(role.month_data[j].demand)
                            totalDataForMonth = { ...totalDataForMonth, ...{ [role.month_data[j].start_date]: add === 0 ? '' : add.toFixed(2) } }
                        }
                        else {
                            totalDataForMonth = { ...totalDataForMonth, ...{ [role.month_data[j].start_date]: Number(role.month_data[j].demand).toFixed(2) } }
                        }
                    }
                    demandMonthData.push(role_demand_month)


                }
            }
        }
        // week_columns.push({ title: 'Total', field: 'total', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 } })
        // month_column.push({ title: 'Total', field: 'total', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 } })
        demandMonthData.push(totalDataForMonth);
        demandWeekData.push(totalDataForWeek)
        this.props.updateRole(demandRoles)
        if (filter === 'months') {
            this.setState({
                columns: month_column,
                data: demandMonthData,
                dataLength: demandMonthData.length,
                isLoading: true
            },
                () => {
                    this.setState({ isLoading: false })
                })
        }
        if (filter === 'weeks') {
            this.setState({ columns: week_columns, data: demandWeekData, dataLength: demandWeekData.length, isLoading: true }, () => {
                this.setState({ isLoading: false })
            })
        }
    }

    render() {
        return (
            <div>
                {this.state.isLoading === false ? <MaterialTable
                    columns={this.state.columns}
                    data={this.state.data}
                    options={{
                        pageSize: this.props.groupByRolesSwitch ? this.state.dataLength : 20,
                        pageSizeOptions: this.props.groupByRolesSwitch ? [this.state.dataLength]:  [20, 50, 100] ,
                        // fixedColumns: {
                        //     left: 1,
                        //     right: 0
                        // },
                        // filtering: true,
                        toolbar: false,
                        padding: 'dense',
                        rowStyle: (rowData) => {
                            // console.log(rowData)
                            if (rowData.role === 'Total') {
                                return {
                                    // backgroundColor: getPrimaryColor(),
                                    color: '#444',
                                    fontWeight: 'bold'
                                }
                            }
                        },
                    }}
                /> : null}
            </div>
        )
    }
}
export default withSnackbar(DemandReport)