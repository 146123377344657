import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import { Box, Typography } from '@material-ui/core';
import ManageTasks from './ManageTasks';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));
// var autocompdata = '';
class ManageWBS extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            data1: [],
            data2: [],
            dataT: [],
            rates:[],
            openAdd: false,
            openEdit: false,
            projName: '',
            projId: '',
            isLoading: true,
            isLoading2: true,
            isLoading4: true,
            selectType: 'ohl',
            allTemp: [],
        }
    }


    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        Service.get('/bb/api/tender/ohlwbs/?pid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({ data1: res.data, isLoading: false })
            })
            .catch(error => {
               errorMessage(this.props, error)
            });

        Service.get('/bb/api/tender/tasks/roles/?pid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({ data2: JSON.parse(JSON.stringify(res.data)), isLoading4: false, });
            })
            .catch(error => {
               errorMessage(this.props, error)
            });

        Service.get('/bb/api/tender/rate/?pid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                if (res.data.length === 0) {
                    this.props.enqueueSnackbar('Goto Rates tab to initiate default rates!', {
                        variant: 'warning'
                    });
                } else {

                    this.setState({
                        isLoading2: false,
                        rates: res.data,
                    });
                }
            })
            .catch(error => {
               errorMessage(this.props, error)
            });
    }

    costCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_wbs === rowData.id) {
                cost = cost + this.costCalc2(element)
            }
        }
        return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
    }

    costCalc2(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return data1.hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costuk;
                }
            }
        }
        return 0;
    }

    hourCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_wbs === rowData.id) {
                cost = cost + parseFloat(element.hours);
            }
        }
        return cost.toFixed(2);
    }

    render() {

        return (
            <div>
                {(this.state.isLoading || this.state.isLoading2 || this.state.isLoading4) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                        <div>
                            <MaterialTable
                                title="Deliverables Package"
                                columns={[
                                    { title: 'WBS ID', field: 'id', filtering: false },
                                    { title: 'WBS', field: 'name' },
                                    {
                                        title: 'Type', field: 'type',
                                        lookup: { 'ohl': 'OHL', 'cabling': 'Cabling', 'sub_struc': 'Substation Primary', 'ohl_civ_struc': 'Substation Secondary', 'sub_civ_struct': 'Substation Civ Structure', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical' },
                                    },
                                    {
                                        title: 'Budgeted Hours', field: 'hour',
                                        render: rowData => <Typography>{this.hourCalc(rowData)}</Typography>
                                    },
                                    {
                                        title: 'Actual Hours', filed: 'dummy',
                                        render: rowData => <Typography>0</Typography>
                                    },
                                    {
                                        title: "Hours to come", filed: 'dummy',
                                        render: rowData => <Typography>{this.hourCalc(rowData)}</Typography>
                                    },
                                    {
                                        title: 'Hours at completion', filed: 'dummy',
                                        render: rowData => <Typography>{this.hourCalc(rowData)}</Typography>
                                    },
                                    {
                                        title: 'Variance', filed: 'dummy',
                                        render: rowData => <Typography>0</Typography>
                                    },
                                    
                                    {
                                        title: 'Anticipated Final Pain (-) / Gain (+)', filed: 'dummy',
                                        render: rowData => <Typography>0</Typography>
                                    }
                                ]}
                                data={this.state.data1}

                                options={{
                                    padding: "dense",
                                    paging: false,
                                    actionsColumnIndex: -1,
                                    filtering: true
                                }}

                                detailPanel={rowData => {
                                    return (
                                        <Box p={3}><ManageTasks value={rowData} roles={this.state.data2} /></Box>
                                    )
                                }}

                            />

                        </div>
                    )}

            </div>
        )
    }
}

export default withSnackbar(ManageWBS);