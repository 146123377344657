import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { Tooltip, withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CardMedia from '@material-ui/core/CardMedia';
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import InputIcon from '@material-ui/icons/Input';
import { getCookie, setCookie, deleteCookie } from '../Helper/CookieHelper';
// import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ReactGA from 'react-ga';
import TimesheetReportTab from './TimesheetReport/TimesheetReportTab';
import DemandVsCapcity from './DemandVsCapcity';
import { errorMessage } from '../Helper/ErrorMessage';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import UsersTimesheetSummary from './UsersTimesheetSummary/UsersTimesheetSummary';
// import ReactGA from 'react-ga';
// import {} from '@material-ui/icons'
import ResourceReport from './ResourceReport/ResourceReport';
import DvsC2 from './DvsC2/DvsCHome'
import ListAltIcon from '@material-ui/icons/ListAlt';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import DetailsIcon from '@mui/icons-material/Details';
import KPIReport from '../Kpi/KPIReport';
import { accessReloadOrRefresh, getPrimaryColor } from '../Helper/CommonThings';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import DownloadingIcon from '@mui/icons-material/Downloading';
// import ChangeManagement from '../HeadOfs/ChangeManagement';
import CMRisk from '../HeadOfs/CMRisk/CMRisk';
import UserTimesheetReport from './UserTimesheetReport/UserTimesheetReport';
import HoeGate6 from '../HoeGate6/HoeGate6';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AllUsersWorkload from './AllUsersWorkload/AllUsersWorkload';

const drawerWidth = 240;

const useStyles = (theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        width: `calc(100% - ${drawerWidth}px)`,
        padding: theme.spacing(3),
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    tutorial: {
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
    },
    active: {
        backgroundColor: "red"
    }
});

const StyledListItem = withStyles({
    root: {
        "&.Mui-selected": {
            backgroundColor: "#CFE9F8"
        }
    }
})(ListItem);

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
// const loadergot = getCookie("load_got_user");
var email = '';

class Home extends Component {

    constructor(props) {
        super(props)

        this.state = {
            openDialog: false,
            firstname: '',
            lastname: '',
            subButton: true,
            openDrawer: false,
            userData: {},
            viewClass: 'Timesheet Report',
            menus: [],
            isLoading: true,
            userAccess: {},
        }
    }

    componentDidMount() {

        var pageView = window.location.pathname + "/#TenderHome";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        } else {
            Service.get(`/rest-auth/user/`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    // console.log(!((access && access.access && access.access.l5a && access.access.l5a.l5a_a)))
                    if (!((access && access.access && access.access.l15a && access.access.l15a.l15a_a))) {
                        window.location.href = '/'
                    }
                    // console.log(res.data)
                    setCookie('bb_ts_user', JSON.stringify(res.data), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                    email = res.data.email;
                    var names = false;
                    if ((res.data.first_name === "" && res.data.last_name === "") || (res.data.first_name === null || res.data.last_name === null)) {
                        names = true;
                    } else {
                        this.props.enqueueSnackbar('Welcome back ' + res.data.first_name + '!', {
                            variant: 'info'
                        });
                    }
                    this.setState({
                        isLoading: false,
                        userData: res.data,
                        openDialog: names,
                    });

                    // this.getAccess(res.data);
                })
                .catch(error => {
                    try {
                        errorMessage(this.props, error)
                    } catch (error) {
                        errorMessage(this.props, error)
                    }

                });

            this.getAccess();

            this.createMenu();

        }
    }

    getAccess() {
        Service.get(`/bb/api/token/access/`, {
            headers: {
                Authorization: "Token " + getCookie("bb_ts_token"),
            },
        })
            .then(res => {
                if (res.data && res.data[0]) {
                    if (access) {
                        if (access.last_updated !== res.data[0].last_updated) {
                            setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                            accessReloadOrRefresh();
                        }
                    } else {
                        setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                        accessReloadOrRefresh();
                    }

                } else {
                    setCookie('bb_ts_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                    alert("This user doesn't have basic access")
                }


            })
            .catch(error => {
                setCookie('bb_ts_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
            });
    }

    createMenu() {
        // console.log(access.access)
        // ['Tender', 'Unsecured Work', 'Opportunities Tracker', 'Deliverables Template', 'Rate Card Template', 'Roles', 'Clients']
        var accessBuild = [];
        var defaultView = '';
        var hereMenu = [];

        var hashDone = false;

        var viewHash = window.location.hash.replace('#', '').replace('%20', ' ').replace('%20', ' ').replace('%20', ' ');

        if (access && access.access) {
            if (access.access.l15a && access.access.l15a.l15a_d) {
                if (viewHash === 'User Summary') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('User Summary')
                if (defaultView === '') {
                    defaultView = 'User Summary'
                }
            }

            if (access.access.l15a && access.access.l15a.l15a_b) {
                if (viewHash === 'Timesheet Report') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('Timesheet Report')
                defaultView = 'Timesheet Report'
            }
            if (access.access.l15a && access.access.l15a.l15a_c) {
                if (viewHash === 'DemandVsCapacity') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('DemandVsCapacity')
                if (defaultView === '') {
                    defaultView = 'DemandVsCapacity'
                }
            }
            if (access.access.l15a && access.access.l15a.l15a_e) {
                if (viewHash === 'D vs C') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('D vs C')
                if (defaultView === '') {
                    defaultView = 'D vs C'
                }
            }
            if (access.access.l15a && access.access.l15a.l15a_f) {
                if (viewHash === 'Lookahead Report') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('Lookahead Report')
                if (defaultView === '') {
                    defaultView = 'Lookahead Report'
                }
            }
            // console.log(access.access.l15a.l15a_g)
            if (access.access.l15a && access.access.l15a.l15a_g) {
                if (viewHash === 'KPI Report') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('KPI Report')
                if (defaultView === '') {
                    defaultView = 'KPI Report'
                }
            }

            if (access.access.l15a && access.access.l15a.l15a_h) {
                if (viewHash === 'Timesheet Download') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('Timesheet Download')
                if (defaultView === '') {
                    defaultView = 'Timesheet Download'
                }
            }

            if (access.access.l22a && access.access.l22a.l22a_a) {
                if (viewHash === 'CM Risk') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('CM Risk')
                if (defaultView === '') {
                    defaultView = 'CM Risk'
                }
            }

            if (access.access.l15a && access.access.l15a.l15a_i) {
                if (viewHash === 'HOE Gate6') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('HOE Gate6')
                if (defaultView === '') {
                    defaultView = 'HOE Gate6'
                }
            }

            if (access.access.l15a && access.access.l15a.l15a_k) {
                if (viewHash === 'Members Workload') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                hereMenu.push('Members Workload')
                if (defaultView === '') {
                    defaultView = 'Members Workload'
                }
            }
            // hereMenu.push('Resource Report')
        }

        accessBuild.push(hereMenu)

        if (hashDone) {
            this.setState({
                menus: accessBuild,
            })
        } else {
            this.setState({
                menus: accessBuild,
                viewClass: defaultView,
            })
        }

    }


    handleTextChange(e) {
        if (e.target.id === 'firstname') {
            this.setState({
                firstname: e.target.value,
            })
        } else if (e.target.id === 'lastname') {
            this.setState({
                lastname: e.target.value,
            })
        }
        if (this.state.firstname !== '' && this.state.lastname !== '') {
            this.setState({
                subButton: false,
            })
        } else {
            this.setState({
                subButton: true,
            })
        }
    }

    handleClose(e) {
        this.setState({
            subButton: false,
        })
        const json = {
            email: email,
            first_name: this.state.firstname,
            last_name: this.state.lastname,
        }

        Service.put(`/rest-auth/user/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: json,
        })
            .then(res => {
                deleteCookie("bb_ts_user");
                window.location.href = '/'
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }


    render() {
        const { classes } = this.props;

        const handleDrawerOpen = () => {
            this.setState({
                openDrawer: true,
            })
        };

        const handleDrawerClose = () => {
            this.setState({
                openDrawer: false,
            })
        };

        const handleViewChange = (e, val) => {

            var pageView = window.location.pathname + "/#" + val;
            ReactGA.set({ page: pageView, });
            ReactGA.pageview(pageView);

            if (!getCookie("bb_ts_token")) {
                window.location.href = '/'
            }
            if (val === "Back to Home") {
                window.location.href = "/home/#Dashboard";
            } else {
                window.location.hash = val;
                this.setState({
                    viewClass: val,
                })
            }
        };

        return (

            <>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div className={classes.root}>
                        <CssBaseline />
                        <AppBar
                            position="fixed"
                            className={clsx(classes.appBar, {
                                [classes.appBarShift]: this.state.openDrawer,
                            })}
                        >
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    className={clsx(classes.menuButton, {
                                        [classes.hide]: this.state.openDrawer,
                                    })}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Typography variant="h6" noWrap>
                                    Reports
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <Drawer
                            variant="permanent"
                            className={clsx(classes.drawer, {
                                [classes.drawerOpen]: this.state.openDrawer,
                                [classes.drawerClose]: !this.state.openDrawer,
                            })}
                            classes={{
                                paper: clsx({
                                    [classes.drawerOpen]: this.state.openDrawer,
                                    [classes.drawerClose]: !this.state.openDrawer,
                                }),
                            }}
                        >
                            <div className={classes.toolbar}>
                                <CardMedia
                                    component="img"
                                    alt="Balfour Beatty"
                                    image={process.env.PUBLIC_URL + '/bb.png'}
                                    title="Balfour Beatty"
                                    style={{ width: '170px' }}
                                />
                                <IconButton onClick={handleDrawerClose}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </div>
                            <Divider />
                            <List>
                                {['Back to Home'].map((text, index) => (
                                    <ListItem button key={text} onClick={e => handleViewChange(e, text)}>
                                        <ListItemIcon>{index === 0 ? <ArrowBackIosIcon /> : <AllInboxIcon />}</ListItemIcon>
                                        <ListItemText primary={text} />
                                    </ListItem>
                                ))}
                            </List>
                            <Divider />
                            <List>
                                {this.state.menus[0].map((text, index) => (
                                    <Tooltip title={text} aria-label={text} key={text}>
                                        <StyledListItem button key={text} onClick={e => handleViewChange(e, text)} selected={this.state.viewClass === text} >
                                            <ListItemIcon>
                                                {
                                                    text === "User Summary" ?
                                                        <DataUsageIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                        text === "Timesheet Report" ?
                                                            <ArtTrackIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                            text === "DemandVsCapacity" ?
                                                                <AllInboxIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                                text === "Lookahead Report" ?
                                                                    <ListAltIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                                    text === "D vs C" ?
                                                                        <VerticalSplitIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                                        text === "KPI Report" ?
                                                                            <DetailsIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                                            text === "CM Risk" ?
                                                                                <PublishedWithChangesIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                                                text === "Timesheet Download" ?
                                                                                    <DownloadingIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                                                    text === "HOE Gate6" ?
                                                                                        <AssessmentIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                                                        text === "Members Workload" ?
                                                                                            <AssignmentIndIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                                                            <PersonPinIcon color={this.state.viewClass === text ? "primary" : 'inherit'} />
                                                }
                                            </ListItemIcon>
                                            <ListItemText primary={text} />
                                        </StyledListItem>
                                    </Tooltip>

                                ))}
                            </List>

                            <Divider />

                        </Drawer>
                        <main className={classes.content}>
                            <div className={classes.toolbar} />
                            {
                                (!this.state.isLoading) ? (
                                    (this.state.viewClass === 'Timesheet Report') ? (
                                        <TimesheetReportTab />
                                    ) : (this.state.viewClass === 'DemandVsCapacity') ? (
                                        <DemandVsCapcity />
                                    ) : (this.state.viewClass === 'User Summary') ? (
                                        <UsersTimesheetSummary />
                                    ) : (this.state.viewClass === 'Lookahead Report') ? (
                                        <ResourceReport />
                                    ) : (this.state.viewClass === 'D vs C') ? (
                                        <DvsC2 />
                                    ) : (this.state.viewClass === 'KPI Report') ? (
                                        <KPIReport />
                                    ) : (this.state.viewClass === 'CM Risk') ? (
                                        <CMRisk />
                                    ) : (this.state.viewClass === 'Timesheet Download') ? (
                                        <UserTimesheetReport teamUsers={false} userEnabled={true} allFilter={true} roleChangeHistory={true} />
                                    ) :
                                        (this.state.viewClass === 'HOE Gate6') ? (
                                            <HoeGate6 isHoe={true} />
                                        ) :
                                            (this.state.viewClass === 'Members Workload') ? (
                                                <AllUsersWorkload />
                                            ) : (null)
                                ) : (
                                    null
                                )
                            }
                        </main>

                        <Dialog open={this.state.openDialog} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">User Detail Form</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    You need to fill below fields to continue.
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="firstname"
                                    label="First Name"
                                    type="name"
                                    onChange={e => this.handleTextChange(e)}
                                    fullWidth
                                />
                                <TextField
                                    margin="dense"
                                    id="lastname"
                                    label="Last Name"
                                    type="name"
                                    onChange={e => this.handleTextChange(e)}
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={e => this.handleClose(e)} disabled={this.state.subButton} color="primary">
                                    Continue
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </div>
                )
                }

            </>
        )
    }
}


export default withStyles(useStyles)(withSnackbar(Home));
