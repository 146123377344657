import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CoffeeLoading, LoopCircleLoading } from 'react-loadingg';
import { TextField, Box, Typography, IconButton, Card } from '@material-ui/core';
import ReactGA from 'react-ga';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor, getSecondaryColor, getWbsType } from '../../Helper/CommonThings';
import { BootstrapDialogTitleV4, BootstrapDialogV4 } from '../../Helper/DialogTitleM5';
// import AddWBS from './AddWBS/AddWBS';
import MainStepper from './MainStepper/MainStepper';
import SummaryList from './MainStepper/SummaryList';
import EditIcon from '@material-ui/icons/Edit';
import OverAllSummary from './MainStepper/OverAllSummary';
import FindUserByRole from '../../FindUserByRole/FindUserByRole';
import WbsSummary from './WbsSummary';
import { ToggleButtonGroup } from '@mui/material';
import { ToggleButton } from '@material-ui/lab';
import WBSGantt from './WBSGantt/WBSGanttHome';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { screenSizeCheck } from '../../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
// var access = JSON.parse(getCookie("bb_ts_access"));
var autocompdata = '';
// var edit_copy = null
// var edit_copy_index = null

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -7,
        top: screenSizeCheck() ? 0 : 0,
        backgroundColor: getSecondaryColor()
    },
}));

class WBSTemplate extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            wbsdata: [],
            data2: [],
            data3: [],
            dataT: [],
            openAdd: false,
            openEdit: false,
            openComm: false,
            projName: '',
            projId: '',
            isLoading: true,
            isLoading2: true,
            isLoading3: true,
            isLoading4: true,
            isLoading5: true,
            selectType: 'ohl',
            allTemp: [],
            openTask: false,
            showFilterAlert: false,
            openTemplate: false,
            deliValue: '',
            comments: '',
            load_data: false,
            isLoadingDum: true,
            whichOpened: 0,
            open_wbs: false,
            open_select: false,
            selected_deliverables: [],
            addList: [],
            openCart: false,
            editData: null,
            cardData: null,
            roles: [],
            rates: [],
            clearAll: false,
            openDelete: false,
            wbs: null,
            disabled: true,
            disabled1: true,
            openWbsEdit: false,
            openWbsName: '',
            selectedWbs: null,
            searchUserRoles: false,
            isEstimateEdit: false,
            wbsViewToggle: 'normal',
            openCoffee: false,
        }
        this.addSelectDeliverables = this.addSelectDeliverables.bind(this)
        this.openAdd = this.openAdd.bind(this)
        this.addCart = this.addCart.bind(this)
        this.addMore = this.addMore.bind(this)
        this.editSummary = this.editSummary.bind(this)
        this.selectedWbs = this.selectedWbs.bind(this)
        this.deleteSummary = this.deleteSummary.bind(this)
        this.singleDsPost = this.singleDsPost.bind(this)
        this.lockedStatus = this.lockedStatus.bind(this)
        this.openEdit = this.openEdit.bind(this)
        this.cartExistItems = this.cartExistItems.bind(this)
        this.openWBSEdit = this.openWBSEdit.bind(this)
        this.postCart = this.postCart.bind(this)
        this.child = React.createRef()
        this.summary = React.createRef()
        this.resEdit = React.createRef()
        this.wbsSummaryRef = React.createRef()
        this.wbsGanttRef = React.createRef()
    }

    componentDidMount() {
        this.fetchOps();
        this.getCart();
        this.setState({ comments: this.props.value.comments, open_wbs: this.props.value.open_wbs })
    }

    fetchOps() {
        var pageView = window.location.pathname + "/#TenderAddAndEdit";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }
        Service.get('/bb/api/tender/ohlwbs/get_ds_estimates_api/?ds=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var data123 = res.data;
                data123.sort(sortByProperty("template_pid"));
                if (this.wbsSummaryRef && this.wbsSummaryRef.current) {
                    this.wbsSummaryRef.current.wbsSummary(data123)
                }
                if (this.wbsGanttRef && this.wbsGanttRef.current) {
                    this.wbsGanttRef.current.makeData(data123)
                }
                // console.log(data123)
                this.setState({ wbsdata: data123, allWbsData: data123, isLoading: false, isLoadingDum: false })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        Service.get('/bb/api/tender/rate/?pid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                if (res.data.length === 0) {
                    this.props.enqueueSnackbar('Goto Rates tab to initiate default rates!', {
                        variant: 'warning'
                    });
                } else {

                    this.setState({
                        isLoading2: false,
                        rates: res.data,
                    });
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
        setTimeout(() => {
            this.fetchWBSTemplate()
        }, 500);
    }

    fetchWBSTemplate() {
        var templateurl = '/bb/api/tender/template/ohlwbs/'

        if (this.props.value.audit_status === 11) {
            templateurl = templateurl + "?tempid=87"
        }

        Service.get(templateurl, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var all = [];
                var dataT = [];
                if (this.summary && this.summary.current) {
                    this.summary.current.enableAddMore()
                }
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    element.template_pid = element.id
                    if (element.template_type === 'edc') {
                        if (user.office_loc === 1) {
                            // var curr_discipline = getWbsType()[element.template_type]
                            element.showName = element.name + ' (' + getWbsType()[element.template_type] + ')';
                            all.push(element)
                        }
                    } else {
                        // var curr_discipline = getWbsType()[element.template_type]
                        element.showName = element.name + ' (' + getWbsType()[element.template_type] + ')';
                        all.push(element)
                    }
                    element.type = element.template_type
                    if (element.tasks !== null && element.tasks !== 'null') {
                        for (let j = 0; j < element.tasks.length; j++) {
                            const element1 = element.tasks[j];
                            element1.wbsname = element.name + ' (' + getWbsType()[element.template_type] + ')';
                            // console.log(element.id)
                            element1.wbsid = element.id;
                            dataT.push(element1);
                        }
                    }
                }

                this.setState({ data: all, allTemp: res.data, isLoading: false, isLoadingDum: false, dataT: JSON.parse(JSON.stringify(dataT)), disabled: false })

            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    getCart() {
        Service.get(`/bb/api/wbs/estimate/cart/?ds=${this.props.value.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.length === 0) {
                    this.setState({ openCart: false })
                }
                this.setState({ addList: res.data, isLoading5: false, disabled1: false });
            })
            .catch(error => {
                if (error && (error.status === 404 || (error.response && error.response.status === 404))) {
                    // ignore
                    this.setState({ addList: [], isLoading5: false, disabled1: false });
                } else {
                    errorMessage(this.props, error)
                }
            });
    }

    postCart(wbs, type) {
        wbs.pid = this.props.value.id
        for (let i = 0; i < wbs.tasks.length; i++) {
            const element = wbs.tasks[i];
            element.t_pid = this.props.value.id
            for (let j = 0; j < element.roles.length; j++) {
                const roleElement = element.roles[j];
                roleElement.t_pid = this.props.value.id
                // roleElement.t_role = roleElement.id ? roleElement.id : roleElement.t_role
            }
        }

        if (type === 'copy') {
            var addList = this.state.addList
            if (wbs.name.includes(" - Copy ")) {
                var occurence = 0
                for (let i = 0; i < addList.length; i++) {
                    const element = addList[i];
                    if (element.cart_data.name.includes(wbs.name)) {
                        var cpySplit = element.cart_data.name.split(wbs.name)
                        if (cpySplit.length === 2) {
                            if (cpySplit[1] === '' && occurence === 0) {
                                occurence = 1
                            } else if (cpySplit[1].includes(' - Copy ')) {
                                let name = cpySplit[1].split(' - Copy ')
                                let cpyName = ((name[1] === '') || (name === undefined)) ? 1 : parseInt(name[1])
                                if (occurence <= cpyName) {
                                    occurence = cpyName + 1
                                }
                            }
                        }
                    }
                }
                wbs.name = wbs.name + " - Copy " + (occurence === 1 ? '' : occurence)
            } else {
                var highest = 0
                var highestCopy = 0
                var name = wbs.name.split(' - Copy ')
                var removeCopy = ''
                if (name.length > 2) {
                    removeCopy = name[0]
                    for (let i = 1; i < (name.length - 1); i++) {
                        const element = name[i];
                        removeCopy += ' - Copy ' + element
                    }
                } else {
                    removeCopy = name[0]
                }

                for (let i = 0; i < addList.length; i++) {
                    const element = addList[i];
                    var split = element.cart_data.name.split(' - Copy ')
                    var splitCopy = ''
                    if (split.length > 2) {
                        highest = split[(split.length - 1)] === undefined ? 0 : split[(split.length - 1)] === '' ? 1 : parseInt(split[(split.length - 1)])
                        splitCopy = split[0]
                        for (let i = 1; i < (split.length - 1); i++) {
                            const element = split[i];
                            splitCopy += ' - Copy ' + element
                        }
                    } else {
                        highest = split[1] === undefined ? 0 : split[1] === '' ? 1 : parseInt(split[1])
                        splitCopy = split[0]
                    }
                    if (splitCopy === removeCopy) {
                        if (highestCopy <= highest) {
                            highestCopy = highest + 1
                        }
                    }
                }
                for (let i = 0; i < addList.length; i++) {
                    const element = addList[i];
                    if (wbs.name === element.cart_data.name) {
                        wbs.name = wbs.name + " - Copy " + (highestCopy === 1 ? '' : highestCopy)
                        break
                    }
                }
            }
        }

        Service.post('/bb/api/wbs/estimate/cart/wbs_estimate_cart_creation/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                ds: this.props.value.id,
                wbs_template: wbs.id,
                cart_data: wbs,
                updated_by: user.id,
            }
        })
            .then(res => {
                // console.log(res.data)
                this.setState({ openAdd: false, openCart: true, editData: null, cardData: null, wbs: null, openWbsName: '' }, () => this.fetchWBSTemplate());
                this.getCart()
            })
            .catch(error => {
                if (error && error.status === 406) {
                    this.props.enqueueSnackbar(error.data.message, {
                        variant: 'warning'
                    });
                } else {
                    errorMessage(this.props, error)
                }

            });
    }

    addCart(wbs, comments) {
        if (this.state.isEstimateEdit) {
            return this.putToEstimate(wbs)
        }
        let list = this.state.addList
        if (list.length === 0 || !this.state.editData) {
            this.postCart(wbs, '')
        } else {
            for (let i = 0; i < list.length; i++) {
                const element = list[i];
                if (element.id === this.state.cardData.id) {
                    return this.putCart(wbs, element.id, comments)
                }
            }
        }
    }

    putCart(wbs, id, comments) {
        this.state.cardData.comments.push({
            user_id: user.id,
            user_name: user.last_name + ", " + user.first_name,
            date_created: new Date(),
            cart_item_added: comments
        })
        wbs.pid = this.props.value.id
        for (let i = 0; i < wbs.tasks.length; i++) {
            const element = wbs.tasks[i];
            element.t_pid = this.props.value.id
            for (let j = 0; j < element.roles.length; j++) {
                const roleElement = element.roles[j];
                roleElement.t_pid = this.props.value.id
                // roleElement.t_role = roleElement.id ? roleElement.id : roleElement.t_role
            }
        }
        Service.put('/bb/api/wbs/estimate/cart/' + id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                ds: this.props.value.id,
                wbs_template: wbs.template_pid,
                cart_data: wbs,
                updated_by: user.id,
                comments: this.state.cardData.comments
            }
        })
            .then(res => {
                // console.log(res.data)
                this.props.enqueueSnackbar('Updated successfully!', {
                    variant: 'success'
                });
                this.setState({ openAdd: false, openCart: true, editData: null, cardData: null, wbs: null, openWbsName: '' }, () => this.fetchWBSTemplate());
                this.getCart()
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    lockedStatus(value) {
        Service.put('/bb/api/wbs/estimate/cart/' + value.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                is_locked: !value.is_locked,
                locked_by: user.id,
                updated_by: user.id,
                ds: value.ds,
                wbs_template: value.wbs_template,
                comments: value.comments
            }
        })
            .then(res => {
                this.getCart()
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    deleteSummary(id) {
        this.setState({ openCoffee: true })
        Service.delete('/bb/api/wbs/estimate/cart/ ' + id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.getCart()
                this.props.enqueueSnackbar('Deleted Successfully!', {
                    variant: 'success'
                });
                this.setState({ openCoffee: false })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
                this.setState({ openCoffee: false })
            });
    }

    singleDsPost(id) {
        this.setState({ openCoffee: true })
        Service.post(`/bb/api/tender/ohlwbs/wbs_estimates_api/?cart=${id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('WBS added successfully!', {
                    variant: 'success'
                });
                // console.log(res.data)
                // this.setState({ openAdd: false, openCart: true, editData: null, wbs: null }, () => this.fetchWBSTemplate());
                this.getCart()
                this.fetchOps()
                this.setState({ openCoffee: false })
            })
            .catch(error => {
                if (error && error.status === 406) {
                    this.props.enqueueSnackbar(error.data.message, {
                        variant: 'warning'
                    });
                } else {
                    errorMessage(this.props, error)
                }
                console.log(error)
                this.setState({ openCoffee: false })
            });
    }

    postData() {
        var pageView = window.location.pathname + "/#TenderAddAndEdit";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }

        if (autocompdata === '') {
            this.props.enqueueSnackbar('Fill the field!', {
                variant: 'warning'
            });
        } else {
            var proceddPost = true;
            for (let i = 0; i < this.state.wbsdata.length; i++) {
                const element = this.state.wbsdata[i];
                if (element.template_pid === autocompdata.id) {
                    proceddPost = false;
                }
            }
            if (proceddPost) {
                this.setState({ selected_deliverables: autocompdata, open_select: true, openAdd: false })
                if (this.child && this.child.current) {
                    this.child.current.reRenderAddWBS(autocompdata);
                }

            } else {
                this.props.enqueueSnackbar('WBS already added to project!. Add the task which is not in template.', {
                    variant: 'warning'
                });
            }

        }
    }

    addSelectDeliverables(data) {
        var json = {
            pid: this.props.value.id,
            template_pid: autocompdata.id,
            name: autocompdata.name,
            editable: autocompdata.editable,
            type: autocompdata.template_type,
            created_by: user.email,
        }
        if (autocompdata.editable) {
            json.template_pid = null
        }
        Service.post("/bb/api/tender/ohlwbs/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: json,
        })
            .then(res => {

                var arrjson = data;

                for (let i = 0; i < arrjson.length; i++) {
                    arrjson[i].t_pid = this.props.value.id;
                    arrjson[i].t_ohlwbs = res.data.id;
                    arrjson[i].decs = '';
                    try {
                        arrjson[i].template_roles = JSON.parse(JSON.stringify(arrjson[i].roles));
                    } catch (error) {
                        arrjson[i].template_roles = [];
                    }
                    arrjson[i].roles = [];
                    arrjson[i].created_by = user.email;
                }

                if (json.editable) {
                    this.setState({ openAdd: false, open_select: false, deliValue: '', isLoadingDum: false, addList: [] });
                    this.fetchOps();
                } else {
                    Service.post("/bb/api/tender/wbs/tasks/", {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: arrjson,
                    })
                        .then(res => {

                            autocompdata = '';

                            this.setState({ openAdd: false, open_select: false, deliValue: '', isLoadingDum: false, addList: [] });
                            this.props.enqueueSnackbar('Task added successfully', {
                                variant: 'success'
                            });
                            this.fetchOps();
                        })
                        .catch(error => {
                            console.log(error)
                            errorMessage(this.props, error)
                        });
                }

                // console.log(res.data)
                this.props.enqueueSnackbar('WBS added successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    openWBS() {
        if (autocompdata === "") {
            this.props.enqueueSnackbar('Fill the field!', {
                variant: 'warning'
            });
        } else {
            var data1P = [];
            this.setState({ isLoadingDum: true, openTask: false })
            for (let i = 0; i < this.state.allWbsData.length; i++) {
                const element = this.state.allWbsData[i];
                if (element.id === autocompdata.t_ohlwbs) {
                    data1P.push(element)
                }
            }
            this.setState({ wbsdata: data1P })
            setTimeout(() => {
                // console.log(data1P)
                this.setState({ isLoadingDum: false, showFilterAlert: true });
                autocompdata = "";
            }, 500);
        }
    }

    removeFilter() {
        this.setState({ wbsdata: this.state.allWbsData, showFilterAlert: false, isLoadingDum: true, });
        setTimeout(() => {
            this.setState({ isLoadingDum: false });
        }, 500);
    }

    onTagsChange = (event, values) => {
        // console.log(values)
        autocompdata = values;
    }

    onTemplateFindChange = (event, values) => {
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            // console.log(element.id+ ' '+ values.wbsid)
            if (element.id === values.wbsid) {
                autocompdata = element;
            }
        }
        // console.log(autocompdata)
        this.setState({ deliValue: autocompdata, openAdd: true, openTemplate: false })
    }

    costCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < rowData.tasks.length; i++) {
            const element = rowData.tasks[i];
            for (let j = 0; j < element.roles.length; j++) {
                const taskElement = element.roles[j];
                cost = cost + this.costCalc2(taskElement)
            }
        }
        return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
    }

    costCalc2(wbsdata) {
        if (wbsdata.country === 'in' || wbsdata.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (wbsdata.grade.toUpperCase() === element.grade) {
                    // console.log(wbsdata.no_off + "  " + wbsdata.hours + " " + element.costin)
                    return wbsdata.hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (wbsdata.grade.toUpperCase() === element.grade) {
                    return wbsdata.hours * element.costuk;
                }
            }
        }
        return 0;
    }

    hourCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < rowData.tasks.length; i++) {
            const element = rowData.tasks[i];
            for (let j = 0; j < element.roles.length; j++) {
                const taskElement = element.roles[j];
                cost = cost + parseFloat(taskElement.hours);
            }
        }
        return cost.toFixed(2);
    }

    dsCostCalc() {
        var cost = 0;
        for (let k = 0; k < this.state.wbsdata.length; k++) {
            const rowData = this.state.wbsdata[k];
            for (let i = 0; i < rowData.tasks.length; i++) {
                const element = rowData.tasks[i];
                for (let j = 0; j < element.roles.length; j++) {
                    const taskElement = element.roles[j];
                    cost = cost + this.costCalc2(taskElement)
                }
            }
        }
        return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
    }

    dsHourCalc() {
        var cost = 0;
        for (let k = 0; k < this.state.wbsdata.length; k++) {
            const rowData = this.state.wbsdata[k];
            for (let i = 0; i < rowData.tasks.length; i++) {
                const element = rowData.tasks[i];
                for (let j = 0; j < element.roles.length; j++) {
                    const taskElement = element.roles[j];
                    cost = cost + parseFloat(taskElement.hours);
                }
            }
        }

        return cost.toFixed(2);
    }

    loadComm() {
        this.setState({ load_data: true })
        // var data = this.props.value;
        // data.comments  = this.state.comments;
        Service.get(`/bb/api/tender/project/` + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.setState({ comments: res.data.comments, openComm: true, load_data: false, })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    updateComm() {
        this.setState({ load_data: true })
        // var data = this.props.value;
        // data.comments  = this.state.comments;
        Service.put(`/bb/api/tender/project/` + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                name: this.props.value.name,
                comments: this.state.comments
            },
        })
            .then(res => {
                this.setState({ comments: res.data.comments, openComm: false, load_data: false })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    activateOpenWbs() {
        this.setState({ load_data: true })
        // var data = this.props.value;
        // data.comments  = this.state.comments;
        Service.put(`/bb/api/tender/project/` + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                name: this.props.value.name,
                open_wbs: !this.state.open_wbs
            },
        })
            .then(res => {
                this.setState({ open_wbs: res.data.open_wbs, load_data: false })
                this.fetchOps()
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    putData(data) {
        Service.put("/bb/api/tender/ohlwbs/" + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        })
            .then(res => {
                this.props.enqueueSnackbar('Data saved seccessfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    openAdd() {
        this.getCart()
        if (this.state.addList.length !== 0) {
            this.setState({ openCart: true })
        } else {
            this.setState({ openAdd: true })
        }
    }

    addMore() {
        this.setState({ openAdd: true, editData: null, cardData: null, wbs: null })
    }

    cartExistItems(cartExistItems) {
        this.setState({ openAdd: false }, () => {
            setTimeout(() => {
                for (let i = 0; i < this.state.addList.length; i++) {
                    const element = this.state.addList[i];
                    if (element.id === cartExistItems.id) {
                        this.editSummary(element)
                        break
                    }
                }
            }, 500);
        })

    }

    editSummary(value) {
        this.setState({ editData: value.cart_data, cardData: value, openAdd: true })
    }

    clearCart() {
        if (localStorage.getItem('wbs_cart_' + this.props.value.id) && (this.state.addList.length > 0)) {
            localStorage.removeItem('wbs_cart_' + this.props.value.id)
            this.setState({ addList: [], clearAll: false, openCart: false, editData: null, cardData: null, })
            this.props.enqueueSnackbar('Data cleared successfully!', {
                variant: 'success'
            });
        }
    }

    selectedWbs(wbs) {
        this.setState({ wbs })
    }

    openEdit(value) {
        this.setState({ openWbsEdit: true, selectedWbs: value })
    }

    editWbs() {
        let cartList = this.state.addList
        let wbsList = this.state.wbsdata
        if (!this.state.openWbsName) {
            this.props.enqueueSnackbar('Fill the WBS Name!', {
                variant: 'warning'
            });
            this.setState({ openWbsName: this.state.editData !== null ? this.state.editData.name : this.state.wbs !== null ? this.state.wbs.name : '' })
        } else if (this.state.wbs !== null || this.state.editData !== null || this.state.selectedWbs !== null) {
            var proceed = true
            var proceed1 = true
            for (let i = 0; i < cartList.length; i++) {
                const element = cartList[i];
                if (element.cart_data.name.toLowerCase().trim() === this.state.openWbsName.toLowerCase().trim()) {
                    proceed = false
                    break
                }
            }
            for (let i = 0; i < wbsList.length; i++) {
                const element1 = wbsList[i];
                if (element1.name.toLowerCase().trim() === this.state.openWbsName.toLowerCase().trim()) {
                    proceed1 = false
                    break
                }
            }
            if (proceed && proceed1) {
                if (this.state.wbs !== null) {
                    let wbs = this.state.wbs
                    wbs['name'] = this.state.openWbsName
                    this.setState({ openWbsEdit: false, openWbsName: '', wbs })
                } else if (this.state.editData !== null) {
                    let editData = this.state.editData
                    editData['name'] = this.state.openWbsName
                    this.setState({ openWbsEdit: false, openWbsName: '', editData })
                } else if (this.state.selectedWbs !== null) {
                    let selectedWbs = this.state.selectedWbs
                    selectedWbs['name'] = this.state.openWbsName.trim()
                    this.setState({ openWbsEdit: false, openWbsName: '', selectedWbs }, () => {
                        if (this.resEdit && this.resEdit.current) {
                            this.resEdit.current.respoEdit(this.state.selectedWbs)
                            this.setState({ selectedWbs: null })
                        }
                    })
                }
            } else {
                this.props.enqueueSnackbar('WBS Name already Present.Please Change!', {
                    variant: 'warning'
                });
            }
        }
    }

    openWBSEdit(wbsID) {
        for (let i = 0; i < this.state.wbsdata.length; i++) {
            const element = this.state.wbsdata[i];
            if (element.id === wbsID) {
                return this.wbsEdit(element)
            }
        }
    }

    wbsEdit(value) {
        this.setState({
            openAdd: true,
            editData: JSON.parse(JSON.stringify(value)),
            isEstimateEdit: true
        })
    }

    putToEstimate(wbs) {

        wbs.pid = this.props.value.id
        for (let i = 0; i < wbs.tasks.length; i++) {
            const element = wbs.tasks[i];
            element.t_pid = this.props.value.id
            for (let j = 0; j < element.roles.length; j++) {
                const roleElement = element.roles[j];
                roleElement.t_pid = this.props.value.id
                // roleElement.t_role = roleElement.id ? roleElement.id : roleElement.t_role
            }
        }

        Service.post('/bb/api/tender/ohlwbs/ds_estimates_api/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: wbs
        })
            .then(res => {
                this.setState({ openAdd: false, isEstimateEdit: false, editData: null, cardData: null, wbs: null, openWbsName: '' }, () => this.fetchWBSTemplate());
                this.fetchOps()
                this.props.enqueueSnackbar('WBS updated successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                if (error && error.status === 406) {
                    this.props.enqueueSnackbar(error.data.message, {
                        variant: 'warning'
                    });
                } else {
                    errorMessage(this.props, error)
                }

            });
    }

    render() {

        const handleTextChange = (e) => {
            this.setState({ openWbsName: e.target.value })
        }

        const handleClose = (e) => {
            this.fetchWBSTemplate()
            this.getCart()
            autocompdata = "";
            this.setState({ openAdd: false, deliValue: '', isLoadingDum: false, editData: null, cardData: null, wbs: null, isEstimateEdit: false })
        };

        const handleClearClose = (e) => {
            this.setState({ clearAll: false })
        };

        const handleCommClose = (e) => {
            autocompdata = "";
            this.setState({ openComm: false, })
        };

        const handleTaskClose = (e) => {
            autocompdata = "";
            this.setState({ openTask: false })
        };

        const handleTemplateClose = (e) => {
            this.setState({ openTemplate: false, openAdd: true, })
        };

        const handleCommChange = (e) => {
            this.setState({ comments: e.target.value, })
        };

        const handleEditClose = (e) => {
            this.setState({ openWbsEdit: false })
        };

        const handleUserRoleClose = () => {
            this.setState({ searchUserRoles: false })
        };

        const handleWbsToggle = (event, newToggle) => {
            if (newToggle) {
                this.setState({
                    wbsViewToggle: newToggle
                })
            }
        };

        return (
            <div>
                {(this.state.isLoading || this.state.isLoading2 || this.state.isLoading5) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <Box display="flex" flexDirection={'row'} alignItems='center' style={{ width: '100%' }} marginBottom={'8px'}>
                            <Box display="flex" flexDirection="row" justifyContent="flex-start" style={{ width: '100%' }}>
                                <Card elevation={4}
                                    style={{ height: '40px', padding: '11px', marginRight: '10px', backgroundColor: getSecondaryColor() }}>
                                    Hours: {this.dsHourCalc()}
                                </Card>
                                <Card elevation={4} style={{ height: '40px', padding: '11px', backgroundColor: getSecondaryColor() }}>
                                    Amount: {this.dsCostCalc()}
                                </Card>
                            </Box>
                            <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
                                <ToggleButtonGroup
                                    style={{ marginRight: '15px' }}
                                    value={this.state.wbsViewToggle}
                                    exclusive
                                    size="small"
                                    onChange={handleWbsToggle}
                                    aria-label="wbs view toggle"
                                >
                                    <ToggleButton value="normal" aria-label="left aligned">
                                        Normal
                                    </ToggleButton>
                                    <ToggleButton value="gantt" aria-label="centered">
                                        Gantt
                                    </ToggleButton>
                                    <ToggleButton value="summary" aria-label="right aligned">
                                        Summary
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                            <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                                {this.state.addList.length > 0 ? <IconButton disabled={this.state.disabled} onClick={e => { this.openAdd() }}> <StyledBadge badgeContent={this.state.addList.length} color="success"><ShoppingCartIcon fontSize='medium' /></StyledBadge></IconButton> : null}
                                <Button disabled={this.state.disabled}
                                    size="small" variant="contained"
                                    color="primary" onClick={e => { this.openAdd() }}
                                    style={{ marginRight: '10px', marginLeft: '10px' }}>Add Deliverables Package</Button>
                                <Button variant="contained" size="small" onClick={() => this.setState({ searchUserRoles: true })} color="primary" style={{ marginRight: '10px' }}>Find User Role</Button>
                                <Button variant="contained" size="small"
                                    color="primary" onClick={e => { this.loadComm(); }} >Comments</Button>
                                {/* <Button variant="contained" color="primary" size="small" onClick={e => { this.setState({ openTask: true }) }} style={{ marginRight: '15px' }}>Search Task</Button> */}
                            </Box>
                        </Box>
                        {(this.state.showFilterAlert) ? (
                            <Alert severity="warning"
                                action={
                                    <Button color="inherit" size="small" onClick={() => { this.removeFilter() }}>
                                        Remove Filter
                                    </Button>
                                }
                            >
                                Filter is applied from Task Search. Please Click "Remove Filter" on right corner to see all data.
                            </Alert>
                        ) : (
                            null
                        )}

                        {this.state.wbsViewToggle === "normal" ?
                            <MaterialTable
                                title="Deliverables Package"
                                columns={[
                                    // { title: 'WBS ID', field: 'template_pid' },
                                    { title: 'WBS', field: 'name', },
                                    {
                                        title: 'Type', field: 'type', editable: 'never',
                                        lookup: { 'ohl': 'OHL', 'cabling': 'Cabling', 'sub_struc': 'Substation Primary', 'ohl_civ_struc': 'Substation Secondary', 'sub_civ_struct': 'Substation Civ Structure', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical', 'edc': 'EDC' },
                                    },
                                    {
                                        title: 'Hours', field: 'hour', editable: 'never', filtering: false,
                                        render: rowData => <Typography>{this.hourCalc(rowData)}</Typography>
                                    },
                                    {
                                        title: 'Amount', field: 'cost', editable: 'never', filtering: false,
                                        render: rowData => <Typography>{this.costCalc(rowData)}</Typography>
                                    },

                                ]}
                                data={this.state.wbsdata}

                                options={{
                                    paging: false,
                                    padding: "dense",
                                    actionsColumnIndex: -1,
                                    filtering: true,
                                    toolbar: false,
                                    headerStyle: { position: "sticky", top: 0 },
                                    maxBodyHeight: Math.round(window.screen.height - 200),
                                }}

                                actions={[
                                    {
                                        icon: 'refresh',
                                        tooltip: 'Refresh',
                                        isFreeAction: true,
                                        disabled: this.state.save,
                                        onClick: () => { this.setState({ isLoadingDum: true }); this.fetchOps(); },
                                    },
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit WBS Estimate',
                                        onClick: (e, rowData) => { this.wbsEdit(rowData) },
                                    },
                                ]}

                                detailPanel={rowData => {
                                    return (
                                        <OverAllSummary value={rowData} rates={this.state.rates} cart={true} showAll={true} />
                                    )
                                }}
                            />
                            : this.state.wbsViewToggle === "gantt" ?
                                <WBSGantt ref={this.wbsGanttRef} value={this.state.wbsdata} openWBSEdit={this.openWBSEdit} />
                                : this.state.wbsViewToggle === "summary" ?
                                    <WbsSummary ref={this.wbsSummaryRef} wbsData={this.state.wbsdata} openWBSEdit={this.openWBSEdit} />
                                    : null}

                        <Dialog open={this.state.clearAll} fullWidth maxWidth='xs' onClose={handleClearClose} aria-labelledby="customized-dialog-title">
                            <DialogContent>
                                <Typography variant='h6'>
                                    Are you sure! Do you want to clear the Cart?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button color='primary' onClick={() => this.setState({ clearAll: false })}>
                                    No
                                </Button>
                                <Button color='primary' onClick={() => this.clearCart()}>
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <BootstrapDialogV4 open={this.state.openAdd} fullWidth maxWidth='xl' aria-labelledby="customized-dialog-title">
                            <BootstrapDialogTitleV4 id="customized-dialog-title" onClose={handleClose}>
                                <Box display='flex' flexDirection={'row'} alignItems='center'>
                                    <Box>
                                        {this.state.editData !== null ? this.state.editData.name + ' (' + getWbsType()[this.state.editData.template_type ? this.state.editData.template_type : this.state.editData.type] + ')' : this.state.wbs !== null ? this.state.wbs.name + ' (' + getWbsType()[this.state.wbs.template_type] + ')' : "Add Deliverables Package"}
                                    </Box>
                                    {
                                        (this.state.editData !== null && this.state.editData.editable) || (this.state.wbs !== null && this.state.wbs.editable) ?
                                            <Box>
                                                <IconButton onClick={() => this.setState({ openWbsEdit: true, openWbsName: this.state.wbs !== null ? this.state.wbs.name : this.state.editData !== null ? this.state.editData.name : '' })}>
                                                    <EditIcon fontSize='small' />
                                                </IconButton>
                                            </Box>
                                            : null
                                    }
                                </Box>
                            </BootstrapDialogTitleV4>
                            <DialogContent>
                                <MainStepper ref={this.resEdit} closeStepper={handleClose} cardData={this.state.cardData} openEdit={this.openEdit} wbs={this.state.data}
                                    pid={this.props.value.id} data={this.state.wbsdata} dataT={this.state.dataT} addCart={this.addCart} addList={this.state.addList}
                                    editData={this.state.editData} selectedWbs={this.selectedWbs} cartExistItems={this.cartExistItems}
                                    isEstimateEdit={this.state.isEstimateEdit}
                                />
                            </DialogContent>
                        </BootstrapDialogV4>

                        <BootstrapDialogV4 open={this.state.openCart} fullWidth maxWidth='xl' aria-labelledby="customized-dialog-title">
                            <BootstrapDialogTitleV4 id="form-dialog-title" onClose={() => this.setState({ openCart: false })}>
                                <Box sx={{ display: "flex" }}>
                                    <Box>WBS Cart {this.state.addList ? ("  (" + this.state.addList.length + " Items)") : null}</Box>
                                </Box>
                            </BootstrapDialogTitleV4>
                            <DialogContent>
                                <SummaryList ref={this.summary} value={this.state.addList} pid={this.props.value.id} addMore={this.addMore} editSummary={this.editSummary} deleteSummary={this.deleteSummary} singleDSPost={this.singleDsPost} lockedStatus={this.lockedStatus} rates={this.state.rates} roles={this.state.roles} data={this.state.wbsdata} tender={this.props.tender} postCart={this.postCart} />
                            </DialogContent>
                        </BootstrapDialogV4>

                        <Dialog open={this.state.openComm} fullWidth onClose={handleCommClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Comments</DialogTitle>
                            <DialogContent>
                                <TextField
                                    id="standard-multiline-static"
                                    label="Comments"
                                    multiline
                                    rows={10}
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.comments}
                                    onChange={handleCommChange}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCommClose} color="primary">
                                    Close
                                </Button>
                                <Button onClick={e => { this.updateComm() }} color="primary">
                                    Save & Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.openTask} fullWidth onClose={handleTaskClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Search Task</DialogTitle>
                            <DialogContent>

                                <Autocomplete
                                    id="combo-box-demo"
                                    options={this.state.data2}
                                    getOptionLabel={(option) => option.name}
                                    onChange={this.onTagsChange}
                                    style={{ marginTop: '15px' }}
                                    renderInput={(params) => <TextField {...params} label="Type Task Name" variant="outlined" />}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleTaskClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={e => { this.openWBS() }} color="primary">
                                    Show WBS (Filter)
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.openTemplate} fullWidth onClose={handleTemplateClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Search WBS from Template</DialogTitle>
                            <DialogContent>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={this.state.dataT}
                                    getOptionLabel={(option) => option.name}
                                    onChange={this.onTemplateFindChange}
                                    style={{ marginTop: '15px' }}
                                    renderInput={(params) => <TextField {...params} label="Type Task Name" variant="outlined" />}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleTemplateClose} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.openWbsEdit} fullWidth aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Edit Open WBS</DialogTitle>
                            <DialogContent>
                                <TextField
                                    margin="dense"
                                    label="Edit Open WBS"
                                    type="name"
                                    onChange={handleTextChange}
                                    fullWidth
                                    variant='outlined'
                                    value={this.state.openWbsName}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleEditClose} color="primary">
                                    Close
                                </Button>
                                <Button onClick={() => this.editWbs()} color="primary">
                                    ok
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.searchUserRoles}
                            fullWidth
                            maxWidth={'sm'}
                            onClose={handleUserRoleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="customized-dialog-title2" >
                                Find Role of a user
                            </DialogTitle>
                            <DialogContent>
                                <FindUserByRole />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleUserRoleClose} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog maxWidth="sm" open={this.state.openCoffee} aria-labelledby="form-dialog-title">
                            <DialogContent>
                                <Box m={5} p={2}>
                                    <CoffeeLoading />
                                </Box>
                            </DialogContent>
                        </Dialog>

                        {(this.state.load_data) ? (<LoopCircleLoading color={getPrimaryColor()} />) : (null)}
                    </div >
                )
                }

                {
                    (this.state.isLoadingDum) ? (
                        <LoopCircleLoading color={getPrimaryColor()} />
                    ) : (null)
                }

            </div >
        )
    }
}

function sortByProperty(property) {
    return function (a, b) {
        if (a[property] > b[property])
            return 1;
        else if (a[property] < b[property])
            return -1;

        return 0;
    }
}

export default withSnackbar(WBSTemplate);
