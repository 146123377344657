import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TenderPDM from './TenderPDM';
import WBSManager from './WBSManager';
import { getCookie } from '../../Helper/CookieHelper';
import ProjectReportManager from './ProjectReportManager';

const access = JSON.parse(getCookie("bb_ts_access"));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function TaskManager(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Project Access" {...a11yProps(0)} />
                    <Tab label="WBS Managers" {...a11yProps(1)} />
                    {
                        (access && access.access && access.access.l4a && access.access.l4a.l4a_aa) ?
                            (<Tab label="Enable Reports" {...a11yProps(2)} />)
                            : (null)
                    }

                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <TenderPDM value={props.value} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <WBSManager value={props.value} />
            </TabPanel>
            {
                (access && access.access && access.access.l4a && access.access.l4a.l4a_aa) ? (
                    <TabPanel value={value} index={2}>
                        <ProjectReportManager value={props.value} />
                    </TabPanel>
                ) : (null)
            }
        </div>
    );
}
