import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import MCReportTabs from './MCReportTabs'
import Service from "../Networking/networkutils";
import { errorMessage } from '../Helper/ErrorMessage';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { LoopCircleLoading } from 'react-loadingg';
import { withSnackbar } from 'notistack';
import { getPrimaryColor } from '../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class MCReport extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: null,
            project: null,
        }
    }


    componentDidMount() {
        Service.get("/bb/api/tender/project/get_tender_data/?pid=" + this.props.match.params.pid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log((access && access.access && access.access.l4a.l4a_x))

                // console.log(res.data.report_manager, res.data.tenderbid_data.pdm, access.access.l4a.l4a_x)
                // console.log(res.data)

                if (res.data.audit_status > 9 && (((res.data.report_manager && res.data.report_manager.length > 0 && res.data.report_manager[0].fields.psr) && ((res.data.tenderbid_data.pdm === user.id && (access && access.access && access.access.l4a.l4a_x)) || (access && access.access && access.access.l4a.l4a_y))) || (access && access.access && access.access.l4a.l4a_z))) {
                    this.setState({
                        project: res.data,
                    })
                    var audit_status = "10"
                    if (res.data.audit_status !== 11) {
                        audit_status = "10,13"
                    }

                    var url = "/bb/api/mcr/timeline/get_report/?pid=" + this.props.match.params.pid + "&audit_status=" + audit_status;

                    Service.get2(url, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res1 => {
                            console.log(res1.data)
                            this.setState({
                                data: res1.data,
                            })
                        })
                        .catch(error => {
                            this.setState({
                                openCoffee: false
                            })
                            errorMessage(this.props, error)
                        });

                } else {
                    alert("Report is unavailable!.")
                    // window.close()
                }

            })
            .catch(error => {
                console.log(error)

                errorMessage(this.props, error)

                // window.close()
            });
    }

    render() {
        return (
            <div>

                {this.state.data ? (
                    <div>
                        <AppBar position="static">
                            <Toolbar>
                                <Typography variant="h6">
                                    Monthly Commercial Report for {this.state.project.tender_name}
                                </Typography>
                                {/* <Button color="inherit">Login</Button> */}
                            </Toolbar>
                        </AppBar>
                        <MCReportTabs value={this.state.data} />
                    </div>
                ) : <LoopCircleLoading color={getPrimaryColor()} />}
            </div>
        )
    }
}

export default withSnackbar(MCReport)