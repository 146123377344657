import React, { Component } from 'react'
import Service from "../../Networking/networkutils";
import { LoopCircleLoading } from 'react-loadingg';
import MaterialTable from 'material-table';
import { Typography } from '@material-ui/core';
import { errorMessage } from '../../Helper/ErrorMessage';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar } from 'notistack';
import { getCookie } from '../../Helper/CookieHelper';
import { getPrimaryColor } from '../../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));


class RoleMapping extends Component {

    constructor(props) {
        super(props)

        this.state = {
            users: [],
            usersAll: [],
            data: [],
            isLoading: true,
            open: false,
            deliValue: {},
            roleUid: '0',
        }
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        Service.get('/bb/api/tender/user/task/?trid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({ data: res.data, isLoading: false })

                Service.get('/bb/api/users/?role_id=' + this.props.value.t_role, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res1 => {

                        var data = [];
                        var role_uid = '0'
                        for (let i = 0; i < res1.data.length; i++) {
                            const element = res1.data[i];
                            var goA = true;
                            role_uid = role_uid + "," + element.id
                            // for (let j = 0; j < res.data.length; j++) {
                            //     const element1 = res.data[j];
                            //     if (element1.user_email === element.email) {
                            //         goA = false;
                            //     }
                            // }
                            if (goA) {
                                data.push(element);
                            }
                        }
                        this.setState({ users: data, usersAll: res1.data, roleUid: role_uid })
                        // console.log(jsondata)
                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });
                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
               errorMessage(this.props, error)
            });
    }

    postData(userdata) {
        if (userdata === '') {
            this.props.enqueueSnackbar('Select a user to continue!', {
                variant: 'warning'
            });
        } else {
            // this.setState({ isLoading: true })
            var postData = {
                task_id: this.props.task.id,
                role_id: this.props.value.t_role,
                task_role_id: this.props.value.id,
                wbs_id: this.props.value.t_wbs,
                t_pid: this.props.value.t_pid,
                user_id: userdata.id,
                user_email: userdata.email,
                created_by: user.email,
            }
            Service.post('/bb/api/tender/user/task/user_task_allocation/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData,
            })
                .then(res => {
                    var data = this.state.data;
                    data.push(res.data);
                    // autocompdata = '';
                    this.setState({ data: data, isLoading: false, open: false })
                    this.props.enqueueSnackbar('Data Added Successfully!', {
                        variant: 'success'
                    });
                    this.fetchOps();
                    // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                    this.setState({ isLoading: false })
                });
        }

    }

    putData(asdata) {
        // this.setState({ isLoading: true })
        Service.put('/bb/api/tender/user/task/' + asdata.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: asdata,
        })
            .then(res => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Edited Successfully!', {
                    variant: 'success'
                });
                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
               errorMessage(this.props, error)
                this.setState({ isLoading: false })
            });
    }

    deleteData(asdata) {

        // this.setState({ isLoading: true })
        Service.delete('/bb/api/tender/user/task/' + asdata.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Deleted Successfully!', {
                    variant: 'success'
                });
                this.fetchOps();
                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
               errorMessage(this.props, error)
                this.setState({ isLoading: false })
            });
    }

    // onTagsChange = (event, values) => {
    //     // console.log(values)
    //     autocompdata = values;
    // }

    handleUserSelect = (e) => {
        for (let i = 0; i < this.state.users.length; i++) {
            const element = this.state.users[i];
            if (element.id === e) {
                this.postData(element);
                break;
            }
        }
    }

    render() {

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                        <div>
                            <MaterialTable
                                title="Manage Users"
                                columns={[
                                    {
                                        title: 'User', field: 'name', editable: 'never',
                                        render: rowData => <Typography>{rowData.user_id_first_name + " " + rowData.user_id_last_name + " (" + rowData.user_email + ")"}</Typography>
                                    },
                                    {
                                        title: 'Actual Hours', field: 'ah', editable: 'never',
                                        render: rowData => <Typography>20</Typography>
                                    },
                                    {
                                        title: 'Allocated/Planned Hours', field: 'af', editable: 'never',
                                        render: rowData => <Typography>40</Typography>
                                    },
                                ]}
                                data={this.state.data}
                                options={{
                                    padding: "dense",
                                    paging: false,
                                    actionsColumnIndex: -1,
                                    filtering: true
                                }}
                                
                            />
                            
                        </div>
                    )}
            </div>
        )
    }
}

export default withSnackbar(RoleMapping);