import React, { Component } from 'react';
import MaterialTable from 'material-table'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';

const token = getCookie("bb_ts_token");

class ReadFeedbackComments extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            avgRating: 0,
            isLoading: true
        }
    }

    componentDidMount() {
        Service.get2("/bb/api/feedback/?category=" + this.props.value.feedback_code, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var totalRating = 0
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    totalRating += element.rating
                    element.full_name = element.uid_data.last_name + ", " + element.uid_data.first_name
                }
                this.setState({
                    data: res.data,
                    avgRating: totalRating / res.data.length,
                    isLoading: false
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    render() {
        return (
            <div>
                <MaterialTable
                    columns={[
                        {
                            title: 'User', field: 'full_name',
                            headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                        },
                        {
                            title: 'Rating', field: 'rating',
                            headerStyle: { minWidth: 100 }, cellStyle: { minWidth: 100 },
                        },
                        {
                            title: 'Feedback', field: 'feedback',
                            headerStyle: { minWidth: 1000 }, cellStyle: { minWidth: 1000 },
                        },
                        {
                            title: 'Date', field: 'date_created', type: 'date',
                            headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                        },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    title={this.props.value.title + " --> (Average: " + this.state.avgRating.toFixed(2) + ")"}
                    options={{
                        exportButton: true,
                        padding: 'dense',
                        paging: false
                    }}
                />
            </div>
        );
    }
}

export default withSnackbar(ReadFeedbackComments);