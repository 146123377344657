import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import { LoopCircleLoading } from 'react-loadingg';
import MaterialTable from 'material-table';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar } from 'notistack';
// import User3W from '../UserRoleMapping/User3W';
import { withStyles } from '@material-ui/core/styles';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import ReactGA from 'react-ga';
import { blue } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getPrimaryColor } from '../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
// var autocompdata = '';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

// const DialogTitle = withStyles(styles)((props) => {
//     const { children, classes, onClose, ...other } = props;
//     return (
//         <MuiDialogTitle disableTypography className={classes.root} {...other}>
//             <Typography variant="h6">{children}</Typography>
//             {onClose ? (
//                 <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//                     <CloseIcon />
//                 </IconButton>
//             ) : null}
//         </MuiDialogTitle>
//     );
// });
var waitData = false;

class RoleMapping extends Component {

    constructor(props) {
        super(props)

        this.state = {
            users: [],
            usersAll: [],
            data: [],
            isLoading: true,
            open: false,
            deliValue: {},
            roleUid: '0',
            openWho: false,
            pdmData: [],
            bid_data: {},
            hourReqButton: true,
            splPdm: false,
            pdmToRecv: null,
            isLoadingTable: false,
            addUserDialog: false,
            comData: [],
            selectedUser: {}
        }
    }

    componentDidMount() {
        this.fetchOps();
        var pdmData = [];
        Service.get('/bb/api/tender/pdm/?pid=' + this.props.task.ds.subproject_pid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res1 => {
                pdmData = res1.data
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        if (this.props.task.ds.subproject_pid) {
            Service.get('/bb/api/tender/project/' + this.props.task.ds.subproject_pid + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res2 => {
                    if (res2.data.from_tenderbid) {
                        Service.get('/bb/api/tender/bid/' + res2.data.from_tenderbid + '/', {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res1 => {
                                // console.log(res1.data)
                                this.setState({
                                    bid_data: res1.data,
                                    pdmData: pdmData
                                }, () => {
                                    this.checkPDM();
                                })
                            })
                            .catch(error => {
                                errorMessage(this.props, error)
                            });
                    } else {
                        this.setState({
                            // bid_data: res1.data,
                            pdmData: pdmData
                        }, () => {
                            this.checkPDM();
                        })
                    }

                })
                .catch(error => {
                    errorMessage(this.props, error)
                });

        } else {
            this.setState({
                // bid_data: res1.data,
                pdmData: pdmData
            }, () => {
                this.checkPDM();
            })
        }
    }

    fetchOps() {

        // console.log(this.props.team)

        var pageView = window.location.pathname + "/#TenderAddAndEdit";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        Service.get('/bb/api/tender/user/task/?tid=' + this.props.value.t_tasks + "&rid=" + this.props.value.t_role, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)

                var comData = [];
                for (let i = 0; i < this.props.team.length; i++) {
                    const element = this.props.team[i];
                    var goon = true;
                    for (let j = 0; j < res.data.length; j++) {
                        const element1 = res.data[j];
                        if (element1.user_id === element.uid) {
                            goon = false;
                        }
                    }
                    if (goon) {
                        comData.push(
                            {
                                type: 100,
                                user_data: element.uid_data
                            }
                        )
                    }
                }

                // console.log(res.data)

                // console.log(res.data, comData)
                // this.setState({ data: [...res.data, ...comData], isLoading: false, isLoadingTable: false, }, () => {
                this.setState({ data: [...res.data], isLoading: false, isLoadingTable: false, comData: comData }, () => {

                    var viewPid = []
                    for (let i1 = 0; i1 < res.data.length; i1++) {
                        const element = res.data[i1];
                        viewPid.push({ 'com_ttid': this.props.task.id, 'com_uid': element.user_id })
                    }
                    if (viewPid.length !== 0) {
                        Service.patch("/bb/api/team/tasks/get_acthours/", {
                            headers: {
                                Authorization: "Token " + token,
                            },
                            data: viewPid,
                        })
                            .then(res12 => {

                                for (let j = 0; j < res12.data.length; j++) {
                                    const element13 = res12.data[j];
                                    for (let i = 0; i < res.data.length; i++) {
                                        const element12 = res.data[i];
                                        if (element12.user_id === element13.id2) {
                                            element12.actual_hours = timeToDecimal(element13.hours_actual)
                                            element12.inprogress_hours = timeToDecimal(element13.hours_ongoing)
                                            element12.total_alloc_hours = element13.allocated
                                            break;
                                        }
                                        // console.log(timeToDecimal(element13.hours_actual) !== 0)
                                    }

                                }
                                // console.log(dataD)
                                // console.log(res.data, comData)
                                this.setState({

                                    // data: [...res.data, ...comData]
                                    data: [...res.data],
                                    comData: comData

                                })
                            })
                            .catch(error => {
                                this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                                    variant: 'error'
                                });

                            });
                    }

                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

    }

    postData(userdata) {
        var pageView = window.location.pathname + "/#TenderAddAndEdit";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        if (userdata === '') {
            this.props.enqueueSnackbar('Select a user to continue!', {
                variant: 'warning'
            });
        } else {
            // this.setState({ isLoading: true })
            var postData = {
                task_id: this.props.task.id,
                role_id: this.props.value.t_role,
                task_role_id: this.props.value.id,
                wbs_id: this.props.value.t_wbs,
                t_pid: this.props.value.t_pid,
                user_id: userdata.id,
                user_email: userdata.email,
                created_by: user.email,
            }
            Service.post('/bb/api/tender/user/task/user_task_allocation/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData,
            })
                .then(res => {
                    waitData = false;
                    var data = this.state.data;
                    data.push(res.data);
                    // autocompdata = '';
                    this.setState({ data: data, isLoading: false, addUserDialog: false })
                    this.props.enqueueSnackbar('Data Added Successfully!', {
                        variant: 'success'
                    });
                    this.fetchOps();
                    // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
                })
                .catch(error => {
                    waitData = false;
                    errorMessage(this.props, error)
                    this.setState({ isLoading: false })
                });
        }

    }

    putData(asdata, alldata) {

        var hourCount = 0;
        for (let i = 0; i < alldata.length; i++) {
            const element = alldata[i];
            if (element.planed_hours) {
                hourCount = hourCount + element.planed_hours;
            }
        }

        if (hourCount <= (this.props.value.all_hours_to_this_role)) {

            var pageView = window.location.pathname + "/#TenderAddAndEdit";
            ReactGA.set({ page: pageView, });
            ReactGA.pageview(pageView);
            // this.setState({ isLoading: true })
            Service.put('/bb/api/tender/user/task/' + asdata.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: asdata,
            })
                .then(res => {
                    this.setState({ isLoading: false })
                    this.props.enqueueSnackbar('Edited Successfully!', {
                        variant: 'success'
                    });
                    this.fetchOps();
                    // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                    this.setState({ isLoading: false })
                });
        } else {
            this.fetchOps();
            this.props.enqueueSnackbar('You are trying to exceed the role hours!. Changes are not saved.', {
                variant: 'warning'
            });
        }

    }

    deleteData(asdata) {

        var pageView = window.location.pathname + "/#TenderAddAndEdit";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        // this.setState({ isLoading: true })
        Service.delete('/bb/api/tender/user/task/' + asdata.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Deleted Successfully!', {
                    variant: 'success'
                });
                this.fetchOps();
                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
                errorMessage(this.props, error)
                this.setState({ isLoading: false })
            });
    }

    // onTagsChange = (event, values) => {
    //     // console.log(values)
    //     autocompdata = values;
    // }

    handleUserSelect(e) {
        this.setState({ isLoadingTable: true });

        var ext = false;
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if (element.user_id === e.id) {
                ext = true;
                break;
            }
        }
        if (!ext && !waitData) {
            waitData = true;
            // this.setState({ openWho: false })
            this.postData(e);
        } else if (ext) {
            this.props.enqueueSnackbar('User already mapped!', {
                variant: 'warning'
            });
        } else if (waitData) {
            this.props.enqueueSnackbar('Please wait!', {
                variant: 'warning'
            });
        }
    }

    checkTeamUser(e) {
        var ext = false;
        // console.log(this.props.team)
        // console.log(e)
        for (let i = 0; i < this.props.team.length; i++) {
            const element = this.props.team[i];
            if (element.uid === e.user_id) {
                ext = true;
                break;
            }
        }
        // console.log(ext)
        if (ext) {
            return true;
        } else {
            // this.props.enqueueSnackbar("User is not member of this team", {
            //     variant: 'warning'
            // });
            return false;
        }
    }

    checkPDM() {

        // console.log(this.state.pdmData)
        // console.log(this.props.value.t_tasks_data.wbs.type)
        if (this.state.pdmData.length === 0) {
            if (this.state.bid_data.pdm) {
                this.setState({
                    splPdm: false,
                    pdmToRecv: this.state.bid_data.pdm_data,
                    hourReqButton: false
                })
            }
        } else {
            var gotit = false
            for (let i = 0; i < this.state.pdmData.length; i++) {
                const element = this.state.pdmData[i];
                if (element.pdm_type === this.props.value.t_tasks_data.wbs.type && (element.allocation_access === 1 || element.allocation_access === 2)) {
                    this.setState({
                        splPdm: true,
                        pdmToRecv: element.uid_data,
                        hourReqButton: false
                    })
                    gotit = true;
                    break;
                }
            }
            if (!gotit) {
                if (this.state.bid_data.pdm) {
                    this.setState({
                        splPdm: false,
                        pdmToRecv: this.state.bid_data.pdm_data,
                        hourReqButton: false
                    })
                }
            }
        }
    }

    addUserDialogClose = () => {
        this.setState({ addUserDialog: false })
    }

    addUserChange = (e, v) => {
        // console.log(v)
        // this.handleUserSelect(v.user_data)
        this.setState({ selectedUser: v.user_data })
    }

    render() {
        // const { classes } = this.props;

        // const handleClose = () => {
        //     this.setState({ open: false })
        // };

        // const handleWhoClose = () => {
        //     this.setState({
        //         openWho: false,

        //     })
        // };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <MaterialTable
                            title='Mapped Users'
                            columns={[
                                {
                                    title: 'User', field: 'name', editable: 'never',
                                    headerStyle: {
                                        width: 450
                                    },
                                    cellStyle: {
                                        width: 450
                                    },
                                    render: rowData => <Typography>{rowData.user_data.last_name + ", " + rowData.user_data.first_name}</Typography>
                                },
                                {
                                    title: 'Roles', field: 'user_data.role_id',
                                    lookup: this.props.rolesLookUp,
                                    cellStyle: {
                                        width: 350
                                    },
                                    headerStyle: {
                                        width: 350
                                    }
                                },
                                {
                                    title: 'Type', field: 'assign_type',
                                    lookup: { 0: 'Assigned', 1: 'Reviewer', 100: 'Not Assigned', }
                                },
                                {
                                    title: 'Work Estimated Start', field: 'start', type: 'date', default: this.props.task.from_date,
                                },
                                {
                                    title: ' Work Estimated End', field: 'end', type: 'date', default: this.props.task.to_date,
                                },
                                {
                                    title: 'Actual and Inprogress Hours/Total Allocated', field: 'ah', editable: 'never',
                                    cellStyle: {
                                        width: 350
                                    },
                                    headerStyle: {
                                        width: 350
                                    },
                                    render: rowData => (rowData.id) ? (rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{(rowData.actual_hours + rowData.inprogress_hours) + "/" + rowData.total_alloc_hours}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />) : ("")
                                },
                                {
                                    title: 'Balance Hours', field: 'ah', editable: 'never',
                                    render: rowData => (rowData.id) ? (rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{rowData.total_alloc_hours - (rowData.actual_hours + rowData.inprogress_hours)}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />) : ("")
                                },
                                {
                                    title: 'Allocated Hours', field: 'planed_hours', type: 'numeric',
                                },
                            ]}
                            data={this.state.data}
                            options={{
                                padding: "dense",
                                pageSize: 10,
                                actionsColumnIndex: -1,
                                // filtering: true
                            }}
                            isLoading={this.state.isLoadingTable}
                            actions={
                                [

                                    // rowData => ({
                                    //     icon: 'add_box',
                                    //     tooltip: (rowData.id) ? ('User Added') : ('Add User'),
                                    //     onClick: (event, rowData) => this.handleUserSelect(rowData.user_data),
                                    //     disabled: rowData.id
                                    // }),

                                    // {
                                    //     icon: 'assignments_late',
                                    //     tooltip: 'Hour Request',
                                    //     isFreeAction: true,
                                    //     disabled: this.state.hourReqButton,
                                    //     onClick: () => { alert("This option has been moved. Please close this, and open on the 'briefcase' icon at the left for the task you are planning to request additional hours. Inside there, go to 'Manage Task Roles' tab, and under the 'Actions' column, you can see the 'Additional Hours Request' option.") },
                                    // },
                                    {
                                        icon: () => <Button variant="outlined" color="primary">Add User</Button>,
                                        // tooltip: 'Add',
                                        isFreeAction: true,
                                        // disabled: this.state.hourReqButton,
                                        onClick: () => { this.setState({ addUserDialog: true }) },
                                    },

                                ]
                            }
                            editable={{
                                isEditable: rowData => (rowData.id && this.checkTeamUser(rowData) && ((rowData.actual_hours || rowData.actual_hours === 0) && (rowData.inprogress_hours || rowData.inprogress_hours === 0))),
                                // isDeletable: rowData => rowData.id,
                                // onRowAdd: newData =>
                                //     new Promise(resolve => {
                                //         setTimeout(() => {
                                //             resolve();
                                //             this.setState({ save: false });
                                //             this.setState(prevState => {
                                //                 const data = [...prevState.data];
                                //                 newData.roles = [];
                                //                 data.push(newData);
                                //                 return { ...prevState, data };
                                //             });
                                //         }, 60);
                                //     }),

                                onRowUpdate: (newData, oldData) =>

                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            if (oldData && this.checkTeamUser(oldData)) {
                                                this.setState({ save: false });
                                                if (newData.planed_hours >= oldData.planed_hours || (oldData.planed_hours - newData.planed_hours) <= (oldData.total_alloc_hours - (oldData.actual_hours + oldData.inprogress_hours))) {
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        delete newData['actual_hours']
                                                        data[data.indexOf(oldData)] = newData;
                                                        this.putData(newData, data);
                                                        return { ...prevState, data };
                                                    });
                                                } else {
                                                    this.props.enqueueSnackbar("You can only reduce " + (oldData.total_alloc_hours - (oldData.actual_hours + oldData.inprogress_hours)) + " hours", {
                                                        variant: 'warning'
                                                    });
                                                }

                                            }
                                        }, 60);
                                        // this.putData(newData);
                                    }),
                                isDeletable: rowData => (rowData.id && this.checkTeamUser(rowData) && (rowData.actual_hours + rowData.inprogress_hours) === 0),
                                onRowDelete: oldData =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            this.setState({ save: false });
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data.splice(data.indexOf(oldData), 1);
                                                this.deleteData(oldData);
                                                return { ...prevState, data };
                                            });
                                        }, 60);

                                    }),
                            }}
                        />

                        {/* <Dialog onClose={handleWhoClose} aria-labelledby="simple-dialog-title" open={this.state.openWho}>
                                <DialogTitle id="simple-dialog-title">To Whom</DialogTitle>

                                <List>
                                    {this.props.team.map((text, index) => (
                                        <ListItem button key={text.id} onClick={() => { this.handleUserSelect(text.uid_data) }} >
                                            <ListItemAvatar>
                                                <Avatar className={classes.avatar}>
                                                    <PersonIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={text.name} />
                                        </ListItem>
                                    ))}
                                </List>
                                
                            </Dialog> */}

                        {/* <Dialog open={this.state.open} maxWidth="xl" onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                                Additional Hour Request
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Additional Hour Request can made from here. This requests will be sent to the EM mentioned below.
                                </DialogContentText>

                                <AdditionalHourRequest value={this.props.value} projectLook={this.props.projectLook} splPdm={this.state.splPdm} pdmToRecv={this.state.pdmToRecv} pdmData={this.state.pdmData} />

                            </DialogContent>

                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog> */}

                        <Dialog
                            open={this.state.addUserDialog}
                            onClose={this.addUserDialogClose}
                            aria-labelledby="form-dialog-title"
                            fullWidth
                            maxWidth={'sm'}
                        >
                            <DialogTitle id="customized-dialog-title">
                                Add User
                            </DialogTitle>
                            <DialogContent>
                                <Autocomplete
                                    fullWidth={true}
                                    id="combo-box-demo"
                                    options={this.state.comData}
                                    getOptionLabel={(option) => option.user_data.last_name + ', ' + option.user_data.first_name + " (" + option.user_data.email + ")"}
                                    onChange={this.addUserChange}
                                    renderInput={(params) => <TextField {...params} label="Type here to search" variant="outlined" />}
                                />

                            </DialogContent>

                            <DialogActions>
                                <Button onClick={this.addUserDialogClose} variant="outlined">
                                    Close
                                </Button>
                                <Button
                                    onClick={() => this.handleUserSelect(this.state.selectedUser)}
                                    color="primary"
                                    variant="outlined"
                                    disabled={!this.state.selectedUser}
                                >
                                    Add
                                </Button>

                            </DialogActions>
                        </Dialog>
                    </div>
                )}
            </div>
        )
    }
}

function addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec ? 1 : null
    if (len) {
        return parseFloat(Number(num).toFixed(len))
    } else {
        return parseFloat(Number(num))
    }
}

function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
}

export default withStyles(styles)(withSnackbar(RoleMapping));