import React, { PureComponent } from 'react'
import MaterialTable from 'material-table'
import { Box, Typography } from '@material-ui/core'
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../../../Helper/CommonThings';
import AddWBSRoles from '../AddWBS/AddWBSRoles';

class OverAllSummary extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selected_deliverables: [],
            rates: [],
            roles: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        // debugger
        for (let i = 0; i < this.props.value.tasks.length; i++) {
            const element = this.props.value.tasks[i];
            delete element?.tableData?.showDetailPanel
        }
        this.setState({ isLoading: false })
    }

    costCalc(rowData) {
        try {
            var cost = 0;
            for (let i = 0; i < rowData.roles.length; i++) {
                const element = rowData.roles[i];
                if ((element.t_tasks === rowData.id || this.props.showAll) && (element.fm_checked || this.props.showAll)) {
                    cost = cost + this.costCalc2(element)
                }
            }
            return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
        } catch (error) {
            return 0;
        }

    }

    costCalc2(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
                const element = this.props.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
                const element = this.props.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costuk;
                }
            }
        }
        return 0;
    }

    hourCalc(rowData) {
        try {
            var cost = 0;
            for (let i = 0; i < rowData.roles.length; i++) {
                const element = rowData.roles[i];
                if ((element.t_tasks === rowData.id || this.props.showAll) && (element.fm_checked || this.props.showAll)) {
                    cost = cost + parseFloat(element.hours);
                }
            }
            return cost.toFixed(2);
        } catch (error) {
            return 0;
        }
    }

    render() {
        return (
            (this.state.isLoading) ? (
                <LoopCircleLoading color={getPrimaryColor()} />
            ) : (
                <div>
                    <MaterialTable
                        title={<Box
                            display="flex"
                            alignItems="flex-end"
                        >
                            <Box fontSize={'18px'} fontWeight='bold'>
                                Tasks
                            </Box>
                            {this.props.cart ?
                                <Box style={{ marginLeft: '10px' }} >
                                    (Click the above edit icon to edit this item)
                                </Box>
                                : null}
                        </Box>
                        }
                        columns={
                            [
                                { title: 'Task', field: 'name', editable: (this.props.value.editable) ? ('always') : ('never'), },
                                { title: 'Description', field: 'decs', },
                                {
                                    title: 'Quantity', field: 'quatity', type: 'numeric',
                                },
                                {
                                    title: 'P6 ID', field: 'p6',
                                },
                                {
                                    title: 'Hours', field: 'hour', editable: 'never',
                                    render: rowData => <Typography>{this.hourCalc(rowData)}</Typography>
                                },
                                {
                                    title: 'Amount', field: 'cost', editable: 'never',
                                    render: rowData => <Typography>{this.costCalc(rowData)}</Typography>
                                },
                                {
                                    title: 'From', field: 'from_date', type: 'date',
                                },
                                {
                                    title: 'To', field: 'to_date', type: 'date',
                                },
                                {
                                    title: 'Remarks', field: 'remark',
                                },
                            ]}
                        data={this.props.value.tasks.filter(el => el.fm_checked || this.props.showAll )}
                        isLoading={this.state.isLoading}
                        options={{
                            paging: false,
                            padding: "dense",
                            actionsColumnIndex: -1,
                            headerStyle: { position: "sticky", top: 0 },
                            maxBodyHeight: Math.round(window.screen.height - 200),
                        }}
                        detailPanel={rowData => {
                            return (
                                <AddWBSRoles value={rowData} rates={this.props.rates} showAll={true} />
                            )
                        }}
                    />
                </div >
            )
        )
    }
}

export default OverAllSummary