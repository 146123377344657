import React, { PureComponent } from 'react'
import MaterialTable from 'material-table'
import { Box, Button, IconButton } from '@material-ui/core'
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../../../Helper/CommonThings';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { withSnackbar } from 'notistack';
import moment from 'moment';

class SetDateToTask extends PureComponent {
    constructor(props) {
        super(props)
        console.log(props)
        this.state = {
            isLoading: true,
            data: props.value.tasks
        }
    }

    componentDidMount() {
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if (element.tableData) {
                delete element.tableData.showDetailPanel
            }
        }
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            for (let j = 0; j < element.roles.length; j++) {
                const roleElement = element.roles[j];
                roleElement.fm_checked = this.props.value.selected_roles.includes(roleElement.t_role)
            }
        }
        this.setState({ isLoading: false })
    }

    costCalc(rowData) {
        try {
            var cost = 0;
            for (let i = 0; i < rowData.roles.length; i++) {
                const element = rowData.roles[i];
                if (element.t_tasks === rowData.id && element.fm_checked) {
                    cost = cost + this.costCalc2(element)
                }
            }
            return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
        } catch (error) {
            return 0;
        }

    }

    costCalc2(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
                const element = this.props.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
                const element = this.props.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costuk;
                }
            }
        }
        return 0;
    }

    hourCalc(rowData) {
        try {
            var cost = 0;
            for (let i = 0; i < rowData.roles.length; i++) {
                const element = rowData.roles[i];
                if (element.t_tasks === rowData.id && element.fm_checked) {
                    cost = cost + parseFloat(element.hours);
                }
            }
            return cost.toFixed(2);
        } catch (error) {
            return 0;
        }
    }

    render() {
        return (
            (this.state.isLoading) ? (
                <LoopCircleLoading color={getPrimaryColor()} />
            ) : (
                <div>
                    <MaterialTable
                        title={<Alert severity="info" > Please fill estimated start and finish date for each task </Alert>}
                        columns={[
                            {
                                title: 'Task', field: 'name',
                                editable: (column, rowData) => { return (this.props.value.editable || !rowData || rowData.fm_tasks_editable) },
                            },
                            { title: 'Description', field: 'decs', },
                            {
                                title: 'Quantity', field: 'quatity', type: 'numeric',
                            },
                            {
                                title: 'P6 ID', field: 'p6',
                                validate: (row) => ({
                                    isValid: (row.hasOwnProperty('p6') && row?.p6.length <= 16) ? true : (!row.hasOwnProperty('p6')) ? true : false,
                                    helperText: (row.hasOwnProperty('p6') && row?.p6.length > 16) ? "length should not be more than 16 characters" : '',
                                }),
                            },
                            // {
                            //     title: 'Hours', field: 'hour', editable: 'never',
                            //     render: rowData => this.hourCalc(rowData)
                            // },
                            // {
                            //     title: 'Amount', field: 'cost', editable: 'never',
                            //     render: rowData => this.costCalc(rowData)
                            // },
                            {
                                title: 'From', field: 'from_date', type: 'date',
                                editComponent: props => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            format="dd/MM/yyyy"
                                            value={props.value || null}
                                            onChange={props.onChange}
                                            clearable
                                            fullWidth
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                )
                            },
                            {
                                title: 'To', field: 'to_date', type: 'date',
                                editComponent: props => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            format="dd/MM/yyyy"
                                            value={props.value || null}
                                            onChange={props.onChange}
                                            clearable
                                            fullWidth
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                )
                            },
                            {
                                title: 'Remarks', field: 'remark',
                            },
                        ]}
                        data={this.state.data.filter(el => el.fm_checked)}
                        options={{
                            paging: false,
                            padding: "dense",
                            actionsColumnIndex: -1,
                            headerStyle: { position: "sticky", top: 0 },
                            maxBodyHeight: Math.round(window.screen.height - 200),
                            toolbarButtonAlignment: "left"
                        }}
                        components={{
                            Toolbar: props => {
                                if (props.actions[1].position === "toolbar" && props.actions[1].tooltip === "Edit All") {
                                    this.props.editSave(props.actions[1].hidden)
                                }
                                return <Box display="flex" flexDirection="row" padding='5px' alignItems='center'>
                                    <Box><Alert severity="info" > Please fill estimated start and finish date for each task </Alert></Box>
                                    {(props.actions[1].position === "toolbar" && props.actions[1].tooltip === "Edit All" && !props.actions[1].hidden) ? (
                                        <Box padding='2px'>
                                            <Button onClick={props.actions[1].onClick} variant='outlined' size='small' padding='2px'>{!props.actions[1].hidden ? "Edit" : ''} </Button>
                                        </Box>
                                    ) :
                                        <Box display='flex' flexDirection={'row'}>
                                            <Box><IconButton onClick={props.actions[2].onClick}><CheckIcon fontSize='small' /></IconButton></Box>
                                            <Box><IconButton onClick={props.actions[3].onClick}><CloseIcon fontSize='small' /></IconButton></Box>
                                        </Box>}
                                    {(props.actions[0].position === "toolbar" && props.actions[0].tooltip === "Add" && !props.actions[1].hidden) ? (
                                        <Box padding='2px'>
                                            <Button onClick={props.actions[0].onClick} variant='outlined' size='small'>{!props.actions[0].hidden ? "Add" : ''}</Button>
                                        </Box>
                                    ) : null}
                                </Box>
                            },
                        }}
                        editable={{
                            onRowAdd: newData =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        if (newData.name) {
                                            if ((moment(newData.to_date).format('YYYY-MM-DD')) >= (moment(newData.from_date).format('YYYY-MM-DD'))) {
                                                this.setState(prevState => {
                                                    newData.fm_checked = true
                                                    newData.roles = []
                                                    newData.quatity = !newData?.quatity ? 1 : newData.quatity
                                                    newData.fm_tasks_editable = true
                                                    prevState.data.push(newData);
                                                }, () => this.forceUpdate());
                                            } else {
                                                this.props.enqueueSnackbar('To dates are not lesser than from dates!', {
                                                    variant: 'warning'
                                                });
                                            }

                                        } else {
                                            this.props.enqueueSnackbar('Name is Mandatory', {
                                                variant: 'warning'
                                            });
                                        }

                                    }, 100);
                                }),
                            onBulkUpdate: changes =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        resolve();
                                        const data = this.state.data;
                                        for (let i = 0; i < data.length; i++) {
                                            if (changes[i.toString()]) {
                                                const element = changes[i.toString()];
                                                if (element.newData.hasOwnProperty('p6') && element.newData.p6.length > 16) {
                                                    this.props.enqueueSnackbar('P6 ID length should not be more than 16 characters', {
                                                        variant: 'warning'
                                                    });
                                                    // break
                                                    return
                                                }
                                                if ((moment(element.newData.to_date).format('YYYY-MM-DD')) >= (moment(element.newData.from_date).format('YYYY-MM-DD'))) {
                                                    if (!element.newData?.quatity) {
                                                        element.newData.quatity = 1;
                                                    }
                                                    data[data.indexOf(element.oldData)] = element.newData;
                                                } else {
                                                    this.props.enqueueSnackbar('To dates are not lesser than from dates!', {
                                                        variant: 'warning'
                                                    });
                                                    break
                                                }
                                            }
                                        }
                                        this.forceUpdate()
                                    }, 100);
                                }),
                        }} />
                </div >
            )
        )
    }
}

export default withSnackbar(SetDateToTask)