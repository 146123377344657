import React from 'react'
import { Grid } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DemandReport from './DemandReport';
import CapacityReport from './CapacityReport';
import DvsCTable from './DvsCTable'
import { getPrimaryColor } from '../../Helper/CommonThings';
import ShowMoreText from "react-show-more-text";
import { getCookie } from '../../Helper/CookieHelper';
import DownloadDialog from './DownloadDialog';
import DownloadIcon from '@material-ui/icons/CloudDownload';


const access = JSON.parse(getCookie("bb_ts_access"));

class DvsCReport extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            filter: "months",
            dvcFilterType: "",
            isLoading: false,
            switchAllRoles: false,
            demandComp: true,
            showRevenue: false,
            capacityComp: false,
            demandRoles: [],
            DvsCcomp: false,
            // groupByRoles: {
            //     admin: [46],
            //     apprenties_trainees: {
            //         roles:[1, 2, 3, 70, 71],
            //         values:{}
            //     },
            //     cable_system_design: [40, 10, 22, 28, 29, 42, 43]
            // },
            groupByRoles: [
                {
                    name: 'Engineering Managers',
                    roles: [13],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'OHL Design',
                    roles: [21, 25, 34, 50],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'CAD Technicians - OHL',
                    roles: [19, 33, 49],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Cable Systems Design',
                    roles: [40, 10, 22, 28, 29, 42, 43],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'CAD Technicians - Cables',
                    roles: [9, 80],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Substations Design - Primary',
                    roles: [77, 76, 27, 32, 37, 54, 63, 68, 64, 67],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Substations Design - P&C/Secondary',
                    roles: [75, 38, 55, 66],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'CAD Technicians - Subs',
                    roles: [23, 65],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Substations Design - Control Systems',
                    roles: [61],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Structures Design',
                    roles: [26, 36, 53, 60],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'CAD Technicians - Structures',
                    roles: [35, 52, 59],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Civils Design',
                    roles: [94, 45],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Civils/Geotech/TW Design',
                    roles: [12, 24, 31, 97, 48],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'CAD Technicians - Civils',
                    roles: [11, 30, 44],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Chief Engineers',
                    roles: [96],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'DDM',
                    roles: [85, 62],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Digital',
                    roles: [6, 69, 72, 5, 73, 93, 51, 56, 41, 47, 7],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Multi Utilities',
                    roles: [90, 84, 87, 83, 95, 81, 86, 89, 82, 88],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Site Based Designers',
                    roles: [57],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Project Controls',
                    roles: [14, 39],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'IT',
                    roles: [78],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Software',
                    roles: [74, 58],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Management',
                    roles: [8, 79, 91, 94, 92, 15, 17, 16, 18, 20],
                    week_data: [],
                    month_data: []
                },
                {
                    name: 'Apprentices/Trainee',
                    roles: [1, 2, 3, 70, 71],
                    week_data: [],
                    month_data: []
                },
            ],
            groupByRolesSwitch: false,
            data: [],
            downloadDialog: false,
        }
        this.revenue = React.createRef()
        this.downloadDialogClose = this.downloadDialogClose.bind(this)
    }

    componentDidMount() {
        this.setState({ data: this.props.data }, () => {
            this.setState({ dvcFilterType: "demand" })
        })
    }

    setFilter = (e, value) => {
        if ((value === 'months' || value === 'weeks') && this.state.dvcFilterType === 'demand') {
            this.setState({ filter: value, demandComp: false, capacityComp: false }, () => {
                this.setState({ demandComp: true })
            })
        }
        if ((value === 'months' || value === 'weeks') && this.state.dvcFilterType === 'capacity') {
            this.setState({ filter: value, demandComp: false, capacityComp: false }, () => {
                this.setState({ capacityComp: true })
            })
        }
        if ((value === 'months' || value === 'weeks') && this.state.dvcFilterType === 'demandVsCapacity') {
            this.setState({ filter: value, demandComp: false, capacityComp: false, DvsCcomp: false }, () => {
                this.setState({ DvsCcomp: true })
            })
        }

    }

    dvcFilter = (e, value) => {
        if (value) this.setState({ dvcFilterType: value }, () => {
            let type = value;
            if (type === 'capacity') {
                this.setState({ demandComp: false, capacityComp: false, DvsCcomp: false }, () => {
                    this.setState({ capacityComp: true })
                })
            }
            if (type === 'demand') {
                this.setState({ demandComp: false, capacityComp: false, DvsCcomp: false }, () => {
                    this.setState({ demandComp: true })
                })
            }
            if (type === 'demandVsCapacity') {
                this.setState({ demandComp: false, capacityComp: false, DvsCcomp: true, demandVsCapacityData: this.props.data })
            }
        })
    }

    allSwitchChange = (e) => {
        if (this.state.groupByRolesSwitch === true) {
            this.setState({ switchAllRoles: true });
            return;
        }
        let value = e.target.checked;
        if (this.state.dvcFilterType === 'demand') {
            this.setState({ switchAllRoles: value, demandComp: false, capacityComp: false, DvsCcomp: false }, () => {
                this.setState({ demandComp: true })
            })
        }
        if (this.state.dvcFilterType === 'capacity') {
            this.setState({ switchAllRoles: value, demandComp: false, capacityComp: false, DvsCcomp: false }, () => {
                this.setState({ capacityComp: true })
            })
        }
        if (this.state.dvcFilterType === 'demandVsCapacity') {
            this.setState({ switchAllRoles: value, demandComp: false, capacityComp: false, DvsCcomp: false }, () => {
                this.setState({ DvsCcomp: true })
            })
        }
    }

    updateDemandRoles = (roles) => {
        this.setState({ demandRoles: roles })
    }

    groupByChange = (e) => {
        let value = e.target.checked;
        this.setState({ groupByRolesSwitch: value });
        if (value === false) {
            this.setState({ data: this.props.data }, () => this.updateComp())
        }

        if (value === true) {
            const data = JSON.parse(JSON.stringify(this.props.data));

            let groupByRole = []




            for (let j = 0; j < this.state.groupByRoles.length; j++) {
                let groupRole = this.state.groupByRoles[j]
                let obj = {
                    name: groupRole.name,
                    month_data: [],
                    week_data: []
                }


                for (let i = 0; i < data.length; i++) {
                    let role = data[i];
                    if (groupRole.roles.includes(role.id)) {

                        if (obj.month_data.length === 0) {
                            for (let k = 0; k < role.month_data.length; k++) {
                                obj.month_data.push(role.month_data[k])
                            }

                        }
                        else {
                            for (let k = 0; k < role.month_data.length; k++) {
                                for (let l = 0; l < obj.month_data.length; l++) {
                                    if (role.month_data[k].id === obj.month_data[l].id) {
                                        obj.month_data[l].demand += role.month_data[k].demand
                                        obj.month_data[l].other_demand += role.month_data[k].other_demand
                                        obj.month_data[l].capacity += role.month_data[k].capacity
                                    }
                                }
                            }

                        }

                        if (obj.week_data.length === 0) {
                            for (let k = 0; k < role.week_data.length; k++) {
                                obj.week_data.push(role.week_data[k])
                            }

                        }
                        else {
                            for (let k = 0; k < role.week_data.length; k++) {
                                for (let l = 0; l < obj.week_data.length; l++) {
                                    if (role.week_data[k].id === obj.week_data[l].id) {
                                        obj.week_data[l].demand += role.week_data[k].demand
                                        obj.week_data[l].other_demand += role.week_data[k].other_demand
                                        obj.week_data[l].capacity += role.week_data[k].capacity
                                    }
                                }
                            }

                        }
                    }

                }
                groupByRole.push(obj)
            }
            this.setState({ data: groupByRole, switchAllRoles: true }, () => {
                this.updateComp()
            })
        }


    }

    showRevenueChange = (e) => {
        this.setState({ showRevenue: e.target.checked }, () => {
            if (this.revenue && this.revenue.current) {
                this.revenue.current.showRevenue()
            }
        });
    }

    updateComp() {
        let type = this.state.dvcFilterType;
        if (type === 'capacity') {
            this.setState({ demandComp: false, capacityComp: false, DvsCcomp: false }, () => {
                this.setState({ capacityComp: true })
            })
        }
        if (type === 'demand') {
            this.setState({ demandComp: false, capacityComp: false, DvsCcomp: false }, () => {
                this.setState({ demandComp: true })
            })
        }
        if (type === 'demandVsCapacity') {
            this.setState({ demandComp: false, capacityComp: false, DvsCcomp: false, demandVsCapacityData: this.props.data },
                () => this.setState({ DvsCcomp: true }))
        }

    }

    downloadDialogOpen() {
        this.setState({ isLoading: true }, () => {
            this.setState({ downloadDialog: true, isLoading: false })
        })
    }

    downloadDialogClose() {
        this.setState({ isLoading: true }, () => {
            this.setState({ downloadDialog: false, isLoading: false })
        })
    }

    render() {
        return (

            <>
                {this.state.isLoading ?
                    <div style={{ width: '100%' }}>
                        <Box display="flex" style={{ position: 'relative', height: '200px' }}>
                            <LoopCircleLoading color={getPrimaryColor()} />
                        </Box>
                    </div>
                    :
                    <div>
                        <AppBar color="default" position='static'>
                            <Toolbar>
                                <Grid container spacing={2}>
                                    <Grid item sm={3}>
                                        <h2
                                            style={{ margin: '10px', fontWeight: 'normal', textTransform: 'capitalize' }}
                                        >{this.state.dvcFilterType + " Report"}</h2>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <ToggleButtonGroup
                                            value={this.state.dvcFilterType}
                                            exclusive
                                            onChange={this.dvcFilter}
                                            aria-label="text alignment"
                                            size="small"
                                        >
                                            <ToggleButton value="demand" aria-label="demand"> Demand </ToggleButton>
                                            <ToggleButton value="capacity" aria-label="capacity">Capacity</ToggleButton>
                                            <ToggleButton value="demandVsCapacity" aria-label="demand vs capacity"> Demand VS Capacity</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <ToggleButtonGroup
                                            value={this.state.filter}
                                            exclusive
                                            onChange={this.setFilter}
                                            aria-label="text alignment"
                                            size="small">
                                            <ToggleButton value="weeks" aria-label="by week"> BY Weeks</ToggleButton>
                                            <ToggleButton value="months" aria-label="by month"> By Months </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.switchAllRoles}
                                                    onChange={this.allSwitchChange}
                                                    name="All Roles"
                                                    color="primary"
                                                />
                                            }
                                            label="All Roles"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.groupByRolesSwitch}
                                                    onChange={this.groupByChange}
                                                    name="Group By"
                                                    color="primary"
                                                />
                                            }
                                            label="Group By"
                                        />
                                        {(this.state.dvcFilterType === "demandVsCapacity") && (access.access && access.access.l15a && access.access.l15a.l15a_j) ?
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.state.showRevenue}
                                                        onChange={this.showRevenueChange}
                                                        name="Show Revenue"
                                                        color="primary"
                                                    />
                                                }
                                                label="Show Revenue"
                                            /> : null}
                                    </Grid>
                                    <Grid item sm={1}>

                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={this.props.closeReport}
                                            aria-label="close"
                                            style={{ float: 'right' }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => this.downloadDialogOpen()}
                                            aria-label="close"
                                            style={{ float: 'right' }}
                                        >
                                            <DownloadIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>

                        <ShowMoreText
                            /* Default options */
                            lines={1}
                            more="Show more"
                            less="Show less"
                            className="content-css"
                            anchorClass="my-anchor-css-class"
                            expanded={false}
                        // truncatedEndingComponent={"... "}
                        >
                            Selected Projects: {this.props.projectNames}

                        </ShowMoreText>

                        <div >
                            {(this.state.dvcFilterType === "demand" && this.state.demandComp) ?
                                <DemandReport
                                    data={this.state.data}
                                    periodType={this.state.filter}
                                    dvcFilter={this.state.dvcFilterType}
                                    allRole={this.state.switchAllRoles}
                                    updateRole={this.updateDemandRoles}
                                    groupByRoles={this.state.groupByRoles}
                                    groupByRolesSwitch={this.state.groupByRolesSwitch}
                                />
                                : null}

                            {(this.state.dvcFilterType === "capacity" && this.state.capacityComp) ?
                                <CapacityReport
                                    data={this.state.data}
                                    periodType={this.state.filter}
                                    dvcFilter={this.state.dvcFilterType}
                                    allRole={this.state.switchAllRoles}
                                    demandRoles={this.state.demandRoles}
                                    groupByRolesSwitch={this.state.groupByRolesSwitch}
                                />
                                : null}

                            {(this.state.dvcFilterType === "demandVsCapacity" && this.state.DvsCcomp) ?
                                <DvsCTable
                                    ref={this.revenue}
                                    data={this.state.data}
                                    timeUnit={this.state.filter}
                                    dvcFilter={this.state.dvcFilterType}
                                    allRole={this.state.switchAllRoles}
                                    groupByRolesSwitch={this.state.groupByRolesSwitch}
                                    showRevenue={this.state.showRevenue}
                                />
                                : null}


                        </div>
                        {this.state.downloadDialog ?
                            <DownloadDialog
                                open={this.state.downloadDialog}
                                downloadDialogClose={this.downloadDialogClose}
                                data={this.state.data}
                                projects={this.props.projects}
                                billable={this.props.billable}
                            />
                            : null}
                    </div>}
            </>
        )
    }
}
export default withSnackbar(DvsCReport)