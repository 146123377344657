import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import Service from "../Networking/networkutils";
import { errorMessage } from '../Helper/ErrorMessage';
import { Avatar, Chip, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import UserTasks from './UserTasks';
import { getPrimaryColor, sortByProperty } from '../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

const useStyles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
});

class MembersWorkload extends Component {

    constructor(props) {
        super(props)

        this.state = {
            members: [],
            selectedUser: [],
            isLoading: true,
            remount: false,
        }
    }


    componentDidMount() {
        Service.get(`/bb/api/user/report/view/members_workload/?uid=` + user.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var mem_list = []
                // console.log(res.data)
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    var goon = true
                    for (let j = 0; j < mem_list.length; j++) {
                        const element1 = mem_list[j];
                        if (element.uid === element1.id) {
                            if (element.team_role === 1 || element.team_role === 2) {
                                element1.is_lead = true
                            }
                            goon = false
                        }
                    }
                    if (goon) {
                        if (element.team_role === 1 || element.team_role === 2) {
                            element.uid_data.is_lead = true
                        }
                        mem_list.push(element.uid_data)
                    }
                }
                mem_list.sort(sortByProperty("first_name"))
                // console.log(task, 'task w/o hours')

                this.setState({
                    members: mem_list,
                    isLoading: false
                })


            })
            .catch(e => {
                errorMessage(this.props, e)
                console.log(e)
            })
    }

    userClick(data) {
        if (!this.state.selectedUser.includes(data.id)) {
            this.setState({ selectedUser: [...this.state.selectedUser, ...[data.id]], remount: false }, () => {
                setTimeout(() => {
                    this.setState({ remount: true })
                }, 400);
            })
        } else {
            let selectedUser = this.state.selectedUser
            selectedUser.splice(this.state.selectedUser.indexOf(data.id), 1)
            this.setState({ selectedUser, remount: false }, () => {
                setTimeout(() => {
                    this.setState({ remount: true })
                }, 400);
            })
        }
    }

    render() {
        const classes = this.props.classes
        return (
            <div>
                {(this.state.isLoading) ? (<LoopCircleLoading color={getPrimaryColor()} />) : (
                    <Paper component="ul" className={classes.root}>
                        {this.state.members.map((ele, i) => {
                            // console.log(allUser[key])
                            return (<li key={ele.id}>
                                <Chip
                                    avatar={<Avatar>{ele.first_name.charAt(0) + '' + ele.last_name.charAt(0)}</Avatar>}
                                    label={ele.first_name}
                                    onClick={() => this.userClick(ele)}
                                    // variant={this.state.selectedUser === ele.id ? "contained" : "outlined"}
                                    color={this.state.selectedUser.includes(ele.id) ? "primary" : "default"}
                                    clickable
                                    className={classes.chip}
                                />

                            </li>

                            )
                        })}
                    </Paper>)
                }
                {(this.state.selectedUser.length > 0 && this.state.remount) ? (<UserTasks uid={this.state.selectedUser.join(',')} multiUser={this.state.selectedUser.length > 1} />) : (<LoopCircleLoading color={getPrimaryColor()} />)}
            </div >
        )
    }
}

export default withStyles(useStyles)(withSnackbar(MembersWorkload))