import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
// import Button from '@material-ui/core/Button';
import { LoopCircleLoading } from 'react-loadingg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getPrimaryColor, poundFormat } from '../../Helper/CommonThings';
// import { Box } from '@material-ui/core';
// import Moment from 'react-moment';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';


const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
class ProjectHistoryUsage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: this.props.usage,
            openAdd: false,
            issueName: '',
            issueComment: '',
            isLoading: false,
            usage: this.props.usage,
        }
    }


    totalCalc(key) {
        var total = 0
        for (let i = 0; i < this.props.value.length; i++) {
            const element = this.props.value[i];
            total += element[key]
        }
        return total
    }

    usageCalc(key) {

        return this.state.data[0][key]
    }

    totalUsageCalc(key) {
        var total = 0
        for (let i = 0; i < this.props.value.length; i++) {
            const element = this.props.value[i];
            total += element[key]
        }
        return total + this.state.data[0][key]
    }

    totalUsageDeltaCalc(key) {

        return this.totalUsageCalc("rev_cost") - this.totalUsageCalc("est_cost")
    }

    putData(putData, oldData) {

        if (putData.id) {
            Service.put('/bb/api/tender/history/usage/' + putData.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: putData
            })
                .then(res => {

                    this.setState({
                        data: [res.data]
                    })

                    this.props.usage[0] = res.data
                   
                    this.props.enqueueSnackbar('Data saved successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    // console.log(error)
                    // this.fetchOps();
                   errorMessage(this.props, error)
                });
        } else {
            putData.created_by = user.id
            putData.tenderid = this.props.tenderid
            Service.post('/bb/api/tender/history/usage/' , {
                headers: {
                    Authorization: "Token " + token,
                },
                data: putData
            })
                .then(res => {
                    // console.log(oldData.is_active && !putData.is_active)

                    this.setState({
                        data: [res.data]
                    })

                    this.props.usage[0] = res.data
    
                    this.props.enqueueSnackbar('Data saved successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    // console.log(error)
                    // this.fetchOps();
                   errorMessage(this.props, error)
                });
        }

        
    }

    render() {

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>

                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Budget Hours</TableCell>
                                        <TableCell>Budget Cost</TableCell>
                                        <TableCell>At Completion Hours</TableCell>
                                        <TableCell>At Completion Cost</TableCell>
                                        <TableCell>Variance Cost</TableCell>
                                        <TableCell>Earned Value</TableCell>
                                        <TableCell>Allocated Hours</TableCell>
                                        <TableCell>Hours to date</TableCell>
                                        <TableCell>Cost to date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableCell>{"Before PaRaM"}</TableCell>
                                    <TableCell>{this.usageCalc("est_hours")}</TableCell>
                                    <TableCell>{poundFormat(this.usageCalc("est_cost"))}</TableCell>
                                    <TableCell>{this.usageCalc("rev_hours")}</TableCell>
                                    <TableCell>{poundFormat(this.usageCalc("rev_cost"))}</TableCell>
                                    <TableCell>{poundFormat(this.usageCalc("rev_cost") - this.usageCalc("est_cost"))}</TableCell>
                                    <TableCell>{poundFormat(this.usageCalc("earned_value"))}</TableCell>
                                    <TableCell>{0}</TableCell>
                                    <TableCell>{this.usageCalc("actual")}</TableCell>
                                    <TableCell>{this.usageCalc("actual_cost")}</TableCell>

                                </TableBody>
                                <TableBody>
                                    <TableCell>{"In PaRaM"}</TableCell>
                                    <TableCell>{this.totalCalc("est_hours")}</TableCell>
                                    <TableCell>{poundFormat(this.totalCalc("est_cost"))}</TableCell>
                                    <TableCell>{this.totalCalc("rev_hours")}</TableCell>
                                    <TableCell>{poundFormat(this.totalCalc("rev_cost"))}</TableCell>
                                    <TableCell>{poundFormat(this.totalCalc("delta_cost"))}</TableCell>
                                    <TableCell>{poundFormat(this.totalCalc("earned_value"))}</TableCell>
                                    <TableCell>{this.totalCalc("allocated")}</TableCell>
                                    <TableCell>{this.totalCalc("actual")}</TableCell>
                                    <TableCell>{this.totalCalc("actual_cost")}</TableCell>

                                </TableBody>
                                <TableBody>
                                    <TableCell>{"Total"}</TableCell>
                                    <TableCell>{this.totalUsageCalc("est_hours")}</TableCell>
                                    <TableCell>{poundFormat(this.totalUsageCalc("est_cost"))}</TableCell>
                                    <TableCell>{this.totalUsageCalc("rev_hours")}</TableCell>
                                    <TableCell>{poundFormat(this.totalUsageCalc("rev_cost"))}</TableCell>
                                    <TableCell>{poundFormat(this.totalUsageDeltaCalc("delta_cost"))}</TableCell>
                                    <TableCell>{poundFormat(this.totalUsageCalc("earned_value"))}</TableCell>
                                    <TableCell>{this.totalCalc("allocated")}</TableCell>
                                    <TableCell>{this.totalUsageCalc("actual")}</TableCell>
                                    <TableCell>{this.totalUsageCalc("actual_cost")}</TableCell>

                                </TableBody>
                            </Table>
                        </TableContainer>

                        <MaterialTable
                            title="Report Modifier"
                            columns={[
                                { title: 'Started on PaRaM', field: 'date_into_param', type: "date" },
                                { title: 'Budget Hours', field: 'est_hours', type: "numeric" },
                                { title: 'Budget Cost', field: 'est_cost', type: "numeric" },
                                { title: 'Hours to date', field: 'actual', type: "numeric" },
                                { title: 'Cost to date', field: 'actual_cost', type: "numeric" },
                                { title: 'At Completion Hours', field: 'rev_hours', type: "numeric" },
                                { title: 'At Completion Cost', field: 'rev_cost', type: "numeric" },
                                { title: 'Earned Value', field: 'earned_value', type: "numeric" },
                                

                            ]}
                            data={this.state.data}

                            options={{
                                padding: "dense",
                                paging: false,
                                actionsColumnIndex: -1,
                                pageSize: 1,
                                pageSizeOptions: [1],
                            }}

                            editable={{
                                // onRowAdd: newData =>
                                //     new Promise(resolve => {
                                //         setTimeout(() => {
                                //             resolve();
                                //             if (!newData.pdm_type || !newData.uid_mod || newData.uid_mod === '' || newData.uid_mod === undefined) {
                                //                 this.props.enqueueSnackbar('Fill EM type and EM!', {
                                //                     variant: 'warning'
                                //                 });
                                //             } else {
                                //                 this.setState({ save: false });
                                //                 this.setState(prevState => {
                                //                     const data = [...prevState.data];
                                //                     newData.roles = [];
                                //                     data.push(newData);
                                //                     this.postData(newData);
                                //                     return { ...prevState, data };
                                //                 });
                                //             }

                                //         }, 60);
                                //     }),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                this.putData(newData, oldData);
                                                return { ...prevState, data };
                                            }, () => {
                                                this.props.usage[0] = newData
                                            });
                                        }, 600);

                                    }),
                                // onRowDelete: oldData =>
                                //     new Promise(resolve => {
                                //         setTimeout(() => {
                                //             resolve();
                                //             this.setState({ save: false });
                                //             this.setState(prevState => {
                                //                 const data = [...prevState.data];
                                //                 data.splice(data.indexOf(oldData), 1);
                                //                 this.deleteData(oldData)
                                //                 return { ...prevState, data };
                                //             });
                                //         }, 60);
                                //     }),
                            }}
                        />

                    </div>
                )}

            </div>
        )
    }
}

export default withSnackbar(ProjectHistoryUsage);