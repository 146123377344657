import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import { Box, Typography } from '@material-ui/core';
import Summary2 from './Summary2';
import { getCookie } from '../../Helper/CookieHelper';
import { json2excel } from 'js2excel';
import ReactGA from 'react-ga';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));
// var autocompdata = '';
class Summary extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            data1: [],
            data2: [],
            dataT: [],
            rates: [],
            openAdd: false,
            openEdit: false,
            projName: '',
            projId: '',
            isLoading: true,
            isLoading2: true,
            isLoading4: true,
            selectType: 'ohl',
            allTemp: [],
            allTasks: [],
            dataWbs: [],
            wbstype: { 'ohl': 'OHL', 'cabling': 'Cabling', 'sub_struc': 'Substation Primary', 'ohl_civ_struc': 'Substation Secondary', 'sub_civ_struct': 'Substation Civ Structure', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical' },
        }
    }


    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        var pageView = window.location.pathname + "/#TenderAddAndEdit";
    ReactGA.set({ page: pageView, });
    ReactGA.pageview(pageView);
        Service.get('/bb/api/tender/ohlwbs/?pid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({ data1: JSON.parse(JSON.stringify(res.data)), dataWbs: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
               errorMessage(this.props, error)
            });

        Service.get('/bb/api/tender/tasks/roles/?pid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({ data2: JSON.parse(JSON.stringify(res.data)), isLoading4: false, });
            })
            .catch(error => {
               errorMessage(this.props, error)
            });

        Service.get('/bb/api/tender/rate/?pid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                if (res.data.length === 0) {
                    this.props.enqueueSnackbar('Goto Rates tab to initiate default rates!', {
                        variant: 'warning'
                    });
                } else {

                    this.setState({
                        isLoading2: false,
                        rates: res.data,
                    });
                }
            })
            .catch(error => {
               errorMessage(this.props, error)
            });

        Service.get('/bb/api/tender/wbs/tasks/?pid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({ allTasks: JSON.parse(JSON.stringify(res.data)), })
            })
            .catch(error => {
               errorMessage(this.props, error)
            });
    }
    downloadXl() {
        let down = [];
        var dqw = JSON.parse(JSON.stringify(this.state.dataWbs));

        for (let i = 0; i < dqw.length; i++) {
            let down2 = {};
            const element = dqw[i];

            if (parseFloat(this.hourCalc(element)) !== 0) {
                down2["WBS ID"] = element.id
                down2["WBS"] = element.name
                down2["WBS Type"] = this.state.wbstype[element.type]
                down2["WBS Hours"] = this.hourCalc(element)
                down2["WBS Amount"] = this.costCalc1(element)

                for (let j = 0; j < this.state.allTasks.length; j++) {
                    let down3 = JSON.parse(JSON.stringify(down2))
                    const element1 = this.state.allTasks[j];
                    if (element.id === element1.t_ohlwbs  && parseFloat(this.taskhourCalc(element1)) !== 0) {
                        down3["Task ID"] = element1.id
                        down3["Task"] = element1.name
                        down3["Task Description"] = element1.decs
                        down3["Task Hours"] = this.taskhourCalc(element1)
                        down3["Task Amount"] = this.taskcostCalc(element1)
                        down3["Task From"] = element1.from_date
                        down3["Task TO"] = element1.to_date
                        down3["Task Remark"] = element1.remark


                        for (let k = 0; k < this.state.data2.length; k++) {
                            let down4 = JSON.parse(JSON.stringify(down3))
                            const element2 = this.state.data2[k];

                            if (element1.id === element2.t_tasks && (element2.hours) !== 0) {
                                down4["Role"] = element2.name
                                down4["Role Grade"] = element2.grade.toUpperCase()
                                down4["Role Hours"] = element2.hours
                                down4["Role Country"] = element2.country.toUpperCase()
                                down4["Role Amount"] = this.rolescostCalc(element2)
                                down4["Role Remark"] = element2.remark

                                down.push(down4)
                            }

                        }

                    }

                }
            }

        }
        // console.log(JSON.stringify(down))
        // console.log(down)
        try {
            // console.log(JSON.stringify(down))
            json2excel({
                data: down,
                name: this.props.value.name.substring(0, 10),
                formateDate: 'dd/mm/yyyy'
            });
        } catch (e) {
            console.error('export error');
        }
    }

    costCalc1(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_wbs === rowData.id) {
                cost = cost + this.costCalc2(element)
            }
        }
        return (cost);
    }

    costCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_wbs === rowData.id) {
                cost = cost + this.costCalc2(element)
            }
        }
        return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
    }

    costCalc2(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return data1.hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costuk;
                }
            }
        }
        return 0;
    }

    hourCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_wbs === rowData.id) {
                cost = cost + parseFloat(element.hours);
            }
        }
        return cost.toFixed(2);
    }

    taskcostCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_tasks === rowData.id) {
                cost = cost + this.taskcostCalc2(element)
            }
        }
        return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
    }

    taskcostCalc2(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return data1.hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costuk;
                }
            }
        }
        return 0;
    }

    taskhourCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_tasks === rowData.id) {
                cost = cost + parseFloat(element.hours);
            }
        }
        return cost.toFixed(2);
    }

    rolescostCalc(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.hours * element.costin);
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.hours * element.costuk);
                }
            }
        }
        return 0;
    }



    render() {

        return (
            <div>
                {(this.state.isLoading || this.state.isLoading2 || this.state.isLoading4) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                        <div>
                            <MaterialTable
                                title="Deliverables Package"
                                columns={[
                                    { title: 'WBS ID', field: 'id', filtering: false },
                                    { title: 'WBS', field: 'name' },
                                    {
                                        title: 'Type', field: 'type',
                                        lookup: { 'ohl': 'OHL', 'cabling': 'Cabling', 'sub_struc': 'Substation Primary', 'ohl_civ_struc': 'Substation Secondary', 'sub_civ_struct': 'Substation Civ Structure', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical', 'edc': 'EDC' },
                                    },
                                    {
                                        title: 'Hours', field: 'hour',
                                        render: rowData => <Typography>{this.hourCalc(rowData)}</Typography>
                                    },
                                    {
                                        title: 'Amount', field: 'cost',
                                        render: rowData => <Typography>{this.costCalc(rowData)}</Typography>
                                    },

                                ]}
                                data={this.state.data1}
                                options={{
                                    padding: "dense",
                                    paging: false,
                                    actionsColumnIndex: -1,
                                    filtering: true,
                                    exportButton: { csv: true },
                                    // exportPdf: () => alert('This feature is disabled. Data can only be exported as a CSV.'),
                                    exportCsv: (columns, data) => {
                                        this.downloadXl();
                                    }
                                }}
                                detailPanel={rowData => {
                                    return (
                                        <Box p={3}><Summary2 value={rowData} roles={this.state.data2} /></Box>
                                    )
                                }}

                            />

                        </div>
                    )}

            </div>
        )
    }
}

export default withSnackbar(Summary);