import React, { Component } from 'react'
import { setCookie } from '../Helper/CookieHelper';

// const user = JSON.parse(getCookie("bb_ts_user"));
// var access = JSON.parse(getCookie("bb_ts_access"));

export default class ExtDomainSetCookie extends Component {

    constructor(props) {
        super(props)

        this.state = {
            auth: true
        }
    }

    componentDidMount() {
        // console.log(window.location.host, access.access.l111a.l111a_d)
        const query = new URLSearchParams(this.props.location.search);
        // alert((moment().add(3, "seconds").format()))
        
        if (query.get('url').includes(".bbparam.com") || query.get('url').includes(".bbedcindia.com") || query.get('url').includes(".bbedc.dev")) {
            setCookie(query.get('key'), query.get('value'), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
            window.close()
        } else {
            alert("Domain Not Authorized")
            this.setState({ auth: false })
        }
    }

    render() {
        return (
            <div style={{ padding: '15px' }}>
                {(this.state.auth) ? (<h3>Updating...</h3>) : (<h3>Updation Failed. Please contact PaRaM Support to report this. Thank you!</h3>)}
            </div>
        )
    }
}
