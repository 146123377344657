import React, { Component } from 'react'
import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import Service from "../../../Networking/networkutils";
import { Typography } from '@material-ui/core';
import { getCookie } from '../../../Helper/CookieHelper';
import ApprovalRoles from './ApprovalRoles';
import ReactGA from 'react-ga';
import { errorMessage } from '../../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../../Helper/CommonThings';

const token = getCookie("bb_ts_token");
class Summary2 extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            openAdd: false,
            projName: '',
            projId: '',
            isLoading: true,
            isLoading2: true,
            isLoading3: true,
            save: false,
            roles: [],
            rates: [],
        }
    }

    componentDidMount() {
        var pageView = window.location.pathname;
    ReactGA.set({ page: pageView, });
    ReactGA.pageview(pageView);
        this.fetchOps();
    }

    fetchOps() {
        Service.get('/bb/api/tender/wbs/tasks/?ohl=' + this.props.value.id + '&pid=' + this.props.value.pid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.dataArrange(res.data);
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        Service.get('/bb/api/tender/rate/?pid=' + this.props.value.pid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                if (res.data.length === 0) {

                } else {

                    this.setState({
                        isLoading2: false,
                        rates: res.data,
                    });
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    dataArrange(data) {
        var data32 = [];
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (this.hourCalc(element) !== "0.00" ) {
                data32.push(element)
            }
        }
        this.setState({ data: JSON.parse(JSON.stringify(data32)), isLoading: false });
    }

    costCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.props.roles.length; i++) {
            const element = this.props.roles[i];
            if (element.t_tasks === rowData.id) {
                cost = cost + this.costCalc2(element)
            }
        }
        return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
    }

    costCalc2(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return data1.hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costuk;
                }
            }
        }
        return 0;
    }

    hourCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.props.roles.length; i++) {
            const element = this.props.roles[i];
            if (element.t_tasks === rowData.id) {
                cost = cost + parseFloat(element.hours);
            }
        }
        return cost.toFixed(2);
    }

    numOff(rowData) {
        try {
            var cost = 0;
            for (let i = 0; i < this.props.roles.length; i++) {
                const element = this.props.roles[i];
                if (element.t_tasks === rowData.id) {
                    ++cost;
                }
            }
            return cost;
        } catch (error) {
            return 0;
        }
    }

    render() {


        return (
            <div>
                {(this.state.isLoading || this.state.isLoading2) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                        <div>
                            {/* <Box display="flex" flexDirection="row-reverse" p={1} m={1} bgcolor="background.paper">
                                <Button variant="contained" color="secondary" disabled={this.state.save} onClick={e => { this.giveaccess(this.state.data) }} style={{ marginBottom: '10px' }}>Save</Button>
                            </Box> */}
                            <MaterialTable
                                title="Tasks"
                                columns={[
                                    { title: 'Task', field: 'name', },
                                    { title: 'Description', field: 'decs', },
                                    // {
                                    //     title: 'Status', filed: 'dummy',
                                    //     render: rowData => <Typography>In Progress</Typography>
                                    // },
                                    // {
                                    //     title: 'Assigned', filed: 'dummy',
                                    //     render: rowData => <Typography>Test user 1, Test user 2</Typography>
                                    // },
                                    // {
                                    //     title: 'Reviewer', filed: 'dummy',
                                    //     render: rowData => <Typography>Test user 3</Typography>
                                    // },
                                    {
                                        title: 'Budgeted Hours', field: 'hour',
                                        render: rowData => <Typography>{this.hourCalc(rowData)}</Typography>
                                    },
                                    {
                                        title: 'Budgeted Amount', field: 'amount',
                                        render: rowData => <Typography>{this.costCalc(rowData)}</Typography>
                                    },
                                    {
                                        title: 'From', field: 'from_date', type: 'date',
                                    },
                                    {
                                        title: 'To', field: 'to_date', type: 'date',
                                    },
                                    {
                                        title: 'Remarks', field: 'remark',
                                    },
                                ]}
                                data={this.state.data}
                                options={{
                                    actionsColumnIndex: -1,
                                    padding: "dense",
                                }}
                               
                                detailPanel={rowData => {
                                    return (
                                        <ApprovalRoles value={rowData} rates={this.state.rates} roles={this.props.roles} />
                                    )
                                }}
                                
                            />
                            
                        </div>
                    )}

            </div>
        )
    }
}

export default withSnackbar(Summary2);