import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import RoleGroup from './RoleGroup';
import RolesTemplate from '../Template/RolesTemplate';

class RolesTemplateMain extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: 'roles_template'
        }
    }

    render() {
        const handleChange = (event, newValue) => {
            this.setState({ value: newValue })
        };
        return (
            <div>
                <TabContext value={this.state.value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab value={'roles_template'} label="Roles Template" />
                            <Tab value={'roles_group'} label="Roles Group" />
                        </TabList>
                    </Box>
                    <TabPanel value={'roles_group'}><RoleGroup /></TabPanel>
                    <TabPanel value={'roles_template'}><RolesTemplate /></TabPanel>
                </TabContext>
            </div>
        )
    }
}

export default RolesTemplateMain