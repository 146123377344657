import React, { Component } from 'react'
import { Box, Card, } from '@material-ui/core';
import { getCardWidth, } from '../Helper/KanbanHelper';
import AddIcon from '@material-ui/icons/Add';
import { CardActionArea } from '@mui/material';
import { withSnackbar } from 'notistack';
class AddCardLink extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    render() {
        return (
            <div >
                <Card style={{ margin: '5px', width: getCardWidth(this.props.data.lanes.length) + "px" }}>
                    <CardActionArea onClick={() => this.props.props.onClick()}>
                        <Box display={'flex'} flexDirection='row' alignItems={'center'}>
                            <Box m={1}>
                                <AddIcon fontSize='small' />
                            </Box>
                            <Box >
                                Add SubTender Tasks
                            </Box>
                        </Box>
                    </CardActionArea>
                </Card>
            </div>
        )
    }
}
export default withSnackbar(AddCardLink)