import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import Service from "../Networking/networkutils";
import { errorMessage } from '../Helper/ErrorMessage';
import moment from "moment";
import { CircleLoading } from 'react-loadingg';
import UserWorkloadGraph from './UserWorkloadGraph';
import { screenSizeCheck } from '../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import { getPrimaryColor } from '../Helper/CommonThings';

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

class UserProductivityReport extends Component {

    s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    constructor(props) {
        super()

        this.state = {
            workSearchDialog: false,
            userWeekData: [],
            graphLoad: false,
            billableTotal: 0,
            nonBillableTotal: 0,
            overHeadTotal: 0,
            tenderTotal: 0,
            hoursLoading: false,
            all: [],
            contractedHours: 0,
        }
    }

    componentDidMount() {
        this.productivity();
    }
    productivity() {
        this.setState({ graphLoad: true })
        let start_date = moment().subtract((screenSizeCheck() ? 3 : 6), 'months').startOf('week');
        let end_date = moment()
        // this.setState({ isLoading: true })
        var start_date1 = start_date
        var end_date1 = end_date
        start_date1.subtract(1, 'days')

        Service.get("/bb/api/team/tasks/billability/?uid=" + this.props.uid + "&start=" + start_date1.format('DD-MM-YYYY') + "&end=" + end_date1.format('DD-MM-YYYY'), {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var nonBillableTotal = 0
                var billableTotal = 0
                var overHeadTotal = 0
                var tenderTotal = 0
                var UserWeekData = []
                var contractedHours = 0
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    UserWeekData.push({
                        lable: moment(element.start).utc().format("DD-MM-YYYY"),
                        o_count: element.non_billable,
                        p_count: element.billable,
                        overhead_count: element.overhead_hours,
                        tender_count: element.tender_hours,
                    })
                    nonBillableTotal += element.non_billable
                    billableTotal += element.billable
                    overHeadTotal += element.overhead_hours
                    tenderTotal += element.tender_hours
                    contractedHours += element.contract_hours
                }
                // console.log(UserWeekData)
                this.setState({ userWeekData: UserWeekData, showGraph: true, graphLoad: false, nonBillableTotal, billableTotal, overHeadTotal, tenderTotal, contractedHours })
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });

    }
    render() {

        return (
            <div>

                {this.state.graphLoad ? <CircleLoading color={getPrimaryColor()} style={{ position: 'relative', margin: 'auto' }} /> :
                    <UserWorkloadGraph
                        data={this.state.userWeekData}
                        billableTotal={this.state.billableTotal}
                        nonBillableTotal={this.state.nonBillableTotal}
                        overHeadTotal={this.state.overHeadTotal}
                        tenderTotal={this.state.tenderTotal}
                        contractedHours={this.state.contractedHours}
                    />
                }

            </div>
        )
    }
}

export default withSnackbar(UserProductivityReport)