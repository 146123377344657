import { Box, Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import { getPrimaryColor } from '../../Helper/CommonThings';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import Service from "../../Networking/networkutils";

const token = getCookie("bb_ts_token");
// const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class ProjectReportManager extends Component {

    constructor(props) {
        super();

        this.state = {
            data: {},
            isLoading: true
        }
    }


    componentDidMount() {
        // console.log(this.props.value)
       this.fetchOps()
    }

    fetchOps() {
        Service.get('/bb/api/tender/manage/report/?pid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                // console.log(res.data)
                if (res.data.length !== 0) {
                    this.setState({
                        data: res.data[0],
                        isLoading: false
                    })
                } else {
                    this.putData(this.state.data)
                }

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    putData(putData) {
        this.setState({
            isLoading:true
        })
        // console.log(putData)
        if (putData.id) {
            putData.last_updated_by = user.id
            Service.put("/bb/api/tender/manage/report/" + putData.id + "/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: putData,
            })
                .then(res => {

                    this.setState({
                        data: res.data,
                        isLoading: false
                    })

                    this.props.enqueueSnackbar('Data updated successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    // console.log(error)
                    errorMessage(this.props, error)
                });
        } else {
            putData.pid = this.props.value.id
            putData.last_updated_by = user.id
            Service.post("/bb/api/tender/manage/report/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: putData,
            })
                .then(res => {

                    this.setState({
                        data: res.data,
                        isLoading: false
                    })
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }

    }

    render() {

        const handleToggle = (value) => () => {
            var change = { ...this.state.data }
            change[value] = !change[value]
            this.setState({ change })
            this.putData(change)
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
                            <List>
                                <ListItem key="psr" role={undefined} dense button onClick={handleToggle("psr")}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={this.state.data.psr}
                                            tabIndex={-1}
                                            disableRipple

                                        />
                                    </ListItemIcon>
                                    <ListItemText id={0} primary={`Project Status Report (PSR)`} />
                                </ListItem>
                                <ListItem key="evp" role={undefined} dense button onClick={handleToggle("evp")}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={this.state.data.evp}
                                            tabIndex={-1}
                                            disableRipple

                                        />
                                    </ListItemIcon>
                                    <ListItemText id={0} primary={`Earned Value Performance (EVP)`} />
                                </ListItem>
                                <ListItem key="include_gate6" role={undefined} dense button onClick={handleToggle("include_gate6")}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={this.state.data.include_gate6}
                                            tabIndex={-1}
                                            disableRipple

                                        />
                                    </ListItemIcon>
                                    <ListItemText id={0} primary={`HOE Gate6 Report`} />
                                </ListItem>
                            </List>
                        </Box>
                        {this.state.last_updated_by_data ? "Last Updated By: " + this.state.last_updated_by_data.last_name + ", " + this.state.last_updated_by.first_name : null}
                    </div>
                )}

            </div>
        )
    }
}

export default withSnackbar(ProjectReportManager)