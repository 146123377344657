import React, { Component } from 'react'
import CoverSheet from '../Forms/Coversheet';
import Rate from '../Forms/Rate';
import WBS from '../Forms/WBS';
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { getPrimaryColor } from '../../Helper/CommonThings';
import { Box } from '@mui/system';
import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// import Roles from '../Forms/Roles';

// const token = getCookie("bb_ts_token");
class AddTender extends Component {

  constructor(props) {
    super(props)

    this.state = {
      activeStep: this.props.page,
      allData: this.props.value,
      isLoading: true,
      nextBlock: false,
      tabSelected: this.props.onprojedit ? 0 : this.props.value === 'null' ? 0 : 2,
    }
  }

  componentDidMount() {

    var pageView = window.location.pathname + "/#TenderAddAndEdit";
    ReactGA.set({ page: pageView, });
    ReactGA.pageview(pageView);

    if (!getCookie("bb_ts_token")) {
      window.location.href = '/'
    }

    setTimeout(() => {
      this.setState({ isLoading: false })
    }, 250);
  }

  render() {

    const handleAllData = (alldata1) => {
      this.setState({ allData: alldata1, });
    }

    const handleChange = (event, newValue) => {
      this.setState({ tabSelected: newValue })
    };

    const getStepContent = () => {
      if (this.props.onprojedit) {
        return (
          <TabContext value={this.state.tabSelected}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Estimate" value={0} />
              </TabList>
            </Box>
            <TabPanel value={0}>
              <CoverSheet value={this.state.allData} subTender={false} onAllData={handleAllData} subproj={this.props.subproj} />
            </TabPanel>
          </TabContext>
        )
        // switch (step) {
        //   case 0:
        //     return <CoverSheet value={this.state.allData} subTender={false} onAllData={handleAllData} subproj={this.props.subproj} />;
        //   default:
        //     throw new Error('Unknown step');
        // }
      } else {
        return (
          <TabContext value={this.state.tabSelected}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Deliverables Schedule" value={0} />
                {this.state.allData === 'null' ? null : <Tab label="Rates" value={1} />}
                {this.state.allData === 'null' ? null : <Tab label="Deliverables Package" value={2} />}
              </TabList>
            </Box>
            <TabPanel value={0} >
              <CoverSheet value={this.state.allData} tender={this.props.tender} subTender={true} onAllData={handleAllData} subproj={this.props.subproj} />
            </TabPanel>
            {this.state.allData === 'null' ? null :
              <TabPanel value={1}>
                <Rate value={this.state.allData} />
              </TabPanel>}
            {this.state.allData === 'null' ? null :
              <TabPanel value={2}>
                <WBS value={this.state.allData} tender={this.props.tender} />
              </TabPanel>}
          </TabContext>
        )
        // switch (step) {
        //   case 0:
        //     return <CoverSheet value={this.state.allData} tender={this.props.tender} subTender={true} onAllData={handleAllData} subproj={this.props.subproj} />;
        //   case 1:
        //     return <Rate value={this.state.allData} nextBlock={handleNextBlock} />;
        //   case 2:
        //     return <WBS value={this.state.allData} tender={this.props.tender} />;
        //   default:
        //     throw new Error('Unknown step');
        // }
      }

    }

    return (
      <div>
        {(this.state.isLoading) ? (
          <LoopCircleLoading color={getPrimaryColor()} />
        ) : (
          getStepContent()
        )}
      </div>


    );
  }
}

export default (AddTender);