import { Box, Button, DialogActions, DialogContent, MenuItem, TextField, LinearProgress, Typography } from '@material-ui/core';
import React, { Component } from 'react'
import Service, { getBlobSASURL } from '../Networking/networkutils';
import { errorMessage } from '../Helper/ErrorMessage';
import { getCookie } from '../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import { DropzoneArea } from 'material-ui-dropzone';
import { Dialog, DialogTitle } from '@mui/material';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const token = getCookie("bb_ts_token");

class DefectsAndReqForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            issue: this.props.option,
            issueComment: null,
            issueName: null,
            files: [],
            loading: false,
            progress: 0,
            dateOfOccurance: dayjs(new Date()),
        }
    }

    postData() {
        if (this.state.issue === null || this.state.issueComment === null || this.state.issueName === null) {
            this.props.enqueueSnackbar('Please Fill all the fields!', {
                variant: 'warning'
            });
        } else {
            this.setState({ isLoading: true, });
            var category;
            if (this.props.option) {
                category = this.props.option
            } else {
                category = this.state.issue
            }
            var json = {
                category: category,
                name: this.state.issueName,
                comment: this.state.issueComment,
                date_of_occurrence: this.state.dateOfOccurance.format('YYYY-MM-DD'),
            }
            Service.post("/bb/api/helps/helps_creation/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: json,
            })
                .then(res => {

                    if (this.state.files.length > 0) {
                        this.uploadFiles(res.data.id)
                    } else {
                        this.setState({ projName: '' }, () => {
                            this.setState({
                                issueComment: null,
                                issue: null,
                                issueName: null
                            })
                            this.props.close()
                        });
                        this.props.enqueueSnackbar('Issue added successfully', {
                            variant: 'success'
                        });
                        if (!this.props.option) {
                            this.props.getData();
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    handleChange(files) {
        this.setState({
            files: files
        });
    }

    uploadFiles = async (id) => {
        const { BlobServiceClient } = require("@azure/storage-blob");
        window.Buffer = require("buffer").Buffer;

        let blob_obj = getBlobSASURL()

        if (blob_obj) {
            const blobSasUrl = blob_obj.domain + "/" + blob_obj.connection_string

            const blobServiceClient = new BlobServiceClient(blobSasUrl);

            const containerName = "general/helps/" + id;

            const containerClient = blobServiceClient.getContainerClient(containerName);

            // const SASToken = "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-12-31T12:44:46Z&st=2023-08-02T04:44:46Z&spr=https,http&sig=SNGaK%2FWsTOQ2ubG8IK9zdoETBBfZvSb6oyL5mJPVC%2Fc%3D"
            try {
                // debugger
                const promises = [];
                this.setState({ loading: true })
                var total_files_size = 0
                var file_name_array = []
                var obj = {}
                for (let i = 0; i < this.state.files.length; i++) {
                    const element = this.state.files[i];
                    if (element) {
                        total_files_size += element.size
                        obj[element.name] = 0
                    }
                }
                for (let i = 0; i < this.state.files.length; i++) {
                    const file = this.state.files[i];
                    if (file) {
                        file_name_array.push({ file_name: file.name, file_path: containerName + "/" + file.name })
                        const blockBlobClient = containerClient.getBlockBlobClient(file.name);
                        promises.push(blockBlobClient.uploadData(file, {
                            onProgress: ev => {
                                obj[file.name] = ev.loadedBytes
                                let total = 0
                                for (let j = 0; j < Object.keys(obj).length; j++) {
                                    const element = Object.keys(obj)[j];
                                    total += obj[element]
                                }
                                this.setState({ progress: (total / total_files_size) * 100 })
                            }
                        }));
                    }
                }
                await Promise.all(promises);
                this.putUpload(id, file_name_array)
            }
            catch (error) {
                console.log(error.message);
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ loading: false })
            }
        } else {
            this.props.enqueueSnackbar('Blob not available!', {
                variant: 'warning'
            });
        }


    }

    putUpload(id, files) {
        Service.put(`/bb/api/helps/update_helps_file_location/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                id: id,
                file_location: files
            },
        })
            .then(res => {
                this.setState({ projName: '' }, () => {
                    this.setState({
                        issueComment: null,
                        issue: null,
                        issueName: null,
                        loading: false
                    })
                    this.props.close()
                });
                this.props.enqueueSnackbar('Issue added successfully', {
                    variant: 'success'
                });
                if (!this.props.option) {
                    this.props.getData();
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    render() {

        const handleComm = (e) => {
            this.setState({ issueComment: e.target.value })
        };

        const handleIssueName = (e) => {
            this.setState({ issueName: e.target.value })
        }



        return (
            <div>
                <DialogContent>
                    <Box>
                        {!this.props.option ?
                            <TextField
                                select
                                style={{ width: 550 }}
                                disabled={this.props.option}
                                value={this.state.issue}
                                onChange={(e) => { this.setState({ issue: e.target.value }) }}
                                label="Select Type" variant="outlined" fullWidth >
                                <MenuItem value={'defects'}>
                                    Defects/Error/Other Issues
                                </MenuItem>
                                <MenuItem value={'requirements'}>
                                    Requirements, Developments and Improvements
                                </MenuItem>
                            </TextField> : null}
                    </Box>
                    <Box marginTop={1}>
                        <TextField variant="outlined"
                            value={this.state.issueName}
                            onChange={handleIssueName}
                            fullWidth
                            label={(this.state.issue === 'defects' || this.props.option === 'defects') ? 'Name of the Defect/Error/Other Issues' : (this.state.issue === 'requirements' || this.props.option === 'requirements') ? 'Name of the Requirement/Developement/Improvement' : 'Name'}
                            style={{ width: 550 }}
                        />
                    </Box>
                    {(this.state.issue === 'defects' || this.props.option === 'defects') ?
                        <Box marginTop={1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateField
                                    fullWidth
                                    format="DD-MM-YYYY"
                                    label="Date of Defect/Error/Other Issues Occurrence"
                                    value={this.state.dateOfOccurance}
                                    onChange={(dateOfOccurance) => this.setState({ dateOfOccurance })}
                                />
                            </LocalizationProvider>
                        </Box> : null
                    }
                    <Box marginTop={1}>
                        {this.state.issue ?
                            <Box display={'flex'} flexDirection={'column'}>
                                <TextField variant="outlined"
                                    value={this.state.issueComment}
                                    onChange={handleComm}
                                    fullWidth
                                    label={(this.state.issue === 'requirements' || this.props.option === 'requirements') ? 'Description of Requirement/Developement/Improvement' : (this.state.issue === 'defects' || this.props.option === 'defects') ? 'Please provide additional information' : null}
                                    style={{ width: 550 }}
                                />
                                <Box marginTop={1} width={'100%'}>
                                    <DropzoneArea
                                        // acceptedFiles={['.jpg','.xls',".xlsx", '.docx', '.ppt', '.jpeg', '.png', '.mp4']}
                                        maxFileSize={250000000}
                                        dropzoneText={(this.state.issue === 'defects' || this.props.option === 'defects') ? "Please attach the screenshot of the error pop-up message received\n(Drag and drop an attachment here or click)" : "Please provide any additional information\n(Drag and drop an attachment here or click)"}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </Box>
                            </Box> : null}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.close()} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={e => this.postData()} color="primary">
                        Submit
                    </Button>
                </DialogActions>

                <Dialog maxWidth="lg" fullWidth={true} open={this.state.loading}  >
                    <DialogTitle marginTop="2px" spacing={2}>Uploading...</DialogTitle>
                    <DialogContent>
                        <Box display="flex" alignItems="center">
                            <Box width="100%" mr={1}>
                                <LinearProgress variant="determinate" value={this.state.progress} />
                            </Box>
                            <Box minWidth={35}>
                                <Typography variant="body2" color="primary">{`${Math.round(
                                    this.state.progress,
                                )}%`}</Typography>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>

            </div>
        )
    }
}

export default withSnackbar(DefectsAndReqForm);
