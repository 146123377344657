import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { errorMessage } from '../../Helper/ErrorMessage';
import { getCookie } from '../../Helper/CookieHelper';
import ReactGA from 'react-ga';
import Service from "../../Networking/networkutils";
import MaterialTable from 'material-table';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import { getPrimaryColor } from '../../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class SubContractRolesActual extends Component {

    constructor(props) {
        super()

        this.state = {
            isLoading: true,
            data: [],
            rates: [],
            actual: [],
        }
    }

    componentDidMount() {
        
        var pageView = window.location.pathname + "/#TaskLevelAllocation";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        this.fetchOps()

    }

    fetchOps() {
        Service.get('/bb/api/tender/tasks/rolesnodata/?tid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    element.rev_hours = element.hours + element.req_hours
                }

                this.setState({
                    data: res.data,
                    
                })

                Service.get('/bb/api/tender/roles/subcontract/?t_tasks=' + this.props.value.id, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res1 => {
                        for (let i = 0; i < res.data.length; i++) {
                            const element = res.data[i];
                            element.act_avail = false
                            for (let j = 0; j < res1.data.length; j++) {
                                const element1 = res1.data[j];
                                if (element.id === element1.t_role) {
                                    element.act_avail = true
                                    element.act_data = element1
                                }
                            }
                        }
                        this.setState({
                            actual: res1.data,
                            rates: this.props.rates,
                            isLoading: false
                        })

                       
                    })
                    .catch(error => {
                        console.log(error)
                        errorMessage(this.props, error)
                    });


                

            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    rateCalc(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return element.costin;
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    return element.costuk;
                }
            }
        }
        return 'null';
    }

    costCalc(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.hours * element.costin);
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.hours * element.costuk);
                }
            }
        }
        return 'null';
    }

    revCostCalc(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.rev_hours * element.costin);
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.rev_hours * element.costuk);
                }
            }
        }
        return 'null';
    }

    actCostCalc(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.act_data.act_hours * element.costin);
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.act_data.act_hours * element.costuk);
                }
            }
        }
        return 'null';
    }

    putOps(oldData, newData) {
        var historyData = null
        if (newData.act_cost) {
            
            if (oldData.act_avail) {
                Service.post('/bb/api/tender/roles/subcontracthistory/' , {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data : {
                        tender: this.props.ds.subproject_pid,
                        t_pid: oldData.t_pid,
                        t_wbs: oldData.t_wbs,
                        t_tasks: oldData.t_tasks,
                        t_role: oldData.id,
                        prev_hours: oldData.act_data.act_hours,
                        curr_hours: newData.act_cost/this.rateCalc(newData),
                        created_by: user.id
                    }
        
                })
                    .then(res => {
                        historyData = res.data
                        Service.put('/bb/api/tender/roles/subcontract/' + oldData.act_data.id + "/", {
                            headers: {
                                Authorization: "Token " + token,
                            },
                            data : {
                                
                                act_hours: newData.act_cost/this.rateCalc(newData),
                               
                            }
                        })
                            .then(res2 => {
                                this.fetchOps()
                
                            })
                            .catch(error => {
    
                                errorMessage(this.props, error)
                                if (historyData) {
                                    Service.delete('/bb/api/tender/roles/subcontracthistory/' + historyData.id + '/', {
                                        headers: {
                                            Authorization: "Token " + token,
                                        },
    
                                    })
                                        .catch(error => {
                                            errorMessage(this.props, error)
                                        });
                                }
                            });
        
                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });
            } else {
                Service.post('/bb/api/tender/roles/subcontracthistory/' , {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data : {
                        tender: this.props.ds.subproject_pid,
                        t_pid: oldData.t_pid,
                        t_wbs: oldData.t_wbs,
                        t_tasks: oldData.t_tasks,
                        t_role: oldData.id,
                        prev_hours: 0,
                        curr_hours: newData.act_cost/this.rateCalc(newData),
                        created_by: user.id
                    }
        
                })
                    .then(res => {
                        historyData = res.data
                        Service.post('/bb/api/tender/roles/subcontract/', {
                            headers: {
                                Authorization: "Token " + token,
                            },
                            data : {
                                tender: this.props.ds.subproject_pid,
                                t_pid: oldData.t_pid,
                                t_wbs: oldData.t_wbs,
                                t_tasks: oldData.t_tasks,
                                t_role: oldData.id,
                                act_hours: newData.act_cost/this.rateCalc(newData),
                                created_by: user.id
                            }
                        })
                            .then(res2 => {
                                this.fetchOps()
                
                            })
                            .catch(error => {
                                errorMessage(this.props, error)
                                if (historyData) {
                                    Service.delete('/bb/api/tender/roles/subcontracthistory/' + historyData.id + '/', {
                                        headers: {
                                            Authorization: "Token " + token,
                                        },
    
                                    })
                                        .catch(error => {
                                            errorMessage(this.props, error)
                                        });
                                }
                            });
        
                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });
            }
        }

        
    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? (<LoopCircleLoading color={getPrimaryColor()} />) : (
                    <div>
                        <MaterialTable
                            key={3}
                            title="Actual"
                            columns={[
                                // { title: 'Role', field: 'id', editable: 'never', },
                                { title: 'Role', field: 'name', editable: 'never', },
                                {
                                    title: 'Budget Hours', field: 'hours', editable: 'never',
                                },
                                {
                                    title: 'Cost per hour', field: 'rev_hours', editable: 'never',
                                    render: rowData => this.rateCalc(rowData)
                                },
                                {
                                    title: 'Budget Cost', field: 'cost', editable: 'never',
                                    render: rowData => this.costCalc(rowData)
                                },
                                {
                                    title: 'Cost at completion', field: 'rev_cost', editable: 'never',
                                    render: rowData => this.revCostCalc(rowData)
                                },
                                {
                                    title: 'Current Actual Cost', field: 'act_cost',
                                    render: rowData => rowData.act_avail ? this.actCostCalc(rowData) : "NA"
                                },
                            ]}
                            data={this.state.data}
                            options={{

                                padding: "dense",
                            }}
                            editable={{

                                onRowUpdate: (newData, oldData) =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            if (oldData) {
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    if (newData.act_cost/this.rateCalc(newData) <= oldData.rev_hours) {
                                                        data[data.indexOf(oldData)] = newData;
                                                        if (newData.act_cost) {
                                                            newData.act_avail = true
                                                        }
                                                        this.setState({
                                                            isLoading: true
                                                        })
                                                        this.putOps(oldData, newData)
                                                    } else {
                                                        this.props.enqueueSnackbar('Actual Cost must be less than Revised Cost', {
                                                            variant: 'warning'
                                                        });
                                                    }
                                                    
                                                    return { ...prevState, data };
                                                });
                                            }
                                        }, 600);
                                    }),
                            }}
                        />
                    </div>
                )}
            </div>

        )
    }
}

export default withSnackbar(SubContractRolesActual)