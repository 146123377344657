import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from '@material-ui/core';
import { getMode } from '../MainDashBoard/ColorTheme/ColorTheme';
// import { styled } from '@mui/material/styles';
// import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
// import InputBase from '@mui/material/InputBase';
import { getCookie } from '../Helper/CookieHelper'
import { errorMessage } from '../Helper/ErrorMessage'
import Service from '../Networking/networkutils'
import { screenSizeCheck } from '../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { getPrimaryColor } from '../Helper/CommonThings';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';

var token = getCookie("bb_ts_token");
var access = JSON.parse(getCookie("bb_ts_access"));

// const Search = styled('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: getMode() ? alpha(theme.palette.common.white, 0.15) : '#e6e6e6',
//     '&:hover': {
//         backgroundColor: getMode() ? alpha(theme.palette.common.white, 0.25) : '#f2f2f2',
//     },
//     marginRight: theme.spacing(1),
//     marginLeft: 0,
//     width: '100%',
//     [theme.breakpoints.up('lg')]: {
//         marginLeft: theme.spacing(7),
//         width: 'auto',
//     },
//     [theme.breakpoints.down('lg')]: {
//         marginLeft: theme.spacing(4),
//         width: 'auto',
//     },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: 'inherit',
//     '& .MuiInputBase-input': {
//         padding: theme.spacing(1, 1, 1, 0),
//         // vertical padding + font size from searchIcon
//         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
//         transition: theme.transitions.create('width'),
//         width: '100%',
//         [theme.breakpoints.up('lg')]: {
//             width: '30ch',
//         },
//         [theme.breakpoints.down('lg')]: {
//             width: '25ch',
//         },
//     },
// }));

var tutorialLastModCount = 0

class Tutorials extends Component {
    constructor(props) {
        super(props)
        this.state = {
            video_url: [],
            all_video_url: [],
            isLoading: true,
            lastModified: {}
        }
    }

    getlastmod(url, id) {
        var req = new XMLHttpRequest();
        req.open("HEAD", url + '?time=' + (new Date().getTime()), true);
        // req.setRequestHeader('FileHeader','qwerty')
        req.send();
        req.onreadystatechange = () => {
            if (req.readyState === 4) {
                var contentType = req.getResponseHeader("Last-Modified");
                var lastModified = this.state.lastModified;
                lastModified[id] = contentType
                tutorialLastModCount += 1
                if (tutorialLastModCount === this.state.all_video_url.length) {
                    var all_video_url = this.state.all_video_url
                    for (let i = 0; i < all_video_url.length; i++) {
                        const element = all_video_url[i];
                        element.video_updated_on = lastModified[element.id]
                        if (lastModified[element.id]) {
                            element.video_updated_on_ms = moment(lastModified[element.id]).valueOf()
                        } else {
                            element.video_updated_on_ms = 0
                        }
                    }
                    all_video_url.sort((a, b) => b.video_updated_on_ms - a.video_updated_on_ms)
                    this.setState({ ...lastModified, ...all_video_url, video_url: all_video_url })
                } else {
                    this.setState({ ...lastModified })
                }
            }
        }
    }

    componentDidMount() {
        tutorialLastModCount = 0
        Service.get(`/bb/api/tutorials/tutorial_data/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var data = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    const access_category = element.access.split('_')[0]
                    if (access && access.access && access.access[access_category] && access.access[access_category][element.access]) {
                        this.getlastmod(element.url, element.id)
                        data.push(element)
                    }
                }
                this.setState({
                    video_url: data,
                    all_video_url: data,
                    isLoading: false
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    getNewTag(value) {
        if (this.state.lastModified[value.id]) {
            var created_date = moment(this.state.lastModified[value.id]).add(15, 'days').format()
            var current_date = moment().format();
            if (current_date <= created_date) {
                return true
            }
        }
        return false
    }

    handleSearchChange(data) {
        const data_lowercase = data.toLowerCase()
        var search_words = data_lowercase.split(" ");
        search_words = search_words.filter(n => n)
        var filtered_data = []
        if (search_words.length === 0) {
            filtered_data = this.state.all_video_url
        }

        for (let i = 0; i < this.state.all_video_url.length; i++) {
            const element = this.state.all_video_url[i];
            var num_of_passed = false
            for (let j = 0; j < search_words.length; j++) {
                const el = search_words[j];
                num_of_passed = element.search_key.includes(el)
                if (!num_of_passed) {
                    break
                }
            }
            if (num_of_passed) {
                filtered_data.push(element)
            }
        }
        this.setState({ video_url: filtered_data })
    }

    getUpdatedStatus(value) {
        if (this.state.lastModified[value.id]) {
            var status = ''
            var time = moment(this.state.lastModified[value.id]).startOf('seconds').fromNow()
            status = 'Updated ' + time
        } else {
            status = 'Record Unavailable'
        }
        return status
    }

    render() {
        return (
            this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                <div>
                    <Box display='flex' justifyContent={'center'} marginTop={'10px'}>
                        {/* <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                autoFocus
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(e) => this.handleSearchChange(e.target.value)}
                            />
                        </Search> */}
                         <TextField
                                autoFocus
                                size='small'
                                placeholder="Search"
                                id="search"
                                onChange={(e) => this.handleSearchChange(e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                }}
                            />
                    </Box>
                    {this.state.video_url.length !== 0 ?
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={'5px'}>
                            {this.state.video_url.map((value) =>
                                <Grid item xs={screenSizeCheck() ? 6 : 4}>
                                    <Card style={{ margin: '10px', padding: '10px' }} elevation={3}>
                                        <ReactPlayer width="100%" url={value.url} controls={true} pip={true} stopOnUnmount={false}
                                            light={value.thumbnail}
                                            playing
                                            playIcon={<IconButton><PlayArrowIcon color="primary" sx={{ fontSize: 120 }} /></IconButton>}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        controlsList: 'nodownload',
                                                        onContextMenu: e => e.preventDefault()
                                                    }
                                                }
                                            }}
                                        />
                                        <CardContent>
                                            <Box alignItems="center" flexDirection="row" display="flex" >
                                                <Box>
                                                    {this.getNewTag(value) ?
                                                        <p style={{ marginRight: '5px', fontSize: '10px', borderRadius: '3px', backgroundColor: getMode() ? '#f5f5f0' : '#DCDCDC', color: getMode() ? 'black' : 'black', padding: '3px' }}>
                                                            New
                                                        </p>
                                                        : null}
                                                </Box>
                                                <Box>
                                                    <Box fontWeight="fontWeightBold" fontSize={18}>
                                                        {value.name}
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Typography variant="body2" color="text.secondary">
                                                {value.description}
                                            </Typography>
                                            <Typography variant="body2" color="primary">
                                                {this.getUpdatedStatus(value)}
                                                {/* {this.state.lastModified[value.id] ? 'Updated ' + moment(this.state.lastModified[value.id]).startOf('seconds').fromNow() : 'Record Unavailable'} */}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                        </Grid> :
                        <Box display='flex' justifyContent={'center'} style={{ height: '500px' }} alignItems={'center'} fontSize={'16px'} fontWeight={'bold'}>No Tutorials</Box>}
                </div>
        );
    }
}

export default withSnackbar(Tutorials);