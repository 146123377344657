import MaterialTable from 'material-table';
import React, { Component } from 'react';
import { getGBPFormat } from '../../Helper/CommonThings';
import { CsvBuilder } from 'filefy';

class CMRiskDSTable extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: this.props.value.ds_data
        }
    }

    componentDidMount() {
        if (this.props.user) {
            this.setState({
                data: this.props.value.ds_data.filter(item => item.em_id === this.props.user.id)
            })
        }
    }

    render() {

        const exportCsv = (columnList, initialData) => {
            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    return columnDef.render ? columnDef.render(rowData) : rowData[columnDef.field];
                })
            );

            const builder = new CsvBuilder(this.props.user ? ("Unapproved Changes of " + this.props.user.last_name + ", " + this.props.user.first_name + ".csv") : "Unapproved Changes Details.csv");
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };
        return (
            <div>
                <MaterialTable
                    title={this.props.user ? "Unapproved Changes of " + this.props.user.last_name + ", " + this.props.user.first_name : "Unapproved Changes Details"}
                    columns={[
                        { title: 'EM', field: 'em' },
                        {
                            title: 'Discipline', field: 'discipline',
                            // render: rowData => getDiscipline()[rowData.discipline]
                        },
                        { title: 'Project', field: 'project_name' },
                        { title: 'DS', field: 'name' },
                        {
                            title: 'Value', field: 'new_cost', grouping: false,
                            render: rowData => getGBPFormat(rowData.new_cost)
                        },
                        {
                            title: 'Days?', field: 'no_of_days_from_date_created', grouping: false,
                            render: rowData => rowData.no_of_days_from_date_created + " days"
                        },
                    ]}
                    data={this.state.data}
                    options={{
                        paging: false,
                        padding: "dense",
                        grouping: true,
                        search: false,
                        // padding: this.state.data.length < 7 ? "default" : "dense",
                        filtering: true,
                        doubleHorizontalScroll: true,
                        toolbar: true,
                        exportButton: { csv: true },
                        exportCsv: (columns, data) => {
                            exportCsv(columns, data)
                        },
                    }}
                />
            </div>
        );
    }
}

export default CMRiskDSTable;