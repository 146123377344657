import React, { Component } from 'react'
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Service from "../Networking/networkutils"
import { withSnackbar } from 'notistack';
import Moment from 'react-moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { getCookie } from '../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { errorMessage } from '../Helper/ErrorMessage';
import { LoopCircleLoading } from 'react-loadingg';
import { getDarkMode, getPrimaryColor } from '../Helper/CommonThings';
import CalenderCustomToolbar from './CalenderCustomToolbar';

const localizer = momentLocalizer(moment);
const token = getCookie("bb_ts_token");
const minTime = new Date();
minTime.setHours(6, 0, 0);
const maxTime = new Date();
maxTime.setHours(21, 0, 0);

class Calender extends Component {

    constructor(props) {
        super(props)

        this.state = {
            events: [],
            openAdd: false,
            selectTime1: new Date(),
            selectTime2: new Date(),
            selectDate: new Date(),
            addName: '',
            addWork: '',
            isLoading2: true,
            openInfo: false,
            info: {},
            calender_view: 'work_week',
            start_date: moment().startOf('month').startOf('isoWeek').subtract(1, 'days'),
            end_date: moment().endOf('month').endOf('isoWeek').subtract(1, 'days'),
        }
    }

    componentDidMount() {

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }
        this.fetchOps(this.state.start_date, this.state.end_date);
    }

    fetchOps(start_date, end_date) {

        this.setState({ isLoading2: true })

        var pageView = window.location.pathname + "/#CalenderNoData";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        var start_date1 = moment(start_date).utc()
        var end_date1 = moment(end_date).utc()
        // start_date1.subtract(1, 'days')
        end_date1.add(1, 'days')
        // let time = moment('12:00', 'HH:mm');
        // let time1 = moment('12:00', 'HH:mm');
        // start_date.set({
        //     hour: time.get('hour'),
        //     minute: time.get('minute'),
        //     second: time.get('second')
        // });
        // end_date.set({
        //     hour: time1.get('hour'),
        //     minute: time1.get('minute'),
        //     second: time1.get('second')
        // });
        var url = "/bb/api/team/tasks/?gid=" + this.props.value.id + "&start_date=" + start_date1.format('DD-MM-YYYY HH:mm:ss') + "&end_date=" + end_date1.format('DD-MM-YYYY HH:mm:ss');
        if (localStorage.getItem("staff_to_see") !== "0") {
            url = url + "&uid=" + localStorage.getItem("staff_to_see");
        }
        // console.log(url)
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                var datapull = [];
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    var date = moment(element.start).format('DD/MM/YYYY')
                    var whours = moment.duration(moment(element.end).diff(moment(element.start)));
                    var whours1 = ''
                    if (parseFloat(whours.asHours()) < 0.9) {
                        whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                    } else {
                        whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                    }
                    var status = '';
                    if (element.is_approved === 1) {
                        status = "[Approved]"
                    } else if (element.is_approved === 2) {
                        status = "[Rejected]"
                    } else if (element.is_approved === 0 && element.is_submitted === 1) {
                        status = "[Submited]"
                    }
                    if (element.is_tender === 1) {
                        datapull.push({
                            id: element.id,
                            uid: element.uid,
                            gid: element.gid,
                            tid: element.tid,
                            pid: element.pid,
                            ttid: element.ttid,
                            tpid: element.tpid,
                            twbsid: element.twbsid,
                            trolesid: element.trolesid,
                            tmapid: element.tmapid,
                            uemail: element.uemail,
                            uname: element.uname_first + " " + element.uname_last,
                            gname: element.gname,
                            tname: element.tname,
                            tproj_name: element.tproj_name,
                            ttname: element.ttdecs ? element.ttdecs : element.ttname,
                            tuemail: element.tuemail,
                            twbsname: element.twbsname,
                            trolename: element.trolename,
                            ttender_name: element.ttender_name,
                            title: element.uname_last + ", " + element.uname_first + " " + status + "(" + whours1 + " hours) " + element.ttender_name + " - " + element.tproj_name + "(" + element.twbsname + " - " + (element.ttdecs ? element.ttdecs : element.ttname) + ") [" + element.uname_first + " " + element.uname_last + "]",
                            proj_name: element.proj_name,
                            work_detail: element.work_detail,
                            comments: element.comments,
                            date: date,
                            start: new Date(element.start),
                            end: new Date(element.end),
                            is_locked: element.is_locked,
                            is_submitted: element.is_submitted,
                            is_approved: element.is_approved,
                            strstart: element.start,
                            strend: element.end,
                            estimated_hours: element.estimated_hours,
                            created_by: element.created_by,
                            whours: whours1,
                            allDay: element.allDay,
                            is_tender: element.is_tender,
                        });
                    } else if (element.is_tender === 0) {
                        datapull.push({
                            id: element.id,
                            uid: element.uid,
                            gid: element.gid,
                            tid: element.tid,
                            pid: element.pid,
                            ttid: element.ttid,
                            tpid: element.tpid,
                            twbsid: element.twbsid,
                            trolesid: element.trolesid,
                            tmapid: element.tmapid,
                            uemail: element.uemail,
                            uname: element.uname_first + " " + element.uname_last,
                            gname: element.gname,
                            tname: element.tname,
                            tproj_name: element.tproj_name,
                            ttname: element.ttdecs ? element.ttdecs : element.ttname,
                            tuemail: element.tuemail,
                            twbsname: element.twbsname,
                            trolename: element.trolename,

                            title: status + "(" + whours1 + " hours) ->" + element.proj_name + "(" + element.tname + ") [" + element.uname_first + " " + element.uname_last + "]",
                            proj_name: element.proj_name,
                            work_detail: element.work_detail,
                            comments: element.comments,
                            date: date,
                            start: new Date(element.start),
                            end: new Date(element.end),
                            is_locked: element.is_locked,
                            is_submitted: element.is_submitted,
                            is_approved: element.is_approved,
                            strstart: element.start,
                            strend: element.end,
                            estimated_hours: element.estimated_hours,
                            created_by: element.created_by,
                            whours: whours1,
                            allDay: element.allDay,
                            is_tender: element.is_tender,
                        });
                    }

                }
                this.setState({ events: datapull, isLoading2: false });
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    onViewChange = (view) => {
        this.setState({ calender_view: view })
    }

    onRangeChange = (range) => {

        // console.log(range)

        if (Array.isArray(range)) {
            this.setState({
                start_date: moment(range[0]),
                end_date: moment(range[range.length - 1]),
            });
            this.fetchOps(moment(range[0]), moment(range[range.length - 1]))

        } else {
            this.setState({
                start_date: moment(range.start),
                end_date: moment(range.end),
            });
            this.fetchOps(moment(range.start), moment(range.end))
        }

    }

    render() {

        const handleInfoClose = () => {
            this.setState({ openInfo: false, })
        };

        const handleEventInfo = (e) => {
            //console.log(e)
            this.setState({ openInfo: true, info: e, })
        };


        return (
            <div>
                {(this.state.isLoading2) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (null)}
                <Calendar
                    selectable={true}
                    onSelectSlot={this.handleSelect}
                    defaultDate={moment().toDate()}
                    defaultView={Views.MONTH}
                    views={[Views.MONTH, Views.WORK_WEEK, Views.WEEK, Views.DAY]}
                    min={minTime}
                    max={maxTime}
                    events={this.state.events}
                    localizer={localizer}
                    onSelectEvent={(events, date) => handleEventInfo(events)}
                    onRangeChange={(range) => this.onRangeChange(range)}
                    onView={(view) => this.onViewChange(view)}
                    resizable
                    // style={{ margin: '1vh', height: "90vh" }}
                    getNow={() => null}
                    components={{ toolbar: props => <CalenderCustomToolbar {...props} noPlans={true} /> }}
                    // style={{ margin: '1vh', height: "92vh" }}
                    style={{
                        // backgroundColor: "#000000",
                        // borderRadius: '0px',
                        margin: '1vh', height: "92vh",
                        // border: "none",
                        //opacity: opacity,
                        color: getDarkMode() === "dark" ? "#ffffff" : null,

                        //display: 'block'
                    }}
                    eventPropGetter={event => (event.is_approved === 1) ? (
                        {
                            style: {
                                backgroundColor: '#00aa39',
                            },
                        }
                    ) : (event.is_approved === 2) ? (
                        {
                            style: {
                                backgroundColor: '#585858',
                            },
                        }
                    ) : (event.is_submitted === 1) ? (
                        {
                            style: {
                                backgroundColor: '#f09e00',
                            },
                        }
                    ) : (event.allDay) ? (
                        {
                            style: {
                                backgroundColor: '#b800ff',
                            },
                        }
                    ) : (null)}
                />
                <Dialog
                    open={this.state.openInfo}
                    onClose={handleInfoClose}
                    fullWidth
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Task Info"}</DialogTitle>
                    <DialogContent>
                        {(this.state.info.is_tender === 1) ? (
                            <div>
                                <Typography component="div" style={{ marginBottom: '7px' }}><Box fontWeight="fontWeightBold">Project : </Box>{this.state.info.ttender_name}</Typography>
                                <Typography component="div" style={{ marginBottom: '7px' }}><Box fontWeight="fontWeightBold">Deliverables Schedule : </Box>{this.state.info.tproj_name}</Typography>
                                <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box fontWeight="fontWeightBold">WBS : </Box>{this.state.info.twbsname}</Typography>
                                <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box fontWeight="fontWeightBold">Task : </Box>{this.state.info.ttdecs ? this.state.info.ttdecs : this.state.info.ttname}</Typography>
                            </div>
                        ) : (
                            <div>
                                <Typography component="div" style={{ marginBottom: '7px' }}><Box fontWeight="fontWeightBold">Project : </Box>{this.state.info.proj_name}</Typography>
                                <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box fontWeight="fontWeightBold">Task : </Box>{this.state.info.tname}</Typography>
                            </div>

                        )}
                        {/* <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box fontWeight="fontWeightBold">PTask Detail : </Box>{this.state.info.work_detail}</Typography> */}
                        <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box fontWeight="fontWeightBold">Start Time : </Box><Moment local format="hh:mm A">{this.state.info.strstart}</Moment></Typography>
                        <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box fontWeight="fontWeightBold">End Time : </Box><Moment local format="hh:mm A">{this.state.info.strend}</Moment></Typography>
                        <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box fontWeight="fontWeightBold">Comment : </Box>{this.state.info.comments}</Typography>
                        <Typography component="div" style={{ marginTop: '7px' }}><Box fontWeight="fontWeightBold">Employee : </Box>{this.state.info.uname}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.props.launchAuto(this.state.info.uid, this.state.info.gid) }} color="primary">
                            View User Data
                        </Button>
                        <Button onClick={handleInfoClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(Calender);