import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import { errorMessage } from '../Helper/ErrorMessage';
import { isMobile, screenSizeCheck } from '../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import Service from '../Networking/networkutils'
import moment from 'moment';
import { getMode } from '../MainDashBoard/ColorTheme/ColorTheme';
import { AppBar, Dialog, Toolbar, } from '@mui/material'
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import BroadCastPlayer from './BroadCastPlayer';
// import { styled } from '@mui/material/styles';
// import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
// import InputBase from '@mui/material/InputBase';
import { Alert } from '@material-ui/lab';
import { Box, Card, CardActionArea, CardContent, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { getPrimaryColor } from '../Helper/CommonThings';
import { LoopCircleLoading } from 'react-loadingg';
import { InputAdornment, TextField, } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// const Search = styled('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: getMode() ? alpha(theme.palette.common.white, 0.15) : '#e6e6e6',
//     '&:hover': {
//         backgroundColor: getMode() ? alpha(theme.palette.common.white, 0.25) : '#f2f2f2',
//     },
//     marginRight: theme.spacing(1),
//     marginLeft: 0,
//     width: '100%',
//     [theme.breakpoints.up('lg')]: {
//         marginLeft: theme.spacing(7),
//         width: 'auto',
//     },
//     [theme.breakpoints.down('lg')]: {
//         marginLeft: theme.spacing(4),
//         width: 'auto',
//     },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: 'inherit',
//     '& .MuiInputBase-input': {
//         padding: theme.spacing(1, 1, 1, 0),
//         // vertical padding + font size from searchIcon
//         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
//         transition: theme.transitions.create('width'),
//         width: '100%',
//         [theme.breakpoints.up('lg')]: {
//             width: '30ch',
//         },
//         [theme.breakpoints.down('lg')]: {
//             width: '25ch',
//         },
//     },
// }));

class UpComingBroadCast extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            copyData: [],
            isLoading: true,
            openBroadCast: false,
            broadCastData: null
        }
    }

    componentDidMount() {
        this.fetchData()
        this.refreshData = setInterval(() => {
            this.fetchData()
        }, (1000 * 10));
    }

    fetchData() {
        var url = ''
        if (window.location.href.includes('2022')) {
            url = `/bb/api/broadcast/?end__year=${2022}`
        } else {
            // url = `/bb/api/broadcast/?end__year=${2023}`
            url = `/bb/api/broadcast/?end__year=${2024}`
        }
        var data = []
        Service.get(url)
            .then(res => {
                var current_time = moment().format('YYYY-MM-DD HH:mm:ss')
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    console.log(element.start, element.end, current_time)
                    if ((moment(current_time).isSameOrAfter(element.start) && moment(current_time).isSameOrBefore(element.end))) {
                        data.push(element)
                    } else if (moment(current_time).isSameOrBefore(element.start)) {
                        data.push(element)
                    }
                }

                this.setState({
                    data: data,
                    copyData: data,
                    isLoading: false
                })
            })
            .catch(error => {
                if (this.refreshData) {
                    clearInterval(this.refreshData)
                }
                errorMessage(this.props, error)
            });
    }

    componentWillUnmount() {
        clearInterval(this.refreshData)
    }

    liveCheck(start, end) {
        var current_time = moment().format('YYYY-MM-DD HH:mm:ss')
        var start_time = moment(start).format('YYYY-MM-DD HH:mm:ss')
        var end_time = moment(end).format('YYYY-MM-DD HH:mm:ss')
        // console.log(current_time, start_time, end_time)
        if (moment(current_time).isSameOrAfter(start_time) && moment(current_time).isSameOrBefore(end_time)) {
            return 'live'
        } else if (moment(current_time).isSameOrBefore(start_time)) {
            return 'upcoming'
        } else if (moment(current_time).isSameOrAfter(end_time)) {
            return 'completed'
        } else {
            return null
        }
    }

    allowCLick(value) {
        var current_time = moment().format('YYYY-MM-DD HH:mm:ss')
        var start_time = moment(value.start).subtract(1, 'hours').format('YYYY-MM-DD HH:mm:ss')
        var end_time = moment(value.end).add(2, 'hours').format('YYYY-MM-DD HH:mm:ss')
        if (moment(current_time).isSameOrAfter(start_time) && moment(current_time).isSameOrBefore(end_time)) {
            this.setState({ broadCastData: value, openBroadCast: true })
        } else {
            this.props.enqueueSnackbar('Enabled only an hour before start and two hours after end ', {
                variant: 'info'
            });
        }
    }

    handleSearchChange(data) {
        const data_lowercase = data.toLowerCase()
        var search_words = data_lowercase.split(" ");
        search_words = search_words.filter(n => n)
        var filtered_data = []
        if (search_words.length === 0) {
            filtered_data = this.state.copyData
        }

        for (let i = 0; i < this.state.copyData.length; i++) {
            const element = this.state.copyData[i];
            var num_of_passed = false
            for (let j = 0; j < search_words.length; j++) {
                const el = search_words[j];
                num_of_passed = element.name.toLowerCase().includes(el)
                if (!num_of_passed) {
                    break
                }
            }
            if (num_of_passed) {
                filtered_data.push(element)
            }
        }
        this.setState({ data: filtered_data })
    }

    render() {
        const handleBroadCastClose = () => {
            this.setState({ openBroadCast: false })
        };
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) :
                    <div>
                        <Box margin={'7px'} style={{ width: '99%' }}>
                            <Alert severity="info"> The Match schedules are subjected to change.
                                Please look at 11.30 am everyday for the exact day's events</Alert>
                        </Box>
                        <Box display='flex' justifyContent={'center'} marginTop={'10px'}>
                            {/* <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    autoFocus
                                    placeholder="Search"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e) => this.handleSearchChange(e.target.value)}
                                />
                            </Search> */}
                            <TextField
                                autoFocus
                                autoComplete='off'
                                size='small'
                                placeholder="Search"
                                id="search"
                                onChange={(e) => this.handleSearchChange(e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                }}
                            />
                        </Box>
                        {this.state.data.length !== 0 ?
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={'5px'}>
                                {this.state.data.map((value) =>
                                    <Grid item xs={isMobile() ? 12 : screenSizeCheck() ? 4 : 3}>
                                        <Card style={{ margin: '10px', }} elevation={3} className='gamecard'>
                                            <CardActionArea onClick={() => this.allowCLick(value)}>
                                                <CardContent>
                                                    <Box display='flex' flexDirection='column' >
                                                        <Box display='flex' flexDirection='row' alignItems={'center'}>
                                                            <Box marginRight="10px"  >
                                                                <Typography variant='h5'>
                                                                    {value.name}
                                                                </Typography>
                                                            </Box>
                                                            <Box >
                                                                {this.liveCheck(value.start, value.end) === 'live' ?
                                                                    <p style={{ marginRight: '5px', fontSize: '12px', borderRadius: '3px ', backgroundColor: 'red', color: 'white', padding: '3px' }}>
                                                                        Live
                                                                    </p> : this.liveCheck(value.start, value.end) === 'completed' ? <p style={{ marginRight: '5px', fontSize: '12px', borderRadius: '3px ', backgroundColor: 'green', color: 'white', padding: '3px' }}>
                                                                        Completed
                                                                    </p> : this.liveCheck(value.start, value.end) === 'upcoming' ? <p style={{ marginRight: '5px', fontSize: '12px', borderRadius: '3px ', backgroundColor: 'blue', color: 'white', padding: '3px' }}>
                                                                        Upcoming
                                                                    </p> : null}
                                                            </Box>
                                                        </Box>

                                                        <Box display='flex' flexDirection={'row'} alignItems='center'>
                                                            <Typography variant='subtitle1'>
                                                                Date of Game: {moment(value.start).format('DD-MM-YYYY')}
                                                            </Typography>
                                                        </Box>
                                                        <Box display='flex' flexDirection={'row'} alignItems='center' >
                                                            <Box display='flex' flexDirection={'row'} alignItems='center' >
                                                                <Typography variant='body2'>
                                                                    Timing: {moment(value.start).format('hh:mm A')} - {moment(value.end).format('hh:mm A')}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Divider variant="middle" style={{ marginTop: '5px' }} />
                                                    <Box display='flex' flexDirection={'row'} mt={1}>
                                                        {!value.final_announcement ?
                                                            <Box>
                                                                <Typography variant='caption' >
                                                                    Live Streaming will start 15 mins before from the game schedule
                                                                </Typography>
                                                            </Box> :
                                                            <Box >
                                                                <Typography variant='caption' color={getMode() ? 'secondary' : 'primary'}>
                                                                    {value.final_announcement}
                                                                </Typography>
                                                            </Box>}
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )}
                            </Grid> :
                            <Box display='flex' justifyContent={'center'} style={{ height: '500px' }} alignItems={'center'} fontSize={'16px'} fontWeight={'bold'}>No Games</Box>}

                        <div>
                            <Dialog fullScreen open={this.state.openBroadCast} onClose={handleBroadCastClose} TransitionComponent={Transition}>
                                <AppBar position='fixed' color="transparent" elevation={0} >
                                    <Toolbar>
                                        <IconButton edge="start" color="inherit" onClick={handleBroadCastClose} aria-label="close">
                                            <CloseIcon color="error" />
                                        </IconButton>
                                        <Typography variant="h6" color='primary'>
                                            {this.state.broadCastData ? this.state.broadCastData.name : null}
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                <BroadCastPlayer data={this.state.broadCastData} />
                            </Dialog>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default withSnackbar(UpComingBroadCast)