import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import Service from "../../Networking/networkutils";
import { getCookie } from '../../Helper/CookieHelper';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Box, Button, Checkbox, Grid, TextField } from '@material-ui/core';
import { errorMessage } from '../../Helper/ErrorMessage';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import MaterialTable from 'material-table';
import moment from "moment";
import CustomTable1 from './CustomTable1';
import { getPrimaryColor } from '../../Helper/CommonThings';

const token = getCookie("bb_ts_token");

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class UsersTimesheetSummary extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
            startDate: moment(),
            endDate: moment(),
            weeksData: [],
            rows: [],
            column1: [],
            column2: [],
            selectedWeeks: [],
        }
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        })
        var date1 = moment(this.state.startDate).subtract(7, 'days').format('DD-MM-YYYY')
        var date2 = moment(this.state.endDate).subtract(7, 'days').format('DD-MM-YYYY')
        Service.get2("/bb/api/report/view/timesheet_week_report3/?start=" + date1 + "&end=" + date2, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                // console.log(res.data)
                this.compileData(res.data)

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
        this.weekList();
    }

    fetchOps() {
        this.setState({
            isLoading: true
        })
        var date1 = moment(this.state.startDate).format('DD-MM-YYYY')
        var date2 = moment(this.state.endDate).format('DD-MM-YYYY')
        Service.get2("/bb/api/report/view/timesheet_week_report3/?start=" + date1 + "&end=" + date2, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                // console.log(res.data)
                this.compileData(res.data)

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchOps2() {
        // console.log(this.state.selectedWeeks)
        this.setState({
            isLoading: true
        })
        Service.patch("/bb/api/report/view/timesheet_week_report4/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: this.state.selectedWeeks,
        })
            .then(res => {
                // console.log(res.data)
                this.compileData(res.data)
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    compileData(data) {
        var column1 = [""]
        var column2 = [
            { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
            { id: 'loc', numeric: false, disablePadding: true, label: 'EDC/UK/SL' },
            { id: 'teams', numeric: false, disablePadding: true, label: 'Teams' },
            // { id: i+'work', numeric: false, disablePadding: true, label: 'Completed' }
        ]
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            column1.push(element.name)
            column2.push({ id: element.week_no + 'cont', numeric: true, disablePadding: true, label: 'Contracted' })
            column2.push({ id: element.week_no + 'comp', numeric: true, disablePadding: true, label: 'Completed' })
            column2.push({ id: element.week_no + 'bal', numeric: true, disablePadding: true, label: 'Difference' })
        }

        var userData = data[0].users_details

        for (let i = 0; i < userData.length; i++) {
            const element = userData[i];
            for (let j = 0; j < data.length; j++) {
                const element1 = data[j];
                for (let k = 0; k < element1.users_details.length; k++) {
                    const element2 = element1.users_details[k];
                    if (element2.uid === element.uid) {
                        element[element1.week_no + 'comp'] = element2.worked_time
                        element[element1.week_no + 'cont'] = element2.weekly_hours
                        element[element1.week_no + 'bal'] = element[element1.week_no + 'cont'] - element[element1.week_no + 'comp']
                    }
                }
            }
        }

        // console.log(column2)
        // console.log(userData)

        this.setState({
            isLoading: false,
            rows: userData,
            column1: column1,
            column2: column2
        })
    }

    weekList() {
        var weeks_list = []
        var year_start = moment().subtract(1, 'years')
        var start_date = year_start
        // var week_num_increase = 0
        // console.log(start_date.week())
        // if (start_date.week() > 1) {
        //     weeks_list.push({
        //         name: "Week 1 (" + (start_date.startOf('week').format('DD-MM-YYYY') + " - "
        //             + start_date.endOf('week').format('DD-MM-YYYY'))+ ")",
        //         start: start_date.startOf('week').format('DD-MM-YYYY'),
        //         end: start_date.endOf('week').format('DD-MM-YYYY'),
        //     })
        //     week_num_increase = 1
        //     start_date = start_date.endOf('week').add(1, 'day')
        // }
        // console.log(parseInt(start_date.format('YYYY')), parseInt(moment().format('YYYY')))
        while (start_date <= moment().add(1, 'week')) {

            weeks_list.push({
                name: moment(start_date).format('YYYY') + " Week " + (start_date.endOf('week').isoWeek()) + " (" + (start_date.startOf('week').format('DD-MM-YYYY') + " - "
                    + start_date.endOf('week').format('DD-MM-YYYY')) + ")",
                start: start_date.startOf('week').format('DD-MM-YYYY'),
                end: start_date.endOf('week').format('DD-MM-YYYY'),
            })
            start_date = start_date.endOf('week').add(1, 'day')
        }
        this.setState({
            weeksData: weeks_list.reverse()
        })
    }

    handleDateChange = (date) => {
        this.setState({
            startDate: date
        })
    }

    handleDateChange2 = (date) => {
        this.setState({
            endDate: date
        })
    }

    onSelectedWeeks = (event, values) => {
        this.setState({ selectedWeeks: values });
    }

    render() {
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Box
                                display="flex"
                                alignItems="center"
                                p={1}
                                m={1}
                            >

                                <Box p={1}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Start Date"
                                        format="dd/MM/yyyy"
                                        value={this.state.startDate}
                                        onChange={this.handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Box>

                                <Box p={1}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog1"
                                        label="End Date"
                                        format="dd/MM/yyyy"
                                        value={this.state.endDate}
                                        onChange={this.handleDateChange2}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Box>

                                <Box p={1}>
                                    <Button variant="contained" color="primary" onClick={() => this.fetchOps()}>Fetch Data</Button>

                                </Box>


                            </Box>


                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <Box
                            display="flex"
                            alignItems="center"
                            p={1}
                            m={1}
                        >

                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={this.state.weeksData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={this.onSelectedWeeks}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Select Weeks" placeholder="Favorites" />
                                )}
                            />
                            <Button style={{ marginLeft: '10px' }} variant="contained" color="primary" onClick={() => this.fetchOps2()}>Fetch Data</Button>
                        </Box>
                    </Grid>
                </Grid>
                {
                    (this.state.isLoading) ? (<LoopCircleLoading color={getPrimaryColor()} />) : (
                        <CustomTable1 rows={this.state.rows} headCells={this.state.column2} topCol={this.state.column1} tableName={"Users Timesheet Submission Summary Status"} />
                    )
                }
            </div>
        )
    }
}

export default withSnackbar(UsersTimesheetSummary)