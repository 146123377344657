import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import FutureBudgetHours from './FutureBudgetHours';
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import FutureBudget from './FutureBudget';
import { Box } from '@mui/system';
import ScrollToTop from "react-scroll-to-top";
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { Autocomplete, ToggleButtonGroup } from '@material-ui/lab';
import RefreshIcon from '@material-ui/icons/Refresh';
import ToggleButton from '@material-ui/lab/ToggleButton';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FutureRequirement from './FutureRequirement';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import UpdateIcon from '@material-ui/icons/Update';
import { getPrimaryColor } from '../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
// const user = JSON.parse(getCookie("bb_ts_user"));

const BUDGETING_YEAR = 2022

class FutureBudgetMain extends Component {

    constructor(props) {
        super(props)

        this.state = {
            teams: [],
            teamMembers: [],
            rateCard: [],
            budgetHours: [],
            futureBudget: [],
            futureRequirement: [],
            isLoading: true,
            searchText: "",
            selectedTeam: null,
            displayType: 'percent',
            rolesLookup: null,
            budgetOrReq: 'budget',
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        Service.get(`/bb/api/budget/future/estimate/get_user_team_data/?year=` + BUDGETING_YEAR, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({
                    teams: res.data.teams,
                    teamMembers: res.data.team_members,
                    rateCard: res.data.rate_card,
                    budgetHours: res.data.future_budget_hours,
                    futureBudget: res.data.future_budget,
                    futureRequirement: res.data.future_requirement,
                    isLoading: false,
                })
                this.fetchRoles()
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    fetchRoles() {
        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var rolesLookup = {}
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    rolesLookup[element.id.toString()] = element.name
                }
                this.setState({
                    rolesLookup: rolesLookup,
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    render() {

        const onTeamChange = (event, values) => {
            this.setState({
                selectedTeam: null
            }, () => {
                this.setState({
                    selectedTeam: values
                })
            })

        }

        const onRefresh = (event, values) => {
            this.setState({
                isLoading: true,
                selectedTeam: null
            })
            this.fetchData()
        }

        const handleDisplayTypeChange = (event, values) => {
            var selectedTeam = this.state.selectedTeam
            this.setState({
                selectedTeam: null
            }, () => {
                this.setState({
                    displayType: values,
                    selectedTeam: selectedTeam
                })
            })
        }

        const handleBudgetOrReqChange = (event, values) => {
            if (values !== null) {
                this.setState({
                    budgetOrReq: values,
                    // selectedTeam: selectedTeam
                })
            }

        }

        return (
            <div style={{ marginBottom: '55px' }}>
                <ScrollToTop smooth />
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (access.access.l19a && access.access.l19a.l19a_b) ? (
                    <FutureBudgetHours BUDGETING_YEAR={BUDGETING_YEAR} data={this.state.budgetHours} />
                ) : null}

                <Box sx={{
                    display: 'flex',
                    p: 1,
                    m: 1,
                    justifyContent: 'center'
                }}>
                    <Autocomplete
                        id="combo-box-demo"
                        value={this.state.selectedTeam}
                        options={this.state.teams}
                        getOptionLabel={(option) => option.name}
                        onChange={onTeamChange}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Select Team" variant="outlined" />}
                    />
                    <Tooltip title="Refresh" style={{ marginLeft: '15px' }} placement="top">
                        <IconButton color="primary" aria-label="add to shopping cart" onClick={onRefresh}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>

                    <ToggleButtonGroup
                        style={{ marginLeft: '15px' }}
                        value={this.state.budgetOrReq}
                        exclusive
                        onChange={handleBudgetOrReqChange}
                        aria-label="budget or req"
                    >
                        <ToggleButton value="budget" aria-label="percent">
                            <Tooltip title="Overview" placement="top">
                                <DirectionsRunIcon />
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="requirement" aria-label="amount">
                            <Tooltip title="Future Recruitments" placement="top">
                                <UpdateIcon />
                            </Tooltip>
                        </ToggleButton>

                    </ToggleButtonGroup>

                    <ToggleButtonGroup
                        style={{ marginLeft: '15px' }}
                        value={this.state.displayType}
                        exclusive
                        onChange={handleDisplayTypeChange}
                        aria-label="display type"
                    >
                        <ToggleButton value="percent" aria-label="percent">
                            <Tooltip title="Productivity Percent" placement="top">
                                <TrendingUpIcon />
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="amount" aria-label="amount">
                            <Tooltip title="Forecast" placement="top">
                                <AttachMoneyIcon />
                            </Tooltip>
                        </ToggleButton>

                    </ToggleButtonGroup>
                   
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        bgcolor: 'background.paper',
                    }}
                >
                    {(this.state.selectedTeam && this.state.budgetOrReq === "budget") ? (
                        <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                            <FutureBudget team={this.state.selectedTeam}
                                allTeamMembers={this.state.teamMembers} BUDGETING_YEAR={BUDGETING_YEAR}
                                rateCard={this.state.rateCard} futureBudget={this.state.futureBudget}
                                typeOfDisplay={this.state.displayType} futureBudgetHours={this.state.budgetHours}
                                futureRequirement={this.state.futureRequirement}
                            />
                        </div>
                    ) : (
                        null
                    )}
                    {(this.state.selectedTeam && this.state.rolesLookup && this.state.budgetOrReq === "requirement") ? (
                        <FutureRequirement team={this.state.selectedTeam}
                            futureRequirement={this.state.futureRequirement}
                            rolesLookup={this.state.rolesLookup}
                        />
                    ) : (null)}
                </Box>


            </div>
        )
    }
}

export default withSnackbar(FutureBudgetMain)