import { Dialog, DialogTitle, IconButton } from "@mui/material";
import { Dialog as DialogV4, IconButton as IconButtonV4, Typography, withStyles } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import * as React from 'react';


export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export const BootstrapDialogV4 = DialogV4
export const BootstrapDialogTitleV4 = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButtonV4 aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon color="error" />
        </IconButtonV4>
      ) : null}
    </MuiDialogTitle>
  );
});


export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});