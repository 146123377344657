import { Box } from '@material-ui/core'
import { Avatar, Button, Tooltip, } from '@mui/material'
import React, { Component } from 'react'
import { getCookie } from '../../../../../Helper/CookieHelper';
import { screenSizeCheck } from '../../../../ScreensizeCheck/ScreensizeCheck';
import { getGreyColor } from '../../../../ColorTheme/ColorTheme';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import moment from "moment";
import Service from '../../../../../Networking/networkutils'
import { withSnackbar } from 'notistack';
import { errorMessage } from '../../../../../Helper/ErrorMessage';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
const access = JSON.parse(getCookie("bb_ts_access"));

class ProfileCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            isLoading: true,
            morningQuotes: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        var startDate = moment().startOf('year').format('DD-MM-YYYY');
        var endDate = moment().endOf('year').format('DD-MM-YYYY');
        var time = moment().format('HH');
        var morningQuotes = ['Rise and shine!', 'Top of the morning to you!', 'Good day to you!', 'Have a great day!', 'Hello there!', 'Morning!', 'Greetings!', 'Good morning sunshine!', 'What a lovely morning!', "Rise n’ shine!", " Very great morning!"]
        var afternoonQuotes = ['Have a wonderful afternoon!', 'Splendid noon!', 'Marvelous afternoon!', 'Have a glorious afternoon!', 'Have a astonishing noon!', 'Golden afternoons!', 'Hope your noon is wonderful!', 'Have a happy afternoon!', 'Bright as the afternoon sun!', 'Fun-filled afternoon!', 'Brightener noon!', 'Wishing you a splendid afternoon!', 'Here’s a wish for a fun afternoon!', 'May your noon be blessed!', 'Brighter noon!']
        var eveningQuotes = ['Have a great evening!', 'Have a wonderful evening!', 'Enjoy your evening!', 'Wishing you a fabulous evening!', 'Have an amazing evening!', 'Enjoy your evening to the fullest!', 'Wishing you the best day ever!']

        if (time < 12) {
            var morning_quotes = morningQuotes[moment().format('ss') % morningQuotes.length];
            this.setState({ quotes: morning_quotes })
        } else if (time < 16) {
            var afternoon_quotes = afternoonQuotes[moment().format('ss') % afternoonQuotes.length];
            this.setState({ quotes: afternoon_quotes })
        } else if (time >= 16) {
            var evening_quotes = eveningQuotes[moment().format('ss') % eveningQuotes.length];
            this.setState({ quotes: evening_quotes })
        }

        Service.get(`/bb/api/lms/leaveinfo/cumulative/?uid=${user.id}&start=${startDate}&end=${endDate}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.length !== 0) {
                    this.setState({
                        data: res.data[0],
                        isLoading: false,
                    })
                }
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }


    render() {
        return (
            <div style={{ height: '100%' }}>
                <Box display='flex' flexDirection={'row'} justifyContent={'left'} alignItems={'center'} style={{ height: '100%', }}>

                    <Box display={'flex'} flexDirection={'column'} m={1} >
                        <Avatar sx={{ bgcolor: 'grey', width: screenSizeCheck() ? 60 : 90, height: screenSizeCheck() ? 60 : 90 }}><Box fontWeight={'bold'} fontSize={screenSizeCheck() ? '20px' : '30px'}>{user.first_name.charAt(0) + user.last_name.charAt(0)}</Box></Avatar>
                    </Box>

                    <Box display={'flex'} flexDirection={'column'} m={1} >

                        <Box fontSize={window.innerWidth <= 1366 ? '12px' : window.innerWidth <= 1650 ? '14px' : '18px'} color={getGreyColor()}>
                            {this.state.quotes}
                        </Box>

                        <Box fontSize={window.innerWidth <= 1366 ? '16px' : window.innerWidth <= 1650 ? '18px' : '25px'} fontWeight={'bold'} flexWrap="wrap" width="auto">
                            {user.first_name}
                        </Box>

                        <Box fontSize={window.innerWidth <= 1366 ? '14px' : window.innerWidth <= 1650 ? '16px' : '18px'} flexWrap='wrap' color={getGreyColor()}>
                            {!user.user_details?.designation ? user.role_name : user.user_details?.designation}
                        </Box>
                        {user.office_loc === 1 ?
                            <Box display={'flex'} flexDirection={'row'} justifyContent={'left'} marginTop={'10px'} alignItems={'center'}>

                                <Tooltip title='Casual Leave'>
                                    <Box marginRight={window.innerWidth <= 1366 ? '10px' : window.innerWidth <= 1650 ? '12px' : '15px'} color='#E08925' fontWeight={'bold'} fontSize={window.innerWidth <= 1366 ? '10px' : window.innerWidth <= 1650 ? '12px' : '14px'}>
                                        CL: {(this.state.data.this_month_sick_leave - this.state.data.sick_days).toFixed(2)}
                                    </Box>
                                </Tooltip>

                                <Tooltip title='Annual Leave'>
                                    <Box marginRight={window.innerWidth <= 1366 ? '10px' : window.innerWidth <= 1650 ? '12px' : '15px'} color='#1A6DA3' fontWeight={'bold'} fontSize={window.innerWidth <= 1366 ? '10px' : window.innerWidth <= 1650 ? '12px' : '14px'}>
                                        AL: {((this.state.data.this_month_annual_leave + this.state.data.annaul_carry_forward) - this.state.data.leave_days).toFixed(2)}
                                    </Box>
                                </Tooltip>

                                <Box marginRight={screenSizeCheck() ? '10px' : '15px'} >
                                    <Tooltip title="Leave Management System">
                                        <Button
                                            size={screenSizeCheck() ? 'small' : 'medium'}
                                            startIcon={<OpenInNewIcon />}
                                            variant="standard"
                                            color="primary"
                                            onClick={() => { ((access.access.l18a && access.access.l18a.l18a_b && (this.props.data.lead_in_teams !== 0)) || (access.access.l18a && access.access.l18a.l18a_c)) ? window.open("https://lms.bbparam.com/") : window.open("https://lms.bbparam.com/user/leave") }}>
                                            LMS
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Box>
                            : null}
                    </Box>
                </Box>
            </div>
        )
    }
}

export default withSnackbar(ProfileCard)