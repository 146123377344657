import React, { Component } from 'react'
import MaterialTable from 'material-table'
// import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
// import Button from '@material-ui/core/Button';
import { LoopCircleLoading } from 'react-loadingg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getPrimaryColor, poundFormat } from '../../Helper/CommonThings';
// import { Box } from '@material-ui/core';
// import Moment from 'react-moment';
// import { getCookie } from '../../Helper/CookieHelper';
// import { errorMessage } from '../../Helper/ErrorMessage';


// const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));
class ProjectReportUser extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            openAdd: false,
            issueName: '',
            issueComment: '',
            isLoading: false,
        }
    }

    totalCalc(key) {
        var total = 0
        for (let i = 0; i < this.props.value.length; i++) {
            const element = this.props.value[i];
            total += element[key]
        }
        return total
    }



    render() {

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>

                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Allocated Hours</TableCell>
                                        <TableCell>Hours to date</TableCell>
                                        {/* <TableCell>Rate</TableCell> */}
                                        <TableCell>Cost</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableCell>{this.totalCalc("allocated_this_task")}</TableCell>
                                    <TableCell>{this.totalCalc("hours_worked")}</TableCell>
                                    {/* <TableCell>{this.totalCalc("rate")}</TableCell> */}
                                    <TableCell>{poundFormat(this.totalCalc("cost"))}</TableCell>

                                </TableBody>
                            </Table>
                        </TableContainer>

                        <MaterialTable
                            title="User Based Report"
                            columns={[
                                { title: 'User Name', field: 'full_name' },
                                { title: 'DS ID', field: 'sheet_data.tpid' },
                                { title: 'DS', field: 'sheet_data.tproj_name' },
                                { title: 'WBS ID', field: 'sheet_data.twbsid' },
                                { title: 'WBS', field: 'sheet_data.twbsname' },
                                { title: 'Task ID', field: 'sheet_data.ttid' },
                                {
                                    title: 'Task Name', field: 'sheet_data.ttname',
                                    render: rowData =>  rowData.sheet_data.ttdecs ? rowData.sheet_data.ttdecs : rowData.sheet_data.ttname,
                                },
                                { title: 'Allocated Hours', field: 'allocated_this_task' },
                                { title: 'Hours to date', field: 'hours_worked' },
                                // { title: 'Rate', field: 'rate' },
                                { title: 'Cost to date', field: 'cost' },

                            ]}
                            data={this.props.value}

                            options={{
                                padding: "dense",
                                actionsColumnIndex: -1,
                                pageSize: 10,
                                paging: false,
                                pageSizeOptions: [5, 10, 20],
                                exportButton: { csv: true },
                            }}
                        />

                    </div>
                )}

            </div>
        )
    }
}

export default withSnackbar(ProjectReportUser);