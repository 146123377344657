import React, { Component } from 'react'
import moment from 'moment'
import Drawer from '@material-ui/core/Drawer';
import RoleVsResourceToggle from './RoleVsResourceToggle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../Helper/CookieHelper';
import Service from "../Networking/networkutils";
import { errorMessage } from '../Helper/ErrorMessage';
import { withSnackbar } from 'notistack'
import { getPrimaryColor } from '../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class TeamTaskAllocation extends Component {

    constructor(props) {
        super(props)

        this.state = {
            menu: false,
            selectedTask: null,
            users: null,
            isLoading: false,
        }
    }

    componentDidMount() {
        if (this.props.allUsers) {
            this.setState({
                users: this.props.allUsers
            })
        } else {
            this.getUsers()
        }
    }

    getTask(id) {
        this.setState({
            isLoading: true,
            menu: true,
            selectedTask: null
        })
        Service.get('/bb/api/tender/wbs/tasks/' + id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                Service.get('/bb/api/tender/project/' + res.data.t_wbs_data.pid_data.subproject_pid + '/', {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(project => {
                        res.data.ds = res.data.t_wbs_data.pid_data
                        res.data.wbs = res.data.t_wbs_data
                        res.data.project = project.data
                        // console.log(res.data)
                        this.setState({
                            selectedTask: res.data,
                            isLoading: false,
                        })
                    })
                    .catch(error => {
                        console.log(error)
                        errorMessage(this.props, error)
                    }); 
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    getUsers() {
        Service.get(`/bb/api/user/report/view/team_members/?uid=` + user.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({
                    users: res.data
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }
    // ignore
    userAdded() {
        return null
    }

    render() {
        const toggleDrawer = (event) => {
            this.setState({ menu: false })
        };

        return (
            <div>
                {this.state.users ?
                    <Drawer anchor={'right'} open={this.state.menu} onClose={toggleDrawer}>
                        <div style={{ width: window.innerWidth / 2 + 'px' }}>
                            {this.state.selectedTask ? <div>
                                <p style={{ margin: '15px', color: '#444', fontWeight: 'bold' }}>
                                    {'Task : ' + this.state.selectedTask.name}
                                </p>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Task Date</TableCell>
                                                <TableCell align="left">Start</TableCell>
                                                <TableCell align="left">Finish</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={1}>
                                                <TableCell component="th" scope="row">
                                                    Baseline
                                                </TableCell>
                                                <TableCell align="left">{this.state.selectedTask.from_date ? moment(this.state.selectedTask.from_date).format('DD/MM/YYYY') : '-'}</TableCell>
                                                <TableCell align="left">{this.state.selectedTask.to_date ? moment(this.state.selectedTask.to_date).format('DD/MM/YYYY') : '-'}</TableCell>
                                            </TableRow>
                                            <TableRow key={2}>
                                                <TableCell component="th" scope="row">
                                                    Actual/Expected
                                                </TableCell>
                                                <TableCell align="left">{this.state.selectedTask.t_act_exp_start ? moment(this.state.selectedTask.t_act_exp_start).format('DD/MM/YYYY') : '-'}</TableCell>
                                                <TableCell align="left">{this.state.selectedTask.t_act_exp_finish ? moment(this.state.selectedTask.t_act_exp_finish).format('DD/MM/YYYY') : '-'}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div> : <LoopCircleLoading color={getPrimaryColor()} />}
                            {/* <TaskLevelTabs value={this.value} team={[]} /> */}
                            {/* <TaskLevelDetails task={this.state.selectedTask} allUsers={this.props.allUsers} userAdded={this.userAdded}/> */}
                            {this.state.selectedTask ? <RoleVsResourceToggle task={this.state.selectedTask} allUsers={this.state.users} userAdded={this.userAdded} teamOrProj={this.props.teamOrProj} /> : null}
                        </div>
                    </Drawer>
                    : null}
                {this.state.isLoading ?
                    <LoopCircleLoading color={getPrimaryColor()} />
                    : null}

            </div>
        )
    }
}

export default withSnackbar(TeamTaskAllocation)