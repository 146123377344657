import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@material-ui/core';
import Service from "../../Networking/networkutils";
// import Moment from 'react-moment';
import { getCookie } from '../../Helper/CookieHelper';
import moment from "moment";
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';

const token = getCookie("bb_ts_token");

class TaskHoldDetail extends Component {

    constructor(props) {
        super()

        this.state = {
            data: [],
            isLoading: true,
            openHold: false,
            holdDate1: new Date(),
            holdDate2: new Date(),
            holdData: {},
            openUnholdImmediate: false,
        }
    }

    componentDidMount() {
        this.fetchData(0)
    }

    fetchData(trig) {
        Service.get('/bb/api/tender/tasks/hold/?t_task=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {

                if (trig === 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        if (element.status === 0) {
                            this.setState({
                                holdData: element,
                                openHold: true
                            })
                        }
                    }

                }

                this.setState({
                    data: res.data,
                    isLoading: false
                })
            })
            .catch(error => {

                console.log(error)
                errorMessage(this.props, error)
                // this.fetchOps();
            });
    }

    putData(data, end_status) {
        // alert(end_status)
        var cont = true
        data.task_hold = 0
        if (end_status === 1) {
            // console.log(moment(data.hold_start))
            if (moment(data.hold_start) > moment().subtract(1, 'days')) {
                cont = false
                data.status = 1
                data.task_hold = 0
                data.hold_end = moment().subtract(1, 'days').format()
            } else {
                data.status = 1
                data.task_hold = 0
                data.hold_end = moment().subtract(1, 'days').format()
            }

        } else if (end_status === 2) {
            data.status = 0
            data.task_hold = 1
            data.hold_end = moment().format()
        } else if (end_status === 0) {
            data.status = 0
            data.task_hold = 1
            data.hold_end = this.state.holdDate2
        }
        this.setState({
            openHold: false,
            isLoading: true,
        })
        if (cont) {
            Service.patch('/bb/api/tender/tasks/hold/unhold/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: [data]
            })
                .then(res => {
                    if (end_status === 1) {
                        this.props.triggerRefresh()
                        this.fetchData(1)
                    } else if (end_status === 2) {
                        this.fetchData(1)
                    } else {
                        this.fetchData(1)
                    }
    
                })
                .catch(error => {
    
                    this.fetchData(0)
                    console.log(error)
                    errorMessage(this.props, error)
                    // this.fetchOps();
                });
        } else {
            Service.patch('/bb/api/tender/tasks/hold/unhold/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: [data]
            })
                .then(res => {
                    if (end_status === 1) {
                        this.props.triggerRefresh()
                        // this.fetchData(1)
                    }

                    Service.delete('/bb/api/tender/tasks/hold/' + data.id + '/', {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        
                    })
                        .then(res => {
                            if (end_status === 1) {
                                // this.props.triggerRefresh()
                                this.fetchData(1)
                            } else if (end_status === 2) {
                                this.fetchData(1)
                            } else {
                                this.fetchData(1)
                            }    
        
                        })
                        .catch(error => {
        
                        });
    
                })
                .catch(error => {
    
                    this.fetchData(0)
                    console.log(error)
                    errorMessage(this.props, error)
                    // this.fetchOps();
                });
           
        }
       
    }

    render() {

        const handleHoldClose = () => {
            this.setState({ openHold: false, });
        };

        // const handleHoldDate1Change = (date) => {
        //     this.setState({ holdDate1: date, holdDate2: date })
        // };
        const handleHoldDate2Change = (date) => {
            this.setState({ holdDate2: date })
        };

        const handleHoldImmediateClose = () => {
            this.setState({ openUnholdImmediate: false, });
        };

        return (
            (this.state.isLoading) ? (
                <LoopCircleLoading color={getPrimaryColor()} />
            ) : (
                <div>
                    <MaterialTable
                        title={this.props.value.wbs_name + " - " + this.props.value.name}
                        columns={
                            [
                                { title: 'ID', field: 'id' },
                                { title: 'Start', field: 'hold_start', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, type: 'date' },
                                { title: 'End', field: 'hold_end', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, type: 'date' },
                                { title: 'Comment', field: 'comment', headerStyle: { minWidth: 525 }, cellStyle: { minWidth: 525 } },

                            ]}
                        data={this.state.data}
                        // actions={[
                        //     rowData => ({
                        //         icon: this.status1(rowData),
                        //         tooltip: this.status2(rowData),
                        //     }),
                        // ]}
                        options={{
                            padding: "dense",
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 30],
                        }}
                        actions={[
                            rowData => ({
                                icon: 'edit',
                                tooltip: 'Edit',
                                disabled: rowData.status,
                                onClick: () => { this.setState({ holdData: rowData, openHold: true }) },
                            }),
                        ]}
                    />
                    <Dialog
                        open={this.state.openHold}
                        // fullWidth={true}
                        maxWidth={'xl'}
                        onClose={handleHoldClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent>

                            <Box display="flex" flexDirection="row" p={1} m={1}>
                                <Button color="primary" onClick={() => {
                                    this.setState({ openUnholdImmediate: true })
                                }}>Unhold Immediately</Button>
                            </Box>
                            <Box display="flex" flexDirection="row" p={1} m={1}>
                                <Button color="primary" onClick={() => {
                                    this.putData(this.state.holdData, 2)
                                }}>Unhold Tomorrow</Button>
                            </Box>

                            <Box display="flex" flexDirection="row" p={1} m={1}>
                                <Box fontWeight="fontWeightBold"> Or Select the Date to End the Hold</Box>
                            </Box>

                            <Box display="flex" flexDirection="row" p={1} m={1}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="holdDate2"
                                        label="Hold End Date"
                                        minDate={new Date()}
                                        value={this.state.holdDate2}
                                        onChange={handleHoldDate2Change}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                <Button style={{ marginTop: '5px' }} onClick={() => {
                                    this.putData(this.state.holdData, 0)
                                }} color="primary">
                                    Submit
                    </Button>
                            </Box>


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleHoldClose} color="primary">
                                Close
                    </Button>

                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.openUnholdImmediate}
                        // fullWidth={true}
                        maxWidth={'xl'}
                        onClose={handleHoldImmediateClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Are you sure? You want to unhold immediately?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Choosing Immediate unhold will change the hold end date to yeasterday's date and the tasks will be unholded immediately
                                </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleHoldImmediateClose} color="primary">
                                Close
                                </Button>
                            <Button onClick={() => { this.setState({ openUnholdImmediate: false }); this.putData(this.state.holdData, 1) }} color="primary">
                                Proceed
                                </Button>

                        </DialogActions>
                    </Dialog>

                </div >
            )

        )
    }
}

export default withSnackbar(TaskHoldDetail)