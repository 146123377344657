import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getPrimaryColor, poundFormat } from '../Helper/CommonThings';
import { LoopCircleLoading } from 'react-loadingg';

export default class MCReportView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            changeData: []
        }
    }


    componentDidMount() {
        console.log(this.props.usage)
        if (this.props.usage.length === 0) {
            var data = {
                'est_hours': 0,
                'est_cost': 0,
                'rev_hours': 0,
                'rev_cost': 0,
                'actual': 0,
                'actual_cost': 0,
                'earned_value': 0,
            }
            this.props.usage.push(data)

        }
        var change = []
        for (let i = 0; i < this.props.psr2.ds_data.length; i++) {
            const element = this.props.psr2.ds_data[i];
            if (element.type === 1) {
                change.push(element)
            }
        }

        this.setState({
            isLoading: false,
            changeData: change
        })
    }

    totalCalcPre(key) {
        var total = 0
        for (let i = 0; i < this.props.psr1.ds_data.length; i++) {
            const element = this.props.psr1.ds_data[i];
            total += element[key]
        }
        return total
    }

    usageCalcPre(key) {

        return this.props.usage.length > 0 ? this.props.usage[0][key] : 0
    }

    totalUsageCalcPre(key) {
        var total = 0
        for (let i = 0; i < this.props.psr1.ds_data.length; i++) {
            const element = this.props.psr1.ds_data[i];
            total += element[key]
        }
        return total + (this.props.usage.length > 0 ? this.props.usage[0][key] : 0)
    }

    totalUsageDeltaCalcPre(key) {

        return this.totalUsageCalcPre("rev_cost") - this.totalUsageCalcPre("est_cost")
    }

    totalCalcCurr(key) {
        var total = 0
        for (let i = 0; i < this.props.psr2.ds_data.length; i++) {
            const element = this.props.psr2.ds_data[i];
            total += element[key]
        }
        return total
    }

    totalCalcUapprovedCurr(key) {
        var total = 0
        for (let i = 0; i < this.props.psr2.ds_data.length; i++) {
            const element = this.props.psr2.ds_data[i];
            if (element[key]) {
                total += element[key]
            }

        }
        return total
    }

    totalCalcCurrwithId(key, id) {
        var total = 0
        for (let i = 0; i < this.props.psr2.ds_data.length; i++) {
            const element = this.props.psr2.ds_data[i];
            if (element[key] && element.id === id) {
                total += element[key]
            }

        }
        return total
    }

    totalCalcUapprovedCurrwithId(key, id) {
        var total = 0
        for (let i = 0; i < this.props.psr2.ds_data.length; i++) {
            const element = this.props.psr2.ds_data[i];
            if (element[key] && element.id === id) {
                total += element[key]
            }

        }
        return total
    }

    totalCalcChangeCurr(key) {
        var total = 0
        for (let i = 0; i < this.props.psr2.ds_data.length; i++) {
            const element = this.props.psr2.ds_data[i];
            if (element[key] && element.type === 1) {
                total += element[key]
            }

        }
        return total
    }

    usageCalcCurr(key) {

        return this.props.usage.length > 0 ? this.props.usage[0][key] : 0
    }

    totalUsageCalcCurr(key) {
        var total = 0
        for (let i = 0; i < this.props.psr2.ds_data.length; i++) {
            const element = this.props.psr2.ds_data[i];
            total += element[key]
        }
        return total + (this.props.usage.length > 0 ? this.props.usage[0][key] : 0)
    }

    totalUsageDeltaCalcCurr(key) {

        return this.totalUsageCalcCurr("rev_cost") - this.totalUsageCalcCurr("est_cost")
    }

    render() {
        return (
            <div>
                {!this.state.isLoading ? (
                    <div>
                        <TableContainer component={Paper} style={{ width: 1000 }}>
                            <Table aria-label="simple table" size="small">
                                <TableBody>
                                    <TableRow key={1}>
                                        <TableCell component="th" scope="row">
                                            Original Budget
                                        </TableCell>
                                        <TableCell align="left">{poundFormat(this.totalUsageCalcCurr("est_cost"))}</TableCell>
                                    </TableRow>
                                    <TableRow key={1}>
                                        <TableCell component="th" scope="row">
                                            Design EAC (end of previous period)
                                        </TableCell>
                                        <TableCell align="left">{poundFormat(this.totalUsageCalcCurr("rev_cost"))}</TableCell>
                                    </TableRow>
                                    <TableRow key={1}>
                                        <TableCell component="th" scope="row">
                                            Variance from original budget
                                        </TableCell>
                                        <TableCell align="left">{poundFormat(this.totalUsageCalcCurr("rev_cost") - this.totalUsageCalcCurr("est_cost"))}</TableCell>
                                    </TableRow>
                                    <TableRow key={1}>
                                        <TableCell component="th" scope="row">
                                            Total amount of change
                                        </TableCell>
                                        <TableCell align="left">{poundFormat(this.totalCalcUapprovedCurr("est_cost_unapproved") + this.totalCalcChangeCurr("est_cost"))}</TableCell>
                                    </TableRow>
                                    {this.state.changeData.map((item) => {
                                        return <TableRow key={1}>
                                            <TableCell component="th" scope="row" align="right">
                                                {item.name + " - " + (item.approval_status === 1 ? " (Approved)" : " (Unapproved)")}
                                            </TableCell>
                                            <TableCell align="right">{poundFormat(this.totalCalcUapprovedCurrwithId("est_cost_unapproved", item.id) + this.totalCalcCurrwithId("est_cost", item.id))}</TableCell>
                                        </TableRow>
                                    })}
                                    <TableRow key={1}>
                                        <TableCell component="th" scope="row">
                                            Design spend for the previous period
                                        </TableCell>
                                        <TableCell align="left">{poundFormat(this.totalUsageCalcCurr("actual_cost") - this.totalUsageCalcPre("actual_cost"))}</TableCell>
                                    </TableRow>
                                    <TableRow key={1}>
                                        <TableCell component="th" scope="row">
                                            Design spend to the end of the previous period
                                        </TableCell>
                                        <TableCell align="left">{poundFormat(this.totalUsageCalcCurr("actual_cost"))}</TableCell>
                                    </TableRow>
                                    <TableRow key={1}>
                                        <TableCell component="th" scope="row">
                                            Design spend for this period (Estimate)
                                        </TableCell>
                                        <TableCell align="left">{poundFormat(this.props.current_period_forecast)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                ) : <LoopCircleLoading color={getPrimaryColor()} />}

            </div>
        )
    }
}
