import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { sortByProperty } from '../../Helper/CommonThings';
import { getCookie } from '../../Helper/CookieHelper';
import Service from "../../Networking/networkutils";
import { errorMessage } from '../../Helper/ErrorMessage';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class Tenderers extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             isLoading: true,
             users: [],
             data: [],
        }
    }
    

    componentDidMount() {

        this.fetchOps();

    }

    fetchOps() {
        Service.get('/bb/api/tender/tenderers/?opportunity_id=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(respro => {

                for (let i = 0; i < respro.data.length; i++) {
                    const element = respro.data[i];
                    element.uid_mod = "id" + element.uid
                    
                }

                Service.get("/bb/api/users/", {
                    headers: {
                        Authorization: "Token " + token,
                    },

                })
                    .then(res => {
                        var responceData = res.data.sort(sortByProperty('last_name'))
                        
                        var users = {}
                        users[null] = "None"
                        for (let i = 0; i < responceData.length; i++) {
                            const element = responceData[i];
                            users["id" + element.id] = element.last_name + ", " + element.first_name
                        }
                        // console.log(users)
                        // console.log(respro.data)
                        this.setState({
                            users: users,
                            data: respro.data,
                            isLoading: false,
                        })
                    })
                    .catch(error => {
                        // console.log(error)
                        errorMessage(this.props, error)
                    });

            })
            .catch(error => {
               errorMessage(this.props, error)
            });
    }

    postData(postData) {
        // console.log(postData.uid_mod.split('d')[1])
        if ( !postData.uid_mod || postData.uid_mod === '' || postData.uid_mod === undefined) {
            this.props.enqueueSnackbar('Fill EM type and EM!', {
                variant: 'warning'
            });
        } else {
            postData.uid = parseInt(postData.uid_mod.split('d')[1])
        
            postData.created_by = user.id;
            postData.opportunity_id = this.props.value.id
           
            Service.post('/bb/api/tender/tenderers/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData
            })
            .then(res => {
                this.fetchOps();
                this.props.enqueueSnackbar('Data saved successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                this.fetchOps();
               errorMessage(this.props, error)
            });
        }
        
    }

    putData(putData) {
        
        putData.uid = parseInt(putData.uid_mod.split('d')[1])
       
        Service.put('/bb/api/tender/tenderers/'+putData.id+'/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: putData
        })
        .then(res => {
            this.props.enqueueSnackbar('Data saved successfully!', {
                variant: 'success'
            });
        })
        .catch(error => {
            console.log(error)
            this.fetchOps();
            errorMessage(this.props, error)
        });
    }

    deleteData(putData) {
        // putData.uid = parseInt(putData.uid_mod.split('d')[1])
        // putData.frwd_uid = parseInt(putData.frwd_uid_mod.split('d')[1])
        Service.delete('/bb/api/tender/tenderers/'+putData.id+'/', {
            headers: {
                Authorization: "Token " + token,
            },
            
        })
        .then(res => {
            this.props.enqueueSnackbar('Data deleted successfully!', {
                variant: 'success'
            });
        })
        .catch(error => {
            console.log(error)
            this.fetchOps();
            errorMessage(this.props, error)
        });
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title={(this.props.value.opportunity) + " Tenderers"}
                    columns={[

                        {
                            title: 'Tenderer', field: 'uid_mod',
                            lookup: this.state.users

                        },
                       
                    ]}
                    isLoading={this.state.isLoading}
                    data={this.state.data}
                    options={{
                        padding: "dense",
                    }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (!newData.uid_mod || newData.uid_mod === '' || newData.uid_mod === undefined) {
                                        this.props.enqueueSnackbar('Fill EM type and EM!', {
                                            variant: 'warning'
                                        });
                                    } else {
                                        this.setState({ save: false });
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            newData.roles = [];
                                            data.push(newData);
                                            this.postData(newData);
                                            return { ...prevState, data };
                                        });
                                    }

                                }, 60);
                            }),
                        // onRowUpdate: (newData, oldData) =>
                        //     new Promise(resolve => {
                        //         setTimeout(() => {
                        //             resolve();
                        //             if (oldData) {
                        //                 this.setState({ save: false });
                        //                 this.setState(prevState => {
                        //                     const data = [...prevState.data];
                        //                     data[data.indexOf(oldData)] = newData;
                        //                     this.putData(newData);
                        //                     return { ...prevState, data };
                        //                 });
                        //             }
                        //         }, 60);

                        //     }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState({ save: false });
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        this.deleteData(oldData)
                                        return { ...prevState, data };
                                    });
                                }, 60);
                            }),
                    }}
                />

            </div>
        )
    }
}

export default withSnackbar(Tenderers)