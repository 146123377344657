export function screenSizeCheck() {
    if (window.innerWidth <= 1650) {
        // alert(window.innerWidth)
        // 1536
        // 1366
        //low <=1366
        //medium <=1650
        //high > 1650
        return true
    } else {
        // alert(window.innerWidth)
        return false
    }
}

export function profileSize() {
    if (window.innerWidth <= 1366) {
        return 3.4
    } else if (window.innerWidth <= 1650) {
        return 3.2
    } else {
        return 3.4
    }
}

export function teamInfoSize() {
    if (window.innerWidth <= 1366) {
        return 2.5
    } else if (window.innerWidth <= 1650) {
        return 2.6
    } else {
        return 3
    }
}

export function taskInfoSize() {
    if (window.innerWidth <= 1366) {
        return 2.9
    } else if (window.innerWidth <= 1650) {
        return 2.8
    } else {
        return 3
    }
}

export function taskChartSize() {
    if (window.innerWidth <= 1366) {
        return 3.1
    } else if (window.innerWidth <= 1650) {
        return 3.3
    } else {
        return 2.5
    }
}

export function teamTeamInfoSize() {
    if (window.innerWidth <= 1366) {
        return 3.3
    } else if (window.innerWidth <= 1650) {
        return 3.3
    } else {
        return 3.7
    }
}

export function teamTaskInfoSize() {
    if (window.innerWidth <= 1366) {
        return 4.3
    } else if (window.innerWidth <= 1650) {
        return 4.3
    } else {
        return 4.3
    }
}

export function teamTaskChartSize() {
    if (window.innerWidth <= 1366) {
        return 4.3
    } else if (window.innerWidth <= 1650) {
        return 4.2
    } else {
        return 3.9
    }
}

export function isMobile() {
    if (window.innerWidth <= 600) {
        return true
    } else {
        return false
    }
}