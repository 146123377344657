import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import { Box } from '@material-ui/core';
import { getDarkMode, getDiscipline } from '../../Helper/CommonThings';

var options = {
    series: [{
        name: 'Inflation',
        data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
    }],
    chart: {
        height: 350,
        type: 'bar',
    },
    dataLabels: {
        enabled: true,
        formatter: function (value) {
            return value
        },
        offsetY: -20,
        style: {
            fontSize: '12px',
            colors: [getDarkMode() === 'dark' ? "#F5F5F5" : "#707070"]
        }
    },
    plotOptions: {
        bar: {
            borderRadius: 10,
            columnWidth: '55%',
            endingShape: 'rounded',
            dataLabels: {
                position: 'top', // top, center, bottom
            },
        }
    },

    stroke: {
        show: true,
        width: 6,
        colors: ['transparent']
    },
    xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: 'bottom',
        axisBorder: {
            show: true
        },
        axisTicks: {
            show: true
        },
        crosshairs: {
            fill: {
                type: 'gradient',
                gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                }
            }
        },
        tooltip: {
            enabled: true,
        },
        labels: {
            style: {
                colors: getDarkMode() === 'dark' ? "#F5F5F5" : "#707070"
            },
        }
    },
    yaxis: {
        axisBorder: {
            show: true,
        },
        axisTicks: {
            show: true,
        },
        labels: {
            show: true,
            formatter: function (val) {
                return val;
            },
            style: {
                colors: getDarkMode() === 'dark' ? "#F5F5F5" : "#707070"
            },
        },
        title: {
            text: 'Numbers',
            style: {
                color: getDarkMode() === 'dark' ? "#F5F5F5" : "#707070"
            }
        }
    },
    title: {
        text: 'No of Unapproved change orders',
        show: true,
        align: 'center',
        style: {
            color: getDarkMode() === 'dark' ? "#F5F5F5" : "#707070"
        }
    },
    tooltip: {
        theme: getDarkMode() === 'dark' ? 'dark' : 'light',
    },
    legend: {
        labels: {
            colors: getDarkMode() === 'dark' ? 'dark' : 'light',
        },
    }
};

class CMRiskGraph extends Component {

    constructor(props) {
        super(props)

        this.state = {
            options: this.prepGraphData('discipline'),
            chartType: 'discipline'
        }
    }

    prepGraphData(value) {
        var value_data = []
        var axis_data = []
        options.title.text = 'No of Unapproved change orders'
        options.yaxis.title.text = 'Numbers'
        if (value === 'em_number') {
            for (let i = 0; i < this.props.value.pdm_data.length; i++) {
                const element = this.props.value.pdm_data[i];
                value_data.push(element.no_of_ds)
                axis_data.push(element.last_name + "," + element.first_name)
            }
            options.series = [{ data: [] }]
            options.series[0].data = value_data
        } else if (value === 'em_value') {
            options.title.text = 'Unapproved change order by Value'
            options.yaxis.title.text = 'Value (£)'
            for (let i = 0; i < this.props.value.pdm_data.length; i++) {
                const element = this.props.value.pdm_data[i];
                value_data.push(element.unapproved_cost)
                axis_data.push(element.last_name + "," + element.first_name)
            }
            options.series = [{ data: [] }]
            options.series[0].data = value_data
        } else if (value === "discipline") {
            for (let i = 0; i < this.props.value.dicipline_data.length; i++) {
                const element = this.props.value.dicipline_data[i];
                if (value_data.length === 0) {
                    value_data.push({
                        "name": element["t-2_date"],
                        "data": [element["t-2"]]
                    })
                    value_data.push({
                        "name": element["t-1_date"],
                        "data": [element["t-1"]]
                    })
                    value_data.push({
                        "name": element.t_date,
                        "data": [element.t]
                    })
                } else {
                    value_data[0].data.push(element["t-2"])
                    value_data[1].data.push(element["t-1"])
                    value_data[2].data.push(element.t)
                }

                axis_data.push(getDiscipline()[element.decipline])
            }
            options.series = value_data
        } else if (value === "last24") {
            for (let i = 0; i < this.props.value.last_24_week_count.length; i++) {
                const element = this.props.value.last_24_week_count[i];
                value_data.push(element.count)
                axis_data.push(element.date_str)
            }
            options.series = [{ data: [] }]
            options.series[0].data = value_data
        }
        // options.series = value_data
        options.xaxis.categories = axis_data
        return JSON.parse(JSON.stringify(options))
    }

    render() {

        const handleChange = (event) => {
            this.setState({ chartType: event.target.value, options: this.prepGraphData(event.target.value) });
        };

        return (
            <Card style={{ padding: '15px' }}>
                <Box marginBottom='5px' minWidth={200} >
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id="demo-simple-select-label">Chart Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.chartType}
                            label="Chart Type"
                            onChange={handleChange}

                        >
                            <MenuItem value={'em_number'}>Data based on Engineering Manager (by Numbers)</MenuItem>
                            <MenuItem value={'em_value'}>Data based on Engineering Manager (by Value)</MenuItem>
                            <MenuItem value={'discipline'}>Data based on Disciplines</MenuItem>
                            <MenuItem value={'last24'}>Past 24 weeks comparison</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <ReactApexChart options={this.state.options} series={this.state.options.series} type='bar' height={600} width={'100%'} />
            </Card >
        );
    }
}

export default CMRiskGraph;