import { Box, Button } from '@material-ui/core';
import React, { Component } from 'react';
import { getDarkMode, getPrimaryColor } from '../Helper/CommonThings';
import { LoopCircleLoading } from 'react-loadingg';
import { withSnackbar } from 'notistack';
import { getCookie } from '../Helper/CookieHelper';
import { MenuItem, TextField } from '@mui/material';
import Service from '../Networking/networkutils';
import { errorMessage } from '../Helper/ErrorMessage';
import moment from 'moment';
import MaterialTable from 'material-table';

var token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

var slHrsColumn = [
    {
        day: 1,
        hours: 8.5
    },
    {
        day: 2,
        hours: 8.5
    },
    {
        day: 3,
        hours: 8.5
    },
    {
        day: 4,
        hours: 8.5
    },
    {
        day: 5,
        hours: 8.5
    },
]

var indHrsColumn = [
    {
        day: 1,
        hours: 8
    },
    {
        day: 2,
        hours: 8
    },
    {
        day: 3,
        hours: 8
    },
    {
        day: 4,
        hours: 8
    },
    {
        day: 5,
        hours: 8
    },
]

var UKHrsColumn = [
    {
        day: 1,
        hours: 7.5
    },
    {
        day: 2,
        hours: 7.5
    },
    {
        day: 3,
        hours: 7.5
    },
    {
        day: 4,
        hours: 7.5
    },
    {
        day: 5,
        hours: 7.5
    },
]

class UserRequestForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            postData: {
                first_name: '',
                last_name: '',
                email: '',
                office_loc: '',
                join_date: null,
                designation: '',
                grade: '',
                rate: null,
                internal_emp_id: null,
                r12_emp_code: null,
                r12_class_code: null,
                team_name: [],
                windows_user_name: '',
                company: null,
            },
            isLoading: true,
            teamLookup: {},
            disabled: false,
            data: [],
            loading: false,
            team: [],
            roles: [],
            emailExists: false,
            companies: [],
            companyLookup: {}
        }
    }
    componentDidMount() {
        this.fetchCompany();
        this.setState({ isLoading: true })
        Service.get(`/bb/api/teams/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var teamLookup = {}

                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    teamLookup[element.id] = element.name
                }
                this.setState({
                    teamLookup,
                }, () => {
                    if (!this.props.editData || !this.props.approveDialog) {
                        this.setState({ isLoading: false })
                    }
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
        if (this.props.editData) {
            this.setState({ emailExists: false, postData: JSON.parse(JSON.stringify(this.props.editData)), team: JSON.parse(JSON.stringify(this.props.editData.team_name)), data: JSON.parse(JSON.stringify(this.props.editData.hours)), isLoading: false })
        } else if (this.props.approveDialog) {
            this.setState({ emailExists: false, postData: JSON.parse(JSON.stringify(this.props.approveDialog)), team: JSON.parse(JSON.stringify(this.props.approveDialog.team_name)), data: JSON.parse(JSON.stringify(this.props.approveDialog.hours)), isLoading: false })
        }
    }

    fetchCompany() {
        Service.get('/bb/api/company/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var companyLookup = {}
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    companyLookup[element.id] = element.name
                }
                this.setState({ companies: res.data, companyLookup: companyLookup })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }


    postData() {
        this.setState({
            disabled: true
        })

        if (this.state.emailExists) {
            this.props.enqueueSnackbar('Email already exists!', {
                variant: 'warning'
            })
            this.setState({
                disabled: false
            })
            return null
        }

        if (!this.state.postData.first_name || !this.state.postData.last_name || !this.state.postData.email ||
            (this.state.postData.office_loc === '') || !this.state.postData.join_date ||
            this.state.team.length === 0 || !this.state.postData.windows_user_name || !this.state.postData.grade || !this.state.postData.company) {
            this.props.enqueueSnackbar('Please fill all mandatory fields', {
                variant: 'warning'
            })
            this.setState({
                disabled: false
            })
            return null
        }
        if (!this.state.postData.email.includes('@balfourbeatty.com')) {
            this.props.enqueueSnackbar('Only BalfourBeatty mail is allowed', {
                variant: 'warning'
            })
            this.setState({
                disabled: false
            })
            return null
        }

        if (this.state.data.length === 0) {
            this.props.enqueueSnackbar('Hours must be compulsory', {
                variant: 'warning'
            })
            this.setState({
                disabled: false
            })
            return null
        }

        var postData = this.state.postData
        postData['hours'] = this.state.data
        postData['team_name'] = this.state.team
        this.setState({ postData, disabled: true, isLoading: true })
        Service.post(`/bb/api/user/registration/request/create_new_user_data/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: postData
        })
            .then(res => {
                this.setState({
                    postData: {
                        first_name: '',
                        last_name: '',
                        email: '',
                        office_loc: '',
                        join_date: null,
                        designation: '',
                        grade: '',
                        rate: '',
                        internal_emp_id: null,
                        r12_emp_code: null,
                        r12_class_code: null,
                        team_name: [],
                        windows_user_name: '',
                        company: null,
                    },
                    disabled: false,
                    isLoading: false,
                    data: [],
                    open: false,
                    team: [],
                    emailExists: false
                })
                this.props.enqueueSnackbar('User requested successfully!', {
                    variant: 'success'
                });
                this.setState({
                    disabled: false
                })
                if (this.props.dialogClose) {
                    this.props.dialogClose()
                }
            })
            .catch(error => {
                if (error && error.status === 406) {
                    this.props.enqueueSnackbar(error.data.message, {
                        variant: 'warning'
                    });
                    this.setState({ isLoading: false, disabled: false, })
                    console.log(error)
                } else {
                    this.setState({ isLoading: false, disabled: false, })
                    console.log(error)
                    errorMessage(this.props, error)
                }
            });
    }

    editData() {
        this.setState({
            disabled: true
        })

        if (this.state.emailExists) {
            this.props.enqueueSnackbar('Email already exists!', {
                variant: 'warning'
            })
            this.setState({
                disabled: false
            })
            return null
        }
        if (!this.state.postData.first_name || !this.state.postData.last_name || !this.state.postData.email ||
            (this.state.postData.office_loc === '') || !this.state.postData.join_date ||
            this.state.team.length === 0 || !this.state.postData.windows_user_name || !this.state.postData.grade || !this.state.postData.designation || !this.state.postData.company) {
            this.props.enqueueSnackbar('Please fill all mandatory fields', {
                variant: 'warning'
            })
            this.setState({
                disabled: false
            })
            return null
        }
        if (!this.state.postData.email.includes('@balfourbeatty.com')) {
            this.props.enqueueSnackbar('Only BalfourBeatty mail is allowed', {
                variant: 'warning'
            })
            this.setState({
                disabled: false
            })
            return null
        }

        if (this.state.data.length === 0) {
            this.props.enqueueSnackbar('Hours must be compulsory', {
                variant: 'warning'
            })
            this.setState({
                disabled: false
            })
            return null
        }

        var postData = this.state.postData
        postData['hours'] = this.state.data
        postData['team_name'] = this.state.team
        this.setState({ postData, disabled: true, isLoading: true })
        Service.put(`/bb/api/user/registration/request/update_new_user_details/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: postData
        })
            .then(res => {
                this.setState({
                    postData: {
                        first_name: '',
                        last_name: '',
                        email: '',
                        office_loc: '',
                        join_date: null,
                        designation: '',
                        grade: '',
                        rate: '',
                        internal_emp_id: null,
                        r12_emp_code: null,
                        r12_class_code: null,
                        team_name: [],
                        windows_user_name: '',
                        company: null,
                    },
                    disabled: false,
                    isLoading: false,
                    data: [],
                    open: false,
                    team: [],
                    emailExists: false
                })
                this.props.enqueueSnackbar('Edited successfully!', {
                    variant: 'success'
                });
                this.setState({
                    disabled: false
                })
                if (this.props.reload) {
                    this.props.reload()
                }
            })
            .catch(error => {
                if (error && error.status === 406) {
                    this.setState({ isLoading: false, disabled: false, })
                    console.log(error)
                    this.props.enqueueSnackbar(error.data.message, {
                        variant: 'warning'
                    });
                } else {
                    this.setState({ isLoading: false, disabled: false, })
                    console.log(error)
                    errorMessage(this.props, error)
                }
            });
    }

    dayExist(postData) {
        var goon = true;
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if (parseInt(element.day) === parseInt(postData.day)) {
                goon = false;
                this.props.enqueueSnackbar('Data with same day already exist!', {
                    variant: 'warning'
                });
                break;
            }
        }
        return goon
    }

    teamExist(postData) {
        var goon = true;
        for (let i = 0; i < this.state.team.length; i++) {
            const element = this.state.team[i];
            if (element.team === postData.team) {
                goon = false;
                this.props.enqueueSnackbar('Data with same team already exist!', {
                    variant: 'warning'
                });
                break;
            }
        }
        return goon
    }

    emailExistsCheck(event) {
        Service.get(`/bb/api/user/registration/request/user_exist_check/?email=${event.target.value}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ emailExists: false })
            })
            .catch(error => {
                if (error && error.status === 406) {
                    this.props.enqueueSnackbar('Email already exists!', {
                        variant: 'warning'
                    });
                    var postData = this.state.postData
                    postData[event.target.id] = event.target.value
                    this.setState({ postData, emailExists: true })
                } else {
                    console.log(error)
                    errorMessage(this.props, error)
                }
            });
    }

    handleEmail(event) {
        var postData = this.state.postData
        if (event.target.value === "") {
            postData[event.target.id] = null
            this.setState({ postData, emailExists: false })
        } else if (event.target.value.includes('@balfourbeatty.com')) {
            postData[event.target.id] = event.target.value
            this.setState({ postData })
            this.emailExistsCheck(event)
        } else {
            postData[event.target.id] = event.target.value
            this.setState({ postData, emailExists: false })
        }
    }

    render() {

        const handleChange = (event, id) => {
            var postData = this.state.postData
            postData[id] = event.target.value
            this.setState({ postData }, () => {
                if (id === 'office_loc') {
                    if (parseInt(event.target.value) === 0) {
                        this.setState({ data: UKHrsColumn })
                    } else if (parseInt(event.target.value) === 1) {
                        this.setState({ data: indHrsColumn })
                    } else if (parseInt(event.target.value) === 2) {
                        this.setState({ data: slHrsColumn })
                    }
                }
            })
        };

        const handleDateChange = (event) => {
            var postData = this.state.postData
            postData['join_date'] = event.target.value ? moment(event.target.value).format('YYYY-MM-DD') : null
            this.setState({ postData })
        };

        const handleTextChange = (event) => {
            var postData = this.state.postData
            if (event.target.value === "") {
                postData[event.target.id] = null
                this.setState({ postData })
            } else {
                postData[event.target.id] = event.target.value
                this.setState({ postData })
            }
        };

        return (
            <div>
                {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                    <Box display={'flex'} flexDirection={'column'} >
                        <Box marginTop={'5px'}>
                            <TextField
                                disabled={this.props.approveDialog}
                                helperText={<Box color={getDarkMode() ? '#ff4d4d' : '#cc0000'}>*mandatory</Box>}
                                autoComplete={'false'}
                                onChange={handleTextChange}
                                value={this.state.postData.first_name}
                                id="first_name"
                                label="First Name" variant="outlined" fullWidth />
                        </Box>
                        <Box marginTop={'5px'}>
                            <TextField
                                disabled={this.props.approveDialog}
                                helperText={<Box color={getDarkMode() ? '#ff4d4d' : '#cc0000'}>*mandatory</Box>}
                                autoComplete={'false'}
                                onChange={handleTextChange}
                                value={this.state.postData.last_name}
                                id="last_name"
                                label="Last Name" variant="outlined" fullWidth />
                        </Box>
                        <Box marginTop={'5px'}>
                            <TextField
                                disabled={this.props.approveDialog}
                                helperText={<Box color={getDarkMode() ? '#ff4d4d' : '#cc0000'}>*mandatory</Box>}
                                autoComplete={'false'}
                                onChange={(e) => this.handleEmail(e)}
                                value={this.state.postData.email}
                                id="email"
                                label="Email" variant="outlined" fullWidth />
                        </Box>

                        <Box marginTop={'5px'}>
                            <TextField
                                disabled={this.props.approveDialog}
                                fullWidth
                                select
                                labelId={"office_loc"}
                                id={'office_loc'}
                                value={this.state.postData.office_loc}
                                label={"Office Location"}
                                onChange={(e) => {
                                    handleChange(e, 'office_loc')
                                }}
                                helperText={<Box color={getDarkMode() ? '#ff4d4d' : '#cc0000'}>*mandatory</Box>} >
                                <MenuItem value={1}>
                                    India
                                </MenuItem>
                                <MenuItem value={0}>
                                    UK
                                </MenuItem>
                                <MenuItem value={2}>
                                    SL
                                </MenuItem>
                            </TextField>
                        </Box>

                        <Box marginTop={'5px'}>
                            <TextField
                                disabled={this.props.approveDialog}
                                fullWidth
                                select
                                labelId={"company"}
                                id={'company'}
                                value={this.state.postData.company}
                                label={"Select SBU"}
                                onChange={(e) => {
                                    handleChange(e, 'company')
                                }}
                                helperText={<Box color={getDarkMode() ? '#ff4d4d' : '#cc0000'}>*mandatory</Box>} >
                                {this.state.companies.map((element, index) =>
                                    <MenuItem key={index} value={element.id}>
                                        {element.name}
                                    </MenuItem>
                                )}
                            </TextField>
                        </Box>
                        <Box marginTop={'5px'}>
                            <TextField
                                id={'join_date'}
                                label={'Join Date'}
                                format='dd/mm/yyyy'
                                value={this.state.postData.join_date}
                                helperText={<Box color={getDarkMode() ? '#ff4d4d' : '#cc0000'}>*mandatory</Box>}
                                onChange={(newValue) => handleDateChange(newValue)}
                                type="date"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={this.props.approveDialog}
                            />
                        </Box>
                        <Box marginTop={'5px'}>
                            <TextField
                                disabled={this.props.approveDialog}
                                helperText={<Box color={getDarkMode() ? '#ff4d4d' : '#cc0000'}>*mandatory</Box>}
                                onChange={handleTextChange}
                                value={this.state.postData.designation}
                                id="designation"
                                label="Designation/Role" variant="outlined" fullWidth />
                        </Box>
                        <Box marginTop={'5px'}>
                            <TextField
                                disabled={this.props.approveDialog}
                                fullWidth
                                select
                                labelId={"grade"}
                                id={'grade'}
                                value={this.state.postData.grade}
                                label={"Charge Out Grade"}
                                onChange={(e) => {
                                    handleChange(e, 'grade')
                                }}
                                helperText={<Box color={getDarkMode() ? '#ff4d4d' : '#cc0000'}>*mandatory</Box>} >
                                <MenuItem value={'a'}>
                                    A
                                </MenuItem>
                                <MenuItem value={'b'}>
                                    B
                                </MenuItem>
                                <MenuItem value={'c'}>
                                    C
                                </MenuItem>
                                <MenuItem value={'d'}>
                                    D
                                </MenuItem>
                                <MenuItem value={'e'}>
                                    E
                                </MenuItem>
                                <MenuItem value={'f'}>
                                    F
                                </MenuItem>
                                <MenuItem value={'g'}>
                                    G
                                </MenuItem>
                            </TextField>
                        </Box>

                        <Box marginTop={'5px'}>
                            <TextField
                                disabled={this.props.approveDialog}
                                // helperText={'* Mandatory'}
                                onChange={handleTextChange}
                                value={this.state.postData.rate}
                                type='number'
                                id="rate"
                                label="Charge Out Rate" variant="outlined" fullWidth />
                        </Box>
                        <Box marginTop={'5px'}>
                            <TextField
                                disabled={this.props.approveDialog}
                                // helperText={'* Mandatory'}
                                onChange={handleTextChange}
                                value={this.state.postData.internal_emp_id}
                                type='number'
                                id="internal_emp_id"
                                label="Internal Emp ID" variant="outlined" fullWidth />
                        </Box>
                        <Box marginTop={'5px'}>
                            <TextField
                                disabled={this.props.approveDialog}
                                // helperText={'* Mandatory'}
                                onChange={handleTextChange}
                                value={this.state.postData.r12_emp_code}
                                id="r12_emp_code"
                                label="R12 Emp Code" variant="outlined" fullWidth />
                        </Box>
                        <Box marginTop={'5px'}>
                            <TextField
                                disabled={this.props.approveDialog}
                                // helperText={'* Mandatory'}
                                onChange={handleTextChange}
                                value={this.state.postData.r12_class_code}
                                id="r12_class_code"
                                label="R12 Class code" variant="outlined" fullWidth />
                        </Box>

                        <Box marginTop={'5px'}>
                            <MaterialTable
                                title={
                                    <Box display={'flex'} flexDirection={'row'}>
                                        <Box fontSize={'16px'} marginRight={'5px'}>Hours</Box>
                                        <Box color={getDarkMode() ? '#ff4d4d' : '#cc0000'}>*mandatory</Box>
                                    </Box>
                                }
                                columns={[
                                    {
                                        title: 'Day', field: 'day',
                                        lookup: { 0: 'Sunday', 1: 'Monday', 2: 'Tuesday', 3: 'Wednesday', 4: 'Thursday', 5: 'Friday', 6: 'Saturday' },
                                    },
                                    {
                                        title: 'Hours', field: 'hours', type: 'numeric'
                                    },
                                ]}
                                data={this.state.data}
                                isLoading={this.state.isLoading}
                                options={{
                                    padding: "dense",
                                    search: false,
                                    paging: false
                                }}
                                editable={(this.props.approveDialog) ? null : ({
                                    onRowAdd: newData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (!newData.hours || newData.hours < 0) {
                                                    this.props.enqueueSnackbar('Hours must be greater than 0!', {
                                                        variant: 'warning'
                                                    });
                                                    return null
                                                }
                                                if (this.dayExist(newData)) {
                                                    this.setState({ save: false });
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data.push(newData);
                                                        return { ...prevState, data };
                                                    });
                                                }
                                            }, 60);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (newData.hours < 0) {
                                                    this.props.enqueueSnackbar('Hours must be greater than 0!', {
                                                        variant: 'warning'
                                                    });
                                                    return null
                                                }
                                                if (oldData) {
                                                    this.setState({ save: false });
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        return { ...prevState, data };
                                                    });
                                                }
                                            }, 60);

                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState({ save: false });
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });
                                            }, 60);
                                        }),
                                })}
                            />
                        </Box>
                        <Box marginTop={'10px'}>
                            <MaterialTable
                                title={
                                    <Box display={'flex'} flexDirection={'row'}>
                                        <Box fontSize={'16px'} marginRight={'5px'}>Teams</Box>
                                        <Box color={getDarkMode() ? '#ff4d4d' : '#cc0000'}>*mandatory</Box>
                                    </Box>
                                }
                                columns={[
                                    {
                                        title: 'Team', field: 'team',
                                        lookup: this.state.teamLookup,
                                    },
                                    {
                                        title: 'Role in Team', field: 'role_in_team',
                                        lookup: { 0: 'Member', 1: 'Delegated', 2: 'Lead', 3: 'Controls' }
                                    },
                                ]}
                                data={this.state.team}
                                isLoading={this.state.isLoading}
                                options={{
                                    padding: "dense",
                                    search: false,
                                    paging: false
                                }}
                                editable={(this.props.approveDialog) ? null : ({
                                    onRowAdd: newData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (!newData.team || (!newData.hasOwnProperty('role_in_team'))) {
                                                    this.props.enqueueSnackbar('Both Team and Role in Team are mandatory!', {
                                                        variant: 'warning'
                                                    });
                                                    return null
                                                }
                                                if (this.teamExist(newData)) {
                                                    this.setState({ save: false });
                                                    this.setState(prevState => {
                                                        const team = [...prevState.team];
                                                        team.push(newData);
                                                        return { ...prevState, team };
                                                    });
                                                }
                                            }, 60);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    this.setState({ save: false });
                                                    this.setState(prevState => {
                                                        const team = [...prevState.team];
                                                        team[team.indexOf(oldData)] = newData;
                                                        return { ...prevState, team };
                                                    });
                                                }
                                            }, 60);

                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState({ save: false });
                                                this.setState(prevState => {
                                                    const team = [...prevState.team];
                                                    team.splice(team.indexOf(oldData), 1);
                                                    return { ...prevState, team };
                                                });
                                            }, 60);
                                        }),
                                })}
                            />
                        </Box>
                        <Box marginTop={'10px'}>
                            <TextField
                                disabled={this.props.approveDialog}
                                helperText={<Box color={getDarkMode() ? '#ff4d4d' : '#cc0000'}>*mandatory</Box>}
                                onChange={handleTextChange}
                                value={this.state.postData.windows_user_name}
                                id="windows_user_name"
                                label="Windows User Name (Computer Login User Name)" variant="outlined" fullWidth />
                        </Box>
                        {this.props.approveDialog ? null :
                            <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} marginTop={'5px'} width={'100%'}>
                                <Button color='primary' variant='contained' disabled={this.state.disabled} onClick={() => {
                                    if (this.props.editData) {
                                        this.editData()
                                    } else {
                                        this.postData()
                                    }
                                }}>{this.props.editData ? "Edit" : "Request User"}</Button>
                            </Box>}
                    </Box>
                }
            </div>
        );
    }
}

export default withSnackbar(UserRequestForm);