import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getCookie } from '../../Helper/CookieHelper';
import Service from "../../Networking/networkutils";
import { errorMessage } from '../../Helper/ErrorMessage';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class SecondaryRoles extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             data: [],
             isLoading: true,
             rolesCust : {},
             roleAll: [],
        }
    }

    componentDidMount() {
        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                var response = res.data;
                var data12 = { null: 'Not Available' };
                for (let i = 0; i < response.length; i++) {
                    const element = response[i];
                    data12[element.id] = element.name;
                }
                this.setState({ rolesCust: data12, roleAll: res.data, })

                this.fetchOps();
                
            })
            .catch(error => {
               errorMessage(this.props, error)
            });
    }

    fetchOps() {
        this.setState({ isLoading: true })
        Service.get('/bb/api/secondary/roles/?uid='+this.props.selectedUser.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
               
                this.setState({ isLoading: false, data: res.data })
                
            })
            .catch(error => {
               errorMessage(this.props, error)
            });
    }

    postData(postData) {
        postData.created_by = user.email;
        postData.uid = this.props.selectedUser.id
        Service.post('/bb/api/secondary/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: postData
        })
        .then(res => {
            this.fetchOps();
            this.props.enqueueSnackbar('Data saved successfully!', {
                variant: 'success'
            });
        })
        .catch(error => {
            console.log(error)
            this.fetchOps();
            errorMessage(this.props, error)
        });
    }

    putData(putData) {

        Service.put('/bb/api/secondary/roles/'+putData.id+'/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: putData
        })
        .then(res => {
            this.props.enqueueSnackbar('Data saved successfully!', {
                variant: 'success'
            });
        })
        .catch(error => {
            console.log(error)
            this.fetchOps();
            errorMessage(this.props, error)
        });
    }

    deleteData(putData) {
        Service.delete('/bb/api/secondary/roles/'+putData.id+'/', {
            headers: {
                Authorization: "Token " + token,
            },
            
        })
        .then(res => {
            this.props.enqueueSnackbar('Data deleted successfully!', {
                variant: 'success'
            });
        })
        .catch(error => {
            console.log(error)
            this.fetchOps();
            errorMessage(this.props, error)
        });
    }
    

    render() {
        return (
            <div>
                <MaterialTable
                    title={this.props.selectedUser.last_name + ", " + this.props.selectedUser.first_name}
                    columns={[

                        {
                            title: 'Secondary Role', field: 'role',
                            lookup: this.state.rolesCust,
                        },

                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        padding: "dense",
                    }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState({ save: false });
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        newData.roles = [];
                                        data.push(newData);
                                        this.postData(newData);
                                        return { ...prevState, data };
                                    });
                                }, 60);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        this.setState({ save: false });
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            this.putData(newData);
                                            return { ...prevState, data };
                                        });
                                    }
                                }, 60);
                                
                            }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState({ save: false });
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        this.deleteData(oldData)
                                        return { ...prevState, data };
                                    });
                                }, 60);
                            }),
                    }}
                />
            </div>
        )
    }
}

export default withSnackbar(SecondaryRoles)