import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import UserHoursRequest from '../HoursRequest/UserHoursRequest';
import TaskUsers from './TaskUsers';

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

class UserTaskTable extends Component {

    constructor(props) {
        super()

        this.state = {
            isLoading: true,
            data: [],
            dialogData: null,
            open: false,
            projectLookUp: null,
            openRequest: false
        }
    }

    componentDidMount() {
        let data = this.props.data

        if (data.length === 0) {
            this.setState({ isLoading: false });
            return;
        }
        let viewPid = []
        try {
            for (let i = 0; i < data.length; i++) {
                viewPid.push({ 'com_ttid': data[i].id, 'com_uid': this.props.uid })
            }
            // console.log(viewPid)
        }
        catch (e) { console.log(e) }

        if (viewPid.length !== 0) {
            Service.patch("/bb/api/team/tasks/get_acthours/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: viewPid,
            })
                .then(res12 => {

                    for (let j = 0; j < res12.data.length; j++) {
                        const element13 = res12.data[j];
                        for (let i = 0; i < data.length; i++) {
                            const element12 = data[i];
                            if (element12.id === element13.id) {
                                element12.actual_hours = timeToDecimal(element13.hours_actual)
                                element12.inprogress_hours = timeToDecimal(element13.hours_ongoing)
                                element12.total_alloc_hours = element13.allocated
                                element12.actual_and_inprogress = (element12.actual_hours + element12.inprogress_hours) + "/" + element12.total_alloc_hours
                            }
                        }
                    }
                    data.sort(function (a, b) {
                        return new Date(a.act_exp_start) - new Date(b.act_exp_start);
                    });
                    this.setState({ data, isLoading: false })

                })
                .catch(error => {
                    this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                        variant: 'error'
                    });

                });
        }

        // this.setState({ data: array, projectLookUp: this.props.projectLookUp }, () => this.setState({ isLoading: false }))
    }

    render() {
        const handleClose = () => {
            this.setState({ open: false, })
        };
        const handleRequestClose = () => {
            this.setState({ openRequest: false, })
        };
        return (
            <div>
                <MaterialTable
                    title={this.props.viewAll ? 'All Tasks' : this.props.status}
                    columns={this.props.viewAll ? [
                        {
                            title: 'Project', field: 'project.name',
                            // lookup: this.state.projectLookUp,
                            // render: rowData => this.state.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },
                        { title: 'Deliverables Schedule', field: 'ds.name', },
                        { title: 'WBS', field: 'wbs.name', },
                        { title: 'Tasks Id', field: 'id' },
                        {
                            title: 'Tasks', field: 'name',
                            render: (data) => data.decs  ? data.decs : data.name,
                            customFilterAndSearch: (term, rowData) => {
                                return (rowData.decs  ? rowData.decs : rowData.name).toLowerCase().includes(term.toLowerCase())
                            }
                        },
                        {
                            title: 'Status', field: 'status',
                        },
                        {
                            title: 'Required Start Date',
                            field: 'act_exp_start',
                            type: 'date',
                        },
                        {
                            title: 'Required End Date',
                            field: 'act_exp_finish',
                            type: 'date',
                        },
                        {
                            title: 'Complete (%)', field: 'comp_percnt',
                            lookup: { 0: '0%', 5: '5%', 10: '10%', 20: '20%', 30: '30%', 40: '40%', 50: '50%', 60: '60%', 65: '65%', 70: '70%', 80: '80%', 90: '90%', 100: '100%' },
                        },
                        {
                            title: 'Actual and Inprogress Hours',
                            field: 'actual_hours',
                            editable: 'never',
                        },
                        
                        {
                            title: 'Allocated Hours',
                            field: 'total_alloc_hours',
                            editable: 'never',
                        },
                        {
                            title: 'Remaining Hours',
                            field: 'inprogress_hours',
                            editable: 'never',
                            render: rowData => (rowData.id) ? (rowData.actual_hours || rowData.actual_hours === 0) ? (rowData.total_alloc_hours - (rowData.actual_hours + rowData.inprogress_hours)) : (<CircularProgress style={{ height: '15px', width: '15px' }} />) : ("")
                        },
                        // {
                        //     title: 'Actual and Inprogress Hours/Total Allocated', field: 'actual_and_inprogress', editable: 'never',
                        //     render: rowData => (rowData.id) ? (rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{(rowData.actual_hours + rowData.inprogress_hours) + "/" + rowData.total_alloc_hours}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />) : ("")
                        // },

                    ] :
                        [
                            {
                                title: 'Project', field: 'project.name',
                                // lookup: this.state.projectLookUp,
                                // render: rowData => this.state.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                            },
                            { title: 'Deliverables Schedule', field: 'ds.name', },
                            { title: 'WBS', field: 'wbs.name', },
                            { title: 'Tasks Id', field: 'id' },
                            {
                                title: 'Tasks', field: 'name',
                                render: (data) => data.decs  ? data.decs : data.name,
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.decs  ? rowData.decs : rowData.name).toLowerCase().includes(term.toLowerCase())
                                }
                            },
                            {
                                title: 'Required Start Date',
                                field: 'act_exp_start',
                                type: 'date',
                            },
                            {
                                title: 'Required End Date',
                                field: 'act_exp_finish',
                                type: 'date',
                            },
                            {
                                title: 'Complete (%)', field: 'comp_percnt',
                                lookup: { 0: '0%', 5: '5%', 10: '10%', 20: '20%', 30: '30%', 40: '40%', 50: '50%', 60: '60%', 65: '65%', 70: '70%', 80: '80%', 90: '90%', 100: '100%' },
                            },
                            {
                                title: 'Actual and Inprogress Hours/Total Allocated', field: 'actual_and_inprogress', editable: 'never',
                                render: rowData => (rowData.id) ? (rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{(rowData.actual_hours + rowData.inprogress_hours) + "/" + rowData.total_alloc_hours}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />) : ("")
                            },

                        ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    style={{ marginTop: '65px' }}
                    options={{
                        padding: "dense",
                        filtering: true,
                        // exportButton: true,
                        doubleHorizontalScroll: true,
                        pageSize: 20,
                        pageSizeOptions: [10, 20, 50],
                        // exportButton: { csv: true },
                        // exportCsv: (columns, data) => {
                        //     formatCSVData(columns, data)
                        // },
                    }}
                    actions={[
                        rowData => ({
                            icon: 'group',
                            tooltip: 'Users working on same task',
                            onClick: (event, rowData) => { this.setState({ dialogData: rowData, open: true }) },
                        }),
                        rowData => ({
                            icon: 'post_add',
                            tooltip: rowData.comp_percnt > 90 ? "Task Completion is above 90%" : 'Request more hours',
                            disabled: rowData.comp_percnt > 90,
                            onClick: (event, rowData) => { this.setState({ dialogData: rowData, openRequest: true }) },
                        }),
                    ]}
                />
                {this.state.dialogData ?
                    <>
                        <Dialog onClose={handleClose} fullWidth="true" aria-labelledby="customized-dialog-title" open={this.state.open}>

                            <DialogContent dividers>
                                <TaskUsers value={this.state.dialogData} />
                            </DialogContent>
                        </Dialog>
                        <Dialog onClose={handleRequestClose} fullWidth="true" aria-labelledby="customized-dialog-title" open={this.state.openRequest}>
                            <DialogTitle id="customized-dialog-title1" onClose={handleRequestClose}>
                                {this.state.dialogData.decs ? this.state.dialogData.decs : this.state.dialogData.name}
                            </DialogTitle>
                            <DialogContent dividers>
                                <UserHoursRequest value={this.state.dialogData} uid={this.props.uid} tender={this.state.dialogData ? this.state.dialogData.project.id : null} onCloseCommand={handleRequestClose} />
                            </DialogContent>
                        </Dialog>
                    </>
                    : null}

            </div>
        )
    }
}


function addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec ? 1 : null
    if (len) {
        return parseFloat(Number(num).toFixed(len))
    } else {
        return parseFloat(Number(num))
    }
}

function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
}

export default withSnackbar(UserTaskTable)