import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ProjectReportTask from './ProjectReportTask';
import ProjectReportUser from './ProjectReportUser';
import ProjectReportRoleDetail from './ProjectReportRoleDetail';
import ProjectReportRoleSummary from './ProjectReportRoleSummary';
import ProjectReportDs from './ProjectReportDs';
import { getCookie } from '../../Helper/CookieHelper';
import ProjectHistoryUsage from './ProjectHistoryUsage';
import DownloadReport from './DownloadReport';
// import TaskLevelAllocation2 from './TaskLevelAllocation2';
// import SecuredProjectRoles from '../SecuredProjectRoles';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const access = JSON.parse(getCookie("bb_ts_access"));

export default function ProjectReportsTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="DS Level Summary" {...a11yProps(0)} />
                    <Tab label="Task Level Summary" {...a11yProps(1)} />
                    <Tab label="Role Level Summary" {...a11yProps(2)} />
                    <Tab label="Role Level Detail" {...a11yProps(3)} />
                    <Tab label="User Level Summary" {...a11yProps(4)} />

                    {(access.access.l4a && access.access.l4a.l4a_ae) ? (
                        <Tab label="Download Report" {...a11yProps(5)} />
                    ) : (null)}
                    {((access.access.l4a && access.access.l4a.l4a_w) && props.psrSnapshotId === 0) ? (
                        <Tab label="History Usage" {...a11yProps(access.access.l4a.l4a_ae ? 6 : 5)} />
                    ) : (null)}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <ProjectReportDs value={props.value.ds_data} usage={props.value.usage_history} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ProjectReportTask value={props.value.tasks_data} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ProjectReportRoleSummary value={props.value.roles_summary} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <ProjectReportRoleDetail value={props.value.roles_data} />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <ProjectReportUser value={props.value.timesheet_data} />
            </TabPanel>

            {(access.access.l4a && access.access.l4a.l4a_ae) ? (
                <TabPanel value={value} index={5}>
                    <DownloadReport value={props.value} projName={props.projName} />
                </TabPanel>

            ) : (null)}
            {(access.access.l4a && access.access.l4a.l4a_w) ? (
                <TabPanel value={value} index={access.access.l4a.l4a_ae ? 6 : 5}>
                    <ProjectHistoryUsage value={props.value.ds_data} usage={props.value.usage_history} tenderid={props.tenderid} />
                </TabPanel>

            ) : (null)}

            {/* <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
        </div>
    );
}
