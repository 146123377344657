import { Box, Typography, AppBar } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Confetti from 'react-confetti'
import PropTypes from 'prop-types';
import UpComingBroadCast from './UpComingBroadCast';
import CompletedBroadCast from './CompletedBroadCast';
import { getPrimaryColor } from '../Helper/CommonThings';
import { LoopCircleLoading } from 'react-loadingg';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

class BroadCast extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: 0,
            isLoading: true,
            showOnly: false
        }
    }

    componentDidMount() {
        if (window.location.href.includes('2022')) {
            this.setState({ showOnly: true, isLoading: false })
        } else {
            this.setState({ showOnly: false, isLoading: false })
        }
    }

    render() {

        const handleChange = (event, newValue) => {
            this.setState({ value: newValue });
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) :
                    this.state.showOnly ? <CompletedBroadCast /> :
                        <div>
                            <Confetti
                                gravity={0.1}
                                numberOfPieces={100}
                            />
                            <AppBar position='relative' color='secondary'>
                                <Tabs value={this.state.value} style={{ marginTop: '5px', marginLeft: '8px', marginRight: '8px' }} onChange={handleChange} aria-label="simple tabs example"
                                    indicatorColor="primary"
                                    variant="fullWidth"
                                    scrollButtons="auto"
                                >
                                    <Tab label="Upcoming" {...a11yProps(0)} />
                                    <Tab label="Completed" {...a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.value} index={0}>
                                <UpComingBroadCast />
                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>
                                <CompletedBroadCast />
                            </TabPanel>
                        </div>}
            </div>
        )
    }
}

export default withSnackbar(BroadCast)