import React, { Component } from 'react'
// import moment from 'moment';
import { getCookie, setCookie } from '../Helper/CookieHelper';
import Service from "../Networking/networkutils";

// const user = JSON.parse(getCookie("bb_ts_user"));
const user = JSON.parse(getCookie("bb_ts_user"));

export default class PMSAuth extends Component {

    constructor(props) {
        super(props)

        this.state = {
            auth: true
        }
    }

    componentDidMount() {
        // console.log(window.location.host, access.access.l111a.l111a_d)
        const query = new URLSearchParams(this.props.location.search);
        // alert((moment().add(3, "seconds").format()))

        Service.get(`/bb/api/token/access/`, {
            headers: {
                Authorization: "Token " + getCookie("bb_ts_token"),
            },
        })
            .then(res => {
                console.log(query.get('url'))
                // console.log((JSON.parse(getCookie("bb_ts_access")).last_updated +"  "+ res.data[0].last_updated))
                if (res.data && res.data[0]) {
                    var access = res.data[0]

                    if (query.get('url').includes("pms.bbparam.com")) {
                        if (access.access && access.access.l20a && (access.access.l20a.l20a_a || access.access.l20a.l20a_b || access.access.l20a.l20a_c)) {
                            setCookie('bb_ts_token', getCookie("bb_ts_token"), { secure: true, 'max-age': 43200, 'domain': 'pms.' + window.location.host, 'samesite': 'strict', 'path': '/', });
                            setCookie('bb_ts_user', JSON.stringify(user), { secure: true, 'max-age': 43200, 'domain': 'pms.' + window.location.host, 'samesite': 'strict', 'path': '/', });
                            setCookie('bb_ts_access', JSON.stringify(access), { secure: true, 'max-age': 43200, 'domain': 'pms.' + window.location.host, 'samesite': 'strict', 'path': '/', });
                            setCookie('bb_ts_access', JSON.stringify(access), { secure: true, 'max-age': 43200, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                            window.location = (query.get('url')) + (window.location.hash ? window.location.hash : "")
                        } else {
                            alert("You don't have access")
                            this.setState({auth: false})
                        }
                    } else {
                        alert("Domain Not Authorized")
                        this.setState({auth: false})
                        // window.location.href = "/"
                    }

                } else {
                    alert("You don't have access!")
                    this.setState({auth: false})
                    // window.location.href = "/"

                }

            })
            .catch(error => {
                console.log(error)
                alert("Something went wrong")
                // window.location.href = "/"
            });


    }

    render() {
        return (
            <div style={{padding: '15px'}}>
                {(this.state.auth) ? (<h3>Authenticating...</h3>) : (<h3>Authentication Failed. Please contact PaRaM Support for access. Thank you!</h3>)}
                
            </div>
        )
    }
}
