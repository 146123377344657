import React from 'react';
import MaterialTable from 'material-table';
import { errorMessage } from '../Helper/ErrorMessage';
import Service from '../Networking/networkutils';
import { getCookie } from '../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import { Link } from '@material-ui/core';
import UserRoles from './UserRoles';
import UserAdd from './UserAdd';
import { getPrimaryColor } from '../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

// function addZeroes(num) {
//     const dec = num.toString().split('.')[1]
//     const len = dec ? 1 : null
//     if (len) {
//         return parseFloat(Number(num).toFixed(len))
//     } else {
//         return parseFloat(Number(num))
//     }
// }


// function timeToDecimal(t) {
//     try {
//         var arr = t.split(':');
//         var dec = parseInt((arr[1] / 6) * 10, 10);

//         return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
//     } catch (error) {
//         return 0
//     }

// }

var toOpenIndexs = []

class TaskResources extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            roles: [],
            isLoading: true,
            dataLength: 0,
            addUserComp: false,
            allUid: null,
        }
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        // console.log(this.props)
        // this.setState({ data: this.props.task.users })
        this.fetchData()
    }

    fetchData = (newUser) => {
        try {
            Service.get('/bb/api/tender/tasks/rolesrevhistory/?tid=' + this.props.task.id, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    // console.log(res.data)
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        element.req_hours = element.hours + element.req_hours
                        element.rev_hours = element.req_hours
                    }
                    this.setState({ roles: res.data }, () => this.taskUserData(newUser))

                })
                .catch(e => {
                    console.log(e)
                })

        }
        catch (e) {
            console.log(e)
        }
    }

    handleShowDetailPanel = (indexs) => {
        if (!this.tableRef.current) return;

        const { detailPanel } = this.tableRef.current.props;
        let handleShowDetailPanel = detailPanel;
        const _data = [...this.state.data]; // Make copy of data

        for (let i = 0; i < indexs.length; i++) {
            const element = indexs[i];
            if (typeof detailPanel === 'object') {
                // ~ I AM USING INDEX 0 HERE - CHANGE TO WHATEVER YOU NEED ~
                handleShowDetailPanel = detailPanel[element].render
            }

            // If `showDetailPanel` already exists, remove it to close the detailPanel..
            _data[element].tableData = {
                ..._data[element].tableData,
                showDetailPanel: handleShowDetailPanel
            }
        }
        // If props.detailPanel is an array not a func


        this.setState({ data: _data })
    }

    taskUserData(newUser) {

        let users = this.props.allUsers;
        // console.log(users)
        // users.push(newUser)
        let uid = []
        if (newUser) {
            uid.push(newUser.user_id);
            this.props.userAdded(newUser.user_data)
        }
        for (let i = 0; i < users.length; i++) {
            uid.push(users[i].id)
        }

        const dataOld = this.state.data

        Service.get('/bb/api/tender/user/task/get_task_allocations/?task_id=' + this.props.task.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                let currentRoleUsers = res.data;

                let roles = this.state.roles

                for (let i = 0; i < roles.length; i++) {
                    const element = roles[i];
                    element.total_alloc_in_role = 0
                    for (let j = 0; j < res.data.length; j++) {
                        const element1 = res.data[j];
                        if (element.id === element1.task_role_id) {
                            element.total_alloc_in_role += element1.planed_hours
                        }
                    }
                }

                let users = currentRoleUsers
                let uniqueUsers = []
                // console.log(roles, users)
                for (let i = 0; i < users.length; i++) {


                    let role_data = []
                    for (let j = 0; j < roles.length; j++) {
                        if (users[i].task_role_id === roles[j].id) {
                            // users[i]['role_data'] = roles[j]
                            roles[j].planed_hours = users[i].planed_hours
                            roles[j].planed_id = users[i].id
                            role_data.push(JSON.parse(JSON.stringify(roles[j])))
                        }
                    }
                    users[i]['role_data'] = role_data
                }

                let newArr = []
                for (let i = 0; i < users.length; i++) {
                    if (uniqueUsers.indexOf(users[i].user_id) === -1) {
                        uniqueUsers.push(users[i].user_id)
                        newArr.push(users[i])
                    }
                    else {
                        let eleIdx = uniqueUsers.indexOf(users[i].user_id);
                        let ele = newArr[eleIdx];
                        console.log(ele, users[i])
                        let role_data = ele.role_data;
                        role_data.push(JSON.parse(JSON.stringify(users[i].role_data[0])))
                    }
                }
                // console.log(users, 'users')
                // console.log(newArr, 'newArr')
                let dataLength = newArr.length;

                this.setState({ data: newArr, dataLength: dataLength, isLoading: false, addUserComp: false, allUid: uid }, () => {
                    this.openPanel(dataOld)
                })

                // var viewPid = []
                // for (let i1 = 0; i1 < currentRoleUsers.length; i1++) {
                //     const element = currentRoleUsers[i1];
                //     viewPid.push({ 'com_ttid': this.props.task.id, 'com_uid': element.user_id })
                // }
                // if (viewPid.length !== 0) {
                //     Service.patch("/bb/api/team/tasks/get_acthours/", {
                //         headers: {
                //             Authorization: "Token " + token,
                //         },
                //         data: viewPid,
                //     })
                //         .then(res12 => {

                //             for (let j = 0; j < res12.data.length; j++) {
                //                 const element13 = res12.data[j];
                //                 for (let i = 0; i < currentRoleUsers.length; i++) {
                //                     const element12 = currentRoleUsers[i];
                //                     if (element12.user_id === element13.id2) {
                //                         element12.actual_hours = timeToDecimal(element13.hours_actual)
                //                         element12.inprogress_hours = timeToDecimal(element13.hours_ongoing)
                //                         element12.total_alloc_hours = element13.allocated

                //                     }
                //                 }
                //             }

                //             for (let i = 0; i < currentRoleUsers.length; i++) {
                //                 for (let j = 0; j < currentRoleUsers.length; j++) {
                //                     // if (currentRoleUsers[i].user_id === currentRoleUsers[j].user_id) {
                //                     currentRoleUsers[i].actualAndInprogressHours = currentRoleUsers[i].actual_hours + currentRoleUsers[i].inprogress_hours
                //                     // }
                //                 }
                //                 for (let k = 0; k < currentRoleUsers.length; k++) {
                //                     if (currentRoleUsers[i].user_id === currentRoleUsers[k].user_id) {
                //                         currentRoleUsers[i].balance_hours = currentRoleUsers[i].total_alloc_hours - currentRoleUsers[i].actualAndInprogressHours
                //                     }
                //                 }
                //             }

                //             let roles = this.state.roles

                //             for (let i = 0; i < roles.length; i++) {
                //                 const element = roles[i];
                //                 element.total_alloc_in_role = 0
                //                 for (let j = 0; j < res.data.length; j++) {
                //                     const element1 = res.data[j];
                //                     if (element.id === element1.task_role_id) {
                //                         element.total_alloc_in_role += element1.planed_hours
                //                     }
                //                 }
                //             }

                //             let users = currentRoleUsers
                //             let uniqueUsers = []
                //             // console.log(roles, users)
                //             for (let i = 0; i < users.length; i++) {


                //                 let role_data = []
                //                 for (let j = 0; j < roles.length; j++) {
                //                     if (users[i].task_role_id === roles[j].id) {
                //                         // users[i]['role_data'] = roles[j]
                //                         roles[j].planed_hours = users[i].planed_hours
                //                         roles[j].planed_id = users[i].id
                //                         role_data.push(JSON.parse(JSON.stringify(roles[j])))
                //                     }
                //                 }
                //                 users[i]['role_data'] = role_data
                //             }

                //             let newArr = []
                //             for (let i = 0; i < users.length; i++) {
                //                 if (uniqueUsers.indexOf(users[i].user_id) === -1) {
                //                     uniqueUsers.push(users[i].user_id)
                //                     newArr.push(users[i])
                //                 }
                //                 else {
                //                     let eleIdx = uniqueUsers.indexOf(users[i].user_id);
                //                     let ele = newArr[eleIdx];
                //                     console.log(ele, users[i])
                //                     let role_data = ele.role_data;
                //                     role_data.push(JSON.parse(JSON.stringify(users[i].role_data[0])))
                //                 }
                //             }
                //             // console.log(users, 'users')
                //             // console.log(newArr, 'newArr')
                //             let dataLength = newArr.length;

                //             this.setState({ data: newArr, dataLength: dataLength, isLoading: false, addUserComp: false, allUid: uid }, () => {
                //                 this.openPanel(dataOld)
                //             })
                //         })
                //         .catch(error => {
                //             this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                //                 variant: 'error'
                //             });

                //         });
                // } else {
                //     this.setState({ data: currentRoleUsers, dataLength: currentRoleUsers.length, isLoading: false, addUserComp: false }, () => {
                //         this.openPanel(dataOld)
                //     })
                // }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    openPanel(dataOld) {
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            for (let j = 0; j < dataOld.length; j++) {
                const element1 = dataOld[j];
                if (element.id === element1.id && element1.tableData && element1.tableData.showDetailPanel) {
                    toOpenIndexs.push(i)
                    break
                }
            }
        }
        this.handleShowDetailPanel(toOpenIndexs)
    }

    givePlanned(data, asdata) {
        var tot_planned = 0
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (asdata.task_role_id === element.task_role_id && asdata.id !== element.id) {
                tot_planned = tot_planned + element.planed_hours
            }
        }
        return tot_planned + asdata.planed_hours
    }

    addUser = () => {
        this.setState({ addUserComp: true })
    }

    hideAddUser = () => {
        this.setState({ addUserComp: false })
    }


    save = (role, selectedUser, hrs) => {

        // console.log(role, selectedUser, hrs)
        // console.log(this.state.data)
        let userExist = false
        for (let i = 0; i < this.state.data.length; i++) {
            if (selectedUser.id === this.state.data[i].user_id) {
                for (let j = 0; j < this.state.data[i].role_data.length; j++) {
                    if (role.t_role === this.state.data[i].role_data[j].t_role) {
                        this.props.enqueueSnackbar('User exist for the role.', {
                            variant: 'warning'
                        });
                        userExist = true
                        break;
                    }
                }

            }
        }
        if (userExist) return;
        this.setState({ isLoading: true })
        Service.get('/bb/api/tender/user/task/?trid=' + role.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res1 => {

                try {
                    var postData = {
                        task_id: this.props.task.id,
                        role_id: role.t_role,
                        task_role_id: role.id,
                        wbs_id: role.t_wbs,
                        t_pid: role.t_pid,
                        user_id: selectedUser.id,
                        user_email: selectedUser.email,
                        planed_hours: hrs > 0 ? hrs : 0,
                        created_by: user.email,
                    }

                    if (this.givePlanned(res1.data, postData) <= ((res1.data.length === 0) ? role.rev_hours : res1.data[0].tasks_roles_data.hours + res1.data[0].tasks_roles_data.req_hours)) {

                        Service.post('/bb/api/tender/user/task/user_task_allocation/', {
                            headers: {
                                Authorization: "Token " + token,
                            },
                            data: postData,
                        })
                            .then(res => {
                                // waitData = false;
                                // var data = this.state.data;
                                // data.push(res.data);
                                // // autocompdata = '';
                                // this.setState({ data: data, isLoading: false, openAdd: false })
                                this.props.enqueueSnackbar('Data Added Successfully!', {
                                    variant: 'success'
                                });
                                this.fetchData(res.data)
                                // this.fetchOps();
                                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
                            })
                            .catch(error => {
                                // waitData = false;
                                errorMessage(this.props, error)
                                this.setState({ isLoading: false })
                            });

                    } else {
                        this.setState({ isLoading: false })
                        // this.fetchOps();
                        this.props.enqueueSnackbar('You are trying to allocate more hours than available hours for this role.', {
                            variant: 'warning',
                            autoHideDuration: 5000,

                        });
                    }
                }
                catch (e) {
                    console.log(e)
                }

            })
            .catch(error => {
                // this.fetchOps();
                errorMessage(this.props, error)
                this.setState({ isLoading: false })
            });


    }

    render() {
        return (
            <div>

                <MaterialTable
                    tableRef={this.tableRef}
                    columns={[
                        {
                            title: 'Name', field: 'id',
                            editable: 'never',
                            render: rowdata => rowdata.first_name + ' ' + rowdata.last_name,
                        },
                        { title: 'Alloc Hrs', field: 'total_alloc_hours', type: 'numeric', editable: 'never' },
                        { title: 'Bal.', field: 'balance_hours', type: 'numeric', editable: 'never' },
                        {
                            title: 'Alloc Cost', field: 'allocation_cost', editable: 'never', type: 'numeric',
                            render: rowData =>  new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.allocation_cost)
                        },
                    ]}

                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        showTitle: false,
                        padding: 'dense',
                        paging: false,
                        toolbar: false,
                        // pageSize: this.state.data.length,
                        headerStyle: {
                            backgroundColor: getPrimaryColor(),
                            color: '#FFF',
                            fontSize: '12px',
                            padding: '10px'
                        },
                        rowStyle: rowData => {
                            return {
                                //   backgroundColor: '#eaeaea'
                                fontSize: '12px',
                                padding: '10px'
                            };
                        }
                    }}

                    detailPanel={rowData => {
                        return (
                            <UserRoles rowData={rowData} fetchData={this.fetchData} task={this.props.task} allUid={this.state.allUid} />
                        )
                    }}
                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                // components={{
                //     Pagination: props => (

                //     )
                // }}
                />
                {this.state.addUserComp ? <UserAdd allUsers={this.props.allUsers} hide={this.hideAddUser} save={this.save} roles={this.state.roles} /> :
                    <Link
                        component="button"
                        variant="body2"
                        onClick={this.addUser}
                        style={{ marginLeft: '18px', marginTop: '3px' }}
                    >
                        Add User
                    </Link>}
            </div>
        )

    }
}

export default withSnackbar(TaskResources)