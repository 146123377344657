import React, { Component } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, } from '@mui/material';
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../../Helper/CommonThings';
import UserTasks from '../../WorkHours/UserTasks';
import Service from '../../Networking/networkutils';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import { withSnackbar } from 'notistack';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const token = getCookie("bb_ts_token");
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class AllUsersWorkload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            allUsers: [],
            selectedUser: [],
            isLoading: true,
            remount: false,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        Service.get(`/bb/api/user/withdetail/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    allUsers: res.data,
                    isLoading: false,
                })
            })
            .catch(e => {
                errorMessage(this.props, e)
                console.log(e)
            })
    }

    submitData() {
        if (this.state.selectedUser.length > 0) {
            this.setState({ remount: false }, () => { setTimeout(() => { this.setState({ remount: true }) }, 100) })
        } else {
            this.props.enqueueSnackbar("Please select a user", { variant: 'warning' })
        }
    }

    UserChange = (event, value) => {
        if (!value) {
            this.setState({ selectedUser: [] })
        } else {
            this.setState({ selectedUser: value.map((item) => item.id), remount: false }, () => { setTimeout(() => { this.setState({ remount: true }) }, 100) })
        }
    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? (<LoopCircleLoading color={getPrimaryColor()} />) : (
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center" }}>
                        <Autocomplete
                            multiple
                            id="export-selected_users"
                            options={this.state.allUsers}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.last_name + ", " + option.first_name}
                            style={{ width: 600, marginRight: '20px' }}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.last_name + ", " + option.first_name}
                                </React.Fragment>
                            )}
                            onChange={this.UserChange}
                            fullWidth
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Select Users" placeholder="Select Users" />
                            )}
                        />
                        {/* <Button onClick={() => this.submitData()}>Submit</Button> */}
                    </Box>
                )
                }
                {(this.state.selectedUser.length > 0 && this.state.remount) ? (<UserTasks uid={this.state.selectedUser.join(',')} multiUser={this.state.selectedUser.length > 1} />) : (null)}
            </div>
        );
    }
}

export default withSnackbar(AllUsersWorkload);