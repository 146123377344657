import React, { Component } from 'react';
import TeamGantt from './TeamGantt';
import './Gantt.css'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import { ZoomIn, ZoomOut } from '@material-ui/icons';
import { Switch, Button } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { CircleLoading } from 'react-loadingg';

// const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

// const data2 = require('./data.json');

class TeamGanttHome extends Component {

    constructor() {
        super()
        this.state = {
            data: {
                data: [],
                links: [],
            },
            switchdate: false,
            refreshGantt: true,
            expand: false
        }

        this.childGantt = React.createRef()
    }

    componentDidMount() {
        // console.log(this.props);

        // let project = [], ds = [], wbs = [];
        let task = this.props.taskData;
        // let upr = [], uds = [], uwbs = [];

        // for (let i = 0; i < task.length; i++) {
        //     if (upr.indexOf(task[i].project.id) === -1) { upr.push(task[i].project.id); project.push(task[i].project); }
        //     if (uds.indexOf(task[i].ds.id) === -1) { uds.push(task[i].ds.id); ds.push(task[i].ds); }
        //     if (uwbs.indexOf(task[i].wbs.id) === -1) { uwbs.push(task[i].wbs.id); wbs.push(task[i].wbs); }
        //     // ds.push(task[i].ds);
        //     // wbs.push(task[i].wbs);
        // }
        // console.log(project, ds, wbs)
        // // this.getAlldata();
        // this.allData = { ds, project, task, wbs }
        // this.makeData({ ds, project, task, wbs });

        let tid = [];
        for (let i = 0; i < task.length; i++) {
            tid.push(task[i].id)
        };
        this.getAlldata(tid)
    }

    getAlldata(tid) {
        let allData = this.props.responseData
        let tasks = this.props.taskData;
        console.log(tasks)
        for (let i = 0; i < allData.task.length; i++) {
            for (let j = 0; j < tasks.length; j++) {
                if (allData.task[i].id === tasks[j].id) {
                    allData.task[i].users = tasks[j].users
                    for (let k = 0; k < allData.ds.length; k++) {
                        if (allData.ds[k].id === allData.task[i].t_pid) {
                            for (let l = 0; l < allData.project.length; l++) {
                                if (allData.ds[k].subproject_pid === allData.project[l].id) {
                                    allData.task[i].project = allData.project[l]
                                }
                            }
                        }
                    }
                }
            }
        }
        // console.log(allData);
        this.allData = allData
        this.makeData(allData)
    }

    makeData(allData) {
        // let data2 = this.props.data
        console.log(allData, 'All Data')

        // let allData = this.props.allData;
        let { ds, project, task, wbs } = allData;

        let data = [];

        for (let i = 0; i < project.length; i++) {
            let p = project[i];
            var proj_push = null
            var ds_push = null
            var wbs_push = null
            var task_push = null
            if (p.from_date && p.to_date) {
                proj_push = {
                    id: 'p' + p.id,
                    text: p.name,
                    start_date: moment(p.from_date).format('DD-MM-YYYY'),
                    end_date: moment(p.to_date).format('DD-MM-YYYY'),
                    type: "project",
                    actual_start: moment(p.t_act_exp_start).format('DD-MM-YYYY'),
                    actual_finish: moment(p.t_act_exp_finish).format('DD-MM-YYYY'),
                }
            }
            else {
                proj_push = {
                    id: 'p' + p.id,
                    text: p.name,
                    start_date: '',
                    end_date: '',
                    unscheduled: true,
                    type: "project"
                }
            }

            proj_push.searchKey = p.name
            if (!this.props.teamOrProj) {
                data.push(proj_push)
            }


            for (let j = 0; j < ds.length; j++) {
                let d = ds[j];
                if (p.id === d.subproject_pid) {
                    if (d.from_date && d.to_date) {
                        ds_push = {
                            id: 'd' + d.id,
                            text: d.name,
                            start_date: moment(d.from_date).format('DD-MM-YYYY'),
                            end_date: moment(d.to_date).format('DD-MM-YYYY'),
                            color: '#5C6BC0'
                        }
                    }
                    else {
                        ds_push = {
                            id: 'd' + d.id,
                            text: d.name,
                            start_date: '',
                            end_date: '',
                            unscheduled: true
                        }
                    }
                    if (!this.props.teamOrProj) {
                        ds_push.parent = 'p' + p.id
                    }


                    proj_push.searchKey += d.name
                    ds_push.searchKey = p.name + d.name
                    data.push(ds_push)


                    for (let k = 0; k < wbs.length; k++) {
                        let w = wbs[k];

                        if (d.id === w.pid) {
                            if (w.t_from_date && w.t_to_date) {
                                wbs_push = {
                                    id: 'w' + w.id,
                                    text: w.name,
                                    start_date: moment(w.t_from_date).format('DD-MM-YYYY'),
                                    end_date: moment(w.t_to_date).format('DD-MM-YYYY'),
                                    parent: 'd' + d.id,
                                    color: '#00BCD4'
                                }
                            }
                            else {
                                wbs_push = {
                                    id: 'w' + w.id,
                                    text: w.name,
                                    start_date: '',
                                    end_date: '',
                                    parent: 'd' + d.id,
                                    unscheduled: true
                                }
                            }
                            proj_push.searchKey += w.name
                            ds_push.searchKey += w.name
                            wbs_push.searchKey = p.name + d.name + w.name
                            data.push(wbs_push)
                            for (let l = 0; l < task.length; l++) {
                                let t = task[l];
                                if (w.id === t.t_ohlwbs) {
                                    if (t.from_date && t.to_date) {
                                        task_push = {
                                            id: 't' + t.id,
                                            text: t.name,
                                            start_date: moment(t.from_date).format('DD-MM-YYYY'),
                                            end_date: moment(t.to_date).format('DD-MM-YYYY'),
                                            parent: 'w' + w.id,
                                            color: '#607D8B',
                                            rowType: 'task'
                                        }
                                    }
                                    else {
                                        task_push = {
                                            id: 't' + t.id,
                                            text: t.name,
                                            start_date: '',
                                            end_date: '',
                                            parent: 'w' + w.id,
                                            unscheduled: true,
                                            rowType: 'task'
                                        }
                                    }
                                    proj_push.searchKey += t.name + t.id
                                    ds_push.searchKey += t.name + t.id
                                    wbs_push.searchKey += t.name + t.id
                                    task_push.searchKey = p.name + d.name + w.name + t.name + t.id
                                    data.push(task_push)

                                    // for (let m = 0; m < t.users.length; m++) {
                                    //     let u = t.users[m]
                                    //     data.push({
                                    //         id: u.task_user_id,
                                    //         text: u.first_name + ' ' + u.last_name,
                                    //         start_date: '',
                                    //         end_date: '',
                                    //         parent: 't' + t.id,
                                    //         unscheduled: true
                                    //     })
                                    // }
                                }
                            }

                        }


                    }

                }

            }

        }
        console.log(data, 'data')
        this.setState({ data: { data: data, links: [] }, refreshGantt: true }, () => {
            setTimeout(() => {
                this.setState({ refreshGantt: false })
            }, 200);
        });
    }

    makeActualdateData() {
        // let data2 = this.props.data
        // console.log(this.allData, 'All Data')

        // let allData = this.props.allData;
        let { ds, project, task, wbs } = this.allData;

        let data = [];

        for (let i = 0; i < project.length; i++) {
            let p = project[i];
            var proj_push = null
            var ds_push = null
            var wbs_push = null
            var task_push = null
            if (p.t_act_exp_start && p.t_act_exp_finish) {
                proj_push = {
                    id: 'p' + p.id,
                    text: p.name,
                    start_date: moment(p.t_act_exp_start).format('DD-MM-YYYY'),
                    end_date: moment(p.t_act_exp_finish).format('DD-MM-YYYY'),
                    type: "project",
                    //    actual_start: moment(p.t_act_exp_start).format('DD-MM-YYYY'),
                    //    actual_finish: moment(p.t_act_exp_finish).format('DD-MM-YYYY'),
                }
            }
            else {
                proj_push = {
                    id: 'p' + p.id,
                    text: p.name,
                    start_date: '',
                    end_date: '',
                    unscheduled: true,
                    type: "project"
                }
            }

            proj_push.searchKey = p.name
            if (!this.props.teamOrProj) {
                data.push(proj_push)
            }

            for (let j = 0; j < ds.length; j++) {
                let d = ds[j];

                if (p.id === d.subproject_pid) {
                    if (d.from_date && d.to_date) {
                        ds_push = {
                            id: 'd' + d.id,
                            text: d.name,
                            start_date: moment(d.from_date).format('DD-MM-YYYY'),
                            end_date: moment(d.to_date).format('DD-MM-YYYY'),
                            parent: 'p' + p.id,
                            color: '#5C6BC0'
                        }
                    }
                    else {
                        ds_push = {
                            id: 'd' + d.id,
                            text: d.name,
                            start_date: '',
                            end_date: '',
                            parent: 'p' + p.id,
                            unscheduled: true
                        }
                    }
                    proj_push.searchKey += d.name
                    ds_push.searchKey = p.name + d.name
                    data.push(ds_push)


                    for (let k = 0; k < wbs.length; k++) {
                        let w = wbs[k];

                        if (d.id === w.pid) {
                            if (w.t_from_date && w.t_to_date) {
                                wbs_push = {
                                    id: 'w' + w.id,
                                    text: w.name,
                                    start_date: moment(w.t_from_date).format('DD-MM-YYYY'),
                                    end_date: moment(w.t_to_date).format('DD-MM-YYYY'),
                                    parent: 'd' + d.id,
                                    color: '#00BCD4'
                                }

                            }
                            else {
                                wbs_push = {
                                    id: 'w' + w.id,
                                    text: w.name,
                                    start_date: '',
                                    end_date: '',
                                    parent: 'd' + d.id,
                                    unscheduled: true
                                }
                            }
                            proj_push.searchKey += w.name
                            ds_push.searchKey += w.name
                            wbs_push.searchKey = p.name + d.name + w.name
                            data.push(wbs_push)
                            for (let l = 0; l < task.length; l++) {
                                let t = task[l];

                                if (w.id === t.t_ohlwbs) {
                                    if (t.from_date && t.to_date) {
                                        task_push = {
                                            id: 't' + t.id,
                                            text: t.name,
                                            start_date: moment(t.from_date).format('DD-MM-YYYY'),
                                            end_date: moment(t.to_date).format('DD-MM-YYYY'),
                                            parent: 'w' + w.id,
                                            color: '#607D8B',
                                            rowType: 'task'
                                        }
                                    }
                                    else {
                                        task_push = {
                                            id: 't' + t.id,
                                            text: t.name,
                                            start_date: '',
                                            end_date: '',
                                            parent: 'w' + w.id,
                                            unscheduled: true,
                                            rowType: 'task'
                                        }
                                    }
                                    proj_push.searchKey += t.name + t.id
                                    ds_push.searchKey += t.name + t.id
                                    wbs_push.searchKey += t.name + t.id
                                    task_push.searchKey = p.name + d.name + w.name + t.name + t.id
                                    data.push(task_push)
                                    // for (let m = 0; m < t.users.length; m++) {
                                    //     let u = t.users[m]
                                    //     data.push({
                                    //         id: u.task_user_id,
                                    //         text: u.first_name + ' ' + u.last_name,
                                    //         start_date: '',
                                    //         end_date: '',
                                    //         parent: 't' + t.id,
                                    //         unscheduled: true
                                    //     })
                                    // }
                                }
                            }
                        }


                    }
                }


            }

        }
        // console.log(data, 'data')
        this.setState({ data: { data: data, links: [] }, refreshGantt: true }, () => {
            setTimeout(() => {
                this.setState({ refreshGantt: false })
            }, 200);
        });

    }

    zoomIn() {
        // console.log('from parent call')
        this.childGantt.current.zoomIn();
    }

    zoomOut() {
        this.childGantt.current.zoomOut();
    }

    expandAll() {
        this.childGantt.current.expandAll();
    }

    collapseAll() {
        this.childGantt.current.collapseAll();
    }

    handleSwitch = (event) => {
        let value = event.target.checked
        this.childGantt.current.search('')
        this.setState({ switchdate: value });

        if (value === true) {
            this.makeActualdateData()
        }
        else {
            this.makeData(this.allData)
        }
    }

    setExpand = () => {
        this.setState({ expand: !this.state.expand }, () => {
            if (this.state.expand === true) {
                this.expandAll()
            }
            else {
                this.collapseAll()
            }
        })

    }

    render() {
        // console.log(this.state.data)
        return (
            <div className="gantt-container" style={{ marginTop: '17px' }} >
                {this.state.refreshGantt === false ?
                    <div>
                        <div>
                            <div style={{ textAlign: 'right', marginBottom: '5px' }}>
                                <div style={{ marginRight: '10px', float: 'left', marginLeft: '15px' }}>
                                    Baseline Dates <Switch
                                        checked={this.state.switchdate}
                                        onChange={this.handleSwitch}
                                        color="primary"
                                        name="switchdate"
                                        inputProps={{ 'aria-label': 'Switch' }}
                                    /> Actual Dates
                                    <Button
                                        variant='outlined'
                                        onClick={this.setExpand}
                                        size='small'
                                        endIcon={this.state.expand === false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                                        style={{ marginLeft: '20px', marginRight: '20px' }}
                                    >
                                        {this.state.expand === false ? 'Expand All' : 'Collapse All'}
                                    </Button>

                                    Search: <input data-text-filter type='text' onInput={(e) => { this.expandAll(); this.childGantt.current.search(e.target.value) }} />
                                </div>
                                {/* <Button variant="outlined" onClick={() => this.expandAll()} size='small' endIcon={<ArrowDropDownIcon />} style={{ marginRight: '10px' }}>Expand All</Button>
                                <Button variant="outlined" onClick={() => this.collapseAll()} size='small' endIcon={<ArrowDropUpIcon />} style={{ marginRight: '10px' }}>Collapse All</Button> */}
                                <Button variant="outlined" onClick={() => this.zoomOut()} size='small' startIcon={<ZoomOut />} style={{ marginRight: '10px' }}>Zoom Out</Button>
                                <Button variant="outlined" onClick={() => this.zoomIn()} size='small' startIcon={<ZoomIn />} style={{ marginRight: '10px' }}>Zoom IN</Button>

                            </div>
                        </div>

                        <div style={{ height: 'calc(100vh - 160px)' }}>
                            <TeamGantt
                                ref={this.childGantt}
                                tasks={this.state.data}
                                baselineDates={this.state.switchdate}
                                allData={this.allData}
                                allUsers={this.props.allUsers}
                                teamOrProj={this.props.teamOrProj}
                            />
                        </div>

                    </div>
                    : <CircleLoading />}
            </div>
        );
    }
}

export default withSnackbar(TeamGanttHome)