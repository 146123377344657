import React, { Component } from 'react'
import MultiProgress from "react-multi-progress";
import { getGreencolor, getOrangecolor, getProgBgColor, getSkyBluecolor } from '../ColorTheme/ColorTheme';

class StackedProgressbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            actual: 0,
            inprogress: 0,
            assigned: 0
        }
    }
    componentDidMount() {
        this.calcHours()
    }

    calcHours() {
        var converted_assinged = 100 / this.props.assigned
        var actual = converted_assinged * this.props.actual
        var inprogress = converted_assinged * this.props.inprogress
        var assigned = 100 - actual - inprogress
        this.setState({ actual: actual, inprogress: inprogress, assigned: assigned })
    }
    render() {
        return (
            <MultiProgress
                transitionTime={1.2}
                elements={[
                    {
                        value: this.state.actual,
                        color: getGreencolor(),
                    },
                    {
                        value: this.state.inprogress,
                        color: getSkyBluecolor(),
                    },
                    {
                        value: this.state.assigned,
                        color: getOrangecolor(),
                    },
                ]}
                height={10}
                backgroundColor={getProgBgColor()}
            />
        )
    }
}

export default StackedProgressbar