import React, { Component } from 'react'
import Service from "../../Networking/networkutils";
import { LoopCircleLoading } from 'react-loadingg';
import MaterialTable from 'material-table';
import { CircularProgress, Dialog, DialogContent, DialogContentText, IconButton, Typography } from '@material-ui/core';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar } from 'notistack';
import User3W from '../UserRoleMapping/User3W';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { getCookie } from '../../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
// var autocompdata = '';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
class RoleMapping extends Component {

    constructor(props) {
        super(props)

        this.state = {
            users: [],
            usersAll: [],
            data: [],
            isLoading: true,
            open: false,
            deliValue: {},
            roleUid: '0',
        }
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        var pageView = window.location.pathname + "/#TenderAddAndEdit";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        Service.get('/bb/api/tender/user/task/?tid=' + this.props.value.t_tasks + "&rid=" + this.props.value.t_role, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({ data: res.data, isLoading: false }, () => {
                    var viewPid = []

                    for (let i1 = 0; i1 < res.data.length; i1++) {
                        const element = res.data[i1];
                        viewPid.push({ 'com_ttid': element.task_id, 'com_uid': element.user_id })

                    }
                    // console.log(viewPid)
                    if (viewPid.length !== 0) {
                        Service.patch("/bb/api/team/tasks/get_acthours/", {
                            headers: {
                                Authorization: "Token " + token,
                            },
                            data: viewPid,
                        })
                            .then(res12 => {
                                // console.log(res12.data)

                                for (let j = 0; j < res12.data.length; j++) {
                                    const element13 = res12.data[j];
                                    for (let i = 0; i < res.data.length; i++) {
                                        const element12 = res.data[i];
                                        if (element12.user_id === element13.id2) {
                                            element12.actual_hours = timeToDecimal(element13.hours_actual)
                                            element12.inprogress_hours = timeToDecimal(element13.hours_ongoing)
                                            element12.total_alloc_hours = element13.allocated
                                            break;
                                        }
                                        // console.log(timeToDecimal(element13.hours_actual) !== 0)
                                    }

                                }
                                // console.log(res.data)
                                this.setState({

                                    data: res.data,

                                })



                            })
                            .catch(error => {
                                this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                                    variant: 'error'
                                });

                            });
                    }
                })

                Service.get('/bb/api/users/?role_id=' + this.props.value.t_role, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res1 => {

                        Service.get('/bb/api/secondary/roles/?role=' + this.props.value.t_role, {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res2 => {
                                var data = [];
                                var role_uid = '0'
                                for (let i = 0; i < res1.data.length; i++) {
                                    const element = res1.data[i];
                                    var goA = true;
                                    role_uid = role_uid + "," + element.id
                                    // for (let j = 0; j < res.data.length; j++) {
                                    //     const element1 = res.data[j];
                                    //     if (element1.user_email === element.email) {
                                    //         goA = false;
                                    //     }
                                    // }
                                    if (goA) {
                                        data.push(element);
                                    }
                                }

                                for (let i = 0; i < res2.data.length; i++) {
                                    const element = res2.data[i];
                                    role_uid = role_uid + "," + element.uid;
                                    data.push(element.uid_data);
                                }
                                this.setState({ users: data, usersAll: data, roleUid: role_uid })
                            })
                            .catch(error => {
                                this.props.enqueueSnackbar('Something went wrong!', {
                                    variant: 'error'
                                });
                            });

                        // console.log(jsondata)
                    })
                    .catch(error => {
                        this.props.enqueueSnackbar('Something went wrong!', {
                            variant: 'error'
                        });
                    });
                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
               errorMessage(this.props, error)
            });
    }

    postData(userdata) {
        var pageView = window.location.pathname + "/#TenderAddAndEdit";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        if (userdata === '') {
            this.props.enqueueSnackbar('Select a user to continue!', {
                variant: 'warning'
            });
        } else {
            // this.setState({ isLoading: true })
            var postData = {
                task_id: this.props.task.id,
                role_id: this.props.value.t_role,
                task_role_id: this.props.value.id,
                wbs_id: this.props.value.t_wbs,
                t_pid: this.props.value.t_pid,
                user_id: userdata.id,
                user_email: userdata.email,
                created_by: user.email,
            }
            Service.post('/bb/api/tender/user/task/user_task_allocation/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData,
            })
                .then(res => {
                    var data = this.state.data;
                    data.push(res.data);
                    // autocompdata = '';
                    this.setState({ data: data, isLoading: false, open: false })
                    this.props.enqueueSnackbar('Data Added Successfully!', {
                        variant: 'success'
                    });
                    this.fetchOps();
                    // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                    this.setState({ isLoading: false })
                });
        }

    }

    putData(asdata, alldata) {

        var hourCount = 0;
        for (let i = 0; i < alldata.length; i++) {
            const element = alldata[i];
            hourCount = hourCount + element.planed_hours;
        }

        if (hourCount <= (this.props.value.all_hours_to_this_role)) {
            var pageView = window.location.pathname + "/#TenderAddAndEdit";
            ReactGA.set({ page: pageView, });
            ReactGA.pageview(pageView);
            // this.setState({ isLoading: true })
            Service.put('/bb/api/tender/user/task/' + asdata.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: asdata,
            })
                .then(res => {
                    this.setState({ isLoading: false })
                    this.props.enqueueSnackbar('Edited Successfully!', {
                        variant: 'success'
                    });
                    this.fetchOps();
                    // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                    this.setState({ isLoading: false })
                });
        } else {
            this.props.enqueueSnackbar('You are trying to exceed the role hours!. Changes are not saved.', {
                variant: 'warning'
            });
            this.fetchOps();
        }

    }

    deleteData(asdata) {

        var pageView = window.location.pathname + "/#TenderAddAndEdit";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        // this.setState({ isLoading: true })
        Service.delete('/bb/api/tender/user/task/' + asdata.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Deleted Successfully!', {
                    variant: 'success'
                });
                this.fetchOps();
                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
               errorMessage(this.props, error)
                this.setState({ isLoading: false })
            });
    }

    // onTagsChange = (event, values) => {
    //     // console.log(values)
    //     autocompdata = values;
    // }

    handleUserSelect = (e) => {
        this.setState({
            open: false
        })
        for (let i = 0; i < this.state.users.length; i++) {
            const element = this.state.users[i];
            if (element.id === e) {
                this.postData(element);
                break;
            }
        }
    }

    render() {

        const handleClose = () => {
            this.setState({ open: false })
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                        <div>
                            <MaterialTable
                                title='Mapped Users'
                                columns={[
                                    {
                                        title: 'User', field: 'name', editable: 'never',
                                        render: rowData => <Typography>{rowData.user_data.first_name + " " + rowData.user_data.last_name + " (" + rowData.user_email + ")"}</Typography>
                                    },
                                    {
                                        title: 'Type', field: 'assign_type',
                                        lookup: { 0: 'Assigned', 1: 'Reviewer', 100: 'Not Assigned', }
                                    },
                                    {
                                        title: 'Baseline Start', field: 'start', type: 'date',
                                    },
                                    {
                                        title: 'Baseline End', field: 'end', type: 'date',
                                    },
                                    {
                                        title: 'Work Estimated Start', field: 'start', type: 'date', default: this.props.task.from_date,
                                    },
                                    {
                                        title: ' Work Estimated End', field: 'end', type: 'date', default: this.props.task.to_date,
                                    },
                                    {
                                        title: 'Actual and Inprogress Hours/Total Allocated', field: 'ah', editable: 'never',
                                        render: rowData => (rowData.id) ? ((rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{(rowData.actual_hours + rowData.inprogress_hours) + "/" + rowData.total_alloc_hours}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)) : ("")
                                    },
                                    {
                                        title: 'Balance Hours', field: 'ah', editable: 'never',
                                        render: rowData => (rowData.id) ? ((rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{rowData.total_alloc_hours - (rowData.actual_hours + rowData.inprogress_hours)}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)) : ("")
                                    },
                                    {
                                        title: 'Allocated Hours', field: 'planed_hours', type: 'numeric'
                                    },
                                ]}
                                data={this.state.data}
                                options={{
                                    padding: "dense",
                                    actionsColumnIndex: -1,
                                    search: false,
                                    doubleHorizontalScroll: true,
                                    // filtering: true
                                }}
                                actions={
                                    [
                                        {
                                            icon: 'add_box',
                                            tooltip: 'Add User',
                                            isFreeAction: true,
                                            onClick: () => {
                                                this.setState({ open: true })
                                            },
                                        },

                                    ]
                                }
                                editable={{
                                    // onRowAdd: newData =>
                                    //     new Promise(resolve => {
                                    //         setTimeout(() => {
                                    //             resolve();
                                    //             this.setState({ save: false });
                                    //             this.setState(prevState => {
                                    //                 const data = [...prevState.data];
                                    //                 newData.roles = [];
                                    //                 data.push(newData);
                                    //                 return { ...prevState, data };
                                    //             });
                                    //         }, 60);
                                    //     }),
                                    isEditable: rowData => (rowData.id && ((rowData.actual_hours || rowData.actual_hours === 0) && (rowData.inprogress_hours || rowData.inprogress_hours === 0))),

                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    this.setState({ save: false });
                                                    if (newData.planed_hours >= oldData.planed_hours || (oldData.planed_hours - newData.planed_hours) <= (oldData.total_alloc_hours - (oldData.actual_hours + oldData.inprogress_hours))) {
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            delete newData['actual_hours']
                                                            data[data.indexOf(oldData)] = newData;
                                                            this.putData(newData, data);
                                                            return { ...prevState, data };
                                                        });
                                                    } else {
                                                        this.props.enqueueSnackbar("You can only reduce " + (oldData.total_alloc_hours - (oldData.actual_hours + oldData.inprogress_hours)) + " hours", {
                                                            variant: 'warning'
                                                        });
                                                    }

                                                }
                                            }, 60);
                                            // this.putData(newData);
                                        }),
                                    isDeletable: rowData => ((rowData.actual_hours + rowData.inprogress_hours) === 0),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState({ save: false });
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);

                                                    return { ...prevState, data };
                                                });
                                            }, 60);
                                            this.deleteData(oldData);
                                        }),
                                }}
                            />
                            <Dialog open={this.state.open} maxWidth="xl" onClose={handleClose} aria-labelledby="form-dialog-title">
                                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                                    Select Employee
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        User are filtered according to their role. Please select a employee to assign a task to them.
                                    </DialogContentText>
                                    {/* <Autocomplete
                                        id="combo-box-demo"
                                        fullwidth="true"
                                        value={null}
                                        options={this.state.users}
                                        onChange={this.onTagsChange}
                                        getOptionLabel={(option) => { return option.first_name + " " + option.last_name + " (" + option.email + ")" }}
                                        renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
                                    /> */}
                                    <User3W roleids={this.state.roleUid} mapped={this.state.data} users={this.state.usersAll} onUserSelect={this.handleUserSelect} />
                                </DialogContent>

                            </Dialog>
                        </div>
                    )}
            </div>
        )
    }
}

function addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec ? 1 : null
    if (len) {
        return parseFloat(Number(num).toFixed(len))
    } else {
        return parseFloat(Number(num))
    }
}

function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
}

export default withSnackbar(RoleMapping);