import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import { LoopCircleLoading } from 'react-loadingg';
import { withSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import LeaveCard from '../LMS/LeaveCard';
import { getPrimaryColor } from '../Helper/CommonThings';
import SettingsTab from '../Settings/SettingsTab';

const useStyles = (theme) => ({
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        background: getPrimaryColor(),
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing(8),
        marginLeft: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '50%',
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        width: '20%',
    },
    textbox_names: {
        width: 400,
        marginTop: theme.spacing(8),
    },
    textbox: {
        width: '800px',
        marginTop: theme.spacing(8),
    },
});

var QRCode = require('qrcode.react');
var switch1stA = false;
var changeRq = false;

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {

            },
            isLoading: true,
            saveButton: true,
            openPass: false,
            pass: '',
            openQR: false,
            authurl: '',
            optcode: '',
            roleAll: [],
            designation: null
        }
    }

    handleTextChange(e) {
        // console.log(e.target.id)
        if (e.target.id === 'first_name') {
            var data2 = this.state.data;
            data2.first_name = e.target.value;
            this.setState({
                saveButton: false,
                data: data2
            })
        } else if (e.target.id === 'lastname') {
            var data1 = this.state.data;
            data1.last_name = e.target.value;
            this.setState({
                saveButton: false,
                data: data1
            })
        }
    }

    componentDidMount() {
        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res1 => {

                this.setState({ roleAll: res1.data, });
                Service.get(`/rest-auth/user/`, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res => {
                        // console.log(res.data)
                        if (res.data.twof_auth) {
                            switch1stA = true;
                        }
                        this.setState({ data: res.data, isLoading: false });
                    })
                    .catch(error => {

                    });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        Service.get(`/bb/api/user/detail/get_designation/?uid=${user.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ designation: res.data.designation })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

    }
    onSwitchFail() {
        var data = this.state.data;
        data.twof_auth = !data.twof_auth;
        this.setState({ data: data, openPass: false, });
    }
    onSwitchChange() {

        if (!switch1stA) {

            var data = this.state.data;
            data.twof_auth = !data.twof_auth;
            this.setState({ data: data, openPass: true, });
        } else {
            switch1stA = false;
        }
    }

    handleSubmit() {
        // const json = {
        //     first_name: firstname,
        //     last_name: lastname,
        // }
        // console.log(this)
        this.setState({ isLoading: true });
        Service.put(`/rest-auth/user/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: this.state.data,
        })
            .then(res => {
                this.setState({ isLoading: false, saveButton: true, openPass: false, openQR: false });
                this.props.enqueueSnackbar('Data updated successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: false });
                errorMessage(this.props, error)
            });
    }

    veriftOTP() {
        if (this.state.optcode.length === 6) {
            Service.post('/bb/api/totp/login/' + this.state.optcode + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    if (res.data || res.data === 'true') {
                        this.handleSubmit();
                    } else {
                        var errormessage = 'Information is not correct!';

                        this.props.enqueueSnackbar(errormessage, {
                            variant: 'error'
                        });
                    }

                })
                .catch(error => {
                    var errormessage = 'Information is not correct!';

                    this.props.enqueueSnackbar(errormessage, {
                        variant: 'error'
                    });

                });
        } else {
            this.props.enqueueSnackbar("OTP should 6 digits", {
                variant: 'warning'
            });
        }

    }

    deleteTotp() {
        Service.post('/bb/api/totp/delete/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (changeRq) {
                    Service.get("/bb/api/totp/create/", {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res => {
                            var authurlhere = res.data.split("/totp/");
                            var domainauth = authurlhere[0] + '/totp/BB%20Task%20Management:' + authurlhere[1] + '&issuer=BB%20Task%20Management'
                            this.setState({ openPass: false, openQR: true, authurl: domainauth })
                        })
                        .catch(error => {
                            this.onSwitchFail();
                            // console.log(error)
                            errorMessage(this.props, error)
                        });
                    this.props.enqueueSnackbar('Device Removed. Please add your new device.', {
                        variant: 'success'
                    });
                } else {
                    this.handleSubmit();
                    this.props.enqueueSnackbar('Device Removed.', {
                        variant: 'success'
                    });
                }

            })
            .catch(error => {
                if (!changeRq) {
                    this.onSwitchFail();
                }
                var errormessage = 'Information is not correct!';

                this.props.enqueueSnackbar(errormessage, {
                    variant: 'error'
                });

            });
    }

    verifyUser() {
        Service.post('/rest-auth/login/', {
            data: {
                email: this.state.data.email,
                password: this.state.pass,
            },
        })
            .then(res => {
                if (!changeRq) {
                    if (this.state.data.twof_auth) {
                        Service.get("/bb/api/totp/create/", {
                            headers: {
                                Authorization: "Token " + res.data.key,
                            },
                        })
                            .then(res => {
                                var authurlhere = res.data.split("/totp/");
                                var domainauth = authurlhere[0] + '/totp/BB%20Task%20Management:' + authurlhere[1] + '&issuer=BB%20Task%20Management'
                                this.setState({ openPass: false, openQR: true, authurl: domainauth })
                            })
                            .catch(error => {
                                this.onSwitchFail()
                                // console.log(error)
                                errorMessage(this.props, error)
                            });
                    } else {
                        this.setState({ openPass: false, })
                        this.deleteTotp();
                    }
                } else {
                    changeRq = true;
                    this.setState({ openPass: false, })
                    this.deleteTotp();
                }

            })
            .catch(error => {
                var errormessage = 'Something went wrong!';
                //  console.log(error.data)
                if (!changeRq) {
                    this.onSwitchFail()
                }

                try {

                    if (error.data.non_field_errors) {
                        for (let i = 0; i < error.data.non_field_errors.length; i++) {
                            const element = error.data.non_field_errors[i];
                            if (i === 0) {
                                errormessage = element;
                            } else {
                                errormessage = errormessage + "\n" + element;
                            }

                        }
                    } else if (error.data.email) {
                        for (let i = 0; i < error.data.email.length; i++) {
                            const element = error.data.email[i];
                            if (i === 0) {
                                errormessage = element;
                            } else {
                                errormessage = errormessage + "\n" + element;
                            }

                        }
                    } else if (error.data.password) {
                        for (let i = 0; i < error.data.password.length; i++) {
                            const element = error.data.password[i];
                            if (i === 0) {
                                errormessage = element;
                            } else {
                                errormessage = errormessage + "\n" + element;
                            }

                        }
                    }
                } catch (error) {

                }
                this.props.enqueueSnackbar(errormessage, {
                    variant: 'error'
                });

            });
    }

    handleRoleName() {
        for (let i = 0; i < this.state.roleAll.length; i++) {
            const element = this.state.roleAll[i];
            if (this.state.data.role_id === element.id) {
                return element.name;
            }
        }
        return "Not Available";
    };

    render() {
        const { classes } = this.props;

        const handlePassClose = () => {
            this.setState({ openPass: false, })
            if (!changeRq) {
                this.onSwitchFail()
            }

        };
        const handleQRClose = () => {
            this.setState({ openQR: false, })
            if (!changeRq) {
                this.onSwitchFail()
            }

        };


        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <Box display="flex" flexDirection="row" justifyContent="center" m={1} p={1} bgcolor="background.paper">


                            <form className={classes.form} onSubmit={() => this.handleSubmit()} noValidate>
                                <Box marginTop="50px" flexDirection="row" justifyContent="center" display="flex" padding="15px">
                                    <Box m={1} p={1}>
                                        <Avatar className={classes.avatar} >{this.state.data.first_name.charAt(0)}{this.state.data.last_name.charAt(0)}</Avatar>
                                    </Box>
                                    <Box m={1} p={1}>
                                        <Typography gutterBottom>
                                            Name : {this.state.data.last_name + ", " + this.state.data.first_name}
                                        </Typography>
                                        <Typography gutterBottom>
                                            Email : {this.state.data.email}
                                        </Typography>
                                        <Typography gutterBottom>
                                            Designation : {this.state.designation ? this.state.designation : this.handleRoleName()}
                                        </Typography>
                                    </Box>
                                </Box>


                                {/* <Box display="flex" justifyContent="center" m={1}>
                                            <FormControlLabel
                                                value="start"
                                                control={<Switch checked={this.state.data.twof_auth} onChange={() => { changeRq = false; this.onSwitchChange(); }} color="primary" />}
                                                label="Two Factor Authentication"
                                                labelPlacement="start"
                                            />
                                            <Button
                                                style={{ marginLeft: '15px' }}
                                                disabled={!this.state.data.twof_auth}
                                                onClick={() => { changeRq = true; this.setState({ openPass: true, }) }}
                                                color="primary">
                                                Change Authenticator App
                                            </Button>
                                        </Box> */}
                                {/* <Box display="flex" justifyContent="center">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            disabled={this.state.saveButton}
                                        >
                                            save
                                            </Button>
                                    </Box> */}

                            </form>


                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="center" m={1} p={1} bgcolor="background.paper">
                            {(access.access && access.access.l18a && access.access.l18a.l18a_a) ? (
                                <LeaveCard />
                            ) : (null)}
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="center" m={1} p={1} bgcolor="background.paper">
                            <SettingsTab />
                        </Box>
                        <Dialog open={this.state.openPass} onClose={handlePassClose} fullWidth aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Verify yourself</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Enter Password to continue
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    variant="outlined"
                                    margin="dense"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    value={this.state.pass}
                                    onChange={(e) => { this.setState({ pass: e.target.value, }) }}
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handlePassClose} color="primary">
                                    Cancel
                                </Button>
                                <Button autoFocus onClick={() => this.verifyUser()} color="primary">
                                    Verify
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={this.state.openQR} onClose={handleQRClose} fullWidth aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Scan QR code</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Scan the the QR code in your authenticator app. If in case you can't access your authnticator app we will send codes to your email. You can skip this process and use your email as two factor authentication
                                </DialogContentText>
                                <Box display="flex" justifyContent="center" m={1}>
                                    <QRCode value={this.state.authurl} />
                                </Box>

                                <TextField
                                    autoFocus
                                    variant="outlined"
                                    margin="dense"
                                    name="code"
                                    label="Enter OTP shown in authenticator app."
                                    type="numeric"
                                    id="code"
                                    value={this.state.optcode}
                                    onChange={(e) => { this.setState({ optcode: e.target.value, }) }}
                                    fullWidth
                                />

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleQRClose} color="primary">
                                    Close
                                </Button>
                                <Button onClick={() => {
                                    this.setState({ openQR: false, })
                                    this.handleSubmit();
                                }} color="primary">
                                    Skip
                                </Button>
                                <Button autoFocus onClick={() => this.veriftOTP()} color="primary">
                                    Verify
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}
            </div >
        )
    }
}
export default withStyles(useStyles)(withSnackbar(Profile));