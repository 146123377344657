import React, { Component } from 'react'
// import Chart from 'react-google-charts';
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
// import { errorMessage } from '../Helper/ErrorMessage';
import MaterialTable from 'material-table';
// import moment from "moment";
import { withSnackbar } from 'notistack';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { AppBar, Box, Button, createTheme, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, Select, TextField, ThemeProvider, Toolbar, Typography } from '@material-ui/core';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import moment from 'moment';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getDarkMode, getPrimaryColor, onlyUniqueArr } from '../Helper/CommonThings';
import DateFnsUtils from '@date-io/date-fns';
import CoffeeLoading from 'react-loadingg/lib/CoffeeLoading';
import CloseIcon from '@material-ui/icons/Close';
import { errorMessage } from '../Helper/ErrorMessage';
import { Alert } from '@material-ui/lab';
import Moment from 'react-moment';
import ReactApexChart from 'react-apexcharts';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

const bbColorThemered = createTheme({
    palette: {
        primary: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        secondary: {
            main: '#cca300',
            contrastText: '#fff',
        },
        error: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        type: getDarkMode(),
    },
})

class EarnedValue extends Component {
    constructor(props) {
        super()
        this.state = {
            chartDataTemp: [['Weeks', 'Planned Value', 'Earned Value', 'Earned Value Forecast (trend)', "Actual Cost", "Actual Forecast (trend)"]],
            chartData: [],
            data: {},
            isLoading: true,
            isLoading2: true,
            project: "",
            openSnapshot: false,
            postSnapshotName: null,
            postSnapshotDate: null,
            postSnapshotMonth: null,
            postSnapshotDateLimit1: null,
            postSnapshotDateLimit2: null,
            openCoffee: false,
            openDownloadReport: false,
            downloadReportData: {},
            openProjectReport: false,
            evpAuditStatus: 10,
            projectReportData: {},
            userPdm: [],
            evpSnapshotHistoryId: 0,
            evpSnapshots: [],
            evpSnapshotsYears: [],
            evpSnapshotSelectedYear: 0,
            evpComment: [],
            openComments: false,
            openAddEvpComment: false,
            addEvpComment: "",
            errorTask: [],
            openErrorTask: false,
            evpFailed: false,
            labels: [],
            est_cumulative_cost: [],
            cumulative_earned_cost: [],
            aimed_cumulative_earned_cost: [],
            cumulative_actual_cost: [],
            aimed_cumulative_actual_cost: [],
            cumulative_task_deliver_percent: [],
            cumulative_task_to_be_delivered: [],
            task_billable_percent: [],
            overAllMax: null,
            yaxisMax: null
        }
    }

    componentDidMount() {
        if (token) {
            this.fetchOps()
        } else {
            alert("You don't have access to view this page!.")
            window.close()
        }
    }

    fetchOps() {
        Service.get("/bb/api/tender/project/get_tender_data/?pid=" + this.props.match.params.pid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log((access && access.access && access.access.l4a.l4a_x))

                // console.log(res.data.report_manager, res.data.tenderbid_data.pdm, access.access.l4a.l4a_x)


                if (res.data.audit_status > 9 && (((res.data.report_manager && res.data.report_manager.length > 0 && res.data.report_manager[0].fields.evp) && ((access && access.access && access.access.l4a.l4a_x) || (access && access.access && access.access.l4a.l4a_y))) || (access && access.access && access.access.l4a.l4a_z))) {
                    this.setState({
                        project: res.data,
                        isLoading2: false
                    })
                    Service.get2("/bb/api/report/view/earned_value_report/?tenderid=" + this.props.match.params.pid, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res1 => {
                            // console.log(res1.data)
                            this.setState({
                                data: res1.data,
                                chartData: [],
                                evpComment: [],
                            }, () => {
                                this.chartDataCompile()
                            })
                            this.getEvpSnapshotHistory(this.props.match.params.pid)
                        })
                        .catch(error => {
                            errorMessage(this.props, error)

                            if (error && error.status === 403 && error.data && error.data.length) {
                                console.log(error.data)
                                this.setState({
                                    errorTask: error.data,
                                    openErrorTask: true,
                                    evpFailed: true,
                                })
                                alert('Pheww...Sorry ! We understand your report could not be generated due to some insufficient information to calculate EV. Please click on "OK" to know the issue.')
                            } else {
                                alert("Report is unavailable!.")
                            }

                            // window.close()
                        });
                } else {
                    alert("Report is unavailable!.")
                    // window.close()
                }

            })
            .catch(error => {
                console.log(error)

                // errorMessage(this.props, error)
                alert("Some of the data is not updated. Please update the % complete, and estimated end dates to generate the EVP curve.")
                // window.close()
            });
    }

    chartDataCompile() {
        // var chartData = JSON.parse(JSON.stringify(this.state.chartDataTemp))
        var labels = []
        var est_cumulative_cost = []
        var cumulative_earned_cost = []
        var aimed_cumulative_earned_cost = []
        var cumulative_actual_cost = []
        var aimed_cumulative_actual_cost = []
        var cumulative_task_deliver_percent = []
        var cumulative_task_to_be_delivered = []
        var task_billable_percent = []
        // console.log(chartData)
        for (let i = 0; i < this.state.data.weeklyData.length; i++) {
            const element = this.state.data.weeklyData[i];
            if (element) {
                labels.push(element.name)
                est_cumulative_cost.push((element.est_cumulative_cost === null || element.est_cumulative_cost === undefined) ? null : (element.est_cumulative_cost.toFixed(1)))
                cumulative_earned_cost.push((element.cumulative_earned_cost === null || element.cumulative_earned_cost === undefined) ? null : (element.cumulative_earned_cost.toFixed(1)))
                aimed_cumulative_earned_cost.push((element.aimed_cumulative_earned_cost === null || aimed_cumulative_earned_cost === undefined) ? null : (element.aimed_cumulative_earned_cost.toFixed(1)))
                cumulative_actual_cost.push((element.cumulative_actual_cost === null || element.cumulative_actual_cost === undefined) ? null : (element.cumulative_actual_cost.toFixed(1)))
                aimed_cumulative_actual_cost.push((element.aimed_cumulative_actual_cost === null || element.aimed_cumulative_actual_cost === undefined) ? null : (element.aimed_cumulative_actual_cost.toFixed(1)))
                cumulative_task_deliver_percent.push((element.cumulative_task_deliver_percent === null || element.cumulative_task_deliver_percent === undefined) ? null : (element.cumulative_task_deliver_percent * 100).toFixed(1))
                cumulative_task_to_be_delivered.push((element.cumulative_task_to_be_delivered === null || element.cumulative_task_to_be_delivered === undefined) ? null : (element.cumulative_task_to_be_delivered * 100).toFixed(1))
                task_billable_percent.push((element.task_billable_percent === null || element.task_billable_percent === undefined) ? null : (element.task_billable_percent * 100).toFixed(1))
            }
        }
        var max_1 = Math.max(...est_cumulative_cost)
        var max_2 = Math.max(...cumulative_earned_cost)
        var max_3 = Math.max(...aimed_cumulative_earned_cost)
        var max_4 = Math.max(...cumulative_actual_cost)
        var max_5 = Math.max(...aimed_cumulative_actual_cost)
        var overAllMax = Math.max(max_1, max_2, max_3, max_4, max_5)
        var yaxisMax = Math.max(...task_billable_percent)

        this.setState({
            labels: labels,
            est_cumulative_cost,
            cumulative_earned_cost,
            aimed_cumulative_earned_cost,
            cumulative_actual_cost,
            aimed_cumulative_actual_cost,
            cumulative_task_deliver_percent,
            cumulative_task_to_be_delivered,
            task_billable_percent,
            overAllMax,
            yaxisMax,
            isLoading: false,
            errorTask: this.state.data.date_error_tasks
        })
    }

    showGraph() {
        const options = {
            series: [
                {
                    name: "Planned Value",
                    data: this.state.est_cumulative_cost,
                    color: '#99b3e5'
                },
                {
                    name: "Earned Value",
                    data: this.state.cumulative_earned_cost,
                    color: '#ed9c88'
                },
                {
                    name: "Earned Value Forecast(trend)",
                    data: this.state.aimed_cumulative_earned_cost,
                    color: '#ffcc80'
                },
                {
                    name: "Actual Cost",
                    data: this.state.cumulative_actual_cost,
                    color: '#109618'
                },
                {
                    name: "Actual Forecast(trend)",
                    data: this.state.aimed_cumulative_actual_cost,
                    color: '#cc80cc'
                },
                {
                    name: "Task Planned(%)",
                    data: this.state.cumulative_task_to_be_delivered,
                    color: '#66C7F4',
                },
                {
                    name: "Task Delivered(%)",
                    data: this.state.cumulative_task_deliver_percent,
                    color: '#99C2A2'
                },
                {
                    name: "Billable",
                    data: this.state.task_billable_percent,
                    color: '#FEB019'
                }
            ],
            chart: {
                height: 500,
                width: '100%',
                type: "line"
            },
            theme: {
                mode: getDarkMode() === 'dark' ? 'dark' : 'light',
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 3,
                curve: "straight",
                dashArray: [0, 0, 5, 0, 5, 0, 0, 0]
            },
            legend: {
                tooltipHoverFormatter: function (val, opts) {
                    return (
                        val +
                        " - <strong>" +
                        opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                        "</strong>"
                    );
                },
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 6
                }
            },
            xaxis: {
                labels: {
                    trim: false
                },
                categories: this.state.labels
            },
            yaxis: [
                {
                    seriesName: "Planned Value",
                    min: 0,
                    max: this.state.overAllMax,
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true,
                    },
                    showAlways: true,
                },
                {
                    seriesName: "Earned Value",
                    show: false,
                    min: 0,
                    max: this.state.overAllMax,
                },
                {
                    seriesName: "Earned Value Forecast(trend)",
                    show: false,
                    min: 0,
                    max: this.state.overAllMax,
                },
                {
                    seriesName: "Actual Cost",
                    show: false,
                    min: 0,
                    max: this.state.overAllMax,
                },
                {
                    seriesName: "Actual Forecast(trend)",
                    show: false,
                    min: 0,
                    max: this.state.overAllMax,
                },
                {
                    opposite: true,
                    min: 0,
                    // max: (this.state.yaxisMax !== null && this.state.yaxisMax > 100) ? this.state.yaxisMax : 100,
                    max: 100,
                    seriesName: "Task Delivered",
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true,
                    },
                    title: {
                        text: "Task Percent"
                    },
                    show: true,
                    showAlways: true,
                    labels: {
                        show: true,
                        formatter: (value) => {
                            if (value) {
                                return value.toFixed(1) + "%"
                            }
                        }
                    },
                },
                {
                    opposite: true,
                    min: 0,
                    // max: (this.state.yaxisMax !== null && this.state.yaxisMax > 100) ? this.state.yaxisMax : 100,
                    max: 100,
                    seriesName: "Task Planned",
                    show: false,
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true,
                    },
                    labels: {
                        show: true,
                        formatter: (value) => {
                            if (value) {
                                return value.toFixed(1) + "%"
                            }
                        },
                    },
                },
                {
                    opposite: true,
                    min: 0,
                    // max: (this.state.yaxisMax !== null && this.state.yaxisMax > 100) ? this.state.yaxisMax : 100,
                    max: 100,
                    seriesName: "Billable",
                    show: false,
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true,
                    },
                    labels: {
                        show: true,
                        formatter: (value) => {
                            if (value) {
                                return value.toFixed(1) + "%"
                            }
                        },
                    },
                },
            ],
            grid: {
                borderColor: "#f1f1f1"
            }
        };
        return <div style={{ width: '100%', padding: '10px' }
        }> <ReactApexChart options={options} series={options.series} height={600} width={'100%'} type="line" /></div >
    }

    getEvpSnapshotHistory(data) {

        var url = "/bb/api/tender/evp/snapshot/get_snapshot_history/?pid=" + data;

        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                var years = []
                var dataTo = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    // if (element.audit_status === audit_status) {
                    //     dataTo.push(element)
                    //     years.push(moment(element.month_of_snapshot).format("YYYY"))
                    // }
                    dataTo.push(element)
                    years.push(moment(element.month_of_snapshot).format("YYYY"))
                }
                years = years.filter(onlyUniqueArr)
                // console.log(years)
                this.setState({
                    evpSnapshots: dataTo,
                    openCoffee: false,
                    openProjectReport: true,
                    evpSnapshotsYears: years
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

    }

    getEvpSnapshot(data) {
        this.setState({
            isLoading: true
        })
        var url = "/bb/api/tender/evp/snapshot/get_snapshot/?id=" + data;

        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)

                this.setState({
                    data: res.data,
                    chartData: [],
                    openCoffee: false,
                    openProjectReport: true,
                    // evpComment: evpComment,
                }, () => {
                    this.chartDataCompile()
                })

                Service.get("/bb/api/tender/evp/snapshot/get_snapshot_comment/?id=" + data, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res1 => {
                        // console.log(res1.data)

                        var evpComment = []

                        if (res1.data.comments) {
                            evpComment = res1.data.comments
                        }

                        this.setState({

                            evpComment: evpComment,
                        })
                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    deleteEvpSnapshot(data) {
        var url = "/bb/api/tender/evp/snapshot/" + data + "/";

        this.setState({
            openCoffee: true,
            openProjectReport: false
        })

        Service.delete(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)

                this.fetchOps()
                this.setState({
                    openDownloadReport: false,
                    openCoffee: true,
                    evpSnapshotHistoryId: 0,
                    evpSnapshots: [],
                    evpSnapshotSelectedYear: 0,
                    evpSnapshotsYears: [],
                })

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    putEvpCommentSnapshot(data, id) {
        var url = "/bb/api/tender/evp/snapshot/" + id + "/";

        Service.put(url, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                id: id,
                comments: data
            }
        })
            .then(res => {
                // console.log(res.data)

                this.props.enqueueSnackbar('Comment successfully posted.', {
                    variant: 'success'
                });

            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    takeSnapshot(data, audit_status, postData) {
        var url = "/bb/api/tender/evp/snapshot/create_snapshot/?pid=" + data + "&audit_status=" + audit_status;

        Service.post(url, {
            headers: {
                Authorization: "Token " + token,
            },
            data: postData
        })
            .then(res => {
                this.props.enqueueSnackbar('Snapshot taken successfully', {
                    variant: 'success'
                });
                this.setState({
                    postSnapshotDate: null,
                    postSnapshotName: null,
                    postSnapshotMonth: null,
                    postSnapshotDateLimit1: null,
                    postSnapshotDateLimit2: null
                })
                this.getEvpSnapshotHistory(this.props.match.params.pid)
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    handleSnapshotSubmit() {

        // console.log(moment(this.state.postSnapshotMonth).format("DD-MM-YYYY"))
        if (this.state.postSnapshotName && this.state.postSnapshotName.trim() && moment(this.state.postSnapshotDate).isBetween(this.state.postSnapshotDateLimit1, this.state.postSnapshotDateLimit2)) {
            var data = {
                name: this.state.postSnapshotName,
                timesheet_last_date: moment(this.state.postSnapshotDate).format("DD-MM-YYYY"),
                month_date: moment(this.state.postSnapshotMonth).format("DD-MM-YYYY")
            }
            // console.log(data)
            this.takeSnapshot(this.props.match.params.pid, 10, data)
            this.setState({
                openCoffee: true,
                openSnapshot: false
            })
        } else {
            this.props.enqueueSnackbar('Actual timesheet end date should be within the month of snapshot report', {
                variant: 'warning'
            });
        }

    }

    refreshKPI() {
        this.setState({
            isLoading: true
        })
        var url = "/bb/api/report/view/refresh_project_kpi/?tenderid=" + this.props.match.params.pid;

        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar(res.data, {
                    variant: 'success'
                });
                this.setState({
                    isLoading: false
                })

            })
            .catch(error => {
                this.setState({
                    isLoading: false
                })
                errorMessage(this.props, error)
            });
    }

    render() {

        const handleClose4 = () => {
            this.setState({ openSnapshot: false, });
        };

        const handleChangeWithId = (event) => {
            // console.log(event.target.id, event.target.value)
            this.setState({ [event.target.id]: event.target.value });
        }

        const handleChangeSelect = (event) => {
            if (event.target.value === 0) {
                this.fetchOps()

            } else {
                this.getEvpSnapshot(event.target.value)
            }

            this.setState({
                openProjectReport: false,
                openCoffee: true,
                evpSnapshotHistoryId: event.target.value
            })
        };

        const handleChangeSelect2 = (event) => {
            if (event.target.value === 0) {
                this.setState({
                    openDownloadReport: false,
                    openCoffee: true,
                    openProjectReport: false,
                    evpSnapshotHistoryId: 0,
                    evpSnapshots: [],
                    evpSnapshotSelectedYear: 0,
                    evpSnapshotsYears: [],
                })
                this.fetchOps()
            } else {
                var defaultSelectId = 0
                for (let i = 0; i < this.state.evpSnapshots.length; i++) {
                    const element = this.state.evpSnapshots[i];
                    if (!defaultSelectId && parseInt(moment(element.month_of_snapshot).format("YYYY")) === parseInt(event.target.value)) {
                        defaultSelectId = element.id
                        break;
                    }
                }
                if (defaultSelectId !== 0) {
                    this.getEvpSnapshot(defaultSelectId)
                }

                this.setState({
                    openProjectReport: (defaultSelectId === 0),
                    openCoffee: (defaultSelectId !== 0),
                    evpSnapshotHistoryId: defaultSelectId,
                    evpSnapshotSelectedYear: parseInt(event.target.value)
                })
            }
        };

        const handleDateChange = (date) => {

            this.setState({
                postSnapshotDate: date
            })
        };

        const handleDateChange2 = (date) => {
            var startOfMonth = moment(date).clone().startOf('month');
            var endOfMonth = moment(date).clone().endOf('month');
            // console.log(startOfMonth, endOfMonth)
            this.setState({
                postSnapshotMonth: startOfMonth,
                postSnapshotDateLimit1: startOfMonth,
                postSnapshotDateLimit2: endOfMonth,
            })
        };

        const handleEvpCommentlose = () => {
            this.setState({ openComments: false, });

        };

        const handleAddEvpCommentClose = () => {
            this.setState({ openAddEvpComment: false, });

        };

        const handleEvpCommentChange = (event) => {
            this.setState({
                addEvpComment: event.target.value
            })
        };

        const handleErrorTaskClose = () => {
            this.setState({ openErrorTask: false, });
        };

        return (
            <div>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Earned Value Performance for {this.state.project.tender_name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {(this.state.isLoading || this.state.isLoading2) ? (
                    (this.state.evpFailed) ? (
                        <h3>Report Unavailable</h3>
                    ) : (<LoopCircleLoading color={getPrimaryColor()} />)

                ) : (
                    <Grid container spacing={3}>
                        {(this.state.errorTask.length) ? (
                            <Grid item xs={12}>
                                <Box m={1} p={1} display="flex" justifyContent="center">
                                    <Alert severity="error"
                                        action={
                                            <Button color="inherit" size="small"
                                                onClick={() => this.setState({ openErrorTask: true })}>
                                                View Tasks
                                            </Button>
                                        }
                                    >
                                        {'Pheww...Sorry ! We understand your report could not be generated due to some insufficient information to calculate EV. Please click on "View Tasks" to know the issue.'}
                                        <br />
                                        {'Note: Its mandatory to have both the Baseline & Actual / Expected dates for each task.'}
                                    </Alert>
                                </Box>

                            </Grid>
                        ) : (null)}

                        <Grid item xs={9}>
                            {this.showGraph()}
                        </Grid>
                        <Grid item xs={3}>
                            <Box display="flex" >
                                <Box>
                                    <FormControl style={{ zoom: 1, marginRight: "30px" }}>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={this.state.evpSnapshotSelectedYear}
                                            onChange={handleChangeSelect2}
                                        >
                                            <MenuItem value={0}>
                                                <em>Live</em>
                                            </MenuItem>
                                            {this.state.evpSnapshotsYears.map(item => {
                                                return <MenuItem value={item}>{item}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>

                                {(this.state.evpSnapshotSelectedYear > 0) ? (
                                    <Box >
                                        <FormControl style={{ zoom: 1, marginRight: "30px" }}>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={this.state.evpSnapshotHistoryId}
                                                onChange={handleChangeSelect}
                                            >
                                                {this.state.evpSnapshots.map(item => {
                                                    if (parseInt(moment(item.month_of_snapshot).format("YYYY")) === this.state.evpSnapshotSelectedYear) {
                                                        return <MenuItem value={item.id}>{moment(item.month_of_snapshot).format("MMM_YYYY") + "_" + item.name}</MenuItem>

                                                    } else {
                                                        return null
                                                    }
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                ) : (null)}

                                {(this.state.evpSnapshotSelectedYear === 0) ? (
                                    <Box >
                                        <Button color="primary" style={{ marginRight: '25px' }} size="small" onClick={() => {
                                            this.setState({
                                                openSnapshot: true
                                            })
                                        }}>Take Snapshot</Button>
                                    </Box>
                                ) : (null)}

                                <Button color="primary" style={{ marginRight: '30px' }} size="small" onClick={() => {
                                    window.open("https://faqs.bbparam.com/paramfaqs/availableworkbook#q3")
                                }}>Help</Button>

                                <Button color="primary" style={{ marginRight: '30px' }} size="small" onClick={() => {
                                    this.refreshKPI()
                                }}>Refresh KPI</Button>

                            </Box>
                            {(this.state.evpSnapshotHistoryId !== 0) ? (
                                <Box display="flex" flexDirection="row" p={1} m={1} >
                                    <Button color="primary" style={{ marginRight: '25px' }} size="small" onClick={() => {
                                        // console.log(this.state.data)
                                        this.setState({ openComments: true })
                                    }}>Comments</Button>
                                    <ThemeProvider theme={bbColorThemered} >
                                        <Button color="primary" style={{ marginRight: '25px' }} size="small" onClick={() => {
                                            this.deleteEvpSnapshot(this.state.evpSnapshotHistoryId)
                                        }}>Delete</Button>
                                    </ThemeProvider>
                                </Box>
                            ) : (null)}

                            <Box display="flex" flexDirection="row" p={1} m={1} >
                                SPI: {this.state.data.weeklyData[this.state.data.current_week_index].spi.toFixed(2)}
                            </Box>
                            <Box display="flex" flexDirection="row" p={1} m={1} >
                                CPI: {this.state.data.weeklyData[this.state.data.current_week_index].cpi.toFixed(2)}
                            </Box>
                            <Box display="flex" flexDirection="row" p={1} m={1} >
                                Time: {(this.state.data.delayedWeeks === 0) ? (
                                    this.state.data.delayedWeeks + " week - On Schedule"
                                ) : (this.state.data.delayedWeeks > 0) ? (
                                    this.state.data.delayedWeeks + " weeks delay"
                                ) : (this.state.data.delayedWeeks < 0) ? (
                                    (this.state.data.delayedWeeks * -1) + " weeks ahead"
                                ) : (this.state.data.delayedWeeks)}
                            </Box>
                            <Box display="flex" flexDirection="row" p={1} m={1} >
                                EAC (trend): {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.state.data.eac)}
                            </Box>
                            <Box display="flex" flexDirection="row" p={1} m={1} >
                                Cost to Come (trend): {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.state.data.cost_to_come)}
                            </Box>
                            <Box display="flex" flexDirection="row" p={1} m={1} >
                                Vs Budget (trend): {this.state.data.vs_budget}%
                            </Box>
                            <Box display="flex" flexDirection="row" p={1} m={1} >
                                {(this.state.evpComment && this.state.evpComment.length !== 0) ? "Comments by " + this.state.evpComment[0].user_name + " --> " + this.state.evpComment[0].comment : null}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <MaterialTable
                                style={{ margin: '10px' }}
                                title={
                                    <div>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                            }}
                                            fontWeight="fontWeightBold" fontSize={15}>Late Deliverables (the below tasks should have been completed as of today)</Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                            }}
                                        ><p style={{ color: 'blue' }}>Number of Tasks : {this.state.data.tasksOrdered.length}</p></Box>
                                    </div>
                                }
                                columns={[
                                    { title: 'Task ID', field: 'id' },
                                    {
                                        title: 'Task', field: 'name',
                                        render: rowData => rowData.decs ? rowData.decs : rowData.name,
                                    },
                                    { title: 'Cost', field: 'est_cost', defaultSort: 'desc' },
                                    { title: '% Complete', field: 'comp_percnt', defaultGroupOrder: 0 },

                                ]}
                                data={this.state.data.tasksOrdered}
                                options={{
                                    grouping: true,
                                    padding: "dense",
                                }}
                            />
                        </Grid>
                    </Grid>
                )
                }

                <Dialog maxWidth="sm" open={this.state.openCoffee} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <Box m={5} p={2}>
                            <CoffeeLoading />
                        </Box>
                    </DialogContent>
                </Dialog>

                <Dialog open={this.state.openSnapshot} onClose={handleClose4} aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title1" onClose={handleClose4}>
                        <Box display="flex" >
                            <Box flexGrow={1}>
                                Take Snapshot
                            </Box>
                            <Box >
                                <IconButton onClick={handleClose4} aria-label="close" size="small">
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box display="flex">
                            <TextField id="postSnapshotName" fullWidth style={{ marginBottom: '25px' }} type="text" label="Snapshot Name" onChange={handleChangeWithId} />
                        </Box>
                        <Box display="flex">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            fullWidth
                                            variant="inline"
                                            id="postSnapshotMonth"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="Month of the Snapshot"
                                            helperText="Select month of the snapshot"
                                            value={this.state.postSnapshotMonth}
                                            onChange={handleDateChange2}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="postSnapshotDate"
                                            label="Actual hours upto(dd/MM/yyyy)"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={this.state.postSnapshotDate}
                                            onChange={handleDateChange}
                                            disabled={!this.state.postSnapshotMonth}
                                            minDate={this.state.postSnapshotDateLimit1}
                                            maxDate={this.state.postSnapshotDateLimit2}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            helperText="Select the date upto which the Actual hours data to be updated for the report"
                                        />
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Box>
                        {/* <TextField id="postSnapshotDate" type="text" label="Timesheet Last Date" variant="outlined" onChange={handleChangeWithId} /> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose4} color="primary">
                            Close
                        </Button>
                        <Button onClick={() => this.handleSnapshotSubmit()} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openComments} maxWidth={"lg"} fullWidth onClose={handleEvpCommentlose} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <MaterialTable
                            title={this.state.project.tender_name}
                            columns={[
                                {
                                    title: 'User', field: 'user_name', editable: 'never',
                                },
                                {
                                    title: 'Comment', field: 'comment',
                                },
                                {
                                    title: 'Commented On', field: 'date_created', type: 'datetime', editable: 'never', defaultSort: 'desc',
                                },
                            ]}
                            data={this.state.evpComment}
                            options={{
                                padding: "dense",
                                actionsColumnIndex: -1
                            }}
                            actions={[
                                {
                                    icon: 'add',
                                    tooltip: 'Add Comment',
                                    isFreeAction: true,
                                    onClick: (event) => { this.setState({ openAddEvpComment: true }) },
                                },
                            ]}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEvpCommentlose} color="primary">
                            Close
                        </Button>

                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openAddEvpComment}
                    onClose={handleAddEvpCommentClose}
                    maxWidth={"sm"}
                    fullWidth
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Fill you comment below"}</DialogTitle>
                    <DialogContent>
                        <TextField
                            id="outlined-multiline-static"
                            label="Comment"
                            multiline
                            rows={4}
                            fullWidth
                            value={this.state.addEvpComment}
                            onChange={handleEvpCommentChange}
                            variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAddEvpCommentClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            if (this.state.addEvpComment && this.state.addEvpComment.trim()) {

                                this.setState(prevState => {
                                    var newData = {}
                                    let evpComment = [...prevState.evpComment];
                                    // console.log(evpComment)
                                    newData.user_name = user.first_name + " " + user.last_name
                                    newData.user = user.id
                                    newData.date_created = new Date()
                                    newData.comment = this.state.addEvpComment.trim();
                                    // console.log(newData)
                                    evpComment = [...[newData], ...evpComment];
                                    this.putEvpCommentSnapshot(evpComment, this.state.evpSnapshotHistoryId);
                                    return { ...prevState, evpComment: evpComment, openAddEvpComment: false };
                                });
                            } else {
                                this.props.enqueueSnackbar('Please fill the comment!', {
                                    variant: 'warning'
                                });
                            }

                        }} color="primary" autoFocus>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openErrorTask} maxWidth={"xl"} onClose={handleErrorTaskClose} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <Box display="flex" flexDirection="row-reverse">
                            <Button color='primary' onClick={handleErrorTaskClose}>Close</Button>
                        </Box>
                        <div style={{ display: 'flex' }}>
                            <MaterialTable
                                title="Error Tasks"
                                columns={[
                                    {
                                        title: 'Deliverables Type', field: 't_wbs_data.pid_data.type', editable: 'never',
                                        lookup: { 0: 'Budget', 1: 'Change', },
                                    },
                                    {
                                        title: 'Deliverables Schedule', field: 't_wbs_data.pid_data.name', editable: 'never',
                                    },
                                    {
                                        title: 'WBS', field: 't_wbs_data.name', editable: 'never',
                                    },
                                    {
                                        title: 'ID', field: 'id', editable: 'never', defaultSort: 'asc'
                                    },
                                    {
                                        title: 'Tasks', field: 'name', editable: 'never',
                                        render: rowData => rowData.decs ? rowData.decs : rowData.name
                                    },
                                    {
                                        title: 'Baseline Start', field: 'from_date', type: 'date',
                                        render: rowData => <Moment local format="DD/MM/YYYY">{rowData.from_date}</Moment>
                                    },
                                    {
                                        title: 'Baseline Finish', field: 'to_date', type: 'date',
                                        render: rowData => <Moment local format="DD/MM/YYYY">{rowData.to_date}</Moment>
                                    },
                                    {
                                        title: 'Complete (%)', field: 'comp_percnt',
                                        lookup: { 0: '0%', 5: '5%', 10: '10%', 20: '20%', 30: '30%', 40: '40%', 50: '50%', 60: '60%', 65: '65%', 70: '70%', 80: '80%', 90: '90%', 100: '100%' },
                                    },
                                    {
                                        title: 'Actual/Expected Start', field: 'act_exp_start', type: 'date',
                                        render: rowData => (rowData.act_exp_start) ? (<Moment local format="DD/MM/YYYY">{rowData.act_exp_start}</Moment>) : ('NA')
                                    },
                                    {
                                        title: 'Actual/Expected Finish', field: 'act_exp_finish', type: 'date',
                                        render: rowData => (rowData.act_exp_finish) ? (<Moment local format="DD/MM/YYYY">{rowData.act_exp_finish}</Moment>) : ('NA')
                                    },
                                ]}
                                data={this.state.errorTask}
                                options={{
                                    padding: "dense",
                                    actionsColumnIndex: -1,
                                    paging: false
                                }}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default withSnackbar(EarnedValue)
