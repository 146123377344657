import React, { Component } from 'react'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReactPlayer from 'react-player';
import { IconButton } from '@mui/material';
import { Box } from '@material-ui/core';

class BroadCastReactPlayer extends Component {
    render() {
        return (
            <div>
                {this.props.data.support_url !== null ?
                    <ReactPlayer width="100%" height='90%' url={this.props.data.support_url} controls={true} pip={true} stopOnUnmount={false}
                        // light={value.thumbnail}
                        playing
                        playIcon={<IconButton><PlayArrowIcon color="primary" sx={{ fontSize: 120 }} /></IconButton>}
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload',
                                    onContextMenu: e => e.preventDefault()
                                }
                            }
                        }}
                    /> :
                    <Box display='flex' flexDirection={'row'} justifyContent='center' height='500px' alignItems={'center'} >
                        No record availbale
                    </Box>}
            </div>
        )
    }
}
export default BroadCastReactPlayer
