import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import Tutorials from './Tutorials';
import CloseIcon from '@material-ui/icons/Close';

class NewTabTutorials extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    render() {
        return (
            <div>
                <AppBar position="fixed" color='primary'>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => window.close()}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" >
                            Tutorials
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{ marginTop: '70px' }}>
                    <Tutorials />
                </div>
            </div>
        );
    }
}

export default NewTabTutorials;