import React, { PureComponent } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { LoopCircleLoading } from 'react-loadingg';
import { getDarkMode, getPrimaryColor } from '../../../Helper/CommonThings';
import { screenSizeCheck } from '../../../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import { Box, TextField } from '@material-ui/core';

const StickyTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: getDarkMode() === 'dark' ? '#424242' : '#fafafa',
        position: "sticky",
        right: 0,
        left: 0,
        zIndex: theme.zIndex.appBar + 2
    },
    body: {
        backgroundColor: getDarkMode() === 'dark' ? '#424242' : '#fafafa',
        minWidth: "30vw",
        position: "sticky",
        right: 0,
        left: 0,
        zIndex: theme.zIndex.appBar + 1
    }
}))(TableCell);

class TaskEstimate extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            roles: [],
            tasks: this.props.value.tasks,
            isLoading: true
        }
    }
    componentDidMount() {
        var roles_array = []
        let checked_roles = this.props.roles
        for (let i = 0; i < checked_roles.length; i++) {
            const element = checked_roles[i];
            if (element.fm_checked) {
                roles_array.push(element)
            }
        }
        this.setState({ roles: roles_array, isLoading: false })
    }

    costCalc(rowData) {
        try {
            var cost = 0;
            for (let i = 0; i < rowData.roles.length; i++) {
                const element = rowData.roles[i];
                if (element.fm_checked) {
                    cost = cost + this.costCalc2(element)
                }
            }
            return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
        } catch (error) {
            return 0;
        }

    }

    costCalc2(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
                const element = this.props.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
                const element = this.props.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costuk;
                }
            }
        }
        return 0;
    }

    hourCalc(rowData) {
        try {
            var cost = 0;
            for (let i = 0; i < rowData.roles.length; i++) {
                const element = rowData.roles[i];
                if (element.fm_checked) {
                    cost = cost + parseFloat(element.hours);
                }
            }
            return cost.toFixed(2);
        } catch (error) {
            return 0;
        }
    }

    getHours(value, roles, country) {
        for (let i = 0; i < roles.length; i++) {
            const element = roles[i];
            if (element.t_role === value.t_role) {
                if (element.country === country) {
                    return element.hours
                }
            }
        }
    }

    handleHoursChange(event, value, roles, country) {
        var go_on = false
        var number = Number(event.target.value)
        for (let i = 0; i < roles.length; i++) {
            const element = roles[i];
            if ((element.t_role === value.t_role) && (element.country === country)) {
                if (element.non_task_role && event.target.value === "") {
                    roles.splice(i, 1)
                } else {
                    if (number < Number(0)) {
                        number = Number(0)
                    }
                    element['hours'] = (event.target.value ? number.toString() : 0)
                }
                go_on = true
                break
            }
        }
        if (!go_on && event.target.value !== "") {
            if (number < Number(0)) {
                number = Number(0)
            }
            var json = {
                // id: value.id,
                country: country,
                grade: value.grade,
                name: value.name,
                hours: number,
                t_role: value.t_role,
                non_task_role: true,
                fm_checked: true
            }
            roles.push(json)
        }
        this.forceUpdate()
    }

    dsHourCalc() {
        let selected_roles = this.props.value.selected_roles ? this.props.value.selected_roles : []
        var cost = 0;
        for (let k = 0; k < this.state.tasks.length; k++) {
            const element = this.state.tasks[k];
            if (element.fm_checked) {
                for (let j = 0; j < element.roles.length; j++) {
                    const taskElement = element.roles[j];
                    let roleId = taskElement.t_role
                    if (selected_roles.includes(roleId)) {
                        cost = cost + parseFloat(taskElement.hours);
                    }
                }
            }
        }

        return cost.toFixed(2);
    }

    dsCostCalc() {
        let selected_roles = this.props.value.selected_roles ? this.props.value.selected_roles : []
        var cost = 0;
        for (let k = 0; k < this.state.tasks.length; k++) {
            const element = this.state.tasks[k];
            if (element.fm_checked) {
                for (let j = 0; j < element.roles.length; j++) {
                    const taskElement = element.roles[j];
                    let roleId = taskElement.t_role
                    if (selected_roles.includes(roleId)) {
                        cost = cost + this.costCalc2(taskElement)
                    }
                }
            }
        }
        return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
    }

    render() {
        return (
            (this.state.isLoading) ? (
                <LoopCircleLoading color={getPrimaryColor()} />
            ) : (
                <div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StickyTableCell>{this.props.value.name + " (Hours: " + this.dsHourCalc() + ", Amount: " + this.dsCostCalc() + ")"}</StickyTableCell>
                                    {this.state.roles.map((value, i) =>
                                        <TableCell align="right">{value.name + " (" + value.grade.toUpperCase() + ")"}</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.tasks.filter(item => item.fm_checked).map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StickyTableCell component="th" scope="row" >

                                            <Box sx={{
                                                alignItems: 'stretch',
                                                flexDirection: 'row',
                                            }}>
                                                <Box fontSize={screenSizeCheck() ? '12px' : '14px'} fontWeight={'bold'}>
                                                    {row.decs ? row.decs : row.name}
                                                </Box>
                                                <Box mt={1} sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly',
                                                    borderRadius: 1,
                                                }}>
                                                    <Box fontSize={screenSizeCheck() ? '10px' : '12px'} fontWeight={'bold'}>
                                                        From Date: {row.from_date ? moment(row.from_date).format('DD/MM/YYYY') : 'NA'}
                                                    </Box>
                                                    <Box fontSize={screenSizeCheck() ? '10px' : '12px'} fontWeight={'bold'}>
                                                        To Date: {row.to_date ? moment(row.to_date).format('DD/MM/YYYY') : 'NA'}
                                                    </Box>
                                                    <Box fontSize={screenSizeCheck() ? '10px' : '12px'} fontWeight={'bold'}>
                                                        hours: {this.hourCalc(row)}
                                                    </Box>
                                                    <Box fontSize={screenSizeCheck() ? '10px' : '12px'} fontWeight={'bold'}>
                                                        Cost: {this.costCalc(row)}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </StickyTableCell>
                                        {this.state.roles.map((value, i) =>
                                            <TableCell align="right" style={{ zoom: 0.9 }}>
                                                <Box display='flex' flexDirection='column'>
                                                    <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                                                        {/* <Box fontSize={screenSizeCheck() ? '10px' : '12px'} marginRight='11px' >UK</Box> */}
                                                        <Box >
                                                            <TextField
                                                                id="demo-helper-text-aligned-no-helper"
                                                                label="UK"
                                                                type={'number'}
                                                                variant='outlined'
                                                                size='small'
                                                                fontSize={screenSizeCheck() ? '10px' : '12px'}
                                                                style={{ width: '90px' }}
                                                                margin='dense'
                                                                value={this.getHours(value, row.roles, 'uk')}
                                                                InputLabelProps={{ fontSize: '10px' }}
                                                                onChange={(e) => this.handleHoursChange(e, value, row.roles, "uk")}
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' >
                                                        {/* <Box fontSize={screenSizeCheck() ? '10px' : '12px'} marginRight='11px' >IN</Box> */}
                                                        <Box >
                                                            <TextField
                                                                id="demo-helper-text-aligned-no-helper"
                                                                label="India"
                                                                type={'number'}
                                                                variant='outlined'
                                                                fontSize={screenSizeCheck() ? '10px' : '12px'}
                                                                style={{ width: '90px' }}
                                                                margin='dense'
                                                                value={this.getHours(value, row.roles, 'in')}
                                                                onChange={(e) => this.handleHoursChange(e, value, row.roles, "in")}
                                                            />
                                                        </Box>
                                                    </Box>

                                                    <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' >
                                                        {/* <Box fontSize={screenSizeCheck() ? '10px' : '12px'} marginRight='11px' >IN</Box> */}
                                                        <Box >
                                                            <TextField
                                                                id="demo-helper-text-aligned-no-helper"
                                                                label="Srilanka"
                                                                type={'number'}
                                                                variant='outlined'
                                                                fontSize={screenSizeCheck() ? '10px' : '12px'}
                                                                style={{ width: '90px' }}
                                                                margin='dense'
                                                                value={this.getHours(value, row.roles, 'sl')}
                                                                onChange={(e) => this.handleHoursChange(e, value, row.roles, "sl")}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div >
            )
        )
    }
}

export default TaskEstimate