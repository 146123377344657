import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Service from "../../Networking/networkutils";
import { getCookie } from '../../Helper/CookieHelper';
import { CoffeeLoading } from 'react-loadingg';
import { withSnackbar } from 'notistack';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));

const excelColumns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ', 'BA', 'BB', 'BC', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BK'];

class DownloadDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            capacityCheck: false,
            data: [],
            isLoading: false
        }
    }

    s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    getReport = (type) => {

        if (this.state.data.length !== 0) {
            if (type === 'months') {
                this.downloadByMonths()
            }
            if (type === 'weeks') {
                this.downloadByWeeks()
            }
            return;
        }
        this.setState({ isLoading: true })
        let pid = [];
        let billable = [];
        this.props.projects.forEach(ele => pid.push(ele.id));
        this.props.billable.forEach(ele => billable.push(ele.id));
        Service.post2("/bb/api/report/view/demand_vs_capacity_view/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: { pid: pid, audit_status: [0, 10, 11], billable: billable }
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false })
                if (type === 'months') {
                    this.downloadByMonths()
                }
                if (type === 'weeks') {
                    this.downloadByWeeks()
                }
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false })
            })
    }

    downloadByMonths() {
        let data = this.state.data;

        // console.log(data)
        var sheets = ["Demand"]

        if ((access.access && access.access.l15a && access.access.l15a.l15a_j)) {
            sheets.push("Revenue")
        }

        if (this.state.capacityCheck === true) {
            sheets.push("Capacity")
        }
        var sheetData = {}

        var exportData = {}

        try {

            sheetData["Demand"] = {
                '!ref': "A1:" + excelColumns[data[0].month_data.length] + (data.length + 1),
                '!cols': [
                    { wch: 35 }
                ],
                'A1': { t: "s", v: "Role" },
            }

            if ((access.access && access.access.l15a && access.access.l15a.l15a_j)) {
                sheetData["Revenue"] = {
                    '!ref': "A1:" + excelColumns[data[0].month_data.length] + (data.length + 1),
                    '!cols': [
                        { wch: 35 }
                    ],
                    'A1': { t: "s", v: "Role" },
                }
            }

            sheetData["Capacity"] = {
                '!ref': "A1:" + excelColumns[data[0].month_data.length] + (data.length + 1),
                '!cols': [
                    { wch: 35 }
                ],
                'A1': { t: "s", v: "Role" },
            }
            let col = {}
            let capacity_col = {}

            for (let i = 0; i < data[0].month_data.length; i++) {
                col = { ...col, [excelColumns[i + 1] + '1']: { t: 's', v: data[0].month_data[i].name } }
                capacity_col = { ...capacity_col, [excelColumns[i + 1] + '1']: { t: 's', v: data[0].month_data[i].name } }
            }

            let user_sheet = sheetData["Demand"]
            let revenue_sheet = sheetData["Revenue"]
            let capacity_user_sheet = sheetData["Capacity"]

            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                user_sheet["A" + (i + 2)] = { t: "s", v: element.name }
                if ((access.access && access.access.l15a && access.access.l15a.l15a_j)) {
                    revenue_sheet["A" + (i + 2)] = { t: "s", v: element.name }
                }
                capacity_user_sheet["A" + (i + 2)] = { t: "s", v: element.name }
                for (let j = 0; j < element.month_data.length; j++) {
                    user_sheet[excelColumns[j + 1] + (i + 2)] = { t: "s", v: element.month_data[j].demand === 0 ? '' : (element.month_data[j].demand % 1 !== 0 ? Number(element.month_data[j].demand).toFixed(2) : element.month_data[j].demand) }
                    if ((access.access && access.access.l15a && access.access.l15a.l15a_j)) {
                        revenue_sheet[excelColumns[j + 1] + (i + 2)] = { t: "s", v: element.month_data[j].demand_cost === 0 ? '' : (element.month_data[j].demand_cost % 1 !== 0 ? Number(element.month_data[j].demand_cost).toFixed(2) : element.month_data[j].demand_cost) }
                    }
                    capacity_user_sheet[excelColumns[j + 1] + (i + 2)] = { t: "s", v: element.month_data[j].capacity === 0 ? '' : (element.month_data[j].capacity % 1 !== 0 ? Number(element.month_data[j].capacity).toFixed(2) : element.month_data[j].capacity) }
                }
            }

            sheetData.Demand = { ...sheetData.Demand, ...col, ...user_sheet };
            if ((access.access && access.access.l15a && access.access.l15a.l15a_j)) {
                sheetData.Revenue = { ...sheetData.Revenue, ...col, ...revenue_sheet };
            }
            if (this.state.capacityCheck === true) sheetData.Capacity = { ...sheetData.Capacity, ...capacity_col, ...capacity_user_sheet };

            // console.log(sheetData)

            exportData = {
                'SheetNames': sheets,
                'Sheets': sheetData
            }

            // console.log(exportData)

            var blob = new Blob([this.s2ab(XLSX.write(exportData, { bookType: 'xlsx', type: 'binary' }))], { type: "application/vnd.ms-excel" });
            saveAs(blob, "DvsC Month Report.xlsx");
            // this.props.downloadDialogClose()
        }
        catch (e) {
            console.log(e)
        }
    }


    downloadByWeeks() {
        let data = this.state.data;

        // console.log(data)
        var sheets = ["Demand"]

        if ((access.access && access.access.l15a && access.access.l15a.l15a_j)) {
            sheets.push("Revenue")
        }

        if (this.state.capacityCheck === true) {
            sheets.push("Capacity")
        }

        var sheetData = {}

        var exportData = {}

        try {
            sheetData["Demand"] = {
                '!ref': "A1:" + excelColumns[data[0].week_data.length] + (data.length + 1),
                '!cols': [
                    { wch: 35 }
                ],
                'A1': { t: "s", v: "Role" },
            }

            if ((access.access && access.access.l15a && access.access.l15a.l15a_j)) {
                sheetData["Revenue"] = {
                    '!ref': "A1:" + excelColumns[data[0].week_data.length] + (data.length + 1),
                    '!cols': [
                        { wch: 35 }
                    ],
                    'A1': { t: "s", v: "Role" },
                }
            }

            sheetData["Capacity"] = {
                '!ref': "A1:" + excelColumns[data[0].week_data.length] + (data.length + 1),
                '!cols': [
                    { wch: 35 }
                ],
                'A1': { t: "s", v: "Role" },
            }

            let col = {}
            let capacity_col = {}

            for (let i = 0; i < data[0].week_data.length; i++) {
                col = { ...col, [excelColumns[i + 1] + '1']: { t: 's', v: 'W' + data[0].week_data[i].week_number } }
                capacity_col = { ...capacity_col, [excelColumns[i + 1] + '1']: { t: 's', v: 'W' + data[0].week_data[i].week_number } }
            }

            let user_sheet = sheetData["Demand"]
            let revenue_sheet = sheetData["Revenue"]
            let capacity_user_sheet = sheetData["Capacity"]

            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                user_sheet["A" + (i + 2)] = { t: "s", v: element.name }
                if ((access.access && access.access.l15a && access.access.l15a.l15a_j)) {
                    revenue_sheet["A" + (i + 2)] = { t: "s", v: element.name }
                }
                capacity_user_sheet["A" + (i + 2)] = { t: "s", v: element.name }
                for (let j = 0; j < element.week_data.length; j++) {
                    user_sheet[excelColumns[j + 1] + (i + 2)] = { t: "s", v: element.week_data[j].demand === 0 ? '' : (element.week_data[j].demand % 1 !== 0 ? Number(element.week_data[j].demand).toFixed(2) : element.week_data[j].demand) }
                    if ((access.access && access.access.l15a && access.access.l15a.l15a_j)) {
                        revenue_sheet[excelColumns[j + 1] + (i + 2)] = { t: "s", v: element.week_data[j].demand_cost === 0 ? '' : (element.week_data[j].demand_cost % 1 !== 0 ? Number(element.week_data[j].demand_cost).toFixed(2) : element.week_data[j].demand_cost) }
                    }
                    capacity_user_sheet[excelColumns[j + 1] + (i + 2)] = { t: "s", v: element.week_data[j].capacity === 0 ? '' : (element.week_data[j].capacity % 1 !== 0 ? Number(element.week_data[j].capacity).toFixed(2) : element.week_data[j].capacity) }
                }
            }

            sheetData.Demand = { ...sheetData.Demand, ...col, ...user_sheet };
            if ((access.access && access.access.l15a && access.access.l15a.l15a_j)) {
                sheetData.Revenue = { ...sheetData.Revenue, ...col, ...revenue_sheet };
            }
            if (this.state.capacityCheck === true) sheetData.Capacity = { ...sheetData.Capacity, ...capacity_col, ...capacity_user_sheet };

            // console.log(sheetData)

            exportData = {
                'SheetNames': sheets,
                'Sheets': sheetData
            }

            // console.log(exportData)

            var blob = new Blob([this.s2ab(XLSX.write(exportData, { bookType: 'xlsx', type: 'binary' }))], { type: "application/vnd.ms-excel" });
            saveAs(blob, "DvsC Week Report.xlsx");
            // this.props.downloadDialogClose()
        }
        catch (e) {
            console.log(e)
        }
    }

    checkChange = (event) => {
        this.setState({ capacityCheck: event.target.checked })
    }

    render() {
        return (
            <>
                <Dialog
                    disableBackdropClick
                    open={this.props.open}
                    onClose={() => this.props.downloadDialogClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'sm'}
                >
                    <DialogTitle id="alert-dialog-title">{"Download Report"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.data.length > 0 ? <h4 style={{ color: '#4CAF50', margin: 0 }}>{"Download started."} </h4> : null}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.capacityCheck}
                                        onChange={this.checkChange}
                                        name="capacityCheck"
                                        color="primary"
                                    />
                                }
                                label="Include capacity data"
                                style={{ marginTop: '10px', marginBottom: '10px' }}
                            />
                            <br />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.getReport('weeks')}
                                style={{ marginRight: '30px' }}
                            > By Weeks</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.getReport('months')}
                            > By Months</Button>


                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.downloadDialogClose()} color="primary" autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth="sm" open={this.state.isLoading} aria-labelledby="form-dialog-title">
                    {/* <DialogTitle id="customized-dialog-title" onClose={handleFeedbackClose}>
                                    Additional Hours Request
                                </DialogTitle> */}
                    <DialogContent>

                        <Box m={5} p={2}>

                            <CoffeeLoading />

                        </Box>

                    </DialogContent>

                </Dialog>
            </>

        )
    }
}

export default withSnackbar(DownloadDialog);