import React, { Component } from 'react'
import { Box, Button, CircularProgress, InputAdornment, TextField, Tooltip } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import TaskCards from './TaskCards';
import Badge from '@mui/material/Badge';
import { withSnackbar } from 'notistack'
import { screenSizeCheck } from '../../../ScreensizeCheck/ScreensizeCheck';
// import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
// import InputBase from '@mui/material/InputBase';
// import { getMode } from '../../../ColorTheme/ColorTheme';
import { getPrimaryColor } from '../../../../Helper/CommonThings';
import { IconButton } from '@material-ui/core';
import SaveAltIcon from '@mui/icons-material/SaveAlt';



const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -7,
        top: screenSizeCheck() ? 17 : 20,
        backgroundColor: '#66b6f3'
    },
}));

const StartSoonBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -7,
        top: screenSizeCheck() ? 17 : 20,
        backgroundColor: '#FFF176'
    },
}));

const ReadtToStartBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -7,
        top: screenSizeCheck() ? 17 : 20,
        backgroundColor: '#DCE775'
    },
}));

const LaterDueBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -7,
        top: screenSizeCheck() ? 17 : 20,
        backgroundColor: '#da8bc5'
    },
}));

const SoonDueBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -7,
        top: screenSizeCheck() ? 17 : 20,
        backgroundColor: '#FFB74D'
    },
}));

const OverDueBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -7,
        top: screenSizeCheck() ? 17 : 20,
        backgroundColor: '#EF9A9A'
    },
}));

// const Search = styled('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: getMode() ? alpha(theme.palette.common.white, 0.15) : '#e6e6e6',
//     '&:hover': {
//         backgroundColor: getMode() ? alpha(theme.palette.common.white, 0.25) : '#f2f2f2',
//     },
//     marginRight: theme.spacing(1),
//     marginLeft: 0,
//     width: '100%',
//     [theme.breakpoints.up('lg')]: {
//         marginLeft: theme.spacing(7),
//         width: 'auto',
//     },
//     [theme.breakpoints.down('lg')]: {
//         marginLeft: theme.spacing(4),
//         width: 'auto',
//     },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: 'inherit',
//     '& .MuiInputBase-input': {
//         padding: theme.spacing(1, 1, 1, 0),
//         // vertical padding + font size from searchIcon
//         paddingLeft: '`calc(1em + ${theme.spacing(4)}px)`',
//         transition: theme.transitions.create('width'),
//         width: '100%',
//         [theme.breakpoints.up('lg')]: {
//             width: '25ch',
//         },
//         [theme.breakpoints.down('lg')]: {
//             width: '15ch',
//         },
//     },
// }));

var searchInput = ""

class Tasks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            button1: '#A9ABAD',
            button2: '#A9ABAD',
            button3: '#A9ABAD',
            button4: '#A9ABAD',
            button5: '#A9ABAD',
            button6: '#A9ABAD',
            datas: [],
            isLoading: true,
            allTask: 0,
            startSoon: 0,
            readyToStart: 0,
            soonDue: 0,
            overDue: 0,
            laterDue: 0,
            taskFind: false,
            outOfWorkTasks: {},
            workSearchDialog: false,
            emptyStatus: '',
            boxHeight: null,
        }
        this.child = React.createRef();
        this.handleChange = this.handleChange.bind(this)
        this.refreshData = this.refreshData.bind(this)
        this.taskAllocation = this.taskAllocation.bind(this)
        this.boxHeight = this.boxHeight.bind(this)
        this.refreshSubTender = this.refreshSubTender.bind(this)
    }
    componentDidMount() {
        searchInput = ""
        this.handleData(this.props.data.team_details)
    }

    handleData(values) {
        var datas = []
        if (searchInput === '') {
            datas = values
        } else {
            const data_lowercase = searchInput.toLowerCase()
            var search_words = data_lowercase.split(" ");
            search_words = search_words.filter(n => n)
            var filtered_data = []
            if (search_words.length === 0) {
                filtered_data = this.props.data.team_details
            }

            for (let i = 0; i < this.props.data.team_details.length; i++) {
                const element = this.props.data.team_details[i];
                var num_of_passed = false
                for (let j = 0; j < search_words.length; j++) {
                    const el = search_words[j];
                    num_of_passed = element.search_key.includes(el)
                    if (!num_of_passed) {
                        break
                    }
                }
                if (num_of_passed) {
                    filtered_data.push(element)
                }
            }
            datas = filtered_data
        }
        var startSoon = []
        var readyToStart = []
        var soonDue = []
        var overDue = []
        var laterDue = []

        for (let i = 0; i < datas.length; i++) {
            const element = datas[i];
            if (element.task_stand === 1) {
                startSoon.push(element)
            } else if (element.task_stand === 2) {
                readyToStart.push(element)
            }
            else if (element.task_stand === 3) {
                soonDue.push(element)
            }
            else if (element.task_stand === 4) {
                overDue.push(element)
            }
            else if (element.task_stand === 6) {
                laterDue.push(element)
            }
        }

        this.setState({ isLoading: true }, () => {
            this.setState({ emptyStatus: datas.length <= 0 ? 'for You' : '', allTask: datas.length, startSoon: startSoon.length, readyToStart: readyToStart.length, soonDue: soonDue.length, overDue: overDue.length, laterDue: laterDue.length, isLoading: false, datas: this.props.data.team_details, button1: getPrimaryColor(), button2: '#A9ABAD', button3: '#A9ABAD', button4: '#A9ABAD', button5: '#A9ABAD', button6: '#A9ABAD', });
        })
    }

    handleChange(data) {
        if (data === null) {
            var datasAll = this.props.data.team_details;
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datasAll.length === 0 ? 'for You' : null, isLoading: false, datas: datasAll, button1: getPrimaryColor(), button2: '#A9ABAD', button3: '#A9ABAD', button4: '#A9ABAD', button5: '#A9ABAD', button6: '#A9ABAD' })
            })
        } else if (data === 1) {
            var datas = [];
            for (let i = 0; i < this.props.data.team_details.length; i++) {
                const element = this.props.data.team_details[i];
                if (element.task_stand === 1) {
                    datas.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas.length === 0 ? 'in Start Soon' : null, isLoading: false, datas: datas, button1: '#A9ABAD', button2: getPrimaryColor(), button3: '#A9ABAD', button4: '#A9ABAD', button5: '#A9ABAD', button6: '#A9ABAD' })
            })
        } else if (data === 2) {
            var datas1 = [];
            for (let i = 0; i < this.props.data.team_details.length; i++) {
                const element = this.props.data.team_details[i];
                if (element.task_stand === 2) {
                    datas1.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas1.length === 0 ? 'in Ready To Start' : null, isLoading: false, datas: datas1, button1: '#A9ABAD', button2: '#A9ABAD', button3: getPrimaryColor(), button4: '#A9ABAD', button5: '#A9ABAD', button6: '#A9ABAD' })
            })

        } else if (data === 3) {
            var datas2 = [];
            for (let i = 0; i < this.props.data.team_details.length; i++) {
                const element = this.props.data.team_details[i];
                if (element.task_stand === 3) {
                    datas2.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas2.length === 0 ? 'in Soon Due' : null, isLoading: false, datas: datas2, button1: '#A9ABAD', button2: '#A9ABAD', button3: '#A9ABAD', button4: getPrimaryColor(), button5: '#A9ABAD', button6: '#A9ABAD' })
            })
        } else if (data === 4) {
            var datas3 = [];
            for (let i = 0; i < this.props.data.team_details.length; i++) {
                const element = this.props.data.team_details[i];
                if (element.task_stand === 4) {
                    datas3.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas3.length === 0 ? 'in Over Due' : null, isLoading: false, datas: datas3, button1: '#A9ABAD', button2: '#A9ABAD', button3: '#A9ABAD', button4: '#A9ABAD', button5: getPrimaryColor(), button6: '#A9ABAD' })
            })

        } else if (data === 6) {
            var datas4 = [];
            for (let i = 0; i < this.props.data.team_details.length; i++) {
                const element = this.props.data.team_details[i];
                if (element.task_stand === 6) {
                    datas4.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas4.length === 0 ? 'in Later Due' : null, isLoading: false, datas: datas4, button1: '#A9ABAD', button2: '#A9ABAD', button3: '#A9ABAD', button4: '#A9ABAD', button5: '#A9ABAD', button6: getPrimaryColor() })
            })

        }
    }

    boxHeight(height) {
        this.setState({ boxHeight: height + 6 })
    }

    refreshData() {
        this.props.refreshData()
    }

    refreshSubTender() {
        this.props.refreshSubTender()
    }

    taskAllocation(value) {
        this.props.taskAllocation(value)
    }

    handleSearchChange(data) {
        searchInput = data
        if (this.child && this.child.current) {
            var filtered_data = this.child.current.taskSearch(searchInput)
            this.handleData(filtered_data)
        }
    }

    downloadExcel() {
        if (this.child && this.child.current) {
            this.child.current.openTaskDownload()
        }
    }

    render() {

        return (
            <div style={{ marginTop: '0px', marginLeft: '5px', marginRight: '5px' }}>
                <Box m={1} sx={{
                    display: 'flex',
                }} >
                    <Box sx={{
                        flexDirection: "row",
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: 'space-between',
                    }} >
                        <Box sx={{ display: "flex", justifyContent: 'flex-start' }} >
                            <StyledBadge badgeContent={this.state.allTask} color="success">
                                <Button size={screenSizeCheck() ? "medium" : "large"} style={{ color: this.state.button1 }} onClick={() => this.handleChange(null)}>To Do</Button>
                            </StyledBadge>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box sx={{ display: "flex", justifyContent: 'flex-start' }}>
                            <StartSoonBadge badgeContent={this.state.startSoon} color="success">
                                <Button size={screenSizeCheck() ? "medium" : "large"} style={{ color: this.state.button2 }} onClick={() => this.handleChange(1)}>Start Soon</Button>
                            </StartSoonBadge>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box sx={{ display: "flex", justifyContent: 'flex-start' }}>
                            <ReadtToStartBadge badgeContent={this.state.readyToStart} color="success">
                                <Button size={screenSizeCheck() ? "medium" : "large"} style={{ color: this.state.button3 }} onClick={() => this.handleChange(2)}>Ready to Start</Button>
                            </ReadtToStartBadge>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box sx={{ display: "flex", justifyContent: 'flex-start' }}>
                            <LaterDueBadge badgeContent={this.state.laterDue} color="success">
                                <Button size={screenSizeCheck() ? "medium" : "large"} style={{ color: this.state.button6 }} onClick={() => this.handleChange(6)}>Later Due</Button>
                            </LaterDueBadge>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box sx={{ display: "flex", justifyContent: 'flex-start' }}>
                            <SoonDueBadge badgeContent={this.state.soonDue} color="success">
                                <Button size={screenSizeCheck() ? "medium" : "large"} style={{ color: this.state.button4 }} onClick={() => this.handleChange(3)}>Soon Due</Button>
                            </SoonDueBadge>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box sx={{ display: "flex", justifyContent: 'flex-start' }}>
                            <OverDueBadge badgeContent={this.state.overDue} color="success">
                                <Button size={screenSizeCheck() ? "medium" : "large"} style={{ color: this.state.button5 }} onClick={() => this.handleChange(4)}>Over Due</Button>
                            </OverDueBadge>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: 'flex-start', }}>
                            <Tooltip placement="top" title={<div>You can use multiple keyword searches<br />Ex: David OHL Wireclearance</div>}>
                                {/* <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    autoFocus
                                    placeholder="Search"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e) => this.handleSearchChange(e.target.value)}
                                />
                            </Search> */}
                                <TextField
                                    autoFocus
                                    autoComplete='off'
                                    size='small'
                                    placeholder="Search"
                                    id="search"
                                    onChange={(e) => this.handleSearchChange(e.target.value)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                    }}
                                />
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box sx={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: 'center'
                    }}>

                        {/* {this.props.is_teamtasks ? */}
                        {/* <> */}
                        <Box sx={{ display: "flex", justifyContent: 'flex-start', marginLeft: '5px' }}>
                            <Tooltip title='Download'>
                                <IconButton size='small' onClick={() => {
                                    if (this.state.datas.length !== 0) {
                                        this.downloadExcel()
                                    } else {
                                        this.props.enqueueSnackbar('No Tasks to download!', {
                                            variant: 'warning'
                                        });
                                    }
                                }}>
                                    <SaveAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        {/* </> */}
                        {/* : null} */}

                    </Box>

                </Box>

                {
                    this.state.isLoading ?
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 1, height: this.state.boxHeight ? this.state.boxHeight : '200px' }}>
                            <CircularProgress color='primary' />
                        </Box>
                        :
                        this.state.datas.length === 0 ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 1, fontWeight: 'bold', height: this.state.boxHeight ? this.state.boxHeight : '200px' }}>
                                No Tasks {this.state.emptyStatus}
                            </Box> :
                            <TaskCards
                                boxHeight={this.boxHeight}
                                ref={this.child}
                                fullData={this.props.data}
                                data={this.state.datas ? this.state.datas : null}
                                handleChange={this.handleChange}
                                innerHeight={this.getHeight}
                                is_teamtasks={this.props.is_teamtasks}
                                refreshData={this.refreshData}
                                taskAllocation={this.taskAllocation}
                                presentSearch={searchInput}
                                refreshSubTender={this.refreshSubTender} />
                }

            </div >
        )
    }
}

export default withSnackbar(Tasks)