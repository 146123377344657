import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import Service from "../Networking/networkutils";
import { CircularProgress, Typography, withStyles, Button } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { Refresh } from '@material-ui/icons';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

const useStyles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
});

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#B3E5FC',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

class TeamUserTaskTable extends Component {

    constructor(props) {
        super()

        this.state = {
            isLoading: true,
            data: [],
            dialogData: null,
            open: false,
            projectLookUp: null,
            allUsers: {},
            allocatedUsersTotal: [],
            selectedUser: null,
            hoursLoading: false
        }
    }

    componentDidMount() {
        // console.log(this.props)
        let array = this.props.data;
        if (array.length === 0) {
            this.setState({ isLoading: false });
            return;
        }
        array.sort(function (a, b) {
            return new Date(a.act_exp_start) - new Date(b.act_exp_start);
        });
        this.setState({ data: array }, () => {
            this.setState({ isLoading: false });
            this.fetchOps(this.state.data)
        })
    }

    fetchOps(dataArr) {
        // console.log(this.props.allUsers)
        let data = [...dataArr];
        // if (value === 'refresh') {
        //     data = this.props.data;
        // }

        // let dataAll = this.props.dataAll
        var viewPid = []
        // let updatedArr = {}
        // for (let i = 0; i < data.length; i++) {
        //     const element = data[i];
        //     let matchedTask = []
        //     for (let j = 0; j < dataAll.length; j++) {
        //         const element2 = dataAll[j];
        //         if (element.id === element2.id) {
        //             matchedTask.push(element2)
        //             viewPid.push({ 'com_ttid': element2.id, 'com_uid': element2.user.id })
        //         }
        //     }
        //     updatedArr = { ...updatedArr, ...{ [element.id]: [...matchedTask] } }
        // }

        // console.log(JSON.parse(JSON.stringify(data)), 'data org')
        for (let i = 0; i < data.length; i++) {
            let users = [...data[i].users]
            for (let j = 0; j < users.length; j++) {
                viewPid.push({ 'com_ttid': data[i].id, 'com_uid': users[j].id })
            }
        }
        // console.log(updatedArr, 'Updated')
        // console.log(viewPid)
        // for (const taskId in updatedArr) {

        // }

        // return;
        // this.setState({ data: res.data, isLoading: false, }, () => {

        if (viewPid.length !== 0) {

            this.setState({ hoursLoading: true })
            Service.patch("/bb/api/team/tasks/get_acthours/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: viewPid,
            })
                .then(res12 => {

                    // for (let i = 0; i < data.length; i++) {
                    //     let ele = data[i];
                    //     // let users = [];
                    //     let uniqueUsers = []
                    //     for (let j = 0; j < res12.data.length; j++) {
                    //         let ele2 = res12.data[j]
                    //         if (ele.task_id === ele2.id) {
                    //             let allocatedUsers = updatedArr[ele.task_id];
                    //             for (let k = 0; k < allocatedUsers.length; k++) {
                    //                 if (allocatedUsers[k].user_id === ele2.id2) {
                    //                     let user = this.props.allUsers[ele2.id2]
                    //                     if (uniqueUsers.indexOf(user.id) === -1) uniqueUsers.push(user.id)
                    //                     // users.push(user)
                    //                 }
                    //             }
                    //         }
                    //     }
                    //     ele.uniqueUsers = uniqueUsers
                    //     // ele.users = users
                    // }

                    let newArr = []
                    for (let i = 0; i < data.length; i++) {
                        let ele = JSON.parse(JSON.stringify(data[i]))
                        let users = []
                        for (let j = 0; j < ele.users.length; j++) {
                            let user = JSON.parse(JSON.stringify(ele.users[j]))//this.state.allUsers[ele.uniqueUsers[j]]
                            for (let k = 0; k < res12.data.length; k++) {
                                let ele2 = res12.data[k]
                                if (ele2.id === ele.id && ele2.id2 === user.id) {
                                    user.actual_hours = timeToDecimal(ele2.hours_actual)
                                    user.inprogress_hours = timeToDecimal(ele2.hours_ongoing)
                                    user.total_alloc_hours = ele2.allocated
                                    user.actual_and_inprogress = (user.actual_hours + user.inprogress_hours) + "/" + user.total_alloc_hours
                                    user.available_hours = user.total_alloc_hours - (user.actual_hours + user.inprogress_hours)
                                    // user.tender_name_str = this.props.projectLookUp[ele.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                                }
                            }
                            let flag = true
                            for (let m = 0; m < users.length; m++) {
                                if (user.id === users[m].id) {
                                    flag = false
                                }
                            }
                            if (flag) users.push(user)
                        }
                        ele.users = users
                        newArr.push(JSON.parse(JSON.stringify(ele)))
                    }
                    // console.log(newArr, 'new')
                    // console.log(data)
                    this.data = newArr;
                    this.setState({ data: newArr }, () => {

                        let allUserUpdatedHours = [];

                        let allUsers = [...this.props.allUsers];

                        for (let i = 0; i < allUsers.length; i++) {
                            let user = JSON.parse(JSON.stringify(allUsers[i]))
                            for (let i = 0; i < this.state.data.length; i++) {
                                let ele = this.state.data[i]
                                for (let j = 0; j < ele.users.length; j++) {
                                    let task_user = JSON.parse(JSON.stringify(ele.users[j]));
                                    if (user.id === task_user.id) {
                                        if (user.hoursAdded === true) {
                                            // console.log('=============> updated for user ' + user.first_name, user.actual_hours, task_user.actual_hours)
                                            // console.log(user, task_user,ele)
                                            user.actual_hours = user.actual_hours + task_user.actual_hours
                                            user.inprogress_hours = user.inprogress_hours + task_user.inprogress_hours
                                            user.total_alloc_hours = user.total_alloc_hours + task_user.total_alloc_hours
                                            user.actual_and_inprogress = (user.actual_hours + user.inprogress_hours) + "/" + user.total_alloc_hours
                                            user.available_hours = user.total_alloc_hours - (user.actual_hours + user.inprogress_hours)
                                        }
                                        else {
                                            // console.log('+++++++++++++ added for user ' + user.first_name, task_user, ele)
                                            user.actual_hours = task_user.actual_hours
                                            user.inprogress_hours = task_user.inprogress_hours
                                            user.total_alloc_hours = task_user.total_alloc_hours
                                            user.actual_and_inprogress = (user.actual_hours + user.inprogress_hours) + "/" + user.total_alloc_hours
                                            user.available_hours = user.total_alloc_hours - (user.actual_hours + user.inprogress_hours)
                                            user.hoursAdded = true
                                        }
                                    }
                                }
                            }

                            allUserUpdatedHours.push(JSON.parse(JSON.stringify(user)))
                        }

                        // Object.keys(this.state.allUsers).forEach((key, i) => {
                        //     let user = JSON.parse(JSON.stringify(this.state.allUsers[key]))
                        //     for (let i = 0; i < this.state.data.length; i++) {
                        //         let ele = this.state.data[i]
                        //         for (let j = 0; j < ele.users.length; j++) {
                        //             let task_user = JSON.parse(JSON.stringify(ele.users[j]));
                        //             if (user.id === task_user.id) {
                        //                 if (user.hoursAdded === true) {
                        //                     // console.log('=============> updated for user ' + user.first_name, user.actual_hours, task_user.actual_hours)
                        //                     // console.log(user, task_user,ele)
                        //                     user.actual_hours = user.actual_hours + task_user.actual_hours
                        //                     user.inprogress_hours = user.inprogress_hours + task_user.inprogress_hours
                        //                     user.total_alloc_hours = user.total_alloc_hours + task_user.total_alloc_hours
                        //                     user.actual_and_inprogress = (user.actual_hours + user.inprogress_hours) + "/" + user.total_alloc_hours
                        //                     user.available_hours = user.total_alloc_hours - (user.actual_hours + user.inprogress_hours)
                        //                 }
                        //                 else {
                        //                     // console.log('+++++++++++++ added for user ' + user.first_name, task_user, ele)
                        //                     user.actual_hours = task_user.actual_hours
                        //                     user.inprogress_hours = task_user.inprogress_hours
                        //                     user.total_alloc_hours = task_user.total_alloc_hours
                        //                     user.actual_and_inprogress = (user.actual_hours + user.inprogress_hours) + "/" + user.total_alloc_hours
                        //                     user.available_hours = user.total_alloc_hours - (user.actual_hours + user.inprogress_hours)
                        //                     user.hoursAdded = true
                        //                 }
                        //             }
                        //         }
                        //     }
                        //     allUserUpdatedHours.push(JSON.parse(JSON.stringify(user)))
                        // })

                        // console.log(this.state.allUsers, 'All users')
                        // console.log(allUserUpdatedHours, 'allUserUpdatedHours')
                        allUserUpdatedHours.sort(function (a, b) {
                            return parseInt(a.available_hours) - parseInt(b.available_hours);
                        });
                        this.setState({ allocatedUsersTotal: allUserUpdatedHours, isLoading: false, hoursLoading: false })
                    })


                })
                .catch(error => {
                    console.log(error)
                    this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                        variant: 'error'
                    });
                });
        }
        // })
    }


    getAlldata() {
        Service.get(`/bb/api/user/report/view/team_workload/?uid=` + user.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                let { ds, project, user_task, wbs, users } = res.data;

                let task = [...this.data]
                this.users = users;

                for (let i = 0; i < task.length; i++) {

                    if (!task[i].act_exp_start) {
                        task[i].act_exp_start = task[i].from_date
                    }
                    if (!task[i].act_exp_finish) {
                        task[i].act_exp_finish = task[i].to_date
                    }

                    let task_users = []
                    for (let j = 0; j < user_task.length; j++) {
                        if (task[i].id === user_task[j].task_id) {
                            for (let k = 0; k < users.length; k++) {
                                if (user_task[j].user_id === users[k].id) {
                                    // task[i].user = users[k]
                                    task_users.push(users[k])
                                }
                            }
                        }
                    }
                    task[i].users = task_users
                    for (let j = 0; j < wbs.length; j++) {
                        if (task[i].t_ohlwbs === wbs[j].id) {
                            task[i].wbs = wbs[j];

                            for (let k = 0; k < ds.length; k++) {
                                if (wbs[j].pid === ds[k].id) {
                                    task[i].ds = ds[k];
                                    for (let l = 0; l < project.length; l++) {
                                        if (ds[k].subproject_pid === project[l].id) {
                                            task[i].project = project[l]
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // console.log(task)

                this.fetchOps(task)

            })
            .catch(e => {
                console.log(e)
            })
    }

    userClick(id) {
        this.setState({ selectedUser: id }, () => {
            if (!id) {
                this.setState({ data: [...this.data] })
                return;
            }
            let data = [...this.data];
            let filtered = []
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < data[i].users.length; j++) {
                    if (data[i].users[j].id === id) {
                        filtered.push(data[i])
                    }
                }
            }
            this.setState({ data: filtered })
        })
    }

    render() {
        // const allUser = this.props.allUsers
        const classes = this.props.classes
        return (
            <div>
                <Alert severity="info">
                    How you can do better resource planning to manage your team tasks, and not end up in "Overdue" ?
                    <br /> Follow the below steps (Its not clickable, just hover your mouse over the text)
                </Alert>

                <Stepper>

                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <Typography color="inherit"> Review the overdue tasks in the list.</Typography>
                            </React.Fragment>
                        }
                    >
                        <Step key={1} active={true}> <StepLabel >{'Step 1'}</StepLabel></Step>

                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <Typography color="inherit">Each team member name with balance hours of work to be performed is shown in the brackets.</Typography>
                            </React.Fragment>
                        }
                    >
                        <Step key={2} active={true}> <StepLabel >{'Step 2'}</StepLabel></Step>

                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <Typography color="inherit">Click on any of the team member to filter their assigned tasks. Review against the balance hours of work available to the other team members.</Typography>
                            </React.Fragment>
                        }
                    >
                        <Step key={3} active={true}> <StepLabel >{'Step 3'}</StepLabel></Step>

                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <Typography color="inherit">If you decide to re-allocate a task to another team member, just take note of the Task ID and in the "Team Allocation" page, search for the task and redistribute it.</Typography>
                            </React.Fragment>
                        }
                    >
                        <Step key={4} active={true}> <StepLabel >{'Step 4'}</StepLabel></Step>

                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <Typography color="inherit">Use the "Refresh" icon below to get the latest status of tasks.</Typography>
                            </React.Fragment>
                        }
                    >
                        <Step key={5} active={true}> <StepLabel >{'Step 5'}</StepLabel></Step>

                    </HtmlTooltip>
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <Typography color="inherit">Likewise, go back to step 2 and repeat the cycle, until your resource allocation is optimized.</Typography>
                            </React.Fragment>
                        }
                    >
                        <Step key={6} active={true}> <StepLabel >{'Step 6'}</StepLabel></Step>

                    </HtmlTooltip>

                </Stepper>

                <Paper component="ul" className={classes.root}>

                    {this.state.allocatedUsersTotal.map((ele, i) => {
                        // console.log(allUser[key])
                        return (<li key={ele.id}>
                            {ele.hoursAdded ? <Chip
                                avatar={<Avatar>{ele.first_name.charAt(0) + '' + ele.last_name.charAt(0)}</Avatar>}
                                label={ele.first_name + '(' + ((ele.available_hours).toFixed(1) ? (ele.available_hours).toFixed(1) : 0) + ')'}
                                onClick={() => this.userClick(ele.id)}
                                // variant={this.state.selectedUser === ele.id ? "contained" : "outlined"}
                                color={this.state.selectedUser === ele.id ? "primary" : "default"}
                                clickable
                                className={classes.chip}
                            /> : null
                            }
                        </li>

                        )
                    })}
                    {this.state.selectedUser ? <li key={'clear'}>< Chip
                        // avatar={<Avatar>{ele.first_name.charAt(0) + '' + ele.last_name.charAt(0)}</Avatar>}
                        label={'Clear Selection'}
                        onClick={() => this.userClick(null)}
                        variant={"outlined"}
                        // color={this.state.selectedUser === ele.id ? "primary" : "default"}
                        clickable
                        className={classes.chip}
                    /> </li> : null}

                </Paper>
                <MaterialTable
                    title={this.props.viewAll ? 'All Tasks' : this.props.status}
                    columns={this.props.viewAll ? [
                        {
                            title: 'Project', field: 'project.name',
                            // lookup: this.state.projectLookUp,
                            // render: rowData => this.state.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },
                        { title: 'Deliverables Schedule', field: 'ds.name', },
                        { title: 'WBS', field: 'wbs.name', },
                        { title: 'Tasks Id', field: 'id' },
                        {
                            title: 'Tasks', field: 'name',
                            render: (data) => data.decs  ? data.decs : data.name,
                            customFilterAndSearch: (term, rowData) => {
                                return (rowData.decs  ? rowData.decs : rowData.name).toLowerCase().includes(term.toLowerCase())
                            }
                        },
                        {
                            title: 'Status', field: 'status',
                        },
                        {
                            title: 'Required Start Date',
                            field: 'act_exp_start',
                            type: 'date',
                        },
                        {
                            title: 'Required End Date',
                            field: 'act_exp_finish',
                            type: 'date',
                        },
                        {
                            title: 'Complete (%)', field: 'comp_percnt',
                            lookup: { 0: '0%', 5: '5%', 10: '10%', 20: '20%', 30: '30%', 40: '40%', 50: '50%', 60: '60%', 65: '65%', 70: '70%', 80: '80%', 90: '90%', 100: '100%' },
                        },
                        {
                            title: 'Allocated Users', field: 'actual_and_inprogress', editable: 'never',
                            render: rowData => {
                                return (!this.state.hoursLoading) ? <div>
                                    {rowData.users.map((ele, i) => {
                                        return (<><p style={{ margin: '5px 0' }}>{ele.last_name + ', ' + ele.first_name + ' (' + ele.actual_and_inprogress + ')'}</p></>)
                                    })} </div> : <CircularProgress style={{ height: '15px', width: '15px' }} />
                            }
                        }

                    ] :
                        [
                            {
                                title: 'Project', field: 'project.name',
                                // lookup: this.state.projectLookUp,
                                // render: rowData => this.state.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                            },
                            { title: 'Deliverables Schedule', field: 'ds.name', },
                            { title: 'WBS', field: 'wbs.name', },
                            { title: 'Tasks Id', field: 'id' },
                            {
                                title: 'Tasks', field: 'name',
                                render: (data) => data.decs  ? data.decs : data.name,
                                customFilterAndSearch: (term, rowData) => {
                                    return (rowData.decs  ? rowData.decs : rowData.name).toLowerCase().includes(term.toLowerCase())
                                }
                            },
                            {
                                title: 'Required Start Date',
                                field: 'act_exp_start',
                                type: 'date',
                            },
                            {
                                title: 'Required End Date',
                                field: 'act_exp_finish',
                                type: 'date',
                            },
                            {
                                title: 'Complete (%)', field: 'comp_percnt',
                                lookup: { 0: '0%', 5: '5%', 10: '10%', 20: '20%', 30: '30%', 40: '40%', 50: '50%', 60: '60%', 65: '65%', 70: '70%', 80: '80%', 90: '90%', 100: '100%' },
                            },
                            {
                                title: 'Allocated Users', field: 'actual_and_inprogress', editable: 'never',
                                render: rowData => {
                                    return (!this.state.hoursLoading) ? <div>
                                        {rowData.users.map((ele, i) => {
                                            return (<><p style={{ margin: '5px 0' }}>{ele.last_name + ', ' + ele.first_name + ' (' + ele.actual_and_inprogress + ')'}</p></>)
                                        })} </div> : <CircularProgress style={{ height: '15px', width: '15px' }} />
                                }
                            }

                        ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}

                    options={{
                        padding: "dense",
                        filtering: true,
                        doubleHorizontalScroll: true,
                        pageSize: 20,
                        pageSizeOptions: [10, 20, 50],
                        // headerStyle: {
                        //     fontSize: 'smaller'
                        // },
                        // rowStyle: {
                        //     fontSize: 'smaller'
                        // }

                    }}
                    actions={this.props.status === 'Start Soon' || this.props.status === 'Overdue' ? [
                        {
                            icon: () => <Button variant='outlined' color='primary' endIcon={<Refresh />}>Refresh</Button>,
                            tooltip: 'Refresh',
                            isFreeAction: true,
                            onClick: () => { this.setState({ isLoading: true }); this.getAlldata() }
                        }
                    ] : []}

                />
                {/* <TeamGanttHome taskData={this.props.data} /> */}
            </div>
        )
    }
}

function addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec ? 1 : null
    if (len) {
        return parseFloat(Number(num).toFixed(len))
    } else {
        return parseFloat(Number(num))
    }
}

function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
}


export default withStyles(useStyles)(withSnackbar(TeamUserTaskTable))