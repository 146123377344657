import React, { Component } from 'react'
import TenderSubtasks from './TenderSubtasks'
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Typography } from '@material-ui/core';
import { getCookie } from '../Helper/CookieHelper';

const user = JSON.parse(getCookie("bb_ts_user"));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class MainTenderSubTasks extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openDialogue: false,
            dialogData: null,
            openTodaySummary: false,
            userId: null,
            openTeamSummary: false
        }
        this.tenderSubTask = React.createRef()
        this.openSubTender = this.openSubTender.bind(this)
        this.openTodaySummary = this.openTodaySummary.bind(this)
        this.openTeamSummary = this.openTeamSummary.bind(this)
    }

    openSubTender(data) {
        this.setState({ dialogData: data, }, () => { this.setState({ openDialogue: true }) })
    }

    openTodaySummary(id) {
        this.setState({ userId: id }, () => { this.setState({ openTodaySummary: true }) })
    }
    openTeamSummary() {
        this.setState({ openTeamSummary: true })
    }

    render() {

        const handleDialogueClose = (e) => {
            if (this.tenderSubTask && this.tenderSubTask.current) {
                this.tenderSubTask.current.fetchData()
            }
            this.setState({ openDialogue: false })
        }

        const handleTeamSummaryClose = (e) => {
            if (this.tenderSubTask && this.tenderSubTask.current) {
                this.tenderSubTask.current.fetchData()
            }
            this.setState({ openTeamSummary: false })
        }
        const handleTodaySummaryClose = (e) => {
            if (this.tenderSubTask && this.tenderSubTask.current) {
                this.tenderSubTask.current.fetchData()
            }
            this.setState({ openTodaySummary: false })
        }


        return (
            <div>
                {
                    this.props.user_id ? <TenderSubtasks ref={this.tenderSubTask} user_id={this.props.user_id} openSubTender={this.openSubTender} openTodaySummary={this.openTodaySummary} openTeamSummary={this.openTeamSummary} team_lead={this.props.team_lead} /> :
                        this.props.task_id ? <TenderSubtasks ref={this.tenderSubTask} task_id={this.props.task_id} openSubTender={this.openSubTender} /> :
                            this.props.ds_id ? <TenderSubtasks ref={this.tenderSubTask} ds_id={this.props.ds_id} openSubTender={this.openSubTender} /> :
                                this.props.tender_id ? <TenderSubtasks ref={this.tenderSubTask} tender_id={this.props.tender_id} openSubTender={this.openSubTender} /> : null
                }

                < Dialog fullScreen open={this.state.openDialogue} aria-labelledby="form-dialog-title" TransitionComponent={Transition} >
                    <AppBar position="static" color="primary">
                        <Toolbar>
                            <IconButton size='small' edge="start" color="inherit" onClick={handleDialogueClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="body1" >
                                {this.state.dialogData ? this.state.dialogData.title + " (" + this.state.dialogData.task_name + ")" : null}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <TenderSubtasks subTaskData={this.state.dialogData ? this.state.dialogData : null} />
                </Dialog >

                < Dialog fullScreen open={this.state.openTodaySummary} aria-labelledby="form-dialog-title" TransitionComponent={Transition} >
                    <AppBar position="static" color="primary">
                        <Toolbar>
                            <IconButton size='small' edge="start" color="inherit" onClick={handleTodaySummaryClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="body1" >
                                MySubtasks(Today)
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <TenderSubtasks todaySummary={this.state.userId} />
                </Dialog >

                < Dialog fullScreen open={this.state.openTeamSummary} aria-labelledby="form-dialog-title" TransitionComponent={Transition} >
                    <AppBar position="static" color="primary">
                        <Toolbar>
                            <IconButton size='small' edge="start" color="inherit" onClick={handleTeamSummaryClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="body1" >
                                TeamSubtasks(Today)
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <TenderSubtasks teamSummary={user.id} />
                </Dialog >
            </div >
        )
    }
}

export default MainTenderSubTasks