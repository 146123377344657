import { Box, Typography } from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { LoopCircleLoading } from 'react-loadingg'
import BalfourBeattyHeader from '../../CommonTips/BalfourBeattyHeader'
import Service from "../../Networking/networkutils";
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings'
// var CryptoJS = require("crypto-js");
class TenderFeedbackView extends Component {

    constructor(props) {
        super()

        this.state = {
            data: {},
            isLoading: true,
            access: false,
            openFeedback: true,
            meeting_specification: 0,
            consistency_in_quality: 0,
            on_time_delivery: 0,
            our_flexibility_delivery_schedule: 0,
            response_urgent_requirements: 0,
            resolution_your_complaints: 0,
            our_response_your_communication: 0,

        }
    }

    componentDidMount() {

        this.fetchOps()
    }

    fetchOps() {
        Service.get('/bb/api/tender/feedback/' + this.props.match.params.appid + '/', {

        })
            .then(res => {
                // console.log(res.data)
                // console.log(this.state.pass_code)
                this.setState({
                    meeting_specification: res.data.meeting_specification,
                    consistency_in_quality: res.data.consistency_in_quality,
                    on_time_delivery : res.data.on_time_delivery,
                    our_flexibility_delivery_schedule: res.data.our_flexibility_delivery_schedule,
                    response_urgent_requirements: res.data.response_urgent_requirements,
                    resolution_your_complaints: res.data.resolution_your_complaints,
                    our_response_your_communication: res.data.our_response_your_communication,
                    data: res.data,
                    isLoading: false,
                })
                if (res.data.is_locked === 0) {
                    alert("Feedback Not Available. Please wait until "+res.data.email + " submit the timesheet." )
                    window.location.href = "/"
                }
            })
            .catch(error => {
               errorMessage(this.props, error)
            });
    }

    // putData() {
    //     Service.put('/bb/api/tender/feedback/' + this.props.match.params.appid + '/', {
    //         data: {
    //             id: this.state.data.id,
    //             meeting_specification: this.state.meeting_specification,
    //             consistency_in_quality: this.state.consistency_in_quality,
    //             on_time_delivery: this.state.on_time_delivery,
    //             our_flexibility_delivery_schedule: this.state.our_flexibility_delivery_schedule,
    //             response_urgent_requirements: this.state.response_urgent_requirements,
    //             resolution_your_complaints: this.state.resolution_your_complaints,
    //             our_response_your_communication: this.state.our_response_your_communication,
    //             is_locked: 1,
    //         }
    //     })
    //         .then(res => {
                
    //             // alert("Feedback submitted. Thank you!")
    //             alert("Feedback submitted!")
    //             window.location.reload()
    //         })
    //         .catch(error => {
    //             console.log(error)
    //             this.props.enqueueSnackbar('Something went wrong!', {
    //                 variant: 'error'
    //             });
    //         });
    // }

    render() {

        // const handleFeedbackClose = () => {
        //     this.setState({ openFeedback: false, });

        // };

        return (
            <div>
                <BalfourBeattyHeader />
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                        <div>
                            <Box display="flex" fontSize="h6.fontSize" justifyContent="center" fontWeight="fontWeightBold" flexDirection="row" p={1} m={1} >
                                        {this.state.data.pid_data.tender_name + " Project Feedback Form"}
                                    </Box>
                                    <Box display="flex" fontSize="h6.fontSize" justifyContent="center" fontWeight="fontWeightBold" flexDirection="row" p={1} m={1} >
                                        {"Requested By : " + this.state.data.uid_data.email}
                                    </Box>
                                    <Box display="flex" fontSize="h6.fontSize" justifyContent="center" fontWeight="fontWeightBold" flexDirection="row" p={1} m={1} >
                                        {"Feedback By : " + this.state.data.email}
                                    </Box>
                                    <Box display="flex" fontSize="h6.fontSize" fontWeight="fontWeightBold" flexDirection="row" p={1} m={1} >
                                        Quality :
                        </Box>

                                    <Box component="fieldset" display="flex" p={1} m={1}>
                                        <Typography component="legend">Meeting Specifications</Typography>
                                        <Rating name="customized-101" max={10} value={this.state.meeting_specification}
                                            size='large' readOnly
                                            />
                                    </Box>
                                    <Box component="fieldset" display="flex" p={1} m={1}>
                                        <Typography component="legend">Consistency in Quality</Typography>
                                        <Rating name="customized-102" max={10} value={this.state.consistency_in_quality}
                                            size='large' readOnly
                                            />
                                    </Box>
                                    <Box display="flex" fontSize="h6.fontSize" fontWeight="fontWeightBold" flexDirection="row" p={1} m={1} >
                                        On Time Delivery :
                        </Box>
                                    <Box component="fieldset" display="flex" p={1} m={1}>
                                        <Typography component="legend">On time Delivery</Typography>
                                        <Rating name="customized-103" max={10} value={this.state.on_time_delivery}
                                            size='large' readOnly
                                            />
                                    </Box>
                                    <Box component="fieldset" display="flex" p={1} m={1}>
                                        <Typography component="legend">Our Flexibility in Delivery Schedule</Typography>
                                        <Rating name="customized-104" max={10} value={this.state.our_flexibility_delivery_schedule}
                                            size='large' readOnly
                                            />
                                    </Box>
                                    <Box component="fieldset" display="flex" p={1} m={1}>
                                        <Typography component="legend">Response to urgent requirements</Typography>
                                        <Rating name="customized-105" max={10} value={this.state.response_urgent_requirements}
                                            size='large' readOnly
                                            />
                                    </Box>

                                    <Box display="flex" fontSize="h6.fontSize" fontWeight="fontWeightBold" flexDirection="row" p={1} m={1} >
                                        Service :
                        </Box>

                                    <Box component="fieldset" display="flex" p={1} m={1}>
                                        <Typography component="legend">Resolution of your complaints</Typography>
                                        <Rating name="customized-106" max={10} value={this.state.resolution_your_complaints}
                                            size='large' readOnly
                                             />
                                    </Box>
                                    <Box component="fieldset" display="flex" p={1} m={1}>
                                        <Typography component="legend">Our response to your communication</Typography>
                                        <Rating name="customized-107" max={10} value={this.state.our_response_your_communication}
                                            size='large' readOnly
                                             />
                                    </Box>
                                    {/* <Box display="flex" justifyContent="center" m={1} p={1}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            // style={{ marginLeft: '10px', marginRight: '10px' }}
                                            onClick={() => this.putData()}
                                        >
                                            Submit
                                    </Button> */}
                                    {/* </Box> */}

                        </div>
                    )}

            </div>
        )
    }
}

export default withSnackbar(TenderFeedbackView)