import { initializeApp } from 'firebase/app'
import { getMessaging, onMessage } from 'firebase/messaging'
import { getToken } from "firebase/messaging";
import { getApiDomain } from './Networking/networkutils';

var messaging = null

if (window.location.protocol === "https:") {
    const config = {
        apiKey: "AIzaSyBMhJWetkJHDfGgavoNdIgCp0rRFDhfvdA",
        authDomain: "bb-rmp.firebaseapp.com",
        databaseURL: "https://bb-rmp.firebaseio.com",
        projectId: "bb-rmp",
        storageBucket: "bb-rmp.appspot.com",
        messagingSenderId: "241060530392",
        appId: "1:241060530392:web:7dc9ba6fad5925131e6fad",
        measurementId: "G-MZHBBW79F8"
    }

    const firebaseApp = initializeApp(config);
    messaging = getMessaging(firebaseApp)

    onMessage(messaging, (payload) => {
        const full_domain = window.location.protocol + '//' + window.location.host + ":" + window.location.port;
        if (!payload?.data?.icon) {
            payload.data.icon = full_domain + '/logo512.png'
        }

        if (payload.data.actions) {
            let actions = payload.data.actions
            actions = actions.replace(/'/g, '"')
            payload.data.actions = JSON.parse(actions)
        }
       
        const notification = new Notification(payload.data.title, payload.data); 

        notification.onclick = (event) => {
            let token = ""
            if (payload) {
                getToken(messaging, { vapidKey: 'BNNgkEtOcJQN9hW5EutQMFPhK1m2jFMHF8KRdj3hhdnvanToSnEXcxMzKlQG0yhIcSmnzUk3eFJNwl1B94cO_l4' }).then((currentToken) => {
                    token = currentToken
                    let postData = {
                        token: token,
                        notification_log_ids: JSON.parse(payload.data.notification_log_ids),
                    }
                    fetch(getApiDomain() + "/bb/api/cron/notification_log_with_user_ids/", {  // Enter your IP address here
                        method: 'PUT',
                        mode: 'cors',
                        body: JSON.stringify(postData), // body data type must match "Content-Type" header
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                    })
                        .then(response => {
                            // return response.json()
                        })
                        .then(data => {

                        })
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });

            }
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            var data = null
            if (event.currentTarget.data) {
                data = event.currentTarget.data.split(" ")
            }
            if (data && data[0] === "open") {
                window.open(data[1])
            } else if (data && data[0] === "navigate") {
                window.location.href = data[1]
            }
        }
    });

    navigator.serviceWorker.addEventListener("message", (event) => {
        // console.log(event.data.msg, event.data.url);
        // console.log(event)
        if (!event.data.action) {
            return
        }

        switch (event.data.action) {
            case 'redirect-from-notificationclick':
                window.location.href = event.data.url
                break
            // no default
        }
    });
}

export const cloudMessaging = messaging