import React, { Component } from 'react'
import { getPrimaryColor } from '../../Helper/CommonThings'
import { LoopCircleLoading } from 'react-loadingg';
// import { Alert } from '@material-ui/lab';
import { Box, IconButton, Typography } from '@material-ui/core';
import { AppBar, Toolbar, } from '@mui/material'
import CloseIcon from '@material-ui/icons/Close';

class CricketLive2023 extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true
        }
    }
    componentDidMount() {
        this.setState({ isLoading: false })
    }
    render() {
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) :
                    <div>
                        <AppBar position='fixed' >
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={() => window.close()} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6">
                                   #GameWithTom - Live Broadcast Powered by PaRaM
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        {/* <Box margin={'7px'} style={{ width: '99%' }} marginTop={'70px'}>
                            <Alert severity="info"> The Match will starts by 6 pm (IST) / 12:30 pm (GMT)</Alert>
                        </Box> */}
                        <Box marginTop={'70px'} display='flex' flexDirection='row' justifyContent={'center'} height='70%'>
                            <iframe src={'https://owncast.bbedc.dev/embed/video'} frameBorder="0" title="Owncast" height={(window.innerHeight * 0.98) + "px"} width={(window.innerHeight * 1.645) + "px"} referrerpolicy="origin" scrolling="no" allowfullscreen></iframe>
                        </Box>
                    </div>
                }
            </div>
        )
    }
}

export default CricketLive2023
