import React, { Component } from 'react'
import Service from "../../Networking/networkutils";
import moment from 'moment'
import { withSnackbar } from 'notistack';
// import {
//     MuiPickersUtilsProvider,
//     KeyboardDatePicker,
// } from '@material-ui/pickers';
// import Grid from '@material-ui/core/Grid';
// import DateFnsUtils from '@date-io/date-fns';
// import { Button } from '@material-ui/core';
// import Dialog from '@material-ui/core/Dialog';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import CloseIcon from '@material-ui/icons/Close';
// import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
// import WorkHoursDialog from './WorkHoursDialog';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { LoopCircleLoading } from 'react-loadingg';
import MaterialTable from 'material-table';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getCookie } from '../../Helper/CookieHelper';
import { getDarkMode, getPrimaryColor } from '../../Helper/CommonThings';

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#00b300',

        },
        secondary: {
            main: '#cca300',
        },
        error: {
            main: "#ff0000",
        },
        type: getDarkMode(),
    }
})

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });
const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

class WorkHours extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            tabledata: [],

            columns: [
                { title: 'Emp Name', field: 'uname' },
                { title: 'Team', field: 'gname' },
                { title: 'Projects', field: 'proj_name' },

            ],
            date1Selected: false,
            date2Selected: false,
            date1: moment().add(1, 'days').startOf('isoWeek').subtract(1, 'days'),
            date2: moment().add(2, 'weeks').add(1, 'days').endOf('isoWeek').subtract(1, 'days'),
            openCal: false,
            toDia: [[], []],
            isLoading: true,
        }

    }


    componentDidMount() {
        var start_date1 = moment().add(1, 'days').startOf('isoWeek').subtract(1, 'days');
        var end_date1 = moment().add(3, 'weeks').add(1, 'days').endOf('isoWeek').subtract(1, 'days');
        start_date1.subtract(1, 'days')
        end_date1.add(1, 'days')
        let time = moment('12:00', 'HH:mm');
        let time1 = moment('12:00', 'HH:mm');
        start_date1.set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: time.get('second')
        });
        end_date1.set({
            hour: time1.get('hour'),
            minute: time1.get('minute'),
            second: time1.get('second')
        });
        Service.get("/bb/api/team/tasks/?uid=" + this.props.roleids + "&start__gte=" + start_date1.format('YYYY-MM-DD HH:mm:ss') + "&start__lte=" + end_date1.format('YYYY-MM-DD HH:mm:ss'), {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                //console.log(res.data)
                var datapull = [];
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    var date = moment(element.start).format('DD/MM/YYYY')
                    var stime = moment(element.start).format('HH mm A');
                    var etime = moment(element.end).format('HH mm A');
                    var whours = moment.duration(moment(element.end).diff(moment(element.start)));
                    var whours1 = ''
                    if (parseInt(whours.asHours()) === 0) {
                        whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                    } else {
                        whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                    }
                    var avail = false;
                    for (let j = 0; j < this.props.mapped.length; j++) {
                        const element1 = this.props.mapped[j];
                        if (element1.user_id === element.uid) {
                            avail = true;
                            break;
                        }
                    }
                    datapull.push({
                        id: element.id,
                        uid: element.uid,
                        gid: element.gid,
                        pid: element.pid,
                        tid: element.tid,
                        tname: element.tname,
                        uemail: element.uemail,
                        uname: element.uname_first + " " + element.uname_last,
                        gname: element.gname,
                        title: element.title,
                        proj_name: element.proj_name,
                        work_detail: element.work_detail,
                        start: new Date(element.start),
                        end: new Date(element.end),
                        date: date,
                        stime: stime,
                        etime: etime,
                        whours: whours1,
                        created_by: element.created_by,
                        [date]: whours1,
                        avail: avail,
                    });
                }

                this.setState({ data: datapull }, () => { this.handleColumns() });

            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    handleColumns() {
        var date = moment(this.state.date1)
        var date1 = moment(this.state.date1).format('DD/MM/YYYY');
        var date2 = moment(this.state.date2);
        var columns = [
            { title: 'Emp Name', field: 'uname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
            { title: 'Emp Email', field: 'uemail', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
            { title: 'Team', field: 'gname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
            // { title: 'Projects', field: 'proj_name', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, },
            // {
            //     title: 'Tasks', field: 'tname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
            //     render: rowData => rowData ? rowData : rowData
            // },
        ];
        //console.log(this.state.dupCol)
        while (date2 >= date) {

            const ccc = date1;
            columns.push(
                {
                    title: date.format("DD MMM (ddd)"), field: date1,
                    render: rowData => <ThemeProvider theme={bbColorTheme}><Typography color={(parseInt(rowData[ccc]) < 4) ? ("error") : (parseInt(rowData[ccc]) > 7) ? ("primary") : ("secondary")} >{rowData[ccc]}</Typography></ThemeProvider>
                }
            )
            date1 = date.add(1, 'days').format('DD/MM/YYYY')

        }

        var statedata = this.state.data;
        var doneuid = [];
        var toTData = [];
        var userelement = [];

        // console.log(statedata)

        for (let i = 0; i < statedata.length; i++) {
            var userelement2 = [];
            const element = statedata[i];

            if (i === 0) {
                doneuid.push(element.uid);
                for (let j = 0; j < statedata.length; j++) {
                    const element1 = statedata[j];
                    if (element.uid === element1.uid) {

                        userelement2.push(element1)
                    }
                }
            } else {
                var goodtogo = true;
                for (let k = 0; k < doneuid.length; k++) {
                    const element3 = doneuid[k];

                    if (element3 === element.uid) {
                        goodtogo = false;
                    }
                }

                if (goodtogo) {
                    doneuid.push(element.uid);
                    for (let j = 0; j < statedata.length; j++) {
                        const element1 = statedata[j];
                        if (element.uid === element1.uid) {

                            userelement2.push(element1)
                        }

                    }
                }
            }

            if (userelement2.length !== 0) {
                userelement.push(userelement2)
            }

        }

        //date
        for (let i = 0; i < userelement.length; i++) {
            var whdate = [];
            var wh = [];
            var teams = [];
            var proj = [];
            var tasks = [];
            const element = userelement[i];
            // console.log(element)
            for (let j = 0; j < element.length; j++) {
                const element1 = element[j];
                var goodtogo1 = true;
                for (let k = 0; k < whdate.length; k++) {
                    const element2 = whdate[k];
                    if (element2 === element1.date) {
                        goodtogo1 = false;
                    }

                }

                if (goodtogo1) {
                    // console.log('Total 111 time is')
                    whdate.push(element1.date);
                    var hours = []
                    for (let m = 0; m < element.length; m++) {
                        const element3 = element[m];
                        if (element3.date === element1.date) {
                            hours.push(element3[element3.date])
                        }
                        var teamgo = true;
                        for (let n = 0; n < teams.length; n++) {
                            const element4 = teams[n];
                            if (element4 === element3.gname) {
                                teamgo = false;
                            }
                        }
                        if (teamgo) {
                            teams.push(element3.gname)
                        }
                        var projgo = true;
                        for (let n = 0; n < proj.length; n++) {
                            const element4 = proj[n];
                            if (element4 === element3.proj_name) {
                                projgo = false;
                            }
                        }
                        //console.log(element3.proj_name)
                        if (projgo) {
                            proj.push(element3.proj_name)
                        }
                        var taskgo = true;
                        for (let n = 0; n < tasks.length; n++) {
                            const element4 = tasks[n];
                            if (element4 === element3.tname) {
                                taskgo = false;
                            }
                        }
                        //console.log(element3.proj_name)
                        if (taskgo) {
                            tasks.push(element3.tname)
                        }
                    }
                    // console.log(teams)
                    const totalDurations = hours.slice(1)
                        .reduce((prev, cur) => moment.duration(cur).add(prev),
                            moment.duration(hours[0]))
                    wh.push(moment.utc(totalDurations.asMilliseconds()).format("HH:mm"))
                }

            }
            // console.log(element)
            var jsoncons = {
                id: element[0].id,
                uid: element[0].uid,
                gid: element[0].gid,
                pid: element.pid,
                tid: element.tid,
                tname: tasks.join(),
                uemail: element[0].uemail,
                uname: element[0].uname,
                gname: teams.join(),
                title: element[0].title,
                proj_name: proj.join(),
                work_detail: element[0].work_detail,
                start: element[0].start,
                end: element[0].end,
                date: element[0].date,
                stime: element[0].stime,
                etime: element[0].etime,
                whours: element[0].whours1,
                created_by: element[0].created_by,
                avail: element[0].avail,
            }
            for (let k = 0; k < whdate.length; k++) {
                const element = whdate[k];
                jsoncons[element] = wh[k];
            }
            toTData.push(jsoncons);
        }

        for (let m = 0; m < this.props.users.length; m++) {
            const element = this.props.users[m];
            var goon = false;
            for (let n = 0; n < toTData.length; n++) {
                const element1 = toTData[n];
                if (element.id === element1.uid) {
                    goon = true;
                    break;
                }
            }
            if (!goon) {
                toTData.push({
                    uid: element.id,
                    uemail: element.email,
                    uname: element.first_name + " " + element.last_name,
                })
            }
        }

        var todata = [];
        todata.push(columns)
        todata.push(toTData)
        // console.log(todata)
        this.setState({
            toDia: todata,
            isLoading: false,
        })

    }

    render() {

        // const { classes } = this.props;

        // const handleCalClose = () => {
        //     this.setState({
        //         openCal: false,

        //     })
        // };

        // const handleDate1Change = (date) => {
        //     //console.log(moment(date).isoWeekday())
        //     this.setState({
        //         date1: date,
        //         date1Selected: true,

        //     }, () => { this.handleColumns() })
        // };

        // const handleDate2Change = (date) => {
        //     this.setState({
        //         date2: date,
        //         date2Selected: true,
        //     }, () => { this.handleColumns() })
        // };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <MaterialTable
                            title="Users 3 Week Status"
                            columns={this.state.toDia[0]}
                            data={this.state.toDia[1]}
                            options={{
                                padding: "dense",
                                filtering: true,
                                exportButton: { csv: true },
                                // exportPdf: () => alert('This feature is disabled. Data can only be exported as a CSV.'),
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 50],
                            }}
                            actions={[
                                rowData => ({
                                    icon: 'check',
                                    tooltip: 'Select',
                                    disabled: rowData.avail,
                                    onClick: (event, rowData) => this.props.onUserSelect(rowData.uid)
                                })
                            ]}
                        />
                    </div>
                )}
            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(WorkHours));