import React, { Component } from 'react';
import Gantt from './Gantt';
import './Gantt.css'
import moment from 'moment';
import { withSnackbar } from 'notistack'
import { ZoomIn, ZoomOut } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { CircleLoading } from 'react-loadingg';

// const token = getCookie("bb_ts_token");

// const data = {
//     data: [
//         { id: 1, text: 'Task #1', start_date: '15-06-2021', duration: 3, progress: 0.6, type: 'project' },
//         { id: 2, text: 'Task #2', start_date: '18-06-2021', duration: 3, progress: 0.4, parent: 1 },
//         { id: 3, text: 'Task #3', start_date: '19-06-2021', duration: 3, progress: 0.4, parent: 1 }
//     ],
//     links: [
//         { id: 1, source: 1, target: 2, type: '0' },
//         { id: 2, source: 2, target: 3, type: '0' }
//     ]
// };

// const data2 = require('./data.json');

class WBSGanttHome extends Component {

    constructor() {
        super()
        this.state = {
            data: {
                data: [],
                links: []
            },
            refreshGantt: true,
            expand: false
        }
        this.childGanttUser = React.createRef()
        this.openWBSEdit = this.openWBSEdit.bind(this)
    }

    componentDidMount() {
        this.makeData(this.props.value)
    }

    makeData(allData) {

        this.setState({ refreshGantt: true })

        this.allData = allData

        let data = [];

        for (let i = 0; i < allData.length; i++) {
            let p = allData[i];
            var wbs_push = null
            var task_push = null
            if (p.from_date && p.to_date) {
                wbs_push = {
                    id: 'w' + p.id,
                    text: p.name,
                    from_date: moment(p.from_date).format('DD-MM-YYYY'),
                    to_date: moment(p.to_date).format('DD-MM-YYYY'),
                    type: "wbs",
                    // render:"split",
                }
            }
            else {
                wbs_push = {
                    id: 'w' + p.id,
                    text: p.name,
                    start_date: '',
                    end_date: '',
                    unscheduled: true,
                    type: "wbs"
                }
            }

            wbs_push.searchKey = p.name
            data.push(wbs_push)

            for (let j = 0; j < p.tasks.length; j++) {
                let d = p.tasks[j];

                if (d.from_date && d.to_date) {
                    task_push = {
                        id: 't' + d.id,
                        text: d.decs ? d.decs : d.name,
                        start_date: moment(d.from_date).format('DD-MM-YYYY'),
                        end_date: moment(d.to_date).format('DD-MM-YYYY'),
                        parent: 'w' + p.id,
                        color: '#5C6BC0'
                    }
                } else {
                    task_push = {
                        id: 't' + d.id,
                        text: d.name,
                        start_date: '',
                        end_date: '',
                        parent: 'w' + p.id,
                        unscheduled: true
                    }
                }

                wbs_push.searchKey += d.name
                task_push.searchKey = p.name + (d.decs ? d.decs : d.name)
                data.push(task_push)

            }

        }
        // console.log(data, 'data')
        this.setState({ data: { data: data, links: [] }, refreshGantt: true }, () => {
            setTimeout(() => {
                this.setState({ refreshGantt: false })
            }, 300);
        });
    }

    openWBSEdit(id) {
        this.props.openWBSEdit(id)
    }

    zoomIn() {
        this.childGanttUser.current.zoomIn();
    }

    zoomOut() {
        this.childGanttUser.current.zoomOut();
    }

    expandAll() {
        this.childGanttUser.current.expandAll();
    }

    collapseAll() {
        this.childGanttUser.current.collapseAll();
    }

    setExpand = () => {
        this.setState({ expand: !this.state.expand }, () => {
            if (this.state.expand === true) {
                this.expandAll()
            }
            else {
                this.collapseAll()
            }
        })

    }


    render() {
        // console.log(this.state.data)
        return (
            <div className="gantt-container" >
                {this.state.refreshGantt === false ?
                    <div>
                        <div>
                            <div style={{ textAlign: 'right', marginBottom: '5px' }}>
                                <div style={{ marginRight: '10px', float: 'left', marginLeft: '15px' }}>
                                    <Button
                                        variant='outlined'
                                        onClick={this.setExpand}
                                        size='small'
                                        endIcon={this.state.expand === false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                                        style={{ marginLeft: '20px', marginRight: '20px' }}
                                    >
                                        {this.state.expand === false ? 'Expand All' : 'Collapse All'}
                                    </Button>

                                    Search: <input data-text-filter type='text' onInput={(e) => { this.expandAll(); this.childGanttUser.current.search(e.target.value) }} />
                                </div>
                                {/* <Button variant="outlined" onClick={() => this.expandAll()} size='small' endIcon={<ArrowDropDownIcon />} style={{ marginRight: '10px' }}>Expand All</Button>
                                <Button variant="outlined" onClick={() => this.collapseAll()} size='small' endIcon={<ArrowDropUpIcon />} style={{ marginRight: '10px' }}>Collapse All</Button> */}
                                <Button variant="outlined" onClick={() => this.zoomOut()} size='small' startIcon={<ZoomOut />} style={{ marginRight: '10px' }}>Zoom Out</Button>
                                <Button variant="outlined" onClick={() => this.zoomIn()} size='small' startIcon={<ZoomIn />} style={{ marginRight: '10px' }}>Zoom IN</Button>

                            </div>
                        </div>
                        <div style={{ height: 'calc(100vh - 160px)' }}>
                            <Gantt
                                ref={this.childGanttUser}
                                tasks={this.state.data}
                                openWBSEdit={this.openWBSEdit}
                            />
                        </div>
                    </div>
                    : <CircleLoading />}
            </div>
        );
    }
}

export default withSnackbar(WBSGanttHome)