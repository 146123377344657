import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { getCookie } from '../Helper/CookieHelper';
import Service from '../Networking/networkutils';
import { errorMessage } from '../Helper/ErrorMessage';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { CoffeeLoading } from 'react-loadingg';
import SecuredProjectRoles from '../SecuredWorks/SecuredProjectRoles';

const user = JSON.parse(getCookie("bb_ts_user"));
const token = getCookie("bb_ts_token");

// var waitData = false;

class AllocateUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roleActData: {},
            taskRoles: [],
            userListData: [],
            selected_role: null,
            selected_user: null,
            selected_hours: 0,
            actualData: null,
            usersData: props.usersData,
            est_hours: 0,
            rev_hours: 0,
            isLoadingHours: true,
            pendingHourRequest: [],
            openHourRequest: false,
            allUsers: [],
            searchBy: 'role',
            openCoffee: true,
            roleChangeDisabled: false,
            userTaskRoles: [],
            showAlert: false,
            openAddRole: false,
            country: null,
            openManageTaskRoles: false,
            addRoleDisabled: false,
            submitRoleDisabled: false,
            data: this.props.data,
            loadRefresh: false,
        };
    }

    componentDidMount() {
        this.fetchTaskDetails();
    }

    getAllUsers() {
        this.setState({ openCoffee: true })
        Service.get('/bb/api/users/active_users/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let userIds = []
                for (let i = 0; i < this.state.data.length; i++) {
                    const element = this.state.data[i];
                    for (let j = 0; j < element.users.length; j++) {
                        const emp = element.users[j];
                        if (!userIds.includes(emp.user_id)) {
                            userIds.push(emp.user_id)
                        }
                    }

                }
                let filteredUsers = []
                for (let j = 0; j < res.data.length; j++) {
                    const users = res.data[j];
                    if (!userIds.includes(users.id)) {
                        filteredUsers.push(users)
                    }
                }
                this.setState({
                    allUsers: filteredUsers,
                    openCoffee: false,
                    userTaskRoles: this.state.taskRoles
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    postData(userdata) {
        // var pageView = window.location.pathname + "/#TenderAddAndEdit";
        // ReactGA.set({ page: pageView, });
        // ReactGA.pageview(pageView);
        if (userdata === '') {
            this.props.enqueueSnackbar('Select a user to continue!', {
                variant: 'warning'
            });
        } else {
            this.setState({ isLoading: true, submitRoleDisabled: true })

            if (this.state.selected_role && this.state.selected_user) {
                if (this.state.selected_hours > 0) {
                    var postData = {
                        task_id: this.props.value.id,
                        role_id: this.state.selected_role.t_role,
                        task_role_id: this.state.selected_role.id,
                        wbs_id: this.props.value.t_ohlwbs,
                        t_pid: this.props.value.t_pid,
                        user_id: this.state.selected_user.id,
                        user_email: this.state.selected_user.email,
                        created_by: user.email,
                        planed_hours: this.state.selected_hours
                    }
                    Service.post('/bb/api/tender/user/task/user_task_allocation/', {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: postData,
                    })
                        .then(res => {
                            // waitData = false;
                            // data.push(res.data);
                            // autocompdata = '';
                            if (this.props.closeUserDialog) {
                                this.props.closeUserDialog(true);
                            }
                            this.props.enqueueSnackbar('Data Added Successfully!', {
                                variant: 'success'
                            });
                        })
                        .catch(error => {
                            if (error && error.status === 406) {
                                this.props.enqueueSnackbar(error.data.message, {
                                    variant: 'warning'
                                });
                                this.setState({ isLoading: false, submitRoleDisabled: false })
                            } else {
                                // waitData = false;
                                errorMessage(this.props, error)
                                this.setState({ isLoading: false, submitRoleDisabled: false })
                            }
                        });
                } else {
                    this.props.enqueueSnackbar('Hours must be greater than 0!', {
                        variant: 'warning'
                    });
                    this.setState({ isLoading: false, submitRoleDisabled: false })
                }
            } else {
                this.setState({ isLoading: false, submitRoleDisabled: false })
                this.props.enqueueSnackbar('Fill all fields!', {
                    variant: 'warning'
                });
            }

        }

    }

    fetchTaskDetails() {
        Service.get('/bb/api/tender/tasks/roles/get_roles_data/?task_id=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({
                    openCoffee: false,
                    taskRoles: res.data
                }, () => {
                    if (this.state.selected_user) {
                        this.allUsersChange(null, this.state.selected_user)
                    }
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    allUsersChange = (event, values) => {
        if (values) {
            this.setState({ selected_user: values, selected_role: null }, () => {
                // debugger
                var roles = []
                for (let i = 0; i < this.state.taskRoles.length; i++) {
                    const element = this.state.taskRoles[i];
                    if (this.state.selected_user.role_id === element.t_role) {
                        roles.push(element)
                    }
                }
                if (roles.length === 0) {
                    this.setState({ showAlert: true, roleChangeDisabled: true })
                } else if (roles.length === 1) {
                    this.setState({ selected_role: roles[0], roleChangeDisabled: true, showAlert: false })
                } else if (roles.length > 0) {
                    this.setState({ userTaskRoles: roles, showAlert: false })
                }
            })
        } else {
            this.setState({ selected_user: null, showAlert: false, selected_role: null, roleChangeDisabled: false, })
        }
    }

    countryMatchCheck() {
        if (this.state.selected_role?.country === 'in' && this.state.selected_user?.office_loc === 1) {
            return false
        } else if (this.state.selected_role?.country === 'uk' && this.state.selected_user?.office_loc === 0) {
            return false
        } else if (this.state.selected_role?.country === 'sl' && this.state.selected_user?.office_loc === 2) {
            return false
        } else {
            return true
        }
    }

    returnWarningText() {

        if (this.state.selected_user && this.state.selected_role) {
            var text = "You are selecting the "
            if (this.state.selected_user?.office_loc === 1) {
                text += 'India user for '
            } else if (this.state.selected_user?.office_loc === 0) {
                text += 'UK user for '
            } else if (this.state.selected_user?.office_loc === 2) {
                text += 'Sri Lanka user for '
            }

            if (this.state.selected_role?.country === 'in') {
                text += "India role."
            } else if (this.state.selected_role?.country === 'uk') {
                text += "UK role."
            } else if (this.state.selected_role?.country === 'sl') {
                text += "Sri Lanka role."
            }
            return text

        }

    }

    getDefaultRoles() {
        this.setState({ openAddRole: true })
        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ defaultRoles: res.data }, () => {
                    if (this.state.selected_user) {
                        for (let i = 0; i < this.state.defaultRoles.length; i++) {
                            const element = this.state.defaultRoles[i];
                            if (this.state.selected_user.role_id === element.id) {
                                this.setState({ selected_role: element })
                                break
                            }
                        }
                    }
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    onRoleChange = (event, values) => {
        this.setState({ selected_role: values }, () => {
            if (values && values.users) {
                let userIds = []
                for (let i = 0; i < this.state.data.length; i++) {
                    const user = this.state.data[i];
                    if (!userIds.includes(user.user_id)) {
                        userIds.push(user.user_id)
                    }
                }
                let filteredUsers = []
                for (let j = 0; j < values.users.length; j++) {
                    const users = values.users[j];
                    if (!userIds.includes(users.id)) {
                        filteredUsers.push(users)
                    }
                }
                this.setState({ userListData: filteredUsers })
            } else {
                this.setState({ userListData: [], selected_user: null })
            }

        })
    }

    onDefaultRoleChange = (event, values) => {
        this.setState({ selected_role: values ? values : null })
    }

    onUserChange = (event, values) => {
        this.setState({ selected_user: values }, () => {
        })
    }

    addData() {
        let fields = ''

        if (!this.state.selected_role) {
            fields = "Role"
        }
        if (!this.state.selected_hours) {
            fields += !fields ? 'Hours' : ', Hours'
        }
        if (!this.state.country) {
            fields += !fields ? 'Country' : ", Country"
        }

        if (!fields) {
            var roleExist = false;
            this.setState({ addRoleDisabled: true })
            for (let i = 0; i < this.state.taskRoles.length; i++) {
                const element = this.state.taskRoles[i];
                if (element.t_role === this.state.selected_role.id && element.country === this.state.country) {
                    roleExist = true;
                    break;
                }
            }
            if (!roleExist) {
                var postData = {};
                postData.t_pid = this.props.value.t_pid;
                postData.t_wbs = this.props.value.t_ohlwbs;
                postData.t_tasks = this.props.value.id;
                postData.t_role = this.state.selected_role.id;
                postData.remark = '';
                postData.created_by = user.email;
                postData.name = this.state.selected_role.name;
                postData.grade = this.state.selected_role.grade.toLowerCase();
                postData.hours = 0;
                postData.req_hours = this.state.selected_hours;
                postData.country = this.state.country;
                postData.goon = 1;
                postData.hours_rev_history = [{
                    user: user.id,
                    user_name: user.first_name + " " + user.last_name,
                    start: this.state.selected_hours,
                    date_modified: new Date(),
                    rev_new: this.state.selected_hours,
                }]

                Service.post("/bb/api/tender/tasks/rolesrevhistory/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: postData,
                })
                    .then(res => {
                        this.setState({
                            isLoading: false,
                            openAddRole: false,
                            showAlert: false,
                            addRoleDisabled: false,
                            loadRefresh: true
                        })
                        this.props.enqueueSnackbar('Roles updated successfully', {
                            variant: 'success'
                        });
                        this.fetchTaskDetails();
                    })
                    .catch(error => {
                        console.log(error)
                        errorMessage(this.props, error)
                        this.setState({ addRoleDisabled: false })
                    });
            } else {
                this.props.enqueueSnackbar('Role with same country configuration already exist!', {
                    variant: 'warning'
                });
            }
        }
        else {
            this.props.enqueueSnackbar(fields + " is Empty!", {
                variant: 'warning'
            });
        }
    }

    render() {

        const handleToggle = (e, newValue) => {
            if (newValue !== null) {
                this.setState({ searchBy: newValue, selected_user: null, selected_role: null, showAlert: false }, () => {
                    if (this.state.searchBy === 'user' && this.state.allUsers.length === 0) {
                        this.getAllUsers()
                    }
                })
            }
        };

        const handleHoursChange = (e) => {
            this.setState({ selected_hours: e.target.value, })
        };

        const handleManageTaskRolesClose = () => {
            this.setState({ openManageTaskRoles: false }, () => { this.fetchTaskDetails() })
        };

        const handleAddRolesClose = () => {
            this.setState({ openAddRole: false, selected_role: null, country: null })
        };

        const handleCountryChange = (event) => {
            this.setState({ country: event.target.value });
        };

        return (
            <div>
                <Box display={'flex'} flexDirection='row' alignItems={'center'}>
                    <Box fontWeight={500} fontSize='18px' marginRight={'10px'}>
                        Allocate User
                    </Box>
                    <ToggleButtonGroup
                        color="primary"
                        size="small"
                        // style={{ marginBottom: '3px' }}
                        value={this.state.searchBy}
                        onChange={handleToggle}
                        exclusive
                        aria-label="text formatting"
                    >
                        <ToggleButton value={"role"} aria-label="to_lead">
                            Search by role
                        </ToggleButton>
                        <ToggleButton value={"user"} aria-label="to_em">
                            Search by user
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                {this.state.openCoffee ?
                    <Box m={5} p={2}>
                        <CoffeeLoading />
                    </Box>
                    :
                    <Box>
                        {this.state.searchBy === 'role' ?
                            <>
                                <Box display="flex" flexDirection="row" m={2}>
                                    <Autocomplete
                                        id="'selected_role'"
                                        options={this.state.taskRoles}
                                        value={(this.state.selected_role === "") ? (null) : (this.state.selected_role)}
                                        getOptionLabel={(option) => option.name + ((option.country === "in") ? " - IND (" : (option.country === "sl") ? " - SL (" : " - UK (") + (option.hours + option.req_hours) + " hours)"}
                                        style={{ width: '100%' }}
                                        onChange={this.onRoleChange}
                                        renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
                                    />
                                </Box>
                                <Box display="flex" flexDirection="row" m={2}>
                                    <Autocomplete
                                        id="'selected_user'"
                                        options={this.state.userListData}
                                        value={(this.state.selected_user === "") ? (null) : (this.state.selected_user)}
                                        getOptionLabel={(option) => option.last_name + ", " + option.first_name}
                                        style={{ width: '100%' }}
                                        onChange={this.onUserChange}
                                        renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                                    />
                                </Box>

                                {this.countryMatchCheck() ?
                                    <Box m={2} fontSize={'12px'} color='red'>
                                        {this.returnWarningText()}
                                    </Box>
                                    : null}
                            </> :
                            <>
                                <Box display="flex" flexDirection="row" m={2}>
                                    <Autocomplete
                                        id="'selected_user'"
                                        options={this.state.allUsers}
                                        value={(this.state.selected_user === "") ? (null) : (this.state.selected_user)}
                                        getOptionLabel={(option) => option.last_name + ", " + option.first_name}
                                        style={{ width: '100%' }}
                                        onChange={this.allUsersChange}
                                        renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                                    />
                                </Box>

                                {this.state.showAlert ?
                                    <Box display="flex" flexDirection="row" m={2} justifyContent='right'>
                                        <Alert severity="warning" style={{ width: '100%' }}
                                            action={
                                                <Box display="flex" flexDirection={"column"}>
                                                    <Button variant={'outlined'} color="inherit" size="small" onClick={() => this.getDefaultRoles()}>
                                                        Add Role
                                                    </Button>
                                                    <Button variant={'outlined'} color="inherit" size="small" style={{ marginTop: '5px' }} onClick={() => this.setState({ openManageTaskRoles: true })}>
                                                        Manage task roles
                                                    </Button>
                                                </Box>}>{this.state.selected_user ? this.state.selected_user?.role_name + " role " : "Primary Role "} of Selected User is not available at this Task. Please click 'Add Role' to add the role to task.</Alert>
                                    </Box> :
                                    <Box display="flex" flexDirection="row" m={2}>
                                        <Autocomplete
                                            disabled={this.state.roleChangeDisabled}
                                            id="'selected_role'"
                                            options={this.state.userTaskRoles}
                                            value={(this.state.selected_role === "") ? (null) : (this.state.selected_role)}
                                            getOptionLabel={(option) => option.name + ((option.country === "in") ? " - IND (" : (option.country === "sl") ? " - SL (" : " - UK (") + (option.hours + option.req_hours) + " hours)"}
                                            style={{ width: '100%' }}
                                            onChange={this.onRoleChange}
                                            renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
                                        />
                                    </Box>}
                                {this.countryMatchCheck() ?
                                    <Box m={2} fontSize={'12px'} color='red'>
                                        {this.returnWarningText()}
                                    </Box>
                                    : null}
                            </>
                        }

                        <Box display="flex" flexDirection="row" m={2}>
                            <TextField
                                id="hours"
                                type={'number'}
                                label="Hours"
                                variant="outlined"
                                style={{ width: '100%' }}
                                value={this.state.selected_hours}
                                onChange={handleHoursChange}
                            />
                        </Box>
                    </Box>
                }
                <Box
                    display='flex'
                    flexDirection='row'
                    width='100%'
                    marginTop='15px'
                    justifyContent='flex-end'>
                    <Button size='small' variant='contained' color="primary" onClick={() => {
                        if (this.props.closeUserDialog) {
                            this.props.closeUserDialog(true);
                        }
                    }} style={{ marginRight: "10px" }}>
                        Close
                    </Button>

                    <Button variant='contained' disabled={this.state.submitRoleDisabled} onClick={() => this.postData()} color="primary">
                        Submit
                    </Button>

                </Box>

                <Dialog onClose={handleManageTaskRolesClose} aria-labelledby="customized-dialog-title" maxWidth="xl" open={this.state.openManageTaskRoles}>

                    <DialogContent>
                        <Box display="flex" flexDirection="row-reverse">
                            <Button color='primary' onClick={handleManageTaskRolesClose}>Close</Button>
                        </Box>
                        <SecuredProjectRoles value={this.props.value} task={this.props.value} rolesLookUp={this.props.allRoles} projectLook={this.props.projectLook} />
                    </DialogContent>
                </Dialog>

                <Dialog open={this.state.openAddRole} fullWidth={true} maxWidth="sm" onClose={handleAddRolesClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title" onClose={handleAddRolesClose}>
                        Add Role for {this.state.selected_user ? (this.state.selected_user.last_name + ", " + this.state.selected_user.first_name) : null}
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <Box display="flex" flexDirection="row" m={2}>
                                <Autocomplete
                                    id="'selected_role'"
                                    disabled={this.state.roleChangeDisabled}
                                    options={this.state.defaultRoles}
                                    value={(this.state.selected_role === "") ? (null) : (this.state.selected_role)}
                                    getOptionLabel={(option) => option.name}
                                    style={{ width: '100%' }}
                                    onChange={this.onDefaultRoleChange}
                                    renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
                                />
                            </Box>
                            <Box display={'flex'} flexDirectio='row' m={2} >
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.country}
                                        onChange={handleCountryChange}
                                        label="Country"
                                    >
                                        <MenuItem value="in">India</MenuItem>
                                        <MenuItem value="uk">UK</MenuItem>
                                        <MenuItem value="sl">Srilanka</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box display="flex" flexDirection="row" m={2} >
                                <TextField
                                    id="hours"
                                    type={'number'}
                                    label="Hours"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={this.state.selected_hours}
                                    onChange={handleHoursChange}
                                />
                            </Box>

                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleAddRolesClose} color="primary">
                            Close
                        </Button>

                        <Button disabled={this.state.addRoleDisabled} onClick={() => this.addData()} color="primary">
                            Add
                        </Button>

                    </DialogActions>
                </Dialog >
            </div >
        );
    }
}

export default withSnackbar(AllocateUser);