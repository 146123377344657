import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { SemipolarLoading } from 'react-loadingg';
import { Paper, Typography } from '@material-ui/core';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import { getPrimaryColor } from '../Helper/CommonThings';
import ManageTaskCategoryTables from './ManageTaskCategoryTables';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@material-ui/core/Box';
import CompanyCategory from './CompanyCategory';
import { withStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';

const useStyles = (theme) => ({

    paper: {
        // padding: theme.spacing(2),
        // textAlign: 'center',
        // backgroundColor: "#008c99",
    },
});

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
class ManageTaskCategory extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            openAdd: false,
            projName: '',
            isLoading: true,
            value: 0,
            companies: [],
            isLoading1: false,
        }
        this.refresh = React.createRef();
        this.refreshData = this.refreshData.bind(this);
    }

    componentDidMount() {
        if (access && access.access.l3a.l3a_e) {
            this.getCompanies();
        } else {
            this.setState({ isLoading: false })
        }
    }

    getCompanies() {
        Service.get("/bb/api/company/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ companies: res.data, isLoading: false })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    postData() {

        this.setState({ isLoading: true, });
        var json = {
            name: this.state.projName,
            created_by: user.id,
            pid: 11
        }

        Service.post("/bb/api/task/category/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: json,
        })
            .then(res => {

                this.setState({ openAdd: false, projName: '' });
                // console.log(res.data)
                this.props.enqueueSnackbar('Category added successfully', {
                    variant: 'success'
                });
                this.fetchOps();
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    putData(asdata) {

        // console.log(asdata)

        if (asdata.name) {

            // this.setState({ isLoading: true })
            Service.put('/bb/api/task/category/' + asdata.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: asdata,
            })
                .then(res => {
                    // console.log(res.data)
                    this.setState({ isLoading: false })
                    this.props.enqueueSnackbar('Edited Successfully!', {
                        variant: 'success'
                    });
                    // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                    this.setState({ isLoading: false })
                });
        } else {
            this.props.enqueueSnackbar('Category name is empty!', {
                variant: 'warning'
            });
        }

    }

    refreshData() {
        if (this.refresh.current) {
            this.refresh.current.fetchOpsByCompany();
        }
    }
    render() {

        const { classes } = this.props;

        const handleChange = (event, newValue) => {
            this.setState({ value: newValue, isLoading1: true }, () => {
                this.setState({ isLoading1: false })
            });
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <SemipolarLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        {access && access.access.l3a.l3a_e ? (
                            <div>
                                <Paper square className={classes.paper} elevation={4}>
                                    {/* <AppBar position="static" > */}
                                    <Tabs value={this.state.value} onChange={handleChange} aria-label="simple tabs example"
                                        indicatorColor="secondary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        {this.state.companies.map((value, index) =>
                                            <Tab label={this.state.companies[index].name} {...a11yProps(index)} />
                                        )}
                                    </Tabs>

                                    {/* </AppBar> */}
                                    {this.state.companies.map((value, index) =>
                                        <TabPanel value={this.state.value} index={index}>
                                            <CompanyCategory ref={this.refresh} data={value} />
                                        </TabPanel>
                                    )}
                                </Paper>

                                <div style={{ margin: '5px' }}>
                                    <ManageTaskCategoryTables refreshData={this.refreshData} />
                                </div>

                            </div>
                        ) : (
                            <div style={{ margin: '10px' }}>
                                <div>
                                    <CompanyCategory ref={this.refresh} />
                                </div>
                                <div>
                                    <ManageTaskCategoryTables refreshData={this.refreshData} />
                                </div>
                            </div>)}
                    </div>
                )}

            </div>
        )
    }
}

export default withStyles(useStyles)(withSnackbar(ManageTaskCategory));