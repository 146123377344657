import React, { Component } from 'react'
import { Box, Typography, } from '@material-ui/core'
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading'
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import ReactGA from 'react-ga';
import { getPrimaryColor } from '../../../Helper/CommonThings';
// const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));


class Summary3 extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            openAdd: false,
            isLoading: true,
            addHours: 0,
            addEmp: 0,
            area: 'in',
            roles: {},
            open: false,
            columns: [
                { title: 'Roles', field: 'name', editable: 'never', },
                {
                    title: 'Grade', field: 'grade', editable: 'never',
                    lookup: { 'a': 'A', 'b': 'B', 'c': 'C', 'd': 'D', 'e': 'E', 'f': 'F', 'g': 'G' },
                },
                { title: 'Hours', field: 'hours', type: 'numeric' },
                {
                    title: 'Country', field: 'country',
                    lookup: { 'in': 'India', 'uk': 'UK', 'sl': 'Srilanka' },
                },
                {
                    title: 'Amount', field: 'cost', editable: 'never',
                    render: rowData => <Typography>{this.costCalc(rowData)}</Typography>
                },
                { title: 'Remarks', field: 'remark', },
            ],
        }
    }

    componentDidMount() {

        var pageView = window.location.pathname;
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        // console.log(this.props.rates)
        var roles1 = [];
        for (let i = 0; i < this.props.roles.length; i++) {
            const element = this.props.roles[i];
            if (element.t_tasks === this.props.value.id) {
                roles1.push(element)
            }
        }
        this.setState({
            roles: this.props.value,
            data: JSON.parse(JSON.stringify(roles1)),
            isLoading: false,
        });
    }

    costCalc(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
                const element = this.props.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.hours * element.costin);
                }
            }
        } else {
            for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
                const element = this.props.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.hours * element.costuk);
                }
            }
        }
        return 0;
    }

    render() {


        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <Box p={3}>
                            <MaterialTable
                                key={3}
                                title="Users Assigned"
                                columns={[
                                    { title: 'Roles', field: 'name', editable: 'never', },
                                    {
                                        title: 'Grade', field: 'grade', editable: 'never',
                                        lookup: { 'a': 'A', 'b': 'B', 'c': 'C', 'd': 'D', 'e': 'E', 'f': 'F', 'g': 'G' },
                                    },
                                    { title: 'Hours', field: 'hours', type: 'numeric' },
                                    {
                                        title: 'Country', field: 'country',
                                        lookup: { 'in': 'India', 'uk': 'UK','sl':'Srilanka' },
                                    },
                                    {
                                        title: 'Amount', field: 'cost', editable: 'never',
                                        render: rowData => <Typography>{this.costCalc(rowData)}</Typography>
                                    },
                                    { title: 'Remarks', field: 'remark', },
                                ]}
                                data={this.state.data}
                                options={{
                                    actionsColumnIndex: 0,
                                    padding: "dense",
                                }}

                            />
                        </Box>

                    </div>
                )}
            </div>

        )
    }
}

export default withSnackbar(Summary3)