import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import MaterialTable from 'material-table'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FutureBudgetOtherTeamData from './FutureBudgetOtherTeamData'
import Button from '@material-ui/core/Button';
import FutureBudgetErrorData from './FutureBudgetErrorData';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

const token = getCookie("bb_ts_token");
// const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class FutureBudget extends Component {

    constructor(props) {
        super(props)

        this.state = {
            budgetData: [],
            openOtherTeamData: false,
            selectedUser: null,
            postPutErrorData: [],
            openUserPercentSet: false,
            percentText: 0,
        }
    }

    componentDidMount() {
        this.budgetCalc()
    }

    budgetCalc() {
        var teamMembersData = []
        for (let i = 0; i < this.props.allTeamMembers.length; i++) {
            const element = this.props.allTeamMembers[i];
            if (this.props.team.id === element.gid) {
                element.full_name = element.uid_data.last_name + ", " + element.uid_data.first_name
                teamMembersData.push(element)
            }
        }

        for (let i = 0; i < this.props.futureRequirement.length; i++) {
            const element = this.props.futureRequirement[i];
            if (!element.uid && element.gid === this.props.team.id) {
                teamMembersData.push({
                    gid: element.gid,
                    future_req: element.id,
                    date_of_req: element.date_of_req,
                    full_name: "[Future Req]",
                    uid_data: {
                        role_name: element.role_data.name,
                        role_grade: element.role_data.grade,
                        office_loc: element.office_loc,
                        role_id: element.role
                    }
                })
            }
        }

        for (let i = 0; i < teamMembersData.length; i++) {
            const element = teamMembersData[i];
            element.userOtherTeamData = 0
            for (let j = 0; j < this.props.allTeamMembers.length; j++) {
                const element1 = this.props.allTeamMembers[j];
                if (element.uid === element1.uid && element.gid !== element1.gid) {
                    ++element.userOtherTeamData
                }
            }

            for (let m = 0; m < this.props.futureBudget.length; m++) {
                const element1 = this.props.futureBudget[m];
                if (element.uid === element1.uid && element.gid === element1.gid) {
                    element[element1.month.toString()] = element1.percent

                }
            }

            for (let m = 0; m < this.props.futureBudget.length; m++) {
                const element1 = this.props.futureBudget[m];
                if (element.future_req === element1.future_req && element.gid === element1.gid) {
                    element[element1.month.toString()] = element1.percent
                    // element.editable =  this.checkEditableForReq(element, element1.month)
                }
            }

            for (let k = 0; k < this.props.rateCard.length; k++) {
                const element1 = this.props.rateCard[k];
                if (element.uid_data.role_grade.toLowerCase() === element1.grade.toLowerCase()) {
                    if (element.uid_data.office_loc === 0) {
                        element.rate = element1.costuk
                    } else if (element.uid_data.office_loc === 1 || element.uid_data.office_loc === 2) {
                        element.rate = element1.costin
                    }
                }
            }

        }
        if (this.props.typeOfDisplay === "amount") {
            var totalAmount = {}
            for (let month = 1; month <= 12; month++) {
                var monthTotal = 0
                for (let i = 0; i < teamMembersData.length; i++) {
                    const element = teamMembersData[i];
                    if (element[month.toString()]) {
                        // console.log(this.calcAmountForMonthInNumber(month, element))
                        monthTotal += this.calcAmountForMonthInNumber(month, element)
                    }
                }
                totalAmount[month.toString()] = monthTotal
            }

            // console.log(totalAmount)

            teamMembersData.push(totalAmount)
        }
        // console.log(teamMembersData)
        this.setState({
            budgetData: teamMembersData
        })
    }

    processPostData(postData) {
        var post_data = []
        var put_data = []
        for (let postDataIndex = 0; postDataIndex < postData.length; postDataIndex++) {
            const postDataElement = postData[postDataIndex];
            for (let i = 1; i <= 12; i++) {
                var is_exist_in_db = false
                var exist_data = null
                for (let j = 0; j < this.props.futureBudget.length; j++) {
                    const element1 = this.props.futureBudget[j];
                    if (element1.uid && element1.uid === postDataElement.uid && element1.gid === postDataElement.gid && element1.month === i && element1.year === this.props.BUDGETING_YEAR) {
                        is_exist_in_db = true
                        exist_data = element1
                    } else if (element1.future_req && element1.future_req === postDataElement.future_req && element1.gid === postDataElement.gid && element1.month === i && element1.year === this.props.BUDGETING_YEAR) {
                        is_exist_in_db = true
                        exist_data = element1
                    }
                }
                // console.log(postData, i.toString())
                if (postDataElement[i.toString()] === 0 || postDataElement[i.toString()] === "0" || postDataElement[i.toString()]) {
                    if (is_exist_in_db) {
                        put_data.push({
                            id: exist_data.id,
                            uid: exist_data.uid,
                            month: exist_data.month,
                            percent: postDataElement[i.toString()],
                            updated_by: user.id,
                            full_name: postDataElement.full_name,
                            uid_data: postDataElement.uid_data
                        })
                    } else {
                        post_data.push({
                            uid: postDataElement.uid,
                            gid: postDataElement.gid,
                            future_req: postDataElement.future_req,
                            role: postDataElement.uid_data.role_id,
                            month: i,
                            year: this.props.BUDGETING_YEAR,
                            percent: postDataElement[i.toString()],
                            updated_by: user.id,
                            created_by: user.id,
                            full_name: postDataElement.full_name,
                            uid_data: postDataElement.uid_data
                        })
                    }
                }
            }
        }
        return {
            post_data: post_data,
            put_data: put_data
        }
    }

    postData(postData) {
        // console.log(postData)
        var errorData = []
        var processData = this.processPostData(postData)
        // console.log(processData)
        if (processData.post_data.length > 0) {
            Service.post(`/bb/api/budget/future/estimate/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: processData.post_data
            })
                .then(res => {
                    var temp = this.props.futureBudget
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];

                        temp.push(element)
                    }

                    if (res.data.length < processData.post_data.length) {
                        var userMonthDataOk = false
                        for (let j = 0; j < processData.post_data.length; j++) {
                            const element1 = processData.post_data[j];
                            for (let i = 0; i < res.data.length; i++) {
                                const element = res.data[i];
                                if (element.uid === element1.uid && element.month === element1.month) {
                                    userMonthDataOk = true
                                    break
                                }
                            }
                            if (!userMonthDataOk) {
                                errorData.push(element1)
                            }
                        }

                        this.budgetCalc()

                        this.setState({
                            openOtherTeamData: true,
                            postPutErrorData: errorData
                        })

                    }
                    // console.log(temp.length)
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });

        }
        if (processData.put_data.length > 0) {
            Service.patch(`/bb/api/budget/future/estimate/bulk_update/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: processData.put_data
            })
                .then(res => {
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        for (let j = 0; j < this.props.futureBudget.length; j++) {
                            const element1 = this.props.futureBudget[j];
                            if (element.id === element1.id) {
                                element1.percent = element.percent
                                break
                            }
                        }
                    }
                    if (res.data.length < processData.put_data.length) {
                        var userMonthDataOk = false
                        for (let j = 0; j < processData.put_data.length; j++) {
                            const element1 = processData.put_data[j];
                            for (let i = 0; i < res.data.length; i++) {
                                const element = res.data[i];
                                if (element.uid === element1.uid && element.month === element1.month) {
                                    userMonthDataOk = true
                                    break
                                }
                            }
                            if (!userMonthDataOk) {
                                errorData.push(element1)
                            }
                        }
                        this.budgetCalc()

                        this.setState({
                            openOtherTeamData: true,
                            postPutErrorData: errorData
                        })

                    }
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });

        }
    }

    calcAmountForMonth(month, rowData) {
        var totalHours = 0
        var hoursAsPerUser = null
        if (rowData.rate === undefined) {
            var totalAmount = 0
            for (let j = 0; j < this.state.budgetData.length - 1; j++) {

                const element2 = this.state.budgetData[j];
                for (let i = 0; i < this.props.futureBudgetHours.length; i++) {
                    const element = this.props.futureBudgetHours[i];
                    if (element.month === month) {
                        totalHours = element.expected_hours
                    }
                }

                if (element2[month.toString()]) {
                    hoursAsPerUser = totalHours * (element2[month.toString()] / 100)
                    totalAmount += element2.rate * hoursAsPerUser
                }
            }
            if (totalAmount) {
                return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(totalAmount)
            } else {
                return null
            }

        } else {

            for (let i = 0; i < this.props.futureBudgetHours.length; i++) {
                const element = this.props.futureBudgetHours[i];
                if (element.month === month) {
                    totalHours = element.expected_hours
                }
            }

            if (rowData[month.toString()]) {
                hoursAsPerUser = totalHours * (rowData[month.toString()] / 100)
                return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.rate * hoursAsPerUser)
            }

            return null
        }

    }

    calcAmountForMonthInNumber(month, rowData) {
        var totalHours = 0
        for (let i = 0; i < this.props.futureBudgetHours.length; i++) {
            const element = this.props.futureBudgetHours[i];
            if (element.month === month) {
                totalHours = element.expected_hours
            }
        }

        if (rowData[month.toString()]) {
            var hoursAsPerUser = totalHours * (rowData[month.toString()] / 100)
            return rowData.rate * hoursAsPerUser
        }

        return 0

    }

    calcTotalBudgetOfTeam() {
        var totalAmount = 0
        for (let j = 0; j < this.state.budgetData.length - 1; j++) {
            const element2 = this.state.budgetData[j];
            for (let month = 1; month <= 12; month++) {
                var totalHours = 0
                for (let i = 0; i < this.props.futureBudgetHours.length; i++) {
                    const element = this.props.futureBudgetHours[i];
                    if (element.month === month) {
                        totalHours = element.expected_hours
                    }
                }

                if (element2[month.toString()]) {
                    var hoursAsPerUser = totalHours * (element2[month.toString()] / 100)
                    totalAmount += element2.rate * hoursAsPerUser
                }

            }

        }
        // console.log(totalAmount)
        if (totalAmount) {
            return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(totalAmount)
        } else {
            return null
        }
    }

    calcTotalAmountForUser(rowData) {
        var totalAmount = 0
        if (rowData.rate === undefined) {
            return this.calcTotalBudgetOfTeam()
        } else {
            for (let month = 1; month <= 12; month++) {
                var totalHours = 0
                for (let i = 0; i < this.props.futureBudgetHours.length; i++) {
                    const element = this.props.futureBudgetHours[i];
                    if (element.month === month) {
                        totalHours = element.expected_hours
                    }
                }

                if (rowData[month.toString()]) {
                    var hoursAsPerUser = totalHours * (rowData[month.toString()] / 100)
                    totalAmount += rowData.rate * hoursAsPerUser
                }

            }
            if (totalAmount) {
                return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(totalAmount)
            } else {
                return null
            }
        }


    }

    getOtherTeamData(rowData) {
        for (let i = 0; i < this.props.futureBudget.length; i++) {
            const element = this.props.futureBudget[i];
            if (element.uid === rowData.uid && element.gid !== rowData.gid) {
                return false
            }
        }
        return true
    }

    checkEditableForReq(rowData, month) {
        // console.log(rowData)
        if (rowData.uid === undefined) {
            // console.log("enter", rowData)
            var startDate = moment(rowData.date_of_req, 'YYYY-MM-DD')
            // console.log(startDate.year(), this.props.BUDGETING_YEAR)
            if (startDate.year() === this.props.BUDGETING_YEAR) {
                // console.log(rowData.date_of_req, startDate.month(), month)
                if (startDate.month() <= month - 1) {
                    return true
                } else {
                    // console.log("sadfas")
                    return false
                }
            } else if (startDate.year() < this.props.BUDGETING_YEAR) {
                return true
            } else {
                // console.log("false")
                return false
            }
        } else {
            if ((this.props.typeOfDisplay === 'percent' || !this.props.typeOfDisplay)) {
                // console.log("qwe")
                return true
            } else {
                return false
            }
        }
    }

    changePercentForUser(rowData) {
        this.setState({
            openUserPercentSet: true,
            selectedUser: rowData,
        })
    }

    fillOnePercentToAllForUser() {
        this.setState({
            openUserPercentSet: false
        })
        var data = [this.state.selectedUser]
        if (this.checkEditableForReq(data[0], 1)) {
            data[0]["1"] = parseInt(this.state.percentText)
        }
        if (this.checkEditableForReq(data[0], 2)) {
            data[0]["2"] = parseInt(this.state.percentText)
        }
        if (this.checkEditableForReq(data[0], 3)) {
            data[0]["3"] = parseInt(this.state.percentText)
        }
        if (this.checkEditableForReq(data[0], 4)) {
            data[0]["4"] = parseInt(this.state.percentText)
        }
        if (this.checkEditableForReq(data[0], 5)) {
            data[0]["5"] = parseInt(this.state.percentText)
        }
        if (this.checkEditableForReq(data[0], 6)) {
            data[0]["6"] = parseInt(this.state.percentText)
        }
        if (this.checkEditableForReq(data[0], 7)) {
            data[0]["7"] = parseInt(this.state.percentText)
        }
        if (this.checkEditableForReq(data[0], 8)) {
            data[0]["8"] = parseInt(this.state.percentText)
        }
        if (this.checkEditableForReq(data[0], 9)) {
            data[0]["9"] = parseInt(this.state.percentText)
        }
        if (this.checkEditableForReq(data[0], 10)) {
            data[0]["10"] = parseInt(this.state.percentText)
        }
        if (this.checkEditableForReq(data[0], 11)) {
            data[0]["11"] = parseInt(this.state.percentText)
        }
        if (this.checkEditableForReq(data[0], 12)) {
            data[0]["12"] = parseInt(this.state.percentText)
        }
      
        this.postData(data)
    }

    render() {

        const handleClose = () => {
            this.setState({
                openOtherTeamData: false,
                openUserPercentSet:false
            })
        };

        const handlePercentChange = (event) => {
            this.setState({
                percentText: event.target.value
            })
        };

        return (
            <div>
                <MaterialTable
                    title={this.props.team.name + " [" + this.calcTotalBudgetOfTeam() + "]"}
                    columns={[
                        {
                            title: "Member",
                            field: "full_name",
                            editable: 'never'
                        },
                        {
                            title: "Role",
                            field: "uid_data.role_name",
                            editable: 'never'
                        },
                        {
                            title: "Rate (£/hour)",
                            field: "rate",
                            editable: 'never'
                        },
                        {
                            title: 'Total', field: 'total',
                            cellStyle: {
                                backgroundColor: '#039be5',
                                color: '#FFF'
                            },
                            headerStyle: {
                                backgroundColor: '#039be5',
                            },
                            editable: 'never',
                            hidden: this.props.typeOfDisplay === 'percent',
                            render: rowData => rowData["1"] ? this.calcTotalAmountForUser(rowData) : null
                        },
                        {
                            title: "Jan (%)",
                            field: "1",
                            type: "numeric",
                            hidden: this.props.typeOfDisplay === 'amount',
                            editable: (column, rowData) => { return this.checkEditableForReq(rowData, 1) },
                            render: rowData => rowData["1"] ? rowData["1"] + "%" : null
                        },
                        {
                            title: "Jan (£)",
                            field: "1rate",
                            type: "numeric",
                            editable: 'never',
                            hidden: this.props.typeOfDisplay === 'percent',
                            render: rowData => rowData["1"] ? this.calcAmountForMonth(1, rowData) : null
                        },
                        {
                            title: "Feb (%)",
                            field: "2",
                            type: "numeric",
                            hidden: this.props.typeOfDisplay === 'amount',
                            editable: (column, rowData) => { return this.checkEditableForReq(rowData, 2) },
                            render: rowData => rowData["2"] ? rowData["2"] + "%" : null
                        },
                        {
                            title: "Feb (£)",
                            field: "2rate",
                            type: "numeric",
                            editable: 'never',
                            hidden: this.props.typeOfDisplay === 'percent',
                            render: rowData => rowData["2"] ? this.calcAmountForMonth(2, rowData) : null
                        },
                        {
                            title: "Mar (%)",
                            field: "3",
                            type: "numeric",
                            hidden: this.props.typeOfDisplay === 'amount',
                            editable: (column, rowData) => { return this.checkEditableForReq(rowData, 3) },
                            render: rowData => rowData["3"] ? rowData["3"] + "%" : null
                        },
                        {
                            title: "Mar (£)",
                            field: "3rate",
                            type: "numeric",
                            editable: 'never',
                            hidden: this.props.typeOfDisplay === 'percent',
                            render: rowData => rowData["3"] ? this.calcAmountForMonth(3, rowData) : null
                        },
                        {
                            title: "Apr (%)",
                            field: "4",
                            type: "numeric",
                            hidden: this.props.typeOfDisplay === 'amount',
                            editable: (column, rowData) => { return this.checkEditableForReq(rowData, 4) },
                            render: rowData => rowData["4"] ? rowData["4"] + "%" : null
                        },
                        {
                            title: "Apr (£)",
                            field: "4rate",
                            type: "numeric",
                            editable: 'never',
                            hidden: this.props.typeOfDisplay === 'percent',
                            render: rowData => rowData["4"] ? this.calcAmountForMonth(4, rowData) : null
                        },
                        {
                            title: "May (%)",
                            field: "5",
                            type: "numeric",
                            hidden: this.props.typeOfDisplay === 'amount',
                            editable: (column, rowData) => { return this.checkEditableForReq(rowData, 5) },
                            render: rowData => rowData["5"] ? rowData["5"] + "%" : null
                        },
                        {
                            title: "May (£)",
                            field: "5rate",
                            type: "numeric",
                            editable: 'never',
                            hidden: this.props.typeOfDisplay === 'percent',
                            render: rowData => rowData["5"] ? this.calcAmountForMonth(5, rowData) : null
                        },
                        {
                            title: "Jun (%)",
                            field: "6",
                            type: "numeric",
                            hidden: this.props.typeOfDisplay === 'amount',
                            editable: (column, rowData) => { return this.checkEditableForReq(rowData, 6) },
                            render: rowData => rowData["6"] ? rowData["6"] + "%" : null
                        },
                        {
                            title: "Jun (£)",
                            field: "6rate",
                            type: "numeric",
                            editable: 'never',
                            hidden: this.props.typeOfDisplay === 'percent',
                            render: rowData => rowData["6"] ? this.calcAmountForMonth(6, rowData) : null
                        },
                        {
                            title: "Jul (%)",
                            field: "7",
                            type: "numeric",
                            hidden: this.props.typeOfDisplay === 'amount',
                            editable: (column, rowData) => { return this.checkEditableForReq(rowData, 7) },
                            render: rowData => rowData["7"] ? rowData["7"] + "%" : null
                        },
                        {
                            title: "Jul (£)",
                            field: "7rate",
                            type: "numeric",
                            editable: 'never',
                            hidden: this.props.typeOfDisplay === 'percent',
                            render: rowData => rowData["7"] ? this.calcAmountForMonth(7, rowData) : null
                        },
                        {
                            title: "Aug (%)",
                            field: "8",
                            type: "numeric",
                            hidden: this.props.typeOfDisplay === 'amount',
                            editable: (column, rowData) => { return this.checkEditableForReq(rowData, 8) },
                            render: rowData => rowData["8"] ? rowData["8"] + "%" : null
                        },
                        {
                            title: "Aug (£)",
                            field: "8rate",
                            type: "numeric",
                            editable: 'never',
                            hidden: this.props.typeOfDisplay === 'percent',
                            render: rowData => rowData["8"] ? this.calcAmountForMonth(8, rowData) : null
                        },
                        {
                            title: "Sep (%)",
                            field: "9",
                            type: "numeric",
                            hidden: this.props.typeOfDisplay === 'amount',
                            editable: (column, rowData) => { return this.checkEditableForReq(rowData, 9) },
                            render: rowData => rowData["9"] ? rowData["9"] + "%" : null
                        },
                        {
                            title: "Sep (£)",
                            field: "9rate",
                            type: "numeric",
                            editable: 'never',
                            hidden: this.props.typeOfDisplay === 'percent',
                            render: rowData => rowData["9"] ? this.calcAmountForMonth(9, rowData) : null
                        },
                        {
                            title: "Oct (%)",
                            field: '10',
                            type: "numeric",
                            hidden: this.props.typeOfDisplay === 'amount',
                            editable: (column, rowData) => { return this.checkEditableForReq(rowData, 10) },
                            render: rowData => rowData["10"] ? rowData["10"] + "%" : null
                        },
                        {
                            title: "Oct (£)",
                            field: "10rate",
                            type: "numeric",
                            editable: 'never',
                            hidden: this.props.typeOfDisplay === 'percent',
                            render: rowData => rowData["10"] ? this.calcAmountForMonth(10, rowData) : null
                        },
                        {
                            title: "Nov (%)",
                            field: "11",
                            type: "numeric",
                            hidden: this.props.typeOfDisplay === 'amount',
                            editable: (column, rowData) => { return this.checkEditableForReq(rowData, 11) },
                            render: rowData => rowData["11"] ? rowData["11"] + "%" : null
                        },
                        {
                            title: "Nov (£)",
                            field: "11rate",
                            type: "numeric",
                            editable: 'never',
                            hidden: this.props.typeOfDisplay === 'percent',
                            render: rowData => rowData["11"] ? this.calcAmountForMonth(11, rowData) : null
                        },
                        {
                            title: "Dec (%)",
                            field: "12",
                            type: "numeric",
                            hidden: this.props.typeOfDisplay === 'amount',
                            editable: (column, rowData) => { return this.checkEditableForReq(rowData, 12) },
                            render: rowData => rowData["12"] ? rowData["12"] + "%" : null
                        },
                        {
                            title: "Dec (£)",
                            field: "12rate",
                            type: "numeric",
                            editable: 'never',
                            hidden: this.props.typeOfDisplay === 'percent',
                            render: rowData => rowData["12"] ? this.calcAmountForMonth(12, rowData) : null
                        },
                    ]}
                    data={this.state.budgetData}
                    options={{
                        padding: "dense",
                        paging: false,
                        rowStyle: rowData => (rowData.rate === undefined ? {
                            backgroundColor: '#01579b',
                        } : null)
                    }}
                    detailPanel={[

                        rowData => ({
                            icon: 'call_split',
                            tooltip: 'This User is also budgetted in other Team',
                            disabled: this.getOtherTeamData(rowData),
                            render: rowData => {
                                return (
                                    <FutureBudgetOtherTeamData allTeamMembers={this.props.allTeamMembers}
                                        futureRequirement={this.props.futureRequirement}
                                        team={this.props.team}
                                        futureBudget={this.props.futureBudget}
                                        rateCard={this.props.rateCard}
                                        selectedUser={rowData}
                                    />
                                )
                            },
                        }),


                    ]}
                    actions={[
                        {
                            icon: 'assignment_late',
                            tooltip: 'Set one percentage to all month',
                            onClick: (event, rowData) => {this.changePercentForUser(rowData)}
                        }
                    ]}
                    editable={
                        this.props.typeOfDisplay === 'percent' || !this.props.typeOfDisplay ? {
                            onBulkUpdate: changes =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        resolve();
                                        var newData = []
                                        this.setState(prevState => {
                                            const data = [...prevState.budgetData];
                                            for (let i = 0; i < data.length; i++) {
                                                if (changes[i.toString()]) {
                                                    const element = changes[i.toString()];
                                                    // console.log(data.indexOf(element.oldData))
                                                    newData.push(element.newData)
                                                    data[data.indexOf(element.oldData)] = element.newData;
                                                }

                                            }
                                            this.postData(newData)
                                            return { ...prevState, budgetData: data };
                                        });

                                    }, 1000);
                                }),

                        } : null}
                />
                <Dialog
                    open={this.state.openOtherTeamData}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={"md"}
                    fullWidth
                >
                    <DialogContent>
                        <FutureBudgetErrorData data={this.state.postPutErrorData} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openUserPercentSet}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <TextField id="outlined-basic1" label="Percent"
                            variant="outlined"
                            type="number"
                            value={this.state.percentText}
                            onChange={handlePercentChange}
                        />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                        <Button onClick={() => this.fillOnePercentToAllForUser()} color="primary">
                            Submit
                        </Button>

                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}

export default withSnackbar(FutureBudget)