import React, { Component } from 'react'
import MaterialTable from 'material-table'

export default class WorkHoursDialog extends Component {
    render() {
        return (
            <div>
                <MaterialTable
                    title="Summary"
                    columns={this.props.value[0]}
                    data={this.props.value[1]}
                    options={{
                        padding: "dense",
                        filtering: true,
                        exportButton: { csv: true },
                        pageSize: 20,
                        pageSizeOptions: [10, 20, 50],
                    }}
                />
            </div>
        )
    }
}
