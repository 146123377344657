import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { Chart } from "react-google-charts";
// import { LoopCircleLoading } from 'react-loadingg';
import { Box } from '@material-ui/core';
// import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { getDarkMode, getPrimaryColor } from '../Helper/CommonThings';
import { screenSizeCheck } from '../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import { getBrowncolor, getDarkGreenColor, getPinkColor } from '../MainDashBoard/ColorTheme/ColorTheme';

const useStyles = (theme) => ({

});



class UserWorkLoadGraph extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            chartData: [],
            non_billable_count: [],
            billable_count: [],
            overhead_count: [],
            tender_count: [],
            labels: [],
            maxHours: 0,
        }
    }

    componentDidMount() {
        let data = this.props.data;
        var non_billable_count = []
        var billable_count = []
        var overhead_count = []
        var tender_count = []
        var labels = []
        var maxHours = 0
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element) {
                non_billable_count.push(element.o_count)
                billable_count.push(element.p_count)
                overhead_count.push(element.overhead_count)
                tender_count.push(element.tender_count)
                labels.push(element.lable)
                if (maxHours < (element.o_count + element.p_count)) {
                    maxHours = (element.o_count + element.p_count)
                }
            }
        }

        this.setState({
            maxHours: maxHours,
            non_billable_count: non_billable_count, billable_count: billable_count, labels: labels, overhead_count, tender_count
        })
    }

    showGraph() {
        var options = {
            series: [
                {
                    name: 'Non-Billable',
                    data: this.state.non_billable_count,
                    color: getBrowncolor(),
                },
                {
                    name: 'Billable',
                    data: this.state.billable_count,
                    color: getPrimaryColor()
                }],
            chart: {
                type: 'bar',
                height: 300,
                width: '100%',
                stacked: true,
            },
            theme: {
                mode: getDarkMode() === 'dark' ? 'dark' : 'light',
                palette: 'palette1',
            },
            title: {
                text: 'User Billability (' + (((this.props.billableTotal / (this.props.billableTotal + this.props.nonBillableTotal)) * 100).toFixed(2))
                    + '% Billability in last ' + (screenSizeCheck() ? 3 : 6) + ' months)'
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            xaxis: {
                categories: this.state.labels,
            },
            yaxis: {
                max: this.state.maxHours,
                title: {
                    text: 'Hours',
                },
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'bottom',
                offsetX: 0,
                offsetY: 0
            },
        };
        return <div style={{ width: '100%' }}><ReactApexChart options={options} series={options.series} height={'300px'} width={'100%'} type="bar" /></div>
    }

    showGraph1() {
        var options = {
            series: [
                {
                    name: 'Non Billable',
                    data: this.state.overhead_count,
                    color: getPinkColor(),
                },
                {
                    name: 'Tender',
                    data: this.state.tender_count,
                    color: getDarkGreenColor()
                }],
            chart: {
                type: 'bar',
                height: 300,
                width: '100%',
                stacked: true,
            },
            theme: {
                mode: getDarkMode() === 'dark' ? 'dark' : 'light',
                palette: 'palette1',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            title: {
                text: 'Tender vs Non Billable (' + (((this.props.tenderTotal / this.props.contractedHours) * 100).toFixed(2))
                    + '% Tender hours in last ' + (screenSizeCheck() ? 3 : 6) + ' months)'
            },
            xaxis: {
                categories: this.state.labels,
            },
            yaxis: {
                max: this.state.maxHours,
                title: {
                    text: 'Hours',
                },
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'bottom',
                offsetX: 0,
                offsetY: 0
            },
        };
        return <div style={{ width: '100%' }}><ReactApexChart options={options} series={options.series} height={'300px'} width={'100%'} type="bar" /></div>
    }
    render() {
        return (
            <React.Fragment>
                <div style={{ width: '100%' }}>
                    {/* <Typography style={{ fontWeight: 'bold', textAlign: 'center' }} >
                    You have {(((this.props.projectTotal / (this.props.contractedHours)) * 100).toFixed(2))}% Billability in last {(screenSizeCheck() ? 3 : 6)} months
                </Typography> */}
                    <Box display="flex" p={1} style={{ width: '100%' }}>
                        {this.props.data ?
                            <Box display='flex' flexDirection={'column'} style={{ width: '100%' }}>
                                <Box style={{ width: '100%' }}>{this.showGraph()}</Box>
                                {/* <Box marginTop='10px' style={{ width: '100%' }}>{this.showGraph1()}</Box> */}
                            </Box>
                            :
                            <h2>No demand available</h2>}
                    </Box>
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(UserWorkLoadGraph)