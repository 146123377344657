import React, { Component } from 'react'
import { Box, } from '@material-ui/core'
import { Divider } from '@mui/material'
import { getGreencolor, getSkyBluecolor } from '../../../../ColorTheme/ColorTheme'
import { getPrimaryColor } from '../../../../../Helper/CommonThings'
// import { screenSizeCheck } from '../../../../ScreensizeCheck/ScreensizeCheck'

class TasksInfo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            datas: []
        }
    }

    componentDidMount() {
        this.setState({ datas: this.props.data })
    }

    render() {
        return (
            <div style={{ height: '100%'}}>
                <Box display='flex' flexDirection={'row'} justifyContent={'left'} alignItems={'center'} style={{ height: '100%' }}>

                    {this.props.is_general_user ?
                        null
                        :
                        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} textAlign={'left'} fontWeight={'bold'} fontSize={window.innerWidth <= 1366 ? '18px' : window.innerWidth <= 1650 ? '24px' : '28px'} color={getPrimaryColor()} m={1} style={{ height: '100%' }}>
                            <Box style={{ textShadow: '1px 1px 3px #007681' }}>YOUR</Box>
                            <Box style={{ textShadow: '1px 1px 3px #007681' }}>TEAM </Box>
                            <Box style={{ textShadow: '1px 1px 3px #007681' }}>HAVE</Box>
                            <Box style={{ textShadow: '1px 1px 3px #007681' }}>GOT</Box>
                        </Box>
                    }

                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} textAlign={'center'} style={{ height: '100%' }} m={1}>

                        <Box display={'flex'} flexDirection={'row'} >
                            {this.props.is_general_user ?
                                <Box fontSize={window.innerWidth <= 1366 ? '16px' : window.innerWidth <= 1650 ? '20px' : '26px'} color='#DD7C0D' marginRight={'10px'} >
                                    You have got
                                </Box>
                                : null
                            }

                            <Box fontSize={window.innerWidth <= 1366 ? '16px' : window.innerWidth <= 1650 ? '20px' : '26px'} fontWeight={'bold'} color='#DD7C0D' marginRight={'5px'}>
                                {this.state.datas ? this.state.datas.tasks_today + " " : '0 '} tasks
                            </Box>

                            <Box fontSize={window.innerWidth <= 1366 ? '16px' : window.innerWidth <= 1650 ? '20px' : '26px'} color='#E7A356' marginLeft='5px'>today</Box>
                        </Box>

                        <Divider style={{ color: getPrimaryColor(), marginTop: '5px', marginBottom: '5px' }} />

                        <Box display={'flex'} flexDirection={'row'} >

                            <Box fontSize={window.innerWidth <= 1366 ? '12px' : window.innerWidth <= 1650 ? '15px' : '18px'} color='#A9ABAD' marginRight='10px' fontWeight={'bold'}>Balance Workload</Box>

                            <Box fontSize={window.innerWidth <= 1366 ? '12px' : window.innerWidth <= 1650 ? '15px' : '18px'} color={getSkyBluecolor()} fontWeight={'bold'} >
                                {this.state.datas ? this.state.datas.balence_workload + " " : '0 '}Hours
                            </Box>
                        </Box>

                        <Divider style={{ color: getPrimaryColor(), marginTop: '5px', marginBottom: '5px' }} />

                        <Box display={'flex'} flexDirection={'row'} >
                            {this.props.is_general_user ?
                                <Box fontSize={window.innerWidth <= 1366 ? '12px' : window.innerWidth <= 1650 ? '14px' : '18px'} color='#A9ABAD' marginRight={'10px'} >
                                    Your works are
                                </Box>
                                : null
                            }

                            <Box fontSize={window.innerWidth <= 1366 ? '12px' : window.innerWidth <= 1650 ? '14px' : '18px'} color={getGreencolor()} marginRight='3px' fontWeight={'bold'}>{this.state.datas ? this.state.datas.billability : '0 '}%</Box>

                            <Box fontSize={window.innerWidth <= 1366 ? '12px' : window.innerWidth <= 1650 ? '14px' : '18px'} color='#A9ABAD' marginLeft='5px' >
                                Billable in last week
                            </Box>
                            {/* 
                            <Box fontSize={screenSizeCheck() ? '12px' : '18px'} color='#A9ABAD' fontWeight={'bold'} marginLeft='5px'>
                                3 months
                            </Box> */}
                        </Box>
                    </Box>
                </Box>
            </div >
        )
    }
}

export default TasksInfo