import React, { Component } from 'react'
// import BalfourBeattyHeaderNoArrow from '../CommonTips/BalfourBeattyHeaderNoArrow'
import BalfourBeattyHeaderWithBack from '../CommonTips/BalfourBeattyHeaderWithBack'
import TimeSheetOps from './TimeSheetOps'

export default class TimeSheetOpsMain extends Component {
    render() {
        return (
            <div>
                <BalfourBeattyHeaderWithBack url={"/home/#Your%20Portfolio"} />
                <TimeSheetOps value={this.props.match.params.appid} querySearch={this.props.location.search} />
            </div>
        )
    }
}
