import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import Service from '../Networking/networkutils'
// import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from "moment";
import { errorMessage } from '../Helper/ErrorMessage';
import { getDarkMode } from '../Helper/CommonThings';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Container, Row, Col } from 'react-grid';


const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class LeaveCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            isLoading: true,
            noData: false,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        var startDate = moment().startOf('year').format('DD-MM-YYYY');
        var endDate = moment().endOf('year').format('DD-MM-YYYY');

        Service.get(`/bb/api/lms/leaveinfo/cumulative/?uid=${user.id}&start=${startDate}&end=${endDate}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                if (res.data.length) {
                    this.setState({
                        data: res.data[0],
                        isLoading: false,
                        // noData: true,
                    })

                } else {
                    this.setState({
                        // data: res.data[0],
                        // isLoading: false,
                        noData: true,
                    })
                }

            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }
    render() {
        const darkMode = getDarkMode() === 'dark'
        return (
            <div style={{ width: '100%' }}>
                {(this.state.isLoading) ? ((!this.state.noData) ? <Box marginTop="50px" flexDirection="row" justifyContent="center" display="flex" padding="15px"><CircularProgress /></Box> : <Box marginTop="50px" flexDirection="row" justifyContent="center" display="flex" padding="15px">Data unavailable</Box>) : (
                    <Box marginTop="35px" flexDirection="row" display="flex" padding="10px" >
                        <Container fluid >
                            <Row>
                                <Col>
                                    <Card style={{ background: darkMode ? '#ccb800' : '#FFF176' }}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    CL Eligible
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >

                                                    {this.state.data.this_month_sick_leave}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ background: darkMode ? '#9fad1f' : '#DCE775' }}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center">
                                                <Typography >
                                                    Availed CL
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    {this.state.data.sick_days}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ background: darkMode ? '#e68a00' : '#FFB74D' }}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    CL Balance
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    {(this.state.data.this_month_sick_leave - this.state.data.sick_days).toFixed(2)}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ background: darkMode ? '#006363' : '#ccffff' }}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography>
                                                    EL C/F
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography>
                                                    {((this.state.data.annaul_carry_forward)).toFixed(2)}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ background: darkMode ? '#c71f1f' : '#EF9A9A' }}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography>
                                                    EL Eligible
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography>
                                                    {((this.state.data.this_month_annual_leave + this.state.data.annaul_carry_forward)).toFixed(2)}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ background: darkMode ? '#5c862d' : '#AED581' }}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center">
                                                <Typography >
                                                    Availed EL
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    {this.state.data.leave_days}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>
                               
                                <Col >
                                    <Card style={{ background: darkMode ? '#347f77' : '#4DB6AC' }}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    EL Balance
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    {((this.state.data.this_month_annual_leave + this.state.data.annaul_carry_forward) - this.state.data.leave_days).toFixed(2)}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card style={{ background: darkMode ? '#808080' : '#A9A9A9' }} onClick={() => window.open("https://lms.bbparam.com/user/leave")}>
                                        <CardContent>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <Typography >
                                                    Detailed View
                                                </Typography>
                                            </Box>
                                            <Box flexDirection="row" display="flex" justifyContent="center" >
                                                <OpenInNewIcon />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>

                    </Box >
                )
                }

            </div >
        )
    }
}

export default LeaveCard

