import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import Service from "../Networking/networkutils";
import { errorMessage } from '../Helper/ErrorMessage';


const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

class TaskUsers extends Component {

    constructor(props) {
        super()

        this.state = {
            isLoading: true,
            data: [],
        }
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        Service.get(`/bb/api/tender/user/task/?tid=` + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false })

            })
            .catch(error => {
               errorMessage(this.props, error)
            });
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title={this.props.value.wbs.name + " - " + (this.props.value.decs ? this.props.value.decs : this.props.value.name)}
                    columns={[

                        {
                            title: 'Employee Name', field: 'user_data.last_name',
                            render: rowData => rowData.user_data.last_name + ", " + rowData.user_data.first_name
                         },
                        // {
                        //     title: 'Actual and Inprogress Hours/Total Allocated', field: 'ah', editable: 'never',
                        //     render: rowData => (rowData.id) ? (rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{(rowData.actual_hours + rowData.inprogress_hours) + "/" + rowData.total_alloc_hours}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />) : ("")
                        // },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    style={{ marginTop: '25px' }}
                    options={{
                        padding: "dense",
                        filtering: true,
                        search: false,
                        paging: false,
                        // exportButton: true,
                        doubleHorizontalScroll: true,
                    }}
                    
                />
            </div>
        )
    }
}

// function timeToDecimal(t) {
//     var arr = t.split(':');
//     var dec = parseInt((arr[1] / 6) * 10, 10);

//     return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
// }

export default withSnackbar(TaskUsers)