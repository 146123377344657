import React from 'react'
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import DemandVsCapacityGraph from './DvsCGraph';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

var timeUnit = 'months'

class DvsCTable extends React.Component {

    constructor() {
        super()
        this.state = {
            columns: [{ title: 'Role', field: 'name' }],
            data: [],
            isExpanded: false,
            showall: false,
            dataLength: 0,
            isLoading: true
        }
        this.tableRef = React.createRef()
        this.revenue = React.createRef()
    }

    componentDidMount() {
        let allRolesSwitch = this.props.allRole;
        let data = this.props.data
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element?.tableData) {
                delete element.tableData;
            }
        }

        let rolesData = [];

        for (let i = 0; i < data.length; i++) {
            let role = data[i];
            if (allRolesSwitch === false) {
                if (role.hours_req_per_day !== 0) {
                    rolesData.push(role)
                }
            }
            if (allRolesSwitch === true) {
                rolesData.push(role)
            }
        }

        timeUnit = this.props.timeUnit

        this.setState({ data: rolesData, dataLength: rolesData.length, isLoading: true }, () => this.setState({ isLoading: false }))
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.checked })
    }

    showRevenue() {
        if (this.revenue && this.revenue.current) {
            this.setState({ isLoading: true }, () => {
                this.setState({ isLoading: false }, () => {
                    this.revenue.current.showRevenue()
                })
            })
        }
    }

    render() {

        const handleShowDetailPanel = event => {
            if (!this.tableRef.current) return;

            const { detailPanel } = this.tableRef.current.props;
            let handleShowDetailPanel = detailPanel;
            const _data = [...this.state.data]; // Make copy of data
            // If props.detailPanel is an array not a func
            for (let i = 0; i < _data.length; i++) {
                if (typeof detailPanel === 'object') {
                    // ~ I AM USING INDEX 0 HERE - CHANGE TO WHATEVER YOU NEED ~
                    handleShowDetailPanel = detailPanel[i].render
                }



                // // If `showDetailPanel` already exists, remove it to close the detailPanel..
                // if (_data[i].tableData.showDetailPanel) {
                //     _data[i].tableData.showDetailPanel = '';
                // } else {
                //     // If `showDetailPanel` does not exist, add it to open detailPanel..
                //     _data[i].tableData = {
                //         ..._data[i].tableData,
                //         showDetailPanel: handleShowDetailPanel
                //     }
                // }

                if (event.target.checked === true) {
                    _data[i].tableData = {
                        ..._data[i].tableData,
                        showDetailPanel: handleShowDetailPanel
                    }
                }
                else {
                    if (_data[i].tableData.showDetailPanel) {
                        _data[i].tableData.showDetailPanel = '';
                    }
                }
            }

            this.setState({ [event.target.name]: event.target.checked, data: _data })
        }

        return (
            <div>

                {this.state.isLoading === false ?
                    <MaterialTable
                        tableRef={this.tableRef}
                        columns={this.state.columns}
                        data={this.state.data}
                        title={""}
                        options={{
                            pageSize: this.props.groupByRolesSwitch ? this.state.dataLength : 20,
                            pageSizeOptions: this.props.groupByRolesSwitch ? [this.state.dataLength] : [20, 50, 100],
                            // toolbar: false,
                            padding: 'dense',
                            paging: false,
                        }}
                        detailPanel={rowData => (
                            <DemandVsCapacityGraph
                                ref={this.revenue}
                                data={rowData}
                                timeUnit={timeUnit}
                                showRevenue={this.props.showRevenue}
                            />
                        )}
                        onRowClick={(event, rowData, togglePanel) => {
                            togglePanel()
                        }}
                        actions={[
                            {
                                icon: () =>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.showall}
                                                onChange={handleShowDetailPanel}
                                                color="primary"
                                                name="showall"
                                            />
                                        }
                                        label="Expand All"
                                    />,
                                isFreeAction: true,
                            },
                        ]}
                    />
                    : null}
            </div>
        )
    }
}
export default withSnackbar(DvsCTable)