import React, { Component } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import SettingsLists from './SettingsLists';
import NotificationsLists from './NotificationsLists';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

class SettingsTab extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: 0
        }
    }
    render() {
        const handleChange = (event, newValue) => {
            this.setState({ value: newValue })
        };

        return (
            <div style={{ width: '100%' }}>
                <Tabs
                    value={this.state.value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Notifications" {...a11yProps(0)} />
                    <Tab label="Settings" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={this.state.value} index={0} >
                    <NotificationsLists />
                </TabPanel>
                <TabPanel value={this.state.value} index={1} >
                    <SettingsLists />
                </TabPanel>
            </div>
        );
    }
}

export default SettingsTab;