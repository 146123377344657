import React, { Component } from 'react'
import MaterialTable from 'material-table';
import Service from '../../Networking/networkutils'
import { withSnackbar } from 'notistack';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import RolesGroupDetailPanel from './RolesGroupDetailPanel';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

var token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class RoleGroup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        Service.get(`/bb/api/role/group/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoading: false
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    validityCheck(data) {
        var valid = true
        if (!data.name) {
            this.props.enqueueSnackbar('Name field is empty!', {
                variant: 'warning'
            });
            valid = false
        }
        return valid
    }

    postData(data) {
        if (this.validityCheck(data)) {
            const datas = {
                name: data.name,
                created_by: user.id,
            }
            Service.post(`/bb/api/role/group/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: datas
            })
                .then(res => {
                    this.fetchData();
                    this.props.enqueueSnackbar('Role Group Added Successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    putData(newData) {
        if (this.validityCheck(newData)) {
            this.setState({ isLoading: true })

            Service.put(`/bb/api/role/group/` + newData.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: newData
            })
                .then(res => {
                    this.setState({ isLoading: false });
                    this.props.enqueueSnackbar('Data updated successfully!', {
                        variant: 'success'
                    });

                })
                .catch(error => {
                    errorMessage(this.props, error)
                    this.fetchData()
                });
        }
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title="Roles Group"
                    columns={[
                        {
                            title: 'Name', field: 'name',
                        },
                    ]}
                    icons={{
                        Add: props => <Button color='primary' variant="contained" startIcon={<AddIcon />}>
                            Add Roles Group
                        </Button>
                    }}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        padding: "dense",
                        pageSize: 20,
                        pageSizeOptions: [30, 50, 100],
                        actionsColumnIndex: -1,
                        search: true,
                        addRowPosition: "first",
                    }}
                    detailPanel={rowData => {
                        return (
                            <RolesGroupDetailPanel data={rowData} />
                        )
                    }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                if (this.validityCheck(newData)) {
                                    setTimeout(() => {
                                        resolve();
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            newData.created_by = user.email;
                                            this.postData(newData);
                                            data.push(newData);
                                            return { ...prevState, data };
                                        });
                                    }, 600);
                                } else {
                                    resolve();
                                }
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                if (this.validityCheck(newData)) {
                                    setTimeout(() => {
                                        resolve();
                                        if (oldData) {
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                this.setState({ save: false });
                                                return { ...prevState, data };
                                            });
                                            this.putData(newData)
                                        }
                                    }, 60);
                                } else {
                                    resolve();
                                }
                            }),
                    }}
                />
            </div>
        )
    }
}

export default withSnackbar(RoleGroup)