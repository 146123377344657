import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import { getPrimaryColor } from '../Helper/CommonThings';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = getCookie("bb_ts_token");
var dialogmsg = "";
var submitdata = null;
class StaffManage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            open: false,
            isLoading: true,
        }
    }


    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        Service.get(`/bb/api/users/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                var jsondata = [];
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    jsondata.push({
                        id: element.id,
                        name: element.last_name + ", " + element.first_name,
                        email: element.email,
                        is_estimator: element.is_estimator,
                    });
                    
                }
                this.setState({
                    data: jsondata,
                    isLoading: false,
                });
            })
            .catch(error => {
               errorMessage(this.props, error)
            });
    }

    giveaccess() {

        var json = {
            email: submitdata.email,
            is_estimator: !submitdata.is_estimator,
        }

        Service.put("/bb/api/users/" + submitdata.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: json,
        })
            .then(res => {

                this.setState({ open: false, });
                // console.log(res.data)
                this.props.enqueueSnackbar('Permission changed for ' + submitdata.email, {
                    variant: 'success'
                });
                this.fetchOps();
            })
            .catch(error => {
                // console.log(error)
               errorMessage(this.props, error)
            });
    }

    handleDialog(type, data) {
        submitdata = data;
        if (type) {
            dialogmsg = "Do you want to make " + data.email + " (" + data.first_name + " " + data.last_name + ") as Estimator";
        } else {
            dialogmsg = "Do you want to remove Estimator access for " + data.email + " (" + data.first_name + " " + data.last_name + ")";
        }
        this.setState({ open: true, });
    }

    render() {

        const handleClose = () => {
            this.setState({ open: false, });
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                        <div>
                            <MaterialTable
                                title="Manage Estimators"
                                columns={[
                                    { title: 'Name', field: 'name' },
                                    { title: 'Email', field: 'email' },
                                ]}
                                data={this.state.data}
                                actions={[
                                    rowData => ({
                                        icon: 'check_circle',
                                        tooltip: 'Make Estimator',
                                        onClick: (event, rowData) => this.handleDialog(true, rowData),
                                        disabled: rowData.is_estimator
                                    }),
                                    rowData => ({
                                        icon: 'cancel',
                                        tooltip: 'Remove Estimator',
                                        onClick: (event, rowData) => this.handleDialog(false, rowData),
                                        disabled: !rowData.is_estimator
                                    })
                                ]}
                                options={{
                                    padding: "dense",
                                    actionsColumnIndex: -1
                                }}
                            />
                            <Dialog
                                open={this.state.open}
                                TransitionComponent={Transition}
                                keepMounted
                                fullWidth
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle id="alert-dialog-slide-title">{"Are you sure?"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        {dialogmsg}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        No
                    </Button>
                                    <Button onClick={e => this.giveaccess()} color="primary">
                                        Yes
                        </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    )}
            </div>

        )
    }
}

export default withSnackbar(StaffManage);