import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import Service from '../Networking/networkutils';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
// import { LoopCircleLoading } from 'react-loadingg';
// import { getPrimaryColor } from '../Helper/CommonThings';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
// import { CircularProgress } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { Alert, CircularProgress } from '@mui/material';
import { Button } from '@material-ui/core';
import NotificationPermission from '../Helper/NotificationPermission';


var token = getCookie("bb_ts_token");

class NotificationsLists extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            data: []
        }

        this.notificationPermission = React.createRef()
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        Service.get(`/bb/api/notification/control/notification_category_access/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoading: false
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    changeStatus(index, key) {
        // let status = ""
        let statusData = this.state.data

        if (statusData[index].enable_toggle[key]) {
            statusData[index].enable_toggle[key] = false
            // status = "Disabled"
        } else {
            statusData[index].enable_toggle[key] = true
            // status = "Enabled"
        }

        Service.put(`/bb/api/notification/control/notification_category/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                notification_category: statusData[index].id,
                [key]: statusData[index].enable_toggle[key]
            }
        })
            .then(res => {
                this.setState({ data: statusData })
                // this.props.enqueueSnackbar(statusData[index].name + " option " + status + "!", {
                //     variant: 'success'
                // });
            })
            .catch(error => {
                this.fetchData()
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    checkNotify() {
        if (this.notificationPermission && this.notificationPermission.current) {
            this.notificationPermission.current.checkNotify()
        }
    }

    render() {
        return (
            <div>
                <NotificationPermission ref={this.notificationPermission} />
                {localStorage.getItem('push-denied') === "true" ? <Alert severity="warning"
                    action={
                        <Button color="inherit" size="small" variant='outlined' onClick={() => {
                            this.checkNotify()
                        }}>Enable</Button>
                    }
                >Push Notification is disabled. Please enable it to receive Push Notifications.</Alert> : null}
                {(this.state.isLoading) ? (
                    <Box display='flex' flexDirection={'row'} width={'100%'} justifyContent={'center'}>
                        <CircularProgress color='primary' />
                    </Box>

                ) : (
                    <Box display='flex' flexDirection={'row'} width={'100%'} justifyContent={'left'}>
                        <TableContainer sx={{ maxWidth: 650 }} component={Paper}>
                            <Table sx={{ maxWidth: 650 }} size='small' aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Push Notifications</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.data.map((row, i) => (
                                        <TableRow
                                            key={i}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" >
                                                {row.name}
                                            </TableCell>
                                            <TableCell component="th" scope="row" >
                                                <Checkbox
                                                    checked={((row.email_toggle === 0) ? false : (row.email_toggle === 2) ? true : row.enable_toggle.email_notify)}
                                                    onChange={() => this.changeStatus(i, 'email_notify')}
                                                    color="primary"
                                                    size='small'
                                                    disabled={(row.email_toggle === 0 || row.email_toggle === 2) ? true : false}
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row" >
                                                <Checkbox
                                                    checked={((row.notification_toggle === 0) ? false : (row.notification_toggle === 2) ? true : row.enable_toggle.push_notify)}
                                                    onChange={() => this.changeStatus(i, 'push_notify')}
                                                    color="primary"
                                                    size='small'
                                                    disabled={(row.notification_toggle === 0 || row.notification_toggle === 2) ? true : false}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box >
                )}
            </div>
        );
    }
}

export default withSnackbar(NotificationsLists);