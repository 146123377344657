import React, { Component } from 'react'
import Service from '../../Networking/networkutils'
// import moment from "moment";
import { getCookie } from '../../Helper/CookieHelper'
import { errorMessage } from '../../Helper/ErrorMessage';
import { CircleToBlockLoading } from 'react-loadingg';
import { withSnackbar } from 'notistack';
// import { CsvBuilder } from 'filefy';
import { Box, Card, CardContent, Grid, Link, Typography } from '@material-ui/core';

const token = getCookie("bb_ts_token");

class RequestReceivedTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tabledata: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    screenSizeCheck() {
        if (window.innerWidth > 1600) {
            return true
        } else {
            return false
        }
    }

    fetchData() {
        const user = JSON.parse(getCookie("bb_ts_user"));
        const lead_id = user.id;
        Service.get(`/bb/api/lms/approval/pending_request_receive/?requested_to=${lead_id}`, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.setState({
                    tabledata: res.data,
                    isLoading: false,
                }, () => {

                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    // filterDate(date) {
    //     var Date = moment(date).format('DD-MM-YYYY');
    //     if (Date) {
    //         return Date;
    //     }
    // }

    // filterComments(comments) {
    //     if (comments === null) {
    //         return '-';
    //     } else {
    //         return comments;
    //     }
    // }

    // filterDay(end, start) {
    //     var Day = ((moment.duration(moment(end).diff(moment(start))).asMinutes() / 60).toFixed(2) / 8);
    //     if (Day) {
    //         return Day.toString();
    //     }
    // }

    // filterRepliedby(replied_by) {
    //     if (!replied_by) {
    //         return '-';
    //     } else {
    //         return replied_by;
    //     }
    // }

    // filterRepliedon(replied_on) {
    //     var Replied_on = moment(replied_on).format('DD-MM-YYYY');
    //     if (!Replied_on) {
    //         return '-';
    //     } else {
    //         return Replied_on;
    //     }
    // }

    render() {

        // const exportCsv = (columnList, initialData) => {
        //     const columns = columnList.filter(columnDef => {
        //         return !columnDef.hidden && columnDef.field && columnDef.export !== false;
        //     });

        //     const data = initialData.map(rowData =>
        //         columns.map(columnDef => {
        //             // return columnDef.render ? columnDef.render(rowData) : columnDef.lookup[rowData[columnDef.field]];
        //             return rowData[columnDef.field];
        //         })
        //     );

        //     const builder = new CsvBuilder("Request Received report " + this.props.year + '.csv');
        //     builder
        //         .setDelimeter(',')
        //         .setColumns(columns.map(columnDef => columnDef.title))
        //         .addRows(data)
        //         .exportFile();
        // };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <CircleToBlockLoading color='#007681' />
                ) : (
                    <div>
                   {(this.state.tabledata.length === 0) ? 
                   <Box style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'20px'}}>No Leaves to Show</Box>
                    :
                    <div>
                        <Grid container spacing={3}>
                            {(this.state.tabledata).map((value, i) =>
                                <Grid item xs={this.screenSizeCheck() ? 3 : 4}>
                                    {/* <Paper elevation={2} > */}
                                        <Card elevation={2} style={{borderRadius:'10px'}}>
                                            <CardContent>
                                                <Typography gutterBottom >
                                                    <span style={{fontWeight:'bold'}}>Name:</span> {value.user_name}
                                                </Typography>
                                                {/* <Typography gutterBottom >
                                                    Team: {value.gname}
                                                </Typography> */}
                                                <Typography gutterBottom >
                                                    {/* Hours : {(value.approval === 0) ? ((moment.duration(moment(value.team_task_data.end).diff(moment(value.team_task_data.start))).asMinutes() / 60).toFixed(2) ) : ((moment.duration(moment(value.snapshot.end).diff(moment(value.snapshot.start))).asMinutes() / 60).toFixed(2) )} */}
                                                    <span style={{fontWeight:'bold'}}>Requested To:</span> {value.requested_to}
                                                </Typography>
                                                {/* <Typography gutterBottom >
                                                        Date : {(value.approval === 0) ? (moment(value.team_task_data.start).format('DD-MM-YYYY')) : (moment(value.snapshot.start).format('DD-MM-YYYY'))}
                                                    </Typography> */}
                                                <Typography gutterBottom >
                                                <span style={{fontWeight:'bold'}}>Leave Type:</span> {value.leave_type}
                                                </Typography>
                                                <Typography gutterBottom >
                                                <span style={{fontWeight:'bold'}}>Duration in Hours:</span> {value.availed_leave_hours}
                                                </Typography>
                                                <Typography gutterBottom >
                                                <span style={{fontWeight:'bold'}}>Duration in Days:</span> {value.availed_leave_days}
                                                </Typography>
                                                <Typography gutterBottom >
                                                <span style={{fontWeight:'bold'}}>Total Leaves:</span> {value.no_of_items}
                                                </Typography>
                                                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                                    {/* <Link style={{ cursor: 'pointer'}} href='#' onClick={() => window.open("http://paul-dev.centralindia.cloudapp.azure.com:3001/request/" + value.user_id + "/" + value.requested_to_id)}>Click to view in LMS</Link> */}
                                                    <Link href='#' onClick={() => window.open("https://lms.bbparam.com/request/" + value.user_id + "/" + value.requested_to_id)}>Click to view in LMS</Link>
                                                </Box>
                                                {/* {this.datasCheck(value)} */}

                                            </CardContent>

                                            {/* {(value.isLoading === true) ? (<Box display="flex" justifyContent="center" ><CircularProgress /></Box>) :
                                                    (<Box display="flex" justifyContent="center" marginBottom="10px" bgcolor={!value.isSelected ? "white" : "#ccebff"}>
                                                        {this.selectButton(value, i)}
                                                    </Box>)} */}

                                        </Card>
                                    {/* </Paper> */}
                                </Grid>
                            )}
                        </Grid>
                    </div>
                    }
                    </div>
                )
                }
            </div>
        )
    }
}

export default withSnackbar(RequestReceivedTable);