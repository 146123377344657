import React, { Component } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import Service from "../../Networking/networkutils";
import { getCookie } from '../../Helper/CookieHelper';
// import MaterialTable from 'material-table';
import moment from "moment";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from '@material-ui/core';
import { withSnackbar } from 'notistack';
// import { CsvBuilder } from 'filefy';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
// import Chart from "react-google-charts";
import { errorMessage } from '../../Helper/ErrorMessage';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InfoIcon from '@material-ui/icons/Info';
import TimesheetSummaryDetailsTab from './TimesheetSummaryDetailsTab';
import { Alert, AlertTitle } from '@material-ui/lab';
import ReactApexChart from 'react-apexcharts';
import { getDarkMode, getPrimaryColor, getSecondaryColor, stringToDate } from '../../Helper/CommonThings';
import { getBrowncolor, getredcolor } from '../../MainDashBoard/ColorTheme/ColorTheme';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

var percentIndex = [9, 10, 11, 12]
var boldIndex = [1, 4, 6, 7, 8, 9, 10, 11, 12]
var detailIndex = 13

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

class TimesheetSummary extends Component {

    constructor(props) {
        super()
        this.state = {
            data: [],
            isLoading: true,
            startDate: moment(new Date()).subtract(13, "weeks"),
            endDate: new Date(),
            columns: [],
            rows: [],
            row_names: ["Total hours", "Total timesheet submitted hours* (A) ", "Leaves", "Bank Holiday", "Available hours (B)", "Tender Hours", "Billable Project Hours", "Non-Billable Projects Hours", "Total Non-Billable Hours", "Productivity % on A", "Billability % on A", "Productivity % on B", "Billability % on B", "Details"],
            row_nos: ['i1', 'i2', 'i3', 'i4', 'i5', 'i6', 'i7', 'i8', 'i9', 'i10', 'i11', 'i12', 'i13'],
            row_keys: ["total_hours", "total_timesheet_submitted", "total_leave", "bank_holiday", "available_hours", "tenders", "chargeable", "non_billable", "total_non_billable", "productivity_a", "billability_a", "productivity_b", "billability_b", "Details"],
            chartData: [],
            weeksData: [],
            selectedWeeks: [],
            openDetails: false,
            detailsValue: null,
            leavesAndbankHolidays: [],
            trainingAndNonBillable: [],
            tendersAndNonBillableProjects: [],
            billable: [],
            total_hours: [],
            labels: [],
            selectedMembers: [],
            selectedMemberIds: null,
            showHidden: false,
            company: props.company ? props.company : 0,
        }
    }

    componentDidMount() {
        this.fetchOps()
        this.weekList()
    }

    weekList() {
        var weeks_list = []
        var year_start = moment().subtract(1, 'years')
        var start_date = year_start

        while (start_date <= moment().add(1, 'week')) {
            weeks_list.push({
                name: moment(start_date).format('YYYY') + " Week " + (start_date.endOf('week').isoWeek()) + " (" + (start_date.startOf('week').format('DD-MM-YYYY') + " - "
                    + start_date.endOf('week').format('DD-MM-YYYY')) + ")",
                start: start_date.startOf('week').format('DD-MM-YYYY'),
                end: start_date.endOf('week').format('DD-MM-YYYY'),
            })
            start_date = start_date.endOf('week').add(1, 'day')
        }
        this.setState({
            weeksData: weeks_list.reverse()
        }, () => {
            if (this.props.dateValue && this.props.dateValue.length !== 0) {
                var selectedWeeks = []
                for (let i = 0; i < this.state.weeksData.length; i++) {
                    const element = this.state.weeksData[i];
                    if (element.start === this.props.dateValue[0] && element.end === this.props.dateValue[1]) {
                        selectedWeeks.push(element)
                    }
                }
                this.setState({
                    selectedWeeks: selectedWeeks
                })
            }
        })
    }

    fetchOps() {
        this.setState({ isLoading: true })
        var date1 = moment(new Date()).subtract(4, "weeks").format('DD-MM-YYYY')
        var date2 = moment(new Date()).format('DD-MM-YYYY')
        if (this.props.dateValue && this.props.dateValue.length !== 0) {
            date1 = this.props.dateValue[0]
            date2 = this.props.dateValue[1]
            this.setState({ startDate: stringToDate(date1, "dd-mm-yyyy", "-"), endDate: stringToDate(date2, "dd-mm-yyyy", "-") })
        }
        var url = "/bb/api/report/view/timesheet_week_report/?start=" + date1 + "&end=" + date2
        if (this.props.uid) {
            url = "/bb/api/report/view/timesheet_week_report/?start=" + date1 + "&end=" + date2 + "&uid=" + this.props.uid
        } else if (this.props.gid) {
            url = "/bb/api/report/view/timesheet_week_report/?start=" + date1 + "&end=" + date2 + "&gid=" + this.props.gid
        }

        if (this.state.company && this.state.company !== 0) {
            url += "&company=" + this.state.company
        }
        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.rowCompile(res.data)

            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        if (this.props.uid) {
            Service.get2(`/bb/api/user/report/view/team_members/?uid=` + this.props.uid, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({
                        teamMembers: res.data
                    })
                })
                .catch(e => {
                    errorMessage(this.props, e)
                    console.log(e)
                })
        } else if (this.props.gid) {
            Service.get2(`/bb/api/user/report/view/team_users/?gid=` + this.props.gid, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({
                        teamMembers: res.data
                    })
                })
                .catch(e => {
                    errorMessage(this.props, e)
                    console.log(e)
                })
        }
    }

    fetchOps1() {
        this.setState({
            isLoading: true
        })

        var memids = null
        for (let i = 0; i < this.state.selectedMembers.length; i++) {
            const element = this.state.selectedMembers[i];
            if (memids) {
                memids += "," + element.id
            } else {
                memids = element.id
            }
        }

        var date1 = moment(this.state.startDate).format('DD-MM-YYYY')
        var date2 = moment(this.state.endDate).format('DD-MM-YYYY')
        var url = "/bb/api/report/view/timesheet_week_report/?start=" + date1 + "&end=" + date2
        if (this.props.uid) {
            url = "/bb/api/report/view/timesheet_week_report/?start=" + date1 + "&end=" + date2 + "&uid=" + this.props.uid
        } else if (this.props.gid) {
            url = "/bb/api/report/view/timesheet_week_report/?start=" + date1 + "&end=" + date2 + "&gid=" + this.props.gid
        }
        if (memids) {
            url += "&memid=" + memids
        }
        if (this.state.company && this.state.company !== 0) {
            url += "&company=" + this.state.company
        }
        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.rowCompile(res.data)

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchOps2() {
        if (!this.state.selectedWeeks) {
            this.props.enqueueSnackbar("Please select a week!", {
                variant: 'warning'
            });
        } else {
            this.setState({
                isLoading: true
            })

            var memids = null
            for (let i = 0; i < this.state.selectedMembers.length; i++) {
                const element = this.state.selectedMembers[i];
                if (memids) {
                    memids += "," + element.id
                } else {
                    memids = element.id
                }
            }

            var url = "/bb/api/report/view/timesheet_week_report2/"
            if (this.props.uid) {
                url = "/bb/api/report/view/timesheet_week_report2/?uid=" + this.props.uid
            } else if (this.props.gid) {
                url = "/bb/api/report/view/timesheet_week_report2/?gid=" + this.props.gid
            }

            if (memids) {
                url += "&memid=" + memids
            }

            if (this.state.company && this.state.company !== 0) {
                if (url.includes("?")) {
                    url += "&company=" + this.state.company
                }
                else {
                    url += "?company=" + this.state.company
                }
            }

            Service.patch(url, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: this.state.selectedWeeks,
            })
                .then(res => {
                    let sortedData = res.data.sort((a, b) => {
                        return a.week_no - b.week_no
                    })
                    this.rowCompile(sortedData)
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    rowCompile(data) {
        var row = []
        if (this.state.showHidden) {
            percentIndex = [9, 10, 11, 12]
            boldIndex = [1, 4, 6, 7, 8, 9, 10, 11, 12]
            detailIndex = 13
            let row_names = this.state.row_names
            let row_keys = this.state.row_keys
            let row_nos = ['i1', 'i2', 'i3', 'i4', 'i5', 'i6', 'i7', 'i8', 'i9', 'i10', 'i11', 'i12', 'i13']
            let rows = this.state.rows
            rows.splice(9, 2)
            row_names.splice(9, 2)
            row_keys.splice(9, 2)
            this.setState({ row_names: row_names, row_keys: row_keys, showHidden: false, rows: rows, row_nos: row_nos })
        }
        for (let i = 0; i < this.state.row_keys.length; i++) {
            var temp = {
                row_name: this.state.row_names[i],
                row_nos: this.state.row_nos[i],
                data: data
            }
            row.push(temp)

        }
        this.chartCompile(data)
        if (this.state.weeksData.length !== 0) {
            let selectedWeeks = []
            for (let i = 0; i < this.state.weeksData.length; i++) {
                const element = this.state.weeksData[i];
                for (let j = 0; j < data.length; j++) {
                    const element1 = data[j];
                    if (element1.start_date === element.start && element1.end_date === element.end) {
                        selectedWeeks.push(element)
                    }
                }
            }
            this.setState({
                selectedWeeks: selectedWeeks
            })
        }
        this.setState({ data: data, rows: row, isLoading: false })
    }

    chartCompile(data) {
        // console.log(data)
        // var chartData = [["Week", "Chargeable", "Total hours"]]
        // for (let i = 0; i < data.length; i++) {
        //     const element = data[i];
        //     chartData.push([element.name, element.chargeable, element.total_hours])
        // }
        // console.log(data)
        // this.setState({
        //     chartData: chartData
        // })
        var chart_data = data
        // var chargeable_hours = []
        // var total_hours = []
        var labels = []
        var leavesAndbankHolidays = []
        var trainingAndNonBillable = []
        var tendersAndNonBillableProjects = []
        var billable = []
        for (let i = 0; i < chart_data.length; i++) {
            const element = chart_data[i]
            if (element) {
                leavesAndbankHolidays.push((element.total_leave + element.bank_holiday).toFixed(1))
                trainingAndNonBillable.push((element.training + element.overheads).toFixed(1))
                tendersAndNonBillableProjects.push((element.tenders + element.non_billable).toFixed(1))
                billable.push(element.chargeable.toFixed(1))
                labels.push(element.name)
            }
        }
        // console.log(leavesAndbankHolidays, trainingAndNonBillable, tendersAndNonBillableProjects, billable,)
        this.setState({
            leavesAndbankHolidays: leavesAndbankHolidays,
            trainingAndNonBillable: trainingAndNonBillable,
            tendersAndNonBillableProjects: tendersAndNonBillableProjects,
            billable: billable,
            labels: labels,
        })
    }

    showGraph() {
        const options = {
            series: [
                {
                    name: 'Leaves & Bank Holidays',
                    data: this.state.leavesAndbankHolidays,
                    color: getredcolor()
                }, {
                    name: 'Training & Non-Billable',
                    data: this.state.trainingAndNonBillable,
                    color: getSecondaryColor()
                },
                {
                    name: 'Tenders & Non-Billable Projects',
                    data: this.state.tendersAndNonBillableProjects,
                    color: getBrowncolor()
                },
                {
                    name: 'Billable',
                    data: this.state.billable,
                    color: getPrimaryColor()
                }
            ],

            chart: {
                height: 400,
                type: 'bar',
                stacked: true,
                stackType: '100%'
            },
            theme: {
                mode: getDarkMode() === 'dark' ? 'dark' : 'light',
            },
            tooltip: {
                theme: getDarkMode() === 'dark' ? 'dark' : 'light',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            xaxis: {
                categories: this.state.labels,
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'bottom',
                offsetX: 0,
                offsetY: 0
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [0, 1, 2, 3]
            },
            labels: this.state.labels,
            // yaxis: [{
            //     title: {
            //         text: 'Hours',
            //     },

            // }
            // ]

        }
        // console.log(JSON.stringify(this.state.labels))
        return <div style={{ width: '100%' }}><ReactApexChart options={options} series={options.series} height={450} width={'100%'} type='bar' /></div>
    }

    getTotalSubmitted(data) {
        return data.leave + data.sick + data.bank_holiday + data.training + data.chargeable + data.tenders + data.overheads + data.non_billable
    }

    handleDateChange = (date) => {
        this.setState({
            startDate: date
        })
    }

    handleDateChange2 = (date) => {
        this.setState({
            endDate: date
        })
    }

    onSelectedWeeks = (event, values) => {
        this.setState({ selectedWeeks: values });
    }

    onSelectedMembers = (event, values) => {
        this.setState({ selectedMembers: values });
    }

    getMemIds() {
        var memids = null
        for (let i = 0; i < this.state.selectedMembers.length; i++) {
            const element = this.state.selectedMembers[i];
            if (memids) {
                memids += "," + element.id
            } else {
                memids = element.id
            }
        }
        return memids
    }

    showHidden() {
        if (this.state.showHidden) {
            percentIndex = [9, 10, 11, 12]
            boldIndex = [1, 4, 6, 7, 8, 9, 10, 11, 12]
            detailIndex = 13
            let row_names = this.state.row_names
            let row_keys = this.state.row_keys
            let rows = this.state.rows
            rows.splice(9, 2)
            row_names.splice(9, 2)
            row_keys.splice(9, 2)
            this.setState({ row_names: row_names, row_keys: row_keys, showHidden: false, rows: rows })
        } else {
            percentIndex = [11, 12, 13, 14]
            boldIndex = [1, 4, 6, 7, 8, 11, 12, 13, 14]
            detailIndex = 15
            let row_names = this.state.row_names
            let row_keys = this.state.row_keys
            let rows = this.state.rows
            rows.splice(9, 0, { row_name: "Training", data: this.state.data }, { row_name: "Non-Billable", data: this.state.data })
            row_names.splice(9, 0, "Training", "Non-Billable",)
            row_keys.splice(9, 0, "training", "overheads")
            this.setState({ row_names: row_names, row_keys: row_keys, showHidden: true, rows: rows })
        }
    }

    lastWeekComparison(item1, index) {
        let curr_week = item1[this.state.row_keys[index]]
        let last_week = item1["last_week_" + this.state.row_keys[index]]
        if (curr_week !== null && last_week !== null) {
            if (curr_week > last_week) {
                return <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} width={'100%'} >
                    <b>{item1[this.state.row_keys[index]]}% </b>
                    <Box display="flex" flexDirection="column" alignItems='center' height={'100%'} justifyContent={'center'} marginLeft={'10px'}>
                        <div class="up-arrow"></div>
                        <p style={{ fontSize: "10px", color: 'green', marginBottom: 0, marginTop: 0 }}>{(curr_week - last_week)}%</p>
                    </Box>
                </Box >
            } else if (curr_week < last_week) {
                return <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} width={'100%'} >
                    <b>{item1[this.state.row_keys[index]]}% </b>
                    <Box display="flex" flexDirection="column" alignItems='center' height={'100%'} justifyContent={'center'} marginLeft={'10px'}>
                        <p style={{ fontSize: "10px", color: 'red', marginBottom: 0, marginTop: 0 }}>{(last_week - curr_week)}%</p>
                        <div class="down-arrow"></div>
                    </Box>
                </Box>
            } else {
                return <b>{item1[this.state.row_keys[index]]}%</b>
            }
        } else {
            return null
        }
    }

    filterCompany(company) {
        let startDate = moment(new Date()).subtract(13, "weeks").format('DD-MM-YYYY')
        let endDate = moment(new Date()).format('DD-MM-YYYY')
        let startDate1 = moment(this.state.startDate).format('DD-MM-YYYY')
        let endDate1 = moment(this.state.endDate).format('DD-MM-YYYY')
        if (startDate === startDate1 && endDate === endDate1 && this.state.selectedMembers.length === 0 && this.state.selectedWeeks.length === 0) {
            this.setState({ company: company }, () => {
                this.fetchOps()
            })
        } else if (this.state.selectedWeeks.length === 0) {
            this.setState({ company: company }, () => {
                this.fetchOps1()
            })
        } else {
            this.setState({ company: company }, () => {
                this.fetchOps2()
            })
        }
    }

    getFormula(data) {
        if (data.row_nos === 'i5') {
            return '(i5=i2-i3-i4)'
        } else if (data.row_nos === 'i10') {
            return '(i10=(i6+i7+i8)/i2)'
        } else if (data.row_nos === 'i11') {
            return '(i11=i7/i2)'
        } else if (data.row_nos === 'i12') {
            return '(i12=(i6+i7+i8)/i5)'
        } else if (data.row_nos === 'i13') {
            return '(i13=i7/i5)'
        } else {
            return ''
        }
    }

    render() {

        const handleClose = () => {
            this.setState({ openDetails: false, })
        };

        // const exportCsv = (columnList, initialData) => {
        //     const columns = columnList.filter(columnDef => {
        //         return !columnDef.hidden && columnDef.field && columnDef.export !== false;
        //     });

        //     const data = initialData.map(rowData =>
        //         columns.map(columnDef => {
        //             return columnDef.render ? columnDef.render(rowData) : rowData[columnDef.field];
        //         })
        //     );

        //     const builder = new CsvBuilder("User Timesheet Report " + moment().format('MM/DD/YYYY hh:mm') + '.csv');
        //     builder
        //         .setDelimeter(',')
        //         .setColumns(columns.map(columnDef => columnDef.title))
        //         .addRows(data)
        //         .exportFile();
        // };

        return (
            <div style={{ minHeight: '50%' }}>
                {(this.state.isLoading) ? (<LoopCircleLoading color={getPrimaryColor()} />) : (
                    <div style={{ margin: '10px' }}>
                        <Grid container spacing={3}>
                            {(this.props.uid || this.props.gid) ?
                                <Grid item xs={4}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        m={1}>
                                        <Autocomplete
                                            multiple
                                            id="checkboxes-tags-demo"
                                            options={this.state.teamMembers}
                                            value={this.state.selectedMembers}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                                            onChange={this.onSelectedMembers}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.last_name + ',' + option.first_name}
                                                </React.Fragment>
                                            )}
                                            fullWidth
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Select Team Members (optional)" placeholder="Team Members" />
                                            )}
                                        />
                                    </Box>
                                </Grid>
                                : null}
                            <Grid item xs={(this.props.uid || this.props.gid) ? 4 : 6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        m={1}
                                    >

                                        <Box style={{ marginRight: '10px' }}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="Start Date"
                                                format="dd/MM/yyyy"
                                                value={this.state.startDate}
                                                onChange={this.handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </Box>

                                        <Box style={{ marginRight: '10px' }}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog1"
                                                label="End Date"
                                                format="dd/MM/yyyy"
                                                value={this.state.endDate}
                                                onChange={this.handleDateChange2}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </Box>

                                        <Box>
                                            <Button variant="contained" color="primary" onClick={() => this.fetchOps1()}>Fetch Data</Button>
                                        </Box>

                                    </Box>


                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={(this.props.uid || this.props.gid) ? 4 : 6}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    m={1}
                                >

                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={this.state.weeksData}
                                        value={this.state.selectedWeeks}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        onChange={this.onSelectedWeeks}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Select Weeks" placeholder="Favorites" />
                                        )}
                                    />
                                    <Button style={{ marginLeft: '10px' }} variant="contained" color="primary" onClick={() => this.fetchOps2()}>Fetch Data</Button>
                                </Box>
                            </Grid>
                        </Grid>

                        {/* <Chart
                            width={'95%'}
                            height={'500px'}
                            chartType="ComboChart"
                            loader={<span>Loading Chart</span>}
                            data={this.state.chartData}
                            options={{
                                title: 'Weekly Timesheet Summary',
                                vAxis: {
                                    title: 'Hours',
                                    minValue: 0
                                },
                                hAxis: { title: 'Week' },
                                seriesType: 'bars',
                                series: { 1: { type: 'line' }, },
                            }}
                            rootProps={{ 'data-testid': '1' }}
                        /> */}

                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Item No</TableCell>
                                        <TableCell>Line Item</TableCell>
                                        {this.state.data.map((item) => (
                                            <TableCell align="center">{item.name}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.rows.map((item, index) => (
                                        <TableRow key={item.name} style={{ cursor: index === 8 ? 'pointer' : "" }} onClick={() => {
                                            if (index === 8) {
                                                this.showHidden()
                                            }
                                        }}>
                                            <TableCell component="th" scope="row">{item.row_nos}</TableCell>
                                            {index === 8 ?
                                                <TableCell component="th" scope="row">
                                                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                        <b>{item.row_name}</b>
                                                        <Tooltip title={"Click to view in detail"}>
                                                            <InfoOutlinedIcon style={{ marginLeft: "5px" }} color='primary' fontSize='small' onClick={(e) => { e.stopPropagation(); this.showHidden() }} />
                                                        </Tooltip>
                                                    </Box>
                                                </TableCell>
                                                :
                                                <TableCell component="th" scope="row">
                                                    {boldIndex.includes(index) ?
                                                        <Box>
                                                            <b>{item.row_name}</b>
                                                            <Box fontSize={'11px'} style={{ color: getPrimaryColor() }} marginLeft={'5px'}>
                                                                {this.getFormula(item)}
                                                            </Box>
                                                        </Box>
                                                        : item.row_name}
                                                </TableCell>}
                                            {item.data.map((item1) => (
                                                (percentIndex.includes(index)) ? (
                                                    // <TableCell align="center"><b>{item1[this.state.row_keys[index]]}%</b></TableCell>
                                                    <TableCell align='center'><b>{this.lastWeekComparison(item1, index)}</b></TableCell>
                                                ) : (boldIndex.includes(index)) ? (
                                                    <TableCell align="center"><b>{item1[this.state.row_keys[index]]}</b></TableCell>
                                                ) : (index === detailIndex) ? (
                                                    <TableCell align="center">
                                                        <IconButton color="primary" aria-label="add to shopping cart" onClick={() => {
                                                            this.setState({
                                                                detailsData: item1,
                                                                detailsValue: [item1.start_date, item1.end_date],
                                                                selectedMemberIds: this.getMemIds(),
                                                                openDetails: true
                                                            })
                                                        }}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell align="center">{item1[this.state.row_keys[index]]}</TableCell>
                                                )
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Alert style={{ marginTop: '7px' }} severity="info">
                            <AlertTitle>Note</AlertTitle>
                            Percentage calculations are based on the 'Timesheet Submitted Hours".
                        </Alert>

                        <Box>
                            {this.showGraph()}
                        </Box>

                    </div>
                )
                }

                <Dialog
                    open={this.state.openDetails}
                    fullWidth
                    maxWidth={'xl'}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    disableEnforceFocus
                >
                    <DialogTitle id="customized-dialog-title2" >
                        Details
                    </DialogTitle>
                    <DialogContent>
                        <TimesheetSummaryDetailsTab uid={this.props.uid} value={this.state.detailsValue} data={this.state.detailsData} gid={this.props.gid} memid={this.state.selectedMemberIds} company={this.state.company} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>

                    </DialogActions>
                </Dialog>

            </div >

        )
    }
}

export default withSnackbar(TimesheetSummary)