// import moment from 'moment';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import Service from '../../Networking/networkutils';
import { errorMessage } from '../../Helper/ErrorMessage';
import MaterialTable from 'material-table';
import { getCookie } from '../../Helper/CookieHelper';

// const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));
const token = getCookie("bb_ts_token");
var edit_access = false
var user_ids = [18, 267, 536, 545, 587, 385]

class BroadCastManage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        if (user_ids.includes(user?.id)) {
            edit_access = true
        } else {
            edit_access = false
        }
        this.fetchData()
    }

    fetchData() {
        this.setState({ isLoading: true })
        // var url = `/bb/api/broadcast/?end__year=${2023}`
        var url = `/bb/api/broadcast/?end__year=${2024}`
        Service.get(url)
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoading: false
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    postOps(data) {
        var fields = ''
        if (!data.name) {
            fields = "Name"
        }
        if (!data.tournament_link) {
            fields += !fields ? "Link" : ", Link"
        }
        if (!data.start) {
            fields += !fields ? "Start time" : ", Start time"
        }
        if (!data.end) {
            fields += !fields ? "End time" : ", End time"
        }
        // if (!data.final_announcement) {
        //     fields += !fields ? "Final Announcement" : ", Final Announcement"
        // }
        // if (!data.support_url) {
        //     fields += !fields ? "Support URL" : ", Support URL"
        // }

        if (!fields) {
            data.created_by = user.id
            Service.post(`/bb/api/broadcast/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            })
                .then(res => {
                    this.fetchData()
                    this.props.enqueueSnackbar('Data added successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                    this.fetchData()
                });
        } else {
            this.props.enqueueSnackbar(fields + ' is Empty!', {
                variant: 'warning'
            });
            this.fetchData()
        }
    }

    putOps(data) {
        var fields = ''
        if (!data.name) {
            fields = "Name"
        }
        if (!data.tournament_link) {
            fields += !fields ? "Link" : ", Link"
        }
        if (!data.start) {
            fields += !fields ? "Start time" : ", Start time"
        }
        if (!data.end) {
            fields += !fields ? "End time" : ", End time"
        }
        // if (!data.final_announcement) {
        //     fields += !fields ? "Final Announcement" : ", Final Announcement"
        // }
        // if (!data.support_url) {
        //     fields += !fields ? "Support URL" : ", Support URL"
        // }

        if (!fields) {
            Service.put(`/bb/api/broadcast/${data.id}/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            })
                .then(res => {
                    this.fetchData()
                    this.props.enqueueSnackbar('Data updated successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                    this.fetchData()
                });
        } else {
            this.props.enqueueSnackbar(fields + ' is Empty!', {
                variant: 'warning'
            });
            this.fetchData()
        }
    }

    render() {

        return (
            <div >
                <MaterialTable
                    style={{ margin: '10px' }}
                    title={"Broadcast Manage"}
                    columns={[
                        {
                            title: 'ID', field: 'id', editable: 'never',
                        },
                        {
                            title: 'Name', field: 'name',
                        },
                        {
                            title: 'Link', field: 'tournament_link',
                            lookup: {
                                'https://owncast.bbedc.dev/embed/video': "Stream 1",
                                'https://owncast2.bbedc.dev/embed/video': "Stream 2",
                                'https://owncast3.bbedc.dev/embed/video': "Stream 3",
                                'https://owncast4.bbedc.dev/embed/video': "Stream 4",
                                'https://owncast5.bbedc.dev/embed/video': "Stream 5",
                            }
                        },
                        {
                            title: 'Start time', field: 'start', type: 'datetime'
                        },
                        {
                            title: 'End time', field: 'end', type: 'datetime'
                        },
                        {
                            title: 'Final Announcement', field: 'final_announcement',
                        },
                        {
                            title: 'Support URL', field: 'support_url',
                        },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        padding: "dense",
                        doubleHorizontalScroll: true,
                        paging: false,
                        filtering: true,
                        addRowPosition: "first"
                    }}
                    editable={edit_access ? ({
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        this.postOps(newData);
                                        data.push(newData);
                                        return { ...prevState, data };
                                    });
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            this.putOps(newData)
                                            return { ...prevState, data };
                                        });
                                    }
                                }, 600);
                            }),
                    }) : null}
                />
            </div>
        );
    }
}

export default withSnackbar(BroadCastManage);