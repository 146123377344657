import { AppBar, Box, Dialog, IconButton, Toolbar, Slide } from '@material-ui/core'
import { Badge, Button, Card, CardActionArea, CardActions, CardContent, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import PostAddIcon from '@material-ui/icons/PostAdd';
import { getMode } from '../../../ColorTheme/ColorTheme';
import { getCookie } from '../../../../Helper/CookieHelper';
import UserTaskMap from '../../../../SecuredWorks/UserTaskMap';
import CloseIcon from '@material-ui/icons/Close';
// import CallSplitIcon from '@mui/icons-material/CallSplit';
import Service from "../../../../Networking/networkutils";
import { errorMessage } from '../../../../Helper/ErrorMessage';
import { withSnackbar } from 'notistack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CompareIcon from '@mui/icons-material/Compare';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import FeedIcon from '@mui/icons-material/Feed';
import UserTimesheetReport from '../../../../Reports/UserTimesheetReport/UserTimesheetReport';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ProjectUsersTasks from '../../../../WorkHours/ProjectWorkload/ProjectUsersTasks';
import DateChangeReqManage from '../../../../DateChangeRequest/DateChangeReqManage';
import WebIcon from '@material-ui/icons/Web';
import MainTenderSubTasks from '../../../../TenderSubTasks/MainTenderSubTasks';
import AdditionalHrsTabs from '../../../../SecuredWorks/AdditionalHrsTabs';

const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));
const token = getCookie("bb_ts_token");

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ProjectLists extends Component {
    constructor(props) {
        super(props)

        this.state = {
            scrollHeight: 1800,
            data: this.props.data,
            allData: null,
            userPdm: [],
            openNoti: false,
            openTimesheetReport: false,
            requestTimesheetReportData: {},
            fav_data: [],
            openWorkloadReport: false,
            dateRquestDialog: false,
            openSubTender: false,
            subTenderData: null,
        }

        this.handleSelect = this.handleSelect.bind(this)
        this.refreshData = this.refreshData.bind(this)
    }

    componentDidMount() {
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            element.em_name = element.tenderbid_data.pdm_data ? element.tenderbid_data.pdm_data.last_name + ", " + element.tenderbid_data.pdm_data.first_name : null
        }
        setTimeout(() => {
            if (localStorage.getItem('ProjectTasks_Last_Selected_Id')) {
                this.handleSelect({ id: parseInt(localStorage.getItem('ProjectTasks_Last_Selected_Id')) })
            } else if (this.props.data.length > 0) {
                this.handleSelect(this.props.data[0])
            }
            // this.hoursRequestPending(this.props.data)
            this.smoothScrollToProject()
        }, 300);
        this.userPdmGet()
    }

    smoothScrollToProject() {
        setTimeout(() => {
            if (this.messagesEnd) {
                this.messagesEnd.scrollIntoView({ behavior: "smooth" });
            }
        }, 300);
    }

    refCallback = el => {
        if (el) {
            this.setState({
                scrollHeight: window.innerHeight - el.getBoundingClientRect().y - 28
            })
        }
    }

    allocationPDMAccess(value) {
        // console.log(value, this.state.userPdm)
        for (let pdmData = 0; pdmData < this.state.userPdm.length; pdmData++) {
            const element = this.state.userPdm[pdmData];
            if (element.pid === value.id && element.allocation_access) {
                return true;
            }
        }
        return false;
    }

    searchSelection(value) {
        this.handleSelect(value)
        this.smoothScrollToProject()
    }

    handleSelect(value) {
        // this.props.projectId(value.id)

        var isSelected = false
        var isMultipleSelect = false
        var data = this.props.data
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.id === value.id) {
                if (element.is_selected) {
                    isMultipleSelect = true
                    break
                }
                isSelected = true
                this.props.projectId(element)
                element.is_selected = true
            } else if (element.is_selected) {
                element.is_selected = false
            }
        }
        if (isSelected) {
            this.setState({ data: data })
        } else if (this.state.data.length > 0 && !isMultipleSelect) {
            data[0].is_selected = true
            this.props.projectId(this.state.data[0])
            this.setState({ data: data })
        }
    }

    allocationPDMAccessType(value) {
        // return 'ohl_civ_struc'
        if (value.tenderbid_data.pdm === user.id) {
            return null;
        } else {
            for (let pdmData = 0; pdmData < this.state.userPdm.length; pdmData++) {
                const element = this.state.userPdm[pdmData];
                if (element.pid === value.id && element.allocation_access === 1) {
                    return element;
                } else if (element.pid === value.id && element.allocation_access === 3) {
                    return element;
                } else if (element.pid === value.id && element.allocation_access === 2) {
                    return null;
                }
            }
            return null;
        }

    }

    checkAccessDecipline(element) {
        if ((access.access.l4a.l4a_n && element.tenderbid_data.lead_dicipline === 'ohl') || (access.access.l4a.l4a_o && element.tenderbid_data.lead_dicipline === 'cabling')
            || (access.access.l4a.l4a_p && element.tenderbid_data.lead_dicipline === 'substation')
            || (access.access.l4a.l4a_t && element.tenderbid_data.lead_dicipline === 'edc') || (access.access.l4a.l4a_ag && element.tenderbid_data.lead_dicipline === 'multi_utilities')
            || (access.access.l4a.l4a_s && element.tenderbid_data.lead_dicipline === 'technical')) {
            return true;
        }
        return false
    }

    openResourceAllocation(value) {
        if (value.tenderbid_data.pdm) {
            this.setState({ allData: value, openCalc: true, allocationPDMUsertype: this.allocationPDMAccessType(value), isStage1a: false, })

        } else {
            this.props.enqueueSnackbar('Please assign a EM to the project before Resource Allocation!', {
                variant: 'error'
            });
        }
    }

    // hoursRequestPending(data_to_ids) {
    //     var tender_ids = "0"
    //     for (let i = 0; i < data_to_ids.length; i++) {
    //         const element = data_to_ids[i];
    //         element.hours_req_pending = 0
    //         tender_ids += "," + element.id
    //     }
    //     Service.get("/bb/api/tender/hours/request/pending_count/?tender=" + tender_ids, {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res => {
    //             // alert(res.data.length)
    //             for (let i = 0; i < data_to_ids.length; i++) {
    //                 const element = data_to_ids[i];
    //                 if (!element.hours_req_pending || (data_to_ids.length === 1 && element.id === data_to_ids[0].id)) {
    //                     element.hours_req_pending = 0
    //                 }
    //                 for (let j = 0; j < res.data.length; j++) {
    //                     const element1 = res.data[j];
    //                     if (element.id === element1.tender_pid) {
    //                         element.hours_req_pending += 1
    //                     }
    //                 }
    //             }
    //             this.setState({ data: data_to_ids })

    //         })
    //         .catch(error => {
    //             console.log(error)
    //             errorMessage(this.props, error)
    //         });
    // }

    userPdmGet() {
        Service.get('/bb/api/tender/pdm/?uid=' + user.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(respro => {
                this.setState({
                    userPdm: respro.data
                })

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    addFavourite(value) {
        if (value.tender_project_user_pref_id) {
            var put_data = {
                tender_project: value.id,
                is_favourite: !value.is_favourite,
                user: user.id,
                created_by: user.id
            }
            Service.put("/bb/api/tender/project/user/pref/" + value.tender_project_user_pref_id + "/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: put_data,
            })
                .then(res => {
                    this.reRenderFav(value, res.data)
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        } else {
            var initial_post_data = {
                tender_project: value.id,
                is_favourite: !value.is_favourite,
                user: user.id,
                created_by: user.id
            }
            Service.post('/bb/api/tender/project/user/pref/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: initial_post_data,
            })
                .then(res => {
                    this.reRenderFav(value, res.data)
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    reRenderFav(value, response) {
        var stateKey = 'data'
        var dataToChange = this.state[stateKey]
        for (let i = 0; i < dataToChange.length; i++) {
            const element = dataToChange[i];
            if (element.id === response.tender_project) {
                element.tender_project_user_pref_id = response.id
                element.is_favourite = response.is_favourite
                break
            }
        }
        dataToChange.sort(function (a, b) { return b.is_favourite - a.is_favourite });
        // console.log(dataToChange)
        this.setState({
            [stateKey]: dataToChange
        })
    }

    refreshData(updateCount) {
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if (element.id === updateCount.tender) {
                element.pending_date_request = updateCount.pending_date_request
                element.pending_hrs_request = updateCount.pending_hrs_request
                if (parseInt(localStorage.getItem('ProjectTasks_Last_Selected_Id')) === element.id) {
                    this.props.projectId(element)
                }
                break
            }
        }
    }

    updatedCount() {
        var url = `/bb/api/estimate/date/request/get_pending_count/?&tender_id=${this.state.requestTimesheetReportData.id}`

        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let data = this.state.data
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    if (element.id === res.data.tender) {
                        element.pending_date_request = res.data.pending_date_request
                        element.pending_hrs_request = res.data.pending_hrs_request
                        element.pending_task_allocation_request = res.data.pending_task_allocation_request
                        break
                    }
                }
                this.setState({ data })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    openSubTender(data) {
        // console.log(data)
        this.setState({ subTenderData: data }, () => { this.setState({ openSubTender: true }) })
    }
    render() {
        const handleSubTenderClose = () => {
            this.setState({ openSubTender: false, subTenderData: null })
        };
        const handleClose2 = () => {
            this.setState({ openCalc: false, });
        };

        const handleDialogClose = () => {
            this.setState({
                openTimesheetReport: false
            })
        };
        const handleNotiClose = () => {
            this.setState({ openNoti: false, additionalHoursRequestId: null });
            this.updatedCount()
            // this.props.updateCount()
            // this.hoursRequestPending(this.state.data)
        };

        const handleWorkloadClose = () => {
            this.setState({
                openWorkloadReport: false
            })
        };

        const handleDateChangeReqClose = () => {
            this.setState({
                dateRquestDialog: false
            })
        };

        return (
            <div ref={this.refCallback} style={{ height: this.state.scrollHeight + "px", overflowY: 'scroll' }}>
                <Box display={'flex'} flexDirection={'column'} justifyContent={'left'} >
                    {this.state.data.map((value, i) => (
                        <Box key={i} m={1} ref={(el) => { if (value.is_selected) this.messagesEnd = el; }}>
                            <Card style={{ backgroundColor: value.is_selected ? getMode() ? ' #e69e4d' : '#f2cfa6' : null }}>
                                <CardActionArea onClick={() => this.handleSelect(value)}>
                                    <CardContent>
                                        <Box fontWeight="fontWeightBold" fontSize={14}>
                                            {value.tender_name}
                                        </Box>
                                        {/* <Box>
                                            EM: {value.em_name}
                                        </Box>
                                        <Box>
                                            Discipline: {getDiscipline()[value.tenderbid_data.lead_dicipline]}
                                        </Box>
                                        <Box>
                                            Client: {value.tenderbid_data.client_name}
                                        </Box>
                                        <Box>
                                            Region: {value.tenderbid_data.region_name}
                                        </Box> */}
                                        <Box>
                                            Budget Hours: {value.budget_hours}
                                        </Box>
                                        <Box>
                                            Hours at Completion: {value.revised_hours}
                                        </Box>
                                    </CardContent>

                                </CardActionArea>
                                <Divider />
                                <Box margin={'3px'} display="flex" flexDirection="row-reverse" justifyContent={'right'} >
                                    <CardActions>
                                        <Tooltip title={value.is_favourite ? "Remove Favourite" : "Add Favourite"}>
                                            <IconButton size="small" onClick={() => this.addFavourite(value)}>
                                                {value.is_favourite ? <StarIcon fontSize="inherit" color='primary' /> : <StarBorderIcon fontSize="inherit" color='primary' />}
                                            </IconButton>
                                        </Tooltip>
                                        {/* {(((value.tenderbid_data.pdm === user.id || this.allocationPDMAccess(value)) && (access && access.access && access.access.l4a.l4a_c)) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(value)) ?
                                            <Tooltip title="Resource Allocation">
                                                <IconButton size="small" onClick={() => this.openResourceAllocation(value)} >
                                                    <CallSplitIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                            : null} */}
                                        {(((value.psr && ((value.tenderbid_data.pdm === user.id && (access && access.access && access.access.l4a.l4a_u)) || (access && access.access && access.access.l4a.l4a_v))) || (access && access.access && access.access.l4a.l4a_ab))) ?
                                            <Tooltip title="PSR">
                                                <IconButton size="small" onClick={() => window.open("/report/psr/" + value.id + "/10/")}>
                                                    <VisibilityIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        {((value.psr && ((value.tenderbid_data.pdm === user.id && (access && access.access && access.access.l4a.l4a_u)) || (access && access.access && access.access.l4a.l4a_v))) || (access && access.access && access.access.l4a.l4a_ab)) ?
                                            <Tooltip title="MCR">
                                                <IconButton size="small" onClick={() => window.open("/report/mcr/" + value.id)} >
                                                    <CompareIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        {((value.evp && ((value.tenderbid_data.pdm === user.id && (access && access.access && access.access.l4a.l4a_x)) || (access && access.access && access.access.l4a.l4a_y))) || (access && access.access && access.access.l4a.l4a_z)) ?
                                            <Tooltip title="EVP">
                                                <IconButton size="small" onClick={() => window.open("/report/evp/" + value.id)}>
                                                    <EqualizerIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        {(((value.tenderbid_data.pdm === user.id || this.allocationPDMAccess(value)) && (access && access.access && access.access.l4a.l4a_c)) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(value)) ?
                                            <Tooltip title="Planner">
                                                <IconButton size="small" onClick={(() => this.openSubTender(value))} >
                                                    <WebIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        {(((value.tenderbid_data.pdm === user.id || this.allocationPDMAccess(value)) && (access && access.access && access.access.l4a.l4a_c)) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(value)) ?
                                            <Tooltip title="Workload Report">
                                                <IconButton size="small" onClick={() => this.setState({ openWorkloadReport: true, requestTimesheetReportData: value })}>
                                                    <WatchLaterIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        {(value.tenderbid_data.pdm === user.id || (access && access.access && access.access.l4a.l4a_e)) ?
                                            <Tooltip title="Timesheet Report">
                                                <IconButton size="small" onClick={() => this.setState({ openTimesheetReport: true, requestTimesheetReportData: value })}>
                                                    <FeedIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        {((value.tenderbid_data.pdm === user.id || value.enable_hours_request) || (access.access && access.access.l4a.l4a_ad)) ?
                                            <Tooltip title="Hours Request">
                                                <Badge badgeContent={value.pending_hrs_request + value.pending_task_allocation_request} color="primary">
                                                    <IconButton size="small" onClick={() => this.setState({ openNoti: true, requestTimesheetReportData: value })}>
                                                        <PostAddIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Badge>
                                            </Tooltip>
                                            : null}
                                        {((value.tenderbid_data.pdm === user.id) || (value.tenderbid_data.region_hoe.id === user.id)) ?
                                            <Tooltip title="DateChange Request">
                                                <Badge badgeContent={value.pending_date_request} color="primary">
                                                    <IconButton size="small" onClick={() => this.setState({ dateRquestDialog: true, requestTimesheetReportData: value })}>
                                                        <DateRangeIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Badge>
                                            </Tooltip>
                                            : null}
                                    </CardActions>
                                </Box>
                            </Card>
                        </Box>
                    ))
                    }
                    {this.state.data.length === 0 ?
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 1, fontWeight: 'bold', height: this.state.childHeight ? this.state.childHeight : 250 }}>
                            No Data Avaialble
                        </Box>
                        : null}
                </Box>
                <Dialog fullScreen open={this.state.openCalc} onClose={handleClose2} TransitionComponent={Transition}>
                    <AppBar position="relative">
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose2} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6">
                                Resource Allocation for {this.state.allData ? this.state.allData.tender_name : null}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <UserTaskMap value={this.state.allData} type={this.state.allocationPDMUsertype} stage1a={this.state.isStage1a} />
                </Dialog>

                <Dialog
                    open={this.state.openTimesheetReport}
                    maxWidth={"lg"}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Timesheet Report"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Report will shared via E-mail
                        </DialogContentText>
                        <UserTimesheetReport teamUsers={false} userEnabled={false} tender={this.state.requestTimesheetReportData.id} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openNoti} maxWidth="xl" onClose={handleNotiClose} aria-labelledby="form-dialog-title">
                    <Box display="flex" flexDirection="row-reverse">
                        <Button color='primary' onClick={handleNotiClose}>Close</Button>
                    </Box>
                    <DialogContent>
                        <AdditionalHrsTabs value={this.state.requestTimesheetReportData.id} count={this.state.requestTimesheetReportData} />
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.openWorkloadReport}
                    maxWidth={"xl"}
                    fullWidth
                    onClose={handleWorkloadClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.state.requestTimesheetReportData.tender_name + " - Workload Report"}</DialogTitle>
                    <DialogContent>
                        <ProjectUsersTasks value={this.state.requestTimesheetReportData.id} filterAll={this.props.toggle === "all_workbook"} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleWorkloadClose} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.dateRquestDialog}
                    maxWidth={"xl"}
                    fullWidth
                    onClose={handleDateChangeReqClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Box display="flex" flexDirection="row-reverse">
                        <Button color='primary' onClick={handleDateChangeReqClose}>Close</Button>
                    </Box>
                    <DialogContent>
                        <DateChangeReqManage
                            value={this.state.requestTimesheetReportData}
                            refreshData={this.refreshData}
                        />
                    </DialogContent>
                </Dialog>

                <Dialog fullScreen open={this.state.openSubTender} aria-labelledby="customized-dialog-title" >
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleSubTenderClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                {this.state.subTenderData ? this.state.subTenderData.tender_name : null}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {/* <DialogContent> */}
                    <MainTenderSubTasks tender_id={this.state.subTenderData ? this.state.subTenderData.id : null} />
                    {/* </DialogContent> */}
                </Dialog>
            </div >
        )
    }
}

export default withSnackbar(ProjectLists)