import React, { Component } from 'react'
import AppBar from '@material-ui/core/AppBar';
import moment from "moment";
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import RequestReceivedTable from './RequestReceivedTable';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }

class RequestReceived extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0,
            years: [],
        }
    }

    componentDidMount() {
        this.fetchData()
    }
    componentWillUnmount(){
        if (this.props.refreshCount) {
            this.props.refreshCount()
        }
    }

    fetchData() {
        var years = []

        for (let index = 2020; index <= moment().year(); index++) {
            years.push(index)
        }
        if (moment().format("MMM") === "Nov") {
            years.push(moment().add(1, 'year').format("YYYY"))
        }
        this.setState({
            years: years,
            value: moment().format("MMM") === "Nov" ? years.length - 2 : years.length - 1,
        })
    }

    render() {

        // const handleChange = (event, newValue) => {
        //     this.setState({
        //         value: newValue
        //     })
        // };

        return (
            <div >
                <AppBar position="static" color="default" >
                    {/* <Tabs value={this.state.value} onChange={handleChange} aria-label="simple tabs example"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {this.state.years.map((value, index) =>
                            <Tab label={this.state.years[index]} {...a11yProps(index)} />
                        )}
                    </Tabs> */}
                </AppBar>
                {/* {this.state.years.map((value, index) =>
                    <TabPanel value={this.state.value} index={index}> */}
                        <RequestReceivedTable 
                        // year={this.state.years[index]}
                        year={moment().year()}
                        />
                    {/* </TabPanel>
                )} */}
            </div>
        )
    }
}

export default withSnackbar(RequestReceived)
