import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { SemipolarLoading } from 'react-loadingg';
import { TextField } from '@material-ui/core';
// import Moment from 'react-moment';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import { getPrimaryColor } from '../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));
class ManageTasks extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            openAdd: false,
            projName: '',
            description: '',
            isLoading: true,
            hours: 0,
            file: [],
            openImport: false,
            importedData: [],
            taskGroup: '',
            taskType: '',
            selectDate1: new Date(),
            selectDate2: new Date(),
            roleAll: [],
            rolesCust: {},
            isLoading2: true,
        }
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        Service.get("/bb/api/tasks/?task_category=" + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    giveaccess(putData, status) {
        this.setState({ isLoading: true, });

        var json = {
            is_active: status,
        }

        Service.put("/bb/api/tasks/" + putData.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: json,
        })
            .then(res => {
                this.props.enqueueSnackbar('Mode changed', {
                    variant: 'success'
                });
                this.fetchOps();
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    postData() {
        if (!this.state.projName) {
            this.props.enqueueSnackbar('Task name should not be empty!', {
                variant: 'warning'
            });
        } else {
            this.setState({ isLoading: true, });
            var json = {
                pid: 11,
                task_category: this.props.value.id,
                name: this.state.projName,
                hours: 10,
                group: "NA",
                type: "NA",
                est_date_from: this.state.selectDate1,
                est_date_to: this.state.selectDate2,
                created_by: user.email,
                description: this.state.description,
            }

            Service.post("/bb/api/tasks/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: json,
            })
                .then(res => {
                    this.setState({ openAdd: false, projName: '' });
                    this.props.enqueueSnackbar('Task added successfully', {
                        variant: 'success'
                    });
                    this.fetchOps();
                })
                .catch(error => {
                    this.fetchOps();
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    putData(asdata) {
        if (!asdata.name) {
            this.props.enqueueSnackbar('Task name should not be empty!', {
                variant: 'warning'
            });
            this.fetchOps();
        } else {
            Service.put('/bb/api/tasks/' + asdata.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: asdata,
            })
                .then(res => {
                    this.setState({ isLoading: false })
                    this.props.enqueueSnackbar('Edited Successfully!', {
                        variant: 'success'
                    });
                    this.fetchOps();
                })
                .catch(error => {
                    console.log(error)
                    this.fetchOps();
                    errorMessage(this.props, error)
                    this.setState({ isLoading: false })
                });
        }
    }

    render() {

        const handleClose = (e) => {
            this.setState({ openAdd: false, openImport: false, })
        };

        const handleName = (e) => {
            this.setState({ projName: e.target.value })
        };

        const handleDescription = (e) => {
            this.setState({ description: e.target.value })
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <SemipolarLoading color={getPrimaryColor()} />
                ) : (
                    <div style={{ margin: '10px' }}>
                        <MaterialTable
                            title="Manage Task"
                            columns={[
                                { title: 'Id', field: 'id', editable: 'never' },
                                { title: 'Name', field: 'name' },
                                { title: 'Description', field: 'description' },
                                { title: 'Hours Control', field: 'hours_control', type: "boolean" },
                                { title: 'Comment Mandatory', field: 'comment_mandatory', type: "boolean" },
                                // {
                                //     title: 'Date Created', field: 'date_created', editable: 'never',
                                //     render: rowData => <Moment local format="DD/MM/YYYY hh:mm A">{rowData.date_created}</Moment>
                                // },
                                // { title: 'Created By', field: 'created_by', editable: 'never' },
                            ]}
                            data={this.state.data}
                            actions={(this.props.editable && (access && access.access.l3a.l3a_d) && (access && access.access.l3a.l3a_e)) ? ([
                                rowData => ({
                                    icon: 'check_circle',
                                    tooltip: 'Enable',
                                    onClick: (event, rowData) => this.giveaccess(rowData, true),
                                    disabled: rowData.is_active
                                }),
                                rowData => ({
                                    icon: 'cancel',
                                    tooltip: 'Disable',
                                    onClick: (event, rowData) => this.giveaccess(rowData, false),
                                    disabled: !rowData.is_active
                                }),
                                {
                                    icon: () => <Button variant="contained" color="primary" onClick={e => { this.setState({ openAdd: true }) }} style={{ margin: '5px' }}>Add Task</Button>,
                                    isFreeAction: true,
                                }
                            ]) : (null)}
                            options={{
                                padding: "dense",
                                actionsColumnIndex: -1,

                            }}
                            editable={
                                (this.props.editable && (access && access.access && access.access.l3a.l3a_e) && (access && access.access && access.access.l3a.l3a_d)) ? (
                                    {
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.setState({ save: false });
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            this.putData(newData, data);
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 60);
                                            }),
                                    }
                                ) : (null)}
                        />
                        <Dialog open={this.state.openAdd} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Add Task</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    Warning : This can't be changed later. Please double check before submitting.
                                </DialogContentText>
                                <TextField id="outlined-basic" label="Task" variant="outlined"
                                    value={this.state.projName}
                                    onChange={handleName}
                                    fullWidth
                                    style={{ margin: '5px' }}
                                />

                                <TextField id="outlined-basic" label="Description" variant="outlined"
                                    value={this.state.description}
                                    onChange={handleDescription}
                                    fullWidth
                                    style={{ margin: '5px' }}
                                />

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={e => this.postData()} color="primary">
                                    Add Task
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}

            </div>
        )
    }
}

export default withSnackbar(ManageTasks);