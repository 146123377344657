import React, { Component } from 'react'
import MaterialTable from 'material-table';
import Service from "../../Networking/networkutils";
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import ReactGA from 'react-ga';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class RateTemplate extends Component {

    constructor(props) {
        super()

        this.state = {
            data: [],
            columns: [
                { title: 'Designation', field: 'desig', },
                {
                    title: 'Grade', field: 'grade',
                    lookup: { 'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E', 'F': 'F', 'G': 'G' },
                },

                { title: 'Price for UK (In £)', field: 'costuk', type: 'numeric' },
                { title: 'Price for India/Srilanka (In £)', field: 'costin', type: 'numeric' },
            ],
            isLoading: true,
        }
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {

        var pageView = window.location.pathname + "/#Rate Card Template";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        Service.get('/bb/api/tender/template/ratecard/?year=' + this.props.value, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ isLoading: false, data: res.data, })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    postOps(data) {

        var pageView = window.location.pathname + "/#Rate Card Template";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (data.desig === undefined || data.desig === '') {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Designation field is empty!', {
                    variant: 'error'
                });
            }, 200)

        } else if (data.grade === undefined || data.grade === '') {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Grade field is empty!', {
                    variant: 'error'
                });
            }, 200)
        } else {
            data.year = this.props.value
            Service.post('/bb/api/tender/template/ratecard/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data,
            })
                .then(res => {
                    this.setState({ isLoading: true, })
                    this.fetchOps();
                    this.props.enqueueSnackbar('Designation added successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    putOps(data) {

        var pageView = window.location.pathname + "/#Rate Card Template";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (data.desig === undefined || data.desig === '') {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Designation field is empty!', {
                    variant: 'error'
                });
            }, 200)

        } else {
            Service.put('/bb/api/tender/template/ratecard/' + data.id, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data,
            })
                .then(res => {
                    this.props.enqueueSnackbar('Designation updated successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: true, })
                    errorMessage(this.props, error)
                    this.fetchOps();
                });
        }
    }

    deleteData(data) {

        var pageView = window.location.pathname + "/#Rate Card Template";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        Service.delete('/bb/api/tender/template/ratecard/' + data.id, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.props.enqueueSnackbar('Designation deleted successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: true, })
                errorMessage(this.props, error)
                this.fetchOps();
            });
    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <MaterialTable
                            key={3}
                            title="Rate Card Template"
                            columns={[
                                { title: 'Designation', field: 'desig', },
                                {
                                    title: 'Grade', field: 'grade',
                                    lookup: { 'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E', 'F': 'F', 'G': 'G' },
                                },

                                { title: 'Price for UK (In £)', field: 'costuk', type: 'numeric' },
                                { title: 'Price for India/Srilanka (In £)', field: 'costin', type: 'numeric' },
                            ]}
                            options={{
                                padding: "dense",
                                pageSize: 10,
                                pageSizeOptions: [10, 25, 50],
                                addRowPosition: "first",
                            }}
                            data={this.state.data}
                            icons={{
                                Add: props => <Button color='primary' variant="contained" startIcon={<AddIcon />}>
                                    Add Rate card
                                </Button>
                            }}
                            editable={!this.props.editable ? null : ((access && access.access && !access.access.l10a.l10a_b) && (access && access.access && access.access.l10a.l10a_c)) ? (
                                {
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        this.putOps(newData)
                                                        return { ...prevState, data };
                                                    });
                                                }
                                            }, 600);
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState({ saved: false });
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    this.deleteData(oldData)
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                }
                            ) : ((access && access.access && access.access.l10a.l10a_b) && (access && access.access && !access.access.l10a.l10a_c)) ? (
                                {
                                    onRowAdd: newData =>
                                        new Promise(resolve => {

                                            setTimeout(() => {
                                                resolve();
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    newData.created_by = user.email;
                                                    this.postOps(newData);
                                                    data.push(newData);
                                                    // this.postData(newData)
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),

                                }
                            ) : ((access && access.access && access.access.l10a.l10a_b) && (access && access.access && access.access.l10a.l10a_c)) ? (
                                {
                                    onRowAdd: newData =>
                                        new Promise(resolve => {

                                            setTimeout(() => {
                                                resolve();
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    newData.created_by = user.email;
                                                    this.postOps(newData);
                                                    data.push(newData);
                                                    // this.postData(newData)
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        this.putOps(newData)
                                                        return { ...prevState, data };
                                                    });
                                                }
                                            }, 600);
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState({ saved: false });
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    this.deleteData(oldData)
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                }
                            ) : (null)}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default withSnackbar(RateTemplate);