import React, { PureComponent } from 'react'
import { Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import { withSnackbar } from 'notistack'
import { getCookie } from '../../../Helper/CookieHelper';
import Service from '../../../Networking/networkutils'
import { errorMessage } from '../../../Helper/ErrorMessage';
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../../../Helper/CommonThings';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import OverAllSummary from './OverAllSummary';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import { getMode } from '../../../MainDashBoard/ColorTheme/ColorTheme';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import CardActionArea from '@material-ui/core/CardActionArea';
import { getMode } from '../../../MainDashBoard/ColorTheme/ColorTheme';
import CommentIcon from '@material-ui/icons/Comment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { BootstrapDialogTitleV4, BootstrapDialogV4 } from '../../../Helper/DialogTitleM5';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
const access = JSON.parse(getCookie("bb_ts_access"));

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: '95%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
});

var deleIndex = null
var addIndex = null

class SummaryList extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            expanded: null,
            rates: [],
            roles: [],
            isLoading: true,
            isLoading1: true,
            openDelete: false,
            addMoreDisabled: true,
            openAdd: false,
            openComments: false,
            comments: null,
            copyButtonDisabled: false
        }
    }

    componentDidMount() {
        deleIndex = null
        addIndex = null
        if (this.props.rates.length > 0) {
            this.setState({ rates: this.props.rates, roles: this.props.roles, isLoading: false, isLoading1: false, addMoreDisabled: false })
        } else {
            this.fetchOps()
        }
    }

    fetchOps() {
        this.setState({ addMoreDisabled: false })
        Service.get('/bb/api/tender/rate/?pid=' + this.props.pid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.length === 0) {
                    this.props.enqueueSnackbar('Goto Rates tab to initiate default rates!', {
                        variant: 'warning'
                    });
                } else {
                    this.setState({
                        isLoading1: false,
                        rates: res.data,
                    });
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.length === 0) {
                    this.props.enqueueSnackbar('Goto Roles tab to initiate default roles!', {
                        variant: 'warning'
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        roles: JSON.parse(JSON.stringify(res.data)),
                        // roles: res.data
                    });
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    addMore() {
        this.props.addMore()
    }


    getDates(value) {
        var earliestDate = null
        var latestDate = null
        var existRoleIds = []
        var totalHours = 0
        var totalCost = 0
        var number_of_tasks = 0
        for (let j = 0; j < value.tasks.length; j++) {
            const taskElement = value.tasks[j];
            if (taskElement.fm_checked) {
                number_of_tasks += 1
                if (taskElement.from_date && (!earliestDate || moment(taskElement.from_date) < earliestDate)) {
                    earliestDate = moment(taskElement.from_date)
                }
                if (taskElement.to_date && (!latestDate || moment(taskElement.to_date) > latestDate)) {
                    latestDate = moment(taskElement.to_date)
                }
                for (let i = 0; i < taskElement.roles.length; i++) {
                    const roleElement = taskElement.roles[i];
                    if (roleElement.fm_checked) {
                        if (!existRoleIds.includes(roleElement.id)) {
                            existRoleIds.push(roleElement.id)
                        }
                        totalHours += parseFloat(roleElement.hours)
                        totalCost += this.costCalc2(roleElement)
                    }
                }
            }
        }
        const totalCostFormat = new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(totalCost)
        return [earliestDate?.format("DD-MM-YYYY"), latestDate?.format("DD-MM-YYYY"), totalHours, totalCostFormat, number_of_tasks, existRoleIds.length]
    }

    costCalc2(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
                const element = this.props.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
                const element = this.props.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costuk;
                }
            }
        }
        return 0;
    }

    openDelete(id) {
        deleIndex = id
        this.setState({ openDelete: true })
    }

    deleteConfirm() {
        this.props.deleteSummary(deleIndex)
        this.setState({ openDelete: false })
        deleIndex = null
    }

    enableAddMore() {
        this.setState({ addMoreDisabled: false })
    }

    checkExists(value) {
        let existData = this.props.data
        for (let i = 0; i < existData.length; i++) {
            const element = existData[i];
            if (element.template_pid === value.template_pid) {
                return <Box color='red'>Already estimated. This cannot be added to ds</Box>
            }
        }
    }

    deleteDisabled(value) {
        if (this.props.tender.tenderbid_data.pdm === user.id || user.id === value.created_by) {
            return false
        } else {
            return true
        }
    }

    lockDisable(value) {
        if (!value.is_locked || this.props.tender.tenderbid_data.pdm === user.id || user.id === value.locked_by) {
            return false
        } else {
            return true
        }
    }

    addDisabled(value) {
        if (this.props.tender.tenderbid_data.pdm === user.id || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
            return false
        } else {
            return true
        }
    }

    openAdd(id) {
        addIndex = id
        this.setState({ openAdd: true })
    }

    addConfirm() {
        this.props.singleDSPost(addIndex)
        this.setState({ openAdd: false })
        addIndex = null
    }

    openComments(value) {
        this.setState({ comments: value, openComments: true })
    }

    render() {
        // const { classes } = this.props;

        const handlePanelChange = (panel) => (event, newExpanded) => {
            this.setState({ expanded: newExpanded ? panel : false })
        };

        const handleDeleteClose = (e) => {
            deleIndex = null
            this.setState({ openDelete: false })
        };

        const handleAddClose = (e) => {
            addIndex = null
            this.setState({ openAdd: false })
        };

        return (
            <div>
                {((this.state.isLoading) || (this.state.isLoading1)) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <Box display="flex" justifyContent="center" flexDirection='column'>
                        <Box marginBottom={'5px'}>
                            <Alert severity="info">
                                Please note that
                                "WBS, Tasks and Roles with zero budget" will be excluded for the estimate!
                                Dates are mandatory to finalize the estimate
                            </Alert>
                        </Box>

                        {this.props.value.map((value, i) => {
                            var wbsDetails = this.getDates(value.cart_data)
                            return (<Accordion key={value.id} style={{ width: '100%' }} expanded={this.state.expanded === value.id} onChange={handlePanelChange(value.id)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Box display='flex' flexDirection={'row'} style={{ width: '100%' }}>
                                        <Box display='flex' flexDirection={'column'} style={{ width: '100%' }} >
                                            <Box display="flex" alignItems='center' style={{ width: '100%' }}>
                                                <Box display="flex" alignItems='center' fontWeight='bold'>
                                                    <Typography variant='h6'>{value.cart_data.name}</Typography>
                                                </Box>

                                                <Box display="flex" alignItems='center' marginLeft={'15px'} >
                                                    <Typography variant='h7'>(Click for detailed view)</Typography>
                                                </Box>
                                            </Box>
                                            <Box display="flex" style={{ width: '100%', marginLeft: '15px' }} flexDirection={'row'}>
                                                <Box display="flex" flexGrow={1} justifyContent='space-evenly' alignItems='left'>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={3}>
                                                            <Box display='flex' flexDirection={'column'} justifyContent='left'>
                                                                <Box >
                                                                    From date: {wbsDetails[0] ? wbsDetails[0] : "NA"}
                                                                </Box>
                                                                <Box >
                                                                    Hours: {wbsDetails[2]}
                                                                </Box>
                                                                <Box >
                                                                    No of tasks: {wbsDetails[4]}
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Box display='flex' flexDirection={'column'} justifyContent='left'>
                                                                <Box>
                                                                    To date: {wbsDetails[1] ? wbsDetails[1] : "NA"}
                                                                </Box>
                                                                <Box >
                                                                    Cost: {wbsDetails[3]}
                                                                </Box>
                                                                <Box >
                                                                    No of roles: {wbsDetails[5]}
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={5} >
                                                            <Box display='flex' flexDirection={'column'} justifyContent='left' >
                                                                <Box >
                                                                    Cart created by: {value.created_by_data.last_name + ", " + value.created_by_data.first_name}
                                                                </Box>
                                                                <Box>
                                                                    Last modified on: {moment(value.updated_on).format("DD MMM YYYY HH:mm:ss")}
                                                                </Box>
                                                                <Box>
                                                                    Last modified by: {value.updated_by_data.last_name + ", " + value.updated_by_data.first_name}
                                                                </Box>
                                                                <Box>
                                                                    {this.checkExists(value)}
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>

                                                </Box>

                                            </Box>
                                            {value.is_locked ?
                                                <Alert severity="warning" variant="outlined" style={{ marginLeft: '15px', marginTop: '5px' }}>
                                                    This item is locked.
                                                    Only {value.locked_by_data.last_name + ", " + value.locked_by_data.first_name} and the EM can edit this cart item. If you still want to edit this item please contact them to unlock it.
                                                </Alert> : null}
                                        </Box>
                                        <Box display="flex" alignItems='center' onClick={(event) => event.stopPropagation()}>
                                            {
                                                value.cart_data.editable ?
                                                    <Tooltip title={"Copy item"} >
                                                        <IconButton onClick={() => { this.props.postCart(value.cart_data, 'copy'); this.setState({ copyButtonDisabled: true }) }}><FileCopyIcon fontSize="small" /></IconButton>
                                                    </Tooltip> : null
                                            }
                                            <Tooltip title={"Click to " + (value.is_locked ? "Unlock" : "Lock")} >
                                                <IconButton disabled={this.lockDisable(value)} onClick={() => this.props.lockedStatus(value)}>{value.is_locked ? <LockIcon fontSize="small" color='error' /> : <LockOpenIcon fontSize="small" />}</IconButton>
                                            </Tooltip>
                                            <Tooltip title={"Edit Cart Item"} >
                                                <IconButton disabled={this.lockDisable(value)} onClick={() => this.props.editSummary(value)}><EditIcon fontSize="small" /></IconButton>
                                            </Tooltip>
                                            <Tooltip title={"Comments"} >
                                                <IconButton onClick={() => this.openComments(value)}><CommentIcon fontSize="small" /></IconButton>
                                            </Tooltip>
                                            <Tooltip title={"Remove from Cart"} >
                                                <IconButton disabled={this.deleteDisabled(value) || this.lockDisable(value)} onClick={() => this.openDelete(value.id)}><DeleteIcon fontSize="small" /></IconButton>
                                            </Tooltip>
                                            <Tooltip title={"Save Deliverables Schedule"} >
                                                <IconButton disabled={this.addDisabled(value) || this.lockDisable(value)} onClick={() => this.openAdd(value.id)}><SendIcon fontSize="small" /></IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box display="flex" justifyContent="center" flexDirection='column' width={'100%'}>
                                        <OverAllSummary value={value.cart_data} rates={this.props.rates} cart={true} />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>)
                        })}

                        <Card style={{ marginTop: '10px', marginBottom: '10px', boxSizing: 'border-box', border: getMode() ? '1px solid white' : '1px solid black', borderRadius: '10px 10px 10px 10px', borderStyle: 'dashed', height: '100%', width: '100%', borderWidth: '2px' }} elevation={4}>
                            <CardActionArea onClick={(e) => this.state.addMoreDisabled ? null : this.addMore()}>
                                <Box style={{ width: '100%', padding: '2px' }} display='flex' justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                    <IconButton onClick={(e) => this.addMore()} disabled={this.state.addMoreDisabled}><AddIcon /></IconButton>
                                    <Box marginBottom={'2px'} fontSize='10px'>Add more WBS</Box>
                                </Box>
                            </CardActionArea>
                        </Card>
                    </Box>
                )
                }

                <Dialog open={this.state.openDelete} fullWidth maxWidth='xs' onClose={handleDeleteClose} aria-labelledby="customized-dialog-title">
                    <DialogContent>
                        <Typography variant='h6'>
                            Are you sure! Do you want to delete?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' onClick={handleDeleteClose}>
                            No
                        </Button>
                        <Button color='primary' onClick={() => this.deleteConfirm()}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openAdd} onClick={handleAddClose} fullWidth maxWidth='xs' aria-labelledby="customized-dialog-title">
                    <DialogContent>
                        <Typography variant='h6'>
                            Are you sure! Do you want to Save?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' onClick={handleAddClose}>
                            No
                        </Button>
                        <Button color='primary' onClick={() => this.addConfirm()}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <BootstrapDialogV4 open={this.state.openComments} fullWidth maxWidth='xs' aria-labelledby="customized-dialog-title">
                    <BootstrapDialogTitleV4 id="form-dialog-title" onClose={() => this.setState({ openComments: false })}>
                        <Typography variant='h6'>
                            {this.state.comments ? this.state.comments.cart_data.name : null}
                        </Typography>
                    </BootstrapDialogTitleV4>
                    <DialogContent>
                        {
                            this.state.comments && this.state.comments.comments.length !== 0 ?
                                this.state.comments.comments.filter(item => item.cart_item_added).map((value, i) =>
                                    <Box m={1}>
                                        <Card>
                                            <CardContent>
                                                <Box display='flex' flexDirection={'column'} padding='5px' >
                                                    <Box fontWeight='bold'>
                                                        <Typography variant='h7'>{value.user_name}</Typography>
                                                    </Box>
                                                    <Box>
                                                        {value.cart_item_added}
                                                    </Box>
                                                    <Box fontSize={'11px'}>
                                                        {moment(value.date_created).format("DD MMM YYYY HH:mm:ss")}
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                ) : <Box display='flex' flexDirection={'column'} justifyContent={'center'}>No Comments</Box>}
                    </DialogContent>
                </BootstrapDialogV4>

            </div >
        )
    }
}

export default withSnackbar(withStyles(useStyles)(SummaryList))