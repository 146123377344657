import { withSnackbar } from 'notistack';
import React, { Component } from 'react'
import { getCookie } from '../../Helper/CookieHelper';
import Service from "../../Networking/networkutils";
import { errorMessage } from '../../Helper/ErrorMessage';
import MaterialTable from 'material-table';

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

class RolesReviseHistory extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true
        }
    }

    componentDidMount() {
        Service.get('/bb/api/tender/tasks/rolesrevhistory/' + this.props.value.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoading: false
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    render() {
        return (
            <div>
                <MaterialTable
                    key={3}
                    title={this.state.data.name}
                    columns={[
                        {
                            title: 'User', field: 'user_name', editable: 'never',
                        },
                        {
                            title: 'Old Revised Hours', field: 'rev_old', editable: 'never',
                        },
                        {
                            title: 'New Revised Hours', field: 'rev_new', editable: 'never',
                        },
                        {
                            title: 'Modified On', field: 'date_modified', type: 'datetime', editable: 'never', defaultSort: 'desc',
                        },

                    ]}
                    isLoading={this.state.isLoading}
                    data={this.state.data.hours_rev_history}
                    options={{
                        padding: "dense",
                        paging: false,
                        // actionsColumnIndex: -1
                    }}
                />
            </div>
        )
    }
}

export default withSnackbar(RolesReviseHistory)