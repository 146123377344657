import ReactApexChart from 'react-apexcharts';
import React, { Component } from 'react'
import { getDarkMode } from '../../../../../Helper/CommonThings';
import { screenSizeCheck } from '../../../../ScreensizeCheck/ScreensizeCheck';

class TaskChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            height: 0,
            series: [this.getHrsPercent(this.props.data.planned), this.getHrsPercent(this.props.data.completed), this.getHrsPercent(this.props.data.approved)],
            options: {
                chart: {
                    type: 'radialBar',
                    // height: '100%',
                    // width: '100%',
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 1000,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },
                },
                theme: {
                    mode: getDarkMode() === 'dark' ? 'dark' : 'light',
                    palette: 'palette1',
                },
                legend: {
                    show: true,
                    position: 'right',
                    fontSize: screenSizeCheck() ? '9px' : '10px',
                    // offsetX: 5,
                    customLegendItems: ['Planned(' + this.props.data.planned + ')', 'Completed(' + this.props.data.completed + ')', 'Approved(' + this.props.data.approved + ')'],
                    labels: {
                        useSeriesColors: false
                    },
                },
                plotOptions: {
                    radialBar: {
                        dataLabels: {
                            name: {
                                fontSize: '16px',
                            },
                            value: {
                                fontSize: '14px',
                                offsetY: 4,
                            },
                            total: {
                                show: true,
                                label: 'Total Hours',
                                fontSize: '10px',
                                formatter: (w) => {
                                    return this.props.data.total_hours
                                }
                            }
                        }
                    }
                },
                labels: ['Planned', 'Completed', 'Approved'],
            },
        };
    }

    setHeight(height) {
        // alert(height)
        this.setState({
            height: height
        })
    }

    getHrsPercent(value) {
        return ((100 / this.props.data.total_hours) * value).toFixed(1)
    }

    row = el => {
        if (el) {
            const height = (el.offsetWidth - 30) / 2
            // alert(height)
            this.setHeight(height)
        }
    };

    render() {
        return (
            <div ref={this.row} >
                <ReactApexChart options={this.state.options} series={this.state.series} height={this.state.height + "px"} type="radialBar" />
            </div>

        )
    }
}

export default TaskChart