import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { getCookie } from "./CookieHelper";

export function sortByProperty(property) {
    return function (a, b) {
        if (a[property] > b[property])
            return 1;
        else if (a[property] < b[property])
            return -1;

        return 0;
    }
}

var countTimes = 0;

export function getCountTime() {
    return countTimes
}

export function increseCountTime() {
    return ++countTimes
}

export function resetCountTime(path) {

    if (path === '/bb/api/token/access/') {
        return countTimes
    } else {
        countTimes = 0
        return 0;
    }

}

export function poundFormat(data) {
    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data)
}

var darkMode = getCookie('theme_mode')

if (!darkMode) {
    darkMode = localStorage.getItem("theme_mode")
}

export function getDarkMode() {
    if (darkMode) {
        return darkMode
    } else {
        return 'light'
    }

}

export function onlyUniqueArrId(value, index, self) {
    return self.indexOf(value.id) === index;
}

export function onlyUniqueArr(value, index, self) {
    return self.indexOf(value) === index;
}

export function comparer(otherArray) {
    return function (current) {
        return otherArray.filter(function (other) {
            return other === current
        }).length === 0;
    }
}

export function tenderTaskStatus(code) {
    var status = {
        0: 'Not Started', 5: 'Preparation of documents/drawings', 10: 'Preparation of documents/drawings', 20: 'Preparation of documents/drawings',
        30: 'Preparation of documents/drawings', 40: 'Preparation of documents/drawings', 50: 'Preparation of documents/drawings',
        60: 'Checked within Department', 65: 'Approved within Department', 70: 'Site comments received, reviewed',
        80: 'Multi-disciplinary Reviewed & Commented', 90: 'Design verified.', 100: 'Design assured'
    }
    return status[code]
}

export const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export function hourRequestReasonType(code) {
    var type = {
        "1": {
            "menu": "Rework - external",
            "note": "Work completed, submitted for review, and based on the comments the entire or partial work has to be re-done."
        },
        "2": {
            "menu": "Rework - internal",
            "note": "partial or complete reworking after internal review, or due to finding an error",
        },
        "3": {
            "menu": "Additional info received",
            "note": "If you have received additional information, while you were half way or almost near completion of the work, which results in updating the work completed"
        },
        "4": {
            "menu": "Change in original scope",
            "note": "If there is a change in original scope of work",
        },
        "5": {
            "menu": "Incorrect Estimate",
            "note": "There have been no particular issues, the work is taking longer than estimated",
        },
        "6": {
            "menu": "Extended work scope",
            "note": "If you as Team Lead has increased or changed the scope for any reason - for example changing a design to reflect a change on site"
        },
        "7": {
            "menu": "Increase within budget",
            "note": "If you as Team Lead have originally allocated only part of the budget, and this request is to allocate more hours without exceeding the budget"
        }
    }
    return type[code]
}

export function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
}

export function confetti() {

    return false;
}

export function newTabWithHash(data) {
    var location = window.location
    var url = location.protocol + '//' + location.host + location.pathname
    url += "#" + data.replace(' ', '%20').replace(' ', '%20')
    window.open(url);
}

export function newTabWithHashWithQuery(data, query) {
    var location = window.location
    var url = location.protocol + '//' + location.host + location.pathname
    url += query + "#" + data.replace(' ', '%20').replace(' ', '%20')
    window.open(url);
}

export function newTabWithPath(data) {
    var location = window.location
    var url = location.protocol + '//' + location.host + data
    if (window.event.which === 3) {
        window.open(url);
    } else if (window.event.which === 1) {
        window.location.href = url;
    }
}

export function accessReloadOrRefresh() {
    // console.log(window.location.hostname === 'new.bbparam.com', "Reload")
    if (window.location.hostname === 'new.bbparam.com') {
        window.location.href = "https://bbparam.com/auth/newparam/"
    } else {
        window.location.reload();
    }
}

export function getDiscipline() {
    return { 'ohl': 'OHL', 'cabling': 'Cabling', 'substation': 'Substation', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical', 'edc': 'EDC' }
}

export function getStage() {
    return {
        0: 'Stage 2 - Tender Development', 1: 'Stage 2 - Awaiting Approval', 2: 'Stage 2 - Submission Approved', 3: 'Stage 3 - Tender Submitted', 4: 'Stage 3a - Tender Repricing', 5: 'Stage 3a - Awaiting Approval',
        6: 'Stage 3a - Approved', 7: 'Stage 3a - Re-Submission', 8: 'Stage 4 - Awarded', 9: 'Stage 4 - Lost', 10: 'Stage 5 - Mobilising', 13: 'Stage 6a - Design Completed', 11: 'Stage 1a - Tender Mobilising', 12: 'Stage 1b - Tender Mobilised', 100: ''
    }
}

export function getPrimaryColor() {
    return '#007681'
}

export function getSecondaryColor() {
    return '#EEBE86'
}

export function getGBPFormat(val) {
    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(val)
}

export function getWbsType() {
    return {
        'ohl': 'OHL', 'cabling': 'Cabling', 'sub_struc': 'Substation Primary', 'ohl_civ_struc': 'Substation Secondary', 'sub_civ_struct': 'Substation Civ Structure', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical', 'edc': 'EDC'
    }
}

export function SPICPIConditions() {
    const conditions = [
        {
            "spi": "Equals to 1",
            "cpi": "Equals to 1",
            "status": "On budget - On Schedule",
            "color": '#009900',//green
        },
        {
            "spi": "Less than 1",
            "cpi": "Less than 1",
            "status": "Over budget - behind the Schedule",
            "color": '#cc2900',//red
        },
        {
            "spi": "Greater than 1",
            "cpi": "Greater than 1",
            "status": "Under budget - Ahead of Schedule",
            "color": '#009900',//green
        },
        {
            "spi": "Less than 1",
            "cpi": "Greater than 1",
            "status": "Under budget - behind the Schedule",
            "color": '#e68a00',//amber
        },
        {
            "spi": "Greater than 1",
            "cpi": "Less than 1",
            "status": "Over budget - Ahead of Schedule",
            "color": '#cc2900',//red
        },
        {
            "spi": "Equals to 1",
            "cpi": "Greater than 1",
            "status": "Under budget - On Schedule",
            "color": '#009900',//green
        },
        {
            "spi": "Equals to 1",
            "cpi": "Less than 1",
            "status": "Over budget - On Schedule",
            "color": '#e68a00',//amber
        },
        {
            "spi": "Greater than 1",
            "cpi": "Equals to 1",
            "status": "On budget - Ahead of Schedule",
            "color": '#009900',//green
        },
        {
            "spi": "Less than 1",
            "cpi": "Equals to 1",
            "status": "On budget - behind the Schedule",
            "color": '#e68a00',//amber
        },
    ]
    return conditions
}

export function stringToDate(_date, _format, _delimiter) {
    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
    return formatedDate;
}
