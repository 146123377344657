import { accessReloadOrRefresh } from "./CommonThings";

export function errorMessage(props, error) {
    console.log(error)
    // alert(name)
    if (error && (error.status === 401 || (error.response && error.response.status === 401))) {
        accessReloadOrRefresh()
    } else {
        props.enqueueSnackbar('Something went wrong!', {
            variant: 'error'
        });
    }
}
