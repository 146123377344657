import React, { Component } from 'react'
import { Box, Typography, } from '@material-ui/core'
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading'
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import { getDarkMode, getPrimaryColor } from '../../../Helper/CommonThings';

class AddWBSRoles extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
        }
    }

    componentDidMount() {
        this.setState({ isLoading: false })
    }

    costCalc(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
                const element = this.props.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.hours * element.costin);
                }
            }
        } else {
            for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
                const element = this.props.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.hours * element.costuk);
                }
            }
        }
        return 'null';
    }

    render() {

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <Box p={3}>
                            <MaterialTable
                                key={3}
                                title="Roles"
                                style={{ backgroundColor: getDarkMode() === 'dark' ? '#a39260' : '#AFD6CB' }}
                                columns={[
                                    {
                                        title: 'Roles', field: 'name', editable: 'never',
                                    },
                                    {
                                        title: 'Grade', field: 'grade', editable: 'never',
                                        lookup: { 'a': 'A', 'b': 'B', 'c': 'C', 'd': 'D', 'e': 'E', 'f': 'F', 'g': 'G' },
                                    },
                                    { title: 'Hours', field: 'hours', type: 'numeric' },
                                    {
                                        title: 'Country', field: 'country',
                                        lookup: { 'in': 'India', 'uk': 'UK', 'sl': 'Srilanka' },
                                    },
                                    {
                                        title: 'Amount', field: 'cost', editable: 'never',
                                        render: rowData => <Typography>{this.costCalc(rowData)}</Typography>
                                    },
                                    { title: 'Remarks', field: 'remark', },
                                ]}
                                data={this.props.value.roles.filter(el => el.fm_checked || el.fm_checked === undefined ? this.props.showAll : false)}
                                options={{
                                    paging: false,
                                    headerStyle: {
                                        backgroundColor: getDarkMode() === 'dark' ? '#dac481' : '#AFD6CB',
                                    },
                                    padding: "dense",
                                }}
                            />
                        </Box>
                    </div>
                )}
            </div>

        )
    }
}

export default withSnackbar(AddWBSRoles)