import React, { Component } from 'react'
import MultiProgress from "react-multi-progress";
import { getGreencolor, getOrangecolor, getProgBgColor, getSkyBluecolor } from '../ColorTheme/ColorTheme';

class ProjectStackedProgressbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            actual: 0,
            total: 0,
            assigned: 0
        }
    }
    componentDidMount() {
        this.calcHours()
    }

    calcHours() {
        var converted_assinged = 100 / this.props.total
        var actual = converted_assinged * this.props.actual
        var assigned = converted_assinged * (this.props.assigned - this.props.actual)
        var total = converted_assinged * (this.props.total - this.props.assigned)
        this.setState({ actual: actual, total: total, assigned: assigned })
    }
    render() {
        return (
            <MultiProgress
                transitionTime={1.2}
                elements={[
                    {
                        value: this.state.actual,
                        color: getGreencolor(),
                    },
                    {
                        value: this.state.assigned,
                        color: getOrangecolor(),
                    },
                    {
                        value: this.state.total,
                        color: getSkyBluecolor(),
                    },
                ]}
                height={10}
                backgroundColor={getProgBgColor()}
            />
        )
    }
}

export default ProjectStackedProgressbar