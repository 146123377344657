import MaterialTable from 'material-table'
import React, { PureComponent } from 'react'

class WbsSummary extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            tasks: []
        }
    }

    componentDidMount() {
        this.wbsSummary(JSON.parse(JSON.stringify(this.props.wbsData)))
    }

    wbsSummary(wbsData) {
        var tasks = []
        for (let i = 0; i < wbsData.length; i++) {
            const wbsElement = wbsData[i];
            for (let j = 0; j < wbsElement.tasks.length; j++) {
                const tasksElement = wbsElement.tasks[j];
                if (tasksElement.tableData && tasksElement.tableData.showDetailPanel) {
                    delete tasksElement.tableData.showDetailPanel
                }
                tasksElement.wbs_id = wbsElement.id
                tasksElement.wbs_name = wbsElement.name
                tasksElement.task_name = tasksElement.decs ? tasksElement.decs : tasksElement.name
                for (let k = 0; k < tasksElement.roles.length; k++) {
                    const roleElement = tasksElement.roles[k];
                    if (roleElement.tableData && roleElement.tableData.showDetailPanel) {
                        delete roleElement.tableData.showDetailPanel
                    }
                    if (tasksElement[roleElement.grade.toLowerCase()]) {
                        tasksElement[roleElement.grade.toLowerCase()] += roleElement.hours
                    } else {
                        tasksElement[roleElement.grade.toLowerCase()] = roleElement.hours
                    }
                }
                tasks.push(tasksElement)
            }
        }
        this.setState({
            tasks
        })
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title="Deliverables Package"
                    columns={[
                        // { title: 'WBS ID', field: 'template_pid' },
                        { title: 'WBS', field: 'wbs_name', },

                        {
                            title: 'Task', field: 'task_name', editable: 'never',
                        },
                        {
                            title: 'A', field: 'a', editable: 'never',
                        },
                        {
                            title: 'B', field: 'b', editable: 'never',
                        },
                        {
                            title: 'C', field: 'c', editable: 'never',
                        },
                        {
                            title: 'D', field: 'd', editable: 'never',
                        },
                        {
                            title: 'E', field: 'e', editable: 'never',
                        },
                        {
                            title: 'F', field: 'f', editable: 'never',
                        },
                        {
                            title: 'G', field: 'g', editable: 'never',
                        },
                    ]}
                    data={this.state.tasks}

                    options={{
                        paging: false,
                        padding: "dense",
                        actionsColumnIndex: -1,
                        filtering: true,
                        toolbar: false,
                        headerStyle: { position: "sticky", top: 0 },
                        maxBodyHeight: Math.round(window.screen.height - 200),
                    }}

                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Edit WBS Estimate',
                            onClick: (e, rowData) => { this.props.openWBSEdit(rowData.wbs_id) },
                        },
                    ]}
                />
            </div>
        )
    }
}

export default WbsSummary