import { Box, Tooltip } from '@material-ui/core'
import React, { Component } from 'react'
import StackedBudgetHours from './StackedBudgetHours';
import StackedHoursToDate from './StackedHoursToDate';

class CustomBudgetHours extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: this.props.data
        }
    }

    render() {
        return (
            <div>
                <Box display={'flex'} flexDirection='column' justifyContent={'center'} width={250} padding="5px">
                    <Box display={'flex'} flexDirection='row' alignItems={'center'} width='100%'>
                        <Box fontSize={'10px'} width='20%'>
                            Estimate
                        </Box>
                        <Tooltip title={<div>Budget Hours: {this.props.data.budget_hours}<br />Hours At Completion: {this.props.data.hours_at_completion}<br />Variance Hours: {(this.props.data.hours_at_completion - this.props.data.budget_hours).toFixed(1)}</div>}>
                            <Box display={'flex'} flexDirection='row' justifyContent={'left'} alignItems='center' style={{ cursor: 'pointer' }} marginLeft='5px' width='80%'>
                                <StackedBudgetHours data={this.props.data} />
                            </Box>
                        </Tooltip>
                    </Box>
                    <Box display={'flex'} flexDirection='row' alignItems={'center'} width='100%' marginTop='15px'>
                        <Box fontSize={'10px'} width='20%'>
                            Actual
                        </Box>
                        <Tooltip title={<div>Hours To Date: {this.props.data.hours_to_date}<br />Hours At Completion: {this.props.data.hours_at_completion}</div>}>
                            <Box marginLeft='5px' style={{ cursor: 'pointer' }} width='80%'>
                                <StackedHoursToDate data={this.props.data} />
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </div >
        )
    }
}

export default CustomBudgetHours