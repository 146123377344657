import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import Service from '../../../Networking/networkutils'
import { errorMessage } from '../../../Helper/ErrorMessage';
import { getCookie } from '../../../Helper/CookieHelper';
import { LoopCircleLoading } from 'react-loadingg';
import { Container, Divider, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Row, Col } from 'react-grid';
import ProjectLists from './ProjectLists/ProjectLists';
import ProjectTaskCards from './ProjectTaskCards/ProjectTaskCards';
import { getPrimaryColor } from '../../../Helper/CommonThings';
import SearchIcon from '@mui/icons-material/Search';
import { Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { screenSizeCheck } from '../../ScreensizeCheck/ScreensizeCheck';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));

class ProjectTasks extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
            usersData: [],
            openSearch: false,
            accessToggle: localStorage.getItem('project_tasks_toggle_selection') ? localStorage.getItem('project_tasks_toggle_selection') : 'your_workbook',
        }
        this.child = React.createRef();
        this.projectList = React.createRef();
        this.getProjectId = this.getProjectId.bind(this)
    }

    componentDidMount() {
        this.preFetchData()
    }

    preFetchData() {
        var full_access = 0
        if (localStorage.getItem('project_tasks_toggle_selection') === 'all_workbook') {
            full_access = 1
        }
        this.fetchData(full_access)
    }

    fetchData(full_access) {
        this.setState({ isLoading: true })

        var url = ""

        var typesdata = []

        if (access.access.l4a.l4a_g) {
            typesdata.push("ohl")
        }

        if (access.access.l4a.l4a_h) {
            typesdata.push("cabling")
        }

        if (access.access.l4a.l4a_i) {
            typesdata.push("substation")
        }

        if (access.access.l4a.l4a_m) {
            typesdata.push("edc")
        }

        if (access.access.l4a.l4a_l) {
            typesdata.push("multi_utilities")
        }

        if (access.access.l4a.l4a_af) {
            typesdata.push("technical")
        }

        if (access.access.l4a && access.access.l4a.l4a_d && full_access === 1) {
            url = "/bb/api/tender/project/available_workbook/?filter_type=2"
        } else if (typesdata.length > 0 && full_access === 1) {
            url = "/bb/api/tender/project/available_workbook/?filter_type=1&filter_data=" + typesdata.toString()
        } else {
            url = "/bb/api/tender/project/available_workbook/?filter_type=0"
        }

        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element) {
                        element.is_selected = false
                    }
                }
                this.setState({
                    data: res.data,
                    isLoading: false,
                })
                Service.get(`/bb/api/users/`, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res5 => {
                        this.setState({ usersData: res5.data, })
                        if (this.child && this.child.current) {
                            this.child.current.setUsers(res5.data)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        errorMessage(this.props, error)
                    });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    getProjectId(element) {
        if (this.child && this.child.current) {
            this.child.current.loadData(element, this.state.accessToggle === "all_workbook" ? 'all' : 'limited')
        }
    }

    checkTogglePermission() {

        if (access.access.l4a && access.access.l4a.l4a_d) {
            return true
        }

        var typesdata = []

        if (access.access.l4a.l4a_g) {
            typesdata.push("ohl")
        }

        if (access.access.l4a.l4a_h) {
            typesdata.push("cabling")
        }

        if (access.access.l4a.l4a_i) {
            typesdata.push("substation")
        }

        if (access.access.l4a.l4a_m) {
            typesdata.push("edc")
        }

        if (access.access.l4a.l4a_l) {
            typesdata.push("multi_utilities")
        }

        if (access.access.l4a.l4a_af) {
            typesdata.push("technical")
        }

        if (typesdata.length > 0) {
            return true
        }
    }

    onSearchChange = (event, selectedProject) => {
        this.setState({ openSearch: false });
        this.projectList.current.searchSelection(selectedProject)
        this.getProjectId(selectedProject)
    }

    render() {

        const handleToggle = (event, newFormats) => {
            // alert(newFormats)
            this.setState({ accessToggle: newFormats })
            localStorage.setItem('project_tasks_toggle_selection', newFormats)

            setTimeout(() => {
                if (newFormats === 'all_workbook') {
                    this.fetchData(1)
                } else {
                    this.fetchData(0)
                }
            }, 300);
        };

        const handleSearchClose = () => {
            this.setState({
                openSearch: false
            })
        };

        return (
            <div>
                {
                    this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                        <>
                            <Container maxWidth={false} disableGutters>
                                <Row>
                                    <Col md={screenSizeCheck() ? 4 : 3} >
                                        {(this.checkTogglePermission()) ? (
                                            <>
                                                <ToggleButtonGroup
                                                    color="primary"
                                                    size="small"
                                                    style={{ marginBottom: '3px' }}
                                                    value={this.state.accessToggle}
                                                    onChange={handleToggle}
                                                    exclusive
                                                    aria-label="text formatting"
                                                >
                                                    <ToggleButton value="your_workbook" aria-label="your_workbook">
                                                        Your's
                                                    </ToggleButton>
                                                    <ToggleButton value="all_workbook" aria-label="all_workbook">
                                                        All Workbook
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                                <IconButton aria-label="delete" size="large" onClick={() => this.setState({ openSearch: true })}>
                                                    <SearchIcon fontSize="small" />
                                                </IconButton>
                                                <Divider style={{ marginBottom: '3px' }} />
                                            </>
                                        ) : (null)}
                                        <ProjectLists
                                            ref={this.projectList}
                                            data={this.state.data}
                                            projectId={this.getProjectId}
                                            toggle={this.state.accessToggle}
                                        />
                                    </Col>

                                    <Col md={screenSizeCheck() ? 8 : 9} >
                                        <ProjectTaskCards ref={this.child} maxCards={3} />
                                    </Col>
                                </Row>
                            </Container>
                            <Dialog
                                open={this.state.openSearch}
                                onClose={handleSearchClose}
                            >
                                <DialogTitle>{"Search by Project Name"}</DialogTitle>
                                <DialogContent>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={this.state.data}
                                        style={{ minWidth: 500 }}
                                        onChange={this.onSearchChange}
                                        getOptionLabel={(option) => option.tender_name}
                                        renderInput={(params) => <TextField {...params} label="Select Project" variant="outlined" />}
                                    />
                                </DialogContent>
                            </Dialog>
                        </>
                }
            </div>
        )
    }
}

export default withSnackbar(ProjectTasks)
