import React from 'react'
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';


class CapacityReport extends React.Component {

    constructor() {
        super()
        this.state = {
            columns: [],
            data: [],
            dataLength: 0,
            isLoading: true
        }
    }

    componentDidMount() {
        let filter = this.props.periodType;
        let allRolesSwitch = this.props.allRole;
        let data = this.props.data;
        let demandRoles = this.props.demandRoles
        // console.log('render')

        let week_columns = [
            { title: 'Role', field: 'role', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 } }
        ]
        let month_column = [...week_columns]
        if (filter === 'weeks') {
            for (let i = 0; i < data[0]['week_data'].length; i++) {
                week_columns.push(
                    {
                        title: 'W' + data[0]['week_data'][i]['week_number'],
                        field: data[0]['week_data'][i].start_date,
                        render: (rowData) => rowData[data[0]['week_data'][i].start_date] === 0 ? '' : rowData[data[0]['week_data'][i].start_date]
                    }
                )
            }
        }
        if (filter === 'months') {
            for (let i = 0; i < data[0]['month_data'].length; i++) {
                month_column.push(
                    {
                        title: data[0]['month_data'][i]['name'],
                        field: data[0]['month_data'][i].start_date,
                        render: (rowData) => rowData[data[0]['month_data'][i].start_date] === 0 ? '' : rowData[data[0]['month_data'][i].start_date]
                    }
                )
            }
        }

        let capacityWeekData = [];
        let capacityMonthData = [];

        for (let i = 0; i < data.length; i++) {
            let role = data[i];
            if (allRolesSwitch === false) {
                if (demandRoles.includes(role.id)) {
                    if (filter === 'weeks') {

                        let role_capacity_week = { role: role.name }
                        for (let j = 0; j < role.week_data.length; j++) {
                            role_capacity_week = { ...role_capacity_week, [role.week_data[j].start_date]: (role.week_data[j].capacity % 1 !== 0) ? Number(role.week_data[j].capacity).toFixed(2) : role.week_data[j].capacity }
                        }
                        capacityWeekData.push(role_capacity_week)
                    }


                    if (filter === 'months') {
                        // For demand month
                        let role_capacity_month = { role: role.name }
                        for (let j = 0; j < role.month_data.length; j++) {
                            role_capacity_month = { ...role_capacity_month, [role.month_data[j].start_date]: (role.month_data[j].capacity % 1 !== 0) ? Number(role.month_data[j].capacity).toFixed(2) : role.month_data[j].capacity }
                        }
                        capacityMonthData.push(role_capacity_month)
                    }
                }
            }
            if (allRolesSwitch === true) {
                if (filter === 'weeks') {

                    let role_capacity_week = { role: role.name }
                    for (let j = 0; j < role.week_data.length; j++) {
                        role_capacity_week = { ...role_capacity_week, [role.week_data[j].start_date]: (role.week_data[j].capacity % 1 !== 0) ? Number(role.week_data[j].capacity).toFixed(2) : role.week_data[j].capacity }
                    }
                    capacityWeekData.push(role_capacity_week)
                }


                if (filter === 'months') {
                    // For demand month
                    let role_capacity_month = { role: role.name }
                    for (let j = 0; j < role.month_data.length; j++) {
                        role_capacity_month = { ...role_capacity_month, [role.month_data[j].start_date]: (role.month_data[j].capacity % 1 !== 0) ? Number(role.month_data[j].capacity).toFixed(2) : role.month_data[j].capacity }
                    }
                    capacityMonthData.push(role_capacity_month)
                }
            }


        }
        if (filter === 'months') {
            this.setState({ columns: month_column, data: capacityMonthData, dataLength: capacityMonthData.length, isLoading: true }, () => this.setState({ isLoading: false }))
        }
        if (filter === 'weeks') {
            this.setState({ columns: week_columns, data: capacityWeekData, dataLength: capacityWeekData.length, isLoading: true }, () => this.setState({ isLoading: false }))
        }
    }
    render() {
        return (
            <div>
                {this.state.isLoading === false ?
                    <MaterialTable
                        columns={this.state.columns}
                        data={this.state.data}
                        options={{
                            pageSize: this.props.groupByRolesSwitch ? this.state.dataLength : 20,
                            pageSizeOptions: this.props.groupByRolesSwitch ? [this.state.dataLength] : [20, 50, 100],
                            // fixedColumns: {
                            //     left: 1,
                            //     right: 0
                            // },
                            // filtering: true,
                            toolbar: false,
                            padding: 'dense'
                        }}
                    />
                    : null}
            </div>
        )
    }
}
export default withSnackbar(CapacityReport)