import React from 'react';
import MaterialTable from 'material-table';
import { errorMessage } from '../../Helper/ErrorMessage';
import Service from '../../Networking/networkutils';
import { getCookie } from '../../Helper/CookieHelper';
import { withSnackbar } from 'notistack';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));



const country = { uk: 'UK', in: 'IN/SL' };
const grade = { a: 'A', b: 'B', c: 'C', d: 'D', e: 'E', f: 'F', g: 'G', h: 'H', I: 'I', J: 'J', k: 'K' };

function addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec ? 1 : null
    if (len) {
        return parseFloat(Number(num).toFixed(len))
    } else {
        return parseFloat(Number(num))
    }
}


function timeToDecimal(t) {
    try {
        var arr = t.split(':');
        var dec = parseInt((arr[1] / 6) * 10, 10);

        return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
    } catch (error) {
        return 0
    }

}

class Roles extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            roles: [],
            isLoading: true
        }
    }

    componentDidMount() {
        // console.log(this.props)
        // this.setState({ data: this.props.rowData.role_data })
        this.fetchData()
    }

    fetchData = () => {
        try {

            Service.get('/bb/api/tender/tasks/rolesrevhistory/?tid=' + this.props.task.id, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    // console.log(res.data)
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        element.req_hours = element.hours + element.req_hours
                        element.rev_hours = element.req_hours
                    }
                    this.setState({ roles: res.data }, () => this.taskUserData())

                })
                .catch(e => {
                    console.log(e)
                })

        }
        catch (e) {
            console.log(e)
        }
    }

    taskUserData(newUser) {


        Service.get('/bb/api/tender/user/task/get_user_task/?tid=' + this.props.task.id + '&uid=' + user.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                let currentRoleUsers = res.data;

                var viewPid = []
                for (let i1 = 0; i1 < currentRoleUsers.length; i1++) {
                    const element = currentRoleUsers[i1];
                    viewPid.push({ 'com_ttid': this.props.task.id, 'com_uid': element.user_id })
                }
                if (viewPid.length !== 0) {
                    Service.patch("/bb/api/team/tasks/get_acthours/", {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: viewPid,
                    })
                        .then(res12 => {

                            for (let j = 0; j < res12.data.length; j++) {
                                const element13 = res12.data[j];
                                for (let i = 0; i < currentRoleUsers.length; i++) {
                                    const element12 = currentRoleUsers[i];
                                    if (element12.user_id === element13.id2) {
                                        element12.actual_hours = timeToDecimal(element13.hours_actual)
                                        element12.inprogress_hours = timeToDecimal(element13.hours_ongoing)
                                        element12.total_alloc_hours = element13.allocated

                                    }
                                }
                            }

                            for (let i = 0; i < currentRoleUsers.length; i++) {
                                for (let j = 0; j < currentRoleUsers.length; j++) {
                                    // if (currentRoleUsers[i].user_id === currentRoleUsers[j].user_id) {
                                    currentRoleUsers[i].actualAndInprogressHours = currentRoleUsers[i].actual_hours + currentRoleUsers[i].inprogress_hours
                                    // }
                                }
                                for (let k = 0; k < currentRoleUsers.length; k++) {
                                    if (currentRoleUsers[i].user_id === currentRoleUsers[k].user_id) {
                                        currentRoleUsers[i].balance_hours = currentRoleUsers[i].total_alloc_hours - currentRoleUsers[i].actualAndInprogressHours
                                    }
                                }
                            }

                            let roles = this.state.roles
                            let users = currentRoleUsers
                            let uniqueUsers = []
                            // console.log(roles, users)
                            for (let i = 0; i < users.length; i++) {


                                let role_data = []
                                for (let j = 0; j < roles.length; j++) {
                                    if (users[i].task_role_id === roles[j].id) {
                                        // users[i]['role_data'] = roles[j]
                                        roles[j].planed_hours = users[i].planed_hours
                                        roles[j].planed_id = users[i].id
                                        role_data.push(roles[j])
                                    }
                                }
                                users[i]['role_data'] = role_data
                            }

                            let newArr = []
                            for (let i = 0; i < users.length; i++) {
                                if (uniqueUsers.indexOf(users[i].user_id) === -1) {
                                    uniqueUsers.push(users[i].user_id)
                                    newArr.push(users[i])
                                }
                                else {
                                    let eleIdx = uniqueUsers.indexOf(users[i].user_id);
                                    let ele = newArr[eleIdx];
                                    // console.log(ele, users[i])
                                    let role_data = ele.role_data;
                                    role_data.push(JSON.parse(JSON.stringify(users[i].role_data[0])))
                                }
                            }
                            // console.log(users, 'users')
                            // console.log(newArr, 'newArr')
                            this.setState({ data: newArr[0].role_data, isLoading: false, })
                        })
                        .catch(error => {
                            this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                                variant: 'error'
                            });

                        });
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }


    render() {
        return (<>
            <MaterialTable
                columns={[
                    {
                        title: 'Role', field: 'name', editable: 'never',
                        render: (rowdata) => rowdata.name + ' (' + country[rowdata.country] + ' - ' + grade[rowdata.grade] + ')'
                    },
                    { title: 'Alloc Hrs', field: 'planed_hours' },
                    { title: 'Hrs Comp.', field: 'rev_hours', editable: 'never' },
                ]}
                data={this.state.data}

                isLoading={this.state.isLoading}
                options={{
                    showTitle: false,
                    padding: 'dense',
                    paging: false,
                    toolbar: false,
                    headerStyle: {
                        backgroundColor: '#BDBDBD',
                        // color: '#FFF',
                        fontSize: 'smaller',
                        padding: '10px'
                    },
                    rowStyle: rowData => {
                        return {
                            //   backgroundColor: '#eaeaea'
                            fontSize: 'smaller',
                            padding: '10px'
                        };
                    }
                }}

            // cellEditable={{
            //     onCellEditApproved: (newValue, oldValue, rowData, columnDef) =>
            //         new Promise(resolve => {
            //             if (columnDef.field === 'planed_hours') {
            //                 let newData = { ...rowData, ...{ planed_hours: Number(newValue) } }
            //                 let oldData = { ...rowData, ...{ planed_hours: Number(oldValue) } }
            //                 setTimeout(() => {
            //                     resolve();
            //                     if (oldData) {
            //                         this.setState({ save: false });
            //                         if (newData.planed_hours >= oldData.planed_hours || (oldData.planed_hours - newData.planed_hours) <= (this.props.rowData.total_alloc_hours - (this.props.rowData.actual_hours + this.props.rowData.inprogress_hours))) {
            //                             this.setState(prevState => {
            //                                 const data = [...prevState.data];
            //                                 delete newData['actual_hours']
            //                                 data[data.indexOf(oldData)] = newData;
            //                                 this.putData(newData, oldData, data);
            //                                 return { ...prevState, data };
            //                             });
            //                         } else {
            //                             this.props.enqueueSnackbar("You can only reduce " + (this.props.rowData.total_alloc_hours - (this.props.rowData.actual_hours + this.props.rowData.inprogress_hours)) + " hours for this role.", {
            //                                 variant: 'warning'
            //                             });
            //                         }

            //                     }
            //                 }, 1000);
            //             }
            //         }),
            // }}

            />
        </>)
    }
}

export default withSnackbar(Roles)