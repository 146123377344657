import React, { Component } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid, Box, } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
// import WorkOffIcon from '@material-ui/icons/WorkOff';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Button, CardActionArea, Dialog, Toolbar, Typography, ListItem, ListItemAvatar, Avatar, ListItemText, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Slide from '@material-ui/core/Slide';
import { getCookie } from '../../Helper/CookieHelper';
import Calender from '../../Teams/Calender';
import CloseIcon from '@material-ui/icons/Close';
import Service from '../../Networking/networkutils'
import { errorMessage } from '../../Helper/ErrorMessage';
import LMSLeaveSubmit from '../../LMS/LMSLeaveSubmit';
import TimeSheetDialog from '../../TimeSheet/TimeSheetDialog';
import GroupIcon from '@material-ui/icons/Group';
import { withSnackbar } from 'notistack';
import { screenSizeCheck } from '../ScreensizeCheck/ScreensizeCheck';
import { getPrimaryColor, newTabWithHash, newTabWithPath } from '../../Helper/CommonThings';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EventNoteIcon from '@material-ui/icons/EventNote';
import TimesheetReportTab from '../../Reports/TimesheetReport/TimesheetReportTab';
// import TaskSearch from '../../WorkHours/TaskSearch';
import WorkHours from '../../WorkHours/WorkHours';
import ViewTimeSheets from '../../TimeSheet/ViewTimeSheets';
import ManageTimeSheetMain from '../../Manage/ManageTimeSheetMain';
import SecuredWorks from '../../SecuredWorks/SecuredWorks';
import WebIcon from '@material-ui/icons/Web';
import MainTenderSubTasks from '../../TenderSubTasks/MainTenderSubTasks';
import OutOfWork from '../../WorkHours/OutOfWork/OutOfWork';
// import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
// import { MenuItem, Popover } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AppBar from '@material-ui/core/AppBar';

const token = getCookie("bb_ts_token");

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
});

const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ReportCards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            data: [],
            openCal: false,
            openMyCal: false,
            openTS: false,
            calData: {},
            openWho: false,
            openWho2: false,
            memdata: [],
            editableCal: true,
            tstitle: "Timesheet",
            lmstitle: "Submit Leave",
            calTitle: '',
            openAssign: false,
            allRoles: '',
            allUid: '0',
            assignData: {},
            allroleid: '0',
            isLoadingDum: false,
            useraccess: [],
            preopenTS: false,
            isLoadingFetch: false,
            openTSall: false,
            inTeamMemData: [],
            makeTeamDataDialog: false,
            lmsUid: null,
            openLMSSubmit: false,
            selectedTeam: null,
            openHourRequest: false,
            userPermission: [],
            totalHoursRequestPending: 0,
            changeView1: '',
            openDialogue: false,
            TasksCards: [],
            openReports: false,
            workSearchDialog: false,
            taskFind: false,
            outOfWorkTasks: {},
            teamData: null,
            openworkload: false,
            openmytimesheets: false,
            opentimesheets: false,
            opendelivery: false,
            timesheetOption: this.props.timesheetOption
        }
        this.openMyCal = this.openMyCal.bind(this)
        this.selectTeam = this.selectTeam.bind(this)
        this.openReports = this.openReports.bind(this)
        this.searchClick = this.searchClick.bind(this)
        this.openReportsDialogue = this.openReportsDialogue.bind(this)
        this.getTimesheetOption = this.getTimesheetOption.bind(this)
    }

    componentDidMount() {
        this.sendTeamData()
        if (localStorage.getItem('bookmarked_tab')) {
            var bookmarked_tab = localStorage.getItem('bookmarked_tab')
            if (bookmarked_tab === 'team_tasks') {
                this.setState({ changeView1: 'team_tasks' }, () => {
                    this.getTaskCards()
                })
            } else if (bookmarked_tab === 'project_tasks') {
                this.setState({ changeView1: 'project_tasks' }, () => {
                    this.getTaskCards()
                })
            } else if (bookmarked_tab === 'kpi') {
                this.setState({ changeView1: 'kpi' }, () => {
                    this.getTaskCards()
                })
            } else if (bookmarked_tab === 'hoe_gate6') {
                this.setState({ changeView1: 'hoe_gate6' }, () => {
                    this.getTaskCards()
                })
            }
        } else {
            this.getTaskCards()
        }
    }

    sendTeamData() {
        localStorage.removeItem("staff_to_see")
        var datas = this.props.myTaskTeamData.teams
        var teamsdata = []
        var memeberdata = []
        for (let i = 0; i < datas.length; i++) {
            const element = datas[i];

            if (element.is_active && element.team_role > 0) {
                element.lead = element.team_role
            }
            if (element.is_active && element.team_role !== 3) {
                memeberdata.push(element)
            }
            if (element.is_active || element.team_role === 3) {
                teamsdata.push(element)
            }
        }
        this.setState({
            inTeamMemData: memeberdata,
            data: teamsdata
        })
        // this.getPendingRequest(teamsdata)
    }

    getPendingRequest(teamsdata) {
        Service.get("/bb/api/user/hours/request/pending_count/?lead=" + user.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let i = 0; i < teamsdata.length; i++) {
                    const element = teamsdata[i];
                    element.hours_req_pending = 0
                    for (let j = 0; j < res.data.length; j++) {
                        const element1 = res.data[j];
                        if (element.id === element1.team) {
                            element.hours_req_pending += 1
                        }
                    }
                }
                this.setState({
                    // data: teamsdata,
                    totalHoursRequestPending: res.data.length
                }, () => {
                    this.getTaskCards();
                })
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    changeView(data) {
        if (data === 'my_tasks') {
            this.sendTeamData()
        }
        this.setState({ changeView1: data }, () => {
            this.getTaskCards()
        })
    }

    selectTeam() {
        setTimeout(() => {
            this.getPendingRequest(this.state.data);
            if (this.state.data.length === 1) {
                this.props.openHrsRequest(this.state.data[0])
            } else {
                this.setState({ openDialogue: true })
            }
        }, 0)
    }

    openMyCal() {
        this.setState({ openMyCal: true })
    }

    getTaskCards() {
        if (this.state.changeView1 === 'team_tasks' || this.state.changeView1 === 'project_tasks' || this.state.changeView1 === 'hoe_gate6' || this.state.changeView1 === 'kpi') {
            var TeamTaskItems = []
            if (access.access.l0a && access.access.l0a.l0a_b) {
                TeamTaskItems.push(<Tenders />)
            }

            if (access.access.l4a && access.access.l4a.l4a_a) {
                TeamTaskItems.push(<Delivery badgecount={this.state.teamData ? this.state.teamData.pending_tender_request : null} openDialogue={this.openReportsDialogue} />)
            }

            if (access.access.l2a && access.access.l2a.l2a_a) {
                TeamTaskItems.push(<Timesheets badgecount={this.state.teamData ? this.state.teamData.pending_timesheet_request : null} openDialogue={this.openReportsDialogue} />)
            }

            TeamTaskItems.push(<HoursRequest badgecount={this.state.teamData ? this.state.teamData.pending_hours_request : null} selectTeam={this.selectTeam} searchClick={this.searchClick} />)

            if (access.access.l15a && access.access.l15a.l15a_a) {
                TeamTaskItems.push(<Reports />)
            } else {
                TeamTaskItems.push(<TeamReports openReports={this.openReports} />)
            }

            if (TeamTaskItems.length < 5) {
                if (TeamTaskItems.length === 4) {
                    TeamTaskItems.splice(0, 0, <MyCalender openMyCal={this.openMyCal} />)
                } else if (TeamTaskItems.length === 3) {
                    TeamTaskItems.splice(0, 0, <MyCalender openMyCal={this.openMyCal} />, <MyTimesheets openDialogue={this.openReportsDialogue} />)
                }
                this.setState({ TasksCards: TeamTaskItems })
            } else {
                this.setState({ TasksCards: TeamTaskItems })
            }

        } else {
            var MyTaskItems = []
            if (access.access.l0a && access.access.l0a.l0a_a) {
                MyTaskItems.push(<MyCalender openMyCal={this.openMyCal} />, <Workload openDialogue={this.openReportsDialogue} />, <MyTimesheets openDialogue={this.openReportsDialogue} />, <HoursRequest badgecount={this.state.teamData ? this.state.teamData.pending_hours_request : null} selectTeam={this.selectTeam} searchClick={this.searchClick} />, <MyPlanner openDialogue={this.openReportsDialogue} badgecount={this.state.teamData ? this.state.teamData.pending_subtask : null} />)
            }
            this.setState({ TasksCards: MyTaskItems })
        }
    }

    openReports() {
        this.setState({ openReports: true })
    }

    searchClick() {
        this.setState({ workSearchDialog: true });
        // if (this.state.taskFind === true) return;

        // this.setState({ taskFind: true })
        // Service.get("/bb/api/user/report/view/out_of_work/?uid=" + user.id,
        //     {
        //         headers: {
        //             Authorization: "Token " + token,
        //         },
        //     }).then(res => {
        //         this.setState({ outOfWorkTasks: res.data, taskFind: false }, () => {
        //             this.setState({ workSearchDialog: true });
        //         })
        //     }).catch(e => {
        //         this.props.enqueueSnackbar('Unable to fetch tasks. Please contact Admin', {
        //             variant: 'error'
        //         });
        //         this.setState({ taskFind: false })
        //     })
    }

    getBadgeCount(data) {
        this.setState({ teamData: data }, () => {
            this.getTaskCards()
        })
    }

    openReportsDialogue(type) {
        if (type === 'Workload') {
            this.setState({ openworkload: true })
        } else if (type === 'My Timesheets') {
            this.setState({ openmytimesheets: true })
        } else if (type === 'Your Portfolio') {
            this.setState({ opentimesheets: true })
        } else if (type === 'Available Workbook') {
            this.setState({ opendelivery: true })
        } else if (type === 'MyPlanner') {
            this.setState({ openmyplanner: true })
        }
    }

    getTimesheetOption(value) {
        this.setState({ timesheetOption: value })
    }

    render() {
        const { classes } = this.props;

        const handleMyCalClose = () => {
            // this.props.refreshChart();
            this.props.requestCountRefresh(null)
            this.setState({ openMyCal: false, }, () => {
                this.props.getUpdatedData('continue', 1);
            })
        };

        const handleLMSSubmitClose = () => {
            this.props.requestCountRefresh(null)
            this.setState({ openLMSSubmit: false, })
        };

        const handleTSallClose = () => {
            this.setState({ openTSall: false, })
        };

        const handleDialogueClose = () => {
            this.props.requestCountRefresh(null)
            this.setState({ openDialogue: false })
        };

        const handleReportsClose = () => {
            this.props.requestCountRefresh(null)
            this.setState({ openReports: false, })
        };

        const workSearchDialogClose = () => {
            this.setState({ workSearchDialog: false, })
        };

        const closeWorkload = () => {
            this.props.requestCountRefresh(null)
            this.setState({ openworkload: false, })
        };

        const closeMyTimesheets = () => {
            this.props.requestCountRefresh(null)
            this.setState({ openmytimesheets: false, })
        };

        const closeDelivery = () => {
            this.props.requestCountRefresh(null)
            this.setState({ opendelivery: false, })
        };

        const closeTimesheets = () => {
            this.props.requestCountRefresh(null)
            this.setState({ opentimesheets: false, })
        };

        const closeMyPlanner = () => {
            this.props.requestCountRefresh(null)
            this.setState({ openmyplanner: false, }, () => {
                this.props.getUpdatedData('continue', 1);
            })
        };

        return (
            <div style={{ width: '100%' }}>

                <Grid container spacing={2} style={{ marginTop: '10px' }} direction='row'>
                    {this.state.TasksCards.map((value, i) =>
                        <Grid item xs key={i}>
                            {value}
                        </Grid>
                    )}
                </Grid>

                <Dialog fullScreen open={this.state.openMyCal} onClose={handleMyCalClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar} position='relative'>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleMyCalClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                My Calendar
                            </Typography>
                            {(access && access.access && access.access.l18a && access.access.l18a.l18a_a) ? (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    startIcon={<ListAltIcon />}
                                    style={{ marginRight: '5px' }}
                                    onClick={() => this.setState({ openLMSSubmit: true, lmsUid: user.id, lmstitle: "Submit Leave" })}
                                >
                                    Submit Leave
                                </Button>
                            ) : (null)}
                            {(access && access.access && access.access.l1a && access.access.l1a.l1a_c) ? (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    startIcon={<ListAltIcon />}
                                    onClick={() => this.setState({ openTSall: true, })}
                                >
                                    Timesheet
                                </Button>
                            ) : (null)}
                        </Toolbar>
                    </AppBar>
                    {(!this.state.openLMSSubmit) && (!this.state.openTSall) ?
                        <Calender
                            showTimesheetSetting={true}
                            timesheetOption={this.state.timesheetOption}
                            ref={this.leaveRefresh}
                            value={null}
                            myteams={this.state.inTeamMemData}
                            openPrevWeek={!this.props.myTaskTeamData.timesheet_submitted_status}
                            getTimesheetOption={this.getTimesheetOption}
                        />
                        : null}
                </Dialog>

                <Dialog fullScreen open={this.state.openLMSSubmit} onClose={handleLMSSubmitClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar} position='relative'>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleLMSSubmitClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {this.state.lmstitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <LMSLeaveSubmit uid={this.state.lmsUid} />
                </Dialog>

                <Dialog fullScreen open={this.state.openTSall} onClose={handleTSallClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar} position='relative'>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleTSallClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {this.state.tstitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <TimeSheetDialog timesheetOption={this.state.timesheetOption} value={null} useraccess={this.state.useraccess} access={access} openPrevWeek={!this.props.myTaskTeamData.timesheet_submitted_status} />
                </Dialog>

                <Dialog fullWidth={true} maxWidth='sm' onClose={handleDialogueClose} aria-labelledby="customized-dialog-title" open={this.state.openDialogue} TransitionComponent={Transition}>
                    <DialogTitle id="customized-dialog-title" onClose={handleDialogueClose}>
                        Team List
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box flexDirection="column" display="flex" marginLeft='10px' alignItems={'center'} >
                            {this.state.data.map((value, i) =>
                                <ListItem button key={i} onClick={() => { this.props.openHrsRequest(value); this.setState({ openDialogue: false }) }} >
                                    <ListItemAvatar>
                                        <Badge badgeContent={value.hours_req_pending} color="error">
                                            <Avatar >
                                                <GroupIcon />
                                            </Avatar>
                                        </Badge>
                                    </ListItemAvatar>
                                    <ListItemText primary={value.name} />
                                </ListItem>
                            )}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Box display="flex" justifyContent="center" marginTop="5px" marginBottom="10px">
                            <Button style={{ marginLeft: '5px', marginRight: '5px', color: getPrimaryColor() }} onClick={handleDialogueClose}>close</Button>
                        </Box>
                    </DialogActions>
                </Dialog>

                <Dialog fullScreen onClose={handleReportsClose} aria-labelledby="customized-dialog-title" open={this.state.openReports} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar} position='relative'>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleReportsClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Team Reports
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <TimesheetReportTab uid={user.id} companyFilter={0}/>
                    </DialogContent>
                </Dialog>

                <Dialog
                    fullScreen
                    open={this.state.workSearchDialog}
                    onClose={workSearchDialogClose}
                    TransitionComponent={Transition}>
                    <AppBar position='relative' color='primary'>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={workSearchDialogClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                Work Search
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <OutOfWork uid={user.id} />
                    {/* <TaskSearch data={this.state.outOfWorkTasks} /> */}
                </Dialog>

                <Dialog fullScreen open={this.state.openworkload} onClose={closeWorkload} TransitionComponent={Transition}>
                    <AppBar position='relative'>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={closeWorkload} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                Workload
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <WorkHours />
                </Dialog>

                <Dialog fullScreen open={this.state.openmytimesheets} onClose={closeMyTimesheets} TransitionComponent={Transition}>
                    <AppBar position='relative'>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={closeMyTimesheets} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                My Timesheets
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <ViewTimeSheets />
                </Dialog>

                <Dialog fullScreen open={this.state.opentimesheets} onClose={closeTimesheets} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar} position='relative'>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={closeTimesheets} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Timesheets
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <ManageTimeSheetMain reportCards={true} />
                </Dialog>

                <Dialog fullScreen open={this.state.opendelivery} onClose={closeDelivery} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar} position='relative'>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={closeDelivery} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Delivery
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <SecuredWorks />
                </Dialog>

                <Dialog fullScreen open={this.state.openmyplanner} onClose={closeMyPlanner} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar} position='relative'>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={closeMyPlanner} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                MyPlanner
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <MainTenderSubTasks user_id={user.id} team_lead={this.props.myTaskTeamData} />
                </Dialog>

            </div >
        )
    }
}

export default withStyles(useStyles)(withSnackbar(ReportCards))

function MyCalender(props) {
    return <Grid item xs>
        <Card elevation={10} style={{ background: getPrimaryColor(), borderRadius: '30px 30px 30px 30px', color: 'white' }}>
            <CardActionArea onClick={() => props.openMyCal()}>
                <CardContent>
                    <Box display={'flex'} justifyContent="left" flexDirection="row" alignItems="center" >
                        <Box flexDirection="row" display="flex" justifyContent="center" marginLeft='10px' marginRight={'10px'}>
                            <EventNoteIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />
                        </Box>
                        <Typography variant={screenSizeCheck() ? 'h6' : 'h5'}>
                            MyCalendar
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    </Grid>
}

function Workload(props) {
    return <Grid item xs>
        <Card elevation={10} style={{ background: getPrimaryColor(), borderRadius: '30px 30px 30px 30px', color: 'white' }}>
            <CardActionArea onMouseUp={() => {
                if (window.event.which === 1) {
                    props.openDialogue('Workload')
                } else {
                    newTabWithHash('Workload Report')
                }
            }}>
                <CardContent>
                    <Box display={'flex'} justifyContent="left" flexDirection="row" alignItems="center" >
                        <Box flexDirection="row" display="flex" justifyContent="center" marginLeft='10px' marginRight={'10px'}>
                            <WatchLaterIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />
                        </Box>
                        <Typography variant={screenSizeCheck() ? 'h6' : 'h5'}>
                            Workload
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    </Grid>
}

function MyTimesheets(props) {
    return <Grid item xs>
        <Card elevation={10} style={{ background: getPrimaryColor(), borderRadius: '30px 30px 30px 30px', color: 'white' }}>
            <CardActionArea onMouseUp={() => {
                if (window.event.which === 1) {
                    props.openDialogue('My Timesheets')
                } else {
                    newTabWithHash('My Timesheets')
                }
            }}>
                <CardContent>
                    <Box display={'flex'} justifyContent="left" flexDirection="row" alignItems="center" >
                        <Box flexDirection="row" display="flex" justifyContent="center" marginLeft='10px' marginRight={'10px'}>
                            <ListAltIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />
                        </Box>
                        <Typography variant={screenSizeCheck() ? 'h6' : 'h5'}>
                            My Timesheets
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    </Grid>
}

function HoursRequest(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    };

    function handleClose() {
        setTimeout(() => {
            setAnchorEl(null);
        }, 0);
    };

    function openRequest() {
        setTimeout(() => {
            if (props.selectTeam) {
                props.selectTeam()
            }
        }, 5);
        handleClose()
    }

    function outOfWork() {
        setTimeout(() => {
            if (props.searchClick) {
                props.searchClick()
            }
        }, 5);
        handleClose()
    }


    return (
        <Grid item xs>
            {/* <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => ( */}
            <Card elevation={10} style={{ background: getPrimaryColor(), borderRadius: '30px 30px 30px 30px', color: 'white' }}>
                <CardActionArea onClick={handleClick}>
                    <CardContent>
                        <Box display={'flex'} justifyContent="left" flexDirection="row" alignItems="center" >
                            <Box justifyContent="center" marginLeft='10px' marginRight={'10px'}>
                                <Badge anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }} badgeContent={props.badgecount} color="secondary">
                                    <PostAddIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />
                                </Badge>

                            </Box>
                            <Box sx={{ flexGrow: 1 }} justifyContent="center">
                                <Typography variant={screenSizeCheck() ? 'h6' : 'h5'}> Hours Request</Typography>
                            </Box>
                        </Box>
                        {/* <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={() => handleClose()}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >
                            <MenuItem onClick={() => openRequest()}>Hours Request</MenuItem>
                            <MenuItem onClick={() => outOfWork()}>Hours Request (Unallocated tasks)</MenuItem>
                        </Menu> */}
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={Boolean(anchorEl)}
                            onClose={() => handleClose()}
                            onClick={() => handleClose()}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 7,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={() => openRequest()}>Allocated Tasks</MenuItem>
                            <MenuItem onClick={() => outOfWork()}>Unallocated Tasks</MenuItem>
                        </Menu>
                    </CardContent>
                </CardActionArea>
            </Card>
            {/* )}
        </PopupState> */}

        </Grid >
    )
}

// class OutofWork extends Component {
//     constructor(props) {
//         super(props)

//         this.state = {
//             taskFind: false
//         }
//     }
//     setTaskFind = (value) => {
//         this.setState({ taskFind: value })
//     }

//     render() {

//         return (
//             <Grid item xs>
//                 <Card elevation={10} style={{ background: getPrimaryColor(), borderRadius: '30px 30px 30px 30px', color: 'white' }}>
//                     <CardActionArea onClick={() => { this.props.openDialogue(); this.setTaskFind(true) }}>
//                         <CardContent>
//                             <Box display={'flex'} justifyContent="left" flexDirection="row" alignItems="center" >
//                                 <Box flexDirection="row" display="flex" justifyContent="center" marginLeft='10px' marginRight={'10px'}>
//                                     <WorkOffIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />
//                                 </Box>
//                                 <Typography variant={screenSizeCheck() ? 'h6' : 'h5'}>
//                                     {this.state.taskFind ? "Please Wait..." : "Out Of Work"}
//                                 </Typography>
//                             </Box>
//                         </CardContent>
//                     </CardActionArea>
//                 </Card>
//             </Grid>
//         )
//     }
// }

function Tenders() {
    return <Grid item xs>
        <Card elevation={10} style={{ background: getPrimaryColor(), borderRadius: '30px 30px 30px 30px', color: 'white', cursor: 'pointer' }}>
            <CardActionArea onMouseUp={() => newTabWithPath('/estimate')}>
                <CardContent>
                    <Box display={'flex'} justifyContent="left" flexDirection="row" alignItems="center" >
                        <Box flexDirection="row" display="flex" justifyContent="center" marginLeft='10px' marginRight={'10px'}>
                            <ExitToAppIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />
                        </Box>
                        <Typography variant={screenSizeCheck() ? 'h6' : 'h5'}>
                            Tenders
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>

        </Card>
    </Grid>
}

function Delivery(props) {
    return <Grid item xs>
        <Card elevation={10} style={{ background: getPrimaryColor(), borderRadius: '30px 30px 30px 30px', color: 'white' }}>
            <CardActionArea onMouseUp={() => {
                if (window.event.which === 1) {
                    props.openDialogue('Available Workbook')
                } else {
                    newTabWithHash('Available Workbook')
                }
            }} >
                <CardContent>
                    <Box display={'flex'} justifyContent="left" flexDirection="row" alignItems="center" >
                        <Box flexDirection="row" display="flex" justifyContent="center" marginLeft='10px' marginRight={'10px'}>
                            <Badge anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }} badgeContent={props.badgecount} color="secondary">
                                <MeetingRoomIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />
                            </Badge>
                        </Box>
                        <Typography variant={screenSizeCheck() ? 'h6' : 'h5'}>
                            Delivery
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    </Grid>
}

function Timesheets(props) {
    return <Grid item xs>
        <Card elevation={10} style={{ background: getPrimaryColor(), borderRadius: '30px 30px 30px 30px', color: 'white' }}>
            <CardActionArea onMouseUp={() => {
                if (window.event.which === 1) {
                    props.openDialogue('Your Portfolio')
                } else {
                    newTabWithHash('Your Portfolio')
                }
            }} >
                <CardContent>
                    <Box display={'flex'} justifyContent="left" flexDirection="row" alignItems="center" >
                        <Box flexDirection="row" display="flex" justifyContent="center" marginLeft='10px' marginRight={'10px'}>
                            <Badge anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }} badgeContent={props.badgecount} color="secondary">
                                <ReceiptIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />
                            </Badge>
                        </Box>
                        <Typography variant={screenSizeCheck() ? 'h6' : 'h5'}>
                            Timesheets
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    </Grid>
}

function Reports() {
    return <Grid item xs>
        <Card elevation={10} style={{ background: getPrimaryColor(), borderRadius: '30px 30px 30px 30px', color: 'white' }}>
            <CardActionArea onMouseUp={() => newTabWithPath('/reports')}>
                <CardContent>
                    <Box display={'flex'} justifyContent="left" flexDirection="row" alignItems="center" >
                        <Box flexDirection="row" display="flex" justifyContent="center" marginLeft='10px' marginRight={'10px'}>
                            <AssessmentIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />
                        </Box>
                        <Typography variant={screenSizeCheck() ? 'h6' : 'h5'}>
                            Reports
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    </Grid>
}

function TeamReports(props) {
    return <Grid item xs>
        <Card elevation={10} style={{ background: getPrimaryColor(), borderRadius: '30px 30px 30px 30px', color: 'white' }}>
            <CardActionArea onClick={() => props.openReports()}>
                <CardContent>
                    <Box display={'flex'} justifyContent="left" flexDirection="row" alignItems="center" >
                        <Box flexDirection="row" display="flex" justifyContent="center" marginLeft='10px' marginRight={'10px'}>
                            <AssessmentIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />
                        </Box>
                        <Typography variant={screenSizeCheck() ? 'h6' : 'h5'}>
                            Team Reports
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    </Grid>
}

function MyPlanner(props) {
    return (
        <Grid item xs>
            <Card elevation={10} style={{ background: getPrimaryColor(), borderRadius: '30px 30px 30px 30px', color: 'white' }}>
                <CardActionArea onClick={() => { props.openDialogue('MyPlanner') }}>
                    <CardContent>
                        <Box display={'flex'} justifyContent="left" flexDirection="row" alignItems="center" >
                            <Box flexDirection="row" display="flex" justifyContent="center" marginLeft='10px' marginRight={'10px'}>
                                <Badge anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }} badgeContent={props.badgecount} color="secondary">
                                    <WebIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />
                                </Badge>
                            </Box>
                            <Typography variant={screenSizeCheck() ? 'h6' : 'h5'}>
                                MyPlanner
                            </Typography>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}



