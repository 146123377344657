
import React, { Component } from 'react';
import Service from "../Networking/networkutils";
import moment from 'moment'
import { getCookie } from '../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { LoopCircleLoading } from 'react-loadingg';
import { errorMessage } from '../Helper/ErrorMessage';
import { getDarkMode, getPrimaryColor } from '../Helper/CommonThings';

const token = getCookie("bb_ts_token");
var currweek = [];
// var autocompdata = '';
// const user = JSON.parse(getCookie("bb_ts_user"));

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#00b300',

        },
        secondary: {
            main: '#cca300',
        },
        error: {
            main: "#ff0000",
        },
        type: getDarkMode(),
    }
})

class TeamSummary extends Component {

    constructor(props) {
        super()

        this.state = {
            data: [],
            originalData: [],
            isLoading: true,
            toDia: [],
            teamMem: [],
        }
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        Service.get("/bb/api/team/access/?gid=" + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res3 => {

                this.setState({
                    teamMem: res3.data
                })

                var uid1 = '0'
                var gids = '0'

                for (let i = 0; i < res3.data.length; i++) {
                    const element = res3.data[i];
                    uid1 = uid1 + "," + element.uid;
                }

                var start_date1 = moment.utc().add(1, 'days').startOf('isoWeek').subtract(1, 'days');
                var end_date1 = moment.utc().add(1, 'days').endOf('isoWeek').subtract(1, 'days');
                Service.get("/bb/api/team/tasks/?uid=" + uid1 + "&start__gte=" + start_date1.format('YYYY-MM-DD HH:mm:ss') + "&start__lte=" + end_date1.format('YYYY-MM-DD HH:mm:ss'), {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res => {
                        var datapull = [];
                        // console.log(res.data)
                        for (let i = 0; i < res.data.length; i++) {
                            const element = res.data[i];
                            var date = moment(element.start).format('DD/MM/YYYY')
                            var stime = moment(element.start).format('HH mm A');
                            var etime = moment(element.end).format('HH mm A');
                            var whours = moment.duration(moment(element.end).diff(moment(element.start)));
                            var whours1 = ''
                            //console.log(whours.asHours())
                            if (parseFloat(whours.asHours()) < 0.9) {
                                whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                            } else {
                                whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                            }

                            // var status = '';
                            // if (element.is_approved === 1) {
                            //     status = "[Approved]"
                            // } else if (element.is_approved === 2) {
                            //     status = "[Rejected]"
                            // } else if (element.is_approved === 0 && element.is_submitted === 1) {
                            //     status = "[Submited]"
                            // }
                            if (element.is_tender === 1) {
                                datapull.push({
                                    id: element.id,
                                    uid: element.uid,
                                    gid: element.gid,
                                    tid: element.tid,
                                    pid: element.pid,
                                    ttid: element.ttid,
                                    tpid: element.tpid,
                                    twbsid: element.twbsid,
                                    trolesid: element.trolesid,
                                    tmapid: element.tmapid,
                                    uemail: element.uemail,
                                    uname: element.uname_last + ", " + element.uname_first,
                                    gname: element.gname,
                                    tname: element.tname,
                                    tproj_name: element.tproj_name,
                                    ttname: element.ttname,
                                    tuemail: element.tuemail,
                                    twbsname: element.twbsname,
                                    trolename: element.trolename,
                                    ttender_name: element.ttender_name,
                                    title: element.tproj_name + "(" + element.ttname + " - " + element.ttdecs + ")",
                                    proj_name: element.proj_name,
                                    work_detail: element.work_detail,
                                    comments: element.comments,
                                    date: date,
                                    start: new Date(element.start),
                                    end: new Date(element.end),
                                    is_locked: element.is_locked,
                                    is_submitted: element.is_submitted,
                                    is_approved: element.is_approved,
                                    strstart: element.start,
                                    strend: element.end,
                                    stime: stime,
                                    etime: etime,
                                    estimated_hours: element.estimated_hours,
                                    created_by: element.created_by,
                                    whours: whours1,
                                    [date]: whours1,
                                    actual_hours: element.actual_hours,
                                    is_tender: element.is_tender,
                                });
                            } else if (element.is_tender === 0) {
                                datapull.push({
                                    id: element.id,
                                    uid: element.uid,
                                    gid: element.gid,
                                    tid: element.tid,
                                    pid: element.pid,
                                    ttid: element.ttid,
                                    tpid: element.tpid,
                                    twbsid: element.twbsid,
                                    trolesid: element.trolesid,
                                    tmapid: element.tmapid,
                                    uemail: element.uemail,
                                    uname: element.uname_last + ", " + element.uname_first,
                                    gname: element.gname,
                                    tname: element.tname,
                                    tproj_name: element.tproj_name,
                                    ttname: element.ttname,
                                    tuemail: element.tuemail,
                                    twbsname: element.twbsname,
                                    trolename: element.trolename,
                                    ttender_name: element.ttender_name,
                                    title: element.proj_name + "(" + element.tname + ")",
                                    proj_name: element.proj_name,
                                    work_detail: element.work_detail,
                                    comments: element.comments,
                                    date: date,
                                    start: new Date(element.start),
                                    end: new Date(element.end),
                                    is_locked: element.is_locked,
                                    is_submitted: element.is_submitted,
                                    is_approved: element.is_approved,
                                    strstart: element.start,
                                    strend: element.end,
                                    stime: stime,
                                    etime: etime,
                                    estimated_hours: element.estimated_hours,
                                    created_by: element.created_by,
                                    whours: whours1,
                                    [date]: whours1,
                                    actual_hours: element.actual_hours,
                                    is_tender: element.is_tender,
                                });
                            }
                            gids = gids + "," + element.gid
                        }
                        this.setState({ data: datapull, originalData: res.data }, () => {
                            this.handleColumns(moment().add(1, 'days').startOf('isoWeek'), moment().add(1, 'days').endOf('isoWeek'))
                        })
                    })
                    .catch(error => {
                        console.log(error)
                        errorMessage(this.props, error)
                    });

            })
            .catch(error => {
                // console.log(error)
               errorMessage(this.props, error)
            });

    }

    handleColumns(stweek1, endweek1) {
        currweek = [];
        var date = stweek1.subtract(1, 'days');

        var date1 = stweek1.format('DD/MM/YYYY');
        var date2 = endweek1.subtract(1, 'days');
        currweek.push(moment(stweek1));
        currweek.push(moment(date2));
        var columns = [
            { title: 'Member Name', field: 'uname', defaultSort: 'asc', headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }, },
            {

                title: "Team Total", field: 'total', filtering: false,
            },
            // { title: 'Projects', field: 'proj_name' },
            // { title: 'Tasks', field: 'tname' },
        ];
        //console.log(this.state.dupCol)
        while (date2 >= date) {

            const ccc = date1;
            columns.push(
                {
                    title: date.format("DD MMM (ddd)"), field: date1,
                    render: rowData => <ThemeProvider theme={bbColorTheme}><Typography color={(parseInt(rowData[ccc]) < 4) ? ("error") : (parseInt(rowData[ccc]) > 7) ? ("primary") : ("secondary")} >{rowData[ccc]}</Typography></ThemeProvider>
                }
            )
            date1 = date.add(1, 'days').format('DD/MM/YYYY')

        }

        columns.push(

            {

                title: "Other Team Total", field: 'total_other', filtering: false,
            }
        )

        var statedata = this.state.data;
        var doneuid = [];
        var toTData = [];
        var userelement = [];

        for (let i = 0; i < statedata.length; i++) {
            var userelement2 = [];
            const element = statedata[i];

            if (i === 0) {
                doneuid.push(element.uid);
                for (let j = 0; j < statedata.length; j++) {
                    const element1 = statedata[j];
                    if (element.uid === element1.uid) {

                        userelement2.push(element1)
                    }
                }
            } else {
                var goodtogo = true;
                for (let k = 0; k < doneuid.length; k++) {
                    const element3 = doneuid[k];

                    if (element3 === element.uid) {
                        goodtogo = false;
                    }
                }

                if (goodtogo) {
                    doneuid.push(element.uid);
                    for (let j = 0; j < statedata.length; j++) {
                        const element1 = statedata[j];
                        if (element.uid === element1.uid) {

                            userelement2.push(element1)
                        }

                    }
                }
            }

            if (userelement2.length !== 0) {
                userelement.push(userelement2)
            }

        }

        //date
        for (let i = 0; i < userelement.length; i++) {
            var whdate = [];
            var wh = [];
            var wh2 = [];
            var teams = [];
            var proj = [];
            var tasks = [];
            const element = userelement[i];
            for (let j = 0; j < element.length; j++) {
                const element1 = element[j];
                var goodtogo1 = true;
                for (let k = 0; k < whdate.length; k++) {
                    const element2 = whdate[k];
                    if (element2 === element1.date) {
                        goodtogo1 = false;
                    }

                }

                if (goodtogo1) {
                    // console.log('Total 111 time is')
                    whdate.push(element1.date);
                    var hours = []
                    var hours2 = []
                    for (let m = 0; m < element.length; m++) {
                        const element3 = element[m];
                        if (element3.date === element1.date && element3.gid === this.props.value.id) {
                            hours.push(element3[element3.date])
                        }

                        if (element3.date === element1.date && element3.gid !== this.props.value.id) {
                            hours2.push(element3[element3.date])
                        }

                    }
                    // console.log(hours)
                    const totalDurations = hours.slice(1)
                        .reduce((prev, cur) => moment.duration(cur).add(prev),
                            moment.duration(hours[0]))
                    wh.push(moment.utc(totalDurations.asMilliseconds()).format("HH:mm"))
                    const totalDurations2 = hours2.slice(1)
                        .reduce((prev, cur) => moment.duration(cur).add(prev),
                            moment.duration(hours2[0]))
                    wh2.push(moment.utc(totalDurations2.asMilliseconds()).format("HH:mm"))
                    // console.log(wh)
                }

            }

            // console.log(whdate)
            var jsoncons = {
                id: element[0].id,
                uid: element[0].uid,
                gid: element[0].gid,
                pid: element[0].pid,
                tid: element[0].tid,
                ttid: element[0].ttid,
                tpid: element[0].tpid,
                twbsid: element[0].twbsid,
                trolesid: element[0].trolesid,
                tmapid: element[0].tmapid,
                tname: tasks.join(),
                tproj_name: element[0].tproj_name,
                ttname: element[0].ttname,
                tuemail: element[0].tuemail,
                twbsname: element[0].twbsname,
                trolename: element[0].trolename,
                uemail: element[0].uemail,
                uname: element[0].uname,
                gname: teams.join(),
                title: element[0].title,
                proj_name: proj.join(),
                work_detail: element[0].work_detail,
                start: element[0].start,
                end: element[0].end,
                date: element[0].date,
                stime: element[0].stime,
                etime: element[0].etime,
                whours: element[0].whours,
                created_by: element[0].created_by,
                is_tender: element.is_tender,
            }
            for (let k = 0; k < whdate.length; k++) {
                const element = whdate[k];
                jsoncons[element] = wh[k];
            }

            const totalDurations11 = wh.slice(1)
                .reduce((prev, cur) => moment.duration(cur).add(prev),
                    moment.duration(wh[0]));

            const totalDurations112 = wh2.slice(1)
                .reduce((prev, cur) => moment.duration(cur).add(prev),
                    moment.duration(wh2[0]));

            var minin = (moment.duration(totalDurations11.asMilliseconds()));
            var minin2 = moment.utc(totalDurations11.asMilliseconds()).format("mm")
            // console.log(minin2)
            jsoncons["total"] = ((parseInt(minin.asHours()) > 9) ? (parseInt(minin.asHours())) : ("0" + parseInt(minin.asHours()))) + ":" + minin2;

            var minin12 = (moment.duration(totalDurations112.asMilliseconds()));
            var minin22 = moment.utc(totalDurations112.asMilliseconds()).format("mm")
            jsoncons["total_other"] = ((parseInt(minin12.asHours()) > 9) ? (parseInt(minin12.asHours())) : ("0" + parseInt(minin12.asHours()))) + ":" + minin22;

            toTData.push(jsoncons);
        }

        for (let i = 0; i < this.state.teamMem.length; i++) {
            const element = this.state.teamMem[i];
            var goon = true
            for (let j = 0; j < toTData.length; j++) {
                const element1 = toTData[j];
                if (element.uid === element1.uid) {
                    goon = false
                }
            }
            if (goon) {
                toTData.push({
                    uid: element.uid,
                    uname: element.uname_last + ", " + element.uname_first
                })
            }
        }

        var todata = [];
        todata.push(columns)
        todata.push(toTData)
        // console.log(todata)
        this.setState({
            isLoading: false,
            isLoadingTable: false,
            toDia: todata,
        })

    }

    render() {
        return (
            <div>


                {(this.state.isLoading) ? (<LoopCircleLoading color={getPrimaryColor()} />) : (
                    <MaterialTable
                        title={this.props.value.name + "'s Summary"}
                        style={{ backgroundColor: '#D8E1B8' }}
                        columns={this.state.toDia[0]}
                        data={this.state.toDia[1]}
                        isLoading={this.state.isLoading}
                        options={{
                            padding: "dense",
                            search: false,
                            filtering: false,
                            // exportButton: { csv: true },
                            headerStyle: {
                                position: "sticky", top: 0,
                                backgroundColor: '#D8E1B8',
                            },
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            // exportPdf: () => alert('This feature is disabled. Data can only be exported as a CSV.')
                        }}
                    />
                )}
            </div>
        )
    }
}

export default withSnackbar(TeamSummary)