import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core';
import Service from "../../Networking/networkutils";
import { errorMessage } from '../../Helper/ErrorMessage';
import {
    Button,
    Grid,
    Checkbox,
    TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { getCookie } from '../../Helper/CookieHelper';
import { LoopCircleLoading } from 'react-loadingg';
import ResourceReportTable from './ResourceReportTable';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import { getPrimaryColor } from '../../Helper/CommonThings';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const token = getCookie("bb_ts_token");

const useStyles = (theme) => ({
});

class ResourceReport extends Component {

    constructor(props) {
        super(props)

        this.state = {
            regionData: [],
            clientData: [],
            leadDisciplineData: [
                { 'name': 'OHL', value: 'ohl' },
                { 'name': 'Cabling', value: 'cabling' },
                { 'name': 'Substation', value: 'substation' },
                { 'name': 'Multi Utilities', value: 'multi_utilities' },
                { 'name': 'Technical', value: 'technical' },
                { 'name': 'EDC', value: 'edc' }
            ],
            EMData: [],
            projectsData: [],
            selectedRegion: [],
            selectedClient: [],
            selectedLeadDiscipline: [],
            selectedEm: [],
            isLoading: true,
            selectedProjects: [],
            data: [],
            reportModal : false
        }
    }

    componentDidMount() {
        this.getRegion();
        this.getClient();
        // this.getLeadDiscipline();
        // this.getEM();
        this.getProjects();
    }

    getRegion() {
        Service.get2('/bb/api/region/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({ regionData: res.data })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    getClient() {
        Service.get2('/bb/api/clients/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ clientData: res.data, })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    // getLeadDiscipline(){
    //     Service.get2('/bb/api/roles/', {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res => {
    //             this.setState({ leadDisciplineData: res.data, })
    //         })
    //         .catch(error => {
    //            errorMessage(this.props, error)
    //         });
    // }

    getEM() {
        Service.get2('/bb/api/users/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ EMData: res.data, })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    getProjects() {
        Service.get2('/bb/api/tender/project/?audit_status=0,10&tender_notnull=1', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.projectsData = res.data
                this.setState({
                    // projectsData: res.data,
                    isLoading: false
                })
                try {
                    let EmData = [];
                    let unique = []
                    for (let i = 0; i < res.data.length; i++) {
                        let project = res.data[i];
                        if (project.tenderbid_data.pdm) {
                            let em = {
                                id: project.tenderbid_data.pdm,
                                first_name: project.tenderbid_data.pdm_data.first_name,
                                last_name: project.tenderbid_data.pdm_data.last_name
                            }
                            if (unique.indexOf(project.tenderbid_data.pdm) === -1) {
                                unique.push(project.tenderbid_data.pdm)
                                EmData.push(em)
                            }
                        }
                    }
                    // console.log(EmData)
                    this.setState({ "EMData": EmData })
                }
                catch (e) {
                    console.log(e)
                }

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    filterChange(name, value) {
        this.setState({
            [name]: value
        }, () => this.filterProjects())
    }

    projectSelected(e, value) {
        value.forEach(ele => {
            if (ele.id === 0) {
                value = this.state.projectsData;
            }
        })
        // console.log(value)
        this.setState({ selectedProjects: value });
    }

    filterProjects() {
        let allProjects = [...this.projectsData]
        let filter1 = [];
        let filter2 = [];
        let filter3 = [];
        let filter4 = [];
        if (!this.state.selectedRegion || !this.state.selectedRegion.length) {
            filter1 = [...this.projectsData]
        }
        else {
            allProjects.forEach(ele => {
                for (let i = 0; i < this.state.selectedRegion.length; i++) {
                    if (this.state.selectedRegion[i].id === ele.tenderbid_data.region) { filter1.push(ele) }
                }
            })
        }
        // console.log(filter1)

        if (!this.state.selectedClient || !this.state.selectedClient.length) {
            filter2 = [...filter1]
        }
        else {
            filter1.forEach(ele => {
                for (let i = 0; i < this.state.selectedClient.length; i++) {
                    if (this.state.selectedClient[i].id === ele.tenderbid_data.client) { filter2.push(ele) }
                }
            })
        }
        // console.log(filter2)

        if (!this.state.selectedLeadDiscipline || !this.state.selectedLeadDiscipline.length) {
            filter3 = [...filter2]
        }
        else {
            filter2.forEach(ele => {
                for (let i = 0; i < this.state.selectedLeadDiscipline.length; i++) {
                    if (this.state.selectedLeadDiscipline[i].value === ele.tenderbid_data.lead_dicipline) { filter3.push(ele) }
                }
            })
        }
        // console.log(filter3)

        if (!this.state.selectedEm || !this.state.selectedEm.length) {
            filter4 = [...filter3]
        }
        else {
            filter3.forEach(ele => {
                for (let i = 0; i < this.state.selectedEm.length; i++) {
                    if (this.state.selectedEm[i].id === ele.tenderbid_data.pdm) { filter4.push(ele) }
                }
            })
        }
        // console.log(filter4);
        filter4.unshift({ id: 0, name: 'Select All' });
        this.setState({ projectsData: filter4 })
    }

    getReport = () => {
        // console.log(this.state.selectedProjects)
        this.setState({ isLoading: true })
        let pid = [];
        this.state.selectedProjects.forEach(ele => { if (ele.id !== 0) pid.push(ele.id) });
        Service.post2("/bb/api/report/view/lookahead_report/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: { pid: pid, audit_status: [0, 10, 11] }
        })
            .then(res => {
                // console.log(res.data)
                this.setState({ data: res.data, isLoading: false, reportModal: true })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false })
            })
    }

    closeReport = () => {
        this.setState({ reportModal: false })
    }

    render() {
        return (
            <>
                {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                    <Grid container style={{ marginBottom: '20px' }} spacing={2}>
                        <Grid item xs={3}>
                            <Autocomplete
                                multiple
                                id="region"
                                options={this.state.regionData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.filterChange('selectedRegion', value)}
                                value={this.state.selectedRegion}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Region" placeholder="Region" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                multiple
                                id="client"
                                options={this.state.clientData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.filterChange('selectedClient', value)}
                                value={this.state.selectedClient}
                                size="small"
                                getOptionSelected={(option, value) => {
                                    if (option) {
                                        return (option.id === value.id);
                                    }
                                    return false;
                                }}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Client" placeholder="Client" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                multiple
                                id="lead-discipline"
                                options={this.state.leadDisciplineData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.filterChange('selectedLeadDiscipline', value)}
                                value={this.state.selectedLeadDiscipline}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Lead Discipline" placeholder="Lead Discipline" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                multiple
                                id="em"
                                options={this.state.EMData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => (option.last_name + ", " + option.first_name)}
                                onChange={(e, value) => this.filterChange('selectedEm', value)}
                                value={this.state.selectedEm}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {(option.last_name + ', ' + option.first_name)}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="EM" placeholder="EM" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Autocomplete
                                multiple
                                id="project"
                                options={this.state.projectsData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.projectSelected(e, value)}
                                value={this.state.selectedProjects}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                getOptionSelected={(option, value) => {
                                    return (option.id === value.id || value.id === 0)
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Project" placeholder="Project" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ position: 'relative' }}>
                            <Button
                                variant="contained"
                                // size="medium"
                                color="primary"
                                onClick={this.getReport}
                                style={{ position: 'absolute', bottom: '4px', marginLeft: '20px' }}
                                disabled={this.state.selectedProjects.length === 0}
                            >Get Report</Button>
                        </Grid>
                    </Grid>
                }
                {this.state.reportModal ?
                    <Dialog fullScreen
                        open={this.state.reportModal}
                        onClose={this.closeReport}
                        TransitionComponent={Transition}>
                        <ResourceReportTable
                            closeReport={this.closeReport}
                            data={this.state.data}

                        />
                    </Dialog>
                    : null}
            </>
        )
    }
}

export default withStyles(useStyles)(withSnackbar(ResourceReport));