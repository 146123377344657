import { Box, Tooltip } from '@material-ui/core'
import React, { Component } from 'react'
import MultiProgress from 'react-multi-progress'
import { getDarkGreyColor, getGreencolor } from '../../MainDashBoard/ColorTheme/ColorTheme'

class CustomSPICPI extends Component {
    constructor(props) {
        super(props)

        this.state = {
            SPI: 0,
            CPI: 0,
            exceedSPI: 0,
            exceedCPI: 0
        }
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        var SPI = 0
        var CPI = 0
        var exceedSPI = 0
        var exceedCPI = 0
        if (this.props.data.spi > 1) {
            let convert100 = 100 * this.props.data.spi //120
            let spi = 100 / convert100 //0.833
            exceedSPI = convert100 - 100 //20
            SPI = spi * convert100//99.96
            exceedSPI = spi * exceedSPI//16.66
            SPI = 100 - exceedSPI//
        } else {
            let convert100 = 100 * this.props.data.spi //95
            SPI = convert100
        }
        if (this.props.data.cpi > 1) {
            let convert100 = 100 * this.props.data.cpi //120
            let cpi = 100 / convert100 //0.833
            exceedCPI = convert100 - 100 //20
            CPI = cpi * convert100//99.96
            exceedCPI = cpi * exceedCPI//16.66
            CPI = 100 - exceedCPI//
        } else {
            let convert100 = 100 * this.props.data.cpi //95
            CPI = convert100
        }
        // console.log(SPI, CPI, exceedSPI, exceedCPI)
        this.setState({ SPI, CPI, exceedSPI, exceedCPI })
    }

    render() {
        return (
            <div>
                <Box display={'flex'} flexDirection='column' justifyContent={'center'} width={250} padding="5px">
                    <Box display={'flex'} flexDirection='row' alignItems={'center'} width='100%'>
                        <Box fontSize={'10px'} width='20%'>
                            SPI
                        </Box>
                        <Tooltip title={<div>SPI: {this.props.data.spi}</div>}>
                            <Box display={'flex'} flexDirection='row' justifyContent={'left'} alignItems='center' style={{ cursor: 'pointer' }} marginLeft='5px' width='80%'>
                                <MultiProgress
                                    transitionTime={1.2}
                                    elements={[
                                        {
                                            value: this.state.SPI,
                                            color: getGreencolor(),
                                        },
                                        {
                                            value: this.state.exceedSPI,
                                            color: '#b30000',
                                        },
                                    ]}
                                    height={5}
                                    backgroundColor={getDarkGreyColor()}
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                    <Box display={'flex'} flexDirection='row' marginTop='15px' alignItems={'center'} width='100%'>
                        <Box fontSize={'10px'} width='20%'>
                            CPI
                        </Box>
                        <Tooltip title={<div>CPI: {this.props.data.cpi}</div>}>
                            <Box marginLeft='5px' style={{ cursor: 'pointer' }} width='80%'>
                                <MultiProgress
                                    transitionTime={1.2}
                                    elements={[
                                        {
                                            value: this.state.CPI,
                                            color: getGreencolor(),
                                        },
                                        {
                                            value: this.state.exceedCPI,
                                            color: '#b30000',
                                        },
                                    ]}
                                    height={5}
                                    backgroundColor={getDarkGreyColor()}
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </div>
        )
    }
}
export default CustomSPICPI
