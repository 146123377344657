import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import { Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
// import ReactTooltip from 'react-tooltip';
import { getDarkMode } from '../Helper/CommonThings';
import ReactApexChart from 'react-apexcharts';
import { screenSizeCheck } from '../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import { getPrimaryColor } from '../Helper/CommonThings';
import { LoopCircleLoading } from 'react-loadingg';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';

const DIVERGING_COLOR_SCALE = ["#8bc597", "#009933", "#5097d1", "#8b64a1", "#ffc981", '#ABBF66', "#CC837D", "#d97c85", "#d66afc", "#0785af", "#404d30"]

// const LABEL_STYLE = {
//     fontSize: '15px',
//     textAnchor: 'middle',
//     fill: getDarkMode() === 'dark' ? '#fff' : '#000'
// };

var filterData = []

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class KpiDashboard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: {},
            kpiDashboardData: null,
            viewTabs: [],
            openDataView: false,
            tabSelected: 0,
            tableData: [],
            viewTabsErrorStatus: [],
            chartData: [{ angle: 1 }],
            isTableLoading: true,
            tooltipNode: null,
            chartData2: [{ angle: 1 }],
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        this.setState({ kpiDashboardData: null, isTableLoading: true, tableData: [] })
        if (access.access.l4a && access.access.l4a.l4a_a) {
            var url = '/bb/api/report/kpi/kpi_report_mini/?filter_type=0'

            var typesdata = []

            if (access.access.l4a && access.access.l4a.l4a_g) {
                typesdata.push("ohl")
            }

            if (access.access.l4a && access.access.l4a.l4a_h) {
                typesdata.push("cabling")
            }

            if (access.access.l4a && access.access.l4a.l4a_i) {
                typesdata.push("substation")
            }

            if (access.access.l4a && access.access.l4a.l4a_m) {
                typesdata.push("edc")
            }

            if (access.access.l4a && access.access.l4a.l4a_d) {
                url = "/bb/api/report/kpi/kpi_report_mini/?filter_type=2"
            } else if (typesdata.length > 0) {
                url = "/bb/api/report/kpi/kpi_report_mini/?filter_type=1&filter_data=" + typesdata.toString()
            } else {
                url = "/bb/api/report/kpi/kpi_report_mini/?filter_type=0"
            }

            Service.get2(url, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    console.log(res.data)
                    if (res.data.total) {
                        this.setState({
                            kpiDashboardData: res.data
                        })
                        this.showDialog()
                    }

                })
                .catch(error => {
                    // console.log(error)
                    errorMessage(this.props, error)

                });
        }
    }

    showKpiGraph(data) {
        const themeMode = (getDarkMode() === 'dark') ? 'dark' : 'light'
        const customLabel = ['SPI above 0.9 (' + data.perfect_evp_above_spi_threshold + ')',
        'SPI below 0.9 (' + data.perfect_evp_below_spi_threshold + ')', 'Date unavailable (' + data.evp_with_date_issue + ')',
        'Calculation Error (' + data.evp_with_calc_issue + ")", "Wrong Dates (" + data.evp_with_date_calc_issue + ")",
        "Unknown ERROR (" + data.unknown_issue + ")"]
        const seriesDefinition = ["No errors reported in the dates,<br />% completes, SPI calculations",
            "No errors reported in the dates,<br />% completes, SPI calculations",
            "Either Baseline or Actual/Expected dates are not available.<br />Run the EVP curve, to know the list of tasks",
            "Contact: support@bbparam.com", "Actual Start date is incorrectly updated when compared<br />with the actual start of timesheet information for<br />few tasks",
            "None of the above.<br />We will look into the issue."]
        const series = [data.perfect_evp_above_spi_threshold, data.perfect_evp_below_spi_threshold,
        data.evp_with_date_issue, data.evp_with_calc_issue, data.evp_with_date_calc_issue, data.unknown_issue]
        const colors = [DIVERGING_COLOR_SCALE[1], DIVERGING_COLOR_SCALE[4], DIVERGING_COLOR_SCALE[2], DIVERGING_COLOR_SCALE[3], DIVERGING_COLOR_SCALE[5], DIVERGING_COLOR_SCALE[6]]
        const labels = ['SPI above 0.9', 'SPI below 0.9', 'Date unavailable',
            'Calculation Error', "Wrong Dates", "Unknown ERROR"]
        for (let i = 0; i < series.length; i++) {
            const element = series[i];
            if (element === 0) {
                series.splice(i, 1)
                customLabel.splice(i, 1)
                seriesDefinition.splice(i, 1)
                colors.splice(i, 1)
                labels.splice(i, 1)
            }
        }
        const options = {
            chart: {
                type: 'donut',
            },
            theme: {
                mode: themeMode,
            },
            legend: {
                show: true,
                position: 'right',
                fontSize: screenSizeCheck() ? '9px' : '10px',
                // offsetX: 5,
                customLegendItems: customLabel,
                labels: {
                    useSeriesColors: false
                },
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return '<div style="padding-left:10px; padding-right:10px; background-color:' + w.config.colors[seriesIndex] + ';"><h4>' + customLabel[seriesIndex] + '</h4><h5>' + seriesDefinition[seriesIndex] + '</h5></div>'
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                    return w.config.series[seriesIndex]
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                fontSize: '12px',
                            },
                            value: {
                                fontSize: '12px',
                                offsetY: 4,
                            },
                            total: {
                                show: true,
                                label: 'KPI',
                                fontSize: '13px',
                                formatter: (w) => {
                                    return data.total
                                }
                            }
                        }
                    }
                }
            },
            colors: colors,
            labels: labels,
        }
        return <div style={{ width: '100%' }}>
            <ReactApexChart height={screenSizeCheck() ? 400 : 450} width={screenSizeCheck() ? 400 : 450} options={options} series={series} type="donut" />
        </div>

    }

    showLegacyGraph(data) {
        const themeMode = (getDarkMode() === 'dark') ? 'dark' : 'light'
        const series = [data.project_contain_preparam.length, data.total - data.project_contain_preparam.length]
        const options = {
            chart: {
                type: 'donut',
            },
            theme: {
                mode: themeMode,
                palette: 'palette3',
            },
            legend: {
                show: true,
                position: 'right',
                fontSize: screenSizeCheck() ? '9px' : '10px',
                // offsetX: 5,
                customLegendItems: ['Legacy Project (' + data.project_contain_preparam.length + ')',
                'Non-Legacy (' + (data.total - data.project_contain_preparam.length) + ')'],
                labels: {
                    useSeriesColors: false
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                    return w.config.series[seriesIndex]
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                fontSize: '12px',
                            },
                            value: {
                                fontSize: '12px',
                                offsetY: 4,
                            },
                            total: {
                                show: true,
                                label: 'Legacy',
                                fontSize: '13px',
                                formatter: (w) => {
                                    return data.total
                                }
                            }
                        }
                    }
                }
            },
            labels: ['Legacy Project', 'Non-Legacy'],
        }
        return <div style={{ width: '100%', marginBottom: '10px' }}>
            <ReactApexChart height={screenSizeCheck() ? 390 : 440} width={screenSizeCheck() ? 392 : 440} options={options} series={series} type="donut" />
        </div>

    }

    processData(kpiData) {
        // var chartData = {
        //     title: 'KPI',
        //     color: DIVERGING_COLOR_SCALE[0],
        //     children: [
        //         {
        //             name: "Perfect EVP (" + kpiData.perfect_evp + ")",
        //             size: kpiData.perfect_evp,
        //             color: DIVERGING_COLOR_SCALE[1],
        //             children: [
        //                 {
        //                     name: "SPI threshold (" + kpiData.perfect_evp_below_spi_threshold + ")",
        //                     size: kpiData.perfect_evp_below_spi_threshold,
        //                     color: DIVERGING_COLOR_SCALE[1],
        //                 }
        //             ]
        //         },
        //         {
        //             name: "Date unavailable (" + kpiData.evp_with_date_issue + ")",
        //             size: kpiData.evp_with_date_issue,
        //             color: DIVERGING_COLOR_SCALE[2],
        //             children: [
        //                 {
        //                     name: "SPI threshold (" + kpiData.evp_with_date_issue_below_spi_threshold + ")",
        //                     size: kpiData.evp_with_date_issue_below_spi_threshold,
        //                     color: DIVERGING_COLOR_SCALE[2],
        //                 }
        //             ]
        //         },
        //         {
        //             name: "Calculation Error (" + kpiData.evp_with_calc_issue + ")",
        //             size: kpiData.evp_with_calc_issue,
        //             color: DIVERGING_COLOR_SCALE[3],
        //             children: [
        //                 {
        //                     name: "SPI threshold (" + kpiData.evp_with_calc_issue_below_spi_threshold + ")",
        //                     size: kpiData.evp_with_calc_issue_below_spi_threshold,
        //                     color: DIVERGING_COLOR_SCALE[3],
        //                 }
        //             ]
        //         },
        //         {
        //             name: "Wrong Dates (" + kpiData.evp_with_date_calc_issue + ")",
        //             size: kpiData.evp_with_date_calc_issue,
        //             color: DIVERGING_COLOR_SCALE[4],
        //             children: [
        //                 {
        //                     name: "SPI threshold (" + kpiData.evp_with_date_calc_issue_below_spi_threshold + ")",
        //                     size: kpiData.evp_with_date_calc_issue_below_spi_threshold,
        //                     color: DIVERGING_COLOR_SCALE[4],
        //                 }
        //             ]
        //         },
        //         {
        //             name: "Unknown ERROR (" + kpiData.unknown_issue + ")",
        //             size: kpiData.unknown_issue,
        //             color: DIVERGING_COLOR_SCALE[5],

        //         }
        //     ]
        // }
        var viewTabs = []
        // var radialChartData = []
        var viewTabsErrorStatus = []
        // var radialChartData2 = []
        if (kpiData.perfect_evp) {
            viewTabs.push("SPI above 0.9 (" + kpiData.perfect_evp_above_spi_threshold + ")")
            viewTabsErrorStatus.push(0)
            // radialChartData.push({
            //     angle: kpiData.perfect_evp_above_spi_threshold,
            //     label: "SPI above 0.9 (" + kpiData.perfect_evp_above_spi_threshold + ")",
            //     color: DIVERGING_COLOR_SCALE[1],
            //     definition: "No errors reported in the dates, % completes, SPI calculations",

            //     // radius: kpiData.perfect_evp
            // })
        }
        if (kpiData.perfect_evp) {
            viewTabs.push("SPI below 0.9 (" + kpiData.perfect_evp_below_spi_threshold + ")")
            viewTabsErrorStatus.push(0)
            // radialChartData.push({
            //     angle: kpiData.perfect_evp_below_spi_threshold,
            //     label: "SPI below 0.9 (" + (kpiData.perfect_evp_below_spi_threshold) + ")",
            //     color: DIVERGING_COLOR_SCALE[4],
            //     definition: "No errors reported in the dates, % completes, SPI calculations",

            //     // radius: kpiData.perfect_evp
            // })
        }
        if (kpiData.evp_with_date_issue) {
            viewTabs.push("Date unavailable (" + kpiData.evp_with_date_issue + ")")
            viewTabsErrorStatus.push(1)
            // radialChartData.push({
            //     angle: kpiData.evp_with_date_issue,
            //     label: "Date unavailable (" + kpiData.evp_with_date_issue + ")",
            //     color: DIVERGING_COLOR_SCALE[2],
            //     definition: "Either Baseline or Actual/Expected dates are not available. Run the EVP curve, to know the list of tasks",
            //     spi: kpiData.evp_with_date_issue_below_spi_threshold,
            //     // radius: kpiData.evp_with_date_issue
            // })
        }
        if (kpiData.evp_with_calc_issue) {
            viewTabs.push("Calculation Error (" + kpiData.evp_with_calc_issue + ")")
            viewTabsErrorStatus.push(2)
            // radialChartData.push({
            //     angle: kpiData.evp_with_calc_issue,
            //     label: "Calculation Error (" + kpiData.evp_with_calc_issue + ")",
            //     color: DIVERGING_COLOR_SCALE[3],
            //     definition: "Contact: support@bbparam.com",
            //     spi: kpiData.evp_with_calc_issue_below_spi_threshold,
            //     // radius: kpiData.evp_with_calc_issue
            // })
        }
        if (kpiData.evp_with_date_calc_issue) {
            viewTabs.push("Wrong Dates (" + kpiData.evp_with_date_calc_issue + ")")
            viewTabsErrorStatus.push(3)
            // radialChartData.push({
            //     angle: kpiData.evp_with_date_calc_issue,
            //     label: "Wrong Dates (" + kpiData.evp_with_date_calc_issue + ")",
            //     color: DIVERGING_COLOR_SCALE[5],
            //     definition: "Actual Start date is incorrectly updated when compared with the actual start of timesheet information for few tasks",
            //     spi: kpiData.evp_with_date_calc_issue_below_spi_threshold,
            //     // radius: kpiData.evp_with_date_calc_issue
            // })
        }
        if (kpiData.unknown_issue) {
            viewTabs.push("Unknown ERROR (" + kpiData.unknown_issue + ")")
            viewTabsErrorStatus.push(4)
            // radialChartData.push({
            //     angle: kpiData.unknown_issue,
            //     label: "Unknown ERROR (" + kpiData.unknown_issue + ")",
            //     color: DIVERGING_COLOR_SCALE[6],
            //     definition: "None of the above. We will look into the issue.",
            //     spi: null,
            //     // radius: kpiData.unknown_issue
            // })
        }
        if (kpiData.project_contain_preparam.length) {
            viewTabs.push("Legacy Project (" + kpiData.project_contain_preparam.length + ")")
            viewTabsErrorStatus.push(5)
            // radialChartData2.push({
            //     angle: kpiData.project_contain_preparam.length,
            //     label: "Legacy Project (" + kpiData.project_contain_preparam.length + ")",
            //     color: DIVERGING_COLOR_SCALE[4],
            //     definition: "Projects containing Pre-Param data",
            //     spi: null,
            //     // radius: kpiData.unknown_issue
            // })
        }
        if (kpiData.total) {
            // radialChartData2.push({
            //     angle: kpiData.total - kpiData.project_contain_preparam.length,
            //     label: "Non-Legacy Project (" + (kpiData.total - kpiData.project_contain_preparam.length) + ")",
            //     color: DIVERGING_COLOR_SCALE[1],
            //     definition: "Projects don't contain Pre-Param data",
            //     spi: null,
            //     // radius: kpiData.unknown_issue
            // })
        }


        return {
            // data: chartData,
            viewTabs: viewTabs,
            viewTabsErrorStatus: viewTabsErrorStatus,
            // chartData: radialChartData,
            // chartData2: radialChartData2,
        }

    }

    showDialog() {
        var url = '/bb/api/report/kpi/kpi_view_data_mini/?filter_type=0'

        var typesdata = []

        if (access.access.l4a && access.access.l4a.l4a_g) {
            typesdata.push("ohl")
        }

        if (access.access.l4a && access.access.l4a.l4a_h) {
            typesdata.push("cabling")
        }

        if (access.access.l4a && access.access.l4a.l4a_i) {
            typesdata.push("substation")
        }

        if (access.access.l4a && access.access.l4a.l4a_m) {
            typesdata.push("edc")
        }

        if (access.access.l4a && access.access.l4a.l4a_d) {
            url = "/bb/api/report/kpi/kpi_view_data_mini/?filter_type=2"
        } else if (typesdata.length > 0) {
            url = "/bb/api/report/kpi/kpi_view_data_mini/?filter_type=1&filter_data=" + typesdata.toString()
        } else {
            url = "/bb/api/report/kpi/kpi_view_data_mini/?filter_type=0"
        }

        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                const { viewTabs, viewTabsErrorStatus } = this.processData(this.state.kpiDashboardData)
                // console.log(res.data)
                var tableData = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.error === viewTabsErrorStatus[0] && element.spi >= 0.9) {
                        tableData.push(element)
                    }
                }
                this.setState({
                    viewTabs: viewTabs,
                    viewTabsErrorStatus: viewTabsErrorStatus,
                    kpiViewData: res.data,
                    tableData: tableData,
                    isTableLoading: false,
                })
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)

            });
    }

    findFilterValue(field) {
        for (let i = 0; i < filterData.length; i++) {
            const element = filterData[i];
            // console.log(field === element.column.field)
            if (field === element.column.field) {
                return element.value
            }
        }
        return null
    }

    render() {

        const handleTabChange = (event, newValue) => {
            var tableData = []
            if (this.state.viewTabsErrorStatus[newValue] === 5) {

                for (let i = 0; i < this.state.kpiDashboardData.project_contain_preparam.length; i++) {
                    const element = this.state.kpiDashboardData.project_contain_preparam[i];
                    for (let j = 0; j < this.state.kpiViewData.length; j++) {
                        const element1 = this.state.kpiViewData[j];
                        if (element.tenderid === element1.tender) {
                            tableData.push(element1)
                        }
                    }

                }
                this.setState({
                    tabSelected: newValue,
                    tableData: tableData
                })


            } else if (this.state.viewTabsErrorStatus[newValue] === 0) {
                for (let i = 0; i < this.state.kpiViewData.length; i++) {
                    const element = this.state.kpiViewData[i];
                    if (element.error === 0 && newValue === 0 && element.spi >= 0.9) {
                        tableData.push(element)
                    } else if (element.error === 0 && newValue === 1 && element.spi < 0.9) {
                        tableData.push(element)
                    }
                }
                this.setState({
                    tabSelected: newValue,
                    tableData: tableData
                })
            } else {
                for (let i = 0; i < this.state.kpiViewData.length; i++) {
                    const element = this.state.kpiViewData[i];
                    if (element.error === this.state.viewTabsErrorStatus[newValue]) {
                        tableData.push(element)
                    }
                }
            this.setState({
                tabSelected: newValue,
                tableData: tableData
            })

            }


        };

        return (
            <>
                {this.state.kpiDashboardData ?
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" spacing={3}>
                                    <Grid key={1} item >
                                        {this.showKpiGraph(this.state.kpiDashboardData)}

                                    </Grid>
                                    <Grid key={1} item>
                                        {this.state.kpiDashboardData && this.state.kpiDashboardData.project_contain_preparam.length ?
                                            this.showLegacyGraph(this.state.kpiDashboardData)
                                            : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* {this.state.tooltipNode ?
                            <ReactTooltip id='happyFace' type='error'>
                                <Box display="flex" flexDirection="row" >
                                    <Box fontWeight="fontWeightBold">{this.state.tooltipNode.label}</Box>
                                </Box>
                                {(this.state.tooltipNode.spi === null) ? (null) : (
                                    <Box display="flex" flexDirection="row" >
                                        <Box fontStyle="italic">{"Projects in SPI threshold: " + this.state.tooltipNode.spi}</Box>
                                    </Box>
                                )}

                                <Box display="flex" sx={{ maxWidth: 300 }} m={.5} flexDirection="row" >
                                    <Box flexWrap="wrap" >
                                        {this.state.tooltipNode.definition}
                                    </Box>
                                </Box>
                                <Box display="flex" sx={{ maxWidth: 300 }} m={.5} flexDirection="row" >
                                    <Box fontStyle="oblique" fontFamily="Monospace" flexWrap="wrap" >
                                        Click on the chart for more details
                                    </Box>
                                </Box>
                            </ReactTooltip>
                            : null} */}
                        <AppBar position="static">
                            <Tabs indicatorColor="secondary" textColor="inherit" variant="scrollable" scrollButtons="auto" value={this.state.tabSelected} onChange={handleTabChange} aria-label="simple tabs example"> 
                            {this.state.viewTabs.map((text, index) => (
                                    <Tab label={text} {...a11yProps(index)} />
                                ))}
                         </Tabs>
                        </AppBar>
                        <MaterialTable
                            // style={{ minWidth: this.state.tableWidth }}
                            columns={[
                                {
                                    title: 'Discipline', field: 'tender_data.tenderbid_data.lead_dicipline',
                                    headerStyle: { minWidth: 120 }, cellStyle: { minWidth: 120 },
                                    // defaultFilter: this.findFilterValue("tenderbid_data.lead_dicipline"),
                                    lookup: { 'ohl': 'OHL', 'cabling': 'Cabling', 'substation': 'Substation', 'multi_utilities': 'Multi Utilities', 'edc': 'EDC' }
                                },
                                {
                                    title: 'Project', field: 'tender_data.tender_name',
                                    headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 },
                                    // defaultFilter: this.findFilterValue("tender_name"), headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 },
                                },
                                {
                                    title: 'EM', field: 'tender_data.pdm',
                                    customFilterAndSearch: (term, rowData) => {
                                        return ((rowData.tender_data.tenderbid_data && rowData.tender_data.tenderbid_data.pdm_data) ? (rowData.tender_data.tenderbid_data.pdm_data.last_name + ", " + rowData.tender_data.tenderbid_data.pdm_data.first_name) : (null)).toLowerCase().includes(term.toLowerCase())
                                    },
                                    // defaultFilter: this.findFilterValue("pdm"),
                                    render: rowData => (rowData.tender_data.tenderbid_data && rowData.tender_data.tenderbid_data.pdm_data) ? (rowData.tender_data.tenderbid_data.pdm_data.last_name + ", " + rowData.tender_data.tenderbid_data.pdm_data.first_name) : (null)
                                },
                                {
                                    title: 'Client', field: 'tender_data.tenderbid_data.client_name',
                                    // defaultFilter: this.findFilterValue("tenderbid_data.client_name"),
                                    // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                },
                                {
                                    title: 'Region', field: 'tender_data.tenderbid_data.region_name',
                                    // defaultFilter: this.findFilterValue("tenderbid_data.region_name"),
                                    // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                },
                                {
                                    title: '% updated on', field: 'last_percentage_complete_on', type: 'date'
                                    // defaultFilter: this.findFilterValue("tenderbid_data.region_name"),
                                    // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                },
                                {
                                    title: 'SPI', field: 'spi',
                                    // defaultFilter: this.findFilterValue("tenderbid_data.region_name"),
                                    // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                },

                                {
                                    title: 'CPI', field: 'cpi',
                                    // defaultFilter: this.findFilterValue("tenderbid_data.region_name"),
                                    // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                },
                            ]}
                            isLoading={this.state.isTableLoading}
                            data={this.state.tableData}
                            options={{
                                paging: false,
                                padding: "dense",
                                filtering: true,
                                doubleHorizontalScroll: true,

                                toolbar: false
                            }}
                            onFilterChange={(filter) => { filterData = filter }}
                            actions={[

                                rowData => ({
                                    icon: 'equalizer',
                                    tooltip: "EVP",
                                    hidden: !((rowData.evp && ((rowData.tenderbid_data.pdm === user.id && (access && access.access && access.access.l4a.l4a_x)) || (access && access.access && access.access.l4a.l4a_y))) || (access && access.access && access.access.l4a.l4a_z)),
                                    onClick: (event, rowData) => { window.open("/report/evp/" + rowData.tender_data.id) },
                                }),


                            ]}
                        />

                    </div>
                    : <LoopCircleLoading color={getPrimaryColor()} />}
            </>

        )
    }
}

export default withSnackbar(KpiDashboard)

// function getKeyPath(node) {
//     if (!node.parent) {
//         return ['root'];
//     }

//     return [(node.data && node.data.name) || node.name].concat(
//         getKeyPath(node.parent)
//     );
// }

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
