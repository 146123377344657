import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from "moment";
import { errorMessage } from '../Helper/ErrorMessage';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import UserTimesheetReport from './UserTimesheetReport/UserTimesheetReport';
import { getPrimaryColor } from '../Helper/CommonThings';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const token = getCookie("bb_ts_token");

class DemandVsCapcity extends Component {

    constructor(props) {
        super();

        this.state = {
            available_workbook: [],
            estimates: [],
            users: [],
            selected_users: [],
            selectedProject1: [],
            selectedProject2: [],
            isLoading: false,
            selectTime1: moment(),
            selectTime2: moment().add(7, 'days'),
            minTime: moment().add(7, 'days'),
            isLoadingMain: true,
            open: false,
            openTimesheetReport: false,
        }

    }


    componentDidMount() {
        Service.get2('/bb/api/tender/project/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var available_workbook = [{
                    id: 0,
                    tender_name: 'SELECT ALL'
                }], estimates = [
                    {
                        id: 0,
                        show_name: 'SELECT ALL'
                    }
                ]
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.from_tenderbid && (element.audit_status === 0 || element.audit_status === 10) && element.tenderbid_data.lead_dicipline !== 'trail') {
                        available_workbook.push(element)
                    }
                    if (element.is_subproject && element.audit_status === 0) {
                        for (let j = 0; j < res.data.length; j++) {
                            const element1 = res.data[j];
                            if (element1.id === element.subproject_pid && element1.from_tenderbid && element1.tenderbid_data.lead_dicipline !== 'trail') {
                                element.show_name = element1.tender_name + " - " + element1.name
                                estimates.push(element)
                            }
                        }
                    }
                }

                this.setState({
                    available_workbook: available_workbook,
                    estimates: estimates,
                    isLoadingMain: false
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        Service.get2(`/bb/api/users/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    users: res.data
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

    }

    handleSubmit() {
        
        var goon = true
        // console.log(this.state.selectedProject2)
        var emails = null
        for (let i = 0; i < this.state.selected_users.length; i++) {
            const element = this.state.selected_users[i];
            if (!emails) {
                emails = element.email
            } else {
                emails += "," + element.email
            }
        }
        if (!emails) {
            goon = false
            this.props.enqueueSnackbar('Select users to email!', {
                variant: 'warning'
            });
        }
        // var projects = null
        var projects1 = null
        var sele1 = false
        for (let i = 0; i < this.state.selectedProject1.length; i++) {
            const element = this.state.selectedProject1[i];
            if (element.id === 0) {
                sele1 = true
                break
            }
            if (!projects1) {
                projects1 = element.id
            } else {
                projects1 += "," + element.id
            }
        }

        if (sele1) {
            projects1 = null
            for (let i = 0; i < this.state.estimates.length; i++) {
                const element = this.state.estimates[i];
                if (!projects1) {
                    projects1 = element.id
                } else {
                    projects1 += "," + element.id
                }
            }
        }

        var projects2 = null
        var sele2 = false
        for (let i = 0; i < this.state.selectedProject2.length; i++) {
            const element = this.state.selectedProject2[i];
            if (element.id === 0) {
                sele2 = true
                break
            }
            if (!projects2) {
                projects2 = element.id
            } else {
                projects2 += "," + element.id
            }
        }
        if (sele2) {
            projects2 = null
            for (let i = 0; i < this.state.available_workbook.length; i++) {
                const element = this.state.available_workbook[i];
                if (!projects2) {
                    projects2 = element.id
                } else {
                    projects2 += "," + element.id
                }
            }
        }

        if (!projects1 && !projects2) {
            goon = false
            this.props.enqueueSnackbar('Select atleast one project!', {
                variant: 'warning'
            });
        }

        if (!projects1) {
            projects1 = '-1'
        }
        if (!projects2) {
            projects2 = '-1'
        }

        // console.log(projects)
        var errored = false
        if (goon) {
            this.setState({
                isLoading: true
            })
            Service.get2('/bb/api/report/view/demand_vs_capacity/?start=' + moment().format('DD-MM-YYYY') + '&end='
                + moment(this.state.selectTime2).format('DD-MM-YYYY') + '&pid=' + projects2 + '&dsid=' + projects1 + '&email=' + emails, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                })
                .catch(error => {
                    errored = true
                    this.setState({
                        isLoading: false
                    })

                });

            setTimeout(() => {
                if (!errored) {
                    this.setState({
                        open: true,
                        isLoading: false
                    })
                    this.props.enqueueSnackbar('Request raised successfully!', {
                        variant: 'success'
                    });
                } else {
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                }
            }, 5000);
        }



    }

    onUserSelect = (event, values) => {

        this.setState({ selected_users: values });
    }

    onProjectSelect = (event, values) => {

        this.setState({ selectedProject1: values });
    }


    onProjectSelect2 = (event, values) => {
        this.setState({ selectedProject2: values });
    }


    render() {


        // const handleDateStart = (date) => {
        //     // console.log(date)
        //     this.setState({ selectTime1: date, selectTime2: moment(date).add(7, 'days'), minTime: moment(date).add(7, 'days'), maxTime: moment(date).add(45, 'days'), });
        // };
        const handleDateEnd = (date) => {
            this.setState({ selectTime2: date, });
        };

        const handleDialogClose = () => {
            this.setState({
                openTimesheetReport: false
            })
        };

        const handleClose = () => {
            this.setState({
                open: false
            })
        };

        return (
            <div>
                {(this.state.isLoadingMain) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <Box m={3}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={4}>
                                <Autocomplete
                                    // PopperComponent={MyPopper2}
                                    multiple
                                    id="export-columns"
                                    // values={this.state.selectedProject2}
                                    options={this.state.available_workbook}
                                    disableCloseOnSelect
                                    value={this.state.selectedProject2}
                                    getOptionLabel={(option) => option.tender_name}
                                    onChange={this.onProjectSelect2}
                                    fullWidth
                                    renderOption={(option, state) => {
                                        const selectFilmIndex = this.state.selectedProject2.findIndex(
                                            film => film.id === 0
                                        );
                                        if (selectFilmIndex > -1) {
                                            state.selected = true;
                                        }
                                        return (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={state.selected}
                                                />
                                                {option.tender_name}
                                            </React.Fragment>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Available Workbook & Stage 1a" placeholder="Available Workbook & Stage 1a" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    // PopperComponent={MyPopper}
                                    multiple
                                    // values={this.state.selectedProject1}
                                    id="export-projects"
                                    options={this.state.estimates}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.show_name}
                                    renderOption={(option, state) => {
                                        const selectFilmIndex = this.state.selectedProject1.findIndex(
                                            film => film.id === 0
                                        );
                                        if (selectFilmIndex > -1) {
                                            state.selected = true;
                                        }
                                        return (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={state.selected}
                                                />
                                                {option.show_name}
                                            </React.Fragment>
                                        );
                                    }}
                                    onChange={this.onProjectSelect}
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Estimates - Stage 2 In Development" placeholder="Estimates - Stage 2 In Development" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    multiple
                                    id="export-selected_users"
                                    options={this.state.users}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.last_name + ", " + option.first_name}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.last_name + ", " + option.first_name}
                                        </React.Fragment>
                                    )}
                                    onChange={this.onUserSelect}
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Email To" placeholder="Email To" />
                                    )}
                                />
                            </Grid>

                            {/* <Grid item xs={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="From Date"
                                            format="dd/MM/yyyy"
                                            value={this.state.selectTime1}
                                            onChange={handleDateStart}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}

                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid> */}
                            <Grid item xs={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog2"
                                        label="To Date"
                                        format="dd/MM/yyyy"
                                        minDate={this.state.minTime}
                                        value={this.state.selectTime2}
                                        onChange={handleDateEnd}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={4}>


                                {(!this.state.isLoading) ? (<Button variant="contained" color="primary" onClick={() => { this.handleSubmit() }} >Submit</Button>) : (
                                    <CircularProgress style={{ width: '25px', height: '25px' }} />
                                )}

                            </Grid>

                        </Grid>
                    </Box>
                )}

                {/* <Button style={{ marginLeft: '15px' }} variant='outlined' color="primary" onClick={() => this.setState({openTimesheetReport: true})} >Timesheet Report</Button> */}
                
                <Dialog
                    open={this.state.open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Request Started Processing"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {"We started processing your request. This will take several minutes to complete but you can leave this page for now. " +
                                "You will receive a mail with the result of this request from special_task@bbparam.com."}

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                
                <Dialog
                    open={this.state.openTimesheetReport}
                    maxWidth={"lg"}
                    fullWidth
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Timesheet Report"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Report will shared via E-mail
                        </DialogContentText>
                        <UserTimesheetReport teamUsers={false} userEnabled={true} allFilter={true} />
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleDialogClose} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        )
    }
}

export default withSnackbar(DemandVsCapcity)