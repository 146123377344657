import { Grid, InputAdornment } from '@mui/material'
import React, { PureComponent } from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
// import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
// import InputBase from '@mui/material/InputBase';
import { getMode } from '../../../MainDashBoard/ColorTheme/ColorTheme';
// import { styled } from '@mui/material/styles';
import { Box, Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import { withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import Link from '@mui/material/Link';
import { screenSizeCheck } from '../../../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import { getCookie } from '../../../Helper/CookieHelper';
import Service from '../../../Networking/networkutils'
import { errorMessage } from '../../../Helper/ErrorMessage';
import CircularProgress from '@material-ui/core/CircularProgress';
import FindUserByRole from '../../../FindUserByRole/FindUserByRole';
import CloseIcon from '@mui/icons-material/Close';

const token = getCookie("bb_ts_token");

// const Search = styled('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: getMode() ? alpha(theme.palette.common.white, 0.15) : '#e6e6e6',
//     '&:hover': {
//         backgroundColor: getMode() ? alpha(theme.palette.common.white, 0.25) : '#f2f2f2',
//     },
//     marginRight: theme.spacing(1),
//     marginLeft: 0,
//     width: '100%',
//     [theme.breakpoints.up('lg')]: {
//         marginLeft: theme.spacing(7),
//         width: 'auto',
//     },
//     [theme.breakpoints.down('lg')]: {
//         marginLeft: theme.spacing(4),
//         width: 'auto',
//     },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: 'inherit',
//     '& .MuiInputBase-input': {
//         padding: theme.spacing(1, 1, 1, 0),
//         // vertical padding + font size from searchIcon
//         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
//         transition: theme.transitions.create('width'),
//         width: '100%',
//         [theme.breakpoints.up('lg')]: {
//             width: '25ch',
//         },
//         [theme.breakpoints.down('lg')]: {
//             width: '15ch',
//         },
//     },
// }));

var wbsSearch = ""
var tasksSearch = ""
var rolesSearch = ""
var role_ids = []
var old_tasks = []
var old_roles = []
var selectedWbs = null
var availableWBStemplate = []
var existCartList = []

class TaskAndRole extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            wbs: this.props.wbs,
            scrollHeight: 1800,
            tasks: [],
            roles: [],
            openTemplate: false,
            showWbsHelper: false,
            showTasksHelper: false,
            showRolesHelper: false,
            isLoading: false,
            openWbsName: '',
            searchUserRoles: false
        }
    }

    componentDidMount() {
        if (this.props.value) {
            this.smoothScrollToWBS()
            selectedWbs = this.props.value.showName
            this.setState({ tasks: this.props.value.tasks, isLoading: true }, () => {
                setTimeout(() => {
                    this.setState({ isLoading: false, roles: this.props.roles })
                }, 1000);
            })
            // this.getRoles(this.props.value.template_pid)
            // this.setState({ tasks: this.props.value.tasks, isLoading: true, roles: this.props.roles })
        }
        availableWBStemplate = []
        existCartList = []
        for (let i = 0; i < this.props.data.length; i++) {
            const ele = this.props.data[i];
            availableWBStemplate.push(ele.template_pid)
        }
        for (let i = 0; i < this.props.addList.length; i++) {
            const listElement = this.props.addList[i];
            existCartList.push(listElement.wbs_template)
        }
        wbsSearch = ""
        tasksSearch = ""
        rolesSearch = ""
        role_ids = []
        if (this.props.editData) {
            this.setState({ tasks: this.props.editData.tasks, isLoading: true, roles: this.props.roles })
            this.getRoles(this.props.editData.template_pid)
        }
    }

    componentWillUnmount() {
        this.getRoleIds()
    }

    getRoleIds() {
        let role_ids = []
        for (let i = 0; i < this.props.roles.length; i++) {
            const element = this.props.roles[i];
            if (element.fm_checked) {
                if (!role_ids.includes(element.t_role)) {
                    role_ids.push(element.t_role)
                }
            }
        }
        this.props.selectedRoles(role_ids)
    }

    handleWbsSearch(data) {
        wbsSearch = data
        this.wbsSearch(wbsSearch)
        if (wbsSearch !== "" && !this.state.showWbsHelper) {
            setTimeout(() => {
                this.setState({ showWbsHelper: true })
            }, 3000);
        }
    }

    wbsSearch(values) {
        var datas = []
        if (values === '') {
            datas = this.props.wbs
            this.setState({ showWbsHelper: false }, () => {
                setTimeout(() => {
                    this.setState({ showWbsHelper: false })
                }, 3000);
            })
        } else {
            const data_lowercase = wbsSearch.toLowerCase()
            var search_words = data_lowercase.split(" ");
            search_words = search_words.filter(n => n)
            var filtered_data = []
            if (search_words.length === 0) {
                filtered_data = this.props.wbs
            }

            for (let i = 0; i < this.props.wbs.length; i++) {
                const element = this.props.wbs[i];
                var num_of_passed = false
                for (let j = 0; j < search_words.length; j++) {
                    const el = search_words[j];
                    num_of_passed = element.showName.toLowerCase().includes(el)
                    if (!num_of_passed) {
                        break
                    }
                }
                if (num_of_passed) {
                    filtered_data.push(element)
                }
            }
            datas = filtered_data
        }
        this.smoothScrollToWBS()
        this.setState({ wbs: datas })
    }

    handleTaskSearch(data) {
        if (this.state.tasks.length > 0) {
            tasksSearch = data
            this.taskSearch(tasksSearch)
            if (tasksSearch !== "" && !this.state.showTasksHelper) {
                setTimeout(() => {
                    this.setState({ showTasksHelper: true })
                }, 3000);
            }
        }
    }

    taskSearch(values) {
        var datas = []
        if (values === '') {
            datas = old_tasks
            this.setState({ showTasksHelper: false }, () => {
                setTimeout(() => {
                    this.setState({ showTasksHelper: false })
                }, 3000);
            })
        } else {
            const data_lowercase = tasksSearch.toLowerCase()
            var search_words = data_lowercase.split(" ");
            search_words = search_words.filter(n => n)
            var filtered_data = []
            if (search_words.length === 0) {
                filtered_data = this.state.tasks
            }

            for (let i = 0; i < this.state.tasks.length; i++) {
                const element = this.state.tasks[i];
                var num_of_passed = false
                for (let j = 0; j < search_words.length; j++) {
                    const el = search_words[j];
                    num_of_passed = element.name.toLowerCase().includes(el)
                    if (!num_of_passed) {
                        break
                    }
                }
                if (num_of_passed) {
                    filtered_data.push(element)
                }
            }
            datas = filtered_data
        }

        this.setState({ tasks: datas })
    }

    handleRolesSearch(data) {
        // let tasks = this.state.tasks
        // var proceed = false
        // for (let i = 0; i < tasks.length; i++) {
        //     const element = tasks[i];
        //     if (element.fm_checked) {
        //         proceed = true
        //         break
        //     }
        // }
        // if (this.state.tasks.length > 0) {
        rolesSearch = data
        this.rolesSearch(rolesSearch)
        if (rolesSearch !== "" && !this.state.showRolesHelper) {
            setTimeout(() => {
                this.setState({ showRolesHelper: true })
            }, 3000);
        }
        // }
    }

    rolesSearch(values) {
        var datas = []
        if (values === '') {
            datas = old_roles
            this.setState({ showRolesHelper: false }, () => {
                setTimeout(() => {
                    this.setState({ showRolesHelper: false })
                }, 3000);
            })
        } else {
            const data_lowercase = rolesSearch.toLowerCase()
            var search_words = data_lowercase.split(" ");
            search_words = search_words.filter(n => n)
            var filtered_data = []
            if (search_words.length === 0) {
                filtered_data = this.state.roles
            }

            for (let i = 0; i < this.state.roles.length; i++) {
                const element = this.state.roles[i];
                var num_of_passed = false
                for (let j = 0; j < search_words.length; j++) {
                    const el = search_words[j];
                    num_of_passed = element.name.toLowerCase().includes(el)
                    if (!num_of_passed) {
                        break
                    }
                }
                if (num_of_passed) {
                    filtered_data.push(element)
                }
            }
            datas = filtered_data
        }
        datas.sort((a, b) => Number(b.fm_checked) - Number(a.fm_checked));
        this.setState({ roles: datas })
    }

    refCallback = el => {
        if (el) {
            this.setState({
                scrollHeight: window.innerHeight - el.getBoundingClientRect().y - (this.props.editData !== null ? -10 : 160)
            })
        }
    }

    selectWBS(value) {
        if (value.editable) {
            this.props.openEdit(value)
        } else {
            this.selectedWbs(value)
        }
    }

    selectedWbs(value) {
        this.setState({ isLoading: true })
        role_ids = []
        selectedWbs = value.showName
        this.smoothScrollToWBS()
        var proceed = true;
        var cartExistItems = null
        let cartList = this.props.addList
        for (let i = 0; i < cartList.length; i++) {
            const element = cartList[i];
            if (element.wbs_template === value.id && !value.editable) {
                cartExistItems = element
                proceed = false
                break
            }
        }
        if (proceed) {
            proceed = value.editable ? true : !availableWBStemplate.includes(value.id)
            if (proceed) {
                for (let i = 0; i < value.tasks.length; i++) {
                    const element = value.tasks[i];
                    element.fm_checked = false
                    if (!element.roles) {
                        element.roles = []
                    }
                }

                old_tasks = value.tasks
                this.setState({ tasks: old_tasks }, () => {
                    // if (this.state.tasks.length === 0) {
                    //     this.setState({ roles: [], isLoading: false, selectedWbs: null })
                    // } else {
                    this.getRoles(value.id)
                    // }
                })
                this.props.selectedWbs(value)
            } else {
                this.props.enqueueSnackbar('WBS already added to project!. Add the task which is not in template.', {
                    variant: 'warning'
                });
                this.setState({ roles: [], tasks: [], isLoading: false })
            }
        } else {
            this.props.cartExistItems(cartExistItems)
        }
    }

    tasksOnChange(i, roles) {
        let tasks = this.state.tasks
        let tasks_roles = this.state.roles
        tasks[i].fm_checked = !tasks[i].fm_checked
        if (tasks[i].fm_checked) {
            for (let i = 0; i < roles.length; i++) {
                const element = roles[i];
                element.t_role = element.id
                if (!role_ids.includes(element.t_role)) {
                    role_ids.push(element.t_role)
                }
            }
            for (let j = 0; j < tasks_roles.length; j++) {
                const element1 = tasks_roles[j];
                if (role_ids.includes(element1.t_role)) {
                    element1.fm_checked = true
                }
            }
            if (this.state.tasks.length > 0) {
                this.setState({ roles: this.state.roles })
            } else {
                this.setState({ roles: [] })
            }
            this.rolesSearch(rolesSearch)
        } else {
            let roleIds = []
            for (let i = 0; i < old_tasks.length; i++) {
                const element = old_tasks[i];
                if (element.fm_checked) {
                    for (let j = 0; j < element.roles.length; j++) {
                        const element1 = element.roles[j];
                        if (!roleIds.includes(element1.t_role)) {
                            roleIds.push(element1.t_role)
                        }
                    }
                }
            }
            for (let j = 0; j < tasks_roles.length; j++) {
                const element1 = tasks_roles[j];
                if (!roleIds.includes(element1.t_role)) {
                    element1.fm_checked = false
                }
            }
            role_ids = roleIds
            this.rolesSearch(rolesSearch)
        }
        this.setState({ tasks }, () => {
            this.forceUpdate()
        })
    }

    rolesOnChange(i) {
        let roles = this.state.roles
        roles[i].fm_checked = !roles[i].fm_checked
        this.setState({ roles }, () => this.forceUpdate())
    }

    onTemplateFindChange = (event, values) => {
        for (let i = 0; i < this.props.wbs.length; i++) {
            const element = this.props.wbs[i];
            if (element.id === values.wbsid) {
                this.selectWBS(element)
                break
            }
        }

        this.setState({ openTemplate: false })
    }

    smoothScrollToWBS() {
        setTimeout(() => {
            if (this.messagesEnd) {
                this.messagesEnd.scrollIntoView({ behavior: "smooth", block: 'center' });
            }
        }, 300);
    }

    clearSearch(search_type) {
        if (search_type === 'wbs') {
            wbsSearch = ''
            this.wbsSearch(wbsSearch)
        } else if (search_type === 'tasks') {
            tasksSearch = ''
            this.taskSearch(tasksSearch)
        } else if (search_type === 'roles') {
            rolesSearch = ''
            this.rolesSearch(rolesSearch)
        }
    }

    getRoles(id) {
        Service.get(`/bb/api/tender/tasks/roles/frequently_used_role/?template_id=${id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.length === 0) {
                    this.props.enqueueSnackbar('Goto Roles tab to initiate default roles!', {
                        variant: 'warning'
                    });
                } else {
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        element.fm_checked = false
                    }
                    this.setState({ roles: JSON.parse(JSON.stringify(res.data)), isLoading: false }, () => {
                        old_roles = this.state.roles
                        this.props.getRoles(this.state.roles);
                        if (this.props.editData) {
                            let selected_roles = this.props.value.selected_roles
                            for (let j = 0; j < this.state.roles.length; j++) {
                                const element1 = this.state.roles[j];
                                if (selected_roles.includes(element1.t_role)) {
                                    element1.fm_checked = true
                                }
                            }
                            this.state.roles.sort((a, b) => Number(b.fm_checked) - Number(a.fm_checked));
                            this.setState({ roles: this.state.roles }, () => { this.forceUpdate() })
                            old_tasks = this.props.value.tasks
                        }
                    })

                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    handleTextChange(e) {
        this.setState({ openWbsName: e.target.value })
    }

    render() {

        const handleTemplateClose = (e) => {
            this.setState({ openTemplate: false })
        };

        const handleUserRoleClose = () => {
            this.setState({ searchUserRoles: false })
        };

        return (
            <div>
                <Grid container spacing={1}>
                    {!this.props.editData ?
                        <Grid item xs >
                            <Box sx={{ display: "flex", justifyContent: 'center', }}>
                                <Box>
                                    <Tooltip placement="top" title={"Search wbs"}>
                                        <TextField
                                            size='small'
                                            autoComplete='off'
                                            placeholder="Search WBS"
                                            id="search_wbs"
                                            onChange={(e) => this.handleWbsSearch(e.target.value)}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                                // endAdornment: () =>  wbsSearch !== '' ? <Box fontSize='small' sx={{ cursor: 'pointer', marginRight: '5px' }} onClick={() => this.clearSearch('wbs')} >Clear</Box> : null
                                                endAdornment: <InputAdornment position="end">
                                                    {wbsSearch !== '' ? <CloseIcon fontSize={'small'} sx={{ cursor: 'pointer', marginRight: '5px' }} onClick={() => this.clearSearch('wbs')} /> : null}
                                                </InputAdornment>
                                            }}
                                            value={wbsSearch}
                                            variant='outlined'
                                        />
                                        {/* <Search>
                                            <SearchIconWrapper>
                                                <SearchIcon />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                                placeholder="Search WBS"
                                                inputProps={{
                                                    'aria-label': 'search',
                                                }}
                                                onChange={(e) => this.handleWbsSearch(e.target.value)}
                                                endAdornment={
                                                    wbsSearch !== '' ? <Box fontSize='small' sx={{ cursor: 'pointer', marginRight: '5px' }} onClick={() => this.clearSearch('wbs')} >Clear</Box> : null
                                                }
                                                value={wbsSearch}
                                            />
                                        </Search> */}
                                    </Tooltip>
                                    {this.state.showWbsHelper ?
                                        <Box display="flex" justifyContent={'center'} fontSize={screenSizeCheck() ? '10px' : '11px'}>
                                            Clear the search to show all wbs
                                        </Box>
                                        : null}
                                </Box>
                                <Box display='flex' alignItems={'center'} padding='10px'>
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            this.setState({ openTemplate: true })
                                        }}
                                        color={getMode() ? 'secondary' : 'primary'}
                                    >
                                        FindWBS
                                    </Link>
                                </Box>
                            </Box>
                            <List ref={this.refCallback} sx={{
                                width: '100%',
                                // bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: this.state.scrollHeight + "px",
                                '& ul': { padding: 0 },
                            }}>
                                {/* .filter(ele => !availableWBStemplate.includes(ele.id)) */}
                                {this.state.wbs.map((value, i) =>
                                    <ListItem key={i}>
                                        <ListItemButton onClick={() => this.selectWBS(value)} dense selected={selectedWbs === value.showName} ref={(el) => { if (selectedWbs === value.showName) this.messagesEnd = el; }}>
                                            {/* <ListItemText primary={<><>{value.showName}</> <>({availableWBStemplate.includes(value.id) ? <Box>already estimated</Box> }: {existCartList.includes(value.id)} ? <Box>'already exist in cart'</Box> : "")</></>} />
                                         */}
                                            <ListItemText primary={value.showName} secondary={!value.editable ? (availableWBStemplate.includes(value.id) ? <Box color={'#00cc00'}>Already estimated</Box> : existCartList.includes(value.id) ? <Box color={'#ff9900'}>Already exist in cart (Click to edit)</Box> : null) : ''} />
                                        </ListItemButton>
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                        : null}
                    <Grid item xs >
                        <Box sx={{ display: "flex", justifyContent: 'center', }}>
                            <Box>
                                <Tooltip placement="top" title={"Search Tasks"}>
                                    <TextField
                                        size='small'
                                        autoComplete='off'
                                        placeholder="Search Tasks"
                                        id="search_tasks"
                                        onChange={(e) => this.handleTaskSearch(e.target.value)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                            // endAdornment: () =>  wbsSearch !== '' ? <Box fontSize='small' sx={{ cursor: 'pointer', marginRight: '5px' }} onClick={() => this.clearSearch('wbs')} >Clear</Box> : null
                                            endAdornment: <InputAdornment position="end">
                                                {tasksSearch !== '' ? <CloseIcon fontSize={'small'} sx={{ cursor: 'pointer', marginRight: '5px' }} onClick={() => this.clearSearch('tasks')} /> : null}
                                            </InputAdornment>
                                        }}
                                        value={tasksSearch}
                                        variant='outlined'
                                    />
                                    {/* <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search Tasks"
                                            inputProps={{ 'aria-label': 'search' }}
                                            onChange={(e) => this.handleTaskSearch(e.target.value)}
                                            endAdornment={
                                                tasksSearch !== '' ? <Box fontSize='small' sx={{ cursor: 'pointer', marginRight: '5px' }} onClick={() => this.clearSearch('tasks')} >Clear</Box> : null
                                            }
                                            value={tasksSearch}
                                        />
                                    </Search> */}
                                </Tooltip>
                                {this.state.showTasksHelper ?
                                    <Box display="flex" justifyContent={'center'} fontSize={screenSizeCheck() ? '10px' : '11px'}>
                                        Clear the search to show all tasks
                                    </Box>
                                    : null}
                            </Box>
                        </Box>
                        <List ref={this.refCallback} sx={{
                            width: '100%',
                            // bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: this.state.scrollHeight + "px",
                            '& ul': { padding: 0 },
                        }}>
                            {this.props.value && this.props.value.tasks.length === 0 ?
                                <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: this.state.scrollHeight + "px" }}>There is no tasks. Goto next step to add tasks</Box>
                                :
                                this.state.tasks.map((value, i) =>
                                    <ListItem key={i}>
                                        <ListItemButton onClick={(e) => {
                                            if (!this.props.isEstimateEdit) {
                                                this.tasksOnChange(i, value.roles)
                                            }
                                        }} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={value.fm_checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    disabled={this.props.isEstimateEdit}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={value.name} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            }
                        </List>
                    </Grid>
                    <Grid item xs>
                        <Box sx={{ display: "flex", justifyContent: 'center', }}>
                            <Box>
                                <Tooltip placement="top" title={"Search Roles"}>
                                    <TextField
                                        size='small'
                                        autoComplete='off'
                                        placeholder="Search Roles"
                                        id="search_roles"
                                        onChange={(e) => this.handleRolesSearch(e.target.value)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                            // endAdornment: () =>  wbsSearch !== '' ? <Box fontSize='small' sx={{ cursor: 'pointer', marginRight: '5px' }} onClick={() => this.clearSearch('wbs')} >Clear</Box> : null
                                            endAdornment: <InputAdornment position="end">
                                                {rolesSearch !== '' ? <CloseIcon fontSize={'small'} sx={{ cursor: 'pointer', marginRight: '5px' }} onClick={() => this.clearSearch('roles')} /> : null}
                                            </InputAdornment>
                                        }}
                                        value={rolesSearch}
                                        variant='outlined'
                                    />
                                    {/* <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search Roles"
                                            inputProps={{ 'aria-label': 'search' }}
                                            onChange={(e) => this.handleRolesSearch(e.target.value)}
                                            endAdornment={
                                                rolesSearch !== '' ? <Box fontSize='small' sx={{ cursor: 'pointer', marginRight: '5px' }} onClick={() => this.clearSearch('roles')} >Clear</Box> : null
                                            }
                                            value={rolesSearch}
                                        />
                                    </Search> */}
                                </Tooltip>
                                {this.state.showRolesHelper ?
                                    <Box display="flex" justifyContent={'center'} fontSize={screenSizeCheck() ? '10px' : '11px'}>
                                        Clear the search to show all roles
                                    </Box>
                                    : null}
                            </Box>
                            <Box display='flex' alignItems={'center'} padding='10px'>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        this.setState({ searchUserRoles: true })
                                    }}
                                    color={getMode() ? 'secondary' : 'primary'}
                                >
                                    FindUserRoles
                                </Link>
                            </Box>
                        </Box>
                        <List ref={this.refCallback} sx={{
                            width: '100%',
                            // bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: this.state.scrollHeight + "px",
                            '& ul': { padding: 0 },
                        }}>
                            {this.state.isLoading ?
                                <Box display='flex' justifyContent={'center'} alignItems={'center'} height={500}> <CircularProgress /></Box> :
                                this.state.roles.map((value, i) =>
                                    <ListItem key={i}>
                                        <ListItemButton onClick={(e) => this.rolesOnChange(i)} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={value.fm_checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                // disabled={value.fm_disabled}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={value.name + " (" + (value.grade).toUpperCase() + ")"} />
                                        </ListItemButton>
                                    </ListItem>
                                )}
                        </List>
                    </Grid>
                </Grid>

                <Dialog open={this.state.openTemplate} fullWidth onClose={handleTemplateClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Search WBS from Template</DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            id="combo-box-demo"
                            options={this.props.dataT}
                            getOptionLabel={(option) => option.name}
                            onChange={this.onTemplateFindChange}
                            style={{ marginTop: '15px' }}
                            renderInput={(params) => <TextField {...params} label="Type Task Name" variant="outlined" />}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleTemplateClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.searchUserRoles}
                    fullWidth
                    maxWidth={'sm'}
                    onClose={handleUserRoleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="customized-dialog-title2" >
                        Find Role of a user
                    </DialogTitle>
                    <DialogContent>
                        <FindUserByRole />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUserRoleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

            </div >
        )
    }
}

export default withSnackbar(TaskAndRole)