import { Box, CircularProgress, Tooltip } from '@material-ui/core'
import React, { Component } from 'react'
import StackedBudgetCost from './StackedBudgetCost'
import StackedCostToDate from './StackedCostToDate'


class CustomBudgetCost extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: this.props.data
        }
    }

    render() {
        return (
            <div>
                <Box display={'flex'} flexDirection='column' justifyContent={'center'} width={250} padding="5px">
                    <Box display={'flex'} flexDirection='row' alignItems={'center'} width='100%'>
                        <Box fontSize={'10px'} width='20%'>
                            Estimate
                        </Box>
                        <Tooltip title={<div>Budget Cost: {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.props.data.budget_cost)}<br />Cost At Completion: {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.props.data.cost_at_completion)}<br />Variance Cost :{new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.props.data.varience_cost)}</div>}>
                            <Box display={'flex'} flexDirection='row' justifyContent={'left'} alignItems='center' style={{ cursor: 'pointer' }} marginLeft='5px' width='80%'>
                                <StackedBudgetCost data={this.props.data} />
                            </Box>
                        </Tooltip>
                    </Box>
                    <Box display={'flex'} flexDirection='row' marginTop='15px' alignItems={'center'} width='100%'>
                        <Box fontSize={'10px'} width='20%'>
                            Actual
                        </Box>
                        <Tooltip title={<div>Cost To Date: {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.props.data.cost_to_date)}<br />Cost At Completion: {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.props.data.cost_at_completion)}</div>}>
                            <Box marginLeft='5px' style={{ cursor: 'pointer' }} width='80%'>
                                {this.props.data.cost_to_date || this.props.data.cost_to_date === 0 ? <StackedCostToDate data={this.props.data} /> : (<CircularProgress style={{ height: '15px', width: '15px' }} />)}
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </div >
        )
    }
}

export default CustomBudgetCost