import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import Service from '../Networking/networkutils'
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import CustomBudgetHours from './BudgetHours/CustomBudgetHours';
import CustomBudgetCost from './BudgetCost/CustomBudgetCost';
import BaselineActualDate from './BaselineActualDate/BaselineActualDate';
import CustomSPICPI from './SPICPI/CustomSPICPI';
import CustProjDetails from './CustProjDetails';
import { getDarkMode, SPICPIConditions } from '../Helper/CommonThings';
import { Box, Card, CardActionArea, CardContent, CircularProgress, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import moment from 'moment';
// import ToggleButton from '@material-ui/lab/ToggleButton';
// import CheckIcon from '@material-ui/icons/Check';
import { getSPICPIColor } from '../MainDashBoard/ColorTheme/ColorTheme';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import Popover from '@material-ui/core/Popover';
import HoeComments from './HoeComments';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class HoeGate6Table extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoading: false,
            SPIAvg: 0,
            CPIAvg: 0,
            TotVarCost: 0,
            TotHrsCome: 0,
            TotCostCome: 0,
            costLoading: false,
            showGraph: 0,
        }
    }

    componentDidMount() {
        if (!this.props.isHoe) {
            this.fetchData(user.id)
        }
    }

    fetchData(data) {
        this.setState({ isLoading: true, data: [], SPIAvg: 0, CPIAvg: 0, TotVarCost: 0, TotHrsCome: 0, TotCostCome: 0, showGraph: 0, })
        Service.get2(`/bb/api/tender/project/report_api/?hoe_user=${data}&type=${this.props.value === 0 ? 'available_workbook' : "estimate_effort"}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data.tender_project,
                    SPIAvg: res.data.spi_avg,
                    CPIAvg: res.data.cpi_avg,
                    TotVarCost: res.data.total_varience_cost,
                    TotHrsCome: res.data.total_hours_to_come,
                    isLoading: false,
                }, () => {
                    this.getCost(res.data.actual_cost_sequence, 0)
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    emptyData() {
        this.setState({ data: [], isLoading: true }, () => {
            this.setState({ isLoading: false, costLoading: false, showGraph: 0, })
        })
    }

    getCost(data, index) {
        if (data[index] && data[index].length !== 0) {
            var costToCome = 0
            this.setState({ costLoading: true })
            Service.get2(`/bb/api/tender/user/task/cost_to_date/?tender_ids=${data[index].toString()}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    var datas = this.state.data
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        for (let j = 0; j < datas.length; j++) {
                            const element1 = datas[j];
                            if (element.tender_id === element1.id) {
                                element1.cost_to_date = element.cost_to_date
                                element1.cost_to_come = (element1.cost_at_completion - element.cost_to_date)
                                if (element1.cost_to_come < 0) {
                                    element1.cost_to_come = 0
                                }
                                costToCome = costToCome + element1.cost_to_come
                            }
                        }
                    }
                    this.setState({ data: datas, TotCostCome: this.state.TotCostCome + costToCome, costLoading: false }, () => {
                        this.getCost(data, ++index)
                    })

                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    render() {

        const darkMode = getDarkMode() === 'dark'

        const handleToggle = (event, newValue) => {
            if (newValue !== null) {
                this.setState({ showGraph: newValue, });
            }
        };

        return (
            <div>
                <Box flexDirection="column" display="flex">
                    {this.state.data.length !== 0 && this.props.value !== 1 ?
                        <Box marginTop="5px" flexDirection="row" display="flex" padding="10px" >
                            <Container fluid >
                                <Row>
                                    <Col>
                                        <Card style={{ background: darkMode ? '#ccb800' : '#FFF176' }}>
                                            <CardActionArea>
                                                <CardContent>
                                                    <Box flexDirection="row" display="flex" justifyContent="center" >
                                                        <Typography >
                                                            SPI
                                                        </Typography>
                                                    </Box>
                                                    <Box flexDirection="row" display="flex" justifyContent="center" >
                                                        <Typography >
                                                            {this.state.SPIAvg}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card style={{ background: darkMode ? '#9fad1f' : '#DCE775' }}>
                                            <CardActionArea>
                                                <CardContent>
                                                    <Box flexDirection="row" display="flex" justifyContent="center">
                                                        <Typography >
                                                            CPI
                                                        </Typography>
                                                    </Box>
                                                    <Box flexDirection="row" display="flex" justifyContent="center" >
                                                        <Typography >
                                                            {this.state.CPIAvg}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Tooltip title={'Total Variance Cost = Cost at Completion - Budget Cost'}>
                                            <Card style={{ background: darkMode ? '#e68a00' : '#FFB74D' }}>
                                                <CardActionArea>
                                                    <CardContent>
                                                        <Box flexDirection="row" display="flex" justifyContent="center" >
                                                            <Typography >
                                                                Total Variance Cost
                                                            </Typography>
                                                        </Box>
                                                        <Box flexDirection="row" display="flex" justifyContent="center" alignItems={'center'}>
                                                            <Typography >
                                                                {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.state.TotVarCost)}
                                                            </Typography>

                                                            {this.state.TotVarCost > 0 ? <ArrowUpwardIcon color='error' fontSize='small' /> : <ArrowDownwardIcon color='primary' fontSize='small' />}
                                                        </Box>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Tooltip>
                                    </Col>
                                    <Col>
                                        <Card style={{ background: darkMode ? '#006363' : '#ccffff' }}>
                                            <CardActionArea>
                                                <CardContent>
                                                    <Box flexDirection="row" display="flex" justifyContent="center" >
                                                        <Typography>
                                                            Total Hours to Come
                                                        </Typography>
                                                    </Box>
                                                    <Box flexDirection="row" display="flex" justifyContent="center" >
                                                        <Typography>
                                                            {this.state.TotHrsCome}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card style={{ background: darkMode ? '#c71f1f' : '#EF9A9A' }}>
                                            <CardActionArea>
                                                <CardContent>
                                                    <Box flexDirection="row" display="flex" justifyContent="center" >
                                                        <Typography>
                                                            Total Cost to Come
                                                        </Typography>
                                                    </Box>
                                                    <Box flexDirection="row" display="flex" justifyContent="center" >
                                                        {this.state.costLoading ? <CircularProgress style={{ height: '20px', width: '20px' }} /> :
                                                            <Typography>
                                                                {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.state.TotCostCome)}
                                                            </Typography>}
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </Box > : null}
                </Box>
                <Box marginTop='10px'>
                    <MaterialTable
                        title={
                            <div>
                                <Box display='flex' flexDirection={'row'} alignItems='center'>
                                    <Box marginRight={'30px'} fontSize={'20px'} fontWeight={500}>
                                        Stage 6
                                    </Box>
                                    <ToggleButtonGroup
                                        color="primary"
                                        size="small"
                                        // style={{ marginBottom: '3px' }}
                                        value={this.state.showGraph}
                                        onChange={handleToggle}
                                        exclusive
                                        aria-label="text formatting"
                                    >
                                        <ToggleButton value={0} aria-label="your_workbook">
                                            Table View
                                        </ToggleButton>
                                        <ToggleButton value={1} aria-label="all_workbook">
                                            Chart View
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    <Box marginLeft={'10px'}>
                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                            {(popupState) => (
                                                <div>
                                                    <Tooltip title='SPI/CPI Conditions'>
                                                        <IconButton size='medium'  {...bindTrigger(popupState)}>
                                                            <ColorLensIcon fontSize='medium' />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Popover
                                                        {...bindPopover(popupState)}
                                                        anchorOrigin={{
                                                            vertical: 'center',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                    // onClose={() => this.updatedResponse(popupState)}
                                                    >
                                                        <Box width={500} heigth={500} >
                                                            <MaterialTable
                                                                title=''
                                                                columns={[
                                                                    {
                                                                        title: 'SPI', field: 'spi',
                                                                    },
                                                                    {
                                                                        title: 'CPI', field: 'cpi',
                                                                    },
                                                                    {
                                                                        title: 'Status', field: 'status',
                                                                    },
                                                                    {
                                                                        title: 'Color', field: 'color',
                                                                        render: rowData => <span style={{ height: '15px', width: '20px', backgroundColor: rowData.color, display: 'inline-block' }}></span>
                                                                    },
                                                                ]}
                                                                data={SPICPIConditions()}
                                                                options={{
                                                                    padding: "dense",
                                                                    paging: false,
                                                                    search: false,
                                                                    toolbar: false
                                                                }}
                                                            />
                                                        </Box>
                                                    </Popover>
                                                </div>
                                            )}
                                        </PopupState>
                                    </Box>
                                </Box>
                            </div>}
                        columns={[
                            {
                                title: 'Project', field: 'tender_name', sorting: false,
                                render: rowData => <CustProjDetails data={rowData} />
                            },
                            {
                                title: '', field: 'spi', sorting: false, searching: 'false', hidden: this.props.value === 1,
                                headerStyle: { maxWidth: 30 }, cellStyle: { maxWidth: 30 },
                                render: rowData => (rowData.spi !== null && rowData.cpi !== null) ?
                                    <Tooltip title={getSPICPIColor(rowData.spi, rowData.cpi).status}>
                                        <span style={{ height: '15px', width: '20px', backgroundColor: getSPICPIColor(rowData.spi, rowData.cpi).color, display: 'inline-block' }}></span>
                                    </Tooltip> : null
                            },
                            {
                                title: 'Baseline Start', field: 'from_date', sorting: false, hidden: this.state.showGraph === 1, type: 'date',
                                // render: rowData => moment(rowData.from_date).format('DD-MM-YYYY')
                            },
                            {
                                title: 'Baseline End', field: 'to_date', sorting: false, hidden: this.state.showGraph === 1, type: 'date',
                                // render: rowData => moment(rowData.to_date).format('DD-MM-YYYY')
                            },
                            {
                                title: 'Actual Start', field: 'act_exp_start', sorting: false, hidden: this.state.showGraph === 1, type: 'date',
                                // render: rowData => moment(rowData.act_exp_start).format('DD-MM-YYYY')
                            },
                            {
                                title: 'Actual End', field: 'act_exp_finish', sorting: false, hidden: this.state.showGraph === 1, type: 'date',
                                // render: rowData => moment(rowData.act_exp_finish).format('DD-MM-YYYY')
                            },
                            {
                                title: 'Budget Hours', field: 'budget_hours', sorting: false, hidden: this.state.showGraph === 1,
                                render: rowData => rowData.budget_hours.toFixed(1)
                            },
                            {
                                title: 'Hours At Completion', field: 'hours_at_completion', sorting: false, hidden: this.state.showGraph === 1,
                                // render: rowData => moment(rowData.act_exp_finish).format('DD-MM-YYYY')
                            },
                            {
                                title: 'Hours To Date', field: 'hours_to_date', sorting: false, hidden: this.state.showGraph === 1,
                                // render: rowData => moment(rowData.act_exp_finish).format('DD-MM-YYYY')
                            },
                            {
                                title: 'Hours To Come', field: 'hours_to_come', sorting: false, hidden: this.state.showGraph === 1,
                                // render: rowData => moment(rowData.act_exp_finish).format('DD-MM-YYYY')
                            },
                            {
                                title: 'Budget Cost', field: 'budget_cost', sorting: false, hidden: this.state.showGraph === 1,
                                render: rowData => new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.budget_cost)
                            },
                            {
                                title: 'Cost At Completion', field: 'cost_at_completion', sorting: false, hidden: this.state.showGraph === 1,
                                render: rowData => new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.cost_at_completion)
                            },
                            {
                                title: 'Cost To Date', field: 'cost_to_date', sorting: false, hidden: this.state.showGraph === 1,
                                render: rowData => rowData.cost_to_date || rowData.cost_to_date === 0 ? new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.cost_to_date) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)
                            },
                            {
                                title: 'Cost To Come', field: 'cost_to_come', sorting: false, hidden: this.state.showGraph === 1,
                                render: rowData => rowData.cost_to_come || rowData.cost_to_come === 0 ? new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.cost_to_come) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)
                            },
                            {
                                title: 'Variance Cost', field: 'varience_cost', sorting: false, hidden: this.state.showGraph === 1,
                                render: rowData => new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.varience_cost)
                            },
                            {
                                title: 'SPI', field: 'spi', sorting: false, hidden: this.state.showGraph === 1,
                            },
                            {
                                title: 'CPI', field: 'cpi', sorting: false, hidden: this.state.showGraph === 1,
                            },
                            {
                                title: 'UnApproved Changes', field: 'unapproved_change', sorting: false, hidden: this.state.showGraph === 1 || this.props.value === 1,
                                render: rowData => rowData.unapproved_change ? new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.unapproved_change) : null
                            },
                            {
                                title: 'Approved Changes', field: 'approved_change', sorting: false, hidden: this.state.showGraph === 1 || this.props.value === 1,
                                render: rowData => rowData.approved_change ? new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.approved_change) : null
                            },
                            {
                                title: 'Baseline/Actual Date', field: 'from_date', sorting: false, hidden: this.state.showGraph === 0,
                                render: rowData => <BaselineActualDate data={rowData} />
                            },
                            {
                                title: 'Hours', field: 'budget_hours', sorting: false, hidden: this.state.showGraph === 0,
                                render: rowData => <CustomBudgetHours data={rowData} />
                            },
                            {
                                title: 'Cost', field: 'budget_cost', sorting: false, hidden: this.state.showGraph === 0,
                                render: rowData => <CustomBudgetCost data={rowData} />
                            },
                            {
                                title: 'SPI/CPI', field: 'spi', sorting: false, hidden: this.state.showGraph === 0,
                                render: rowData => <CustomSPICPI data={rowData} />
                            },
                            {
                                title: '% Updated On', field: 'percentage_updated_on',
                                render: rowData => moment(rowData.percentage_updated_on).format('DD-MM-YYYY')
                            },
                        ]}

                        data={this.state.data}
                        isLoading={this.state.isLoading}
                        options={{
                            padding: "dense",
                            paging: false,
                            search: true,
                            maxBodyHeight: this.props.value === 1 ? "60vh" : '56vh'
                        }}
                        actions={(this.props.value !== 1) ? ([
                            rowData => ({
                                icon: 'visibility',
                                tooltip: 'PSR',
                                onClick: (event, rowData) => { window.open("/report/psr/" + rowData.id + "/" + rowData.audit_status + "/") },
                            }),
                            rowData => ({
                                icon: 'equalizer',
                                tooltip: 'EVP',
                                onClick: (event, rowData) => { window.open("/report/evp/" + rowData.id + "/") },
                            }),
                            rowData => ({
                                icon: () => <HoeComments data={rowData} />,
                            }),
                        ]) : ([
                            rowData => ({
                                icon: () => <HoeComments data={rowData} />,
                            })
                        ]
                        )}
                    />
                </Box>
            </div>
        )
    }
}

export default withSnackbar(HoeGate6Table)
