import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { getCookie } from '../Helper/CookieHelper';
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../Helper/CommonThings';
import { Box, Divider } from '@material-ui/core';
import Service from '../Networking/networkutils';
import { errorMessage } from '../Helper/ErrorMessage';
import moment from 'moment';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import { getToken } from "firebase/messaging";
// import { cloudMessaging } from '../firebase';
import Link from '@mui/material/Link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, } from '@material-ui/core'
import { styled } from '@mui/system';
import { Alert, Badge, Button } from '@mui/material';
import LinesEllipsis from 'react-lines-ellipsis'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import NotificationsLists from '../Settings/NotificationsLists';
import NotificationPermission from '../Helper/NotificationPermission';

var token = getCookie("bb_ts_token");

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -14,
        top: -8,
    },
}));


class NotificationsLog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
            token: null,
            showMarkAllRead: false,
            open: false
        }

        this.notificationPermission = React.createRef()
    }

    componentDidMount() {
        if (this.props.userData) {
            this.fetchNotifications()
        }
        // getToken(cloudMessaging, { vapidKey: 'BNNgkEtOcJQN9hW5EutQMFPhK1m2jFMHF8KRdj3hhdnvanToSnEXcxMzKlQG0yhIcSmnzUk3eFJNwl1B94cO_l4' }).then((currentToken) => {
        //     let token = currentToken
        //     this.setState({ token })
        // }).catch((err) => {
        //     console.log('An error occurred while retrieving token. ', err);
        // });
    }

    fetchNotifications() {
        this.setState({ isLoading: true })
        Service.get(`/bb/api/notification/log/get_notications_data/?user_id=${this.props.userData.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    let un_read = []
                    for (let j = 0; j < element.list_of_notification.length; j++) {
                        const element1 = element.list_of_notification[j];
                        if (!element1.is_read) {
                            un_read.push(element1)
                        }
                    }
                    element.un_read = un_read
                    element.show_status = "Show unread"
                    element.data_to_show = element.list_of_notification
                }
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.un_read.length > 0) {
                        this.setState({ showMarkAllRead: true })
                        break
                    }
                }
                this.setState({
                    data: res.data,
                    isLoading: false
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    changeHref(data) {
        if (!data.is_read) {
            Service.put(`/bb/api/notification/log/notification_log_data_for_user_token/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    id: data.id
                }
            })
                .then(res => {
                    this.fetchNotifications()
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
        if (data.path) {
            window.history.pushState({}, null, "/")
            window.location.href = data.path
        }
    }

    markAllRead() {
        Service.get(`/bb/api/notification/log/mark_all_as_read/?user=${this.props.userData.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ showMarkAllRead: false })
                this.fetchNotifications()
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    changeTruncate(index, i, is_truncate) {
        let data = this.state.data
        data[index].list_of_notification[i].is_truncate = !is_truncate
        this.setState({ data })
    }

    handlePanelChange(index, is_expanded) {
        let data = this.state.data
        data[index].is_expanded = !is_expanded
        this.setState({ data })
    }

    showStatus(value, index) {
        let data = this.state.data
        if (value.show_status === 'Show unread') {
            data[index].data_to_show = data[index].un_read
            data[index].show_status = "Show all"
        } else {
            data[index].data_to_show = data[index].list_of_notification
            data[index].show_status = "Show unread"
        }
        this.setState({ data })
    }

    checkNotify() {
        if (this.notificationPermission && this.notificationPermission.current) {
            this.notificationPermission.current.checkNotify()
        }
    }

    render() {
        const handleClose = () => {
            this.setState({ open: false })
        };
        return (
            <div>
                <NotificationPermission ref={this.notificationPermission} />
                {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                    <div>
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} marginLeft={'5px'} marginRight={'5px'}>
                            <Box flexGrow={1}>
                                <Link
                                    component="button"
                                    sx={{ fontSize: '12px' }}
                                    onClick={() => this.setState({ open: true })}>
                                    Customise
                                </Link>
                            </Box>
                            {this.state.showMarkAllRead ?
                                <Box width={'100%'} flexDirection={'row-reverse'} display={'flex'}>
                                    <Link
                                        component="button"
                                        sx={{ fontSize: '12px' }}
                                        onClick={() => this.markAllRead()}>
                                        Mark All as Read
                                    </Link>
                                </Box> : null}
                        </Box>
                        {this.state.data.length === 0 ?
                            <Box display={'flex'} flexDirection={'column'} justifyContent='center' alignItems='center' style={{ height: window.innerHeight - 80 }}>
                                <NotificationsIcon fontSize='large' />
                                <Box mt={"5px"} fontSize={'15px'}>
                                    No notifications
                                </Box>
                                {localStorage.getItem('push-denied') === "true" ? <Alert severity="warning"
                                    action={
                                        <Button color="inherit" size="small" variant='outlined' onClick={() => {
                                            this.checkNotify()
                                        }}>Enable</Button>
                                    }
                                >Push Notification is disabled. Please enable it to receive Push Notifications.</Alert> : null}
                            </Box> :
                            <Box style={{ height: (window.innerHeight - 80), overflowY: 'scroll' }}>
                                {localStorage.getItem('push-denied') === "true" ? <Alert severity="warning"
                                    action={
                                        <Button color="inherit" size="small" variant='outlined' onClick={() => {
                                            this.checkNotify()
                                        }}>Enable</Button>
                                    }
                                >Push Notification is disabled. Please enable it to receive Push Notifications.</Alert> : null}
                                {this.state.data.map((value, index) => (
                                    <Accordion expanded={value.is_expanded} key={value.id} style={{ width: '100%' }} onChange={(e) => this.handlePanelChange(index, value.is_expanded)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header">
                                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} width={'100%'}>
                                                <Box fontSize='16px' fontWeight='Bold' flexGrow={1}>
                                                    <StyledBadge anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }} badgeContent={value.notification_count} color="secondary">
                                                        {value.name}
                                                    </StyledBadge>
                                                </Box>
                                                {value.is_expanded ?
                                                    <Box>
                                                        <Link
                                                            sx={{ fontSize: '11px' }}
                                                            onClick={(e) => { e.stopPropagation(); this.showStatus(value, index) }}>
                                                            {value.show_status}
                                                        </Link>
                                                    </Box> : null}
                                            </Box>
                                        </AccordionSummary>
                                        <>
                                            {value.data_to_show.length === 0 ?
                                                <Box mb={1} width='100%' display={'flex'} flexDirection={'column'} justifyContent='center' alignItems='center'>
                                                    {value.list_of_notification.length === 0 ? "No notifications" : "No unread notifications. click on the above show all button to show all notifications"}
                                                </Box> :
                                                value.data_to_show.map((item, i) =>
                                                    <Box key={i} >
                                                        <Box marginLeft={'15px'} marginRight={'15px'} display={'flex'} flexDirection={'column'} onClick={() => this.changeHref(item)} className='noti' >
                                                            <Box marginTop={"5px"} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                                <Box flexGrow={1} fontSize={!item.is_read ? '16px' : '13px'} fontWeight={!item.is_read ? 'Bold' : 'Regular'} color={getPrimaryColor()}>
                                                                    {item.title}
                                                                </Box>
                                                            </Box>

                                                            <Box fontSize={!item.is_read ? '14px' : '11px'} fontWeight={!item.is_read ? 500 : 'Light'}>
                                                                {!item.is_truncate ?
                                                                    <LinesEllipsis
                                                                        text={item.body}
                                                                        maxLine={!item.is_read ? '3' : '2'}
                                                                        ellipsis={<>...<Link onClick={(e) => { e.stopPropagation(); this.changeTruncate(index, i, item.is_truncate) }}>read more</Link></>}
                                                                        trimRight
                                                                        basedOn='letters'
                                                                    /> :
                                                                    <div>
                                                                        {item.body}
                                                                        <><Link onClick={(e) => { e.stopPropagation(); this.changeTruncate(index, i, item.is_truncate) }}
                                                                        >less
                                                                        </Link></>
                                                                    </div>}
                                                            </Box>
                                                            <Box fontWeight={!item.is_read ? 500 : ''} mt={"2px"} mb={"2px"} display={'flex'} flexDirection={'row-reverse'} style={{ width: '100%' }} fontSize={'11px'} marginRight={'15px'}>
                                                                {moment(item.date_created).format("DD MMM YYYY hh:mm A")}
                                                            </Box>
                                                            <Box style={{ width: '100%' }}>
                                                                <Divider style={{ width: '100%' }} textAlign='center'>
                                                                </Divider>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )}
                                        </>
                                    </Accordion>
                                ))}
                            </Box>
                        }
                    </div>
                }

                <Dialog
                    open={this.state.open}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title" >
                    <DialogTitle id="draggable-dialog-title">
                        Notification Settings
                    </DialogTitle>
                    <DialogContent>
                        <NotificationsLists />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withSnackbar(NotificationsLog);


