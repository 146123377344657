import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    TextField,
    IconButton,
    Grid
} from '@material-ui/core';
// import RoleMapping from '../Estimate/Tender/RoleMapping';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import TeamRoleMapping from './TeamRoleMapping';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import SecuredProjectRoles from '../SecuredWorks/SecuredProjectRoles';
// import TaskLevelAllocation from './TaskLevelAllocation/TaskLevelAllocation';
import TaskLevelTabs from './TaskLevelAllocation/TaskLevelTabs';
// import ReactTooltip from 'react-tooltip';
import { errorMessage } from '../Helper/ErrorMessage';
import WorkIcon from '@material-ui/icons/Work';
import MuiAlert from '@material-ui/lab/Alert';
import SearchIcon from '@material-ui/icons/Search';
import { getPrimaryColor } from '../Helper/CommonThings';
import TeamTaskAllocation from '../TeamTaskAllocation/TeamTaskAllocation';

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const leadDiscipline = { 'ohl': 'OHL', 'cabling': 'Cabling', 'substation': 'Substation', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical', 'edc': 'EDC' }
const findDuplicates = (arr) => {
    let sorted_arr = arr.slice().sort(); // You can define the comparing function here. 
    // JS by default uses a crappy string compare.
    // (we use slice to clone the array so the
    // original array won't be modified)
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] === sorted_arr[i]) {
            results.push(sorted_arr[i]);
        }
    }
    return results;
}

const token = getCookie("bb_ts_token");
// const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class UserTaskMap extends Component {

    constructor(props) {
        super(props)

        this.state = {
            roles: [],
            deliSch: [],
            isLoading: false,
            isLoading1: false,
            tasks: [],
            wbs: [],
            mapped: [],
            rolesTemplate: [],
            columns: [],
            open: false,
            mapRoles: {},
            mapTask: {},
            projectLook: {},
            checkedB: false,
            proj_wbs_name: '',
            wbs_task_name: '',
            taskPercent: 0,
            openTaskUpdate: false,
            perTaskData: {},
            perTaskLoading: false,
            allRoles: {},
            selectedProject: [],
            projects: [],
            userPdm: [],
            openTaskLevelAllocation: false,
            userRolesInfo: [],
            searchUserRoles: false,
            findUser: {
                name: '',
                primary_role: '',
                secondary_role: ''
            },
            lead_diciplines: [
                { id: 'ohl', name: 'OHL' },
                { id: 'cabling', name: 'Cabling' },
                { id: 'substation', name: 'Substation' },
                { id: 'multi_utilities', name: 'Multi Utilities' },
                { id: 'technical', name: 'Technical' },
                { id: 'edc', name: 'EDC' }
            ],
            selectedLead: []
        }

        this.teamTaskAllocation = React.createRef();
    }

    componentDidMount() {

        // console.log(access)

        this.setState({ isLoading1: true })
        Service.get(`/bb/api/tender/project/?tender_notnull=1&audit_status=10`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                // console.log(res.data)

                var data1 = []

                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.tenderbid_data.lead_dicipline && element.tenderbid_data.lead_dicipline !== 'trail') {
                        data1.push(element)
                    }
                }

                // console.log(data1)
                this.projects = data1
                this.setState({
                    projects: data1
                }, () => {

                    Service.get('/bb/api/tender/pdm/?uid=' + user.id, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(respro => {
                            // console.log(respro.data)
                            this.setState({
                                userPdm: respro.data
                            })

                            // alert(this.props.alluid)
                            Service.get(`/bb/api/user/with_sec_roles/?uid=` + this.props.alluid, {
                                headers: {
                                    Authorization: "Token " + token,
                                },
                            })
                                .then(res13 => {
                                    // console.log(res13.data)
                                    for (let i = 0; i < res13.data.length; i++) {
                                        const element = res13.data[i];
                                        element.name = element.first_name + " " + element.last_name;

                                    }


                                    this.setState({
                                        userRolesInfo: res13.data,
                                        isLoading1: false
                                    })

                                    // console.log(jsondata)
                                })
                                .catch(error => {
                                    errorMessage(this.props, error)
                                    this.setState({ isLoading1: false })
                                });
                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                            this.setState({ isLoading1: false })
                        });
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
                this.setState({ isLoading1: false })
            });

        this.getRoles()

    }

    getRoles() {
        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var allRoles = {}
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    allRoles[element.id] = element.name
                }
                this.setState({ allRoles: allRoles })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchOps(ids123) {
        Service.get("/bb/api/tender/project/?subproj=" + ids123 + "&audit_status=10,11", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                // var projectLook = {};
                var rolesParam = '0';
                var indevwork = '0'
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    // rolesParam = rolesParam + ',' + element.id;

                    if (element.is_subproject && element.audit_status === 11) {

                        var goon = true;
                        for (let i = 0; i < res.data.length; i++) {
                            const element1 = res.data[i];
                            if (element.subproject_pid === element1.id) {
                                goon = false;
                                break;
                            }
                        }
                        if (goon) {
                            indevwork = indevwork + "," + element.subproject_pid
                        }

                    }

                }

                indevwork = Array.from(new Set(indevwork.split(','))).toString();

                var deliData = res.data;

                deliData = deliData.concat(this.state.selectedProject)

                for (let i = 0; i < deliData.length; i++) {
                    const element = deliData[i];
                    rolesParam = rolesParam + ',' + element.id;
                }

                this.setState({ deliSch: deliData, })

                Service.get(`/bb/api/tender/ohlwbs/?pid=` + rolesParam, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res0 => {

                        Service.get(`/bb/api/tender/tasks/rolestasks/?pid=` + rolesParam + '&rid=' + this.props.roleid, {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res2 => {

                                var taskArr = []
                                // var roles1 = res.data;
                                for (let i = 0; i < res2.data.length; i++) {
                                    const element = res2.data[i];
                                    element.req_hours = element.hours + element.req_hours
                                    taskArr.push(element.t_tasks)
                                }

                                taskArr = taskArr.filter(onlyUnique)

                                for (let i = 0; i < taskArr.length; i++) {
                                    const element = taskArr[i];
                                    var roleArr = []
                                    var roleArrIndex = [];
                                    for (let j = 0; j < res2.data.length; j++) {
                                        const element1 = res2.data[j];
                                        // console.log(element1)
                                        if (element1 && element === element1.t_tasks) {
                                            roleArr.push(element1.t_role)
                                            roleArrIndex.push(j)
                                        }
                                    }
                                    var roleArr1 = findDuplicates(roleArr)
                                    // var found1index = -1;
                                    for (let k = 0; k < roleArr1.length; k++) {
                                        const element2 = roleArr1[k];
                                        var hoursinAllRole = 0
                                        var found1index = -1;
                                        for (let j = 0; j < roleArrIndex.length; j++) {
                                            const element1 = res2.data[roleArrIndex[j]];

                                            if (element1 && element === element1.t_tasks && element2 === element1.t_role && found1index !== -1) {
                                                hoursinAllRole = hoursinAllRole + element1.req_hours
                                                delete res2.data[roleArrIndex[j]]
                                            } else if (element1 && element === element1.t_tasks && element2 === element1.t_role && found1index === -1) {
                                                hoursinAllRole = hoursinAllRole + element1.req_hours
                                                found1index = roleArrIndex[j]
                                            }
                                        }
                                        res2.data[found1index].all_hours_to_this_role = hoursinAllRole
                                    }

                                    for (let m = 0; m < roleArrIndex.length; m++) {
                                        const element2 = roleArrIndex[m];
                                        if (res2.data[element2] && !(res2.data[element2].all_hours_to_this_role || res2.data[element2].all_hours_to_this_role === 0)) {
                                            res2.data[element2].all_hours_to_this_role = res2.data[element2].req_hours
                                        }
                                    }

                                }

                                this.setState({ roles: res2.data, })

                                Service.get('/bb/api/roles/?id=' + this.props.roleid, {
                                    headers: {
                                        Authorization: "Token " + token,
                                    },
                                })
                                    .then(res4 => {
                                        // console.log(res.data)
                                        this.setState({ rolesTemplate: res4.data }, () => {
                                            this.compileData()
                                        })
                                    })
                                    .catch(error => {
                                        errorMessage(this.props, error)
                                    });

                                // Service.get(`/bb/api/tender/user/task/?pid=` + rolesParam, {
                                //     headers: {
                                //         Authorization: "Token " + token,
                                //     },
                                // })
                                //     .then(res3 => {

                                //         Service.get('/bb/api/roles/', {
                                //             headers: {
                                //                 Authorization: "Token " + token,
                                //             },
                                //         })
                                //             .then(res4 => {
                                //                 // console.log(res.data)
                                //                 this.setState({ rolesTemplate: res4.data }, () => {
                                //                     this.compileData()
                                //                 })
                                //             })
                                //             .catch(error => {
                                //                 this.props.enqueueSnackbar('Something went wrong!', {
                                //                     variant: 'error'
                                //                 });
                                //             });

                                //         this.setState({ mapped: res3.data, })

                                //     })
                                //     .catch(error => {
                                //         this.props.enqueueSnackbar('Something went wrong!', {
                                //             variant: 'error'
                                //         });
                                //     });

                            })
                            .catch(error => {

                                console.log(error)
                                errorMessage(this.props, error)
                            });

                        this.setState({ wbs: res0.data, })

                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });


                // Service.get('/bb/api/tender/project/?id=' + indevwork, {
                //     headers: {
                //         Authorization: "Token " + token,
                //     },
                // })
                //     .then(respro => {

                //         deliData = deliData.concat(respro.data)

                //         for (let i = 0; i < deliData.length; i++) {
                //             const element = deliData[i];
                //             rolesParam = rolesParam + ',' + element.id;

                //             if (!element.is_subproject) {
                //                 if (element.tender_name) {
                //                     projectLook[element.id] = element.tender_name;
                //                 } else {
                //                     projectLook[element.id] = element.name;
                //                 }

                //             }

                //         }

                //         this.setState({ deliSch: deliData, projectLook: projectLook })


                //     })
                //     .catch(error => {
                //         this.props.enqueueSnackbar('Something went wrong!', {
                //             variant: 'error'
                //         });
                //     });

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }


    compileData() {
        // console.log(this.state.wbs)
        // console.log(this.state.tasks)
        // console.log(this.state.roles)
        // console.log(this.state.mapped)

        var tasks = [];
        var onRole = [];
        var columnRoles = [];
        var tasksId = '0'

        for (let i = 0; i < this.state.roles.length; i++) {
            const element = this.state.roles[i];

            var taskPro = true;
            for (let j = 0; j < tasks.length; j++) {
                const element1 = tasks[j];
                if (element && element.t_tasks === element1.id) {
                    taskPro = false;
                }
            }

            if (element && taskPro) {
                if (!element.t_tasks_data.act_exp_start) {
                    element.t_tasks_data.act_exp_start = element.t_tasks_data.from_date
                }
                if (!element.t_tasks_data.act_exp_finish) {
                    element.t_tasks_data.act_exp_finish = element.t_tasks_data.to_date
                }
                tasks.push(element.t_tasks_data)
                tasksId = tasksId + ',' + element.t_tasks_data.id;
            }

            var rolesPro = true;
            for (let j = 0; j < onRole.length; j++) {
                const element1 = onRole[j];
                if (element && element.t_role === element1) {
                    rolesPro = false;
                }
            }

            if (element && rolesPro) {
                onRole.push(element.t_role)
            }

        }

        for (let i = 0; i < onRole.length; i++) {
            const element = onRole[i];
            for (let j = 0; j < this.state.rolesTemplate.length; j++) {
                const element1 = this.state.rolesTemplate[j];
                if (element === element1.id) {
                    columnRoles.push(element1)
                }
            }
        }

        var columns = [];

        columns.push({
            title: 'Project',
            field: 'ds.subproject_pid',

            lookup: this.state.projectLook,
        })

        columns.push({
            title: 'Deliverables Schedule',
            field: 'ds.name',

        })

        columns.push({
            title: 'WBS',
            field: 'wbs.name',

        })
        columns.push({
            title: 'Task ID', field: 'id', editable: 'never',

        })
        columns.push({
            title: 'Task',
            field: 'name',
            render: rowData => rowData.decs ? rowData.decs : rowData.name,
        })

        columns.push({
            title: 'Required Start Date',
            field: 'act_exp_start',
            type: 'date',

        })
        columns.push({
            title: 'Required End Date',
            field: 'act_exp_finish',
            type: 'date',

        })
        columns.push({
            title: '%', field: 'comp_percnt',
            lookup: { 0: '0%', 5: '5%', 10: '10%', 20: '20%', 30: '30%', 40: '40%', 50: '50%', 60: '60%', 65: '65%', 70: '70%', 80: '80%', 90: '90%', 100: '100%' },
        })

        // columns.push({
        //     title: 'Allocated Hours',
        //     field: 'pld_hrs',
        //     defaultSort: (this.state.checkedB) ? ('desc') : (null),
        //     render: rowData => (rowData.pld_hrs || rowData.pld_hrs === 0) ? (<Typography>{rowData.pld_hrs}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)
        // })

        // columns.push({
        //     title: 'Assigned',
        //     field: 'assign_users',
        //     render: rowData => (rowData.assign_users || rowData.assign_users === '') ? (<Box fontSize={10}>{rowData.assign_users}</Box>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)
        // })

        // columns.push({
        //     title: 'Actual Hours',
        //     field: 'actual_hours',
        //     render: rowData => (rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{rowData.actual_hours}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)
        // })

        columns.push({
            title: 'Estimated Hours',
            field: 'est_hrs',
            // type: 'date',

        })

        // columns.push({
        //     title: 'Task Level Allocation',
        //     field: 'dumm',
        //     // tyfiltering: false,
        //     render: rowData =>
        //         <div>
        //             <IconButton data-tip data-for={'happyFace'+rowData.id} onClick={() => { rowData.t_wbs_data = rowData.wbs; this.setState({ allData: rowData, openTaskLevelAllocation: true, }) }}><WorkIcon /></IconButton>
        //             <ReactTooltip id={'happyFace'+rowData.id} type='error'>
        //                 <spam>{(rowData.assign_users || rowData.assign_users === '') ? (<Box fontSize={10}>{rowData.assign_users}</Box>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)}</spam>
        //             </ReactTooltip>
        //         </div>
        // })

        var rolehere = '0'
        for (let i = 0; i < columnRoles.length; i++) {
            const element = columnRoles[i];
            rolehere = rolehere + ',' + element.id;
            columns.push({
                title: element.name,
                field: element.id + ".name",
                filtering: false,
                render: rowData => (rowData[(element.id).toString()]) ? (<IconButton
                    onClick={() => {
                        rowData[(element.id).toString()]['t_tasks_data']['wbs'] = rowData.wbs;
                        rowData[(element.id).toString()]['t_tasks_data']['ds'] = rowData.ds;
                        // console.log(rowData)
                        this.setState({
                            open: true, mapRoles: rowData[(element.id).toString()], mapTask: rowData, proj_wbs_name: this.state.projectLook[rowData.ds.subproject_pid.toString()] + " - " + rowData.ds.name,
                            wbs_task_name: rowData.wbs.name + " - " + rowData.decs ? rowData.decs : rowData.name,
                        })
                    }}><AddIcon /></IconButton>) : (null)
            })
        }

        var viewPid = []
        // console.log(res.data)
        for (let i = 0; i < tasks.length; i++) {
            const element = tasks[i];
            viewPid.push({ 'ttid': element.id })
            for (let j = 0; j < this.state.roles.length; j++) {
                const element1 = this.state.roles[j];
                if (element1 && element.id === element1.t_tasks) {
                    var json_key = element1.t_role.toString();
                    element[json_key] = element1;
                }
            }

            for (let j = 0; j < this.state.wbs.length; j++) {
                const element1 = this.state.wbs[j];
                if (element.t_ohlwbs === element1.id) {
                    element.wbs = element1;
                    // element.wbs = element1;
                }
            }

            for (let j = 0; j < this.state.deliSch.length; j++) {
                const element1 = this.state.deliSch[j];
                if (element.t_pid === element1.id) {
                    element.ds = element1;
                }
            }

            var est_hrs = 0;
            for (let j = 0; j < this.state.roles.length; j++) {
                const element1 = this.state.roles[j];
                // console.log(element1.t_tasks + "   " + element.id)
                if (element1 && element1.t_tasks === element.id) {
                    est_hrs = est_hrs + element1.all_hours_to_this_role;
                }
            }

            // var pld_hrs = 0;
            // for (let j = 0; j < res.data.length; j++) {
            //     const element1 = res.data[j];
            //     if (element1.task_id === element.id) {
            //         pld_hrs = pld_hrs + element1.planed_hours;
            //     }
            // }

            element.est_hrs = est_hrs;
            // element.pld_hrs = pld_hrs;

        }

        // if (this.state.checkedB) {
        //     tasks.sort(sortByProperty("pld_hrs"));
        // }

        // console.log(tasks)

        this.setState({ tasks: tasks, columns: columns, isLoading: false, isLoading1: false }, () => {
            if (viewPid.length !== 0) {
                Service.patch("/bb/api/team/tasks/get_acthours/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: viewPid,
                })
                    .then(res12 => {

                        // console.log(res12.data)

                        for (let j = 0; j < res12.data.length; j++) {
                            const element13 = res12.data[j];
                            for (let i = 0; i < tasks.length; i++) {
                                const element12 = tasks[i];
                                if (element12.id === element13.id) {
                                    element12.actual_hours = timeToDecimal(element13.hours_actual)
                                    element12.pld_hrs = element13.allocated
                                    break;
                                }
                                // console.log(timeToDecimal(element13.hours_actual) !== 0)
                            }

                        }
                        // console.log(tasks)
                        this.setState({

                            tasks: tasks,

                        })

                        Service.patch("/bb/api/tender/wbs/tasks/get_assignusers/", {
                            headers: {
                                Authorization: "Token " + token,
                            },
                            data: viewPid,
                        })
                            .then(res21 => {
                                // console.log(res21.data)

                                for (let j = 0; j < tasks.length; j++) {
                                    const element13 = tasks[j];
                                    var names = ''
                                    for (let i = 0; i < res21.data.length; i++) {
                                        const element12 = res21.data[i];
                                        if (element12.id === element13.id) {
                                            for (let k = 0; k < element12.users.length; k++) {
                                                const element14 = element12.users[k];
                                                if (names !== '') {
                                                    names = names + ", " + element14.first_name + "(" + element14.hours + ")"
                                                } else {
                                                    names = element14.first_name + "(" + element14.hours + ")"
                                                }
                                            }
                                        }
                                        // console.log(timeToDecimal(element13.hours_actual) !== 0)
                                    }
                                    element13.assign_users = names

                                }
                                // console.log(res.data)
                                this.setState({

                                    tasks: tasks,

                                })
                            })
                            .catch(error => {
                                this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                                    variant: 'error'
                                });

                            });
                    })
                    .catch(error => {
                        this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                            variant: 'error'
                        });

                    });
            }

        })

        // console.log(tasksId + "   " + rolehere)

        // Service.get('/bb/api/tender/tasks/rolesnodata/?tid=' + tasksId + '&rid=' + this.props.roleid, {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        // })
        //     .then(res1 => {
        //         // var role1id = '0'
        //         // for (let i = 0; i < res1.data.length; i++) {
        //         //     const element = res1.data[i];
        //         //     role1id = role1id + ',' + element.id;
        //         // }
        //         // console.log(res1.data)
        //         // Service.get('/bb/api/tender/user/task/?trid=' + role1id, {
        //         //     headers: {
        //         //         Authorization: "Token " + token,
        //         //     },
        //         // })
        //         //     .then(res => {

        //         //     })
        //         //     .catch(error => {
        //         //         this.props.enqueueSnackbar('Something went wrong!', {
        //         //             variant: 'error'
        //         //         });
        //         //     });

        //     })
        //     .catch(error => {
        //         this.props.enqueueSnackbar('Something went wrong!', {
        //             variant: 'error'
        //         });
        //     });

    }

    fetchPerTaskData(rowData) {
        Service.get('/bb/api/tender/wbs/tasks/' + rowData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    perTaskLoading: false,
                    perTaskData: res.data,
                    taskPercent: res.data.comp_percnt,
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    handleTaskComp() {
        this.setState({

            openTaskUpdate: false,
        })
        var data = this.state.perTaskData;

        var historydata = null

        if (data.comp_percnt !== this.state.taskPercent) {
            Service.post('/bb/api/tender/tasks/history/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    "t_pid": data.t_pid,
                    "t_wbs": data.t_ohlwbs,
                    "t_tasks": data.id,
                    "prev_task_status": data.comp_percnt,
                    "task_status": this.state.taskPercent,
                    "created_by": user.id
                },
            })
                .then(res => {
                    historydata = res.data
                    data.comp_percnt = this.state.taskPercent;
                    Service.put('/bb/api/tender/wbs/tasks/' + this.state.perTaskData.id + '/', {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: data,
                    })
                        .then(res1 => {

                            this.setState({
                                perTaskLoading: false,
                                taskPercent: 0,
                                openTaskUpdate: false,
                            })
                            this.props.enqueueSnackbar('Task status updated!', {
                                variant: 'success'
                            });
                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                            if (historydata) {
                                Service.delete('/bb/api/tender/tasks/history/' + historydata.id + '/', {
                                    headers: {
                                        Authorization: "Token " + token,
                                    },

                                })
                                    .catch(error => {
                                        errorMessage(this.props, error)
                                    });
                            }


                        });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
        // // console.log(data)
        // data.comp_percnt = this.state.taskPercent;
        // Service.put('/bb/api/tender/wbs/tasks/' + this.state.perTaskData.id + '/', {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        //     data: data,
        // })
        //     .then(res1 => {
        //         this.setState({
        //             perTaskLoading: false,
        //             taskPercent: 0,
        //             openTaskUpdate: false,
        //         })
        //         this.props.enqueueSnackbar('Task status updated!', {
        //             variant: 'success'
        //         });
        //     })
        //     .catch(error => {
        //         errorMessage(this.props, error)
        //     });
    }

    onProjectSelect = (event, values) => {
        this.setState({ selectedProject: values });
    }

    initiateNewFetch() {
        var projectLook = {}
        var ids = "0";
        for (let i = 0; i < this.state.selectedProject.length; i++) {
            const element = this.state.selectedProject[i];
            ids = ids + "," + element.id;
            if (element.tender_name) {
                projectLook[element.id] = element.tender_name;
            } else {
                projectLook[element.id] = element.name;
            }
        }

        this.setState({ isLoading: true, projectLook: projectLook });
        this.fetchOps(ids)
    }

    isProjPdm(pid, rowData) {
        for (let i = 0; i < this.state.userPdm.length; i++) {
            const element = this.state.userPdm[i];
            if (element.pid === pid && (element.allocation_access === 2 || (element.allocation_access === 1 && rowData.wbs.type === element.pdm_type))) {
                return true
            }
        }
        return false
    }

    projectPdm(rowData) {
        try {
            for (let i = 0; i < this.state.selectedProject.length; i++) {
                const element = this.state.selectedProject[i];
                if (rowData.ds.subproject_pid === element.id && (element.tenderbid_data.pdm === user.id || this.isProjPdm(element.id, rowData))) {
                    return true
                }
            }
            return false
        } catch (err) {
            return false
        }

    }

    fetchUserRoles = (event, values) => {

        if (!values) {
            this.setState({ findUser: { name: '' } })
        }
        if (values) {
            var secondary_roles = ''
            // console.log(values.secondary_roles)
            if (values.secondary_roles) {
                for (let i = 0; i < values.secondary_roles.length; i++) {
                    const element = values.secondary_roles[i];
                    if (secondary_roles !== '') {
                        secondary_roles = secondary_roles + ", " + element.role_name
                    } else {
                        secondary_roles = element.role_name
                    }
                }
            }

            let role = {
                name: values.name,
                primary_role: values.role_name,
                secondary_role: secondary_roles ? secondary_roles : 'NA'
            }
            this.setState({ findUser: role })
            // alert("Name : " + values.name + "\nPrimary Role : " + values.role_name + "\nSecondary Roles : " + secondary_roles)
        }

    }

    leadChange = (e, value) => {
        let projects = [...this.projects];
        let filtered = []

        if (value.length === 0) {
            this.setState({ selectedLead: value, selectedProject: [], projects: projects });

        } else {
            for (let i = 0; i < value.length; i++) {
                for (let j = 0; j < projects.length; j++) {
                    if (value[i].id === projects[j].tenderbid_data.lead_dicipline) {
                        filtered.push(projects[j])
                    }
                }
            }
            this.setState({ selectedLead: value, selectedProject: [], projects: filtered })
        }

    }

    render() {

        const handleClose = () => {
            this.setState({ open: false, openTaskLevelAllocation: false, })
            // this.initiateNewFetch(); 9
            // this.compileData();
        };

        const handleSearchRoleClose = () => {
            this.setState({ searchUserRoles: false, })

            // this.compileData();
        };

        // const handleRolesClose = () => {
        //     this.setState({ openPDM: false, isLoading1: true })
        //     this.initiateNewFetch();
        // };

        // const handleChange = (event) => {
        //     this.setState({ checkedB: event.target.checked, isLoading1: true }, () => {
        //         this.compileData();
        //     });
        // };

        const handlePercChange = (e) => {
            this.setState({ taskPercent: e.target.value })
        };

        const handleTaskClose = () => {
            this.setState({ openTaskUpdate: false, })
        };



        return (

            <>
                {this.state.isLoading1 ?
                    <LoopCircleLoading color={getPrimaryColor()} /> :
                    <div>
                        <Grid container spacing={3} style={{ margin: '10px 0' }}>
                            <Grid item sm={3}>
                                <Autocomplete
                                    multiple
                                    id="lead-diciplines"
                                    options={this.state.lead_diciplines}
                                    // style={{ width: '250px' }}
                                    value={this.state.selectedLead}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.name}
                                        </React.Fragment>
                                    )}
                                    onChange={this.leadChange}
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Select Lead Diciplines" placeholder="Type here" />
                                    )}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <Autocomplete
                                    multiple
                                    id="export-projects"
                                    options={this.state.projects}
                                    // style={{ width: '250px' }}
                                    value={this.state.selectedProject}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => '[' + leadDiscipline[option.tenderbid_data.lead_dicipline] + '] ' + option.tender_name}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {'[' + leadDiscipline[option.tenderbid_data.lead_dicipline] + '] ' + option.tender_name}
                                        </React.Fragment>
                                    )}
                                    onChange={this.onProjectSelect}
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Select Projects" placeholder="Type Project Name here" />
                                    )}
                                />
                            </Grid>
                            <Grid item sm={1} style={{ position: 'relative' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { this.initiateNewFetch() }}
                                    disabled={this.state.selectedProject.length === 0 || this.state.isLoading === true}
                                    style={{ position: 'absolute', bottom: '14px' }}
                                >Submit</Button>
                            </Grid>
                            <Grid item sm={2} style={{ position: 'relative' }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => { this.setState({ searchUserRoles: true }) }}
                                    style={{ position: 'absolute', bottom: '14px' }}
                                    endIcon={<SearchIcon />}
                                >Find User Role</Button>
                            </Grid>
                        </Grid>


                        {this.state.isLoading ?
                            <LoopCircleLoading color={getPrimaryColor()} /> :

                            <div>
                                {this.state.tasks.length > 0 ?
                                    <MaterialTable
                                        columns={this.state.columns}
                                        data={this.state.tasks}
                                        isLoading={this.state.isLoading1}
                                        options={{
                                            padding: "dense",
                                            search: false,
                                            pageSize: 10,
                                            pageSizeOptions: [10, 20, 50],
                                            doubleHorizontalScroll: true,
                                            filtering: true,
                                            // headerStyle: { position: "sticky", top: 0 },
                                            // maxBodyHeight: Math.round(window.screen.height - 200),
                                            showTitle: false,
                                            toolbar: false
                                        }}
                                        actions={[
                                            rowData => ({
                                                icon: 'assignment_turned_in',
                                                tooltip: 'Update Task Completion',
                                                onClick: (event, rowData) => { this.fetchPerTaskData(rowData); this.setState({ perTaskLoading: true, openTaskUpdate: true, perTaskData: null }) },
                                            }),
                                            rowData => ({
                                                icon: () =>
                                                    <div>
                                                        {/* <WorkIcon data-tip data-for={'happyFace' + rowData.id} />
                                                        <ReactTooltip id={'happyFace' + rowData.id} type='error' backgroundColor={getPrimaryColor()}>
                                                            {(rowData.assign_users || rowData.assign_users === '') ?
                                                                <Box fontSize={10}>{rowData.assign_users}</Box> :
                                                                <CircularProgress color="#a5ff00" style={{ height: '15px', width: '15px' }} />
                                                            }
                                                        </ReactTooltip> */}
                                                        <Button
                                                            variant="outlined"
                                                            startIcon={<WorkIcon />}
                                                            size="small"
                                                            color="primary"
                                                        >(NEW)</Button>
                                                    </div>,
                                                // tooltip: 'Task Level Allocation',
                                                // disabled: !((access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e)),
                                                // onClick: (event, rowData) => { rowData.t_wbs_data = rowData.wbs; this.setState({ allData: rowData, openTaskLevelAllocation: true, }) },
                                                onClick: (event, rowData) => this.teamTaskAllocation.current.getTask(rowData.id)
                                            }),
                                            rowData => ({
                                                icon: () =>
                                                    <div>
                                                        {/* <WorkIcon data-tip data-for={'happyFace' + rowData.id} />
                                                        <ReactTooltip id={'happyFace' + rowData.id} type='error' backgroundColor={getPrimaryColor()}>
                                                            {(rowData.assign_users || rowData.assign_users === '') ?
                                                                <Box fontSize={10}>{rowData.assign_users}</Box> :
                                                                <CircularProgress color="#a5ff00" style={{ height: '15px', width: '15px' }} />
                                                            }
                                                        </ReactTooltip> */}
                                                        <Button
                                                            variant="outlined"
                                                            startIcon={<WorkIcon />}
                                                            size="small"
                                                            color="primary"
                                                        >(OLD)</Button>
                                                    </div>,
                                                // tooltip: 'Task Level Allocation',
                                                // disabled: !((access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e)),
                                                onClick: (event, rowData) => { rowData.t_wbs_data = rowData.wbs; this.setState({ allData: rowData, openTaskLevelAllocation: true, }) },
                                                // onClick: (event, rowData) => this.teamTaskAllocation.current.getTask(rowData.id)
                                            })
                                        ]}

                                    />
                                    : null}
                            </div>

                        }
                        <Dialog onClose={handleClose} fullwidth="true" maxWidth="xl" aria-labelledby="customized-dialog-title" open={this.state.open}>
                            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                                {this.state.mapRoles.name + " [ " + this.state.proj_wbs_name + " ] Available hours for allocation : " + (this.state.mapRoles.all_hours_to_this_role) + " hours"}
                            </DialogTitle>
                            <DialogContent dividers>
                                {/* <Typography>{this.state.wbs_task_name + "   --->  Revised Hours: " + this.state.mapTask.est_hrs + ", Allocated Hours: " + this.state.mapTask.pld_hrs + ", Actual Hours: " + this.state.mapTask.actual_hours + " for the entire task. Please contact EM to increase available hours for allocation."}</Typography> */}
                                <Alert severity="info">To view all roles information and also to request for additional hours, please use the "Manage" option in the previous page.</Alert>
                                <TeamRoleMapping value={this.state.mapRoles} task={this.state.mapTask} team={this.props.team} rolesLookUp={this.state.allRoles} projectLook={this.state.projectLook} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>
                        
                        <Dialog
                            open={this.state.openTaskUpdate}
                            onClose={handleTaskClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle>{this.state.perTaskData.name}</DialogTitle>
                            <DialogContent>
                                {(this.state.perTaskLoading) ? (null) : (
                                    <FormControl fullWidth >
                                        <InputLabel fullWidth id="demo-simple-select-label">Select Percentage Complete</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            fullWidth
                                            value={this.state.taskPercent}
                                            onChange={handlePercChange}
                                        >
                                            <MenuItem value={0}>0% - Not started.</MenuItem>
                                            <MenuItem value={5}>5% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={10}>10% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={20}>20% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={30}>30% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={40}>40% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={50}>50% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={60}>60% - Checked within Department.</MenuItem>
                                            <MenuItem value={65}>65% - Approved within Department.</MenuItem>
                                            <MenuItem value={70}>70% - Site comments received, reviewed.</MenuItem>
                                            <MenuItem value={80}>80% - Multi-disciplinary Reviewed & Commented.</MenuItem>
                                            <MenuItem value={90}>90% - Design verified.</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleTaskClose} color="primary">
                                    Close
                                </Button>
                                <Button onClick={() => this.handleTaskComp()} disabled={!this.state.perTaskData} color="primary">
                                    Submit
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.openTaskLevelAllocation}
                            fullWidth={true}
                            maxWidth={'xl'}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="customized-dialog-title2" >
                                {this.state.allData ? this.state.projectLook[this.state.allData.ds.subproject_pid] + " -> " + this.state.allData.id + " " + this.state.allData.name : null}
                            </DialogTitle>
                            <DialogContent>
                                <TaskLevelTabs value={this.state.allData} task={this.state.allData} team={this.props.team} pdm={this.projectPdm(this.state.allData)} rolesLookUp={this.state.allRoles} projectLook={this.state.projectLook} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>
                        
                        <Dialog
                            open={this.state.searchUserRoles}
                            fullWidth
                            maxWidth={'sm'}
                            onClose={handleSearchRoleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="customized-dialog-title2" >
                                Find Role of a user
                            </DialogTitle>
                            <DialogContent>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={this.state.userRolesInfo}
                                    fullWidth
                                    getOptionLabel={(option) => option.name}
                                    onChange={this.fetchUserRoles}
                                    renderInput={(params) => <TextField {...params} label="Type User Name" variant="outlined" />}
                                />
                                {this.state.findUser.name ? <div> <p>Name : {this.state.findUser.name}</p>
                                    <p>Primary Role : {this.state.findUser.primary_role}</p>
                                    <p>Secondary Role : {this.state.findUser.secondary_role}</p>
                                </div>
                                    : null
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleSearchRoleClose} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <TeamTaskAllocation ref={this.teamTaskAllocation} teamOrProj={false} />
                    </div>
                }
            </>
        )
    }
}

// function sortByProperty(property) {
//     return function (a, b) {
//         if (a[property] > b[property])
//             return 1;
//         else if (a[property] < b[property])
//             return -1;

//         return 0;
//     }
// }

function addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec ? 1 : null
    if (len) {
        return parseFloat(Number(num).toFixed(len))
    } else {
        return parseFloat(Number(num))
    }
}

function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export default withSnackbar(UserTaskMap);