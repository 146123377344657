import React, { Component } from 'react'
import MaterialTable from 'material-table';
import Service from "../../Networking/networkutils";
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor, sortByProperty } from '../../Helper/CommonThings';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
const access = JSON.parse(getCookie("bb_ts_access"));

class Clients extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            data1: [],
            usersData: {},
            isLoading: true,
        }
    }

    componentDidMount() {
        this.fetchOps();
        Service.get(`/bb/api/users/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res1 => {
                var contact = {}
                var data = res1.data
                data.sort(sortByProperty("first_name"));
                for (let i = 0; i < res1.data.length; i++) {
                    const element = res1.data[i];
                    contact["a" + element.id] = element.last_name + ", " + element.first_name;
                }
                this.setState({ usersData: contact })

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchOps() {
        Service.get('/bb/api/region/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    element.hoe = "a" + element.hoe
                }

                this.setState({ isLoading: false, data: res.data })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

    }

    postOps(data) {
        if (data.name === undefined || data.name === '') {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Region field is empty!', {
                    variant: 'error'
                });
            }, 200)

        } else {
            data.created_by = user.id
            data.hoe = data.hoe.split(',')[1]
            Service.post('/bb/api/region/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data,
            })
                .then(res => {
                    this.setState({ isLoading: true, })
                    this.fetchOps();
                    this.props.enqueueSnackbar('Region added successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    putOps(data) {
        console.log(data)
        if (data.name === undefined || data.name === '') {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Region field is empty!', {
                    variant: 'error'
                });
            }, 200)

        } else {
            data.hoe = data.hoe.split('a')[1]
            Service.put('/bb/api/region/' + data.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data,
            })
                .then(res => {
                    this.fetchOps();
                    this.props.enqueueSnackbar('Region updated successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: true, })
                    errorMessage(this.props, error)
                    this.fetchOps();
                });
        }
    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <MaterialTable
                            key={3}
                            title="Regions"
                            columns={[
                                { title: 'Region ID', field: 'id', editable: 'never', },
                                { title: 'Region', field: 'name', },
                                { title: 'HoE', field: 'hoe', lookup: this.state.usersData },
                            ]}
                            data={this.state.data}
                            options={{
                                pageSize: 20,
                                pageSizeOptions: [10, 20, 50],
                                padding: "dense",
                                addRowPosition: "first",
                            }}
                            icons={{
                                Add: props => <Button color='primary' variant="contained" startIcon={<AddIcon />}>
                                    Add Region
                                </Button>
                            }}
                            editable={
                                ((access && access.access && !access.access.l12a.l12a_b) && (access && access.access && access.access.l12a.l12a_c)) ? (
                                    {

                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            this.putOps(newData)
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 600);
                                            }),
                                    }
                                ) : ((access && access.access && access.access.l12a.l12a_b) && (access && access.access && !access.access.l12a.l12a_c)) ? (
                                    {
                                        onRowAdd: newData =>
                                            new Promise(resolve => {

                                                setTimeout(() => {
                                                    resolve();
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        newData.created_by = user.email;
                                                        this.postOps(newData);
                                                        data.push(newData);
                                                        // this.postData(newData)
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),

                                    }
                                ) : ((access && access.access && access.access.l12a.l12a_b) && (access && access.access && access.access.l12a.l12a_c)) ? (
                                    {
                                        onRowAdd: newData =>
                                            new Promise(resolve => {

                                                setTimeout(() => {
                                                    resolve();
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        newData.created_by = user.email;
                                                        this.postOps(newData);
                                                        data.push(newData);
                                                        // this.postData(newData)
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            this.putOps(newData)
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 600);
                                            }),
                                    }
                                ) : (null)}
                        />

                    </div>
                )}
            </div>
        )
    }
}

export default withSnackbar(Clients);