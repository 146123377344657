import React, { Component } from 'react';
import { getCookie } from '../Helper/CookieHelper';
import Service from '../Networking/networkutils';
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../Helper/CommonThings';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DialogContent, TextField } from '@material-ui/core';
import { Box } from '@mui/system';
import { Alert } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, Dialog, DialogTitle } from '@material-ui/core';
import { Link } from '@mui/material';
import moment from 'moment';
import Button from '@mui/material/Button';
import { errorMessage } from '../Helper/ErrorMessage';
import { withSnackbar } from 'notistack';
import UserHoursRequest from '../HoursRequest/UserHoursRequest';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class DateChangeRequest extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dateChangeNouns: [],
            dateChangeAdjectives: [],
            selectedNoun: null,
            selectedAdjective: null,
            delayDate: false,
            startDate: null,
            endDate: null,
            copyEndDate: null,
            submitDisabled: false,
            comments: '',
            isLoading: true,
            openRequest: false,
        }
    }
    componentDidMount() {
        this.fetchData()
    }

    fetchPerTaskData() {
        return new Promise((resolve, reject) => {
            Service.get('/bb/api/tender/wbs/tasks/' + this.props.value.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    fetchData() {
        Promise.all([
            this.getNouns(),
            this.getAdjectives(),
            this.fetchPerTaskData()
        ])
            .then(res => {
                this.setState({
                    dateChangeNouns: res[0],
                    dateChangeAdjectives: res[1],
                    submitDisabled: false,
                    startDate: !res[2].act_exp_start ? res[2].from_date : res[2].act_exp_start,
                    endDate: null,
                    copyEndDate: !res[2].act_exp_finish ? res[2].to_date : res[2].act_exp_finish,
                    isLoading: false,
                    perTaskData: res[2]
                })
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })
    }

    getNouns() {
        return new Promise((resolve, reject) => {
            Service.get("/bb/api/task/date/change/nouns/", {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    getAdjectives() {
        return new Promise((resolve, reject) => {
            Service.get("/bb/api/task/date/change/adjective/", {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    dateSubmit() {
        if (moment(this.state.endDate).format("YYYY-MM-DD") === moment(this.state.copyEndDate).format("YYYY-MM-DD")) {
            this.props.enqueueSnackbar('Requesting existing date is not allowed!', {
                variant: 'warning'
            });
            return null
        }

        var fields = ""

        if (this.state.endDate && moment(this.state.endDate).format('YYYY-MM-DD') >= moment(this.state.copyEndDate).format('YYYY-MM-DD')) {
            if (!this.state.selectedAdjective) {
                fields = "Adjective"
            }
            if (!this.state.selectedNoun) {
                fields += !fields ? "Noun" : ", Noun"
            }
        }
        if (!this.state.endDate) {
            fields += !fields ? "Date" : ", Date"
        }

        if (!this.state.comments) {
            fields += !fields ? "Comments" : ", Comments"
        }

        if (!fields) {
            this.setState({ submitDisabled: true })
            let data = {
                date: !this.state.endDate ? null : moment(this.state.endDate).format("YYYY-MM-DD"),
                task_id: this.state.perTaskData.id,
                comment: this.state.comments,
                user_id: this.props.uid,
                created_by: user.id
            }
            if (this.state.endDate && moment(this.state.endDate).format('YYYY-MM-DD') >= moment(this.state.copyEndDate).format('YYYY-MM-DD')) {
                data.noun = this.state.selectedNoun
                data.adjective = this.state.selectedAdjective
            }
            Service.post('/bb/api/estimate/date/request/estimate_date_request/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            })
                .then(res => {
                    if (this.state.endDate && (moment(this.state.endDate).format('YYYY-MM-DD') >= moment(this.state.copyEndDate).format('YYYY-MM-DD')) && !this.props.is_teamtasks) {
                        this.setState({ delayDate: true, selectedAdjective: null, selectedNoun: null, submitDisabled: false, comments: '' })
                    } else {
                        this.props.enqueueSnackbar('Datechange request sent successfully', {
                            variant: 'success'
                        });
                        this.setState({ selectedAdjective: null, selectedNoun: null, comments: '', submitDisabled: false })
                        if (this.props.onCloseCommand) {
                            this.props.onCloseCommand()
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                    if (error && error.status === 406) {
                        this.props.enqueueSnackbar(
                            error.data, { variant: 'warning' });
                    } else {
                        errorMessage(this.props, error)
                    }
                    this.setState({ submitDisabled: false, delayDate: false, selectedAdjective: null, selectedNoun: null, comments: '', })
                });
        } else {
            this.props.enqueueSnackbar(fields + ' is Empty!', {
                variant: 'warning'
            });
        }
    }

    openHrsReq() {
        this.setState({ openRequest: true })
    }

    render() {

        const handleNounChange = (event) => {
            this.setState({
                selectedNoun: event.target.value
            })
        };

        const handleAdjectiveChange = (event) => {
            this.setState({
                selectedAdjective: event.target.value
            })
        };

        const handleRequestClose = () => {
            this.setState({ openRequest: false, })
        };

        return (
            <div>
                {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> : <div>
                    {!this.state.delayDate ?
                        <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center', }}>
                            <Box sx={{ width: '100%' }}>
                                <Alert severity='info'>
                                    This Date change request directly sent to EM
                                </Alert>
                            </Box>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog1"
                                    label="Recommended Date"
                                    format="dd/MM/yyyy"
                                    style={{ width: '100%', marginTop: '10px' }}
                                    minDate={this.state.startDate}
                                    maxDate={moment(this.state.copyEndDate).add(45, 'days')}
                                    value={this.state.endDate}
                                    onChange={(date) => {
                                        if (moment(date).format('YYYY-MM-DD') >= moment(this.state.startDate).format('YYYY-MM-DD')) {
                                            this.setState({ endDate: date, submitDisabled: false })
                                        } else {
                                            this.setState({ submitDisabled: true })
                                        }
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            {
                                this.state.endDate && moment(this.state.endDate).format('YYYY-MM-DD') >= moment(this.state.copyEndDate).format('YYYY-MM-DD') ?
                                    <Box width="100%" display='flex' flexDirection={'column'} justifyContent={'center'}>
                                        <Box width="100%">
                                            <FormControl fullWidth >
                                                <InputLabel fullWidth id="demo-simple-select-helper-label">Select Noun</InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={this.state.selectedNoun}
                                                    onChange={handleNounChange}
                                                >
                                                    {this.state.dateChangeNouns.map((value, index) =>
                                                        <MenuItem value={value.id}>{value.name}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Box width="100%" mt={1}>
                                            <FormControl fullWidth>
                                                <InputLabel fullWidth id="demo-simple-select-helper-label">Select Adjective</InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={this.state.selectedAdjective}
                                                    onChange={handleAdjectiveChange}
                                                >
                                                    {this.state.dateChangeAdjectives.map((value, index) =>
                                                        <MenuItem value={value.id}>{value.name}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box> : null
                            }
                            <TextField
                                id="comments"
                                label="Comments"
                                style={{ width: '100%', marginTop: '20px' }}
                                variant="outlined"
                                onChange={(e) => {
                                    this.setState({ comments: e.target.value });
                                }}
                            />
                        </Box> :
                        <Box sx={{ display: "flex", flexDirection: 'column', width: '100%', alignItems: 'center', }}>
                            <Box sx={{ fontSize: '16px', fontWeight: 400, display: "flex", flexDirection: 'row', width: '100%', alignItems: 'center', }}>
                                Date change request is sent successfully
                            </Box>
                            <Box sx={{ fontSize: '16px', fontWeight: 400, display: "flex", flexDirection: 'row', width: '100%', alignItems: 'center', }}>
                                <Box>
                                    If you need additional hours:
                                </Box>
                                <Box sx={{ marginLeft: '5px' }}>
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            this.openHrsReq()
                                        }}
                                    >
                                        Click here
                                    </Link>
                                </Box>
                            </Box>
                        </Box>}

                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        {!this.state.delayDate ?
                            <Button color="primary" onClick={() => this.dateSubmit()} disabled={!this.state.perTaskData || this.state.submitDisabled}>
                                Submit
                            </Button>
                            : null}

                        <Button onClick={() => {
                            if (this.props.onCloseCommand) {
                                this.props.onCloseCommand()
                            }
                        }} color="primary">
                            Close
                        </Button>
                    </Box>
                </div>}

                <Dialog onClose={handleRequestClose} fullWidth={true} aria-labelledby="customized-dialog-title" open={this.state.openRequest}>
                    <DialogTitle id="customized-dialog-title1" onClose={handleRequestClose}>
                        {this.state.perTaskData ? (this.state.perTaskData.decs ? this.state.perTaskData.decs : this.state.perTaskData.name) : null}
                    </DialogTitle>
                    <DialogContent dividers>
                        <UserHoursRequest value={this.state.perTaskData} uid={this.props.uid} tender={null} onCloseCommand={handleRequestClose} />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withSnackbar(DateChangeRequest);