import { Card, Box, CardActionArea, Avatar, Dialog, DialogContent, DialogTitle } from '@mui/material'
import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { screenSizeCheck } from '../../../../ScreensizeCheck/ScreensizeCheck';
import { getGreyColor } from '../../../../ColorTheme/ColorTheme';
import UserTasks from '../../../../../WorkHours/UserTasks';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    if (props.slideCount <= props.data + props.currentSlide) {
        return <div></div>
    } else {
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: '50px', background: "grey" }}
                onClick={onClick}
            />
        );
    }
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    if (props.currentSlide <= 0) {
        return <div></div>
    } else {
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: '50px', background: "grey" }}
                onClick={onClick}
            />
        );
    }
}


class TeamMembers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            datas: [],
            openDialogue: false,
            selectedUser: 0,
            userName: ''
        }
    }
    componentDidMount() {
        this.setState({ datas: this.props.data })
    }

    handleUser(data) {
        var name = data.user_details.last_name + ", " + data.user_details.first_name
        // console.log(data)
        this.setState({ selectedUser: data.user_details.id, openDialogue: true, userName: name })
    }

    render() {

        const settings = {
            centerMode: false,
            infinite: false,
            slidesToShow: screenSizeCheck() ? 2 : 3,
            slidesToScroll: screenSizeCheck() ? 2 : 3,
            speed: 600,
            rows: screenSizeCheck() ? 5 : 5,
            arrows: true,
            nextArrow: <SampleNextArrow data={screenSizeCheck() ? 2 : 3} />,
            prevArrow: <SamplePrevArrow data={screenSizeCheck() ? 2 : 3} />,
            adaptiveHeight: true,
            accessibility: true,
        };

        const handleCloseOpenDialogue = () => {
            this.setState({
                openDialogue: false,
            })
        };

        return (
            <div style={{ width: '100%', height: '100%', paddingLeft: '10px', paddingRight: '10px' }}>
                <Slider {...settings}>
                    {this.state.datas.map((value, i) =>
                        <Box key={i}
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 1,
                                height: '100%',
                            }}>
                            <div>
                                <Card style={{ borderRadius: '20px 20px 20px 20px', margin: '3px' }} elevation={3} >
                                    <CardActionArea onClick={() => this.handleUser(value)}>
                                        <Box sx={{
                                            display: 'flex',
                                            borderRadius: 1,
                                            height: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            <Box m={1} sx={{ display: 'flex', flexGrow: 1 }}>
                                                <Box >
                                                    <Avatar sx={{ bgcolor: '#99CFBB' }}><Box display={'flex-start'} fontWeight={'bold'} fontSize={'18px'}>{value.user_details.first_name.charAt(0) + value.user_details.last_name.charAt(0)}</Box></Avatar>
                                                </Box>
                                                <Box >
                                                    <Box marginLeft={'10px'} marginRight={'10px'} fontSize={screenSizeCheck() ? '16px' : '18px'}>
                                                        {value.user_details.last_name + ", " + value.user_details.first_name}
                                                    </Box>
                                                    <Box marginLeft={'10px'} marginRight={'10px'} color={getGreyColor()} fontSize={screenSizeCheck() ? '12px' : '13px'}>
                                                        {value.user_details.role_name}
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box m={1} sx={{
                                                display: 'flex-end',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '100%'
                                            }}>
                                                <Box fontWeight={'bold'} marginRight='5px' color={value.user_remain_hours >= 100 ? '#008755' : '#E7A356'} fontSize={'18px'}>{value.user_remain_hours}</Box>
                                            </Box>
                                        </Box>
                                    </CardActionArea>
                                </Card>
                            </div>
                        </Box>
                    )}
                </Slider>

                <Dialog fullWidth onClose={handleCloseOpenDialogue} aria-labelledby="customized-dialog-title" maxWidth="xl" open={this.state.openDialogue}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseOpenDialogue}>
                        Workload of {" " + this.state.userName}
                    </DialogTitle>
                    <DialogContent dividers>
                        <UserTasks teamLeadData={[]} uid={this.state.selectedUser} />
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default TeamMembers