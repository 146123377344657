import React, { Component } from 'react';
import Box from '@mui/material/Box';
import { withSnackbar } from 'notistack';
import { errorMessage } from '../../../Helper/ErrorMessage';
import { getCookie } from '../../../Helper/CookieHelper';
import Service from '../../../Networking/networkutils';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

const all_disciplines_allocation_access = [
    { value: 2, name: 'All tasks for the project' },
    { value: 3, name: `Specific WBS's` },
]

const other_disciplines_allocation_access = [
    { value: 1, name: 'All discipline tasks' },
    { value: 3, name: `Specific WBS's` },
]


class AddDDM extends Component {
    constructor(props) {
        super(props)

        this.state = {
            postData: {},
            buttonDiasbled: false,
            isLoading: true,
            usersLookup: this.props.usersLookup,
            allocationAccess: [],
            disciplines: []
        }
    }

    componentDidMount() {
        if (this.props.type === 'edit' && this.props.selectedDDM) {
            if (this.props.selectedDDM.pdm_type === 'all_type') {
                this.setState({ allocationAccess: all_disciplines_allocation_access, postData: this.props.selectedDDM, })

            } else {
                this.setState({ allocationAccess: other_disciplines_allocation_access, postData: this.props.selectedDDM, })
            }
        } else if (this.props.type === 'add') {
            let postData = this.state.postData
            postData.timesheet_access = 1
            this.setState({ postData })
        } else {
            let postData = this.state.postData
            postData.frwd_uid = null
            this.setState({ postData })
        }
        this.getDisciplines()
    }

    postData() {

        var postData = this.state.postData
        var keys = ['pdm_type', 'uid', 'allocation_access']
        var go = true

        for (var i = 0; i < keys.length; i++) {
            const element = keys[i];
            if (!postData.hasOwnProperty(element)) {
                go = false
                break
            }
        }



        if (!go) {
            this.props.enqueueSnackbar('Discipline, EM/DDM, Allocation Access, Receive Timesheet should not be empty!', {
                variant: 'warning'
            });
        } else {

            if (postData?.uid === null || postData?.uid === undefined || postData?.uid === '' || postData?.uid === 'null') {
                this.props.enqueueSnackbar('EM/DDM should not be None or Empty!', {
                    variant: 'warning'
                });
                return
            }

            this.setState({ buttonDiasbled: true })

            postData.uid = parseInt(postData.uid)
            postData.created_by = user.id;
            postData.pid = this.props.value.id

            if (!postData.frwd_uid || postData.frwd_uid === undefined || postData.frwd_uid === '' || postData.frwd_uid === 'null') {
                postData.frwd_uid = null
            } else {
                postData.frwd_uid = parseInt(postData.frwd_uid)
            }
            Service.post('/bb/api/tender/pdm/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData
            })
                .then(res => {
                    if (this.props.ddmData) {
                        this.props.ddmData(res.data)
                    }
                    this.setState({ buttonDiasbled: false })
                    // this.fetchOps();
                    this.props.enqueueSnackbar('Data saved successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    this.setState({ buttonDiasbled: false })
                    // this.fetchOps();
                    errorMessage(this.props, error)
                });
        }

    }

    handleAutocompleteChange = (event, value, id) => {
        if (value === null) {
            this.setState({
                postData: {
                    ...this.state.postData,
                    [id]: ''
                }
            })
        } else {
            this.setState({
                postData: {
                    ...this.state.postData,
                    [id]: value.id
                }
            })
        }
    }

    buttonAction() {
        if (this.props.type === 'add') {
            this.postData()
        } else {
            this.editData()
        }
    }

    editData() {
        var putData = this.state.postData
        if (!putData.uid) {
            this.props.enqueueSnackbar('EM/DDM should not be None or Empty', {
                variant: 'warning'
            });
        } else {
            this.setState({ buttonDiasbled: true })
            putData.uid = parseInt(putData.uid)

            if (!putData.frwd_uid || putData.frwd_uid === undefined || putData.frwd_uid === '' || putData.frwd_uid === 'null') {
                putData.frwd_uid = null
            } else {
                putData.frwd_uid = parseInt(putData.frwd_uid)
            }
            Service.put('/bb/api/tender/pdm/' + putData.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: putData
            })
                .then(res => {
                    this.props.enqueueSnackbar('Data edited successfully!', {
                        variant: 'success'
                    })
                    if (this.props.updateDDM) {
                        this.props.updateDDM()
                    }
                    this.setState({ buttonDiasbled: false })
                })
                .catch(error => {
                    console.log(error)
                    this.setState({ buttonDiasbled: false })
                    errorMessage(this.props, error)
                });
        }
    }

    getDisciplines() {
        Service.get(`/bb/api/tender/ohlwbs/get_project_discipline/?pid=${this.props.pid}&response_type=json_array`, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                var allType = [{
                    key: 'all_type',
                    name: 'All'
                }]
                this.setState({
                    isLoading: false,
                    disciplines: allType.concat(res.data),
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    render() {

        const handleDisciplineChange = (event) => {
            const { name, value } = event.target;
            this.setState({
                postData: {
                    ...this.state.postData,
                    [name]: value
                }
            })
            if (value === 'all_type') {
                this.setState({ allocationAccess: all_disciplines_allocation_access })
            } else if (!value) {
                this.setState({ allocationAccess: [] })
            } else {
                this.setState({ allocationAccess: other_disciplines_allocation_access })
            }
        };

        const handleChange = (event) => {
            const { name, value } = event.target;
            this.setState({
                postData: {
                    ...this.state.postData,
                    [name]: value
                }
            }, () => {
                if (this.state.postData.timesheet_access === 0) {
                    this.setState({
                        postData: {
                            ...this.state.postData,
                            frwd_uid: null
                        }
                    })
                }
            })
            if (name === 'allocation_access' && value === 3 && this.props.viewChange) {
                this.props.viewChange(1)
            } else if (this.props.viewChange && name === 'allocation_access' && value !== 3) {
                this.props.viewChange(0)
            }
        };

        return (
            <div>
                {this.state.isLoading ?
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <CircularProgress color='primary' />
                    </Box>
                    :
                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', }}>
                        <Box sx={{ width: 500, }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Discipline</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    name="pdm_type"
                                    label="Discipline"
                                    value={this.state.postData?.pdm_type}
                                    onChange={handleDisciplineChange}>
                                    {this.state.disciplines.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.key}>{item.name}</MenuItem>
                                        )
                                    })}
                                    {/* <MenuItem value={'all_type'}>All</MenuItem>
                                    <MenuItem value={'ohl'}>OHL</MenuItem>
                                    <MenuItem value={'cabling'}>Cabling</MenuItem>
                                    <MenuItem value={'sub_struc'}>Substation Primary</MenuItem>
                                    <MenuItem value={'ohl_civ_struc'}>Substation Secondary</MenuItem>
                                    <MenuItem value={'sub_civ_struct'}>Substation Civ Structure</MenuItem>
                                    <MenuItem value={'multi_utilities'}>Multi Utilities</MenuItem>
                                    <MenuItem value={'technical'}>Technical</MenuItem>
                                    <MenuItem value={'edc'}>EDC</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ width: 500, marginTop: '15px' }}>
                            <Autocomplete
                                id={"uid"}
                                fullWidth
                                value={(this.state.postData.uid === null) ? { id: null, name: 'None' } : !this.state.postData?.uid ? null : { id: this.state.postData.uid, name: this.state.usersLookup[this.state.postData.uid] }}
                                options={this.props.users}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, value) => { this.handleAutocompleteChange(event, value, 'uid') }}
                                renderInput={(params) => <TextField {...params} label="Select Manager" variant="outlined" />}
                            />
                        </Box>
                        <Box sx={{ width: 500, marginTop: '15px' }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Allocation Access</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    label="Allocation Access"
                                    name="allocation_access"
                                    value={this.state.postData?.allocation_access}
                                    onChange={handleChange}
                                >
                                    {this.state.allocationAccess.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ width: 500, marginTop: '15px' }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Timesheet Allocation Access</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    label="Timesheet Allocation Access"
                                    name="timesheet_access"
                                    value={this.state.postData?.timesheet_access}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={0}>No</MenuItem>
                                    <MenuItem value={1}>Yes (Manager timesheet to EM)</MenuItem>
                                    {/* <MenuItem value={2}>Yes, but own timesheet to EM</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Box>
                        {((this.props.type === 'edit') && (this.state.postData?.timesheet_access)) ?
                            <Box sx={{ width: 500, marginTop: '15px' }}>
                                <Autocomplete
                                    id={"frwd_uid"}
                                    fullWidth
                                    value={(this.state.postData?.frwd_uid === null) ? { id: null, name: 'None' } : !this.state.postData?.frwd_uid ? null : { id: this.state.postData.frwd_uid, name: this.state.usersLookup[this.state.postData.frwd_uid] }}
                                    options={this.props.users}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(event, value) => { this.handleAutocompleteChange(event, value, 'frwd_uid') }}
                                    renderInput={(params) => <TextField {...params} label="Select Timesheet Delegate" variant="outlined" />}
                                />
                            </Box> : null}
                        < Box sx={{ display: 'flex', flexDirection: 'row-reverse', width: "100%", marginTop: "15px" }}>
                            <Button disabled={this.state.buttonDiasbled} onClick={() => this.buttonAction()} variant="contained" color="primary">{(this.props.type === 'edit') ? "Edit" : (this.props.type === 'add' && this.state.postData?.allocation_access === 3) ? "Save & Continue" : "Save"}</Button>
                        </Box>
                    </Box>}
            </div>
        );
    }
}

export default withSnackbar(AddDDM);
