import moment from 'moment';
import React, { Component } from 'react';
import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';
import { Autocomplete, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { LoopCircleLoading } from 'react-loadingg';
import Lookahead3MTable from './Lookahead3MTable';
import { DialogContentText } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

var dates = []

class Lookahead3M extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: null,
            isLoading: false,
            showTable: false,
            members: [],
            currentUser: null,
            openDialog: false
        }
    }

    componentDidMount() {
        const current_date = moment()
        const end_date = moment().add(3, 'months')
        dates = []
        while (current_date <= end_date) {
            if (!([0, 6].includes(current_date.weekday()))) {
                const obj_key = current_date.format("DDMMYYYY")
                dates.push({
                    date: moment(current_date),
                    obj_key: obj_key
                })
            }
            current_date.add(1, 'days')
        }
        var members = []
        for (let i = 0; i < this.props.value.length; i++) {
            const element = this.props.value[i];
            members.push(element.user_details)
        }
        this.setState({
            members: members,
        })
    }

    fetchOps(uid) {
        this.setState({ isLoading: true, showTable: false })
        Service.get("/bb/api/user/report/view/user_3mla/?uid=" + uid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.task_data.length; i++) {
                    const element = res.data.task_data[i];
                    const act_finish = moment(element.act_finish)
                    for (let j = 0; j < dates.length; j++) {
                        const date = dates[j];
                        if (act_finish >= date.date && !([0, 6].includes(date.date.weekday()))) {
                            element[date.obj_key] = element.per_day_demand
                        }
                        if (act_finish < date.date) {
                            break
                        }
                    }
                }
                if (res.data.task_data.length) {
                    res.data.task_data.push({})
                }
                this.setState({
                    data: res.data,
                    isLoading: false,
                    showTable: true
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    emailTeamReport() {
        var successMsg = true
        Service.get2("/bb/api/user/report/view/team_3mla/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

            })
            .catch(error => {
                successMsg = false
                console.log(error)
                // errorMessage(this.props, error)
            })
        this.setState({ openDialog: false })
        setTimeout(() => {
            if (successMsg) {
                this.props.enqueueSnackbar('Request received!', {
                    variant: 'success'
                });
            }
        }, 1000);

    }


    // getTotalPerDay(day_key) {
    //     var totalPerDay = 0
    //     for (let i = 0; i < this.state.data.task_data.length; i++) {
    //         const element = this.state.data.task_data[i];
    //         if (element[day_key]) {
    //             totalPerDay += element[day_key]
    //         }
    //     }
    //     if (totalPerDay) {
    //         return totalPerDay.toFixed(2)
    //     }
    //     return null
    // }

    render() {

        const autoCompleteChange = (event, newValue) => {
            if (newValue) {
                this.fetchOps(newValue.id)
                this.setState({
                    currentUser: newValue
                })
            }
        };

        return (
            <div>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    p: 1,
                    m: 1,
                    borderRadius: 1,
                }}>
                    <Autocomplete
                        id="combo-box-demo"
                        autoHighlight
                        options={this.state.members}
                        getOptionLabel={(option) => option.last_name + ", " + option.first_name}
                        sx={{ width: 300 }}
                        onChange={autoCompleteChange}
                        style={{ marginRight: '25px' }}
                        disabled={this.state.isLoading}
                        renderInput={(params) => <TextField {...params} label="Select Member" />}
                    />
                    <Button disabled={this.state.isLoading} variant="contained" onClick={() => this.setState({ openDialog: true })} color="primary">Team 3MLA</Button>
                </Box>

                {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> : null}
                {this.state.showTable ?

                    <Lookahead3MTable data={this.state.data} currentUser={this.state.currentUser} />

                    : null}

                <Dialog open={this.state.openDialog} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <DialogContentText>
                            You will receive the report via email once the report is ready.
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={e => this.emailTeamReport()} color="primary">
                            Continue
                        </Button>
                        <Button onClick={() => this.setState({ openDialog: false })} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}

export default withSnackbar(Lookahead3M);