import { Box, Tooltip } from '@material-ui/core';
import moment from 'moment';
import React, { Component } from 'react'
import MultiProgress from "react-multi-progress";
import { getDarkGreyColor, getGreencolor } from '../../MainDashBoard/ColorTheme/ColorTheme';

class BaselineActualDate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            baseStart: 0,
            actualStart: 0,
            baseFinish: 0,
            actualFinish: 0,
        }
    }
    componentDidMount() {
        this.calcHours()
    }

    calcHours() {
        var base_start = this.getBaseStart(this.props.data.from_date);
        var base_finish = this.getBaseFinish(this.props.data.to_date)
        var actual_start = this.getActualStart(this.props.data.act_exp_start)
        var actual_finish = this.getActualFinish(this.props.data.act_exp_finish)
        var min_value = Math.min(base_start, base_finish, actual_start, actual_finish)
        // console.log(base_start, base_finish, actual_start, actual_finish)
        // console.log(min_value)
        var conv_bs = base_start - min_value
        var conv_bf = base_finish - min_value
        var conv_as = actual_start - min_value
        var conv_af = actual_finish - min_value
        var max_value = Math.max(conv_bs, conv_bf, conv_as, conv_af)
        // console.log(conv_bs, conv_bf, conv_as, conv_af)
        // console.log(highest)
        var converted_max = 100 / max_value
        var baseStart = Math.round(converted_max * conv_bs)
        var baseFinish = Math.round(converted_max * conv_bf)
        var actualStart = Math.round(converted_max * conv_as)
        var actualFinish = Math.round(converted_max * conv_af)

        // console.log(baseStart, baseFinish, actualStart, actualFinish)
        this.setState({ baseStart, baseFinish, actualStart, actualFinish })

        // var base_start = this.props.data.from_date.getTime()
        // var base_finish = this.props.data.to_date.getTime()
        // var actual_start = this.props.data.act_exp_start.getTime()
        // var actual_finish = this.props.data.act_exp_finish.getTime()
        // var converted_hours = 0
        // var budgetHours = 0
        // var hoursAtComp = 0
        // if (this.props.data.budget_hours < this.props.data.hours_at_completion) {
        //     converted_hours = 100 / this.props.data.hours_at_completion
        //     budgetHours = converted_hours * this.props.data.budget_hours
        //     hoursAtComp = converted_hours * this.props.data.hours_at_completion
        //     hoursAtComp = 100 - budgetHours
        // } else {
        //     converted_hours = 100 / this.props.data.budget_hours
        //     budgetHours = converted_hours * this.props.data.budget_hours
        //     hoursAtComp = converted_hours * this.props.data.hours_at_completion
        //     hoursAtComp = 100 - hoursAtComp
        //     budgetHours = 100 - hoursAtComp
        // }
        // this.setState({ budgetHours: budgetHours, hoursAtComp: hoursAtComp })
    }

    getBaseStart(date) {
        var base_start = new Date(date);
        var converted_base_start = base_start.getTime();
        var base_start_day = this.convertDays(converted_base_start);
        return base_start_day
    }

    getBaseFinish(date) {
        var base_finish = new Date(date)
        var converted_base_finish = base_finish.getTime();
        var base_finish_day = this.convertDays(converted_base_finish)
        return base_finish_day
    }

    getActualStart(date) {
        var actual_start = new Date(date)
        var converted_actual_start = actual_start.getTime();
        var actual_start_day = this.convertDays(converted_actual_start)
        return actual_start_day
    }

    getActualFinish(date) {
        var actual_finish = new Date(date)
        var converted_actual_finish = actual_finish.getTime();
        var actual_finish_day = this.convertDays(converted_actual_finish)
        return actual_finish_day
    }

    convertDays(miliseconds) {
        var total_seconds = parseInt(Math.floor(miliseconds / 1000));
        var total_minutes = parseInt(Math.floor(total_seconds / 60));
        var total_hours = parseInt(Math.floor(total_minutes / 60));
        var days = parseInt(Math.floor(total_hours / 24));
        return days
    }
    render() {
        return (
            <div>
                <Box display={'flex'} flexDirection='column' justifyContent={'center'} width={250} padding="5px">
                    <Box display={'flex'} flexDirection='row' alignItems={'center'} width='100%'>
                        <Box fontSize={'10px'} width='20%'>
                            Baseline
                        </Box>
                        <Tooltip title={<div>Baseline Start: {moment(this.props.data.from_date).format('YYYY-MM-DD')}<br />Baseline Finish: {moment(this.props.data.to_date).format('YYYY-MM-DD')}</div>}>
                            <Box display={'flex'} flexDirection='row' justifyContent={'left'} alignItems='center' style={{ cursor: 'pointer' }} marginLeft='5px' width='80%'>
                                <MultiProgress
                                    transitionTime={1.2}
                                    elements={[
                                        {
                                            value: this.state.baseStart,
                                            color: getDarkGreyColor(),
                                        },
                                        {
                                            value: this.state.baseFinish,
                                            color: getGreencolor(),
                                        },
                                    ]}
                                    height={5}
                                    backgroundColor={getDarkGreyColor()}
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                    <Box display={'flex'} flexDirection='row' marginTop='15px' alignItems={'center'} width='100%'>
                        <Box fontSize={'10px'} width='20%'>
                            Actual
                        </Box>
                        <Tooltip title={<div>Actual Start: {moment(this.props.data.act_exp_start).format('YYYY-MM-DD')}<br />Actual Finish: {moment(this.props.data.act_exp_finish).format('YYYY-MM-DD')}</div>}>
                            <Box marginLeft='5px' style={{ cursor: 'pointer' }} width='80%'>
                                <MultiProgress
                                    transitionTime={1.2}
                                    elements={[
                                        {
                                            value: this.state.actualStart,
                                            color: getDarkGreyColor(),
                                        },
                                        {
                                            value: this.state.actualFinish,
                                            color: getGreencolor(),
                                        },
                                    ]}
                                    height={5}
                                    backgroundColor={getDarkGreyColor()}
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </div>
        )
    }
}

export default BaselineActualDate