import React, { Component } from 'react'
import MultiProgress from "react-multi-progress";
import { getGreencolor, getOrangecolor, getProgBgColor } from '../../MainDashBoard/ColorTheme/ColorTheme';

class StackedHoursToDate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            hoursToDate: 0,
            hoursAtComp: 0,
        }
    }
    componentDidMount() {
        this.calcHours()
    }

    calcHours() {
        var converted_hours = 0
        var hoursToDate = 0
        var hoursAtComp = 0
        converted_hours = 100 / this.props.data.hours_at_completion
        hoursToDate = converted_hours * this.props.data.hours_to_date
        hoursAtComp = converted_hours * this.props.data.hours_at_completion
        this.setState({ hoursToDate: hoursToDate, hoursAtComp: hoursAtComp })
    }
    render() {
        return (
            <MultiProgress
                transitionTime={1.2}
                elements={[
                    {
                        value: this.state.hoursToDate,
                        color: getGreencolor(),
                    },
                    {
                        value: this.state.hoursAtComp,
                        color: getOrangecolor(),
                    },
                ]}
                height={5}
                backgroundColor={getProgBgColor()}
            />
        )
    }
}

export default StackedHoursToDate