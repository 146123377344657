import { Component } from 'react';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import Service from "../../Networking/networkutils";
import CMRiskGraph from './CMRiskGraph';
import { withSnackbar } from 'notistack';
import { Grid } from '@mui/material';
import CMRiskInfoView from './CMRiskInfoView';
import CMRiskDSTable from './CMRiskDSTable';
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../../Helper/CommonThings';
import { screenSizeCheck } from '../../MainDashBoard/ScreensizeCheck/ScreensizeCheck';

const token = getCookie("bb_ts_token");
// const access = JSON.parse(getCookie("bb_ts_access"));
// const user = JSON.parse(getCookie("bb_ts_user"));

class CMRisk extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: null
        }
    }

    componentDidMount() {
        this.fetchOps()
    }

    fetchOps() {
        var url = `/bb/api/report/view/cm_risk_live_report/`
        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

    }


    render() {
        return (
            this.state.data ?
                <Grid container spacing={2}>
                    <Grid item xs={screenSizeCheck() ? 7 : 8}>
                        <CMRiskGraph value={this.state.data} />
                    </Grid>
                    <Grid item xs={screenSizeCheck() ? 5 : 4}>
                        <CMRiskInfoView value={this.state.data} />
                    </Grid>
                    <Grid item xs={12}>
                        <CMRiskDSTable value={this.state.data} />
                    </Grid>
                </Grid>

                : <LoopCircleLoading color={getPrimaryColor()} />
        );
    }
}

export default withSnackbar(CMRisk);