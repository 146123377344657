import React, { Component } from 'react'
import MaterialTable from 'material-table';
import Service from "../../Networking/networkutils";
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
const access = JSON.parse(getCookie("bb_ts_access"));

class Company extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        Service.get('/bb/api/company/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ isLoading: false, data: res.data, })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    postOps(data) {
        if (data.name === undefined || data.name === '') {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('SBU Name is empty!', {
                    variant: 'warning'
                });
            }, 200)

        } else {
            Service.post('/bb/api/company/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data,
            })
                .then(res => {
                    this.setState({ isLoading: true, })
                    this.fetchOps();
                    this.props.enqueueSnackbar('SBU added successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    putOps(data) {
        if (data.name === undefined || data.name === '') {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('SBU Name is empty!', {
                    variant: 'warning'
                });
            }, 200)

        } else {
            Service.put('/bb/api/company/' + data.id, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    name: data.name,
                },
            })
                .then(res => {
                    this.props.enqueueSnackbar('SBU updated successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    this.setState({ isLoading: true, })
                    errorMessage(this.props, error)
                    this.fetchOps();
                });
        }
    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <MaterialTable
                            key={3}
                            title="SBU Lists"
                            columns={[
                                { title: 'SBU ID', field: 'id', editable: 'never', },
                                { title: 'SBU Name', field: 'name', },
                            ]}
                            data={this.state.data}
                            options={{
                                paging: false,
                                padding: "dense",
                                addRowPosition: "first",
                                actionsColumnIndex: -1,
                            }}
                            icons={{
                                Add: props => <Button color='primary' variant="contained" startIcon={<AddIcon />}>
                                    Add SBU
                                </Button>
                            }}
                            editable={
                                ((access && access.access && !access.access.l24a.l24a_b) && (access && access.access && access.access.l24a.l24a_c)) ? (
                                    {

                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            this.putOps(newData)
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 600);
                                            }),
                                    }
                                ) : ((access && access.access && access.access.l24a.l24a_b) && (access && access.access && !access.access.l24a.l24a_c)) ? (
                                    {
                                        onRowAdd: newData =>
                                            new Promise(resolve => {

                                                setTimeout(() => {
                                                    resolve();
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        newData.created_by = user.email;
                                                        this.postOps(newData);
                                                        data.push(newData);
                                                        // this.postData(newData)
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),

                                    }
                                ) : ((access && access.access && access.access.l24a.l24a_b) && (access && access.access && access.access.l24a.l24a_c)) ? (
                                    {
                                        onRowAdd: newData =>
                                            new Promise(resolve => {

                                                setTimeout(() => {
                                                    resolve();
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        newData.created_by = user.email;
                                                        this.postOps(newData);
                                                        data.push(newData);
                                                        // this.postData(newData)
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            this.putOps(newData)
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 600);
                                            }),
                                    }
                                ) : (null)}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default withSnackbar(Company);