import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import Service from "../Networking/networkutils";
import { errorMessage } from '../Helper/ErrorMessage';
import { LoopCircleLoading } from 'react-loadingg';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, FormControl, Select, MenuItem, InputLabel, TextField } from '@material-ui/core';
import RolesReviseHistory from './RolesReviseHistory/RolesReviseHistory';
import { getPrimaryColor, hourRequestReasonType, HtmlTooltip } from '../Helper/CommonThings';
import { Alert, Autocomplete } from '@material-ui/lab';
import { CoffeeLoading } from 'react-loadingg';

const token = getCookie("bb_ts_token");
// const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class AdditianalHoursManage extends Component {

    constructor(props) {
        super()

        this.state = {
            data: [],
            isLoading: true,
            disbale_actions: false,
            country_code: {
                uk: 'UK',
                in: 'India',
                sl: 'Srilanka',
            },
            reason_type: 0,
            openRejectConfirm: false,
            openRequest: false,
            selectedRequest: null,
            openReviseHistory: false,
            openNewAllocationRequest: false,
            roles: [],
            selectedRole: null,
            hours: 0,
            coffeeLoading: true,
            buttonDisabled: false,
            hoursUtilizedData: null,
            hoursExistsCheckDialog: false,
            taskHrsData: null
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        var url = '/bb/api/tender/hours/request/?pdm=' + user.id
        if (this.props.task) {
            url = '/bb/api/tender/hours/request/?ttid=' + this.props.task + "&status=0"
        } else if (this.props.value) {
            url = '/bb/api/tender/hours/request/?tender_pid=' + this.props.value
        }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)

                var data = res.data;

                data.sort(sortByProperty("status"));

                this.setState({
                    data: data,
                    isLoading: false,
                    disbale_actions: true,
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    statusChange(rowData, status) {

        this.setState({
            isLoading: true,
            disbale_actions: true,
            openRejectConfirm: false,
            openRequest: false,
        })

        if (status === 2) {

            Service.post('/bb/api/tender/hours/request/reject/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    "request_id": rowData.id
                },
            })
                .then(res3 => {
                    this.props.enqueueSnackbar('Rejected!', {
                        variant: 'success'
                    });
                    var stateData = this.state.data
                    for (let i = 0; i < stateData.length; i++) {
                        let element = stateData[i];
                        if (element.id === rowData.id) {
                            stateData[i] = res3.data
                            break;
                        }
                    }
                    if (this.props.updateCount) {
                        this.props.updateCount()
                    }
                    this.setState({
                        isLoading: false,
                        data: stateData,
                        hoursUtilizedData: null
                    })
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        } else if (status === 1) {
            var dataInput = {
                "curr_history": {
                    user: user.id,
                    user_name: user.first_name + " " + user.last_name,
                    date_modified: new Date()
                },
                "request_id": rowData.id,
                "reason_type": this.state.reason_type
            }
            Service.post('/bb/api/tender/hours/request/approve/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: dataInput,
            })
                .then(res3 => {
                    this.props.enqueueSnackbar('Approved!', {
                        variant: 'success'
                    });
                    var stateData = this.state.data
                    for (let i = 0; i < stateData.length; i++) {
                        let element = stateData[i];
                        if (element.id === rowData.id) {
                            stateData[i] = res3.data
                            break;
                        }
                    }
                    if (this.props.updateCount) {
                        this.props.updateCount()
                    }
                    this.setState({
                        isLoading: false,
                        data: stateData,
                        hoursUtilizedData: null
                    })
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }

    }

    handleMail(to, sub, body, reply_to) {
        // console.log(to)
        Service.patch("/bb/api/mail/send_mail/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: [{
                sub: sub,
                to: to,
                body: body,
                reply_to: reply_to,
            }],
        })
            .then(res => {

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Unable to send mail request!', {
                    variant: 'warning'
                });

            });
    }

    openApproveDialog(rowData) {
        if (rowData.task_map === null & rowData.reason_type === 8) {
            this.setState({ selectedRequest: rowData, openNewAllocationRequest: true, hours: rowData.hours_needed })
            Service.get(`/bb/api/tender/hours/request/task_allocation_request_exist/?user_id=${rowData.uid}&task_id=${rowData.ttid}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({ coffeeLoading: false, roles: res.data.roles }, () => {
                        if (this.state.roles.length === 1) {
                            this.setState({ selectedRole: this.state.roles[0] })
                        }
                    })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                    console.log(error)
                });
        } else {
            this.usageData(rowData.ttid, rowData.uid)
            this.setState({ selectedRequest: rowData, openRequest: true, reason_type: rowData.reason_type })
        }
    }

    usageData(ttid, uid) {
        // console.log(ttid)
        Service.patch("/bb/api/team/tasks/get_utilized_hours/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                uid: uid,
                ttid: ttid
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({
                    hoursUtilizedData: res.data
                })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                    variant: 'error'
                });

            });
    }

    onRoleChange = (event, values) => {
        this.setState({
            selectedRole: values
        })
    }

    newAllocationApprove() {
        if (!this.state.selectedRole) {
            this.props.enqueueSnackbar('Please select the role!', {
                variant: 'warning'
            });
            return null
        }
        if (this.state.hours <= 0) {
            this.props.enqueueSnackbar('Hours should be greater than 0!', {
                variant: 'warning'
            });
            return null
        }

        if (this.state.selectedRole && this.state.hours > 0) {
            this.allocationApproveConfirm(0)
        }
    }

    allocationApproveConfirm(status) {
        var dataInput = {
            item_id: this.state.selectedRequest.id,
            role_id: this.state.selectedRole.id,
            hours_needed: this.state.hours,
            reason_type: 8,
            status: status
        }
        this.setState({ buttonDisabled: true })
        Service.post('/bb/api/tender/hours/request/approve_new_allocation_request/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: dataInput,
        })
            .then(res3 => {
                this.props.enqueueSnackbar('Approved!', {
                    variant: 'success'
                });
                var stateData = this.state.data
                for (let i = 0; i < stateData.length; i++) {
                    let element = stateData[i];
                    if (element.id === this.state.selectedRequest.id) {
                        stateData[i] = res3.data
                        break;
                    }
                }
                if (this.props.updateCount) {
                    this.props.updateCount()
                }
                this.setState({
                    isLoading: false,
                    data: stateData,
                    hours: 0,
                    roles: [],
                    selectedRole: null,
                    selectedRequest: null,
                    buttonDisabled: false,
                    openNewAllocationRequest: false,
                    hoursExistsCheckDialog: false,
                    taskHrsData: null
                })
            })
            .catch(error => {
                if (error && error.status === 406) {
                    this.setState({ buttonDisabled: false, taskHrsData: error.data.message, hoursExistsCheckDialog: true })
                    console.log(error)
                } else {
                    this.setState({ buttonDisabled: false, taskHrsData: null })
                    console.log(error)
                    errorMessage(this.props, error)
                }

            });
    }
    

    

    render() {

        const handleHoursChange = (event) => {
            this.setState({
                hours: event.target.value
            })
        };

        const handleRequestClose = () => {
            this.setState({ openRequest: false, hoursUtilizedData: null })
        };

        const handleNewRequestClose = () => {
            this.setState({ openNewAllocationRequest: false, hours: 0, selectedRole: null, roles: [], selectedRequest: null })
        };

        const handleReviseHistoryClose = () => {
            this.setState({ openReviseHistory: false, })
        };

        const handleRejectConfirmClose = () => {
            this.setState({ openRejectConfirm: false, })
        };

        const handleReasonTypeChange = (event) => {
            if (event.target.value !== 7 || this.state.selectedRequest.reason_type === 7) {
                this.setState({
                    reason_type: event.target.value
                })
            } else {
                this.props.enqueueSnackbar('This option is not allowed!', {
                    variant: 'warning'
                });

            }
        };

        const handleHoursExistsDialogClose = (event) => {
            this.setState({ hoursExistsCheckDialog: false, taskHrsData: null })
        }

        return (
            <div>
                {(this.state.isLoading) ? (<LoopCircleLoading color={getPrimaryColor()} />) : (
                    <div style={{ display: 'flex' }}>
                        <MaterialTable

                            title="Hours Requests"
                            columns={[

                                {
                                    title: 'Deliverables Schedule', field: 'role_data.t_tasks_data.t_wbs_data.pid_data.name',
                                    headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },


                                },
                                {
                                    title: 'WBS', field: 'role_data.t_tasks_data.t_wbs_data.name',
                                    headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },

                                },
                                {
                                    title: 'Tasks', field: 'role_data.t_tasks_data.name',
                                    headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                                    render: rowData => rowData.role_data.t_tasks_data.decs ? rowData.role_data.t_tasks_data.decs : rowData.role_data.t_tasks_data.name

                                },
                                {
                                    title: 'Role', field: 'role_data.name',
                                    headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },

                                },
                                {
                                    title: 'Current At Completion Hours', field: 'role_data.req_hours',
                                    render: rowData => <Typography>{rowData.role_data.req_hours + rowData.role_data.hours}</Typography>

                                },
                                {
                                    title: 'Additional Hours Requested', field: 'hours_needed',

                                },
                                {
                                    title: 'Hours Approved', field: 'hours_approved',

                                },
                                {
                                    title: 'Requested By', field: 'uid',
                                    render: rowData => rowData.uid_data.last_name + ", " + rowData.uid_data.first_name
                                },
                                {
                                    title: 'Reason Type', field: 'reason_type', editable: 'never',
                                    headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                    lookup: { 0: 'NA!', 1: 'Rework - external', 2: 'Rework - internal', 3: 'Additional info received', 4: 'Change in original scope', 5: 'Incorrect Estimate', 6: 'Extended work scope', 7: 'Increase within budget', 8: 'New Allocation Request' },
                                },
                                {
                                    title: 'Reason', field: 'comment',
                                    headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },

                                },
                                {
                                    title: 'Status', field: 'status',
                                    lookup: { 0: 'Pending', 1: 'Approved', 2: 'Rejected', }

                                },

                                {
                                    title: 'Requested On', field: 'date_created', type: 'date', defaultSort: 'desc'
                                },
                                {
                                    title: 'Actioned By', field: 'approved_by',
                                    render: rowData => rowData.approved_by ? <Typography>{rowData.approved_by_data.last_name + ", " + rowData.approved_by_data.first_name}</Typography> : null
                                },

                            ]}
                            data={this.state.data}
                            isLoading={this.state.isLoading}
                            actions={
                                [
                                    rowData => ({
                                        icon: 'check',
                                        tooltip: 'Approve',
                                        disabled: (rowData.status !== 0),
                                        onClick: (event, rowData) => { this.openApproveDialog(rowData) },
                                    }),
                                    rowData => ({
                                        icon: 'close',
                                        tooltip: 'Reject',
                                        disabled: (rowData.status !== 0),
                                        onClick: (event, rowData) => { this.setState({ selectedRequest: rowData, openRejectConfirm: true }) },
                                    }),
                                    rowData => ({
                                        icon: 'change_history',
                                        tooltip: 'Role revise history',
                                        onClick: (event, rowData) => { this.setState({ selectedRequest: rowData, openReviseHistory: true }) },
                                    }),
                                ]
                            }
                            options={{
                                padding: "dense",
                                paging: false,
                            }}

                        />
                        <Dialog onClose={handleRequestClose} fullWidth="true" aria-labelledby="customized-dialog-title" open={this.state.openRequest}>
                            <DialogContent dividers>
                                <FormControl variant="outlined" fullWidth style={{ marginTop: '15px' }} >
                                    <InputLabel id="demo-simple-select-outlined-label">Reason Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.reason_type}
                                        onChange={handleReasonTypeChange}
                                        label="Reason Type"
                                    >
                                        <MenuItem value={0}></MenuItem>
                                        {[1, 2, 3, 4, 5, 6, 7].map((item) =>
                                            <MenuItem value={item}>
                                                <HtmlTooltip
                                                    style={{ width: '100%' }}
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit">{hourRequestReasonType(item).menu}</Typography>
                                                            {hourRequestReasonType(item).note}
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <Box display="flex">{hourRequestReasonType(item).menu}</Box>
                                                </HtmlTooltip>
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                                {(this.state.hoursUtilizedData) ?
                                    <Alert style={{ marginTop: '15px' }} severity="info">
                                        <Box display="flex" flexDirection="row" fontSize={14}><Box fontWeight="fontWeightBold">{this.state.hoursUtilizedData.event_hours}</Box>&nbsp;hours from&nbsp;<Box fontWeight="fontWeightBold">{this.state.hoursUtilizedData.allowed_hours}</Box>&nbsp;allocated hours is utilized for this task</Box>
                                    </Alert>
                                    : null
                                }

                                <Box display="flex" justifyContent="center" m={1} p={1}>
                                    <Box>
                                        <Button variant="contained" color="primary"
                                            disabled={(!(this.state.reason_type))
                                                || ((this.state.allocated && (this.state.hours > ((this.state.allocated.tasks_roles_data.hours + this.state.allocated.tasks_roles_data.req_hours) - this.state.totalAllocForRole))) && !this.state.comment)}
                                            onClick={() => this.statusChange(this.state.selectedRequest, 1)}
                                        >
                                            Approve
                                        </Button>

                                    </Box>
                                </Box>
                            </DialogContent>
                        </Dialog>

                        <Dialog onClose={handleNewRequestClose} fullWidth="true" aria-labelledby="customized-dialog-title" open={this.state.openNewAllocationRequest}>
                            {this.state.coffeeLoading ? <CoffeeLoading /> :
                                <DialogContent >
                                    <Box display="flex" flexDirection={'column'} m={1}>
                                        <Box fontWeight={'Bold'} fontSize='18px'>
                                            New Allocation Request
                                        </Box>
                                        <Box>
                                            {this.state.selectedRequest?.role_data.t_tasks_data.name} --> {this.state.selectedRequest?.uid_data.last_name + ", " + this.state.selectedRequest?.uid_data.first_name}
                                        </Box>
                                    </Box>
                                    <Box display="flex" flexDirection={'column'} justifyContent="center" m={1} >
                                        <Autocomplete
                                            id={"nouns"}
                                            fullWidth
                                            value={this.state.selectedRole}
                                            options={this.state.roles}
                                            getOptionLabel={(option) => option.t_role__name + " (" + option.type + ") "}
                                            onChange={(e, v) => this.onRoleChange(e, v)}
                                            renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
                                        />
                                        <TextField
                                            style={{ marginTop: '15px' }}
                                            id="hours"
                                            label="Hours Required"
                                            value={this.state.hours}
                                            onChange={handleHoursChange}
                                            fullWidth
                                            type="number"
                                            variant="outlined"
                                        />
                                    </Box>

                                    <Box display="flex" justifyContent="center" m={1} >
                                        <Box>
                                            <Button variant="contained" color="primary"
                                                disabled={this.state.buttonDisabled}
                                                onClick={() => this.newAllocationApprove()}
                                            >
                                                Approve
                                            </Button>
                                        </Box>
                                    </Box>

                                </DialogContent>
                            }
                        </Dialog>

                        <Dialog onClose={handleHoursExistsDialogClose} fullWidth="true" aria-labelledby="customized-dialog-title" open={this.state.hoursExistsCheckDialog}>
                            <DialogTitle>
                                Exceeding hours Alert!
                            </DialogTitle>
                            <DialogContent >
                                <Box display="flex" flexDirection={'column'} m={1}>
                                    <Box>
                                        {this.state.taskHrsData}
                                    </Box>
                                    <Box mt={1}>
                                        Click <b>YES</b> for approving the requested hours.
                                        Click <b>NO</b> for approving only the remaining hours.
                                        Click <b>CANCEL</b> for leaving this request.
                                    </Box>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button color="primary"
                                    disabled={this.state.buttonDisabled}
                                    onClick={() => this.allocationApproveConfirm(1)}
                                >
                                    Yes
                                </Button>
                                <Button color="primary"
                                    disabled={this.state.buttonDisabled}
                                    onClick={() => this.allocationApproveConfirm(2)}
                                >
                                    No
                                </Button>
                                <Button color="primary"
                                    disabled={this.state.buttonDisabled}
                                    onClick={handleHoursExistsDialogClose}
                                >
                                    cancel
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.openRejectConfirm}
                            onClose={handleRejectConfirmClose}
                            aria-labelledby="alert-dialog-title1"
                            aria-describedby="alert-dialog-description1"
                        >
                            <DialogTitle id="alert-dialog-title1">{"Are you sure? Do you want to reject the request?"}</DialogTitle>

                            <DialogActions>
                                <Button onClick={handleRejectConfirmClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={() => this.statusChange(this.state.selectedRequest, 2)} color="primary" autoFocus>
                                    Reject
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.openReviseHistory}
                            onClose={handleReviseHistoryClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth={"md"}
                            fullWidth
                        >
                            <DialogContent dividers>
                                <RolesReviseHistory value={this.state.selectedRequest ? this.state.selectedRequest.role_data : null} />
                            </DialogContent>
                        </Dialog>
                    </div>
                )}

            </div>
        )
    }
}

function sortByProperty(property) {
    return function (a, b) {
        if (a[property] > b[property])
            return 1;
        else if (a[property] < b[property])
            return -1;

        return 0;
    }
}

export default withSnackbar(AdditianalHoursManage)