import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { Divider } from '@mui/material'
import ProfileCard from './ProfileCard/ProfileCard'
import TeamInfo from './TeamInfo/TeamInfo'
import TaskChart from './TaskChart/TaskChart'
import TasksInfo from './TasksInfo/TasksInfo'
import { Container, Row, Col } from 'react-grid';
import { profileSize, taskChartSize, taskInfoSize, teamInfoSize, teamTaskChartSize, teamTaskInfoSize, teamTeamInfoSize } from '../../../ScreensizeCheck/ScreensizeCheck'
import { Box, CircularProgress, } from '@mui/material';

class TeamTaskInfo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true
        }
        this.progress = React.createRef();
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {
        this.setState({ data: this.props.data, isLoading: false })
    }

    handleClick(data) {
        if (data) {
            this.props.handleClick(data)
        }
    }

    fetchOps(data) {
        this.setState({ data: data, isLoading: true }, () => {
            this.setState({ isLoading: false })
        })
        if (this.progress && this.progress.current) {
            this.progress.current.fetchData();
        }
    }

    render() {
        return (
            <Grid container direction='row' justifyContent='flex-start' alignItems='center' style={{ marginTop: '5px', boxSizing: 'border-box', border: '4px solid #EEBE86', borderRadius: '20px 20px 20px 20px', height: '100%', width: '100%' }}>
                <Container fluid >
                    {this.props.show_profile ?
                        <Row>
                            <Col md={teamTeamInfoSize()} >
                                <TeamInfo data={this.props.data} handleClick={this.handleClick} />
                            </Col>

                            <Divider orientation="vertical" flexItem />

                            <Col md={teamTaskInfoSize()}  >
                                <TasksInfo data={this.props.data} />
                            </Col>

                            <Col md={teamTaskChartSize()} >
                                {this.state.isLoading ?
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        <CircularProgress color='primary' />
                                    </Box>
                                    :
                                    <TaskChart data={this.state.data} />
                                }
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col md={profileSize()} >
                                <ProfileCard data={this.props.data} ref={this.progress}/>
                            </Col>

                            <Divider orientation="vertical" flexItem />

                            <Col md={teamInfoSize()} >
                                <TeamInfo data={this.props.data} handleClick={this.handleClick} />
                            </Col>

                            <Divider orientation="vertical" flexItem />

                            <Col md={taskInfoSize()}  >
                                <TasksInfo is_general_user={true} data={this.props.data} />
                            </Col>

                            <Col md={taskChartSize()} >
                                {this.state.isLoading ?
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        <CircularProgress color='primary' />
                                    </Box>
                                    : <TaskChart data={this.state.data} />}
                            </Col>
                        </Row>
                    }
                </Container>
            </Grid >
        )
    }
}

export default TeamTaskInfo

