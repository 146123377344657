import React, { Component } from 'react';

class LMSRedirect extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         
      }
    }

    componentDidMount() {
        window.location.href = "https://lms.bbparam.com/user/leave"
    }
    render() {
        return (
            <div>
                
            </div>
        );
    }
}

export default LMSRedirect;