import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import MaterialTable from 'material-table'

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class UserHoursRequestManage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
        }
    }


    componentDidMount() {
        this.fetchOps()
    }

    fetchOps() {
        Service.get("/bb/api/tender/hours/request/get_user_hours_request/?uid=" + user.id + "&team=" + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoading: false
                })
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    putData(data) {
        // console.log(data)
        Service.get("/bb/api/user/hours/request/" + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.status === 0) {
                    Service.put("/bb/api/user/hours/request/" + data.id + "/", {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: data,
                    })
                        .then(res => {
                            this.props.enqueueSnackbar('Updated Successfully!', {
                                variant: 'success'
                            });
                        })
                        .catch(error => {
                            // console.log(error)
                            errorMessage(this.props, error)
                        });
                } else {
                    this.props.enqueueSnackbar('Failed!', {
                        variant: 'error'
                    });
                    this.fetchOps()
                }

            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    render() {
        return (
            <div style={{ display: 'flex' }}>
                <MaterialTable
                    title="Request History"
                    columns={[

                        {
                            title: 'Project', field: 'project_name', editable: 'never',
                            headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                            // lookup: this.state.projectLookUp
                            // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },
                        {
                            title: 'DS', field: 'ds_name', editable: 'never',
                            headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                            // lookup: this.state.projectLookUp
                            // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },
                        {
                            title: 'WBS', field: 'wbs_name', editable: 'never',
                            headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                            // lookup: this.state.projectLookUp
                            // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },
                        {
                            title: 'Task', field: 'task_name', editable: 'never',
                            headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                            // render: rowData => rowData.task_data && rowData.task_data.decs ? rowData.task_data.decs : rowData.task_data.name
                            // lookup: this.state.projectLookUp
                            // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },

                        {
                            title: 'Hours Requested', field: 'hours_needed',
                            // lookup: this.state.projectLookUp
                            // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },
                        {
                            title: 'Hours Approved', field: 'hours_approved', editable: 'never',
                            // lookup: this.state.projectLookUp
                            // render: rowData => [1, 2, 4].includes(rowData.status) ? (rowData.pdm_req_data ? ([0, 2].includes(rowData.pdm_req_data.status) ? (rowData.hours_needed - rowData.pdm_req_data.hours_needed) : (rowData.hours_needed)) : rowData.hours_needed) : (null),
                        },
                        {
                            title: 'Status', field: 'status', editable: 'never',
                            // lookup: { 0: 'Pending', 1: 'Waiting for EM Approval', 2: 'Approved', 3: 'Rejected', 4: 'Partially Approved' },
                        },
                        {
                            title: 'Reason Type', field: 'reason_type', editable: 'never',
                            headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                            lookup: { 0: 'NA!', 1: 'Rework - external', 2: 'Rework - internal', 3: 'Additional info received', 4: 'Change in original scope', 5: 'Incorrect Estimate', 6: 'Extended work scope', 7: 'Increase within budget', 8: 'New Allocation Request' },
                        },
                        {
                            title: 'Comment', field: 'comment', editable: 'never',
                            headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                            // lookup: { 0: 'Pending', 1: 'Approved', 2: 'Rejected' },
                        },
                        {
                            title: 'Requested To', field: 'request_to_first_name', editable: 'never',
                            // render: rowData => rowData.uid_data.last_name + ", " + rowData.uid_data.first_name
                        },
                        {
                            title: 'Reviewed By', field: 'reviewed_by_first_name', editable: 'never',

                            // render: rowData => rowData.approved_by_data ? rowData.approved_by_data.first_name : null
                        },
                        {
                            title: 'Requested Date', field: 'request_date', type: 'date', defaultSort: 'desc', editable: 'never',
                            // lookup: this.state.projectLookUp
                            // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        padding: "dense",
                        // filtering: true,
                        // exportButton: true,
                        doubleHorizontalScroll: true,
                        paging: false,
                        // actionsColumnIndex: -1,
                        toolbarButtonAlignment: 'left',
                        searchFieldAlignment: 'left',

                    }}
                    actions={
                        [

                            // {
                            //     icon: () => <Button color='primary'>Close</Button>,
                            //     tooltip: 'Close',
                            //     isFreeAction: true,
                            //     hidden: this.props.noClose,
                            //     onClick: () => { this.props.onCloseCommand() },
                            // },

                        ]
                    }
                    editable={{
                        isEditable: rowData => rowData.status === 0,
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                if (newData.hours_needed) {
                                    if (parseFloat(newData.hours_needed) <= 100) {
                                        this.putData(newData)
                                        setTimeout(() => {
                                            resolve();
                                            if (oldData) {
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data[data.indexOf(oldData)] = newData;
                                                    return { ...prevState, data };
                                                });
                                            }
                                        }, 60);
                                    } else {
                                        setTimeout(() => {
                                            resolve();
                                            if (oldData) {
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data[data.indexOf(oldData)] = oldData;
                                                    return { ...prevState, data };
                                                });
                                            }
                                        }, 60);
                                        this.props.enqueueSnackbar('Not more than 100 hours is allowed in single request!', {
                                            variant: 'warning'
                                        });
                                    }
                                } else {
                                    this.props.enqueueSnackbar("Request hours can't be empty!", {
                                        variant: 'warning'
                                    });
                                }

                            }),
                    }}

                />
            </div>
        )
    }
}

export default withSnackbar(UserHoursRequestManage)