import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Slide } from '@material-ui/core';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { deleteCookie, getCookie, setCookie } from '../../Helper/CookieHelper';
import Service from '../../Networking/networkutils'
import CloseIcon from '@material-ui/icons/Close';
import WorkPlans from './WorkPlans';
import moment from 'moment';
import { errorMessage } from '../../Helper/ErrorMessage';

const token = getCookie("bb_ts_token");

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class WorkPlanDialog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentPlan: null,
            openWorkPlans: false
        }
        this.refreshPlan = React.createRef()
        this.selectedPlan = this.selectedPlan.bind(this)
    }

    componentDidMount() {
        if (getCookie('skip_workplan_date') && this.props.uid) {
            var date = getCookie('skip_workplan_date')
            if (moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
                deleteCookie('skip_workplan_date');
                this.getWeekPlan()
            }
        } else if (this.props.autoCheck && this.props.uid) {
            this.getWeekPlan()
        }
    }

    getWeekPlan() {
        if (!getCookie('skip_workplan_date')) {
            Service.get(`/bb/api/user/work/plan/user_plan_for_week/?user_id=${this.props.uid}&date=${moment(this.props.date).format('YYYY-MM-DD')}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({ currentPlan: res.data })
                    if (this.refreshPlan && this.refreshPlan.current) {
                        this.refreshPlan.current.getPlans()
                    }
                })
                .catch(error => {
                    if (error && error.status === 404) {
                        this.setState({ currentPlan: null, openWorkPlans: true })
                    } else if (error && error.status === 451) {
                        // Skip
                    } else {
                        errorMessage(this.props, error)
                        console.log(error)
                    }
                })
        }
    }

    weekPlan(type) {
        if (this.props.uid) {
            Service.get(`/bb/api/user/work/plan/user_plan_for_week/?user_id=${this.props.uid}&date=${moment(this.props.date).format('YYYY-MM-DD')}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.props.selectedPlan(res.data.work_plan_data.name)
                    this.setState({ currentPlan: res.data, openWorkPlans: type })
                    if (this.refreshPlan && this.refreshPlan.current) {
                        this.refreshPlan.current.getPlans()
                    }
                })
                .catch(error => {
                    if (error && error.status === 404) {
                        this.props.selectedPlan('Select Workplan')
                        this.setState({ currentPlan: null, openWorkPlans: type })
                    } else if (error && error.status === 451) {
                        // Skip
                    } else {
                        errorMessage(this.props, error)
                        console.log(error)
                    }
                })
        }
    }

    removeCurrentPlan(plan) {
        Service.delete('/bb/api/user/work/plan/' + plan.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.selectedPlan('Select Workplan')
                this.setState({ currentPlan: null })
                if (this.refreshPlan && this.refreshPlan.current) {
                    this.refreshPlan.current.getPlans()
                }
                if (getCookie('skip_workplan_date')) {
                    let date = getCookie('skip_workplan_date')
                    if (moment(date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
                        deleteCookie('skip_workplan_date')
                    }
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
                console.log(error)
            })
    }

    selectedPlan(plan) {
        this.setState({ currentPlan: plan, openWorkPlans: false }, () => {
            if (this.props.autoCheck) {
                this.getWeekPlan()
            } else if (!this.props.autoCheck) {
                this.props.selectedPlan(plan.name)
                // this.weekPlan()
            }
        })
    }

    render() {

        const handlePlanClose = () => {
            if (getCookie('skip_workplan_date') && this.props.autoCheck) {
                var date = getCookie('skip_workplan_date')
                if (moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
                    setCookie('skip_workplan_date', moment().format(), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                }
            } else if (!this.state.currentPlan && this.props.autoCheck) {
                setCookie('skip_workplan_date', moment().format(), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
            }

            this.setState({
                openWorkPlans: false
            })
        };

        return (
            <div>
                <Dialog fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={this.state.openWorkPlans} TransitionComponent={Transition} onClose={handlePlanClose}>
                    <DialogTitle id="customized-dialog-title" onClose={handlePlanClose} >
                        <Box display={'flex'} flexDirection='row' alignItems={'center'}>
                            <Box marginRight='10px' flexGrow={1} fontSize={'22px'}>
                                Select Plan for {" Week " + moment(this.props.date).isoWeek() + " (" + moment(this.props.date).startOf('week').add(1, 'days').format("MMM DD") + " - " + moment(this.props.date).endOf('week').subtract(1, 'days').format("MMM DD") + ")"}
                            </Box>
                            {
                                this.state.currentPlan ?
                                    <Button variant="outlined" onClick={() => this.removeCurrentPlan(this.state.currentPlan)} color='primary'>
                                        Remove Current Plan
                                    </Button>
                                    : null
                            }
                            <IconButton aria-label="close" onClick={handlePlanClose}>
                                <CloseIcon color='error' />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <WorkPlans ref={this.refreshPlan} uid={this.props.uid} date={moment(this.props.date).format()} currentPlan={this.state.currentPlan} selectedPlan={this.selectedPlan} />
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(WorkPlanDialog)
