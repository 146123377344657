import React, { Component } from 'react'
import MaterialTable from 'material-table'
// import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
// import Button from '@material-ui/core/Button';
import { LoopCircleLoading } from 'react-loadingg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getPrimaryColor, poundFormat } from '../../Helper/CommonThings';
// import { Box } from '@material-ui/core';
// import Moment from 'react-moment';
// import { getCookie } from '../../Helper/CookieHelper';
// import { errorMessage } from '../../Helper/ErrorMessage';


// const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));
class ProjectReportRoleSummary extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            openAdd: false,
            issueName: '',
            issueComment: '',
            isLoading: false,
        }
    }
    totalCalc(key) {
        var total = 0
        for (let i = 0; i < this.props.value.length; i++) {
            const element = this.props.value[i];
            total += element[key]
        }
        return total
    }
    render() {

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>

                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Budget Hours</TableCell>
                                        <TableCell>Budget Cost</TableCell>
                                        <TableCell>At Completion Hours</TableCell>
                                        <TableCell>At Completion Cost</TableCell>
                                        <TableCell>Variance Cost</TableCell>
                                        <TableCell>Allocated Hours</TableCell>
                                        {/* <TableCell>Actual Hours</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableCell>{this.totalCalc("hours")}</TableCell>
                                    <TableCell>{poundFormat(this.totalCalc("cost"))}</TableCell>
                                    <TableCell>{this.totalCalc("rev_hours")}</TableCell>
                                    <TableCell>{poundFormat(this.totalCalc("rev_cost"))}</TableCell>
                                    <TableCell>{poundFormat(this.totalCalc("delta_cost"))}</TableCell>
                                    <TableCell>{this.totalCalc("allocated")}</TableCell>
                                    {/* <TableCell>{this.totalCalc("actual")}</TableCell> */}

                                </TableBody>
                            </Table>
                        </TableContainer>

                        <MaterialTable
                            title="Role Based Summary"
                            columns={[
                                { title: 'Role', field: 'name' },
                                { title: 'Budget Hours', field: 'hours' },
                                { title: 'Budget Cost', field: 'cost' },
                                { title: 'At Completion Hours', field: 'rev_hours' },
                                { title: 'At Completion Cost', field: 'rev_cost' },
                                { title: 'Variance Cost', field: 'delta_cost' },
                                { title: 'Allocated Hours', field: 'allocated' },

                                // { title: 'Actual', field: 'actual' },
                            ]}
                            data={this.props.value}

                            options={{
                                padding: "dense",
                                actionsColumnIndex: -1,
                                pageSize: 20,
                                paging: false,
                                pageSizeOptions: [5, 20, 50],
                            }}
                        />

                    </div>
                )}

            </div>
        )
    }
}

export default withSnackbar(ProjectReportRoleSummary);