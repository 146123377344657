import { Box, Button, TextField } from '@mui/material';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import Service from '../../Networking/networkutils';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getCookie } from '../../Helper/CookieHelper';
import { Alert, Autocomplete } from '@material-ui/lab';

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

class OutofWorkHrsRequest extends Component {
    constructor(props) {
        super(props)
        // console.log(props)
        this.state = {
            hours: 0,
            comment: '',
            submitDisable: true,
            isExists: false,
            roles: [],
            selectedRole: null
        }
    }

    componentDidMount() {
        this.checkRecordExists()
    }

    checkRecordExists() {
        Service.get(`/bb/api/tender/hours/request/task_allocation_request_exist/?task_id=${this.props.value.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ isExists: res.data.allocation_request_exist, submitDisable: res.data.allocation_request_exist, roles: res.data.roles }, () => {
                    if (this.state.roles.length === 1) {
                        this.setState({ selectedRole: this.state.roles[0] })
                    }
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
                console.log(error)
            });
    }

    submitHrsReq() {
        if (!this.state.selectedRole) {
            this.props.enqueueSnackbar('Please select the role!', {
                variant: 'warning'
            });
            return null
        }
        if (this.state.hours <= 0) {
            this.props.enqueueSnackbar('Hours must be greater than 0!', {
                variant: 'warning'
            });
            return null
        }

        if (!this.state.comment) {
            this.props.enqueueSnackbar('Please fill the comments!', {
                variant: 'warning'
            });
        } else {
            this.setState({ submitDisable: true })
            let postData = {
                hours_needed: this.state.hours,
                comment: this.state.comment,
                ttid: this.props.value.id,
                task_role_id: this.state.selectedRole.id
            }

            Service.post(`/bb/api/tender/hours/request/create_additional_hour_request/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData
            })
                .then(res => {
                    this.setState({ submitDisable: false })
                    this.props.onCloseCommand()
                    this.props.enqueueSnackbar('Hours request sent successfully!', {
                        variant: 'success'
                    });

                })
                .catch(error => {
                    this.setState({ submitDisable: false })
                    errorMessage(this.props, error)
                    console.log(error)
                });
        }
    }

    onRoleChange = (event, values) => {
        this.setState({
            selectedRole: values
        })
    }

    render() {

        const handleHoursChange = (event) => {
            this.setState({
                hours: event.target.value
            })
        };

        const handleCommentChange = (event) => {
            this.setState({
                comment: event.target.value
            })
        };

        return (
            <div>
                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
                    {this.state.isExists ?
                        <Alert severity="warning" style={{ marginBottom: '5px' }}>
                            There are some pending request available.
                        </Alert> : null}
                    <Autocomplete
                        id={"nouns"}
                        fullWidth
                        value={this.state.selectedRole}
                        options={this.state.roles}
                        getOptionLabel={(option) => option.t_role__name + " (" + option.type + ") "}
                        // getOptionSelected={(option, value) => option.id === value.id}
                        onChange={(e, v) => this.onRoleChange(e, v)}
                        renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
                    />
                    <TextField
                        style={{ marginTop: '15px' }}
                        id="hours"
                        label="Hours Required"
                        value={this.state.hours}
                        onChange={handleHoursChange}
                        fullWidth
                        type="number"
                        variant="outlined"
                    />
                    <TextField
                        id="outlined-multiline-static"
                        label="Comment"
                        multiline
                        style={{ marginTop: '15px' }}
                        fullWidth
                        rows={2}
                        value={this.state.comment}
                        onChange={handleCommentChange}
                        variant="outlined"
                    />
                    <Box mt={1} sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                        <Button disabled={this.state.submitDisable} color='primary' variant='contained' onClick={() => this.submitHrsReq()}>Submit</Button>
                    </Box>
                </Box>
            </div>
        );
    }
}

export default withSnackbar(OutofWorkHrsRequest);