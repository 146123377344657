import React, { Component } from 'react'
import Service from "../../Networking/networkutils";
// import { LoopCircleLoading } from 'react-loadingg';
import MaterialTable from 'material-table';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar } from 'notistack';
// import User3W from '../UserRoleMapping/User3W';
import { withStyles } from '@material-ui/core/styles';
import { getCookie } from '../../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { blue } from '@material-ui/core/colors';
// import AdditionalHourRequest from './AssignRoles/AdditionalHourRequest';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { errorMessage } from '../../Helper/ErrorMessage';
import { Alert } from '@material-ui/lab';
import LeadHoursRequest from '../../HoursRequest/LeadHoursRequest';
import AdditionalHourRequest2 from '../AssignRoles/AdditionalHourRequest2';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
// var autocompdata = '';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

// const DialogTitle = withStyles(styles)((props) => {
//     const { children, classes, onClose, ...other } = props;
//     return (
//         <MuiDialogTitle disableTypography className={classes.root} {...other}>
//             <Typography variant="h6">{children}</Typography>
//             {onClose ? (
//                 <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//                     <CloseIcon />
//                 </IconButton>
//             ) : null}
//         </MuiDialogTitle>
//     );
// });
var waitData = false;

class TaskLevelAllocation extends Component {

    constructor(props) {
        super(props)

        this.state = {
            users: [],
            usersAll: [],
            data: [],
            isLoading: true,
            open: false,
            deliValue: {},
            roleUid: '0',
            openWho: false,
            pdmData: [],
            bid_data: {},
            hourReqButton: true,
            splPdm: false,
            pdmToRecv: null,
            isLoadingTable: false,
            roleActData: {},
            taskRoles: [],
            userListData: [],
            selected_role: null,
            selected_user: null,
            actualData: null,
            isLoadingHours: true,
            est_hours: 0,
            pendingHourRequest: [],
            openHourRequest: false,
            openRequest: false,
            selectedeRowData: null,
        }
    }

    componentDidMount() {
        var pageView = window.location.pathname + "/#TaskLevelAllocation";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        this.getEstHours();
        this.fetchOps();
    }

    fetchOps() {
        this.setState({
            isLoading: true
        })
        // console.log(this.props.team)


        Service.get('/bb/api/tender/user/task/get_task_allocations/?task_id=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)

                // var comData = [];
                // for (let i = 0; i < this.props.team.length; i++) {
                //     const element = this.props.team[i];
                //     var goon = true;
                //     for (let j = 0; j < res.data.length; j++) {
                //         const element1 = res.data[j];
                //         if (element1.user_id === element.uid) {
                //             goon = false;
                //         }
                //     }
                //     if (goon) {
                //         comData.push(
                //             {
                //                 type: 100,
                //                 user_data: element.uid_data
                //             }
                //         )
                //     }
                // }

                // console.log(res.data)

                this.setState({ data: res.data, isLoading: false, isLoadingTable: false, }, () => {
                    
                    // ## Not Required if below code is uncommented
                    this.getPendingRequest(this.props.value.id)

                    // var viewPid = []
                    // for (let i1 = 0; i1 < res.data.length; i1++) {
                    //     const element = res.data[i1];
                    //     viewPid.push({ 'com_ttid': this.props.value.id, 'com_uid': element.user_id })
                    // }
                    // if (viewPid.length !== 0) {
                    //     Service.patch("/bb/api/team/tasks/get_acthours/", {
                    //         headers: {
                    //             Authorization: "Token " + token,
                    //         },
                    //         data: viewPid,
                    //     })
                    //         .then(res12 => {

                    //             for (let j = 0; j < res12.data.length; j++) {
                    //                 const element13 = res12.data[j];
                    //                 for (let i = 0; i < res.data.length; i++) {
                    //                     const element12 = res.data[i];
                    //                     if (element12.user_id === element13.id2) {
                    //                         element12.actual_hours = timeToDecimal(element13.hours_actual)
                    //                         element12.inprogress_hours = timeToDecimal(element13.hours_ongoing)
                    //                         element12.total_alloc_hours = element13.allocated

                    //                     }
                    //                     // console.log(timeToDecimal(element13.hours_actual) !== 0)
                    //                 }

                    //             }
                    //             // console.log(dataD)
                    //             this.setState({

                    //                 data: [...res.data, ...comData]

                    //             })

                    //             this.getPendingRequest(this.props.value.id)

                    //         })
                    //         .catch(error => {
                    //             this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                    //                 variant: 'error'
                    //             });

                    //         });
                    // }

                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        Service.patch("/bb/api/team/tasks/get_acthours/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: [{ ttid: this.props.value.id }],
        })
            .then(res12 => {

                this.setState({

                    actualData: res12.data[0]

                })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                    variant: 'error'
                });

            });

    }

    getPendingRequest(taskid) {
        Service.get("/bb/api/user/hours/request/pending_count_task/?lead=" + user.id + "&task=" + taskid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // alert(res.data.length)

                this.setState({
                    pendingHourRequest: res.data
                })
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    getTaskRoles() {
        Service.get('/bb/api/tender/tasks/roles/?tid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    taskRoles: res.data
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    postData(userdata) {

        var pageView = window.location.pathname + "/#TenderAddAndEdit";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        if (userdata === '') {
            this.props.enqueueSnackbar('Select a user to continue!', {
                variant: 'warning'
            });
        } else {

            var user_role_ex = true
            for (let i = 0; i < this.state.data.length; i++) {
                const element = this.state.data[i];
                if (element.user_id === this.state.selected_user.id && element.task_role_id === this.state.selected_role.id) {

                    this.props.enqueueSnackbar('User already allocated to this role!', {
                        variant: 'warning'
                    });
                    user_role_ex = false

                }

            }
            // this.setState({ isLoading: true })

            if (user_role_ex) {
                if (this.state.selected_role && this.state.selected_user) {
                    var postData = {
                        task_id: this.props.value.id,
                        role_id: this.state.selected_role.t_role,
                        task_role_id: this.state.selected_role.id,
                        wbs_id: this.props.value.t_ohlwbs,
                        t_pid: this.props.value.t_pid,
                        user_id: this.state.selected_user.id,
                        user_email: this.state.selected_user.email,
                        created_by: user.email,
                    }
                    Service.post('/bb/api/tender/user/task/user_task_allocation/', {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: postData,
                    })
                        .then(res => {
                            waitData = false;
                            var data = this.state.data;
                            data.push(res.data);
                            // autocompdata = '';
                            this.setState({ data: data, isLoading: false, openAdd: false })
                            this.props.enqueueSnackbar('Data Added Successfully!', {
                                variant: 'success'
                            });
                            this.fetchOps();
                            // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
                        })
                        .catch(error => {
                            waitData = false;
                            errorMessage(this.props, error)
                            this.setState({ isLoading: false })
                        });
                } else {
                    this.props.enqueueSnackbar('Fill all field!', {
                        variant: 'warning'
                    });
                }

            }

        }

    }

    // givePlanned(data, asdata) {
    //     var tot_planned = 0
    //     for (let i = 0; i < data.length; i++) {
    //         const element = data[i];
    //         if (asdata.task_role_id === element.task_role_id && asdata.id !== element.id) {
    //             tot_planned = tot_planned + element.planed_hours
    //         }
    //     }
    //     return tot_planned + asdata.planed_hours
    // }

    putData(asdata, oldData, alldata) {

        this.setState({ isLoading: true, openAdd: false })
        Service.put('/bb/api/tender/user/task/' + asdata.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: asdata,
        })
            .then(res => {

                this.props.enqueueSnackbar('Edited Successfully!', {
                    variant: 'success'
                });
                this.fetchOps();
                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
                this.fetchOps();
                if (error && error.status === 416) {
                    this.props.enqueueSnackbar(error.data, {
                        variant: 'warning'
                    });
                } else if (error && error.status === 406) {
                    this.props.enqueueSnackbar(error.data, {
                        variant: 'warning'
                    });
                } else {
                    errorMessage(this.props, error)
                }
            });


    }

    deleteData(asdata) {

        // this.setState({ isLoading: true })
        Service.delete('/bb/api/tender/user/task/' + asdata.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Deleted Successfully!', {
                    variant: 'success'
                });
                this.fetchOps();
                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
                errorMessage(this.props, error)
                this.setState({ isLoading: false })
            });
    }

    // onTagsChange = (event, values) => {
    //     // console.log(values)
    //     autocompdata = values;
    // }

    handleUserSelect(e) {
        this.setState({ isLoadingTable: true });

        var ext = false;
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if (element.user_id === e.id) {
                ext = true;
                break;
            }
        }
        if (!ext && !waitData) {
            waitData = true;
            // this.setState({ openWho: false })
            this.postData(e);
        } else if (ext) {
            this.props.enqueueSnackbar('User already mapped!', {
                variant: 'warning'
            });
        } else if (waitData) {
            this.props.enqueueSnackbar('Please wait!', {
                variant: 'warning'
            });
        }
    }

    checkTeamUser(e) {
        var ext = false;
        // console.log(this.props.team)
        // console.log(e)
        for (let i = 0; i < this.props.team.length; i++) {
            const element = this.props.team[i];
            if (element.uid === e.user_id) {
                ext = true;
                break;
            }
        }
        // console.log(ext)
        if (ext) {
            return true;
        } else {
            // this.props.enqueueSnackbar("User is not member of this team", {
            //     variant: 'warning'
            // });
            return false;
        }
    }

    checkPDM() {

        // console.log(this.state.pdmData)
        // console.log(this.props.value.t_tasks_data.wbs.type)
        if (this.state.pdmData.length === 0) {
            if (this.state.bid_data.pdm) {
                this.setState({
                    splPdm: false,
                    pdmToRecv: this.state.bid_data.pdm_data,
                    hourReqButton: false
                })
            }
        } else {
            var gotit = false
            for (let i = 0; i < this.state.pdmData.length; i++) {
                const element = this.state.pdmData[i];
                if (element.pdm_type === this.props.value.t_tasks_data.wbs.type) {
                    this.setState({
                        splPdm: true,
                        pdmToRecv: element.uid_data,
                        hourReqButton: false
                    })
                    gotit = true;
                    break;
                }
            }
            if (!gotit) {
                if (this.state.bid_data.pdm) {
                    this.setState({
                        splPdm: false,
                        pdmToRecv: this.state.bid_data.pdm_data,
                        hourReqButton: false
                    })
                }
            }
        }
    }

    onTagsChange = (event, values) => {
        // console.log(event.target.id.split("-")[0].replace("'", "").replace("'", ""))
        this.setState({ [event.target.id.split("-")[0].replace("'", "").replace("'", "")]: values })

        var userList = []
        if (event.target.id.split("-")[0].replace("'", "").replace("'", "") === 'selected_role') {
            this.setState({ selected_user: null })
            for (let i = 0; i < this.props.team.length; i++) {
                const element = this.props.team[i];
                var goon = true
                for (let j = 0; j < this.state.data.length; j++) {
                    const element1 = this.state.data[j];
                    // console.log((element1.user_id +" "+ element.uid +"   "+ element1.role_id +"  "+ values.id))
                    if (element1.user_id === element.uid && element1.task_role_id === values.id) {
                        goon = false;
                    }
                }
                if (goon) {
                    userList.push(element.uid_data)
                }
            }
            // console.log(userList)
            this.setState({
                userListData: userList,
            })
        }


    }

    getEstHours() {
        Service.get('/bb/api/tender/tasks/roles/?tid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                var hours = 0, hours1 = 0
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    hours = hours + element.hours
                    hours1 = hours1 + element.hours + element.req_hours
                }
                // console.log(hours)
                this.setState({
                    est_hours: hours,
                    rev_hours: hours1,
                    isLoadingHours: false,
                })

            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    render() {
        // const { classes } = this.props;

        // const handleClose = () => {
        //     this.setState({ open: false })
        // };

        const handleAddClose = () => {
            this.setState({ openAdd: false })
        };

        const handleRequestClose = () => {
            this.setState({ openRequest: false })
        };

        const handleHourRequestClose = () => {

            this.setState({
                openHourRequest: false,

            })
            this.getPendingRequest(this.props.value.id)
        };
        // const handleWhoClose = () => {
        //     this.setState({
        //         openWho: false,

        //     })
        // };

        return (
            <div>
                {(this.state.pendingHourRequest.length) ? (
                    <div style={{ display: 'flex' }}>
                        <Alert severity="warning"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => this.setState({ openHourRequest: true })}
                                >
                                    View Requests
                                </Button>
                            }
                        >{this.state.pendingHourRequest.length + " additional hour request for this task is pending"}</Alert>
                    </div>
                ) : (null)}
                <MaterialTable
                    title={
                        <div>
                            <Box component={'p'} fontSize={16}>{(this.state.actualData) ? "Estimated Hours: " + this.state.est_hours + ",  Revised Hours: " + this.state.rev_hours : null}</Box>
                            <Box component={'p'} fontSize={16}>{(this.state.actualData) ? "Unallocated Hours: " + (this.state.rev_hours - this.state.actualData.allocated) + ",  Allocated Hours: " + this.state.actualData.allocated + ",   Actual and Inprogress Hours: " + (timeToDecimal(this.state.actualData.hours_actual) + timeToDecimal(this.state.actualData.hours_ongoing)) + ", Unutilized Hours: " + (this.state.actualData.allocated - (timeToDecimal(this.state.actualData.hours_actual) + timeToDecimal(this.state.actualData.hours_ongoing))) : null}</Box>
                        </div>
                    }
                    columns={[
                         {
                            title: 'User', field: 'first_name', editable: 'never',
                            render: rowData => <Typography>{rowData.first_name + " " + rowData.last_name}</Typography>
                        },
                        {
                            title: 'Allocated Roles', field: 'role_id__name',
                        },
                        {
                            title: 'Type', field: 'assign_type',
                            lookup: { 0: 'Assigned', 1: 'Reviewer', 100: 'Not Assigned', }
                        },
                        // {
                        //     title: 'Work Estimated Start', field: 'start', type: 'date', default: this.props.value.from_date,
                        // },
                        // {
                        //     title: ' Work Estimated End', field: 'end', type: 'date', default: this.props.value.to_date,
                        // },
                        {
                            title: 'Actual and Inprogress Hours/Total Allocated', field: 'act_and_inprogress', editable: 'never',
                            render: rowData => rowData.act_and_inprogress + "/" + rowData.total_alloc_hours
                        },
                        {
                            title: 'Unutilized Hours', field: 'balance_hours', editable: 'never',
                        },
                        {
                            title: 'Allocated Hours', field: 'planed_hours', type: 'numeric',
                        },
                        {
                            title: 'Allocation Cost', field: 'allocation_cost', editable: 'never', type: 'numeric',
                            render: rowData =>  new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.allocation_cost)
                        },
                    ]}
                    data={this.state.data}
                    options={{
                        padding: "dense",
                        pageSize: 5,
                        actionsColumnIndex: -1,
                        paging: false
                        // filtering: true
                    }}
                    isLoading={this.state.isLoadingTable || this.state.isLoading || this.state.isLoadingHours}
                    actions={
                        [

                            // rowData => ({
                            //     icon: 'add_box',
                            //     tooltip: (rowData.id) ? ('User Added') : ('Add User'),
                            //     onClick: (event, rowData) => this.handleUserSelect(rowData.user_data),
                            //     disabled: rowData.id
                            // }),

                            {
                                icon: 'add_box',
                                tooltip: 'Add User',
                                isFreeAction: true,

                                onClick: () => { this.getTaskRoles(); this.setState({ openAdd: true, selected_role: null, selected_user: null }) },
                            },
                            (this.props.requestOption) ? (
                                {
                                    icon: 'report',
                                    tooltip: 'Request Hours for this task role',
                                    // isFreeAction: true,
                                    disabled: !this.props.requestOption,
                                    onClick: (event, rowData) => { this.setState({ openRequest: true, selectedeRowData: rowData }) },
                                }

                            ) : (null),
                        ]
                    }
                    editable={{
                        isEditable: rowData => (rowData.id && this.checkTeamUser(rowData)),
                        // isDeletable: rowData => rowData.id,
                        // onRowAdd: newData =>
                        //     new Promise(resolve => {
                        //         setTimeout(() => {
                        //             resolve();
                        //             this.setState({ save: false });
                        //             this.setState(prevState => {
                        //                 const data = [...prevState.data];
                        //                 newData.roles = [];
                        //                 data.push(newData);
                        //                 return { ...prevState, data };
                        //             });
                        //         }, 60);
                        //     }),

                        onRowUpdate: (newData, oldData) =>

                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData && this.checkTeamUser(oldData)) {
                                        this.setState({ save: false });
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            delete newData['actual_hours']
                                            data[data.indexOf(oldData)] = newData;
                                            this.putData(newData, oldData, data);
                                            return { ...prevState, data };
                                        });
                                    }
                                }, 1000);
                                // this.putData(newData);
                            }),
                        isDeletable: rowData => (rowData.id && this.checkTeamUser(rowData) && rowData.act_and_inprogress === 0),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState({ save: false });
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        this.deleteData(oldData);
                                        return { ...prevState, data };
                                    });
                                }, 60);

                            }),
                    }}
                />

                {/* <Dialog onClose={handleWhoClose} aria-labelledby="simple-dialog-title" open={this.state.openWho}>
                                <DialogTitle id="simple-dialog-title">To Whom</DialogTitle>

                                <List>
                                    {this.props.team.map((text, index) => (
                                        <ListItem button key={text.id} onClick={() => { this.handleUserSelect(text.uid_data) }} >
                                            <ListItemAvatar>
                                                <Avatar className={classes.avatar}>
                                                    <PersonIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={text.name} />
                                        </ListItem>
                                    ))}
                                </List>
                                
                            </Dialog> */}
                <Dialog open={this.state.openAdd} fullWidth={true} maxWidth="md" onClose={handleAddClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title" onClose={handleAddClose}>
                        Allocate User
                    </DialogTitle>
                    <DialogContent>

                        <Box display="flex" flexDirection="row" p={1} m={1}>
                            <Autocomplete
                                id="'selected_role'"
                                options={this.state.taskRoles}
                                value={(this.state.selected_role === "") ? (null) : (this.state.selected_role)}
                                getOptionLabel={(option) => option.name + " (" + (option.hours + option.req_hours) + " hours)"}
                                style={{ width: '100%' }}
                                // disabled={access && access.access && access.access.l7a && !access.access.l7a.l7a_f}
                                onChange={this.onTagsChange}
                                // getOptionSelected={(option, { multiple, value }) => {
                                //     if (!multiple && this.state.selected_role) {
                                //         /*
                                //          * PROPOSAL for single selection, be able to provide own logic.
                                //          */
                                //         return (option.id === this.state.selected_role.id);
                                //     }

                                //     return false;
                                // }}
                                renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
                            />
                        </Box>
                        <Box display="flex" flexDirection="row" p={1} m={1}>
                            <Autocomplete
                                id="'selected_user'"
                                options={this.state.userListData}
                                value={(this.state.selected_user === "") ? (null) : (this.state.selected_user)}
                                getOptionLabel={(option) => option.last_name + ", " + option.first_name}
                                style={{ width: '100%' }}
                                // disabled={access && access.access && access.access.l7a && !access.access.l7a.l7a_f}
                                onChange={this.onTagsChange}
                                // getOptionSelected={(option, { multiple, value }) => {
                                //     if (!multiple && this.state.selected_role) {
                                //         /*
                                //          * PROPOSAL for single selection, be able to provide own logic.
                                //          */
                                //         return (option.id === this.state.selected_role.id);
                                //     }

                                //     return false;
                                // }}
                                renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                            />

                        </Box>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleAddClose} color="primary">
                            Close
                        </Button>

                        <Button onClick={() => this.postData()} color="primary">
                            Submit
                        </Button>

                    </DialogActions>
                </Dialog>

                <Dialog onClose={handleHourRequestClose} aria-labelledby="customized-dialog-title" maxWidth="xl" open={this.state.openHourRequest}>

                    <DialogContent>
                        <Box display="flex" flexDirection="row-reverse">

                            <Button color='primary' onClick={handleHourRequestClose}>Close</Button>

                        </Box>
                        <LeadHoursRequest lead={user.id} task={this.props.value.id} />
                    </DialogContent>
                </Dialog>

                <Dialog onClose={handleRequestClose} fullwidth maxWidth="xl" aria-labelledby="customized-dialog-title" open={this.state.openRequest}>
                    <DialogTitle id="customized-dialog-title" onClose={handleRequestClose}>
                        {(this.state.selectedeRowData) ? this.state.selectedeRowData.roles_data.name + " [ " + this.props.projectLook[this.props.value.t_wbs_data.pid_data.subproject_pid] + " - " + this.props.value.ds.name + " ] " : null}
                    </DialogTitle>
                    <DialogContent dividers>
                        {(this.state.selectedeRowData) ? (
                            <div>
                                <Typography>{this.props.value.wbs.name + "   --->  Revised Hours: " + (this.props.value.est_hrs ? this.props.value.est_hrs : null) + ", Allocated Hours: " + (this.props.value.pld_hrs ? this.props.value.pld_hrs : null) + ". Please contact EM to increase available hours for allocation."}</Typography>
                                <AdditionalHourRequest2 value={{
                                    id: this.state.selectedeRowData.task_role_id,
                                    name: this.state.selectedeRowData.roles_data.name,
                                    country: this.state.selectedeRowData.country,
                                    t_tasks: this.props.value.id,
                                    t_tasks_data: this.props.value,
                                    t_role: this.state.selectedeRowData.role_id,
                                    all_hours_to_this_role: this.state.selectedeRowData.req_hours,
                                }} task={this.props.value} team={this.props.team} rolesLookUp={this.props.allRoles ? this.props.allRoles : this.state.allRoles} projectLook={this.props.projectLook} />
                            </div>
                        ) : null}

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleRequestClose} color="primary">
                            Close
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

function addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec ? 1 : null
    if (len) {
        return parseFloat(Number(num).toFixed(len))
    } else {
        return parseFloat(Number(num))
    }
}


function timeToDecimal(t) {
    try {
        var arr = t.split(':');
        var dec = parseInt((arr[1] / 6) * 10, 10);

        return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
    } catch (error) {
        return 0
    }

}

export default withStyles(styles)(withSnackbar(TaskLevelAllocation));