import React, { Component } from 'react'
import MultiProgress from "react-multi-progress";
import { getGreencolor, getOrangecolor, getProgBgColor } from '../../MainDashBoard/ColorTheme/ColorTheme';

class StackedBudgetHours extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            budgetHours: 0,
            hoursAtComp: 0,
        }
    }
    componentDidMount() {
        this.calcHours()
    }

    calcHours() {
        var converted_hours = 0
        var budgetHours = 0
        var hoursAtComp = 0
        if (this.props.data.budget_hours < this.props.data.hours_at_completion) {
            converted_hours = 100 / this.props.data.hours_at_completion
            budgetHours = converted_hours * this.props.data.budget_hours
            hoursAtComp = converted_hours * this.props.data.hours_at_completion
            hoursAtComp = 100 - budgetHours
        } else {
            converted_hours = 100 / this.props.data.budget_hours
            budgetHours = converted_hours * this.props.data.budget_hours
            hoursAtComp = converted_hours * this.props.data.hours_at_completion
            hoursAtComp = 100 - hoursAtComp
            budgetHours = 100 - hoursAtComp
        }
        this.setState({ budgetHours: budgetHours, hoursAtComp: hoursAtComp })
    }
    render() {
        return (
            <MultiProgress
                transitionTime={1.2}
                elements={[
                    {
                        value: this.state.budgetHours,
                        color: getOrangecolor(),
                    },
                    {
                        value: this.state.hoursAtComp,
                        color: this.props.data.budget_hours < this.props.data.hours_at_completion ? '#b30000' : getGreencolor(),
                    },
                ]}
                height={5}
                backgroundColor={getProgBgColor()}
            />
        )
    }
}

export default StackedBudgetHours