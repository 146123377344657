import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../../Networking/networkutils";
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const token = getCookie("bb_ts_token");
// const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class IndividualRate extends Component {

    constructor(props) {
        super()

        this.state = {
            data: [],
            isLoading: true,
            selectedData: {},
            rate: 0,
            tenderUsers: [],
            allTenderUsers : [],
        }
    }

    componentDidMount() {
        this.fetchOps(0)
    }

    fetchOps(type) {
        var url = '/bb/api/tender/user/rate/?tender='
        var url1 = '/bb/api/tender/user/rate/get_tender_users/?tender='
        if (this.props.value.is_subproject) {
            url = '/bb/api/tender/user/rate/?ds='
            url1 = '/bb/api/tender/user/rate/get_ds_users/?ds='
        } else {
            url = '/bb/api/tender/user/rate/?tender='
            url1 = '/bb/api/tender/user/rate/get_tender_users/?tender='
        }
        Service.get(url + this.props.value.id + "&year=" + this.props.year, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {

                this.setState({
                    data: res.data,
                    isLoading: false
                })

                if (type === 0) {
                    this.getIndivi(url1, res.data)
                }
            })
            .catch(error => {

                console.log(error)
                errorMessage(this.props, error)
                // this.fetchOps();
            });
    }

    getIndivi(url1, data) {
        Service.get(url1 + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res1 => {
                var dataTo = []
                for (let i = 0; i < res1.data.length; i++) {
                    const element = res1.data[i];
                    var avail = false
                    for (let j = 0; j < data.length; j++) {
                        const element1 = data[j];
                        if (element.id === element1.user) {
                            avail = true
                            break
                        }
                    }
                    if (!avail) {
                        dataTo.push(element)
                    }
                }
                this.setState({
                    tenderUsers: dataTo,
                    allTenderUsers: res1.data
                    // isLoading: false
                })
            })
            .catch(error => {

                console.log(error)
                errorMessage(this.props, error)
                // this.fetchOps();
            });
    }

    addData() {
        if (this.state.selectedData) {
            this.setState({
                openAdd: false
            })
            Service.post('/bb/api/tender/user/rate/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    tender: (this.props.value.is_subproject) ? (this.props.value.subproject_pid) : (this.props.value.id),
                    ds: (this.props.value.is_subproject) ? (this.props.value.id) : (null),
                    user: this.state.selectedData.id,
                    rate: this.state.rate,
                    year: this.props.year,
                    created_by: user.id
                }
            })
                .then(res => {

                    var dataTo = []
                    for (let i = 0; i < this.state.allTenderUsers.length; i++) {
                        const element = this.state.allTenderUsers[i];
                        var avail = false
                        for (let j = 0; j < this.state.data.length; j++) {
                            const element1 = this.state.data[j];
                            if (element.id === element1.user) {
                                avail = true
                                break
                            }
                        }
                        if (!avail && this.state.selectedData.id !== element.id) {
                            dataTo.push(element)
                        }
                    }
                    this.setState({
                        tenderUsers: dataTo,
                        // isLoading: false
                    })
                    this.props.enqueueSnackbar('Added Successfully', {
                        variant: 'success'
                    });
                    this.fetchOps(1)
                })
                .catch(error => {

                    console.log(error)
                    errorMessage(this.props, error)
                    // this.fetchOps();
                });
        } else {
            this.props.enqueueSnackbar('Select a user to add!', {
                variant: 'warning'
            });
        }

    }

    putData(data) {
        Service.put('/bb/api/tender/user/rate/' + data.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data

        })
            .then(res => {
                this.props.enqueueSnackbar('Updated Successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {

                console.log(error)
                errorMessage(this.props, error)
                // this.fetchOps();
            });
    }

    deleteDate(data) {
        Service.delete('/bb/api/tender/user/rate/' + data.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.props.enqueueSnackbar('Deleted Successfully', {
                    variant: 'success'
                });

                var dataTo = []
                for (let i = 0; i < this.state.allTenderUsers.length; i++) {
                    const element = this.state.allTenderUsers[i];
                    var avail = false
                    for (let j = 0; j < this.state.data.length; j++) {
                        const element1 = this.state.data[j];
                        if (element.id === element1.user) {
                            avail = true
                            break
                        }
                    }
                    if (!avail || data.user === element.id) {
                        dataTo.push(element)
                    }
                }
                this.setState({
                    tenderUsers: dataTo,
                    // isLoading: false
                })
            })
            .catch(error => {

                console.log(error)
                errorMessage(this.props, error)
                // this.fetchOps();
            });
    }

    render() {
        const handleClose = () => {
            this.setState({ openAdd: false, });
        };

        const onChange = (event, values) => {
            this.setState({ selectedData: values });
        }

        const handleChange = (event) => {
            this.setState({
                rate: event.target.value
            })
        };

        return (
            <div>
                <MaterialTable
                    title={this.props.name + " - Special Rate"}
                    columns={
                        [
                            {
                                title: 'User', field: 'user_data',
                                editable: 'never',
                                render: rowData => rowData.user_data.last_name + ", " + rowData.user_data.first_name,
                            },
                            {
                                title: 'Rate', field: 'rate',

                            },

                        ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        padding: "dense",
                        actionsColumnIndex: -1,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30],
                    }}
                    actions={[
                        {
                            icon: "add",
                            tooltip: "Add",
                            isFreeAction: true,
                            onClick: () => {
                                this.setState({
                                    openAdd: true
                                })
                            }
                        },
                    ]}
                    editable={{

                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                this.putData(newData)
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            return { ...prevState, data };
                                        });
                                    }
                                }, 600);
                            }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                this.deleteDate(oldData)
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        // this.deleteData(oldData)
                                        return { ...prevState, data };
                                    });
                                }, 600);
                            }),
                    }}
                />
                <Dialog open={this.state.openAdd} onClose={handleClose}
                    maxWidth={"sm"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" >

                    {/* <DialogTitle id="alert-dialog-title">{}</DialogTitle> */}
                    <DialogContent>
                        <Autocomplete
                            id="combo-box-demo"
                            options={this.state.tenderUsers}
                            fullWidth
                            onChange={onChange}
                            getOptionLabel={(option) => option.first_name + " " + option.last_name}
                            style={{ width: 300, margin: '5px' }}
                            renderInput={(params) => <TextField {...params} label="Select an Individual" variant="outlined" />}
                        />
                        <TextField
                            id="outlined-basic" label="Rate (€)" variant="outlined"
                            type="number"
                            value={this.state.rate}
                            style={{ margin: '5px' }}
                            onChange={handleChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                        <Button onClick={() => this.addData()} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(IndividualRate)