import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import Service from '.././Networking/networkutils'
import { getPrimaryColor } from '../Helper/CommonThings';
import { LoopCircleLoading } from 'react-loadingg';
import { withSnackbar } from 'notistack';
import MainTenderSubTasks from './MainTenderSubTasks';

const token = getCookie("bb_ts_token");

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
});

class TenderSubTasksNewTab extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: null,
            isLoading: true
        }
    }

    componentDidMount() {
        // console.log(this.props.match.params.taskid)
        this.fetchData()
    }

    fetchData() {
        Service.get(`/bb/api/tender/wbs/tasks/get_task_details/?task_id=${this.props.match.params.taskid}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    render() {

        const classes = this.props.classes

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <>
                        <AppBar position="fixed" color='primary'>
                            <Toolbar>
                                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => window.close()}>
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    {this.state.data.name + " (" + this.state.data.tender_name + " -> " + this.state.data.ds_name + ") "}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <div style={{ marginTop: '70px' }}>
                            <MainTenderSubTasks task_id={this.state.data.id} />
                        </div>
                    </>)}
            </div>
        )
    }
}
export default withStyles(useStyles)(withSnackbar(TenderSubTasksNewTab))