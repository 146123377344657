import { Box, Button, LinearProgress, Typography, DialogContent } from '@material-ui/core';
import { Alert, Autocomplete } from '@mui/material';
import React, { Component } from 'react'
import Service, { getBlobSASURL } from '../Networking/networkutils';
import { errorMessage } from '../Helper/ErrorMessage';
import { getCookie } from '../Helper/CookieHelper';
import { LoopCircleLoading } from 'react-loadingg';
import { withSnackbar } from 'notistack';
import { DropzoneArea } from 'material-ui-dropzone';
import { Dialog, DialogTitle } from '@mui/material';
import { MenuItem, TextField } from '@mui/material';
import moment from 'moment';

const token = getCookie("bb_ts_token");

class ProjectRequestForm extends Component {

    constructor(props) {
        super(props)
        console.log(props)
        this.state = {
            isLoading: true,
            regions: [],
            clients: [],
            users: null,
            postData: {},
            disabled: false,
            files: [],
            loading: false,
            progress: 0,
        }
    }

    getRegions() {
        return new Promise((resolve, reject) => {
            Service.get('/bb/api/region/', {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error)
                });
        })
    }

    getClients() {
        return new Promise((resolve, reject) => {
            Service.get('/bb/api/clients/clients_uk_data/', {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error)
                });
        })
    }

    getUsers() {
        return new Promise((resolve, reject) => {
            Service.get('/bb/api/users/', {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error)
                });
        })
    }

    componentDidMount() {
        this.setState({ postData: {} })
        Promise.all([
            this.getRegions(),
            this.getClients(),
            this.getUsers()])
            .then(res => {
                let regionLookup = {}
                let clientLookup = {}
                let userLookup = {}
                for (let i = 0; i < res[0].length; i++) {
                    const element = res[0][i];
                    regionLookup[element.id] = element.name
                }
                for (let i = 0; i < res[1].length; i++) {
                    const element = res[1][i];
                    clientLookup[element.id] = element.name
                }
                for (let i = 0; i < res[2].length; i++) {
                    const element = res[2][i];
                    element.full_name = element.last_name + ", " + element.first_name
                    userLookup[element.id] = element.last_name + ", " + element.first_name
                }
                this.setState({
                    regions: res[0],
                    clients: res[1],
                    users: res[2], regionLookup, clientLookup, userLookup
                })
                if (this.props.editData) {
                    this.setState({ postData: JSON.parse(JSON.stringify(this.props.editData)) }, () => {
                        this.setState({ isLoading: false })
                    })
                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch(e => {
                console.log(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })


    }

    handleTextChange(e) {
        if (e.target.value < 0) {
            this.setState(prevState => ({
                postData: {
                    ...prevState.postData,
                    [e.target.id]: 0
                }
            }))
        } else {
            this.setState(prevState => ({
                postData: {
                    ...prevState.postData,
                    [e.target.id]: e.target.value
                }
            }))
        }
    }

    submit() {
        this.setState({
            disabled: true
        })
        if (!this.state.postData.client || !this.state.postData.region || !this.state.postData.lead_discipline || !this.state.postData.em ||
            !this.state.postData['project_name']) {
            this.props.enqueueSnackbar('Please fill all the mandatory fields', {
                variant: 'warning'
            })
            this.setState({
                disabled: false
            })
            return
        } else {
            Service.post('/bb/api/new/project/request/create_new_project/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: this.state.postData
            })
                .then(res => {
                    if (this.state.files.length > 0) {
                        this.uploadFiles(res.data.id)
                    } else {
                        this.props.enqueueSnackbar('Request Submitted Successfully', {
                            variant: 'success'
                        })
                        this.setState({
                            disabled: false,
                            postData: {}
                        })
                        if (this.props.reloadProp) {
                            this.props.reload()
                        }
                    }
                })
                .catch(error => {
                    this.setState({
                        disabled: false
                    })
                    errorMessage(this.props, error)
                });
        }
    }


    editData() {
        this.setState({
            disabled: true
        })
        if (!this.state.postData.client || !this.state.postData.region || !this.state.postData.lead_discipline || !this.state.postData.em ||
            !this.state.postData['project_name']) {
            this.props.enqueueSnackbar('Please fill all the mandatory fields', {
                variant: 'warning'
            })
            this.setState({
                disabled: false
            })
            return
        } else {
            Service.put('/bb/api/new/project/request/update_new_project_details/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: this.state.postData
            })
                .then(res => {
                    if (this.state.files.length > 0) {
                        this.uploadFiles(res.data.id)
                    } else {
                        this.props.enqueueSnackbar('Edited Successfully', {
                            variant: 'success'
                        })
                        this.setState({
                            disabled: false,
                            postData: {}
                        })
                        if (this.props.reloadProp) {
                            this.props.reload()
                        }
                    }
                })
                .catch(error => {
                    this.setState({
                        disabled: false
                    })
                    errorMessage(this.props, error)
                });
        }
    }

    handleChange(files) {
        this.setState({
            files: files
        });
    }

    uploadFiles = async (id) => {
        const { BlobServiceClient } = require("@azure/storage-blob");
        window.Buffer = require("buffer").Buffer;

        let blob_obj = getBlobSASURL()

        if (blob_obj) {
            const blobSasUrl = blob_obj.domain + "/" + blob_obj.connection_string

            const blobServiceClient = new BlobServiceClient(blobSasUrl);

            const containerName = "general/new_project_request/" + id;

            const containerClient = blobServiceClient.getContainerClient(containerName);

            // const SASToken = "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-12-31T12:44:46Z&st=2023-08-02T04:44:46Z&spr=https,http&sig=SNGaK%2FWsTOQ2ubG8IK9zdoETBBfZvSb6oyL5mJPVC%2Fc%3D"
            try {
                // debugger
                const promises = [];
                this.setState({ loading: true })
                var total_files_size = 0
                var file_name_array = []
                var obj = {}
                for (let i = 0; i < this.state.files.length; i++) {
                    const element = this.state.files[i];
                    if (element) {
                        total_files_size += element.size
                        obj[element.name] = 0
                    }
                }
                for (let i = 0; i < this.state.files.length; i++) {
                    const file = this.state.files[i];
                    if (file) {
                        file_name_array.push({ file_name: file.name, file_path: containerName + "/" + file.name })
                        const blockBlobClient = containerClient.getBlockBlobClient(file.name);
                        promises.push(blockBlobClient.uploadData(file, {
                            onProgress: ev => {
                                obj[file.name] = ev.loadedBytes
                                let total = 0
                                for (let j = 0; j < Object.keys(obj).length; j++) {
                                    const element = Object.keys(obj)[j];
                                    total += obj[element]
                                }
                                this.setState({ progress: (total / total_files_size) * 100 })
                            }
                        }));
                    }
                }
                await Promise.all(promises);
                this.putUpload(id, file_name_array)
            }
            catch (error) {
                console.log(error.message);
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            }
        } else {
            this.props.enqueueSnackbar('Blob not available!', {
                variant: 'warning'
            });
        }
    }

    putUpload(id, files) {
        Service.put(`/bb/api/new/project/request/update_new_project_file_location/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                id: id,
                file_location: files
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Request Submitted Successfully', {
                    variant: 'success'
                })
                this.setState({
                    disabled: false,
                    postData: {}
                })
                if (this.props.reloadProp) {
                    this.props.reload()
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    render() {

        return (
            <div>
                {this.state.isLoading ? <LoopCircleLoading /> :
                    <Box display="flex" justifyContent='center' alignItems={'center'} flexDirection={'column'}>
                        <Box marginTop={1}>

                            <Autocomplete
                                options={this.state.clients}
                                value={!this.state.postData.client ? null : { id: this.state.postData.client, name: this.state.clientLookup[this.state.postData.client] }}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => value !== null && option.id === value.id}
                                onChange={(e, v) => {
                                    if (v) {
                                        let postData = this.state.postData
                                        postData['client'] = v.id
                                        this.setState({ postData })
                                    } else {
                                        let postData = this.state.postData
                                        postData['client'] = null
                                        this.setState({ postData })
                                    }
                                }}
                                variant="outlined" style={{ width: '500px' }} marginTop={1} label='Client'
                                renderInput={(params) => <TextField {...params} label="Client" variant="outlined" helperText="*mandatory" FormHelperTextProps={{ style: { color: '#ff0000' } }} />} />
                        </Box>
                        <Box marginTop={1}>
                            <TextField
                                value={this.state.postData.project_name}
                                variant="outlined"
                                id='project_name'
                                helperText="*mandatory"
                                FormHelperTextProps={{ style: { color: '#ff0000' } }}
                                onChange={(e) => this.handleTextChange(e)}
                                style={{ width: '500px' }}
                                marginTop={1}
                                label='Project Name' />
                        </Box>
                        <Box marginTop={1}>
                            <Autocomplete
                                options={this.state.regions}
                                value={!this.state.postData.region ? null : { id: this.state.postData.region, name: this.state.regionLookup[this.state.postData.region] }}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option.id === value}
                                onChange={(e, v) => {
                                    if (v) {
                                        let postData = this.state.postData
                                        postData['region'] = v.id
                                        this.setState({ postData })
                                    } else {
                                        let postData = this.state.postData
                                        postData['region'] = null
                                        this.setState({ postData })
                                    }
                                }}
                                variant="outlined" style={{ width: '500px' }} label='Region'
                                renderInput={(params) => <TextField {...params} label="Region" variant="outlined" helperText="*mandatory" FormHelperTextProps={{ style: { color: '#ff0000' } }} />} />
                        </Box>
                        <Box marginTop={1}>
                            <TextField
                                fullWidth
                                select
                                labelId={"lead_discipline"}
                                id={'lead_discipline'}
                                value={this.state.postData.lead_discipline}
                                label={"Lead Discipline"}
                                FormHelperTextProps={{ style: { color: '#ff0000' } }}
                                helperText="*mandatory"
                                onChange={(e) => {
                                    let postData = this.state.postData
                                    postData['lead_discipline'] = e.target.value
                                    this.setState({ postData })
                                }}
                                style={{ width: '500px' }}
                            >
                                <MenuItem value={'ohl'}>
                                    OHL
                                </MenuItem>
                                <MenuItem value={'cabling'}>
                                    Cabling
                                </MenuItem>
                                <MenuItem value={'substation'}>
                                    Substation
                                </MenuItem>
                                <MenuItem value={'multi_utilities'}>
                                    Multi Utilities
                                </MenuItem>
                                <MenuItem value={'technical'}>
                                    Technical
                                </MenuItem>
                                <MenuItem value={'edc'}>
                                    EDC
                                </MenuItem>
                            </TextField>
                        </Box>
                        <Box marginTop={1}>
                            <TextField
                                value={this.state.postData.crm_number}
                                variant="outlined"
                                id='crm_number'
                                onChange={(e) => this.handleTextChange(e)}
                                style={{ width: '500px' }}
                                marginTop={1}
                                label='CRM Number' />
                        </Box>
                        <Box marginTop={1}>
                            <Autocomplete
                                options={this.state.users}
                                value={!this.state.postData.em ? null : { id: this.state.postData.em, full_name: this.state.userLookup[this.state.postData.em] }}
                                getOptionLabel={(option) => option.full_name}
                                getOptionSelected={(option, value) => option.id === value}
                                onChange={(e, v) => {
                                    if (v) {
                                        let postData = this.state.postData
                                        postData['em'] = v.id
                                        this.setState({ postData })
                                    } else {
                                        let postData = this.state.postData
                                        postData['em'] = null
                                        this.setState({ postData })
                                    }
                                }}
                                variant="outlined" style={{ width: '500px' }} marginTop={1} label='Engineering Manager'
                                renderInput={(params) => <TextField {...params} label="Engineering Manager" helperText="*mandatory" FormHelperTextProps={{ style: { color: '#ff0000' } }} variant="outlined" />} />
                        </Box>
                        <Box marginTop={1}>
                            <TextField
                                value={this.state.postData.contract_value}
                                variant="outlined"
                                id='contract_value'
                                InputProps={{ inputProps: { min: 0 } }}
                                type="number"
                                onChange={(e) => this.handleTextChange(e)}
                                style={{ width: '500px' }}
                                marginTop={1}
                                label='Contract Value ($m)' />
                        </Box>
                        <Box marginTop={1}>
                            <TextField
                                format='dd/mm/yyyy'
                                value={!this.state.postData.itt_start ? this.state.postData.itt_start : moment(this.state.postData.itt_start).format('YYYY-MM-DD')}
                                type="date"
                                variant="outlined"
                                id='itt_start'
                                onChange={(e) => this.handleTextChange(e)}
                                style={{ width: '500px' }}
                                marginTop={1}
                                label='ITT Start'
                                InputLabelProps={{ shrink: true }} />
                        </Box>
                        <Box marginTop={1}>
                            <TextField
                                format='dd/mm/yyyy'
                                type="date"
                                variant="outlined"
                                value={!this.state.postData.gate_3 ? this.state.postData.gate_3 : moment(this.state.postData.gate_3).format('YYYY-MM-DD')}
                                id='gate_3'
                                onChange={(e) => this.handleTextChange(e)}
                                style={{ width: '500px' }}
                                marginTop={1}
                                label='Gate 3'
                                InputLabelProps={{ shrink: true }} />
                        </Box>
                        <Box marginTop={1}>
                            <TextField
                                format='dd/mm/yyyy'
                                value={!this.state.postData.tender_submission ? this.state.postData.tender_submission : moment(this.state.postData.tender_submission).format('YYYY-MM-DD')}
                                type="date"
                                variant="outlined"
                                id='tender_submission'
                                FormHelperTextProps={{ style: { color: '#ff0000' } }}
                                onChange={(e) => this.handleTextChange(e)}
                                style={{ width: '500px' }}
                                marginTop={1}
                                label='Tender Submission'
                                InputLabelProps={{ shrink: true }} />
                        </Box>
                        <Box marginTop={1}>
                            <TextField
                                format='dd/mm/yyyy'
                                value={!this.state.postData.contract_award ? this.state.postData.contract_award : moment(this.state.postData.contract_award).format('YYYY-MM-DD')}
                                type="date"
                                variant="outlined"
                                id='contract_award'
                                FormHelperTextProps={{ style: { color: '#ff0000' } }}
                                onChange={(e) => this.handleTextChange(e)}
                                style={{ width: '500px' }}
                                marginTop={1}
                                label='Contract Award'
                                InputLabelProps={{ shrink: true }} />
                        </Box>
                        <Box marginTop={1}>
                            <TextField
                                format='dd/mm/yyyy'
                                value={!this.state.postData.contract_completion ? this.state.postData.contract_completion : moment(this.state.postData.contract_completion).format('YYYY-MM-DD')}
                                type="date"
                                variant="outlined"
                                id='contract_completion'
                                FormHelperTextProps={{ style: { color: '#ff0000' } }}
                                onChange={(e) => this.handleTextChange(e)}
                                style={{ width: '500px' }}
                                marginTop={1} x
                                label='Contract Completion'
                                InputLabelProps={{ shrink: true }} />
                        </Box>
                        <Box marginTop={1}>
                            <TextField
                                value={this.state.postData.tender_weeks}
                                variant="outlined"
                                InputProps={{ inputProps: { min: 0 } }}
                                id='tender_weeks'
                                type='number'
                                onChange={(e) => this.handleTextChange(e)}
                                style={{ width: '500px' }}
                                marginTop={1}
                                label='Tender Weeks'
                                InputLabelProps={{ shrink: true }} />
                        </Box>
                        <Box marginTop={1}>
                            <TextField
                                select
                                value={this.state.postData.gate}
                                type="date"
                                variant="outlined"
                                id={'gate'}
                                FormHelperTextProps={{ style: { color: '#ff0000' } }}
                                onChange={(e) => {
                                    let postData = this.state.postData
                                    postData['gate'] = e.target.value
                                    this.setState({ postData })
                                }}
                                style={{ width: '500px' }}
                                marginTop={1} x
                                label='Go/No Go'
                                InputLabelProps={{ shrink: true }} >
                                <MenuItem value={0}>Prospect</MenuItem>
                                <MenuItem value={1}>Go</MenuItem>
                                <MenuItem value={3}>No Go</MenuItem>
                            </TextField>
                        </Box>
                        {this.props.editData ? null :
                            <Box marginTop={1} width={'100%'}>
                                <Alert
                                    severity="info"
                                    action={
                                        <Button color="inherit" size="small" onClick={() => window.open("https://balfourbeatty.sharepoint.com/:x:/r/sites/powertandd/Shared%20Documents/Roles%20%26%20Grades.xlsx?d=w77744dc83e27453f8bdba649c12863c8&csf=1&web=1&e=GzHqkm")}>
                                            Open File
                                        </Button>
                                    }
                                >
                                    Complete file with adding hours for required role in column D and location in column E and upload
                                </Alert>
                            </Box>}
                        {this.props.editData ? null :
                            <Box marginTop={1} width={'100%'}>
                                <DropzoneArea
                                    // acceptedFiles={['.jpg','.xls',".xlsx", '.docx', '.ppt', '.jpeg', '.png', '.mp4']}
                                    maxFileSize={10000000}
                                    dropzoneText='Please attach a file with Names, Roles, Grades etc. (Drag and drop a file here or click)'
                                    onChange={this.handleChange.bind(this)}
                                />
                            </Box>}
                        <Box marginTop={1}>
                            {this.props.editData ? <Button color='primary' variant='contained' onClick={() => this.editData()} disabled={this.state.disabled}>Edit</Button> :
                                <Button color='primary' variant='contained' onClick={() => this.submit()} disabled={this.state.disabled}>Submit</Button>}
                        </Box>
                    </Box>
                }

                <Dialog maxWidth="lg" fullWidth={true} open={this.state.loading}  >
                    <DialogTitle marginTop="2px" spacing={2}>Uploading...</DialogTitle>
                    <DialogContent>
                        <Box display="flex" alignItems="center">
                            <Box width="100%" mr={1}>
                                <LinearProgress variant="determinate" value={this.state.progress} />
                            </Box>
                            <Box minWidth={35}>
                                <Typography variant="body2" color="primary">{`${Math.round(
                                    this.state.progress,
                                )}%`}</Typography>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(ProjectRequestForm);

