import React, { Component } from 'react'
import moment from 'moment'
import { getCookie } from '../Helper/CookieHelper';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Box } from '@mui/system';
import ShortcutIcon from '@mui/icons-material/Shortcut';

var access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

export default class RedirectAlert extends Component {

    constructor(props) {
        super(props)

        this.state = {
            redirect: false
        }
    }

    componentDidMount() {
        // console.log(moment.utc("2022-04-13").set('hour', 5).set('minute', 45).toDate() , moment().toDate())
        if ((window.location.hostname === 'new.bbparam.com') && getCookie("bb_ts_token") && this.checkAccess()) {
            this.setState({
                redirect: true
            })
        }
    }

    checkAccess() {

        var accessTillDate = '"2022-05-04"'

        if (access && access.access && access.access.l111a && access.access.l111a.l111a_g) {
            return false
        }
        // console.log(user.office_loc === 1 && (moment.utc("2022-04-13").set('hour', 5).set('minute', 45).toDate() < moment().toDate()) && (moment("2022-05-02").toDate() >= moment().toDate()))
        if (user.office_loc === 1 && (moment.utc("2022-04-13").set('hour', 5).set('minute', 45).toDate() < moment().toDate()) && (moment(accessTillDate).toDate() >= moment().toDate())) {
            return false
        }
        if ((moment(accessTillDate).toDate() >= moment().toDate()) && (access && access.access && access.access.l111a && access.access.l111a.l111a_f)) {
            return false
        }
        return true 
    }

    render() {
        return (
                (this.state.redirect ?
                    <Dialog open={true}>
                        <DialogTitle>Redirect to bbparam.com</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                This version is disabled until a new update is available.
                            </DialogContentText>
                            {/* <Box sx={{display: 'flex', justifyContent: 'center' }}>
                                <img alt="redirect" src="https://ps.w.org/simple-website-redirect/assets/icon-128x128.png" />
                            </Box> */}
                            <Box sx={{display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => window.location.href = "https://bbparam.com"} startIcon={<ShortcutIcon />} variant="outlined">Go to bbparam.com</Button>
                            </Box>
                        </DialogContent>
                    </Dialog>
                    : null)

        )
    }
}
