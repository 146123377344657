import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment'
import RateTemplate from './RateTemplate';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
    },
});

class RateCardTemplateTab extends Component {

    constructor(props) {
        super()

        this.state = {
            value: 0,
            years: []
        }
    }

    componentDidMount() {
        var years = []
        var value = 0
        for (let i = 2020; i < moment().year(); i++) {
            years.push(i)
            value = years.length
        }
        for (let i = moment().year(); i < moment().year() + 5; i++) {
            years.push(i)
        }

        this.setState({
            years: years,
            value: value
        })
    }

    render() {

        const { classes } = this.props;

        const handleChange = (event, newValue) => {
            this.setState({
                value: newValue
            })
        };

        return (
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs value={this.state.value} onChange={handleChange} aria-label="simple tabs example"
                         indicatorColor="primary"
                         textColor="primary"
                         variant="scrollable"
                         scrollButtons="auto"
                    >
                        {this.state.years.map((value, index) =>
                            <Tab label={value} {...a11yProps(index)} />
                        )}

                    </Tabs>
                </AppBar>
                {this.state.years.map((value, index) =>
                    <TabPanel value={this.state.value} index={index}>
                        <RateTemplate value={value} editable={this.props.editable} />
                    </TabPanel>
                )}

                {/* <TabPanel value={this.state.value} index={1}>
                    <SecuredPro/jectRoles value={props.value} />
                </TabPanel> */}
                {/* <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
            </div>
        )
    }
}

export default withStyles(useStyles)(RateCardTemplateTab)