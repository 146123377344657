import { Box, Button, TextField } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, Container, InputAdornment } from '@mui/material';
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../../Helper/CommonThings';
import Service from '../../Networking/networkutils';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getCookie } from '../../Helper/CookieHelper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import OutofWorkTaskCards from './OutofWorkTaskCards';

const token = getCookie("bb_ts_token");
class OutOfWork extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            projects: [],
            selectedProject: null,
            isLoading: true,
            tableLoading: false,
            viewClass: 'projects',
            taskKeyword: null,
            searched: false
        }
    }

    componentDidMount() {
        this.fetchProjects()
    }

    fetchProjects() {
        Service.get(`/bb/api/tender/project/available_workbook/?filter_type=2`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ projects: res.data, isLoading: false })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    searchTasks() {
        var url = ''
        if (this.state.viewClass === "projects") {
            if (!this.state.selectedProject) {
                this.props.enqueueSnackbar('Please select the project!', {
                    variant: 'warning'
                });
                return null
            } else {
                url = `/bb/api/tender/wbs/tasks/project_tasks_data/?project=${this.state.selectedProject.id}`
            }
        } else {
            if (!this.state.taskKeyword) {
                this.props.enqueueSnackbar('Please fill the task keyword!', {
                    variant: 'warning'
                });
                return null
            } else {
                url = `/bb/api/tender/wbs/tasks/task_search_key/?searchkey=${this.state.taskKeyword}`
            }
        }
        if (url) {
            this.setState({ searched: true, tableLoading: true })
            Service.get(url, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({ data: res.data, tableLoading: false })
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    onProjectChange = (value) => {
        this.setState({ selectedProject: value })
    }

    taskKeywordChange = (e) => {
        this.setState({ taskKeyword: e.target.value })
    }

    render() {

        const handleChange = (event, newAlignment) => {
            if (newAlignment) {
                this.setState({ viewClass: newAlignment, selectedProject: null, taskKeyword: null })
            }
        };

        return (
            <Container maxWidth={false} disableGutters>
                {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} m={1}>
                        <Alert severity="info">
                            {
                                !this.state.searched ?
                                    <div>
                                        Filter tasks which is aligned to your role.
                                        If you dont see the task you are looking for, please reach out to the respective EM.
                                        EMs names are displayed in brackets next to the Project Name.</div>
                                    :
                                    `Displaying tasks filtered based on your role.`
                            }
                        </Alert>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} marginTop={1}>
                            <Box fontSize={'18px'} fontWeight={400}>
                                Search by:
                            </Box>
                            <Box marginLeft={'5px'}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={this.state.viewClass}
                                    exclusive
                                    onChange={handleChange}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="projects">Projects</ToggleButton>
                                    <ToggleButton value="tasks">Task Keyword</ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                            {this.state.viewClass === 'projects' ?
                                <Box marginLeft={'20px'}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={this.state.projects}
                                        getOptionLabel={(option) => option.name + " (" + option?.tenderbid_data?.pdm_data?.last_name + ", " + option?.tenderbid_data?.pdm_data?.first_name + ") "}
                                        style={{ width: 400 }}
                                        renderInput={(params) => <TextField {...params} label="Projects" variant="outlined" />}
                                        onChange={(e, v) => this.onProjectChange(v)}
                                    />
                                </Box> :
                                <Box marginLeft={'20px'}>
                                    <TextField
                                        value={this.state.taskKeyword}
                                        variant='outlined'
                                        autoComplete='off'
                                        placeholder="Task Keyword"
                                        id="search"
                                        style={{ width: 400 }}
                                        onChange={(e) => this.taskKeywordChange(e)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                        }}
                                    />
                                </Box>}
                            <Box marginLeft={'20px'}>
                                <Button color='primary' variant='contained' onClick={() => this.searchTasks()}>Search</Button>
                            </Box>
                        </Box>
                        <Box marginTop={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} width="100%" alignItems={'center'}>
                            {!this.state.searched ? null : this.state.tableLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                                <Container maxWidth={false} disableGutters>
                                    <OutofWorkTaskCards data={this.state.data} maxCards={4} />
                                </Container>
                            }
                            {/* <MaterialTable
                                style={{ width: '100%' }}
                                title=""
                                columns={[
                                    {
                                        title: 'Project', field: 'project_name',
                                    },
                                    {
                                        title: 'EM', field: 'em_name',
                                    },
                                    {
                                        title: 'DS', field: 'ds_name'
                                    },
                                    {
                                        title: 'WBS', field: 'wbs_name',
                                    },
                                    {
                                        title: 'Task Name', field: 'name',
                                        render: rowData => !rowData.decs ? rowData.name : rowData.decs
                                    },
                                ]}

                                data={this.state.data}
                                isLoading={this.state.tableLoading}
                                options={{
                                    padding: "dense",
                                    paging: false,
                                    actionsColumnIndex: -1,
                                    search: true,
                                    filtering: true
                                }}
                            /> */}

                        </Box>
                    </Box>}
            </Container>
        );
    }
}

export default withSnackbar(OutOfWork);