
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react'
import { sortByProperty } from '../../Helper/CommonThings';
import { getCookie } from '../../Helper/CookieHelper';
import Service from "../../Networking/networkutils";
import { errorMessage } from '../../Helper/ErrorMessage';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class WBSManager extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            users: {},
            ds_wbs_lookup: {},
            data: [],
        }
    }

    componentDidMount() {

        this.fetchOps();

    }

    fetchOps() {
        Service.get('/bb/api/tender/wbsmanager/?pid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(respro => {

                for (let i = 0; i < respro.data.length; i++) {
                    const element = respro.data[i];
                    element.uid_mod = "id" + element.uid
                    if (element.frwd_uid) {
                        element.frwd_uid_mod = "id" + element.frwd_uid
                    } else {
                        element.frwd_uid_mod = element.frwd_uid
                    }
                    element.ds_wbs_id = element.wbsid_data.pid_data.id + "id" + element.wbsid
                }

                Service.get("/bb/api/users/", {
                    headers: {
                        Authorization: "Token " + token,
                    },

                })
                    .then(res => {
                        var responceData = res.data.sort(sortByProperty('last_name'))

                        var users = {}
                        users[null] = "None"
                        for (let i = 0; i < responceData.length; i++) {
                            const element = responceData[i];
                            users["id" + element.id] = element.last_name + ", " + element.first_name
                        }
                        // console.log(users)
                        // console.log(respro.data)

                        this.setState({
                            users: users,
                            data: respro.data,
                            isLoading: false
                        })

                    })
                    .catch(error => {
                        // console.log(error)
                        errorMessage(this.props, error)
                    });

            })
            .catch(error => {
               errorMessage(this.props, error)
            });

            Service.get(`/bb/api/tender/project/?subproj=` + this.props.value.id, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res2 => {

                    var pids = "0"
                    for (let i = 0; i < res2.data.length; i++) {
                        const element = res2.data[i];
                        pids = pids + "," + element.id;
                    }

                    Service.get('/bb/api/tender/ohlwbs/?pid=' + pids, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res3 => {
                            var ds_wbs_lookup = {}
                            for (let i = 0; i < res3.data.length; i++) {
                                const element = res3.data[i];
                                ds_wbs_lookup[element.pid_data.id + "id" + element.id] = element.pid_data.name + " -> " + element.name
                            }
                            this.setState({
                                ds_wbs_lookup: ds_wbs_lookup
                            })
                        })
                        .catch(error => {
                            // console.log(error)
                            errorMessage(this.props, error)
                        });

                   
                })
                .catch(error => {
                    // console.log(error)
                    errorMessage(this.props, error)
                });
    }

    postData(postData) {
        // console.log(postData)
        if (!postData.ds_wbs_id || postData.ds_wbs_id === '' || postData.ds_wbs_id === undefined || !postData.uid_mod || postData.uid_mod === '' || postData.uid_mod === undefined) {
            this.props.enqueueSnackbar('Fill EM type and EM!', {
                variant: 'warning'
            });
        } else {
            postData.uid = parseInt(postData.uid_mod.split('d')[1])
            postData.wbsid = parseInt(postData.ds_wbs_id.split('d')[1])

            postData.created_by = user.id;
            postData.pid = this.props.value.id
            // console.log(postData.frwd_uid_mod)
            if (!postData.frwd_uid_mod || postData.frwd_uid_mod === undefined || postData.frwd_uid_mod === '' || postData.frwd_uid_mod === 'null') {
                postData.frwd_uid = null
            } else {
                postData.frwd_uid = parseInt(postData.frwd_uid_mod.split('d')[1])
            }
            Service.post('/bb/api/tender/wbsmanager/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData
            })
                .then(res => {
                    this.fetchOps();
                    this.props.enqueueSnackbar('Data saved successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    this.fetchOps();
                    errorMessage(this.props, error)
                });
        }

    }

    putData(putData) {

        putData.uid = parseInt(putData.uid_mod.split('d')[1])
        putData.wbsid = parseInt(putData.ds_wbs_id.split('d')[1])

        if (!putData.frwd_uid || putData.frwd_uid_mod === undefined || putData.frwd_uid_mod === '' || putData.frwd_uid_mod === 'null') {
            putData.frwd_uid = null
        } else {
            putData.frwd_uid = parseInt(putData.frwd_uid_mod.split('d')[1])
        }

        Service.put('/bb/api/tender/wbsmanager/' + putData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: putData
        })
            .then(res => {
                this.props.enqueueSnackbar('Data saved successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                this.fetchOps();
               errorMessage(this.props, error)
            });
    }

    deleteData(putData) {
        // putData.uid = parseInt(putData.uid_mod.split('d')[1])
        // putData.frwd_uid = parseInt(putData.frwd_uid_mod.split('d')[1])
        Service.delete('/bb/api/tender/wbsmanager/' + putData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.props.enqueueSnackbar('Data deleted successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                this.fetchOps();
               errorMessage(this.props, error)
            });
    }

    checkWBS(rowData) {
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if (element.wbsid === parseInt(rowData.ds_wbs_id.split('d')[1])) {
                this.props.enqueueSnackbar('WBS already exist!', {
                    variant: 'warning'
                });
                return true;
            }
        }
        return false;
    }

    render() {

        return (
            <div>
                <MaterialTable
                    title={(this.props.value.tender_name ? this.props.value.tender_name : this.props.value.name)}
                    columns={[

                        // {
                        //     title: 'DDM Type', field: 'pdm_type',
                        //     lookup: { 'ohl': 'OHL', 'cabling': 'Cabling', 'sub_struc': 'Substation Primary', 'ohl_civ_struc': 'Substation Secondary', 'sub_civ_struct': 'Substation Civ Structure', 'multi_utilities': 'Multi Utilities', new discipline is needed 'edc': 'EDC' }
                        // },
                        {
                            title: 'WBS', field: 'ds_wbs_id',
                            lookup: this.state.ds_wbs_lookup

                        },
                        {
                            title: 'User', field: 'uid_mod',
                            lookup: this.state.users

                        },
                        // {
                        //     title: 'Timesheet', field: 'allocation_access',
                        //     lookup: { 0: 'Not Allowed', 1: 'Receive and Approve Timesheet' }

                        // },
                        // {
                        //     title: 'Forward to', field: 'frwd_uid_mod',
                        //     lookup: this.state.users
                        // },


                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        padding: "dense",
                        actionsColumnIndex: -1,
                        search: false,
                        // filtering: true
                    }}
                    editable={{
                        // onRowAdd: newData =>
                        //     new Promise(resolve => {
                        //         setTimeout(() => {
                        //             resolve();
                        //             if (!newData.ds_wbs_id || newData.ds_wbs_id === '' || newData.ds_wbs_id === undefined || !newData.uid_mod || newData.uid_mod === '' || newData.uid_mod === undefined) {
                        //                 this.props.enqueueSnackbar('Fill EM type and EM!', {
                        //                     variant: 'warning'
                        //                 });
                        //             } else if (!this.checkWBS(newData)) {
                        //                 this.setState({ save: false });
                        //                 this.setState(prevState => {
                        //                     const data = [...prevState.data];
                        //                     newData.roles = [];
                        //                     data.push(newData);
                        //                     this.postData(newData);
                        //                     return { ...prevState, data };
                        //                 });
                        //             }

                        //         }, 60);
                        //     }),
                        // onRowUpdate: (newData, oldData) =>
                        //     new Promise(resolve => {
                        //         setTimeout(() => {
                        //             resolve();
                        //             if (!this.checkWBS(newData) && oldData) {
                        //                 this.setState({ save: false });
                        //                 this.setState(prevState => {
                        //                     const data = [...prevState.data];
                        //                     data[data.indexOf(oldData)] = newData;
                        //                     this.putData(newData);
                        //                     return { ...prevState, data };
                        //                 });
                        //             }
                        //         }, 60);

                        //     }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState({ save: false });
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        this.deleteData(oldData)
                                        return { ...prevState, data };
                                    });
                                }, 60);
                            }),
                    }}
                />

            </div>
        )
    }
}

export default withSnackbar(WBSManager);