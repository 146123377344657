import { Dialog, Typography, Button } from '@material-ui/core';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import Service from "../Networking/networkutils";
import { errorMessage } from '../Helper/ErrorMessage';
import moment from "moment";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { CardActionArea, CircularProgress } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FlagIcon from '@material-ui/icons/Flag';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DownlodIcon from '@material-ui/icons/CloudDownload';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
// import TaskSearch from './TaskSearch';
import Alert from '@material-ui/lab/Alert';
import { getDarkMode } from '../Helper/CommonThings';
import ViewListIcon from '@material-ui/icons/ViewList';
import UserTaskStatusView from './UserTaskStatusView';
import UserProductivityReport from './UserProductivityReport';
import OutOfWork from './OutOfWork/OutOfWork';
import { Container } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

class UserTasks extends Component {

    s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    constructor(props) {
        super()

        this.state = {
            isLoading: true,
            data: [],
            projectLookUp: {},
            emLookUp: {},
            open: false,
            dialogData: null,
            miscellanious: [],
            ready_to_start: [],
            soon_due: [],
            later_due: [],
            start_soon: [],
            overdue: [],
            completed: [],
            selectedType: '',
            taskStatus: {
                all: 'All Tasks',
                ready_to_start: 'Ready To Start',
                miscellanious: 'No Dates Info',
                start_soon: 'Start Soon',
                soon_due: 'Soon Due',
                later_due: 'Later Due',
                overdue: 'Overdue',
                completed: 'Completed',
            },
            workSearchDialog: false,
            outOfWorkTasks: {},
            taskFind: false,
            hoursLoading: false,
            all: [],
        }
    }

    componentDidMount() {
        // console.log('user task mount')
        // this.fetchOps();
        // console.log(this.props.multiUser)
        this.getAlldata();
        // this.productivity();
        // console.log(getDarkMode(), 'dark mode')
    }

    getAlldata() {
        Service.get(`/bb/api/user/report/view/your_workload/?uid=` + this.props.uid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                let { ds, project, task, user_task, wbs } = res.data;
                // let taskIds = [];
                for (let i = 0; i < task.length; i++) {
                    if (!task[i].act_exp_start || !task[i].act_exp_finish) {
                        task[i].act_exp_start = task[i].from_date
                        task[i].act_exp_finish = task[i].to_date
                    }
                    for (let j = 0; j < user_task.length; j++) {
                        if (task[i].id === user_task[j].task_id) {
                            task[i].user = user_task[j];
                            // taskIds.push({ 'com_ttid': task[i].id, 'com_uid': user_task[j].user_id })
                        }
                    }
                    for (let j = 0; j < wbs.length; j++) {
                        if (task[i].t_ohlwbs === wbs[j].id) {
                            task[i].wbs = wbs[j];
                            for (let k = 0; k < ds.length; k++) {
                                if (wbs[j].pid === ds[k].id) {
                                    task[i].ds = ds[k];
                                    for (let l = 0; l < project.length; l++) {
                                        if (ds[k].subproject_pid === project[l].id) {
                                            task[i].project = project[l]
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // console.log(task, 'task w/o hours')

                this.setState({
                    data: task,
                }, () => { this.statusReport(this.state.data) })


            })
            .catch(e => {
                errorMessage(this.props, e)
                console.log(e)
            })
    }

    // fetchOps() {
    //     Service.get(`/bb/api/tender/user/task/?uid=` + user.id, {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res => {
    //             this.setState({ data: res.data }, () => {
    //                 var viewPid = []
    //                 var pid = "0"
    //                 for (let i1 = 0; i1 < res.data.length; i1++) {
    //                     const element = res.data[i1];
    //                     if (!element.tasks_roles_data.t_tasks_data.act_exp_start) {
    //                         element.tasks_roles_data.t_tasks_data.act_exp_start = element.tasks_roles_data.t_tasks_data.from_date
    //                     }
    //                     if (!element.tasks_roles_data.t_tasks_data.act_exp_finish) {
    //                         element.tasks_roles_data.t_tasks_data.act_exp_finish = element.tasks_roles_data.t_tasks_data.to_date
    //                     }
    //                     pid = pid + "," + element.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid
    //                     viewPid.push({ 'com_ttid': element.task_id, 'com_uid': user.id })
    //                 }
    //                 if (viewPid.length !== 0) {
    //                     Service.patch("/bb/api/team/tasks/get_acthours/", {
    //                         headers: {
    //                             Authorization: "Token " + token,
    //                         },
    //                         data: viewPid,
    //                     })
    //                         .then(res12 => {

    //                             for (let j = 0; j < res12.data.length; j++) {
    //                                 const element13 = res12.data[j];
    //                                 for (let i = 0; i < res.data.length; i++) {
    //                                     const element12 = res.data[i];
    //                                     if (element12.task_id === element13.id) {
    //                                         element12.actual_hours = timeToDecimal(element13.hours_actual)
    //                                         element12.inprogress_hours = timeToDecimal(element13.hours_ongoing)
    //                                         element12.total_alloc_hours = element13.allocated
    //                                         element12.actual_and_inprogress = (element12.actual_hours + element12.inprogress_hours) + "/" + element12.total_alloc_hours
    //                                         element12.tender_name_str = this.state.projectLookUp[element12.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]

    //                                     }
    //                                     // console.log(timeToDecimal(element13.hours_actual) !== 0)
    //                                 }

    //                             }
    //                             // console.log(dataD)

    //                             console.log(res.data, 'Uesr task data')
    //                             this.setState({
    //                                 data: res.data,
    //                             }, () => { this.statusReport(this.state.data) })
    //                         })
    //                         .catch(error => {
    //                             this.props.enqueueSnackbar('Hours utilized data unavailable.', {
    //                                 variant: 'error'
    //                             });

    //                         });
    //                     Service.get(`/bb/api/tender/project/?id=` + pid, {
    //                         headers: {
    //                             Authorization: "Token " + token,
    //                         },
    //                     })
    //                         .then(res31 => {

    //                             var lookupcreation = {}
    //                             let emCreation = {}
    //                             for (let i = 0; i < res31.data.length; i++) {
    //                                 const element = res31.data[i];
    //                                 console.log(element)
    //                                 if (element.tenderbid_data) emCreation[element.id] = element.tenderbid_data.pdm_data;
    //                                 if (element.tender_name) {
    //                                     lookupcreation[element.id] = element.tender_name
    //                                 } else {
    //                                     lookupcreation[element.id] = element.name
    //                                 }
    //                                 for (let i = 0; i < res.data.length; i++) {
    //                                     const element12 = res.data[i];
    //                                     if (element12.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid === element.id) {
    //                                         if (element.tender_name) {
    //                                             element12.tender_name_str = element.tender_name
    //                                         } else {
    //                                             element12.tender_name_str = element.name
    //                                         }

    //                                         break;
    //                                     }
    //                                     // console.log(timeToDecimal(element13.hours_actual) !== 0)
    //                                 }
    //                             }

    //                             this.setState({
    //                                 projectLookUp: lookupcreation,
    //                                 emLookUp: emCreation,
    //                                 isLoading: false,
    //                             })


    //                         })
    //                         .catch(error => {
    //                             console.log(error)
    //                             errorMessage(this.props, error)
    //                         });
    //                 }

    //             })

    //         })
    //         .catch(error => {
    //             errorMessage(this.props, error)
    //         });
    // }

    statusReport(data) {

        // let teamLeadOrEM = this.props.teamLeadData.length > 0;
        // console.log(this.props.teamLeadData, teamLeadOrEM)

        let ready_to_start = [];
        let miscellanious = [];
        let start_soon = [];

        let soon_due = [];
        let overdue = []
        let completed = [];
        let later_due = [];
        // console.log(data)

        // for (let i = 0; i < data.length; i++) {
        //     let task = data[i]
        //     // if (this.state.emLookUp[task.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]) {
        //     if (user.id === task.project.tenderbid_data.pdm_data.id) {
        //         teamLeadOrEM = true
        //     }
        //     // }
        //     if ((!task.tasks_roles_data.t_tasks_data.act_exp_start || !task.tasks_roles_data.t_tasks_data.act_exp_finish)
        //         && task.tasks_roles_data.t_tasks_data.comp_percnt !== (teamLeadOrEM ? 100 : 60)) {
        //         miscellanious.push(task)
        //     }
        //     if (moment(task.tasks_roles_data.t_tasks_data.act_exp_start).isBefore(moment()) && task.tasks_roles_data.t_tasks_data.comp_percnt === 0) {
        //         ready_to_start.push(task)
        //     }
        //     if (moment(task.tasks_roles_data.t_tasks_data.act_exp_start).isBefore(moment())
        //         && moment(task.tasks_roles_data.t_tasks_data.act_exp_finish).isAfter(moment())
        //         && task.tasks_roles_data.t_tasks_data.comp_percnt > 0
        //         && task.tasks_roles_data.t_tasks_data.comp_percnt < (teamLeadOrEM ? 100 : 60)) {
        //         soon_due.push(task)
        //     }
        //     if (moment(task.tasks_roles_data.t_tasks_data.act_exp_start).isBefore(moment())
        //         && moment(task.tasks_roles_data.t_tasks_data.act_exp_finish).isBefore(moment())
        //         && task.tasks_roles_data.t_tasks_data.comp_percnt > 0
        //         && task.tasks_roles_data.t_tasks_data.comp_percnt < (teamLeadOrEM ? 100 : 60)) {
        //         overdue.push(task)
        //     }
        //     if (moment(task.tasks_roles_data.t_tasks_data.act_exp_start).isAfter(moment())
        //         && moment(task.tasks_roles_data.t_tasks_data.act_exp_finish).isAfter(moment())
        //         && task.tasks_roles_data.t_tasks_data.comp_percnt === 0) {
        //         start_soon.push(task)
        //     }
        //     if (task.tasks_roles_data.t_tasks_data.comp_percnt === (teamLeadOrEM ? 100 : 60)) {
        //         completed.push(task)
        //     }
        // }
        // console.log(teamLeadOrEM, ' team lead or em')
        for (let i = 0; i < data.length; i++) {
            let task = data[i]
            // var taskInclude = false
            // if (task.project.tenderbid_data && task.project.tenderbid_data.pdm_data) {
            //     if (this.props.uid === task.project.tenderbid_data.pdm_data.id) {
            //         teamLeadOrEM = true
            //     }
            // }
            if ((!task.act_exp_start || !task.act_exp_finish)
                && task.comp_percnt !== 100) {
                task.status = 'No Dates Info';
                miscellanious.push(task)
            } else if (moment(task.act_exp_start).isBefore(moment())
                && moment(task.act_exp_finish).isAfter(moment())
                && task.comp_percnt === 0) {
                task.status = 'Ready To Start';
                ready_to_start.push(task)
            } else if (moment(task.act_exp_finish).isAfter(moment().subtract(91, 'days')) && moment(task.act_exp_finish).isBefore(moment())
                && 0 < task.comp_percnt && task.comp_percnt < 100) {
                task.status = 'Soon Due';
                soon_due.push(task)
            } else if (moment(task.act_exp_finish).isBefore(moment())
                && task.comp_percnt >= 0
                && task.comp_percnt < 100) {
                task.status = 'Overdue';
                overdue.push(task)
            } else if (moment(task.act_exp_start).isAfter(moment())
                && moment(task.act_exp_finish).isAfter(moment())
                && task.comp_percnt === 0) {
                task.status = 'Start Soon';
                start_soon.push(task)
            } else if (task.comp_percnt >= 100) {
                task.status = 'Completed';
                completed.push(task)
            } else if (moment(task.act_exp_finish).isAfter(moment())) {
                task.status = 'Later Due';
                later_due.push(task)
            }
        }
        this.setState({ miscellanious, ready_to_start, soon_due, overdue, later_due, start_soon, completed, all: data, isLoading: false }, () => {
            // console.log(this.state)
        })
    }

    cardClick(status) {
        this.setState({ open: true, selectedType: status }, () => this.setState({ isLoading: false }))
    }

    downloadExcel() {
        if (this.state.hoursLoading) return;

        let data = this.state.data;


        // console.log(data)

        let viewPid = []
        try {

            for (let i = 0; i < data.length; i++) {
                viewPid.push({ 'com_ttid': data[i].id, 'com_uid': this.props.uid })
            }
            // console.log(viewPid)
        }
        catch (e) { console.log(e) }

        if (viewPid.length !== 0) {
            this.setState({ hoursLoading: true })
            Service.patch("/bb/api/team/tasks/get_acthours/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: viewPid,
            })
                .then(res12 => {

                    for (let j = 0; j < res12.data.length; j++) {
                        const element13 = res12.data[j];
                        for (let i = 0; i < data.length; i++) {
                            const element12 = data[i];
                            if (element12.id === element13.id) {
                                element12.actual_hours = timeToDecimal(element13.hours_actual)
                                element12.inprogress_hours = timeToDecimal(element13.hours_ongoing)
                                element12.total_alloc_hours = element13.allocated
                                element12.actual_and_inprogress = (element12.actual_hours + element12.inprogress_hours)
                                element12.total_hours = element12.total_alloc_hours
                                // element12.tender_name_str = this.state.projectLookUp[element12.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]

                            }
                            // console.log(timeToDecimal(element13.hours_actual) !== 0)
                        }

                    }
                    var sheets = ["User Task"]

                    var sheetData = {}

                    var exportData = {}
                    sheetData["User Task"] = {
                        '!ref': "A1:K" + (data.length + 1),
                        '!cols': [
                            { wch: 35 }
                        ],
                        'A1': { t: "s", v: "Project" },
                        'B1': { t: "s", v: "Deliverable Schedule" },
                        'C1': { t: "s", v: "WBS" },
                        'D1': { t: "s", v: "Task Id" },
                        'E1': { t: "s", v: "Tasks" },
                        'F1': { t: "s", v: "Required Start Date" },
                        'G1': { t: "s", v: "Required End Date" },
                        'H1': { t: "s", v: "Status" },
                        'I1': { t: "s", v: "% Complete" },
                        'J1': { t: "s", v: "Progress Hours" },
                        'K1': { t: "s", v: "Total Hours" },
                    }

                    // console.log(data)
                    let sheet = sheetData['User Task']
                    for (let i = 0; i < data.length; i++) {
                        const element = data[i];
                        sheet["A" + (i + 2)] = { t: "s", v: element.project.tender_name }
                        sheet["B" + (i + 2)] = { t: "s", v: element.ds.name }
                        sheet["C" + (i + 2)] = { t: "s", v: element.wbs.name }
                        sheet["D" + (i + 2)] = { t: "s", v: element.id }
                        sheet["E" + (i + 2)] = { t: "s", v: element.name }
                        sheet["F" + (i + 2)] = { t: "s", v: element.act_exp_start ? moment(element.act_exp_start).format('DD/MM/YYYY') : '' }
                        sheet["G" + (i + 2)] = { t: "s", v: element.act_exp_finish ? moment(element.act_exp_finish).format('DD/MM/YYYY') : '' }
                        sheet["H" + (i + 2)] = { t: "s", v: element.status }
                        sheet["I" + (i + 2)] = { t: "s", v: element.comp_percnt }
                        sheet["J" + (i + 2)] = { t: "n", v: element.actual_and_inprogress }
                        sheet["K" + (i + 2)] = { t: "n", v: element.total_hours }
                    }

                    exportData = {
                        'SheetNames': sheets,
                        'Sheets': sheetData
                    }

                    // console.log(exportData)

                    var blob = new Blob([this.s2ab(XLSX.write(exportData, { bookType: 'xlsx', type: 'binary' }))], { type: "application/vnd.ms-excel" });
                    saveAs(blob, "User Task " + moment().format() + ".xlsx");
                    this.setState({ hoursLoading: false })
                })
                .catch(error => {
                    this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                        variant: 'error'
                    });

                });
        }

    }

    searchClick() {
        this.setState({ workSearchDialog: true })
        // console.log(user)
        // if (this.state.taskFind === true) return;

        // this.setState({ taskFind: true })
        // Service.get("/bb/api/user/report/view/out_of_work/?uid=" + this.props.uid,
        //     {
        //         headers: {
        //             Authorization: "Token " + token,
        //         },
        //     }).then(res => {
        //         // console.log(res.data, 'res out of work')
        //         this.setState({ outOfWorkTasks: res.data, taskFind: false }, () => this.setState({ workSearchDialog: true }))
        //     }).catch(e => {
        //         this.props.enqueueSnackbar('Unable to fetch tasks. Please contact Admin', {
        //             variant: 'error'
        //         });
        //         this.setState({ taskFind: false })
        //     })
    }

    // accordionChange = (e, v) => {
    //     console.log(v)
    //     if (v === true && this.state.userWeekData.length === 0) {
    //         this.setState({ graphLoad: true }, () => this.productivity())
    //     }
    // }

    render() {
        const handleClose = () => {
            this.setState({ open: false, })
        };
        const workSearchDialogClose = () => {
            this.setState({ workSearchDialog: false, })
        };
        const darkMode = getDarkMode() === 'dark'
        return (
            <Container disableGutters maxWidth={false} >
                {
                    // this.state.isLoading ? <CircleLoading color={getPrimaryColor()} /> :
                    <div>
                        <div style={{ marginTop: '5px' }}> <Alert severity="info">
                            Note : Below status is based on the tasks allocated to you.
                        </Alert></div>
                        <div style={{ textAlign: 'right' }}>
                            {(!this.state.isLoading && !this.props.multiUser) ? <Button style={{ marginTop: '10px', marginRight: '10px' }}
                                endIcon={<DownlodIcon />}
                                onClick={() => this.downloadExcel()}
                            >
                                {this.state.hoursLoading ? 'Please Wait...' : 'Download Report'}
                            </Button>
                                : null}
                        </div>
                        {/* <Grid container spacing={2} style={{ margin: '15px 0', width: '100%', marginTop: '20px' }}>
                            <Grid item xs={2}> */}
                        <div style={{ display: 'flex' }}>
                            <Card style={{ background: darkMode ? '#2196F3' : '#64B5F6', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('all')}>
                                    <CardHeader
                                        avatar={
                                            <ViewListIcon />
                                        }
                                        // title={this.state.miscellanious.length}
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.all.length}
                                        subheader="All Tasks"
                                    />
                                </CardActionArea>
                            </Card>

                            <Card style={{ background: darkMode ? '#ccb800' : '#FFF176', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('start_soon')} >
                                    <CardHeader
                                        avatar={
                                            <AccessTimeIcon />
                                        }
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.start_soon.length}
                                        subheader="Start Soon"
                                    />
                                </CardActionArea>
                                {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}

                            </Card>
                            {/* </Grid>
                            <Grid item xs={2}> */}
                            <Card style={{ background: darkMode ? '#9fad1f' : '#DCE775', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('ready_to_start')}>
                                    <CardHeader
                                        avatar={
                                            <FlagIcon />
                                        }
                                        // title={this.state.ready_to_start.length}
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.ready_to_start.length}
                                        subheader="Ready To Start "
                                    />
                                </CardActionArea>
                            </Card>
                            {/* </Grid>
                            <Grid item xs={2}> */}
                            <Card style={{ background: darkMode ? '#da8bc5' : '#dc92d9', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('later_due')}>
                                    <CardHeader
                                        avatar={
                                            <ErrorOutlineIcon />
                                        }
                                        // title={this.state.soon_due.length}
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.later_due.length}
                                        subheader="Later Due"
                                    />
                                </CardActionArea>
                            </Card>
                            <Card style={{ background: darkMode ? '#e68a00' : '#FFB74D', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('soon_due')}>
                                    <CardHeader
                                        avatar={
                                            <ErrorOutlineIcon />
                                        }
                                        // title={this.state.soon_due.length}
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.soon_due.length}
                                        subheader="Soon Due"
                                    />
                                </CardActionArea>
                            </Card>
                            {/* </Grid>
                            <Grid item xs={2}> */}
                            <Card style={{ background: darkMode ? '#c71f1f' : '#EF9A9A', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('overdue')}>
                                    <CardHeader
                                        avatar={
                                            // <Avatar sx={{ bgcolor: '#F44336' }} aria-label="recipe">
                                            //     O
                                            // </Avatar>
                                            <NotificationsIcon />
                                        }
                                        // title={this.state.overdue.length}
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.overdue.length}
                                        subheader="Overdue"
                                    />
                                </CardActionArea>
                            </Card>
                            {/* </Grid>
                            <Grid item xs={2}> */}
                            <Card style={{ background: darkMode ? '#5c862d' : '#AED581', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('completed')}>
                                    <CardHeader
                                        avatar={
                                            <CheckCircleOutlineIcon />
                                        }
                                        // title={this.state.completed.length}
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.completed.length}
                                        subheader="Completed"
                                    />
                                </CardActionArea>
                            </Card>
                            {/* </Grid>
                            <Grid item xs={2}> */}
                            <Card style={{ background: darkMode ? '#347f77' : '#4DB6AC', width: 'calc(100% / 7)', margin: '10px' }}>
                                <CardActionArea onClick={() => this.cardClick('miscellanious')}>
                                    <CardHeader
                                        avatar={
                                            <HourglassEmptyIcon />
                                        }
                                        // title={this.state.miscellanious.length}
                                        title={this.state.isLoading ?
                                            <CircularProgress style={{ height: '15px', width: '15px' }} color='#444' /> : this.state.miscellanious.length}
                                        subheader="No Dates Info"
                                    />
                                </CardActionArea>
                            </Card>
                        </div>
                        {/* </Grid>
                        </Grid> */}
                        <UserProductivityReport uid={this.props.uid} />
                        {!this.state.multiUser ?
                            <div style={{ textAlign: 'center', margin: '50px 0' }}>
                                <Button
                                    color='primary'
                                    variant='outlined'
                                    onClick={() => this.searchClick()}
                                >
                                    {this.state.taskFind === true ? 'Please wait...' : 'Project/Task Search'}
                                </Button>
                            </div>
                            : null}

                        {/* <Accordion onChange={this.accordionChange} style={{ background: '#BDBDBD' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Your Productivity - Click to view your last 3 months productivity report</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {this.state.graphLoad ? <CircleLoading color={getPrimaryColor()} style={{ position: 'relative', margin: 'auto' }} /> :


                                    <UserWorkloadGraph
                                        data={this.state.userWeekData}
                                        projectTotal={this.state.projectTotal}
                                        overheadTotal={this.state.overheadTotal}
                                    />

                                }
                            </AccordionDetails>
                        </Accordion> */}
                    </div>
                }

                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar style={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Your Workload - {this.state.taskStatus[this.state.selectedType]}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {/* <UserTasksTable
                        data={this.state.selectedType === 'all' ? this.state.all
                            : this.state[this.state.selectedType]}
                        projectLookUp={this.state.projectLookUp}
                        status={this.state.taskStatus[this.state.selectedType]}
                        uid={this.props.uid}
                        viewAll={this.state.selectedType === 'all' ? true : false}
                    /> */}
                    <UserTaskStatusView
                        data={this.state[this.state.selectedType]}
                        projectLookUp={this.state.projectLookUp}
                        status={this.state.taskStatus[this.state.selectedType]}
                        uid={this.props.uid}
                        viewAll={this.state.selectedType === 'all' ? true : false}
                        multiUser={this.props.multiUser}
                    />
                </Dialog>
                <Dialog
                    fullScreen
                    open={this.state.workSearchDialog}
                    onClose={workSearchDialogClose}
                    TransitionComponent={Transition}
                >
                    <AppBar position='relative'>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={workSearchDialogClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                Work Search
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <OutOfWork uid={this.props.uid} />
                    {/* <TaskSearch data={this.state.outOfWorkTasks} /> */}
                </Dialog>
            </Container>
        )
    }
}

function addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec ? 1 : null
    if (len) {
        return parseFloat(Number(num).toFixed(len))
    } else {
        return parseFloat(Number(num))
    }
}

function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
}

export default withSnackbar(UserTasks)