import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { getDarkMode, getPrimaryColor } from '../Helper/CommonThings';

const balfour = createTheme({
    palette: {
        secondary: {
            main: getPrimaryColor(),
        },
        type: getDarkMode(),
    },
});

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appbarimg: {
        height: 56,
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            height: 48,
        },
        [theme.breakpoints.up('sm')]: {
            height: 64,
        },
    },
}));

function BalfourBeattyHeader(props) {
    const classes = useStyles();

    const backHandle = () => {
        window.location.href = props.url
    };

    return (
        <div className={classes.root}>
            <ThemeProvider theme={balfour}>
                <AppBar position="static">
                    <Toolbar >
                        <IconButton onClick={backHandle}><ArrowBackIcon /></IconButton>
                        <img src={"https://media-s3-us-east-1.ceros.com/balfour-beatty/images/2018/02/01/8d9dffee5460c61b16ecfe6dadfffa31/2017-balfour-beatty-logo-white.png?imageOpt=1&fit=bounds&width=210"} alt="Balfour Beatty" className={classes.appbarimg} />
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
        </div >
    );
}

export default withRouter(BalfourBeattyHeader) 