import React, { Component } from 'react'
import { Avatar, Box, Card, Tooltip, IconButton, Button, } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { screenSizeCheck } from '../../../ScreensizeCheck/ScreensizeCheck';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getBlackAndWhite, getBrowncolor, getGreencolor, getGreyColor, getLaterDuecolor, getOrangecolor, getredcolor, getSkyBluecolor } from '../../../ColorTheme/ColorTheme';
import StackedProgressbar from '../../../Charts/StackedProgressbar';
import moment from "moment";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { getDarkMode, getPrimaryColor } from '../../../../Helper/CommonThings';
import UserHoursRequest from '../../../../HoursRequest/UserHoursRequest';
import { getCookie } from '../../../../Helper/CookieHelper';
import Link from '@mui/material/Link';
import { withSnackbar } from 'notistack';
import Service from '../../../../Networking/networkutils'
import { errorMessage } from '../../../../Helper/ErrorMessage';
import WorkIcon from '@mui/icons-material/Work';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { Container } from 'react-grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import WebIcon from '@material-ui/icons/Web';
import Slide from '@material-ui/core/Slide';
import MainTenderSubTasks from '../../../../TenderSubTasks/MainTenderSubTasks';
import SubTenderProgressBar from '../../../../TenderSubTasks/SubTenderProgressBar/SunTenderProgressBar';
import { CsvBuilder } from 'filefy';
import DateChangeRequest from '../../../../DateChangeRequest/DateChangeRequest';

const token = getCookie("bb_ts_token");

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    if (props.slideCount <= props.data + props.currentSlide) {
        return <div></div>
    } else {
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: '50px', background: "grey" }}
                onClick={onClick}
            />
        );
    }
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    if (props.currentSlide <= 0) {
        return <div></div>
    } else {
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: '50px', background: "grey" }}
                onClick={onClick}
            />
        );
    }
}

function PrevButton(props) {
    const { className, style, onClick } = props;
    if (props.currentSlide <= 0) {
        return <div></div>
    } else {
        return (
            <div
                style={{ ...style, borderRadius: '50px', background: "grey" }}
                onClick={onClick}>
                <ArrowBackIosIcon className={className} style={{ color: getDarkMode() === 'dark' ? '#fff' : '#000' }} />
            </div>
        );
    }
}

function NextButton(props) {
    const { className, style, onClick } = props;
    if (props.slideCount <= props.data + props.currentSlide) {
        return <div></div>
    } else {
        return (
            <div
                style={{ ...style, borderRadius: '50px', background: "grey" }}
                onClick={onClick}>
                <ArrowForwardIosIcon className={className} style={{ color: getDarkMode() === 'dark' ? '#fff' : '#000' }} />
            </div>
        );
    }
}

var cardMaxHeight = 0
var boxHeight = 0

const user = JSON.parse(getCookie("bb_ts_user"));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class TaskCards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cardHeight: null,
            openRequest: false,
            dialogData: null,
            openTaskUpdate: false,
            perTaskLoading: false,
            taskPercent: 0,
            perTaskData: null,
            data: [],
            allData: null,
            projectLook: null,
            allRoles: null,
            isLoading: false,
            boxHeight: null,
            openDateDialog: false,
            pendingReqData: null,
            openPending: false,
            openSubTender: false,
            subTenderData: null,
            dialogLoader: false,
            openTaskDownload: false,
        }
    }

    componentDidMount() {
        this.taskSearch(this.props.presentSearch)
        this.fetchNounsAndAdjectives()
        setTimeout(() => {
            this.setState({
                cardHeight: cardMaxHeight,
                boxHeight: boxHeight,
            }, () => { this.props.boxHeight(this.state.boxHeight) })
        }, 200)
    }

    fetchNounsAndAdjectives() {
        Promise.all([
            this.getNouns(),
            this.getAdjectives()
        ])
            .then(res => {
                this.setState({ dateChangeNouns: res[0], dateChangeAdjectives: res[1] })
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })
    }

    getNouns() {
        return new Promise((resolve, reject) => {
            Service.get("/bb/api/task/date/change/nouns/", {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    getAdjectives() {
        return new Promise((resolve, reject) => {
            Service.get("/bb/api/task/date/change/adjective/", {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    getHeight(ref) {
        if (ref && cardMaxHeight < ref.clientHeight) {
            cardMaxHeight = ref.clientHeight
        }
    }

    boxHeight(ref) {
        if (ref && boxHeight < ref.clientHeight) {
            boxHeight = ref.clientHeight
            // return boxHeight
        }
    }

    UserHoursRequest(data) {
        this.setState({ openRequest: true, dialogData: data })
    }

    fetchPerTaskData(rowData, type) {
        Service.get('/bb/api/tender/wbs/tasks/' + rowData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (type === 'handleTaskPercent') {
                    this.setState({
                        perTaskLoading: false,
                        perTaskData: res.data,
                        taskPercent: res.data.comp_percnt,
                    })
                } else if (type === 'handleDate') {
                    this.setState({
                        openDateDialog: true,
                        pendingReqData: null,
                        perTaskData: res.data,
                        dialogData: rowData,
                    })
                }

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    handleTaskComp() {
        this.setState({
            openTaskUpdate: false,
        })
        var data = this.state.perTaskData;

        var historydata = null

        if (data.comp_percnt !== this.state.taskPercent) {
            Service.post('/bb/api/tender/tasks/history/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    "t_pid": data.t_pid,
                    "t_wbs": data.t_ohlwbs,
                    "t_tasks": data.id,
                    "prev_task_status": data.comp_percnt,
                    "task_status": this.state.taskPercent,
                    "created_by": user.id
                },
            })
                .then(res => {
                    historydata = res.data
                    data.comp_percnt = this.state.taskPercent;
                    Service.put('/bb/api/tender/wbs/tasks/' + this.state.perTaskData.id + '/', {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: data,
                    })
                        .then(res1 => {
                            var tempData = this.state.data
                            for (let i = 0; i < tempData.length; i++) {
                                const element = tempData[i];
                                if (element.id === this.state.perTaskData.id) {
                                    element.complete_percent = this.state.taskPercent;
                                    break
                                }
                            }
                            this.setState({
                                perTaskLoading: false,
                                taskPercent: 0,
                                openTaskUpdate: false,
                                data: tempData
                            })
                            // this.props.refreshData();
                            this.props.enqueueSnackbar('Task status updated!', {
                                variant: 'success'
                            });
                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                            if (historydata) {
                                Service.delete('/bb/api/tender/tasks/history/' + historydata.id + '/', {
                                    headers: {
                                        Authorization: "Token " + token,
                                    },

                                })
                                    .catch(error => {
                                        errorMessage(this.props, error)
                                    });
                            }
                        });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    handleTaskPercent(value) {
        this.fetchPerTaskData(value, "handleTaskPercent");
        this.setState({ openTaskUpdate: true, taskPercent: value.complete_percent, perTaskData: null, })
    }

    handleClick(value) {
        this.props.taskAllocation(value)
        this.setState({ isLoading: true }, () => {
            if (this.props.fullData.teams.length > 1) {
                setTimeout(() => this.setState({ isLoading: false }), 5000)
            } else {
                setTimeout(() => this.setState({ isLoading: false }), 7500)
            }

        })
    }

    taskSearch(data) {
        if (this.props.data.length > 0) {
            const data_lowercase = data.toLowerCase()
            var search_words = data_lowercase.split(" ");
            search_words = search_words.filter(n => n)
            var filtered_data = []
            if (search_words.length === 0) {
                filtered_data = this.props.data
            }

            for (let i = 0; i < this.props.data.length; i++) {
                const element = this.props.data[i];
                var num_of_passed = false
                for (let j = 0; j < search_words.length; j++) {
                    const el = search_words[j];
                    num_of_passed = element.search_key.includes(el)
                    if (!num_of_passed) {
                        break
                    }
                }
                if (num_of_passed) {
                    filtered_data.push(element)
                }
            }

            this.setState({ data: filtered_data })
            return filtered_data
        }
    }

    handleDate(value) {
        var perTaskData = {
            id: value.id,
            decs: value.decs,
            name: value.name,
        }
        this.setState({ pendingReqData: null, perTaskData: perTaskData }, () => { this.setState({ openDateDialog: true }) })
    }

    downloadSubtask() {
        var data = this.state.data
        var task_ids = []
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element) {
                task_ids.push(element.id)
            }
        }
        if (task_ids.length !== 0) {
            this.downloadData(task_ids)
        } else {
            this.props.enqueueSnackbar('No Tasks to download!', {
                variant: 'warning'
            });
        }
    }

    downloadData(ids) {
        Service.get(`/bb/api/tender/subtask/download/?tid=${ids.toString()}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.exportSubtaskCsv(res.data)
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    exportSubtaskCsv(initialData) {
        this.setState({ dialogLoader: true })
        const columns = ['Project Name', 'DS Name', 'WBS Name', 'Task ID', 'Task Name', 'Baseline Start', 'Baseline Finish', '% Complete', 'Actual Start', 'Actual Finish', 'Planner Task Name', 'Progress', 'Priority', 'Planner Task Start', 'Planner Task Finish', 'Planner Description', 'Members Assigned', 'Subtask Name', 'Subtask Progress', 'Subtask Priority', 'Subtask Start', 'Subtask Finish', 'Subtask Description', 'Subtask Members Assigned']

        const data = initialData.map(rowData =>
            columns.map(columnDef => {
                if (columnDef === 'Project Name') {
                    return rowData.maintask.tender_name
                } else if (columnDef === 'DS Name') {
                    return rowData.maintask.ds_name
                } else if (columnDef === 'WBS Name') {
                    return rowData.maintask.wbs_name
                } else if (columnDef === 'Task ID') {
                    return rowData.maintask.ohlwbs_tasks
                } else if (columnDef === 'Task Name') {
                    return rowData.maintask.task_name
                } else if (columnDef === 'Baseline Start') {
                    return moment(rowData.maintask.baseline_start).format('DD-MM-YYYY')
                } else if (columnDef === 'Baseline Finish') {
                    return moment(rowData.maintask.baseline_finish).format('DD-MM-YYYY')
                } else if (columnDef === '% Complete') {
                    return rowData.maintask.percentage_comp
                } else if (columnDef === 'Actual Start') {
                    return moment(rowData.maintask.act_start).format('DD-MM-YYYY')
                } else if (columnDef === 'Actual Finish') {
                    return moment(rowData.maintask.act_finish).format('DD-MM-YYYY')
                } else if (columnDef === 'Planner Task Name') {
                    return rowData.maintask.title
                } else if (columnDef === 'Progress') {
                    return rowData.maintask.progress
                } else if (columnDef === 'Priority') {
                    return rowData.maintask.priority
                } else if (columnDef === 'Planner Task Start') {
                    return rowData.maintask.planned_start
                } else if (columnDef === 'Planner Task Finish') {
                    return rowData.maintask.planned_end
                } else if (columnDef === 'Planner Description') {
                    return rowData.maintask.description
                } else if (columnDef === 'Members Assigned') {
                    let users = []
                    for (let i = 0; i < rowData.maintask.users.length; i++) {
                        const element = rowData.maintask.users[i];
                        if (element) {
                            users.push(element.last_name + ", " + element.first_name)
                        }
                    }
                    return users.toString()
                } else if (columnDef === 'Subtask Name') {
                    return rowData.subtask.title ? rowData.subtask.title : null
                } else if (columnDef === 'Subtask Progress') {
                    return rowData.subtask.progress ? rowData.subtask.progress : null
                } else if (columnDef === 'Subtask Priority') {
                    return rowData.subtask.priority ? rowData.subtask.priority : null
                } else if (columnDef === 'Subtask Start') {
                    return rowData.subtask.planned_start ? rowData.subtask.planned_start : null
                } else if (columnDef === 'Subtask Finish') {
                    return rowData.subtask.planned_end ? rowData.subtask.planned_end : null
                } else if (columnDef === 'Subtask Description') {
                    return rowData.subtask.description ? rowData.subtask.description : null
                } else if (columnDef === 'Subtask Members Assigned') {
                    if (rowData.subtask.users) {
                        let users = []
                        for (let i = 0; i < rowData.subtask.users.length; i++) {
                            const element = rowData.subtask.users[i];
                            if (element) {
                                users.push(element.last_name + ", " + element.first_name)
                            }
                        }
                        return users.toString()
                    }
                }
                return null
            })
        );

        const builder = new CsvBuilder("Subtask-" + moment().format('DD-MM-YYYY hh:mm') + '.csv');
        builder
            .setDelimeter(',')
            .setColumns(columns.map(columnDef => columnDef))
            .addRows(data)
            .exportFile();

        this.setState({ openTaskDownload: false, dialogLoader: false })
    };

    openTaskDownload() {
        this.setState({ openTaskDownload: true })
    }

    downloadTask() {
        var datas = this.state.data
        if (datas.length !== 0) {
            this.setState({ dialogLoader: true })

            const columns = ['Project Name', 'DS Name', 'WBS Name', 'Task ID', 'Task Name', 'Actual Start Date', 'Actual End Date', '% Complete', 'Members Assigned', 'Subtask Total', 'SubTask Done', 'Actual', 'Assigned', 'Remaining']

            const data = datas.map(rowData =>
                columns.map(columnDef => {
                    if (columnDef === 'Project Name') {
                        return rowData.project_name
                    } else if (columnDef === 'DS Name') {
                        return rowData.ds_name
                    } else if (columnDef === 'WBS Name') {
                        return rowData.wbs_name
                    } else if (columnDef === 'Task ID') {
                        return rowData.id
                    } else if (columnDef === 'Task Name') {
                        return rowData.name
                    } else if (columnDef === 'Actual Start Date') {
                        return moment(rowData.start_date).format('DD-MM-YYYY')
                    } else if (columnDef === 'Actual End Date') {
                        return moment(rowData.end_date).format('DD-MM-YYYY')
                    } else if (columnDef === '% Complete') {
                        return rowData.complete_percent
                    } else if (columnDef === 'Members Assigned') {
                        let users = []
                        for (let i = 0; i < rowData.remaining_users.length; i++) {
                            const element = rowData.remaining_users[i];
                            if (element) {
                                users.push(element.last_name + ", " + element.first_name)
                            }
                        }
                        return users.toString()
                    } else if (columnDef === 'Subtask Total') {
                        return rowData.subtasks_total
                    } else if (columnDef === 'SubTask Done') {
                        return rowData.subtasks_done
                    } else if (columnDef === 'Actual') {
                        return rowData.is_approved
                    } else if (columnDef === 'Assigned') {
                        return rowData.total_hours_planned
                    } else if (columnDef === 'Remaining') {
                        return rowData.total_hours_planned - rowData.is_approved - rowData.in_progress
                    }
                    return null
                })
            );

            const builder = new CsvBuilder("Task-" + moment().format('DD-MM-YYYY hh:mm') + '.csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef))
                .addRows(data)
                .exportFile();

            this.setState({ openTaskDownload: false, dialogLoader: false })
        } else {
            this.props.enqueueSnackbar('No Tasks to download!', {
                variant: 'warning'
            });
        }
    }

    openSubTender(data) {
        this.setState({ subTenderData: data }, () => { this.setState({ openSubTender: true }) })
    }

    render() {

        const settings = {
            centerMode: false,
            infinite: false,
            slidesToShow: screenSizeCheck() ? 5 : 6,
            slidesToScroll: screenSizeCheck() ? 5 : 6,
            speed: 300,
            rows: screenSizeCheck() ? 1 : 1,
            arrows: true,
            nextArrow: <SampleNextArrow data={screenSizeCheck() ? 5 : 6} />,
            prevArrow: <SamplePrevArrow data={screenSizeCheck() ? 5 : 6} />,
            adaptiveHeight: true,
            accessibility: true,
        };

        const settings1 = {
            centerMode: false,
            infinite: false,
            slidesToShow: screenSizeCheck() ? 3 : 4,
            slidesToScroll: screenSizeCheck() ? 3 : 4,
            speed: 300,
            rows: 1,
            nextArrow: <NextButton data={screenSizeCheck() ? 3 : 4} />,
            prevArrow: <PrevButton data={screenSizeCheck() ? 3 : 4} />,
        };

        const handleRequestClose = () => {
            this.setState({ openRequest: false, })
        };

        const handleTaskClose = () => {
            this.setState({ openTaskUpdate: false, })
        };
        const handleSubTenderClose = () => {
            this.setState({ openSubTender: false, subTenderData: null })
            this.props.refreshSubTender()
        };

        const handlePercChange = (e) => {
            this.setState({ taskPercent: e.target.value })
        };

        const handleDateDialogClose = () => {
            this.setState({ openDateDialog: false });
        };

        const handlePendingClose = () => {
            this.setState({ openPending: false, });
        };
        const handleTaskDownloadClose = () => {
            this.setState({ openTaskDownload: false, })
        };



        return (
            <Container fluid>
                {this.state.data.length === 0 ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 1, fontWeight: 'bold', height: boxHeight + 6 }}>No Tasks Available for your Search Keyword</Box> :
                    <Slider {...settings1} >
                        {this.state.data.map((value, i) =>
                            <Box key={i} ref={(r) => { this.boxHeight(r) }} sx={{
                                height: '100%',
                                alignItems: 'stretch',
                            }} >
                                <Tooltip title={<div>Project Name : {value.project_name}<br /> DS Name : {value.ds_name}<br /> Wbs Name : {value.wbs_name}<br />Task Id : {value.id}<br />Task Name : {value.name}<br /></div>}>
                                    <Card sx={{ borderRadius: '20px 20px 20px 20px', margin: '10px', height: '100%' }} elevation={4} >
                                        <div style={{ height: '100%' }}>
                                            <Box m={2} sx={{
                                                alignItems: 'stretch',
                                                flexDirection: 'row',
                                            }}>
                                                <Box ref={(r) => { this.getHeight(r) }} sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    height: this.state.cardHeight ? this.state.cardHeight : '100%',
                                                }}>
                                                    <Box sx={{
                                                        flexGrow: 1,
                                                        flexWrap: 'wrap'
                                                    }}>
                                                        <Box fontSize={'16px'} color={getPrimaryColor()} margin='2px' fontWeight={'bold'}>
                                                            {value.project_name}
                                                        </Box>
                                                        <Box fontSize={'14px'} color={getGreyColor()} margin='2px' >
                                                            {value.name + " - "}
                                                            {
                                                                <Link
                                                                    component="button"
                                                                    variant="body2"
                                                                    onClick={() => this.handleTaskPercent(value)}>
                                                                    {value.complete_percent + "%"}
                                                                </Link>
                                                            }
                                                            {value.inc_evp === 0 ? ' - Non Billable' : ' - Billable'}
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{
                                                        height: '100%',
                                                        flexWrap: 'wrap',
                                                    }}   >
                                                        {
                                                            this.props.is_teamtasks ?
                                                                <Box display='flex' flexDirection='column'>
                                                                    <Tooltip title="Task Allocation">
                                                                        <IconButton size='small' onClick={(() => this.handleClick(value))}>
                                                                            <WorkIcon fontSize='small' />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Box>

                                                                :
                                                                <Box display='flex' flexDirection='column'>
                                                                    <Tooltip title="Request Hours">
                                                                        <IconButton size='small' disabled={value.complete_percent >= 90 ? true : false} onClick={() => this.UserHoursRequest(value)}>
                                                                            <PostAddIcon fontSize='small' />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                </Box>
                                                        }
                                                    </Box>
                                                </Box>

                                                <Box sx={{ alignSelf: 'flex-end' }}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        borderRadius: 1,
                                                    }}>
                                                        <Box fontSize={screenSizeCheck() ? '10px' : '12px'} color={getGreencolor()} fontWeight={'bold'} marginRight={value.task_hold === 1 ? '10px' : '15px'}>
                                                            Start: {moment(value.start_date).format('YYYY-MM-DD')}
                                                        </Box>

                                                        <Box fontSize={screenSizeCheck() ? '10px' : '12px'} fontWeight={'bold'} marginRight={value.task_hold === 1 ? '10px' : '15px'}>
                                                            End:
                                                            <Link
                                                                color={getBlackAndWhite()}
                                                                component="button"
                                                                variant="caption"
                                                                onClick={() => this.handleDate(value)}>
                                                                {moment(value.end_date).format('YYYY-MM-DD')}
                                                            </Link>
                                                        </Box>
                                                        <Tooltip title={value.task_stand === 1 ? "Start soon" : value.task_stand === 2 ? "Ready to start" : value.task_stand === 3 ? "Soon due" : value.task_stand === 4 ? "Over due" : value.task_stand === 6 ? "Later due" : 'No date info'}>
                                                            <Box marginRight={value.task_hold === 1 ? '10px' : '0px'} fontSize={screenSizeCheck() ? '10px' : '12px'} onClick={() => this.props.handleChange(value.task_stand)} fontWeight={'bold'} style={{ cursor: 'pointer' }} color={value.task_stand === 0 ? '#4DB6AC' : value.task_stand === 1 ? '#FFF176' : value.task_stand === 2 ? '#DCE775' : value.task_stand === 3 ? '#FFB74D' : value.task_stand === 4 ? '#EF9A9A' : value.task_stand === 6 ? getLaterDuecolor() : 'primary'}>
                                                                {value.task_stand === 1 ? "Start soon" : value.task_stand === 2 ? "Ready to start" : value.task_stand === 3 ? "Soon due" : value.task_stand === 4 ? "Over due" : value.task_stand === 6 ? "Later due" : 'No date info'}
                                                            </Box>
                                                        </Tooltip>
                                                        {value.task_hold === 1 ?
                                                            <Tooltip title='This task is ON HOLD'>
                                                                <Box fontSize={screenSizeCheck() ? '11px' : '13px'} fontWeight={'bold'} color={getredcolor()}>
                                                                    ON HOLD
                                                                </Box>
                                                            </Tooltip>
                                                            : null}
                                                    </Box>
                                                    <Box
                                                        mt={1}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            cursor: 'pointer',
                                                        }} onClick={(() => this.openSubTender(value))}>
                                                        <Tooltip title="Planner">
                                                            <IconButton size='small' >
                                                                <WebIcon fontSize='small' />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Box sx={{
                                                            flexGrow: 1,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            borderRadius: 1,
                                                            marginRight: '5px',
                                                            cursor: 'pointer'
                                                        }} >
                                                            {/* <MultiProgress
                                                                transitionTime={1.2}
                                                                elements={[
                                                                    {
                                                                        value: (value.subtasks_done / value.subtasks_total) * 100,
                                                                        color: getGreencolor(),
                                                                    },
                                                                    {
                                                                        value: 100 - ((value.subtasks_done / value.subtasks_total) * 100),
                                                                        color: getOrangecolor(),
                                                                    },

                                                                ]}
                                                                height={10}
                                                                backgroundColor={getProgBgColor()}
                                                            /> */}
                                                            <SubTenderProgressBar subtasks_done={value.subtasks_done} subtasks_total={value.subtasks_total} />
                                                        </Box>
                                                        <Box fontSize={screenSizeCheck() ? '10px' : '12px'}> {value.subtasks_done} of {value.subtasks_total} sub-task completed</Box>

                                                    </Box>
                                                    <Box
                                                        m={2}
                                                        sx={{
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            borderRadius: 1,
                                                        }}>
                                                        <Slider {...settings}>
                                                            {value.remaining_users.map((name, i) =>
                                                                <Box
                                                                    key={i}
                                                                    sx={{
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        borderRadius: 1,
                                                                        width: '100%',
                                                                    }} >
                                                                    <Tooltip title={this.props.is_teamtasks ? name.last_name + ", " + name.first_name + '(' + name.planed_hours + ')' : name.last_name + ", " + name.first_name}>
                                                                        <Avatar sx={{ bgcolor: '#99CFBB' }} ><Box fontWeight={'bold'} fontSize={'18px'}>{name.first_name.charAt(0) + name.last_name.charAt(0)}</Box></Avatar>
                                                                    </Tooltip>
                                                                </Box>
                                                            )}
                                                        </Slider>
                                                    </Box>
                                                    <Box sx={{
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderRadius: 1,
                                                        flexDirection: 'row',
                                                        display: 'flex',
                                                        width: '100%',
                                                        height: '100%'
                                                    }} >
                                                        <StackedProgressbar actual={value.is_approved} inprogress={value.in_progress} assigned={value.total_hours_planned} />
                                                    </Box>
                                                    <Box mt={1} sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-evenly',
                                                        borderRadius: 1,
                                                    }}>
                                                        <Box fontSize={screenSizeCheck() ? '10px' : '12px'} color={getGreencolor()} fontWeight={'bold'}>
                                                            Actual: {value.is_approved}
                                                        </Box>
                                                        <Box fontSize={screenSizeCheck() ? '10px' : '12px'} color={getSkyBluecolor()} fontWeight={'bold'}>
                                                            InProgress: {value.in_progress}
                                                        </Box>
                                                        <Box fontSize={screenSizeCheck() ? '10px' : '12px'} color={getOrangecolor()} fontWeight={'bold'}>
                                                            Assigned: {value.total_hours_planned}
                                                        </Box>
                                                        <Box fontSize={screenSizeCheck() ? '10px' : '12px'} color={getBrowncolor()} fontWeight={'bold'}>
                                                            Remain: {(value.total_hours_planned - value.is_approved - value.in_progress)}
                                                        </Box>
                                                    </Box>
                                                </Box>

                                            </Box>
                                        </div>
                                    </Card>
                                </Tooltip>
                            </Box >
                        )}
                    </Slider>
                }
                <div>
                    <Dialog onClose={handleRequestClose} fullWidth={true} aria-labelledby="customized-dialog-title" open={this.state.openRequest}>
                        <DialogTitle id="customized-dialog-title1" onClose={handleRequestClose}>
                            {this.state.dialogData ? (this.state.dialogData.decs ? this.state.dialogData.decs : this.state.dialogData.name) : null}
                        </DialogTitle>
                        <DialogContent dividers>
                            <UserHoursRequest value={this.state.dialogData} uid={user.id} tender={this.state.dialogData ? this.state.dialogData.project_id : null} onCloseCommand={handleRequestClose} />
                        </DialogContent>
                    </Dialog>

                    <Dialog fullWidth
                        open={this.state.openTaskUpdate}
                        onClose={handleTaskClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description">
                        <DialogTitle>{this.state.perTaskData ? this.state.perTaskData.name : null}</DialogTitle>
                        <DialogContent>
                            <div>
                                <Alert severity="info">
                                    For Engineering, this is a Physical % complete.<br/>
                                    For IT & Others, this can be duration % complete. Please contact your Engineering Manager for queries.
                                </Alert>
                                {(this.state.perTaskLoading) ? (null) : (
                                    <FormControl fullWidth >
                                        <InputLabel fullWidth id="demo-simple-select-label">Select Percentage Complete</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            fullWidth
                                            value={this.state.taskPercent}
                                            onChange={handlePercChange}>
                                            <MenuItem value={0}>0% - Not started.</MenuItem>
                                            <MenuItem value={5}>5% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={10}>10% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={20}>20% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={30}>30% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={40}>40% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={50}>50% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={60}>60% - Checked within Department.</MenuItem>
                                            <MenuItem value={65}>65% - Approved within Department.</MenuItem>
                                            <MenuItem value={70}>70% - Site comments received, reviewed.</MenuItem>
                                            <MenuItem value={80}>80% - Multi-disciplinary Reviewed & Commented.</MenuItem>
                                            <MenuItem value={90}>90% - Design verified.</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleTaskClose} color="primary">
                                Close
                            </Button>
                            <Button onClick={() => this.handleTaskComp()} disabled={!this.state.perTaskData} color="primary">
                                Submit
                            </Button>

                        </DialogActions >
                    </Dialog >

                    <Dialog
                        fullWidth
                        maxWidth="xs"
                        open={this.state.openDateDialog}
                        onClose={handleDateDialogClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description">
                        <DialogTitle>{this.state.perTaskData ? (this.state.perTaskData.decs ? this.state.perTaskData.decs : this.state.perTaskData.name) : null}</DialogTitle>
                        <DialogContent>
                            <DateChangeRequest value={this.state.perTaskData} onCloseCommand={handleDateDialogClose} uid={user.id} />
                        </DialogContent>
                    </Dialog>

                    <Dialog maxWidth="sm" open={this.state.openPending} aria-labelledby="form-dialog-title" onClose={handlePendingClose}>
                        <DialogTitle>{this.state.perTaskData ? (this.state.perTaskData.decs ? this.state.perTaskData.decs : this.state.perTaskData.name) : null}</DialogTitle>
                        <DialogContent>
                            <Alert severity="warning">
                                <AlertTitle>Warning</AlertTitle>
                                {this.state.pendingReqData?.requested_by + " is already requested for the date change for this task " + (!this.state.pendingReqData?.requested_date ? "" : "(Requested finish date - " + this.state.pendingReqData?.requested_date + ")")}
                            </Alert>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handlePendingClose} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog fullScreen open={this.state.openSubTender} aria-labelledby="customized-dialog-title" TransitionComponent={Transition}>
                        <AppBar position="static">
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleSubTenderClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" >
                                    {this.state.subTenderData ? this.state.subTenderData.name + " (" + this.state.subTenderData.project_name + " -> " + this.state.subTenderData.ds_name + ") " : null}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        {/* <DialogContent> */}
                        <MainTenderSubTasks task_id={this.state.subTenderData ? this.state.subTenderData.id : null} />
                        {/* </DialogContent> */}
                    </Dialog>

                    <Dialog
                        fullWidth
                        TransitionComponent={Transition}
                        open={this.state.openTaskDownload}
                        onClose={handleTaskDownloadClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description">
                        <DialogTitle>Task Download</DialogTitle>
                        <DialogActions>
                            {this.state.dialogLoader ? <CircularProgress /> :
                                <>
                                    <Button onClick={() => this.downloadTask()} color="primary">
                                        task download
                                    </Button>
                                    <Button onClick={() => this.downloadSubtask()} color="primary">
                                        subtask download
                                    </Button>
                                    <Button onClick={handleTaskDownloadClose} color="primary">
                                        cancel
                                    </Button>
                                </>
                            }
                        </DialogActions>
                    </Dialog>
                </div >
            </Container >
        )
    }
}

export default withSnackbar(TaskCards)