import React, { Component } from 'react';
import MaterialTable from 'material-table'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import ReadFeedbackComments from './ReadFeedbackComments';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

const url = "/bb/api/user/feedback/";

class ManageFeedback extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        this.fetchOps()
    }

    fetchOps() {
        this.setState({ isLoading: true })
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    postData(data) {
        data.created_by = user.id
        Service.post(url, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.fetchOps()
                this.props.enqueueSnackbar('Data saved successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    putData(data) {
        Service.put(url + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.fetchOps()
                this.props.enqueueSnackbar('Data updated successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    deleteData(data) {
        Service.delete(url + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.fetchOps()
                this.props.enqueueSnackbar('Data deleted successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    activateFeedback(data) {
        Service.post(url + "activate_collection/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.fetchOps()
                this.props.enqueueSnackbar('Data saved successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    deactivateFeedback() {
        Service.get(url + "deactivate_collection/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.fetchOps()
                this.props.enqueueSnackbar('Data saved successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    showFeedback(data) {
        Service.post(url + "show_rating/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.fetchOps()
                this.props.enqueueSnackbar('Data saved successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    hideFeedback() {
        Service.get(url + "hide_rating/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.fetchOps()
                this.props.enqueueSnackbar('Data saved successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title={"Manage Feedback"}
                    columns={[
                        {
                            title: 'ID', field: 'id', editable: false,
                            headerStyle: { minWidth: 100 }, cellStyle: { minWidth: 100 },
                        },
                        {
                            title: 'Title', field: 'title',
                            headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                        },
                        {
                            title: 'Description', field: 'decs',
                            headerStyle: { minWidth: 450 }, cellStyle: { minWidth: 450 },
                        },
                        {
                            title: 'Code', field: 'feedback_code', type: 'numeric',
                            headerStyle: { minWidth: 100 }, cellStyle: { minWidth: 100 },
                        },
                        {
                            title: 'Activate', field: 'activate_collection', editable: false, lookup: { 'true': "Activated" },
                            headerStyle: { minWidth: 100 }, cellStyle: { minWidth: 100 },
                        },
                        {
                            title: 'Show', field: 'show_rating', editable: false, lookup: { 'true': "Showing" },
                            headerStyle: { minWidth: 100 }, cellStyle: { minWidth: 100 },
                        },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        padding: "dense",
                        actionsColumnIndex: -1,
                        paging: false,
                    }}
                    detailPanel={rowData => {
                        return (
                            <ReadFeedbackComments value={rowData} />
                        )
                    }}
                    actions={[
                        rowData => ({
                            icon: 'feedback',
                            tooltip: 'Activate Feedback',
                            onClick: (event, rowData) => this.activateFeedback(rowData),
                            disabled: rowData.activate_collection
                        }),
                        rowData => ({
                            icon: 'visibility',
                            tooltip: 'Show Feedbacks',
                            onClick: (event, rowData) => this.showFeedback(rowData),
                            disabled: rowData.show_rating
                        }),
                        {
                            icon: 'comments_disabled',
                            tooltip: 'Deactivate Feedbacks',
                            isFreeAction: true,
                            onClick: () => this.deactivateFeedback(),

                        },
                        {
                            icon: 'visibility_off',
                            tooltip: 'Hide Feedbacks',
                            isFreeAction: true,
                            onClick: () => this.hideFeedback(),
                        }
                    ]}
                    editable={
                        {
                            onRowAdd: newData =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data.push(newData);
                                            this.postData(newData)
                                            return { ...prevState, data };
                                        });
                                    }, 60);
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        if (oldData) {
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                this.putData(newData)
                                                return { ...prevState, data };
                                            });
                                        }
                                    }, 60);
                                }),
                            onRowDelete: oldData =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data.splice(data.indexOf(oldData), 1);
                                            this.deleteData(oldData)
                                            return { ...prevState, data };
                                        });
                                    }, 60);
                                }),
                        }
                    }
                />
            </div>
        );
    }
}

export default withSnackbar(ManageFeedback);