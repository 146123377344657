import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import TaskLevelDetails from './TaskLevelDetails';
import TaskResources from './TaskResources';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SecuredProjectRoles from '../SecuredWorks/SecuredProjectRoles';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'inline-block',
        border: `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
    },
    divider: {
        margin: theme.spacing(1, 0.5),
    },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: theme.spacing(0.5),
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}))(ToggleButtonGroup);

export default function RoleVsResourceToggle(props) {
    const [toggleValue, setToggleValue] = React.useState('resource');
    const [open, setOpen] = React.useState(false);

    const handleToggle = (event, newValue) => {
        setToggleValue(newValue);
    };

    const handleDialogClose = () => {
        setOpen(false)
        const toggleData = toggleValue
        setToggleValue("qwerty")
        setTimeout(() => {
            setToggleValue(toggleData)
        }, 50);
    };

    const classes = useStyles();

    return (
        <div>
            <div style={{ textAlign: 'center' }}>
                <Paper elevation={0} className={classes.paper}>
                    <StyledToggleButtonGroup
                        size="small"
                        value={toggleValue}
                        exclusive
                        onChange={handleToggle}
                        aria-label="text alignment"
                    >
                        <ToggleButton value="role" aria-label="left aligned">
                            By Roles
                        </ToggleButton>
                        <ToggleButton value="resource" aria-label="centered">
                            By Resources
                        </ToggleButton>
                    </StyledToggleButtonGroup>
                </Paper>
                <Button style={{ marginLeft: '15px' }} onClick={() => setOpen(true)} color="primary" variant='outlined'>Manage Roles</Button>
            </div>
            {toggleValue === 'role' ? <TaskLevelDetails task={props.task} allUsers={props.allUsers} userAdded={props.userAdded} /> : null}
            {toggleValue === 'resource' ? <TaskResources task={props.task} allUsers={props.allUsers} userAdded={props.userAdded} /> : null}
            <Dialog
                open={open}
                fullWidth
                maxWidth='lg'
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">{props.name}</DialogTitle> */}
                <DialogContent>
                    <SecuredProjectRoles tid={props.task.id} projectLook={{ [props.task.project.id]: props.task.project.tender_name }} requestOption={props.teamOrProj ? false : true} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}