import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LoopCircleLoading } from 'react-loadingg';
import { TextField, Box } from '@material-ui/core';
// import Moment from 'react-moment';
import WBSTaskTemplate from './WBSTaskTemplate';
import ReactGA from 'react-ga';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';


const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));
var editdata = '';
class WBSTemplate extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            openAdd: false,
            openEdit: false,
            projName: '',
            projId: '',
            isLoading: true,
        }
    }


    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {

        var pageView = window.location.pathname + "/#Deliverables Template";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        Service.get(`/bb/api/tender/template/ohlwbs/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var data = res.data;
                var jsonD = [];
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    if (element.template_type === this.props.value) {
                        jsonD.push(element);
                    }
                }
                this.setState({ data: JSON.parse(JSON.stringify(jsonD)), isLoading: false })
            })
            .catch(error => {
               errorMessage(this.props, error)
            });
    }

    giveaccess(putData, status) {

        var pageView = window.location.pathname + "/#Deliverables Template";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        this.setState({ isLoading: true, });

        putData.is_active = status;

        Service.put("/bb/api/tender/template/ohlwbs/" + putData.id, {
            headers: {
                Authorization: "Token " + token,
            },
            data: putData,
        })
            .then(res => {
                // console.log(res.data)
                this.props.enqueueSnackbar('Mode changed', {
                    variant: 'success'
                });
                this.fetchOps();
            })
            .catch(error => {
                // console.log(error)
               errorMessage(this.props, error)
            });
    }

    editdata(putData) {

        var pageView = window.location.pathname + "/#Deliverables Template";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        this.setState({ isLoading: true, });

        putData.name = this.state.projName;

        Service.put("/bb/api/tender/template/ohlwbs/" + putData.id, {
            headers: {
                Authorization: "Token " + token,
            },
            data: putData,
        })
            .then(res => {
                // console.log(res.data)
                this.props.enqueueSnackbar('Mode changed', {
                    variant: 'success'
                });
                this.setState({ isLoading: false, projName: '', openEdit: false })
                this.fetchOps();
            })
            .catch(error => {
                // console.log(error)
               errorMessage(this.props, error)
                this.setState({ isLoading: false })
            });
    }

    postData() {

        var pageView = window.location.pathname + "/#Deliverables Template";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (this.state.projName === '') {
            this.props.enqueueSnackbar('Fill the field!', {
                variant: 'error'
            });
        } else {
            this.setState({ isLoading: true, });
            var json = {
                name: this.state.projName,
                tasks: [],
                template_type: this.props.value,
                created_by: user.email,
            }

            Service.post("/bb/api/tender/template/ohlwbs/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: json,
            })
                .then(res => {

                    this.setState({ openAdd: false, projName: '' });
                    // console.log(res.data)
                    this.props.enqueueSnackbar('Project added successfully', {
                        variant: 'success'
                    });
                    this.fetchOps();
                })
                .catch(error => {
                    // console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }


    render() {

        const handleClose = (e) => {
            this.setState({ openAdd: false })
        };
        const handleClose2 = (e) => {
            this.setState({ openEdit: false })
        };

        const handleName = (e) => {
            this.setState({ projName: e.target.value })
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                        <div>

                            {(access && access.access && access.access.l9a.l9a_b) ? (
                                <Box display="flex" flexDirection="row-reverse" p={1} m={1} >
                                    <Button variant="contained" color="primary" onClick={e => { this.setState({ openAdd: true }) }} style={{ marginBottom: '10px' }}>Add WBS</Button>
                                </Box>
                            ) : (null)}

                            <MaterialTable
                                title="Manage WBS"
                                columns={[
                                    { title: 'WBS ID', field: 'id' },
                                    { title: 'WBS', field: 'name' },
                                ]}
                                data={this.state.data}
                                actions={(access && access.access && access.access.l9a.l9a_c) ? ([
                                    rowData => ({
                                        icon: 'edit',
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => { editdata = rowData; this.setState({ openEdit: true }) },
                                    }),
                                    rowData => ({
                                        icon: 'check_circle',
                                        tooltip: 'Enable',
                                        onClick: (event, rowData) => this.giveaccess(rowData, true),
                                        disabled: rowData.is_active
                                    }),
                                    rowData => ({
                                        icon: 'cancel',
                                        tooltip: 'Disable',
                                        onClick: (event, rowData) => this.giveaccess(rowData, false),
                                        disabled: !rowData.is_active
                                    })
                                ]) : (null)}
                                options={{
                                    padding: "dense",
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 50],
                                    actionsColumnIndex: -1
                                }}
                                detailPanel={rowData => {
                                    return (
                                        <WBSTaskTemplate value={rowData} />
                                    )
                                }}
                            />
                            <Dialog open={this.state.openAdd} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">Add Project</DialogTitle>
                                <DialogContent>

                                    <TextField id="outlined-basic" label="WBS Name" variant="outlined"
                                        value={this.state.projName}
                                        onChange={handleName}
                                        fullWidth
                                        style={{ marginTop: '5px' }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={e => this.postData()} color="primary">
                                        Add WBS
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={this.state.openEdit} fullWidth onClose={handleClose2} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">Add Project</DialogTitle>
                                <DialogContent>

                                    <TextField id="outlined-basic" label="WBS Name" variant="outlined"
                                        value={this.state.projName}
                                        onChange={handleName}
                                        fullWidth
                                        style={{ marginTop: '5px' }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose2} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={e => this.editdata(editdata)} color="primary">
                                        Add WBS
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    )}

            </div>
        )
    }
}

export default withSnackbar(WBSTemplate);