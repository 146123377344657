import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import Service from "../Networking/networkutils";
// import jsPDF from 'jspdf';
import moment from "moment";
import { CsvBuilder } from 'filefy';
import { errorMessage } from '../Helper/ErrorMessage';

import { CircularProgress, Typography } from '@material-ui/core';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
// const user = JSON.parse(getCookie("bb_ts_user"));

class UserAllocatedHours extends Component {

    constructor(props) {
        super();

        this.state = {
            data: [],
            alloc: [],
            isLoading: true,
        }
    }

    componentDidMount() {

        if (!(access && access.access && access.access.l111a && access.access.l111a.l111a_a)) {
            window.location.href = "/"
        } else {
            this.fetchOps();
        }

    }


    fetchOps() {
        var data12 = { null: 'Not Available' };
        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                var response = res.data;
                
                for (let i = 0; i < response.length; i++) {
                    const element = response[i];
                    data12[element.id] = element.name;
                }
                this.setState({ isLoading2: false, rolesCust: data12, roleAll: res.data, })
            })
            .catch(error => {
               errorMessage(this.props, error)
            });
        Service.get('/bb/api/tender/user/task/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res1 => {

                this.setState({
                    alloc: res1.data
                })

                Service.get(`/bb/api/users/`, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res => {
                        var viewPid = []

                        for (let i = 0; i < res.data.length; i++) {
                            const element = res.data[i];
                            element.alloc  = this.getHours(element)
                            element.role_name = data12[element.role_id]
                            viewPid.push({ 'uid': element.id })
                        }
                        this.setState({
                            data: res.data,
                            isLoading: false,
                        }, () => {
                            Service.patch("/bb/api/team/tasks/get_acthours/", {
                                headers: {
                                    Authorization: "Token " + token,
                                },
                                data: viewPid,
                            })
                                .then(res12 => {
                                    // console.log(res12.data)
    
                                    for (let j = 0; j < res12.data.length; j++) {
                                        const element13 = res12.data[j];
                                        for (let i = 0; i < res.data.length; i++) {
                                            const element12 = res.data[i];
                                            if (element12.id === element13.id) {
                                                element12.actual_hours = timeToDecimal(element13.hours_actual)
                                                element12.inprogress_hours = timeToDecimal(element13.hours_ongoing)
                                                element12.total_alloc_hours = element13.allocated
                                                break;
                                            }
                                            // console.log(timeToDecimal(element13.hours_actual) !== 0)
                                        }
    
                                    }
                                    // console.log(res.data)
                                    this.setState({
    
                                        data: res.data,
    
                                    })
    
                                    
    
                                })
                                .catch(error => {
                                    this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                                        variant: 'error'
                                    });
    
                                });
                        })
                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });

            })
            .catch(error => {
               errorMessage(this.props, error)
            });

    }

    getHours(rowData) {
        var hours =0;
        for (let i = 0; i < this.state.alloc.length; i++) {
            const element = this.state.alloc[i];
            
            if (element.user_id === rowData.id) {
                hours = hours + element.planed_hours;
            }
        }
        return hours;
    }


    render() {

        const exportCsv = (columnList, initialData) => {
            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    return columnDef.render ? columnDef.render(rowData) : rowData[columnDef.field];
                })
            );

            const builder = new CsvBuilder("Timesheet Report " + moment().format('MM/DD/YYYY hh:mm') + '.csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };

        return (
            <div>
                <MaterialTable
                    title="Allocated Hours for Users"
                    columns={[
                        { title: 'First Name', field: 'first_name' },
                        { title: 'Last Name', field: 'last_name' },
                        { title: 'Email', field: 'email', editable: 'never' },
                        {
                            title: 'Role', field: 'role_name',
                            // lookup: this.state.rolesCust,
                        },
                        {
                            title: 'Office Location', field: 'office_loc',
                            lookup: { 0: 'PT&D', 1: 'EDC', 2: 'SL'},
                        },
                        {
                            title: 'Allocated Hours', field: 'alloc',
                            // render: rowData => this.getHours(rowData)
                        },
                        {
                            title: 'Actual and Inprogress Hours/Total Allocated', field: 'ah', editable: 'never',
                            render: rowData => (rowData.id) ? ((rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{(rowData.actual_hours + rowData.inprogress_hours) + "/" + rowData.total_alloc_hours}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)) : ("")
                        },
                        {
                            title: 'Balance Hours', field: 'ah', editable: 'never',
                            render: rowData => (rowData.id) ? ((rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{rowData.total_alloc_hours - (rowData.actual_hours + rowData.inprogress_hours)}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)) : ("")
                        },
                        
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        padding: "dense",
                        pageSize: 20,
                        pageSizeOptions: [10, 20, 50],
                        actionsColumnIndex: -1,
                        filtering: true,
                        exportButton: { csv: true },
                        exportCsv: (columns, data) => {
                            exportCsv(columns, data)
                        },
                        // exportPdf: () => alert('This feature is disabled. Data can only be exported as a CSV.'),
                    }}
                    
                />
            </div>
        )
    }
}

function addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec ? 1 : null
    if (len) {
        return parseFloat(Number(num).toFixed(len))
    } else {
        return parseFloat(Number(num))
    }
}

function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
}


export default withSnackbar(UserAllocatedHours);