import moment from 'moment';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import { errorMessage } from '../Helper/ErrorMessage';
import { getCookie } from '../Helper/CookieHelper';
import { onlyUniqueArr } from '../Helper/CommonThings';
import { CircularProgress } from '@mui/material';
import MaterialTable from 'material-table';

const token = getCookie("bb_ts_token");

class TeamKPI extends Component {

    constructor(props) {
        super(props)

        this.state = {
            startDate: moment(),
            endDate: moment(),
            isLoading: true,
            data: [],
        }
    }


    componentDidMount() {

        var date1 = moment(this.state.startDate).subtract(7, 'days').format('DD-MM-YYYY')
        var date2 = moment(this.state.endDate).subtract(7, 'days').format('DD-MM-YYYY')
        Service.get2("/bb/api/report/view/timesheet_week_report3/?start=" + date1 + "&end=" + date2, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data[0].users_details)
                var allUserTeams = []
                for (let i = 0; i < res.data[0].users_details.length; i++) {
                    const element = res.data[0].users_details[i];
                    if (element.teams) {
                        allUserTeams.push(element.teams)
                    }

                }
                allUserTeams = allUserTeams.filter(onlyUniqueArr)
                // console.log(allUserTeams)
                var tableData = []
                for (let i = 0; i < allUserTeams.length; i++) {
                    const element = allUserTeams[i];
                    var contracted = 0
                    var approved = 0
                    for (let j = 0; j < res.data[0].users_details.length; j++) {
                        const element1 = res.data[0].users_details[j];
                        if (element === element1.teams) {
                            contracted += element1.weekly_hours
                            approved += element1.worked_time
                        }
                    }
                    tableData.push({
                        teams: element,
                        contract: contracted,
                        approved: approved,
                        difference: (contracted - approved) < 0 ? 0 : (contracted - approved),

                    })
                }
                this.setState({
                    data: tableData,
                    isLoading: false
                })

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    render() {
        return (
            <div>
                {this.state.isLoading ?
                    <CircularProgress />
                    :
                    <MaterialTable
                            title="Team's KPI"
                            columns={[
                                { title: 'Teams', field: 'teams' },
                                { title: 'Contracted', field: 'contract' },
                                { title: 'Approved', field: 'approved' },
                                { title: 'Difference', field: 'difference' },
                            ]}
                            data={this.state.data}
                            // actions={[
                            //     rowData => ({
                            //         icon: this.status1(rowData),
                            //         tooltip: this.status2(rowData),
                            //     }),
                            // ]}
                            options={{
                                paging: false,
                                padding: "dense",
                                actionsColumnIndex: -1,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 30],
                            }}
                        />
                }
            </div>
        )
    }
}

export default withSnackbar(TeamKPI)