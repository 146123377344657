import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { hourRequestReasonType, HtmlTooltip } from '../Helper/CommonThings'
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
// import MaterialTable from 'material-table';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import ReqHistoryTabs from './ReqHistoryTabs'

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class UserHoursRequest extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hours: 0,
            team: null,
            lead: null,
            allTeamLeads: [],
            allTeamData: [],
            showLeads: [],
            comment: '',
            requestPending: false,
            openRequest: false,
            data: [],
            isLoading: true,
            reason_type: 0,
            submitTo: 'lead',
            pending_hours_em: 0,
            pending_hours_lead: 0,
            allAllocated: [],
            allocated: null,
        }
    }

    componentDidMount() {
        Service.get("/bb/api/user/report/view/get_team_by_user/?uid=" + this.props.uid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ allTeamData: res.data }, () => {
                    if (this.state.allTeamData.length === 1) {
                        this.setState({ team: this.state.allTeamData.length === 1 ? this.state.allTeamData[0] : null })
                        this.getTeamLeads(this.state.allTeamData[0])
                    }
                })
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });

        Service.get("/bb/api/user/hours/request/pending_hours_request/?uid=" + this.props.uid + "&task_id=" + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.pending_hours_lead > 0 || res.data.pending_hours_em > 0) {
                    this.setState({
                        requestPending: true,
                        pending_hours_em: res.data.pending_hours_em,
                        pending_hours_lead: res.data.pending_hours_lead
                    })
                }

            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    // getRequestHistory() {
    //     this.setState({ openRequest: true })
    //     Service.get("/bb/api/user/hours/request/?uid=" + this.props.uid + "&ttid=" + this.props.value.id, {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res => {
    //             this.setState({
    //                 data: res.data,
    //                 isLoading: false,
    //             })
    //         })
    //         .catch(error => {
    //             // console.log(error)
    //             errorMessage(this.props, error)
    //         });
    // }

    getTeamLeads(data) {
        this.setState({
            showLeads: data.lead_details,
            lead: data.lead_details.length === 1 ? data.lead_details[0] : null
        })
    }

    onTeamChange = (event, values) => {
        this.setState({
            team: values
        }, () => {
            this.getTeamLeads(this.state.team)
        })
    }

    onLeadChange = (event, values) => {
        this.setState({
            lead: values
        })
    }

    processSubmit() {
        if (parseInt(this.state.hours) > 0 && this.props.value && this.state.reason_type && ((this.state.submitTo === 'em' && !this.state.allocated) ? false : true)) {
            Service.post('/bb/api/user/hours/request/hours_request/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    // ds: this.props.value.t_pid,
                    // tender: this.props.tender,
                    uid: this.props.uid,
                    lead: this.state.lead ? this.state.lead.id : null,
                    team: this.state.team ? this.state.team.team_id : null,
                    comment: this.state.comment,
                    hours_needed: this.state.hours,
                    ttid: this.props.value.id,
                    task_map: this.state.submitTo === 'em' ? (this.state.allocated ? this.state.allocated.id : null) : null,
                    // twbsid: this.props.value.t_ohlwbs,
                    created_by: user.id,
                    reason_type: this.state.reason_type,
                    type: this.state.submitTo
                }
            })
                .then(res => {
                    this.props.enqueueSnackbar('Request sent successfully!', {
                        variant: 'success'
                    });
                    this.props.onCloseCommand()
                    // this.fetchData();
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        } else if (!this.state.allocated && this.state.submitTo === 'em') {
            this.props.enqueueSnackbar("Please Select the Role!", {
                variant: 'warning'
            });
        } else if (parseInt(this.state.hours) <= 0) {
            this.props.enqueueSnackbar("Requesting hours should be greate than 0!", {
                variant: 'warning'
            });

        } else {
            this.props.enqueueSnackbar("Fill all the fields!", {
                variant: 'warning'
            });
        }
    }

    fetchAllocatedRoles() {
        this.setState({
            isLoading: true
        })
        // console.log(dataIn)
        Service.get('/bb/api/tender/user/task/?tid=' + this.props.value.id + "&uid=" + this.props.uid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({
                    allAllocated: res.data,
                    isLoading: false,
                    allocated: res.data.length === 1 ? res.data[0] : null
                })

            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    onRoleChange = (event, values) => {
        this.setState({
            allocated: values,
        })
    }

    // putData(data) {
    //     // console.log(data)
    //     Service.get("/bb/api/user/hours/request/" + data.id + "/", {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res => {
    //             if (res.data.status === 0) {
    //                 Service.put("/bb/api/user/hours/request/" + data.id + "/", {
    //                     headers: {
    //                         Authorization: "Token " + token,
    //                     },
    //                     data: data,
    //                 })
    //                     .then(res => {
    //                         this.props.enqueueSnackbar('Updated Successfully!', {
    //                             variant: 'success'
    //                         });
    //                     })
    //                     .catch(error => {
    //                         // console.log(error)
    //                         errorMessage(this.props, error)
    //                     });
    //             } else {
    //                 this.props.enqueueSnackbar('Failed!', {
    //                     variant: 'error'
    //                 });
    //             }

    //         })
    //         .catch(error => {
    //             // console.log(error)
    //             errorMessage(this.props, error)
    //         });
    // }

    render() {

        const handleHoursChange = (event) => {
            if (parseFloat(event.target.value) <= 100 || event.target.value === "") {
                this.setState({
                    hours: event.target.value
                })

            } else {
                this.setState({
                    hours: 100
                })
                this.props.enqueueSnackbar('Not more than 100 hours is allowed in single request!. Only your team lead can request more that 100.', {
                    variant: 'warning'
                });
            }
        };

        const handleCommentChange = (event) => {
            this.setState({
                comment: event.target.value
            })
        };

        const handleRequestClose = () => {
            this.setState({ openRequest: false, })
        };

        const handleReasonTypeChange = (event) => {
            this.setState({
                reason_type: event.target.value
            })
        };

        const handleToggle = (event, newValue) => {
            if (newValue !== null) {
                this.setState({ submitTo: newValue, allocated: null }, () => {
                    if (this.state.submitTo === 'em') {
                        this.fetchAllocatedRoles()
                    }
                });
            }
        };

        return (
            <div>
                {this.state.requestPending ?
                    <Alert severity="warning" style={{ marginBottom: '3px' }}>
                        There are some pending request available. {this.state.pending_hours_lead} hours is waiting for approval from Team Lead and {this.state.pending_hours_em} hours is waiting for EM approval. See request history for more details.
                    </Alert> : null}
                <ToggleButtonGroup
                    color="primary"
                    size="small"
                    // style={{ marginBottom: '3px' }}
                    value={this.state.submitTo}
                    onChange={handleToggle}
                    exclusive
                    aria-label="text formatting"
                >
                    <ToggleButton value={"lead"} aria-label="to_lead">
                        Submit to Lead
                    </ToggleButton>
                    <ToggleButton value={"em"} aria-label="to_em">
                        Submit to EM
                    </ToggleButton>
                </ToggleButtonGroup>
                <FormControl variant="outlined" fullWidth style={{ marginTop: '15px' }} >
                    <InputLabel id="demo-simple-select-outlined-label">Reason Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.reason_type}
                        onChange={handleReasonTypeChange}
                        label="Reason Type"
                    >
                        <MenuItem value={0}></MenuItem>
                        {[1, 2, 3, 4, 5].map((item) =>
                            <MenuItem value={item}>
                                <HtmlTooltip
                                    style={{ width: '100%' }}
                                    title={
                                        <React.Fragment>
                                            <Typography color="inherit">{hourRequestReasonType(item).menu}</Typography>
                                            {hourRequestReasonType(item).note}
                                        </React.Fragment>
                                    }
                                >
                                    <Box display="flex">{hourRequestReasonType(item).menu}</Box>
                                </HtmlTooltip>
                            </MenuItem>
                        )}

                    </Select>
                </FormControl>
                <TextField
                    id="hours"
                    label="Hours Required"
                    value={this.state.hours}
                    onChange={handleHoursChange}
                    style={{ marginTop: '15px' }}
                    fullWidth
                    type="number"
                    variant="outlined"
                />
                {(this.state.allTeamData.length > 1 && this.state.submitTo === 'lead') ? (
                    <Autocomplete
                        id={"teams"}
                        fullWidth
                        style={{ marginTop: '15px' }}
                        value={this.state.team}
                        options={this.state.allTeamData}
                        getOptionLabel={(option) => option.team_name}
                        getOptionSelected={(option, value) => value !== '' && option.team_id === value.team_id}
                        onChange={(e, v) => this.onTeamChange(e, v)}
                        renderInput={(params) => <TextField {...params} label="Select Team" variant="outlined" />}
                    />
                ) : (null)}

                {this.state.submitTo === 'lead' ?
                    <Autocomplete
                        id={"leads"}
                        fullWidth
                        style={{ marginTop: '15px' }}
                        value={this.state.lead}
                        options={this.state.showLeads}
                        getOptionLabel={(option) => option.last_name + ", " + option.first_name}
                        getOptionSelected={(option, value) => value !== '' && option.id === value.id}
                        onChange={(e, v) => this.onLeadChange(e, v)}
                        renderInput={(params) => <TextField {...params} label="Select Team Lead" variant="outlined" />}
                    />
                    : null}

                {this.state.submitTo === 'em' ?
                    <Autocomplete
                        id={"roles"}
                        fullWidth
                        style={{ marginTop: '15px' }}
                        value={this.state.allocated}
                        options={this.state.allAllocated}
                        getOptionLabel={(option) => option.tasks_roles_data.name}
                        getOptionSelected={(option, value) => value !== '' && option.id === value.id}
                        onChange={(e, v) => this.onRoleChange(e, v)}
                        renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
                    /> : null}

                <TextField
                    id="outlined-multiline-static"
                    label="Comment"
                    multiline
                    style={{ marginTop: '15px' }}
                    fullWidth
                    rows={3}
                    value={this.state.comment}
                    onChange={handleCommentChange}
                    // defaultValue="Default Value"
                    variant="outlined"
                    helperText={this.state.comment.length > 250 ? <p style={{ color: 'red' }}>Comments should be less than 250 characters</p> : null}
                />
                <Box display="flex" justifyContent="center" m={1} p={1}>
                    <Box>
                        <Button variant="contained" color="primary"
                            disabled={(!(this.state.hours && (this.state.submitTo === 'em' ? true : this.state.team) && (this.state.submitTo === 'em' ? true : this.state.lead) && this.state.reason_type && ((this.state.submitTo === 'em' && !this.state.allocated) ? false : true))) || (this.state.requestPending)}
                            onClick={() => this.processSubmit()}
                        >
                            Submit
                        </Button>
                        <Button variant="contained" color="primary"
                            style={{ marginLeft: '8px' }}
                            // disabled={!(this.state.hours && this.state.team && this.state.lead && this.state.comment)}
                            onClick={() => this.setState({
                                openRequest: true
                            })}
                        // onClick={() => this.getRequestHistory()}
                        >
                            Show Request History
                        </Button>
                    </Box>
                </Box>

                <Dialog onClose={handleRequestClose} fullWidth={true} maxWidth={"md"} aria-labelledby="customized-dialog-title" open={this.state.openRequest}>
                    <DialogContent dividers>
                        <ReqHistoryTabs value={this.props.value} uid={this.props.uid} />
                    </DialogContent>
                </Dialog>

            </div >
        )
    }
}

export default withSnackbar(UserHoursRequest)