import React, { Component } from 'react'
import Service from "../../Networking/networkutils";
import { getCookie } from '../../Helper/CookieHelper';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import moment from "moment";
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';
// import TimeSheetDialog from '../../TimeSheet/TimeSheetDialog';


const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class UserDetail extends Component {

    constructor(props) {
        super()

        this.state = {
            data: [],
            isLoading: true
        }
    }

    componentDidMount() {
        this.fetchOps()
    }

    fetchOps() {
        Service.get(`/bb/api/user/withdetail/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                var data = []
                var user_detail_init = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if ((access && access.access && access.access.l16a && access.access.l16a.l16a_h) || user.is_hr) {
                        data.push(element)
                    } else if (element.office_loc === 1 && (access && access.access && access.access.l16a && access.access.l16a.l16a_f)) {
                        data.push(element)
                    } else if (element.office_loc === 0 && (access && access.access && access.access.l16a && access.access.l16a.l16a_g)) {
                        data.push(element)
                    }else if (element.office_loc === 2 && (access && access.access && access.access.l16a && access.access.l16a.l16a_k)) {
                        data.push(element)
                    }
                    element.name = element.first_name + " " + element.last_name;
                    if (!element.user_details) {
                        user_detail_init.push({
                            uid: element.id,
                            join_date: "2020-11-30"
                        })
                    }
                }
                if (user_detail_init.length) {
                    Service.post(`/bb/api/user/detail/`, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: user_detail_init,
                    })
                        .then(res1 => {
                            this.fetchOps()
                        })
                        .catch(error => {
                            console.log(error)
                            errorMessage(this.props, error)
                        });

                } else {
                    this.setState({
                        data: data,
                        isLoading: false,
                    });
                }

                // console.log(jsondata)
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    postData(data) {
        if (data.user_details.id) {
            if (data.user_details.join_date) {
                data.user_details.join_date = moment(data.user_details.join_date).format('YYYY-MM-DD')
            } else {
                data.user_details.join_date = null
            }

            if (data.user_details.leave_date) {
                data.user_details.leave_date = moment(data.user_details.leave_date).format('YYYY-MM-DD')
            } else {
                data.user_details.leave_date = null
            }
            Service.put(`/bb/api/user/detail/` + data.user_details.id, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data.user_details,
            })
                .then(res => {
                    this.props.enqueueSnackbar('Updated Sucessfully!', {
                        variant: 'success'
                    });
                    // console.log(jsondata)
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        } else {
            data.user_details.uid = data.id
            if (data.user_details.join_date) {
                data.user_details.join_date = moment(data.user_details.join_date).format('YYYY-MM-DD')
            } else {
                data.user_details.join_date = null
            }

            if (data.user_details.leave_date) {
                data.user_details.leave_date = moment(data.user_details.leave_date).format('YYYY-MM-DD')
            } else {
                data.user_details.leave_date = null
            }

            Service.post(`/bb/api/user/detail/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data.user_details,
            })
                .then(res => {
                    for (let i = 0; i < this.state.data.length; i++) {
                        const element = this.state.data[i];
                        if (res.data.uid === element.id) {
                            element.user_details.id = res.data.id
                            element.user_details.uid = res.data.uid
                            break;
                        }
                    }
                    this.props.enqueueSnackbar('Updated Sucessfully!', {
                        variant: 'success'
                    });
                    // console.log(jsondata)
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }

    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (

                    <MaterialTable
                        title="Employee Data"
                        columns={[
                            { title: 'Name', field: 'name', editable: 'never' },
                            { title: 'Email', field: 'email', editable: 'never' },
                            {
                                title: 'Role', field: 'role_name', editable: 'never'
                            },
                            {
                                title: 'Designation', field: 'user_details.designation',
                                // render: rowData => rowData.user_details.designation
                            },
                            {
                                title: 'Office Location', field: 'office_loc', editable: 'never',
                                lookup: { 0: 'UK', 1: 'EDC', 2: 'SL' },
                            },
                            {
                                title: 'Employee Code', field: 'user_details.emp_code',
                            },
                            {
                                title: 'R12 Employee Code', field: 'user_details.r12_emp_code',
                            },
                            {
                                title: 'R12 Classification Code', field: 'user_details.r12_class_code',
                            },
                            {
                                title: 'Join Date', field: 'user_details.join_date', type: 'date',
                            },
                            {
                                title: 'Leave Date', field: 'user_details.leave_date', type: 'date'
                            },

                        ]}
                        data={this.state.data}
                        options={{
                            padding: "dense",
                            paging: false,
                            filtering: true
                        }}

                        editable={{
                            // onRowAdd: newData =>
                            //     new Promise(resolve => {
                            //         setTimeout(() => {
                            //             resolve();
                            //             this.setState({ save: false });
                            //             this.setState(prevState => {
                            //                 const data = [...prevState.data];
                            //                 newData.roles = [];
                            //                 data.push(newData);
                            //                 return { ...prevState, data };
                            //             });
                            //         }, 60);
                            //     }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        if (oldData && (!newData.leave_date || newData.join_date < newData.leave_date)) {
                                            this.setState({ save: false });
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                return { ...prevState, data };
                                            });
                                            this.postData(newData);
                                        } else {
                                            this.props.enqueueSnackbar('Leave date should not be less that Join date!', {
                                                variant: 'warning'
                                            });
                                        }
                                    }, 60);

                                }),
                            // onRowDelete: oldData =>
                            //     new Promise(resolve => {
                            //         setTimeout(() => {
                            //             resolve();
                            //             this.setState({ save: false });
                            //             this.setState(prevState => {
                            //                 const data = [...prevState.data];
                            //                 data.splice(data.indexOf(oldData), 1);

                            //                 return { ...prevState, data };
                            //             });
                            //         }, 60);
                            //     }),
                        }}
                    />
                )}
            </div>
        )
    }
}

export default withSnackbar(UserDetail)