import { Box, Tooltip } from '@material-ui/core'
import React, { Component } from 'react'
import { getDiscipline } from '../Helper/CommonThings'

class CustProjDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    render() {
        return (
            <div>
                <Tooltip title={
                    <div>
                        Project: {this.props.data.tender_name}<br />
                        EM: {this.props.data.pdm_name ? this.props.data.pdm_name : "NA"}<br />
                        Discipline: {getDiscipline()[this.props.data.discipline]}<br />
                        Client: {this.props.data.client_name}<br />
                        Region: {this.props.data.region_name}
                    </div>
                }>
                    <Box display={'flex'} flexDirection='column' justifyContent={'center'} width={250} padding="5px" style={{ cursor: 'pointer' }}>
                        <Box display={'flex'} flexDirection='row' alignItems={'center'} width='100%' flexWrap='wrap'>
                            <b>{this.props.data.tender_name}</b>
                        </Box>
                        <Box display={'flex'} flexDirection='row' alignItems={'center'} width='100%' mt='5px' fontSize={'12px'}>
                            {(this.props.data.pdm_name ? this.props.data.pdm_name + "  |  " : "NA  |  ") + getDiscipline()[this.props.data.discipline] + "  |  " + this.props.data.client_name + "  |  " + this.props.data.region_name}
                        </Box>
                    </Box>
                </Tooltip>
            </div>
        )
    }
}
export default CustProjDetails
