import React, { Component } from 'react'
import MaterialTable from 'material-table';
import Service from "../../Networking/networkutils";
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';

const token = getCookie("bb_ts_token");
// const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class UserRoleChange extends Component {

    constructor(props) {
        super()

        this.state = {
            data: [],
            isLoading: true,
            roleAll: [],
            rolesCust: {},
        }
    }

    componentDidMount() {
        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                var response = res.data;
                var data12 = {};
                for (let i = 0; i < response.length; i++) {
                    const element = response[i];
                    data12[element.id] = element.name;
                }
                this.setState({ isLoading2: false, rolesCust: data12, roleAll: res.data, })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        this.fetchOps();
    }

    fetchOps() {

        Service.get('/bb/api/user/role/history/?uid=' + this.props.value, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ isLoading: false, data: res.data, })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    postOps(data) {

        if (!data.changed_role) {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Role field is empty!', {
                    variant: 'error'
                });
            }, 200)

        } else if (!data.changed_on) {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Change On field is empty!', {
                    variant: 'error'
                });
            }, 200)
        } else {
            data.uid = this.props.value
            data.created_by = user.id
            Service.post('/bb/api/user/role/history/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data,
            })
                .then(res => {
                    this.setState({ isLoading: true, })
                    this.fetchOps();
                    this.props.enqueueSnackbar('Role added successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    putOps(data) {

        if (!data.changed_on) {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Changed On field is empty!', {
                    variant: 'error'
                });
            }, 200)

        } else {
            Service.put('/bb/api/user/role/history/' + data.id, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data,
            })
                .then(res => {
                    this.props.enqueueSnackbar('Role updated successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: true, })
                    errorMessage(this.props, error)
                    this.fetchOps();
                });
        }
    }

    deleteData(data) {

        Service.delete('/bb/api/user/role/history/' + data.id, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.props.enqueueSnackbar('Role deleted successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: true, })
                errorMessage(this.props, error)
                this.fetchOps();
            });
    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <MaterialTable
                            title="Role Change History"
                            columns={[
                                { title: 'Role', field: 'changed_role', lookup: this.state.rolesCust },
                                { title: 'Location', field: 'changed_loc', lookup: { 0: "UK", 1: "IN", 2: "SL" } },
                                { title: 'Changed On', field: 'changed_on', type: 'date' },
                            ]}
                            options={{
                                padding: "dense",
                                pageSize: 10,
                                pageSizeOptions: [10, 25, 50],
                                paging: false
                            }}
                            data={this.state.data}
                            editable={
                                {
                                    onRowAdd: newData =>
                                        new Promise(resolve => {

                                            setTimeout(() => {
                                                resolve();
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    this.postOps(newData);
                                                    data.push(newData);
                                                    // this.postData(newData)
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        this.putOps(newData)
                                                        return { ...prevState, data };
                                                    });
                                                }
                                            }, 600);
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState({ saved: false });
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    this.deleteData(oldData)
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                }
                            }
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default withSnackbar(UserRoleChange);