import React from 'react';
import { TextField, Grid, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
// import { makeStyles } from '@material-ui/core/styles';
// const useStyles = makeStyles((theme) => ({
//     input: {
//         color: 'white'
//     }
// }));

export default function UserAdd(props) {

    // const classes = useStyles();

    const defaultProps = {
        options: props.allUsers,
        getOptionLabel: (option) => option.first_name + ' ' + option.last_name,
    };
    const defaultRoleProps = {
        options: props.roles,
        getOptionLabel: (option) => option.name,
    };

    const [allocHrs, setAllocHrs] = React.useState(null);
    const [user, setUser] = React.useState(null);
    const [role, setRole] = React.useState(null);

    return (
        <Grid container spacing={1} style={{ margin: 0, padding: '0 10px' }}>
            <Grid item xs={12}>
                <Autocomplete
                    {...defaultRoleProps}
                    id="role"
                    openOnFocus
                    renderInput={(params) => <TextField {...params} label="Role" margin="none"
                    />}
                    size='small'
                    style={{ fontSize: 'small' }}
                    fullWidth
                    onChange={(event, newValue) => { setRole(newValue) }}
                />
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    {...defaultProps}
                    id="user"
                    openOnFocus
                    renderInput={(params) => <TextField {...params} label="User" margin="none"
                    />}
                    size='small'
                    style={{ fontSize: 'small' }}
                    fullWidth
                    onChange={(event, newValue) => { setUser(newValue) }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField id="standard-basic" label="Hrs." size='small' type='number' onChange={(e) => setAllocHrs(Number(e.target.value))} />
            </Grid>
            <Grid item xs={3}>
                <IconButton component="span" onClick={() => props.save(role, user, allocHrs)}>
                    <CheckIcon />
                </IconButton>
                <IconButton component="span" onClick={props.hide}>
                    <CloseIcon />
                </IconButton>
            </Grid>
        </Grid>

    );
}