import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { errorMessage } from '../Helper/ErrorMessage'
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import { Rating } from '@material-ui/lab';
import { Box, TextField } from '@material-ui/core';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class FeedbackView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            feedbackComment: null,
            valueRating: 0
        }
    }


    submitFeedback() {
        // console.log(this.state.valueRating)
        if (!this.state.feedbackComment) {
            this.props.enqueueSnackbar('Add comment!', {
                variant: 'warning'
            })
        } else if (this.state.valueRating <= 0) {
            this.props.enqueueSnackbar('Add Rating!', {
                variant: 'warning'
            })
        } else {
            // this.setState({ openRating: false })
            // this.setState({ isLoading: true, });
            var json = {
                uid: user.id,
                category: this.props.value,
                rating: this.state.valueRating,
                feedback: this.state.feedbackComment,
                created_by: user.email,
            }
            this.setState({ feedbackComment: null });
            // console.log(json)
            Service.post("/bb/api/feedback/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: json,
            })
                .then(res => {
                    this.props.handleClose()
                    
                    this.props.enqueueSnackbar('Feedback submitted successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    this.props.handleClose()
                    // this.setState({ isLoading: false });
                    errorMessage(this.props, error)
                });
        }
    }

    render() {
        return (
            <div>
                <Box display="flex" justifyContent="center" style={{ padding: '5px' }}>
                    <Rating
                        name="hover-feedback"
                        value={this.state.valueRating}
                        precision={0.5}
                        size='large'
                        onChange={(event, newValue) => {
                            this.setState({ valueRating: newValue });
                        }
                        }
                    />
                </Box>
                <TextField
                    variant="outlined"
                    margin="dense"
                    id="feedbackComment"
                    label="Comment"
                    type="comment"
                    multiline
                    rows={3}
                    onChange={e => this.setState({ feedbackComment: e.target.value })}
                    fullWidth
                />
            </div>
        )
    }
}

export default withSnackbar(FeedbackView)