// import { WindMillLoading } from 'react-loadingg';
import Service from "../Networking/networkutils";

import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import { useDencrypt } from "use-dencrypt-effect";
import { Box, Typography } from '@material-ui/core';
import { getCookie, setCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import { confetti } from '../Helper/CommonThings';
// import ReactGA from 'react-ga';


const values = ["Balfour Beatty"];
const values1 = ["Build to Last"];

// const token = getCookie("bb_ts_token");

var doneApi = false;
var doneDec = false;
var doneAccess = false;

class Loader extends Component {

    constructor(props) {
        super(props)

        this.myInput = React.createRef()
    }


    componentDidMount() {
        // console.log(getCookie("bb_ts_token"))
        // console.log(getCookie('bb_ts_user'))
        if (getCookie("bb_ts_token")) {
            this.getAccess();
            Service.get(`/rest-auth/user/`, {
                headers: {
                    Authorization: "Token " + getCookie("bb_ts_token")
                },
            })
                .then(res => {
                    // console.log(res.data)


                    setCookie('bb_ts_user', JSON.stringify(res.data), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                    doneApi = true;
                    localStorage.setItem("load_got_user", true);

                })
                .catch(error => {
                    // alert(erro
                    errorMessage(this.props, error)

                });
        }

    }

    getAccess() {
        Service.get(`/bb/api/token/access/`, {
            headers: {
                Authorization: "Token " + getCookie("bb_ts_token"),
            },
        })
            .then(res => {
                // console.log(res.data)
                if (res.data && res.data[0]) {
                    if (getCookie("bb_ts_access")) {
                        if (JSON.parse(getCookie("bb_ts_access")).last_updated !== res.data[0].last_updated) {
                            setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                        }
                    } else {
                        setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                    }

                } else {
                    setCookie('bb_ts_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });

                }
                doneAccess = true;

            })
            .catch(error => {
                // this.props.enqueueSnackbar('Something went wrong!', {
                //     variant: 'error'
                // });
                setCookie('bb_ts_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                doneAccess = true;
            });
    }

    goOn() {
        setTimeout(function () { //Start the timer
            if (getCookie("bb_ts_token")) {
                window.location.href = '/home/#Dashboard'
            }
            // alert("over")
        }, 100)
    }

    render() {

        const Load1 = () => {
            const { result, dencrypt } = useDencrypt();

            React.useEffect(() => {
                let i = 0;

                const action = setInterval(() => {
                    dencrypt(values1[i]);

                    i = i === values1.length - 1 ? 0 : i + 1;
                }, 500);

                return () => clearInterval(action);
            });

            return (
                <div>
                    {(doneDec) ? (
                        <div style={{
                            position: 'absolute',
                            bottom: 0,
                            height: '150px'
                        }}>

                            <Typography component={'div'} variant="body1" color="primary">
                                <Box fontWeight={1000}>Build to Last</Box>
                            </Typography>

                        </div>
                    ) : (
                        <div style={{
                            position: 'absolute',
                            bottom: 0,
                            height: '150px'
                        }}>
                            <Typography component={'div'} variant="body1" color="primary">
                                <Box fontWeight={1000}>{result}</Box>
                            </Typography>

                        </div>
                    )}
                </div>

            )
        };

        const Load = () => {
            const { result, dencrypt } = useDencrypt();

            React.useEffect(() => {
                let i = 0;

                const action = setInterval(() => {
                    dencrypt(values[i]);

                    i = i === values.length - 1 ? 0 : i + 1;
                }, 500);

                return () => {
                    clearInterval(action)
                };
            });

            setInterval(() => {
                // console.log('cwece')
                if (result === 'Balfour Beatty' && doneApi && doneAccess) {
                    doneDec = true;
                    // console.log('cwece')
                    this.goOn();
                }
            }, confetti() ? 2500 : 1500);
            // if (result === 'Balfour Beatty' && doneApi && doneAccess) {
            //     doneDec = true;
            //     console.log('cwece')
            //     this.goOn();
            // }

            return (
                <div>
                    {(doneDec) ? (
                        <div style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            height: '200px'
                        }}>
                            <Box display="flex" justifyContent="center" m={1} p={1} >
                                <Typography variant="h4" color="primary">
                                    <Box fontWeight={1000}>Balfour Beatty</Box>
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" >
                                <Load1 />
                            </Box>
                        </div>
                    ) : (
                        <div style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            height: '200px'
                        }}>
                            <Box display="flex" justifyContent="center" m={1} p={1} >
                                <Typography variant="h4" color="primary">
                                    <Box fontWeight={1000}>{result}</Box>
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" >
                                <Load1 />
                            </Box>

                        </div>
                    )}
                    <div style={{
                        // backgroundColor: 'transparent',
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        marginBottom: '1%'
                    }}>
                    </div>
                </div>

            )
        };

        return (

            <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Box p={1} display="flex" justifyContent="center">
                        <img style={{ marginBottom: '200px', maxWidth: '50%', alignContent: 'center' }} alt={'Balfour Beatty'} src={'https://assets.bbparam.com/images/param_logo.gif'} />
                    </Box>

                </div>
                <div style={{ width: "100%", height: "100%" }}>
                    <Load />
                </div>
            </>
        )
    }
}

export default withSnackbar(Loader)