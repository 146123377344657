import React from 'react';
import MaterialTable from 'material-table';
import { errorMessage } from '../Helper/ErrorMessage';
import Service from '../Networking/networkutils';
import { getCookie } from '../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import { Link } from '@material-ui/core';
import AddRoleUser from './AddUser';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));


// function addZeroes(num) {
//     const dec = num.toString().split('.')[1]
//     const len = dec ? 1 : null
//     if (len) {
//         return parseFloat(Number(num).toFixed(len))
//     } else {
//         return parseFloat(Number(num))
//     }
// }


// function timeToDecimal(t) {
//     try {
//         var arr = t.split(':');
//         var dec = parseInt((arr[1] / 6) * 10, 10);

//         return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
//     } catch (error) {
//         return 0
//     }

// }
var current_team_users = []

class TaskLevelRoleUsers extends React.Component {
    constructor() {
        super()
        this.state = {
            columns: [],
            data: [],
            addUserComp: false,
            isLoading: true,
            tableLength: 0,
            taskUsers: null
        }
    }

    componentDidMount() {
       this.initSetUp(this.props.taskUsers)
    }

    initSetUp(taskUsers) {
        if (taskUsers) {
            this.setState({taskUsers: taskUsers}, () => {
                console.log(this.state.taskUsers)
                let currentRoleUsers = [];
                for (let i = 0; i < this.state.taskUsers.length; i++) {
                    if (this.state.taskUsers[i].task_role_id === this.props.role.id) {
                        currentRoleUsers.push(this.state.taskUsers[i])
                    }
                }
                for (let i = 0; i < this.props.allUsers.length; i++) {
                    const element = this.props.allUsers[i];
                    current_team_users.push(element.id)
                }
                // let allTeamUsers = this.props.allUsers;
                // let teamUsersObj = {}
                // for (let i = 0; i < allTeamUsers.length; i++) {
                //     teamUsersObj = { ...teamUsersObj, ...{ [allTeamUsers[i].id]: allTeamUsers[i].first_name + ' ' + allTeamUsers[i].last_name } }
                // }
                // console.log(currentRoleUsers)
                this.setState({ data: currentRoleUsers, tableLength: currentRoleUsers.length }, () => this.setState({ isLoading: false }))
                // console.log(this.props);
                // let users = this.props.task.users;
                // let uid = []
                // for (let i = 0; i < users.length; i++) {
                //     uid.push(users[i].id)
                // }
        
                // Service.get('/bb/api/tender/user/task/get_user_task/?tid=' + this.props.task.id + '&uid=' + uid.join(','), {
                //     headers: {
                //         Authorization: "Token " + token,
                //     },
                // })
                //     .then(res => {
        
                //         let currentRoleUsers = [];
        
                //         for (let i = 0; i < res.data.length; i++) {
                //             if (res.data[i].task_role_id === this.props.role.id) {
                //                 currentRoleUsers.push(res.data[i])
                //             }
                //         }
                //         this.setState({ data: currentRoleUsers, isLoading: false, isLoadingTable: false, }, () => {
        
                //             var viewPid = []
                //             for (let i1 = 0; i1 < currentRoleUsers.length; i1++) {
                //                 const element = currentRoleUsers[i1];
                //                 viewPid.push({ 'com_ttid': this.props.task.id, 'com_uid': element.user_id })
                //             }
                //             if (viewPid.length !== 0) {
                //                 Service.patch("/bb/api/team/tasks/get_acthours/", {
                //                     headers: {
                //                         Authorization: "Token " + token,
                //                     },
                //                     data: viewPid,
                //                 })
                //                     .then(res12 => {
        
                //                         for (let j = 0; j < res12.data.length; j++) {
                //                             const element13 = res12.data[j];
                //                             for (let i = 0; i < currentRoleUsers.length; i++) {
                //                                 const element12 = currentRoleUsers[i];
                //                                 if (element12.user_id === element13.id2) {
                //                                     element12.actual_hours = timeToDecimal(element13.hours_actual)
                //                                     element12.inprogress_hours = timeToDecimal(element13.hours_ongoing)
                //                                     element12.total_alloc_hours = element13.allocated
        
                //                                 }
                //                             }
        
                //                         }
                //                         this.setState({ data: currentRoleUsers })
                //                     })
                //                     .catch(error => {
                //                         this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                //                             variant: 'error'
                //                         });
        
                //                     });
                //             }
        
                //         })
                //     })
                //     .catch(error => {
                //         errorMessage(this.props, error)
                //     });
            })
        }
    }

    givePlanned(data, asdata) {
        var tot_planned = 0
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (asdata.task_role_id === element.task_role_id && asdata.id !== element.id) {
                tot_planned = tot_planned + element.planed_hours
            }
        }
        return tot_planned + asdata.planed_hours
    }

    putData(asdata) {

        this.setState({
            isLoading: true,
        })

        Service.put('/bb/api/tender/user/task/' + asdata.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: asdata,
        })
            .then(res => {
                this.setState({ isLoading: false, openAdd: false })
                this.props.enqueueSnackbar('Edited Successfully!', {
                    variant: 'success'
                });
                this.props.fetchData(this.props.task, this.props.allUsers)
                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
                this.setState({ isLoading: false, openAdd: false })
                if (error && error.status === 416) {
                    this.props.initRequestHours(this.props.role, error.data)
                    return null
                } else if (error && error.status === 406) {
                    this.props.enqueueSnackbar(error.data, {
                        variant: 'warning'
                    });
                } else {
                    errorMessage(this.props, error)
                }
                this.initSetUp(this.state.taskUsers)
                this.props.fetchData(this.props.task, this.props.allUsers)
            });

    }

    addUser = () => {
        this.setState({ addUserComp: true })
    }

    hideAddUser = () => {
        this.setState({ addUserComp: false })
    }

    save = (selectedUser, hrs) => {
        // console.log(selectedUser, hrs)
        // this.putData({ user_id: user.id, planed_hours: Number(hrs), id : '', task_role_id : this.props.role.id })
        let userExist = false
        for (let i = 0; i < this.state.data.length; i++) {
            if (selectedUser.id === this.state.data[i].user_id) {
                this.props.enqueueSnackbar('User already added for this role.', {
                    variant: 'warning'
                });
                userExist = true
                break;
            }
        }
        if (userExist) return;

        Service.get('/bb/api/tender/user/task/?trid=' + this.props.role.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res1 => {

                try {
                    var postData = {
                        task_id: this.props.task.id,
                        role_id: this.props.role.t_role,
                        task_role_id: this.props.role.id,
                        wbs_id: this.props.role.t_wbs,
                        t_pid: this.props.role.t_pid,
                        user_id: selectedUser.id,
                        user_email: selectedUser.email,
                        planed_hours: hrs > 0 ? hrs : 0,
                        created_by: user.email,
                    }


                    // console.log(this.givePlanned(res1.data, postData), this.props.role.req_hours)

                    if (this.givePlanned(res1.data, postData) <= ((res1.data.length === 0) ? this.props.role.rev_hours : res1.data[0].tasks_roles_data.hours + res1.data[0].tasks_roles_data.req_hours)) {

                        Service.post('/bb/api/tender/user/task/user_task_allocation/', {
                            headers: {
                                Authorization: "Token " + token,
                            },
                            data: postData,
                        })
                            .then(res => {
                                // waitData = false;
                                // var data = this.state.data;
                                // data.push(res.data);
                                // // autocompdata = '';
                                // this.setState({ data: data, isLoading: false, openAdd: false })
                                this.props.enqueueSnackbar('Data Added Successfully!', {
                                    variant: 'success'
                                });
                                this.props.fetchData(this.props.task, this.props.allUsers, res.data)
                                // this.fetchOps();
                                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
                            })
                            .catch(error => {
                                // waitData = false;
                                errorMessage(this.props, error)
                                this.setState({ isLoading: false })
                            });

                    } else {
                        this.props.fetchData(this.props.task, this.props.allUsers)
                        this.setState({ isLoading: false })
                        // this.fetchOps();
                        this.props.enqueueSnackbar('You are trying to allocate more hours than available hours for this role.', {
                            variant: 'warning',
                            autoHideDuration: 5000,

                        });
                    }
                }
                catch (e) {
                    console.log(e)
                }

            })
            .catch(error => {
                // this.fetchOps();
                this.initSetUp(this.state.taskUsers)
                errorMessage(this.props, error)
                this.setState({ isLoading: false })
            });


    }

    checkEditable(data) {
        return false
    }

    deleteData(asdata) {
        this.setState({ isLoading: true })
        Service.delete('/bb/api/tender/user/task/' + asdata.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Deleted Successfully!', {
                    variant: 'success'
                });
                this.props.fetchData(this.props.task, this.props.allUsers)
                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
                errorMessage(this.props, error)
                this.initSetUp(this.state.taskUsers)
                this.props.fetchData(this.props.task, this.props.allUsers)
            });
    }

    render() {
        return (
            <div style={{ margin: "5px", boxSizing: 'border-box', border: '2px solid orange' }}>

                <MaterialTable
                    // title="Cell Editable Preview"
                    columns={[
                        {
                            title: 'Name', field: 'user_id',
                            editable: 'never',
                            render: rowdata => rowdata.first_name + ' ' + rowdata.last_name,
                        },
                        {
                            title: 'Alloc Hrs', field: 'planed_hours', type: 'numeric', editable: (column, rowData) => { return this.props.allUid.includes(rowData.user_id) },
                            cellStyle: {
                                textAlign: "left"
                            },

                        },
                        { title: 'Bal.(In Task)', field: 'balance_hours', editable: 'never' },
                        {
                            title: 'Alloc Cost.(In Task)', field: 'allocation_cost', editable: 'never',
                            render: rowData =>  new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.allocation_cost)
                        },
                    ]}
                    data={this.state.data}
                    editable={{
                        isEditable: rowData => this.props.allUid.includes(rowData.user_id),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    // newData.hours = newData.hours;
                                    // this.setState({ saved: false, })
                                    this.setState(prevState => {

                                        const data = [...prevState.data];
                                        // const oldInfo = JSON.parse(JSON.stringify(oldData))
                                        data[data.indexOf(oldData)] = newData;
                                        this.putData(newData)
                                        return { ...prevState, data };
                                    });
                                }, 600);
                            }),

                    }}
                    actions={[
                        rowData => ({
                            icon: 'cancel',
                            tooltip: (rowData.id && this.props.allUid.includes(rowData.user_id) && rowData.planed_hours === 0) ? "Delete" : "You can delete only when the Allocated Hours in 0.",
                            disabled: !(rowData.id && this.props.allUid.includes(rowData.user_id) && rowData.planed_hours === 0),
                            onClick: (event, rowData) => { this.deleteData(rowData); },
                        }),
                    ]}
                    isLoading={this.state.isLoading}
                    options={{
                        showTitle: false,
                        padding: 'dense',
                        paging: false,
                        toolbar: false,
                        // pageSize: this.state.tableLength,
                        headerStyle: {
                            textAlign: 'left',
                            fontSize: '12px',
                            padding: '10px',

                        },
                        rowStyle: rowData => {
                            return {
                                fontSize: '12px',
                                padding: '10px',
                            };
                        },

                    }}
                    // components={{
                    //     Pagination: props => (
                    //         this.state.addUserComp ? <AddRoleUser allUsers={this.props.allUsers} hide={this.hideAddUser} save={this.save} /> :
                    //             <Link
                    //                 component="button"
                    //                 variant="body2"
                    //                 onClick={this.addUser}
                    //                 style={{ marginLeft: '18px', fontSize: 'small' }}
                    //             >
                    //                 Add User
                    //             </Link>
                    //     )
                    // }}
                />
                {this.state.addUserComp ? <AddRoleUser allUsers={this.props.allUsers} hide={this.hideAddUser} save={this.save} /> :
                    <Link
                        component="button"
                        variant="body2"
                        onClick={this.addUser}
                        style={{ marginLeft: '18px', marginTop: '3px', marginBottom: '10px' }}
                    >
                        Add User
                    </Link>}

            </div>
        )
    }
}

export default withSnackbar(TaskLevelRoleUsers)