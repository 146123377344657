
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react'
import { sortByProperty } from '../../Helper/CommonThings';
import { getCookie } from '../../Helper/CookieHelper';
import Service from "../../Networking/networkutils";
import WBSListControl from './WBSListControl';
import { errorMessage } from '../../Helper/ErrorMessage';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DDMStepper from './DDMStepper/DDMStepper';
import { BootstrapDialogTitleV4 } from '../../Helper/DialogTitleM5';
import AddDDM from './DDMStepper/AddDDM';
import { DialogContentText } from '@material-ui/core';
import { Alert } from '@mui/material';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class TenderPDM extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            users: [],
            data: [],
            stepperDialog: false,
            usersLookup: {},
            maxWidth: 'md',
            selectedDDM: null,
            editDialog: false,
            // isLoading: true,
        }
        this.closeStepperDialog = this.closeStepperDialog.bind(this)
        this.getDialogWidth = this.getDialogWidth.bind(this)
        this.updateDDM = this.updateDDM.bind(this)
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        this.setState({ isLoading: true });
        Service.get('/bb/api/tender/pdm/?pid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(respro => {

                for (let i = 0; i < respro.data.length; i++) {
                    const element = respro.data[i];
                    element.uid_mod = "id" + element.uid
                    if (element.frwd_uid) {
                        element.frwd_uid_mod = "id" + element.frwd_uid
                    } else {
                        element.frwd_uid_mod = element.frwd_uid
                    }

                }

                Service.get("/bb/api/users/", {
                    headers: {
                        Authorization: "Token " + token,
                    },

                })
                    .then(res => {
                        var responceData = res.data.sort(sortByProperty('last_name'))
                        var users = []
                        var usersLookup = { null: 'None' }
                        for (let i = 0; i < responceData.length; i++) {
                            const element = responceData[i];
                            usersLookup[element.id] = element.last_name + ", " + element.first_name
                            users.push({ name: element.last_name + ", " + element.first_name, id: element.id })
                        }
                        users.splice(0, 0, { name: 'None', id: null })
                        // console.log(users)
                        // console.log(respro.data)
                        this.setState({
                            users: users,
                            data: respro.data,
                            isLoading: false,
                            usersLookup: usersLookup
                        })
                    })
                    .catch(error => {
                        // console.log(error)
                        errorMessage(this.props, error)
                    });

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    postData(postData) {
        // console.log(postData.uid_mod.split('d')[1])
        if (!postData.pdm_type || !postData.uid_mod || postData.uid_mod === '' || postData.uid_mod === undefined) {
            this.props.enqueueSnackbar('Fill EM type and EM!', {
                variant: 'warning'
            });
        } else {
            postData.uid = parseInt(postData.uid_mod.split('d')[1])

            postData.created_by = user.id;
            postData.pid = this.props.value.id
            // console.log(postData.frwd_uid_mod)
            if (!postData.frwd_uid_mod || postData.frwd_uid_mod === undefined || postData.frwd_uid_mod === '' || postData.frwd_uid_mod === 'null') {
                postData.frwd_uid = null
            } else {
                postData.frwd_uid = parseInt(postData.frwd_uid_mod.split('d')[1])
            }
            Service.post('/bb/api/tender/pdm/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData
            })
                .then(res => {
                    this.fetchOps();
                    this.props.enqueueSnackbar('Data saved successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    this.fetchOps();
                    errorMessage(this.props, error)
                });
        }

    }

    putData(putData) {

        putData.uid = parseInt(putData.uid_mod.split('d')[1])

        if (!putData.frwd_uid_mod || putData.frwd_uid_mod === undefined || putData.frwd_uid_mod === '' || putData.frwd_uid_mod === 'null') {
            putData.frwd_uid = null
        } else {
            putData.frwd_uid = parseInt(putData.frwd_uid_mod.split('d')[1])
        }

        Service.put('/bb/api/tender/pdm/' + putData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: putData
        })
            .then(res => {
                this.props.enqueueSnackbar('Data saved successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                this.fetchOps();
                errorMessage(this.props, error)
            });
    }

    deleteData(putData) {
        // putData.uid = parseInt(putData.uid_mod.split('d')[1])
        // putData.frwd_uid = parseInt(putData.frwd_uid_mod.split('d')[1])
        Service.delete('/bb/api/tender/pdm/' + putData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.props.enqueueSnackbar('Data deleted successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                this.fetchOps();
                this.props.enqueueSnackbar('Make sure that all the WBS access is removed before deleting the DDM!', {
                    variant: 'warning'
                });
            });
    }

    closeStepperDialog() {
        this.setState({ stepperDialog: false });
        this.fetchOps();
    }

    closeEditDialog() {
        this.setState({ editDialog: false, selectedDDM: null });
    }

    getDialogWidth(width) {
        this.setState({ maxWidth: width });
    }

    updateDDM() {
        this.fetchOps();
        this.setState({ editDialog: false });
    }

    render() {

        return (
            <div>
                <MaterialTable
                    title={(this.props.value.tender_name ? this.props.value.tender_name : this.props.value.name)}
                    columns={[
                        {
                            title: 'Discipline', field: 'pdm_type',
                            lookup: { 'all_type': 'All', 'ohl': 'OHL', 'cabling': 'Cabling', 'sub_struc': 'Substation Primary', 'ohl_civ_struc': 'Substation Secondary', 'sub_civ_struct': 'Substation Civ Structure', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical', 'edc': 'EDC' }
                        },
                        {
                            title: 'Manager', field: 'uid',
                            lookup: this.state.usersLookup
                        },
                        {
                            title: 'Allocation Access', field: 'allocation_access',
                            lookup: { 0: 'Not Allowed', 1: 'All discipline tasks', 2: 'All tasks of the project', 3: `Specific WBS's` }
                        },
                        {
                            title: 'Timesheet Approval Access', field: 'timesheet_access',
                            lookup: { 0: 'No', 1: 'Yes (Manager timesheet to EM)' }
                        },
                        {
                            title: 'Timesheet Delegate', field: 'frwd_uid',
                            lookup: this.state.usersLookup
                        },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        paging: false,
                        padding: "dense",
                        actionsColumnIndex: -1,
                        search: false,
                        // filtering: true
                    }}
                    detailPanel={(rowData) => rowData.allocation_access === 3 ? <WBSListControl value={this.props.value} ddm={rowData} /> : null}
                    onRowClick={(event, rowData, togglePanel) => (rowData.allocation_access === 3) ? togglePanel() : null}
                    actions={[
                        {
                            icon: 'add',
                            tooltip: 'Add Project Access',
                            isFreeAction: true,
                            onClick: (event) => {
                                this.setState({ stepperDialog: true });
                            }
                        }, {
                            icon: 'edit',
                            tooltip: 'Edit Project Access',
                            onClick: (event, rowData) => {
                                this.setState({ selectedDDM: rowData }, () => {
                                    this.setState({ editDialog: true });
                                });
                            }
                        }
                    ]}
                    editable={{
                        // onRowAdd: newData =>
                        //     new Promise(resolve => {
                        //         setTimeout(() => {
                        //             resolve();
                        //             if (!newData.pdm_type || !newData.uid_mod || newData.uid_mod === '' || newData.uid_mod === undefined) {
                        //                 this.props.enqueueSnackbar('Fill EM type and EM!', {
                        //                     variant: 'warning'
                        //                 });
                        //             } else {
                        //                 this.setState({ save: false });
                        //                 this.setState(prevState => {
                        //                     const data = [...prevState.data];
                        //                     newData.roles = [];
                        //                     data.push(newData);
                        //                     this.postData(newData);
                        //                     return { ...prevState, data };
                        //                 });
                        //             }

                        //         }, 60);
                        //     }),
                        // onRowUpdate: (newData, oldData) =>
                        //     new Promise(resolve => {
                        //         setTimeout(() => {
                        //             resolve();
                        //             if (oldData && oldData.pdm_type === newData.pdm_type) {
                        //                 this.setState({ save: false });
                        //                 this.setState(prevState => {
                        //                     const data = [...prevState.data];
                        //                     data[data.indexOf(oldData)] = newData;
                        //                     this.putData(newData);
                        //                     return { ...prevState, data };
                        //                 });
                        //             } else {
                        //                 this.props.enqueueSnackbar('PDM should not be edited!', {
                        //                     variant: 'warning'
                        //                 });
                        //             }
                        //         }, 60);

                        //     }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState({ save: false });
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        this.deleteData(oldData)
                                        return { ...prevState, data };
                                    });
                                }, 60);
                            }),
                    }}
                />

                <Dialog maxWidth={this.state.maxWidth} open={this.state.stepperDialog} aria-labelledby="form-dialog-title">
                    <BootstrapDialogTitleV4 id="form-dialog-title" onClose={() => { this.closeStepperDialog() }}>Setup Project Access</BootstrapDialogTitleV4>
                    <DialogContentText>
                        <Alert severity="info">Use separate form to setup different project access for each discipline.</Alert>
                    </DialogContentText>
                    <DialogContent>
                        <DDMStepper
                            pid={this.props.value.id}
                            value={this.props.value}
                            users={this.state.users}
                            onClose={this.closeStepperDialog}
                            getDialogWidth={this.getDialogWidth}
                            usersLookup={this.state.usersLookup}
                        />
                    </DialogContent>
                </Dialog>

                <Dialog maxWidth={'md'} open={this.state.editDialog} aria-labelledby="form-dialog-title">
                    <BootstrapDialogTitleV4 id="form-dialog-title" onClose={() => { this.closeEditDialog() }}>Edit Project Access</BootstrapDialogTitleV4>
                    <DialogContent>
                        <AddDDM
                            pid={this.props.value.id}
                            users={this.state.users}
                            updateDDM={this.updateDDM}
                            value={this.props.value}
                            type='edit'
                            selectedDDM={this.state.selectedDDM}
                            usersLookup={this.state.usersLookup}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(TenderPDM);