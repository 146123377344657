import React, { Component } from 'react'
// import AppBar from '@mui/material/AppBar';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
// import PropTypes from 'prop-types';
import AdditianalHoursManage from './AdditianalHoursManage';
// import AllocationRequest from './AllocationRequest';
import Service from '../Networking/networkutils';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import { withSnackbar } from 'notistack';

const token = getCookie("bb_ts_token");

// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`full-width-tabpanel-${index}`}
//             aria-labelledby={`full-width-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box sx={{ p: 3 }}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// }

// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//     return {
//         id: `full-width-tab-${index}`,
//         'aria-controls': `full-width-tabpanel-${index}`,
//     };
// }

class AdditionalHrsTabs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0,
            hours_count: this.props.count.pending_hrs_request,
            alloc_req_count: this.props.count.pending_task_allocation_request
        }
        this.updateCount = this.updateCount.bind(this)
    }

    updateCount() {
        if (this.props.value) {
            var url = `/bb/api/estimate/date/request/get_pending_count/?&tender_id=${this.props.value}`
            Service.get(url, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({
                        hours_count: res.data.pending_hrs_request,
                        alloc_req_count: res.data.pending_task_allocation_request
                    })
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    render() {

        // const handleChange = (event, newValue) => {
        //     this.setState({ value: newValue })
        // };

        return (
            <div>
                {/* <AppBar position="static">
                    <Tabs
                        value={this.state.value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Additional Request" icon={<Typography>{this.state.hours_count === 0 ? "" : this.state.hours_count}</Typography>} {...a11yProps(0)} />
                        <Tab label="Allocation Request" icon={<Typography>{this.state.alloc_req_count === 0 ? "" : this.state.alloc_req_count}</Typography>}  {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.value} index={0} > */}
                    <AdditianalHoursManage value={this.props.value} updateCount={this.updateCount} />
                {/* </TabPanel>
                <TabPanel value={this.state.value} index={1} >
                    <AllocationRequest value={this.props.value} updateCount={this.updateCount} />
                </TabPanel> */}
            </div >
        )
    }
}

export default withSnackbar(AdditionalHrsTabs)

//<Tab label="Additional Request" icon={<Badge anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={this.props.count.pending_hrs_request} color="secondary"></Badge>} {...a11yProps(0)} />
//y<Tab label="Allocation Request" icon={<Badge anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={this.props.count.pending_task_allocation_request} color="secondary"></Badge>}  {...a11yProps(1)} />