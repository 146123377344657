import { Badge, Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Container, InputAdornment, TextField, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import React, { Component } from 'react'
// import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
// import InputBase from '@mui/material/InputBase';
// import { getMode } from '../../../ColorTheme/ColorTheme';
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../../Helper/CommonThings';
import { screenSizeCheck } from '../../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import OutofWorkCardList from './OutofWorkCardList';

// const token = getCookie("bb_ts_token");

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -17,
        top: screenSizeCheck() ? 6 : 9,
        backgroundColor: '#66b6f3'
    },
}));

const StartSoonBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -17,
        top: screenSizeCheck() ? 6 : 9,
        backgroundColor: '#FFF176'
    },
}));

const ReadtToStartBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -17,
        top: screenSizeCheck() ? 6 : 9,
        backgroundColor: '#DCE775'
    },
}));

const LaterDueBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -17,
        top: screenSizeCheck() ? 6 : 9,
        backgroundColor: '#da8bc5'
    },
}));

const SoonDueBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -17,
        top: screenSizeCheck() ? 6 : 9,
        backgroundColor: '#FFB74D'
    },
}));

const OverDueBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -17,
        top: screenSizeCheck() ? 6 : 9,
        backgroundColor: '#EF9A9A'
    },
}));

const OtherBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -17,
        top: screenSizeCheck() ? 6 : 9,
        backgroundColor: '#666666'
    },
}));




var searchInput = ""

class OutofWokTaskCards extends Component {
    constructor(props) {
        super(props)

        this.state = {
            taskstatus: -1,
            isLoading: false,
            data: this.props.data,
            allTask: 0,
            startSoon: 0,
            readyToStart: 0,
            soonDue: 0,
            overDue: 0,
            laterDue: 0,
            others: 0,
            emptyStatus: 'this selection',
            allData: [],
            childHeight: null,
        }
        this.child = React.createRef();
        // this.boxHeight = this.boxHeight.bind(this)
        this.childHeight = this.childHeight.bind(this)
        this.handleDataChange = this.handleDataChange.bind(this)
        this.refreshData = this.refreshData.bind(this)
    }

    componentDidMount() {
        searchInput = ''
        this.fetchData()
    }

    fetchData() {
        this.setState({ data: this.props.data, allData: this.props.data }, () => { this.handleData(this.state.allData) })
    }

    handleData(datas) {
        var startSoon = []
        var readyToStart = []
        var soonDue = []
        var overDue = []
        var laterDue = []
        var others = []

        for (let i = 0; i < datas.length; i++) {
            const element = datas[i];
            if (element.task_stand === 1) {
                startSoon.push(element)
            } else if (element.task_stand === 2) {
                readyToStart.push(element)
            }
            else if (element.task_stand === 3) {
                soonDue.push(element)
            }
            else if (element.task_stand === 4) {
                overDue.push(element)
            }
            else if (element.task_stand === 6) {
                laterDue.push(element)
            } else if (element.task_stand === 0) {
                others.push(element)
            }
        }
        this.setState({ allTask: datas.length, startSoon: startSoon.length, readyToStart: readyToStart.length, soonDue: soonDue.length, overDue: overDue.length, laterDue: laterDue.length, others: others.length, isLoading: false })
        this.handleDataChange(this.state.taskstatus)
    }

    handleDataChange(data) {
        if (data === -1) {
            var datasAll = this.state.allData;
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datasAll.length === 0 ? 'all tasks' : null, isLoading: false, data: datasAll, taskstatus: data })
            })
        } else if (data === 1) {
            var datas = [];
            for (let i = 0; i < this.state.allData.length; i++) {
                const element = this.state.allData[i];
                if (element.task_stand === 1) {
                    datas.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas.length === 0 ? 'Start Soon' : null, isLoading: false, data: datas, taskstatus: data })
            })
        } else if (data === 2) {

            var datas1 = [];
            for (let i = 0; i < this.state.allData.length; i++) {
                const element = this.state.allData[i];
                if (element.task_stand === 2) {
                    datas1.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas1.length === 0 ? 'Ready To Start' : null, isLoading: false, data: datas1, taskstatus: data })
            })

        } else if (data === 3) {

            var datas2 = [];
            for (let i = 0; i < this.state.allData.length; i++) {
                const element = this.state.allData[i];
                if (element.task_stand === 3) {
                    datas2.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas2.length === 0 ? 'Soon Due' : null, isLoading: false, data: datas2, taskstatus: data })
            })
        } else if (data === 4) {
            var datas3 = [];
            for (let i = 0; i < this.state.allData.length; i++) {
                const element = this.state.allData[i];
                if (element.task_stand === 4) {
                    datas3.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas3.length === 0 ? 'Over Due' : null, isLoading: false, data: datas3, taskstatus: data })
            })

        } else if (data === 6) {

            var datas4 = [];
            for (let i = 0; i < this.state.allData.length; i++) {
                const element = this.state.allData[i];
                if (element.task_stand === 6) {
                    datas4.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas4.length === 0 ? 'Later Due' : null, isLoading: false, data: datas4, taskstatus: data })
            })

        }
        else if (data === 0) {
            var datas0 = [];
            for (let i = 0; i < this.state.allData.length; i++) {
                const element = this.state.allData[i];
                if (element.task_stand === 0) {
                    datas0.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas0.length === 0 ? 'Others' : null, isLoading: false, data: datas0, taskstatus: data })
            })
        }
    }

    refreshData() {
        this.fetchData(this.state.currentProj, this.state.filter_type)
    }

    handleSearchChange(data) {
        searchInput = data
        if (this.child && this.child.current) {
            var filtered_data = this.child.current.taskSearch(searchInput)
            this.handleData(filtered_data)
        }
    }

    childHeight(height) {
        this.setState({ childHeight: height })
    }

    downloadExcel() {
        if (this.child && this.child.current) {
            this.child.current.openTaskDownload()
        }
    }

    render() {

        const handleChange = (event) => {
            this.setState({ taskstatus: event.target.value });
            this.handleDataChange(event.target.value)
        };

        return (
            <Container maxWidth={false} disableGutters>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <FormControl variant="outlined" style={{ minWidth: 180, marginRight: '15px' }} size="small">
                                <InputLabel id="demo-simple-select-outlined-label">Task Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.taskstatus}
                                    onChange={handleChange}
                                    label="Task Status"
                                >
                                    <MenuItem value={-1}><FilterMenuItem badgeCount={this.state.allTask} statusName="To Do" styleComponent={StyledBadge} /></MenuItem>
                                    <MenuItem value={1}><FilterMenuItem badgeCount={this.state.startSoon} statusName="Start Soon" styleComponent={StartSoonBadge} /></MenuItem>
                                    <MenuItem value={2}><FilterMenuItem badgeCount={this.state.readyToStart} statusName="Ready to Start" styleComponent={ReadtToStartBadge} /></MenuItem>
                                    <MenuItem value={6}><FilterMenuItem badgeCount={this.state.laterDue} statusName="Later Due" styleComponent={LaterDueBadge} /></MenuItem>
                                    <MenuItem value={3}><FilterMenuItem badgeCount={this.state.soonDue} statusName="Soon Due" styleComponent={SoonDueBadge} /></MenuItem>
                                    <MenuItem value={4}><FilterMenuItem badgeCount={this.state.overDue} statusName="Over Due" styleComponent={OverDueBadge} /></MenuItem>
                                    <MenuItem value={0}><FilterMenuItem badgeCount={this.state.others} statusName="Others" styleComponent={OtherBadge} /></MenuItem>
                                </Select>
                            </FormControl>
                            <Tooltip placement="top" title={<div>You can use multiple keyword searches<br />Ex: David OHL Wireclearance</div>}>
                                <TextField
                                    autoComplete='off'
                                    autoFocus
                                    size='small'
                                    placeholder="Search"
                                    id="search"
                                    onChange={(e) => this.handleSearchChange(e.target.value)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                    }}
                                />
                            </Tooltip>
                            {/* <Box sx={{ display: "flex", justifyContent: 'flex-start' }}>
                            <Tooltip title='Download'>
                                <IconButton size='small' onClick={() => {
                                    if (this.state.data.length !== 0) {
                                        this.downloadExcel()
                                    } else {
                                        this.props.enqueueSnackbar('No Tasks to download!', {
                                            variant: 'warning'
                                        });
                                    }
                                }}>
                                    <SaveAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Box> */}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
                        {
                            this.state.isLoading ?
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 1, height: this.state.boxHeight }}>
                                    <LoopCircleLoading color={getPrimaryColor()} />
                                </Box>
                                :
                                this.state.data.length === 0 ?
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 1, fontWeight: 'bold', height: this.state.childHeight ? this.state.childHeight : 250 }}>
                                        No Tasks in {this.state.emptyStatus}
                                    </Box> :
                                    <div style={{ marginTop: '10px', width: '100%' }}>
                                        <Container maxWidth={false} disableGutters>
                                            <OutofWorkCardList
                                                ref={this.child}
                                                data={this.state.data ? this.state.data : []}
                                                handleChange={this.handleDataChange}
                                                refreshData={this.refreshData}
                                                presentSearch={searchInput}
                                                childHeight={this.childHeight}
                                                maxCards={this.props.maxCards} />
                                        </Container>
                                    </div>
                        }
                    </Box>
                </Box>
            </Container>
        )
    }
}

function FilterMenuItem(props) {
    var StyledBadge = props.styleComponent
    return <Box>
        <StyledBadge badgeContent={props.badgeCount} color="primary" max={999} >
            <Box fontSize={14}>{props.statusName}</Box>
        </StyledBadge>
    </Box>
}

export default withSnackbar(OutofWokTaskCards);