import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import MaterialTable from 'material-table'

const token = getCookie("bb_ts_token");
// const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class FutureBudgetHours extends Component {

    constructor(props) {
        super(props)

        this.state = {
            budgetHours: [],
            budgetHoursOriginal: [],
        }
    }

    componentDidMount() {
        this.fetchOpsHours()

    }

    fetchOpsHours(year) {
        var budgetHours = {}
        for (let i = 0; i < this.props.data.length; i++) {
            const element = this.props.data[i];
            budgetHours[element.month.toString()] = element.expected_hours
        }
        this.setState({ budgetHours: budgetHours, budgetHoursOriginal: this.props.data })
    }

    processPostData(postData) {
        var post_data = []
        var put_data = []
        for (let i = 1; i <= 12; i++) {
            var is_exist_in_db = false
            var exist_data = null
            for (let j = 0; j < this.state.budgetHoursOriginal.length; j++) {
                const element1 = this.state.budgetHoursOriginal[j];
                if (element1.month === i && element1.year === this.props.BUDGETING_YEAR) {
                    is_exist_in_db = true
                    exist_data = element1
                }
            }
            // console.log(postData, i.toString())
            if (postData[i.toString()] === 0 || postData[i.toString()]) {
                if (is_exist_in_db) {
                    put_data.push({
                        id: exist_data.id,
                        expected_hours: postData[i.toString()],
                        updated_by: user.id
                    })
                } else {
                    post_data.push({
                        month: i,
                        year: this.props.BUDGETING_YEAR,
                        office_loc: 1,
                        expected_hours: postData[i.toString()],
                        updated_by: user.id,
                        created_by: user.id
                    })
                }
            }
        }
        return {
            post_data: post_data,
            put_data: put_data
        }
    }

    postData(postData) {
        var preparedData = this.processPostData(postData)
        // console.log(preparedData)
        if (preparedData.post_data.length > 0) {
            Service.post(`/bb/api/budget/future/hours/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: preparedData.post_data
            })
                .then(res => {
                    this.setState({
                        budgetHoursOriginal: [...this.state.budgetHoursOriginal, ...res.data]
                    })
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });

        }
        if (preparedData.put_data.length > 0) {
            Service.patch(`/bb/api/budget/future/hours/bulk_update/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: preparedData.put_data
            })
                .then(res => {

                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });

        }
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title="Monthly Hours Availability"
                    columns={[
                        {
                            title: "Jan",
                            field: "1",
                            type: "numeric"
                        },
                        {
                            title: "Feb",
                            field: "2",
                            type: "numeric"
                        },
                        {
                            title: "Mar",
                            field: "3",
                            type: "numeric"
                        },
                        {
                            title: "Apr",
                            field: "4",
                            type: "numeric"
                        },
                        {
                            title: "May",
                            field: "5",
                            type: "numeric"
                        },
                        {
                            title: "Jun",
                            field: "6",
                            type: "numeric"
                        },
                        {
                            title: "Jul",
                            field: "7",
                            type: "numeric"
                        },
                        {
                            title: "Aug",
                            field: "8",
                            type: "numeric"
                        },
                        {
                            title: "Sep",
                            field: "9",
                            type: "numeric"
                        },
                        {
                            title: "Oct",
                            field: '10',
                            type: "numeric"
                        },
                        {
                            title: "Nov",
                            field: "11",
                            type: "numeric"
                        },
                        {
                            title: "Dec",
                            field: "12",
                            type: "numeric"
                        }
                    ]}
                    data={[this.state.budgetHours]}
                    options={{
                        padding: "dense",
                        paging: false,
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                this.postData(newData)
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        this.setState(prevState => {
                                            const data = [prevState.budgetHours];
                                            data[data.indexOf(oldData)] = newData;
                                            return { ...prevState, budgetHours: data[0] };
                                        });
                                    }
                                }, 600);
                            }),
                    }}
                />
            </div>
        )
    }
}

export default withSnackbar(FutureBudgetHours)