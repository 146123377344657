import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TransferList from './TransferList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function Accordions(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleToUpdate = (stage, left, right) => {
    props.value[stage].left = left;
    props.value[stage].right = right;
    props.handleToUpdate(props.value, props.resData, props.data, props.user)
  }

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel0bh-content"
          id="panel0bh-header"
        >
          <Typography className={classes.heading}>Basic Param Access</Typography>
          {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l0a} stage={'l0a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Param Planner Calender</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            You are currently not an owner
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l1a} stage={'l1a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Timesheet</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            Filtering has been entirely disabled for whole web server
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l2a} stage={'l2a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel13bh-content"
          id="panel13bh-header"
        >
          <Typography className={classes.heading}>Team</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            Filtering has been entirely disabled for whole web server
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l13a} stage={'l13a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Non-Billable</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l3a} stage={'l3a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}>Secured Works</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l4a} stage={'l4a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel17bh-content"
          id="panel17bh-header"
        >
          <Typography className={classes.heading}>Design Management Tracker</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l17a} stage={'l17a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography className={classes.heading}>Estimate Access</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l5a} stage={'l5a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography className={classes.heading}>Tender</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l6a} stage={'l6a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography className={classes.heading}>Unsecured works</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l7a} stage={'l7a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography className={classes.heading}>Opportunity Tracker</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l8a} stage={'l8a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel10bh-header"
        >
          <Typography className={classes.heading}>Deliverables Template</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l9a} stage={'l9a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <Typography className={classes.heading}>Rate Card Template</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l10a} stage={'l10a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11bh-content"
          id="panel11bh-header"
        >
          <Typography className={classes.heading}>Roles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l11a} stage={'l11a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12bh-content"
          id="panel13bh-header"
        >
          <Typography className={classes.heading}>Client</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l12a} stage={'l12a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel14bh-content"
          id="panel14bh-header"
        >
          <Typography className={classes.heading}>Team Task Allocation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l14a} stage={'l14a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel15bh-content"
          id="panel15bh-header"
        >
          <Typography className={classes.heading}>Reports</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l15a} stage={'l15a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel22'} onChange={handleChange('panel22')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel22bh-content"
          id="panel22bh-header"
        >
          <Typography className={classes.heading}>Head Of's</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l22a} stage={'l22a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel16bh-content"
          id="panel16bh-header"
        >
          <Typography className={classes.heading}>User Management</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l16a} stage={'l16a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel18bh-content"
          id="panel18bh-header"
        >
          <Typography className={classes.heading}>LMS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l18a} stage={'l18a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel19bh-content"
          id="panel19bh-header"
        >
          <Typography className={classes.heading}>Future Budget</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l19a} stage={'l19a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel20'} onChange={handleChange('panel20')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel20bh-content"
          id="panel20bh-header"
        >
          <Typography className={classes.heading}>PMS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l20a} stage={'l20a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel21'} onChange={handleChange('panel21')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel21bh-content"
          id="panel21bh-header"
        >
          <Typography className={classes.heading}>MCR Timeline</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l21a} stage={'l21a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel23'} onChange={handleChange('panel23')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel23bh-content"
          id="panel23bh-header"
        >
          <Typography className={classes.heading}>D - Zero</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l23a} stage={'l23a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel24'} onChange={handleChange('panel24')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel24bh-content"
          id="panel24bh-header"
        >
          <Typography className={classes.heading}>SBU</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l24a} stage={'l24a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel111'} onChange={handleChange('panel111')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel111bh-content"
          id="panel111bh-header"
        >
          <Typography className={classes.heading}>Others</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferList value={props.value.l111a} stage={'l111a'} handleToUpdate={handleToUpdate} />
        </AccordionDetails>
      </Accordion>

    </div>
  );
}
