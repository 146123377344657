import { Box } from '@material-ui/core';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import Service from '../../Networking/networkutils';

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

class TenderRateByYear extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoading: true
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        Service.get(`/bb/api/tender/ratecard/template/detail/?tender_ratecard_template=${this.props.value.id}`, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoading: false,
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    putData(data) {
        Service.put('/bb/api/tender/ratecard/template/detail/' + data.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.props.enqueueSnackbar('Data updated successfully!', {
                    variant: 'success'
                });
                this.fetchData()
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    render() {
        return (
            <div>
                <Box display={'flex'} flexDirection='column'>
                    <Box m={1}>
                        <b>Description: </b>{this.props.value.description}
                    </Box>
                    <Box marginTop='5px'>
                        <MaterialTable
                            key={3}
                            title="Custom Rate Card"
                            columns={[
                                {
                                    title: 'Grade', field: 'grade', editable: 'never',
                                    render: rowData => rowData.grade.toUpperCase()
                                },
                                { title: 'Designation', field: 'designation', editable: 'never' },
                                { title: '£ Internal Cost to Contract/Tender UK', field: 'rate_uk', type: 'numeric' },
                                { title: '£ Internal Cost to Contract/Tender INDIA/Srilanka', field: 'rate_in', type: 'numeric' },
                            ]}
                            data={this.state.data}
                            isLoading={this.state.isLoading}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            if (oldData) {
                                                if (!newData.rate_uk || !newData.rate_in) {
                                                    this.props.enqueueSnackbar('Rates are mandatory!', {
                                                        variant: 'warning'
                                                    });
                                                } else {
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        return { ...prevState, data };
                                                    });
                                                    this.putData(newData)
                                                }
                                            }
                                        }, 100);
                                    }),
                            }}
                            options={{
                                padding: "dense",
                                pageSize: 10,
                                pageSizeOptions: [10, 25, 50],
                            }}
                        />
                    </Box>
                </Box>
            </div>
        );
    }
}

export default withSnackbar(TenderRateByYear);
