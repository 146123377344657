import React, { Component } from 'react'
import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { getCookie } from '../../Helper/CookieHelper';
import { TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, Box, Typography, Grid, TextField, Switch, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import BalfourBeattyHeader from '../../CommonTips/BalfourBeattyHeader';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import { errorMessage } from '../../Helper/ErrorMessage';
import Moment from 'react-moment';
import { getPrimaryColor } from '../../Helper/CommonThings';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#000000',
        color: '#ffffff',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#ffffff',
        },
    },
}))(TableRow);

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
// const user = JSON.parse(getCookie("bb_ts_user"));
var rates = [];
var roles = [];
class Report extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tenderData: {},
            deliData: {},
            deliSch: [],
            roles: [],
            totalInfo: {
                totalHour: 0,
                engHour: 0, cadHour: 0,
                adminHour: 0,
                totalAmount: 0,
                engAmount: 0,
                cadAmount: 0,
                adminAmount: 0,
            },
            isLoading: true,
            isLoading1: true,
            isLoading2: true,
            checkedB: false,
        }
    }


    componentDidMount() {
        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }
        if (!this.props.match.params.appid) {
            window.location.href = '/'
        }
        if (!token || !(access && access.access && access.access.l6a && access.access.l6a.l6a_i)) {
            window.location.href = '/'
        }
        this.fetchData();
    }

    fetchData() {
        Service.get(`/bb/api/tender/project/` + this.props.match.params.appid + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({ isLoading1: false, deliData: res.data })
            })
            .catch(error => {
               errorMessage(this.props, error)
            });

        Service.get(`/bb/api/tender/project/` + this.props.match.params.tenid + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ isLoading2: false, tenderData: res.data })
            })
            .catch(error => {
               errorMessage(this.props, error)
            });

        Service.get(`/bb/api/tender/ohlwbs/?pid=` + this.props.match.params.appid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)

                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    element.totalHours = 0;
                    element.cadHours = 0;
                    element.engHours = 0;
                    element.adminHours = 0;
                }

                var deliData = res.data.sort(sortByProperty("template_pid"));;
                var totalInfo = {
                    totalHour: 0,
                    engHour: 0, cadHour: 0,
                    adminHour: 0,
                    totalAmount: 0,
                    engAmount: 0,
                    cadAmount: 0,
                    adminAmount: 0,
                };

                Service.get('/bb/api/tender/wbs/tasksnodata/?pid=' + this.props.match.params.appid, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res5 => {

                        var deliTask = res5.data;

                        for (let i = 0; i < deliTask.length; i++) {
                            const element = deliTask[i];

                            element.totalHours = 0;
                            element.cadHours = 0;
                            element.engHours = 0;
                            element.adminHours = 0;
                        }

                        Service.get('/bb/api/tender/rate/?pid=' + this.props.match.params.appid, {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res2 => {

                                rates = res2.data;

                                Service.get(`/bb/api/tender/tasks/rolesnodata/?pid=` + this.props.match.params.appid, {
                                    headers: {
                                        Authorization: "Token " + token,
                                    },
                                })
                                    .then(res1 => {
                                        roles = res1.data;

                                        var compData = [];
                                        var count = 1;

                                        // console.log(res1.data)
                                        for (let i = 0; i < deliData.length; i++) {
                                            const element = deliData[i];

                                            for (let j = 0; j < res1.data.length; j++) {
                                                const element1 = res1.data[j];
                                                if (element1.t_wbs === element.id) {
                                                    element.totalHours = element.totalHours + element1.hours;
                                                    totalInfo.totalHour = totalInfo.totalHour + element1.hours;
                                                    if (element1.grade === 'a' || element1.grade === 'b' || element1.grade === 'c') {
                                                        element.engHours = element.engHours + element1.hours;
                                                        totalInfo.engHour = totalInfo.engHour + element1.hours;
                                                    } else if (element1.grade === 'd' || element1.grade === 'e' || element1.grade === 'f') {
                                                        element.cadHours = element.cadHours + element1.hours;
                                                        totalInfo.cadHour = totalInfo.cadHour + element1.hours;
                                                    } else if (element1.grade === 'g') {
                                                        element.adminHours = element.adminHours + element1.hours;
                                                        totalInfo.adminHour = totalInfo.adminHour + element1.hours;
                                                    }
                                                }
                                            }

                                            if (element.totalHours === 0) {
                                                delete deliData[i]
                                            } else {
                                                // console.log(element)
                                                deliData[i].sno = count;
                                                count++;
                                                compData.push(deliData[i])

                                                if (this.state.checkedB) {
                                                    var count1 = 1;

                                                    // console.log(deliTask)
                                                    for (let j = 0; j < deliTask.length; j++) {
                                                        const element2 = deliTask[j];
                                                        // console.log(element2)
                                                        try {
                                                            if (element2.t_ohlwbs === element.id) {
                                                                for (let k = 0; k < res1.data.length; k++) {
                                                                    const element1 = res1.data[k];

                                                                    if (element1.t_tasks === element2.id) {
                                                                        element2.totalHours = element2.totalHours + element1.hours;
                                                                        // totalInfo.totalHour = totalInfo.totalHour + element1.hours;
                                                                        if (element1.grade === 'a' || element1.grade === 'b' || element1.grade === 'c') {

                                                                            element2.engHours = element2.engHours + element1.hours;
                                                                            // console.log(JSON.parse(JSON.stringify(element2)))
                                                                            // totalInfo.engHour = totalInfo.engHour + element1.hours;
                                                                        } else if (element1.grade === 'd' || element1.grade === 'e' || element1.grade === 'f') {
                                                                            element2.cadHours = element2.cadHours + element1.hours;
                                                                            // totalInfo.cadHour = totalInfo.cadHour + element1.hours;
                                                                        } else if (element1.grade === 'g') {
                                                                            element2.adminHours = element2.adminHours + element1.hours;
                                                                            // totalInfo.adminHour = totalInfo.adminHour + element1.hours;
                                                                        }
                                                                    }
                                                                }
                                                                if (element2.totalHours !== 0) {

                                                                    deliTask[j].sno = (count - 1) + (count1 / 100);
                                                                    count1++;
                                                                    // console.log(deliTask[j])
                                                                    compData.push(deliTask[j])
                                                                }
                                                            }
                                                        } catch (error) {

                                                        }

                                                    }
                                                }

                                            }

                                            totalInfo.totalAmount = totalInfo.totalAmount + this.costCalc(element);
                                            totalInfo.engAmount = totalInfo.engAmount + this.costCalcABC(element);
                                            totalInfo.cadAmount = totalInfo.cadAmount + this.costCalcDEF(element);
                                            totalInfo.adminAmount = totalInfo.adminAmount + this.costCalcG(element);

                                        }

                                        this.setState({ deliSch: compData, totalInfo: totalInfo, isLoading: false })

                                    })
                                    .catch(error => {
                                        console.log(error)
                                        errorMessage(this.props, error)
                                    });
                            })
                            .catch(error => {
                                errorMessage(this.props, error)
                            });




                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });

            })
            .catch(error => {
               errorMessage(this.props, error)
            });

    }

    costCalcABC(rowData) {
        var cost = 0;
        for (let i = 0; i < roles.length; i++) {
            const element = roles[i];
            if ((element.t_wbs === rowData.id) && (element.grade === 'a' || element.grade === 'b' || element.grade === 'c')) {
                cost = cost + this.costCalc2(element)
            }
        }
        return (cost);
    }

    costCalcDEF(rowData) {
        var cost = 0;
        for (let i = 0; i < roles.length; i++) {
            const element = roles[i];
            if ((element.t_wbs === rowData.id) && (element.grade === 'd' || element.grade === 'e' || element.grade === 'f')) {
                cost = cost + this.costCalc2(element)
            }
        }
        return (cost);
    }

    costCalcG(rowData) {
        var cost = 0;
        for (let i = 0; i < roles.length; i++) {
            const element = roles[i];
            if ((element.t_wbs === rowData.id) && (element.grade === 'g')) {
                cost = cost + this.costCalc2(element)
            }
        }
        return (cost);
    }

    costCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < roles.length; i++) {
            const element = roles[i];
            if (element.t_wbs === rowData.id) {
                cost = cost + this.costCalc2(element)
            }
        }
        return (cost);
    }

    costCalc2(data1) {
        for (let j = 0; j < rates.length; j++) {
            const element1 = rates[j];
            if (element1.t_pid === data1.t_pid) {
                if (data1.country === 'in' || data1.country === 'sl') {
                    for (let i = 0; i < rates[j].t_rates.length; i++) {
                        const element = rates[j].t_rates[i];
                        if (data1.grade.toUpperCase() === element.grade) {
                            // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                            return data1.hours * element.costin;
                        }
                    }
                } else {
                    for (let i = 0; i < rates[j].t_rates.length; i++) {
                        const element = rates[j].t_rates[i];
                        if (data1.grade.toUpperCase() === element.grade) {
                            return data1.hours * element.costuk;
                        }
                    }
                }
                break;
            }
        }
        return 0;
    }

    render() {

        const handleChange = (e) => {
            // console.log(e)
            this.setState({ checkedB: e.target.checked, isLoading: true, isLoading1: true, isLoading2: true }, () => {
                this.fetchData();
            })
        };

        return (
            <div>
                <BalfourBeattyHeader />
                {(this.state.isLoading || this.state.isLoading1 || this.state.isLoading2) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                        <div>
                            <Box m={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={2} >
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Box fontWeight="fontWeightBold">Opportunity Name :</Box></Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Box fontWeight="fontWeightBold">Client :</Box></Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Box fontWeight="fontWeightBold">Deliverables Schedule :</Box></Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={10} >
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}>{this.state.tenderData.tenderbid_data.opportunity}</Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}>{this.state.tenderData.tenderbid_data.client_name}</Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}>{this.state.deliData.name}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={3} >
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'} ><Box fontWeight="fontWeightBold">Team Members</Box></Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Box fontWeight="fontWeightBold">EM :</Box></Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Box fontWeight="fontWeightBold">Bid Lead :</Box></Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Box fontWeight="fontWeightBold">LDE :</Box></Typography>
                                        </Box>
                                        {/* <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Box fontWeight="fontWeightBold">Lead Engineer :</Box></Typography>
                                        </Box> */}
                                    </Grid>
                                    <Grid item xs={3} >
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Box fontWeight="fontWeightBold">:</Box></Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}>{(this.state.tenderData.tenderbid_data.pdm_data) ? (this.state.tenderData.tenderbid_data.pdm_data.last_name + ", " + this.state.tenderData.tenderbid_data.pdm_data.first_name) : (null)}</Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}>{(this.state.tenderData.tenderbid_data.bid_lead_data) ? (this.state.tenderData.tenderbid_data.bid_lead_data.last_name + ", " + this.state.tenderData.tenderbid_data.bid_lead_data.first_name) : (null)}</Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}>{(this.state.tenderData.tenderbid_data.lde_data) ? (this.state.tenderData.tenderbid_data.lde_data.last_name + ", " + this.state.tenderData.tenderbid_data.lde_data.first_name) : (null)}</Typography>
                                        </Box>
                                        {/* <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}>{(this.state.tenderData.tenderbid_data.lead_engineer_data) ? (this.state.tenderData.tenderbid_data.lead_engineer_data.last_name + ", " + this.state.tenderData.tenderbid_data.lead_engineer_data.first_name) : (null)}</Typography>
                                        </Box> */}
                                    </Grid>

                                    <Grid item xs={3} >
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'} ><Box fontWeight="fontWeightBold">Dates</Box></Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Box fontWeight="fontWeightBold">Tender Submission :</Box></Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Box fontWeight="fontWeightBold">Contract Award :</Box></Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Box fontWeight="fontWeightBold">Contract Start :</Box></Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Box fontWeight="fontWeightBold">Contract Finish :</Box></Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3} >
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Box fontWeight="fontWeightBold">:</Box></Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Moment local format="DD/MM/YYYY">{this.state.tenderData.tenderbid_data.submission}</Moment></Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Moment local format="DD/MM/YYYY">{this.state.tenderData.tenderbid_data.contract_award}</Moment></Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Moment local format="DD/MM/YYYY">{this.state.tenderData.tenderbid_data.itt_start}</Moment></Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexWrap="nowrap"
                                            p={1}
                                            m={1}
                                            bgcolor="background.paper"
                                        >
                                            <Typography component={'div'}><Moment local format="DD/MM/YYYY">{this.state.tenderData.tenderbid_data.contract_completion}</Moment></Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box m={3}>
                                <TextField id="outlined-basic" label="Scope" variant="outlined"
                                    value={this.state.deliData.scope_summary}
                                    fullWidth
                                    multiline
                                    placeholder="Placeholder" />
                            </Box>

                            <Box m={3}>
                                <TextField id="outlined-basic2" label="Comments" variant="outlined"
                                    value={this.state.deliData.comments}
                                    fullWidth
                                    multiline
                                    placeholder="Placeholder" />
                            </Box>

                            <Box display="flex" flexDirection="row-reverse" p={1} m={1} bgcolor="background.paper">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.checkedB}
                                            onChange={handleChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Detailed View"
                                />
                            </Box>

                            <Box m={3}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell style={{ color: ' #ffffff' }} align="center">S.No</StyledTableCell>
                                                <StyledTableCell style={{ color: ' #ffffff' }} align="center">WBS and Tasks</StyledTableCell>
                                                <StyledTableCell style={{ color: ' #ffffff' }} align="center">Eng Hours (A,B,C)</StyledTableCell>
                                                <StyledTableCell style={{ color: ' #ffffff' }} align="center">CAD Hours (D,E,F)</StyledTableCell>
                                                <StyledTableCell style={{ color: ' #ffffff' }} align="center">Admin Hours (G)</StyledTableCell>
                                                <StyledTableCell style={{ color: ' #ffffff' }} align="center">Total</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <StyledTableRow style={{ backgroundColor: ' #B0DEAB' }} key='totalRate'>
                                                <StyledTableCell align="left">

                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    Average Rate
                                        </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format((this.state.totalInfo.engAmount / this.state.totalInfo.engHour) ? (this.state.totalInfo.engAmount / this.state.totalInfo.engHour).toFixed(2) : (0))}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format((this.state.totalInfo.cadAmount / this.state.totalInfo.cadHour) ? (this.state.totalInfo.cadAmount / this.state.totalInfo.cadHour).toFixed(2) : (0))}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format((this.state.totalInfo.adminAmount / this.state.totalInfo.adminHour) ? (this.state.totalInfo.adminAmount / this.state.totalInfo.adminHour).toFixed(2) : (0))}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format((this.state.totalInfo.totalAmount / this.state.totalInfo.totalHour) ? (this.state.totalInfo.totalAmount / this.state.totalInfo.totalHour).toFixed(2) : (0))}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            {this.state.deliSch.map((row) => (
                                                <StyledTableRow key={row.id}>
                                                    <StyledTableCell component="th" align="left" scope="row">
                                                        {row.sno}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" align="left" scope="row">
                                                        {row.decs ? row.decs : row.name}
                                                    </StyledTableCell>

                                                    <StyledTableCell align="center">
                                                        {row.engHours.toFixed(2)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {row.cadHours.toFixed(2)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {row.adminHours.toFixed(2)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {row.totalHours.toFixed(2)}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                            <StyledTableRow style={{ backgroundColor: ' #DCE3A2' }} key='totalHour'>
                                                <StyledTableCell align="left">

                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    Total Hours
                                        </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {this.state.totalInfo.engHour.toFixed(2)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {this.state.totalInfo.cadHour.toFixed(2)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {this.state.totalInfo.adminHour.toFixed(2)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {this.state.totalInfo.totalHour.toFixed(2)}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ backgroundColor: ' #BCE8D9' }} key='totalAmount'>
                                                <StyledTableCell align="left">

                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    Total Amount
                                        </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.state.totalInfo.engAmount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.state.totalInfo.cadAmount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.state.totalInfo.adminAmount)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.state.totalInfo.totalAmount)}
                                                </StyledTableCell>
                                            </StyledTableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </div>
                    )}


            </div>
        )
    }
}

function sortByProperty(property) {
    return function (a, b) {
        if (a[property] > b[property])
            return 1;
        else if (a[property] < b[property])
            return -1;

        return 0;
    }
}


export default withSnackbar(Report);