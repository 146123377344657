import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../Networking/networkutils";
// import Box from '@material-ui/core/Box';
import { SemipolarLoading } from 'react-loadingg';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar } from 'notistack';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import { getPrimaryColor } from '../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

var autocompdata = '';

class GroupAccess extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            adddata: [],
            isLoading: true,
            openAdd: false,
            openEdit: false,
            selectedUser: null,
            teams: [],
            selectedTeam: null,
            setDisabled: false
        }
        this.removeaccess = this.removeaccess.bind(this);
        this.checkLead = this.checkLead.bind(this)
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        // this.setState({ isLoading: true })
        Service.get("/bb/api/team/access/users_team_list/?gid=" + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoading: false,
                })
                var data1 = res.data;
                Service.get("/bb/api/users", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res => {
                        var tonewusr = [];
                        for (let i = 0; i < res.data.length; i++) {
                            const element = res.data[i];
                            var acpt = true;
                            for (let j = 0; j < data1.length; j++) {
                                const element1 = data1[j];
                                if (element.id === element1.uid) {
                                    acpt = false;
                                }
                            }
                            if (acpt && !(element.first_name === '' || element.last_name === '') && !(element.first_name === null || element.last_name === null) && !(element.first_name === "null" || element.last_name === "null")) {
                                tonewusr.push({
                                    name: element.email + " (" + element.first_name + " " + element.last_name + ")",
                                    rname: element.first_name + " " + element.last_name,
                                    remail: element.email,
                                    id: element.id,
                                });
                            }
                        }

                        this.setState({
                            adddata: tonewusr,
                        })

                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    postAccess() {
        this.setState({ isLoading: true });

        if (autocompdata === "") {
            this.props.enqueueSnackbar('Select Something!', {
                variant: 'error'
            });
        } else {

            var json = {
                "name": autocompdata.rname,
                "email": autocompdata.remail,
                "gid": this.props.value.id,
                "uid": autocompdata.id,
                "created_by": user.email
            }
            Service.post("/bb/api/team/access/create_team_access/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: json,
            })
                .then(res => {

                    this.setState({ openAdd: false });
                    this.props.enqueueSnackbar(autocompdata.rname + ' added to group', {
                        variant: 'success'
                    });
                    this.fetchOps();

                })
                .catch(error => {
                    // console.log(error)
                    errorMessage(this.props, error)
                    this.fetchOps();
                });

        }
    }

    checkLead(data, oldRowD, rowD) {
        // console.log(data)
        if (parseInt(oldRowD.team_role) !== parseInt(rowD.team_role)) {
            var goon = false;
            if (parseInt(oldRowD.team_role) === 1) {
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    if (rowD.id !== element.id && parseInt(element.team_role) === 1) {
                        goon = true;
                        break;
                    }
                }
            } else {
                goon = true;
            }
            return goon;
        }
        return true;
    }

    updateAccess(data, oldRowD, rowD) {

        if (parseInt(oldRowD.team_role) !== parseInt(rowD.team_role)) {
            var goon = false;
            if (parseInt(oldRowD.team_role) === 1) {
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    if (rowD.id !== element.id && parseInt(element.team_role) === 1) {
                        goon = true;
                        break;
                    }
                }
            } else {
                goon = true;
            }
            if (goon) {
                Service.put("/bb/api/team/access/" + rowD.id, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: rowD,
                })
                    .then(res => {
                        this.props.enqueueSnackbar('Member successfully updated!', {
                            variant: 'success'
                        });
                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                        this.fetchOps();
                    });
            } else {
                this.props.enqueueSnackbar('Atleast one Lead is need for a Team!', {
                    variant: 'warning'
                });
            }

        }
    }

    removeaccess(rowD) {
        this.setState({ isLoading: true });

        Service.delete("/bb/api/team/access/" + rowD.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Member successfully removed!', {
                    variant: 'success'
                });
                this.fetchOps();
            })
            .catch(error => {
                errorMessage(this.props, error)
                this.fetchOps();
            });
    }

    onTagsChange = (event, values) => {
        // console.log(values)
        autocompdata = values;
    }

    setUser(data) {
        this.setState({ selectedUser: data, teams: data.other_teams }, () => {
            this.setState({ openEdit: true })
        })
    }

    onTeamChange = (event, values) => {
        this.setState({ selectedTeam: values });
    }

    setPrimaryTeam() {
        if (!this.state.selectedTeam) {
            this.props.enqueueSnackbar('Please select the team!', {
                variant: 'warning'
            });
        } else {
            this.setState({ setDisabled: true })
            Service.put("/bb/api/team/access/primary_team_update/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    uid: this.state.selectedUser.uid,
                    gid: this.state.selectedTeam.gid
                }
            })
                .then(res => {
                    this.setState({ setDisabled: false, openEdit: false, selectedTeam: null, teams: [], selectedUser: null })
                    this.props.enqueueSnackbar('Primary Team saved successfully!', {
                        variant: 'success'
                    });
                    this.fetchOps()
                })
                .catch(error => {
                    this.setState({ setDisabled: false })
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    render() {

        const handleClose = () => {
            this.setState({ openAdd: false })
        };

        const handleSetClose = () => {
            this.setState({ openEdit: false, selectedUser: null, teams: null })
        };

        return (
            <div style={{ padding: '25px' }}>

                {(this.state.isLoading) ? (
                    <SemipolarLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        {/* {(access && access.access && access.access.l13a && access.access.l13a.l13a_b) ? (
                                <Box display="flex" flexDirection="row-reverse" p={1} m={1} bgcolor="background.paper">
                                    <Button variant="contained" color="primary" onClick={e => { this.setState({ openAdd: true }) }} style={{ marginBottom: '10px' }}>Add Member</Button>
                                </Box>
                            ) : (null)} */}

                        <MaterialTable
                            title={this.props.value.name + " Members"}
                            columns={[
                                {
                                    title: 'Name', field: 'name', editable: false,
                                    render: rowData => rowData.uname_last + ", " + rowData.uname_first
                                },
                                { title: 'Email', field: 'email', editable: false },
                                { title: 'Job Role', field: 'uid_data.role_name', editable: false },
                                {
                                    title: 'Role in Team', field: 'team_role',
                                    lookup: { 0: 'Member', 2: 'Delegated', 1: 'Lead', 3: 'Controls' },
                                },
                                { title: 'Primary Team', field: 'primary_team', editable: false },
                            ]}
                            data={this.state.data}
                            actions={[
                                (access && access.access && access.access.l13a && access.access.l13a.l13a_c) ? ({
                                    icon: 'cancel',
                                    tooltip: 'Remove User',
                                    onClick: (event, rowData) => this.removeaccess(rowData)
                                }) : (null),
                                (access && access.access && access.access.l13a && access.access.l13a.l13a_c) ? ({
                                    icon: 'add_box',
                                    tooltip: 'Add Member',
                                    isFreeAction: true,
                                    onClick: (event) => this.setState({ openAdd: true })
                                }) : (null),
                                (access && access.access && access.access.l13a && access.access.l13a.l13a_c) ? ({
                                    icon: () => <Button color='primary' variant='outlined' size='small'>Primary Team</Button>,
                                    tooltip: 'Set Primary Team',
                                    onClick: (event, rowData) => this.setUser(rowData)
                                }) : (null),
                            ]}
                            options={{
                                padding: "dense",
                                paging: false,
                                actionsCellStyle: { width: 200 }
                                // actionsColumnIndex: -1
                            }}
                            editable={(access && access.access && access.access.l13a && access.access.l13a.l13a_c) ? ({

                                onRowUpdate: (newData, oldData) =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            if (oldData) {
                                                this.setState({ save: false });
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data[data.indexOf(oldData)] = this.checkLead(data, oldData, newData) ? newData : oldData;
                                                    this.updateAccess(data, oldData, newData);
                                                    return { ...prevState, data };
                                                });
                                            }
                                        }, 60);
                                        // this.updateAccess(oldData, newData);
                                    }),

                            }) : (null)}
                        />
                        <Dialog open={this.state.openAdd} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Add Member</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Type member name or email and select the user from the list.
                                </DialogContentText>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={this.state.adddata}
                                    getOptionLabel={(option) => option.name}
                                    onChange={this.onTagsChange}
                                    renderInput={(params) => <TextField {...params} label="Type Member name or email" variant="outlined" />}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={e => this.postAccess()} color="primary">
                                    Add Member
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.openEdit} fullWidth onClose={handleSetClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Set Primary Team for {this.state.selectedUser ? this.state.selectedUser.uname_last + ", " + this.state.selectedUser.uname_first : ""}</DialogTitle>
                            <DialogContent>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={this.state.teams}
                                    getOptionLabel={(option) => option.gname}
                                    onChange={this.onTeamChange}
                                    renderInput={(params) => <TextField {...params} label="Team name" variant="outlined" />}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleSetClose} color="primary">
                                    Cancel
                                </Button>
                                <Button disabled={this.state.setDisabled} onClick={(e) => this.setPrimaryTeam()} color="primary">
                                    Set
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}

            </div>
        )
    }
}

export default withSnackbar(GroupAccess);