import React, { Component } from 'react';
import Box from '@mui/material/Box';
import ManageTimeSheet from '../Manage/ManageTimeSheet';
import { getCookie } from '../Helper/CookieHelper';
import RequestReceived from '../LMS/RequestReceived/RequestReceived';
import { styled } from '@mui/system';
import { Badge } from '@mui/material';
import { Tabs as TabsUnstyled } from '@mui/base/Tabs';
import { TabsList as TabsListUnstyled } from '@mui/base/TabsList';
import { TabPanel as TabPanelUnstyled } from '@mui/base/TabPanel';
import { Tab as TabUnstyled, tabClasses } from '@mui/base/Tab';
import { getPrimaryColor, getSecondaryColor } from '../Helper/CommonThings';
import { screenSizeCheck } from '../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import { withSnackbar } from 'notistack';
import { errorMessage } from '../Helper/ErrorMessage';
import Service from '../Networking/networkutils';
import {  Tooltip } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Button from '@material-ui/core/Button';

const Tab = styled(TabUnstyled)`
  font-family: roboto;
  color: ${getPrimaryColor()};
  cursor: pointer;
  font-size: ${screenSizeCheck() ? '1rem' : '1.2rem'};
  font-weight: bold;
  background-color: transparent;
  padding: 12px 50px;
  margin: 2px 2px 0px 0px ;
  border: none;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: center;
  outline:2px solid #a3c2c2;
  box-shadow:1px 1px 1px 1px #a3c2c2;
  &:hover {
    background-color: ${getSecondaryColor()};
  }

  &:focus {
    color: white;
    border-radius: 20px 20px 0px 0px
    // outline: 2px solid ${getPrimaryColor()};
    // outline-offset: 2px;
  }

  &.${tabClasses.selected} {
    background-color: ${getPrimaryColor()};
    color: white;
  }

`;

const TabPanel = styled(TabPanelUnstyled)`
  width: '100%';
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: left;
  justify-content: left;
  align-content: space-between;
`;


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -14,
        top: -8,
    },
}));

const access = JSON.parse(getCookie("bb_ts_access"));
const token = getCookie("bb_ts_token");


class ManageTimeSheetMain extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: "timesheets",
            timesheet_count: 0,
            leaves_count: 0
        }
        this.refreshCount = this.refreshCount.bind(this)
    }

    componentDidMount() {
        this.getCount()
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    getCount() {
        Service.get(`/bb/api/task/approve/pending_status/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (!this.interval) {
                    this.interval = setInterval(() => {
                        this.getCount()
                    }, 10000);
                }
                if (res.data.timesheet_pending_status === 0 && res.data.lmsapproval_pending_status === 0 && this.interval) {
                    clearInterval(this.interval)
                }
                this.setState({
                    timesheet_count: res.data.timesheet_pending_status,
                    leaves_count: res.data.lmsapproval_pending_status
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });

    }

    refreshCount() {
        this.getCount()
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    render() {
        const handleChange = (event, newValue) => {
            this.setState({ value: newValue })
        };
        return (
            <div>
                {(access.access && access.access.l18a && (access.access.l18a.l18a_b || access.access.l18a.l18a_c)) ?
                    <TabsUnstyled value={this.state.value} onChange={handleChange} >
                        <Box sx={{ display: 'flex', flexDirection: 'row', margin: this.props.reportCards ? 1 : 0, alignItems: 'center' }}>
                            <TabsList >
                                <Tab value={'timesheets'}>
                                    <StyledBadge anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }} badgeContent={this.state.timesheet_count} color="secondary">
                                        Timesheets
                                    </StyledBadge>
                                </Tab>
                                <Tab value={'leaves'}>
                                    <StyledBadge anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }} badgeContent={this.state.leaves_count} color="secondary">
                                        Leaves
                                    </StyledBadge>
                                </Tab>
                            </TabsList>
                            <Tooltip title="Leave Management System">
                                <Button
                                    variant="standard"
                                    color="primary"
                                    style={{ marginLeft: '10px' }}
                                    startIcon={<OpenInNewIcon />}
                                    onClick={() => { window.open("https://lms.bbparam.com") }}
                                >
                                    LMS
                                </Button>
                            </Tooltip>
                        </Box>
                        <TabPanel value={"timesheets"} >
                            <ManageTimeSheet refreshCount={this.refreshCount} />
                        </TabPanel>
                        <TabPanel value={"leaves"} >
                            <RequestReceived refreshCount={this.refreshCount} />
                        </TabPanel>
                    </TabsUnstyled>
                    :
                    <ManageTimeSheet />
                }
            </div>
        );
    }
}

export default withSnackbar(ManageTimeSheetMain)
