import MaterialTable from 'material-table'
import React, { Component } from 'react'

export default class FutureBudgetErrorData extends Component {
    render() {
        return (
            <div>
                 <MaterialTable
                    title="Month of user that's exceeding 100%"
                    columns={[
                        { title: 'Name', field: 'full_name' },
                        {
                            title: "Role",
                            field: "uid_data.role_name",
                            editable: 'never'
                        },
                        {
                            title: 'Month', field: 'month',
                            lookup: { 
                                1: "January",
                                2: "February",
                                3: "March",
                                4: "April",
                                5: "May",
                                6: "June",
                                7: "July",
                                8: "August",
                                9: "September",
                                10: "October",
                                11: "November",
                                12: "December"
                             },
                        }
                    ]}
                    data={this.props.data}
                    options={{
                        padding: "dense",
                        paging: false,
                        search: false
                    }}
                    />
            </div>
        )
    }
}
