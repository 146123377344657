import React from 'react';
import MaterialTable from 'material-table';
import { errorMessage } from '../Helper/ErrorMessage';
import Service from '../Networking/networkutils';
import { getCookie } from '../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import AdditionalHourRequest2 from '../Teams/AssignRoles/AdditionalHourRequest2';
import { Typography } from '@material-ui/core';
import { BootstrapDialogTitleV4, BootstrapDialogV4, Transition } from '../Helper/DialogTitleM5';
import { DialogContentText } from '@mui/material';

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));



const country = { uk: 'UK', in: 'IN/SL' };
const grade = { a: 'A', b: 'B', c: 'C', d: 'D', e: 'E', f: 'F', g: 'G', h: 'H', I: 'I', J: 'J', k: 'K' };

class UserRoles extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            openRequest: false,
            selectedeRowData: null,
            openRequestAlert: false,
            requestAlertMessage: ""
        }
    }

    componentDidMount() {
        console.log(this.props.rowData)
        this.setState({ data: this.props.rowData.role_data })
    }

    // givePlanned(data, asdata) {
    //     var tot_planned = 0
    //     for (let i = 0; i < data.length; i++) {
    //         const element = data[i];
    //         if (asdata.id === element.task_role_id && asdata.planed_id !== element.id) {
    //             tot_planned = tot_planned + element.planed_hours
    //         }
    //     }
    //     return tot_planned + asdata.planed_hours
    // }

    putData(asdata, oldData, alldata) {

        // console.log(asdata, oldData, alldata, 'put ops')
        this.setState({
            isLoading: true,
            selectedeRowData: oldData
        })
        Service.put('/bb/api/tender/user/task/' + asdata.planed_id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: asdata,
        })
            .then(res => {
                this.setState({ isLoading: false, openAdd: false })
                this.props.enqueueSnackbar('Edited Successfully!', {
                    variant: 'success'
                });
                this.props.fetchData()
                // this.props.fetchData(this.props.task, this.props.allUsers)
                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
                this.setState({ data: this.props.rowData.role_data })
                this.setState({ isLoading: false })
                if (error && error.status === 416) {
                    this.setState({
                        requestAlertMessage: error.data,
                        openRequestAlert: true
                    })
                    return null
                } else if (error && error.status === 406) {
                    this.props.enqueueSnackbar(error.data, {
                        variant: 'warning'
                    });
                } else {
                    errorMessage(this.props, error)
                }
                this.props.fetchData()
            });
    }

    deleteData(asdata) {
        this.setState({ isLoading: true })
        Service.delete('/bb/api/tender/user/task/' + asdata.planed_id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Deleted Successfully!', {
                    variant: 'success'
                });
                this.props.fetchData()
                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
                this.setState({ data: this.props.rowData.role_data })
                errorMessage(this.props, error)
                this.props.fetchData()
            });
    }


    render() {

        const handleRequestClose = () => {
            this.setState({ openRequest: false, })
        };

        const handleRequestAlertClose = () => {
            this.props.fetchData()
            this.setState({ openRequestAlert: false, })
        };

        const initiateHourRequest = () => {
            this.setState({ openRequestAlert: false, openRequest: true })
        };

        return (<>
            <MaterialTable
                columns={[
                    {
                        title: 'Role (Country / Grade)', field: 'name', editable: 'never',
                        render: (rowdata) => rowdata.name + ' (' + country[rowdata.country] + ' - ' + grade[rowdata.grade] + ')'
                    },
                    { title: 'Alloc Hrs', field: 'planed_hours' },
                    // { title: 'Hrs Comp.', field: 'rev_hours', editable: 'never' },
                ]}
                data={this.state.data}

                isLoading={this.state.isLoading}
                options={{
                    showTitle: false,
                    padding: 'dense',
                    paging: false,
                    toolbar: false,
                    headerStyle: {
                        // backgroundColor: getPrimaryColor(),
                        // color: '#FFF',
                        fontSize: '12px',
                        padding: '10px',
                    },
                    rowStyle: rowData => {
                        return {
                            //   backgroundColor: '#eaeaea'
                            fontSize: '12px',
                            padding: '10px'
                        };
                    }
                }}
                actions={[
                    rowData => ({
                        icon: 'post_add',
                        tooltip: 'Request Hours for this role',
                        onClick: (event, rowData) => { this.setState({ openRequest: true, selectedeRowData: rowData }) },
                    }),
                    rowData => ({
                        icon: 'cancel',
                        tooltip: (rowData.id && this.props.allUid.includes(this.props.rowData.user_id) && rowData.planed_hours === 0) ? "Delete" : "You can delete only when the Allocated Hours in 0.",
                        disabled: !(rowData.id && this.props.allUid.includes(this.props.rowData.user_id) && rowData.planed_hours === 0),
                        onClick: (event, rowData) => { this.deleteData(rowData); },
                    }),
                ]}
                style={{ margin: "5px", boxSizing: 'border-box', border: '2px solid orange' }}
                cellEditable={this.props.allUid.includes(this.props.rowData.user_id) ? {
                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) =>
                        new Promise(resolve => {
                            if (columnDef.field === 'planed_hours') {
                                let newData = { ...rowData, ...{ planed_hours: Number(newValue) } }
                                let oldData = { ...rowData, ...{ planed_hours: Number(oldValue) } }
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        this.setState({ save: false });
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(rowData)] = newData;
                                            this.putData(newData, oldData, data);
                                            return { ...prevState, data };
                                        });
                                    }
                                }, 10);
                            }
                        }),
                } : null}
            />
            <BootstrapDialogV4 onClose={handleRequestClose} fullwidth maxWidth="xl" aria-labelledby="customized-dialog-title" open={this.state.openRequest}>
                <BootstrapDialogTitleV4 id="customized-dialog-title" onClose={handleRequestClose}>
                    {(this.state.selectedeRowData) ? this.state.selectedeRowData.name + " [ " + this.props.task.project.tender_name + " - " + this.props.task.ds.name + " ] Available hours for allocation : " + (this.state.selectedeRowData.req_hours) + " hours" : null}
                </BootstrapDialogTitleV4>
                <DialogContent dividers>
                    {(this.state.selectedeRowData) ? (
                        <div>
                            <Typography>{this.props.task.wbs.name + "   --->  Revised Hours: " + (this.state.selectedeRowData.rev_hours) + ", Allocated Hours: " + (this.state.selectedeRowData.total_alloc_in_role) + ". Please contact EM to increase available hours for allocation."}</Typography>
                            <AdditionalHourRequest2 value={{
                                id: this.state.selectedeRowData.id,
                                name: this.state.selectedeRowData.name,
                                country: this.state.selectedeRowData.country,
                                t_tasks: this.props.task.id,
                                t_tasks_data: this.props.task,
                                t_role: this.state.selectedeRowData.t_role,
                                all_hours_to_this_role: this.state.selectedeRowData.req_hours,
                            }} task={this.props.task} team={this.props.team} rolesLookUp={this.props.allRoles ? this.props.allRoles : this.state.allRoles} projectLook={this.props.projectLook} />
                        </div>
                    ) : null}

                </DialogContent>
            </BootstrapDialogV4>
            <Dialog
                open={this.state.openRequestAlert}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleRequestAlertClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Hours unavailable"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    {this.state.selectedeRowData ? "Revised Hours: " + (this.state.selectedeRowData.rev_hours) + ", Allocated Hours: " + (this.state.selectedeRowData.total_alloc_in_role) : null}
                    <br />
                        {this.state.requestAlertMessage}
                        <br /><br />
                        You can request additional hours to be approved by the EM. Do you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleRequestAlertClose}>No</Button>
                    <Button color="primary" onClick={initiateHourRequest}>Yes</Button>
                </DialogActions>
            </Dialog>
        </>)
    }
}

export default withSnackbar(UserRoles)