import { Typography } from '@mui/material';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { Component } from 'react';
import { getDarkMode } from '../../Helper/CommonThings';

class Lookahead3MTable extends Component {

    constructor(props) {
        super(props)

        this.state = {
            columns: [],
            scrollHeight: 1800
        }
    }

    componentDidMount() {
        var columns = [
            {
                title: 'Project', field: 'project_name',
            },
            {
                title: 'DS', field: 'ds_name',
            },
            {
                title: 'WBS', field: 'wbs_name',
            },
            {
                title: 'Task ID', field: 'task_id',
            },
            {
                title: 'Task', field: 'task_name',
            },
            {
                title: 'ACT Start', field: 'act_start_date',
            },
            {
                title: 'ACT Finish', field: 'act_finish_date',
            },
            {
                title: 'Rem. Hours', field: 'remain_hours',
            },
        ]
        const current_date = moment()
        const end_date = moment().add(3, 'months')
        while (current_date <= end_date) {
            if (!([0, 6].includes(current_date.weekday()))) {
                const obj_key = current_date.format("DDMMYYYY")
                columns.push({
                    title: current_date.format("DD MMM"), field: obj_key,
                    render: rowData => rowData.task_id ? rowData[obj_key] : this.getTotalPerDay(obj_key),
                })
            }
            current_date.add(1, 'days')
        }

        this.setState({ columns: columns })

    }

    refCallback = el => {
        if (el) {
            setTimeout(() => {
                this.setState({
                    scrollHeight: window.innerHeight - el.getBoundingClientRect().y - 20
                })
            }, 10);

        }
    }

    getTotalPerDay(day_key) {
        var totalPerDay = 0
        for (let i = 0; i < this.props.data.task_data.length; i++) {
            const element = this.props.data.task_data[i];
            if (element[day_key]) {
                totalPerDay += element[day_key]
            }
        }
        if (totalPerDay) {
            return <Typography color={getDarkMode() === "dark" ? "secondary" : "primary"}>{totalPerDay.toFixed(2)}</Typography>
        }
        return null
    }

    render() {
        return (
            <div ref={this.refCallback} >
                <MaterialTable
                    style={{ zoom: 0.85 }}
                    columns={this.state.columns}
                    data={this.props.data.task_data}
                    title={(this.props.currentUser ? this.props.currentUser.last_name + ", " + this.props.currentUser.first_name : "") + " - 3MLA"}
                    options={{
                        exportButton: { csv: true },
                        padding: 'dense',
                        paging: false,
                        doubleHorizontalScroll: true,
                        toolbarButtonAlignment: 'left',
                        headerStyle: {
                            position: "sticky",
                            top: 0
                        },
                        maxBodyHeight: this.state.scrollHeight + "px",
                        // rowStyle: rowData => ({
                        //     backgroundColor: !rowData.task_id ? getSecondaryColor() : null,
                        //     color: !rowData.task_id ? getPrimaryColor() : null
                        // })
                    }}
                />
            </div>
        );
    }
}

export default Lookahead3MTable;