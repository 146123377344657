import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../Helper/CommonThings';
import { getCookie, setCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
// import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Service from '../Networking/networkutils';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@material-ui/core';


var token = getCookie("bb_ts_token");
// var user = JSON.parse(getCookie("bb_ts_user"));

class SettingsLists extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            data: [],
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        Service.get(`/bb/api/user/settings/settings_status/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                setCookie("bb_ts_user_settings", JSON.stringify(res.data))
                this.setState({
                    data: res.data,
                    isLoading: false,
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    changeStatus(index) {
        let status = ""
        let statusData = this.state.data
        let checkValue = statusData[index].value !== null ? statusData[index].value : statusData[index].default_value

        for (let i = 0; i < statusData[index].values.length; i++) {
            const element = statusData[index].values[i];
            if (element.id === checkValue && i === 0) {
                statusData[index].value = statusData[index].values[1].id
                status = statusData[index].name + " option is Enabled!"
                break
            } else {
                statusData[index].value = statusData[index].values[0].id
                status = statusData[index].name + " option is Disabled!"
                break
            }
        }

        this.Apicall(status, statusData, index)
    }

    changeOption(e, index) {
        let status = ""
        let statusData = this.state.data
        statusData[index].value = e.target.value
        status = statusData[index].name + " is changed sucessfully!"
        this.Apicall(status, statusData, index)
    }

    Apicall(status, statusData, index) {
        Service.put(`/bb/api/user/settings/create_or_update_item/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: statusData[index]
        })
            .then(res => {
                this.setState({ data: statusData })
                this.props.enqueueSnackbar(status, {
                    variant: 'success'
                });
                this.fetchData()
                // ]
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    getCheck(item) {
        let checkValue = item.value !== null ? item.value : item.default_value
        for (let i = 0; i < item.values.length; i++) {
            const element = item.values[i];
            if (element.id === checkValue && i === 0) {
                return false
            } else {
                return true
            }
        }
    }

    render() {

        return (
            <div>
                {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                    this.state.data.map((item, i) =>
                        item.type === "toggle" ?
                            <Box display='flex' flexDirection={'row'} alignItems={'center'} >
                                {/* < FormGroup> */}
                                <FormControlLabel control={
                                    <Switch
                                        checked={this.getCheck(item)}
                                        onChange={() => this.changeStatus(i)}
                                        inputProps={{ 'aria-label': 'controlled' }} />}
                                    label={item.name}
                                    labelPlacement="start" />
                                {/* </FormGroup> */}
                            </Box>
                            :
                            item.type === "dropdown" ?
                                <Box display='flex' flexDirection={'row'} marginLeft={'15px'} alignItems={'center'} >
                                    <Box marginRight={'5px'}>
                                        {item.name} :
                                    </Box>
                                    <FormControl>
                                        {/* <InputLabel id="demo-simple-select-label">{item.name}</InputLabel> */}
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={item.value === null ? item.default_value : item.value}
                                            // label={item.name}
                                            onChange={(e) => this.changeOption(e, i)}
                                            size='small'
                                        >
                                            {item.values.map((item, i) =>
                                                <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box> : null)}
            </div >

        );
    }
}

export default withSnackbar(SettingsLists);


// item.type === "toggle" ?
//     < FormGroup >
//         <FormControlLabel control={
//             <Switch
//                 checked={this.getCheck(item)}
//                 onChange={() => this.changeStatus(i)}
//                 inputProps={{ 'aria-label': 'controlled' }} />}
//             label={item.name} />
//     </FormGroup> :
//     item.type === "dropdown" ?
//         <div>
//             <FormControl>
//                 <InputLabel id="demo-simple-select-label">{item.name}</InputLabel>
//                 <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     value={!item.value ? item.default_value : item.value}
//                     label={item.name}
//                     onChange={(e) => this.changeOption(e, i)}
//                 >
//                     {item.values.map((item, i) =>
//                         <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
//                     )}
//                 </Select>
//             </FormControl>
//         </div> : null