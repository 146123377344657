import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { getPrimaryColor } from '../../../Helper/CommonThings';
import AddDDM from './AddDDM';
import WBSListControl from '../WBSListControl';
import { LoopCircleLoading } from 'react-loadingg';
import Button from '@mui/material/Button';

const steps = ['Add DDM', 'WBS Access',];


class DDMStepper extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeStep: null,
            buttonDisabled: false,
            ddmData: null,
            addView: 0
        }
        this.ddmData = this.ddmData.bind(this)
        this.viewChange = this.viewChange.bind(this)
    }

    ddmData = (data) => {
        this.setState({
            ddmData: data,
        })
        if (this.props.getDialogWidth) {
            this.props.getDialogWidth('lg')
        }
        if (this.state.addView !== 0) {
            this.setState({
                activeStep: 1
            })
        } else if (this.props.onClose && this.state.addView === 0) {
            this.props.onClose()
        }
    }

    componentDidMount() {
        this.setState({
            activeStep: 0
        })
    }

    viewChange = (view) => {
        this.setState({
            addView: view
        })
    }

    render() {
        return (
            <div>
                <Box sx={{ width: '100%' }}>
                    {this.state.addView === 0 ? null :
                        <Stepper activeStep={this.state.activeStep} style={{ marginbottom: '10px' }}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={index} >
                                        <StepLabel >{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>}
                    < React.Fragment >
                        <Box m={1}>
                            {this.state.activeStep === 0 ?
                                <AddDDM
                                    pid={this.props.pid}
                                    users={this.props.users}
                                    ddmData={this.ddmData}
                                    value={this.props.value}
                                    type='add'
                                    usersLookup={this.props.usersLookup}
                                    viewChange={this.viewChange} /> :
                                this.state.activeStep === 1 ?
                                    <WBSListControl
                                        value={this.props.value}
                                        ddm={this.state.ddmData} />
                                    : <LoopCircleLoading color={getPrimaryColor()} />}
                        </Box>
                        {this.state.activeStep === 1 ?
                            <Box>
                                <Button onClick={() => this.props.onClose()} variant="contained" color="primary" sx={{ float: 'right', margin: '10px' }}>Done</Button>
                            </Box> : null}
                    </React.Fragment>
                </Box>
            </div >
        );
    }
}

export default DDMStepper;