import React from 'react';
import MaterialTable from 'material-table';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';

const comp_percent_lookup = { 0: '0%', 5: '5%', 10: '10%', 20: '20%', 30: '30%', 40: '40%', 50: '50%', 60: '60%', 65: '65%', 70: '70%', 80: '80%', 90: '90%', 100: '100%' }
const task_status_lookup = { 0: 'S0', 5: 'S0', 10: 'S0', 20: 'S0', 30: 'S0', 40: 'S0', 50: 'S0', 60: 'S0', 65: 'S1', 70: 'S2', 80: 'S3', 90: 'S4', 100: 'A1' }
const task_progress_lookup = {
    0: 'Not Started', 5: 'Preparation of documents/drawings', 10: 'Preparation of documents/drawings', 20: 'Preparation of documents/drawings',
    30: 'Preparation of documents/drawings', 40: 'Preparation of documents/drawings', 50: 'Preparation of documents/drawings',
    60: 'Checked within Department', 65: 'Approved within Department', 70: 'Site comments received, reviewed',
    80: 'Multi-disciplinary Reviewed & Commented', 90: 'Design verified.', 100: 'Design assured'
}
const budget_change_lookup = { 0: 'Budget', 1: 'Change' }
const task_hold_lookup = { 0: 'UnHold', 1: 'Hold' }
const approval_status_lookup = { 0: 'Not Approved', 1: 'Approved' }
const lead_discipline_lookup = { 'ohl': 'OHL', 'cabling': 'Cabling', 'substation': 'Substation', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical', 'edc': 'EDC' }

class ResourceReportTable extends React.Component {

    constructor() {
        super()
        this.state = {
            columns: [
                { title: 'Lead Discipline', field: 'project_data.tenderbid_data.lead_dicipline', lookup: lead_discipline_lookup },
                { title: 'Client', field: 'project_data.tenderbid_data.client_name' },
                { title: 'Region', field: 'project_data.tenderbid_data.region_name' },
                { title: 'Stage', field: 'project_data.audit_status', lookup:{0: 'Stage 2 - Tender Development', 10 : 'Stage 5 - Mobilising',11 : 'Stage 1a - Tender Mobilising'} },
                {
                    title: 'EM', field: 'project_data.tenderbid_data.pdm_data.first_name',
                    render: (rowData) => rowData.project_data.tenderbid_data.pdm_data.last_name + ', ' + rowData.project_data.tenderbid_data.pdm_data.first_name
                },
                { title: 'Project', field: 'project_data.tender_name' },
                { title: 'DS', field: 'ds_data.name', },
                { title: 'Budget/Change', field: 'ds_data.type', lookup: budget_change_lookup },
                { title: 'Approval Status', field: 'ds_data.approval_status', render: (rowData) => rowData.type === 1 ? approval_status_lookup[rowData.ds_data.approval_status] : '' },
                { title: 'WBS', field: 'wbs_data.name' },
                { title: 'Task ID', field: 'id' },
                { title: 'Task Description', field: 'desc', render: (rowData) => (rowData.decs ? rowData.decs : rowData.name) },
                { title: 'Estimated Hours', field: 'est_hours' },
                { title: 'Hours at Completion', field: 'rev_hours' },
                { title: 'Baseline Start', field: 'from_date', type: 'date' },
                { title: 'Baseline Finish', field: 'to_date', type: 'date' },
                { title: 'Act/Exp Start', field: 'act_exp_start', type: 'date' },
                { title: 'Act/Exp Finish', field: 'act_exp_finish', type: 'date' },
                { title: 'Start Variance', field: 'variance_start' },
                { title: 'Finish Variance', field: 'variance_finish' },
                { title: 'Assigned', field: 'assigned' },
                { title: 'Hold/UnHold', field: 'task_hold', lookup: task_hold_lookup },
                { title: 'Comments(If Hold)', field: 'hold_comment' },
                {
                    title: 'Complete (%)', field: 'comp_percnt',
                    lookup: comp_percent_lookup,
                },
                {
                    title: 'Task Status', field: 'comp_percnt', editable: 'never',
                    lookup: task_status_lookup,
                },
                {
                    title: 'Task Progress', field: 'comp_percnt', editable: 'never',
                    lookup: task_progress_lookup,
                },
            ],
            data: []
        }
    }


    s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    componentDidMount() {
        // console.log(this.props.data)

        let data = this.props.data
        let sorted = data.sort(function (a, b) {
            if (a.project_data.tender_name < b.project_data.tender_name) { return -1; }
            if (a.project_data.tender_name > b.project_data.tender_name) { return 1; }
            return 0;
        })
        this.setState({ data: sorted })
    }

    downloadReport = () => {
        // console.log(this.state.data)
        let sheets = ["Report"]

        let sheetData = {}

        let exportData = {}

        try {
            sheetData["Report"] = {
                '!ref': "A1:Y" + (this.state.data.length + 2),
                'A1': { t: "s", v: "Lead Discipline" },
                'B1': { t: "s", v: "Client" },
                'C1': { t: "s", v: "Region" },
                'D1': { t: "s", v: "EM" },
                'E1': { t: "s", v: "Project" },
                'F1': { t: "s", v: "DS" },
                'G1': { t: "s", v: "Budget/Change" },
                'H1': { t: "s", v: "Approval Status" },
                'I1': { t: "s", v: "WBS" },
                'J1': { t: "s", v: "Task ID" },
                'K1': { t: "s", v: "Task Description" },
                'L1': { t: "s", v: "Estimated Hours" },
                'M1': { t: "s", v: "Hours at Completion" },
                'N1': { t: "s", v: "Baseline Start" },
                'O1': { t: "s", v: "Baseline Finish" },
                'P1': { t: "s", v: "Act/Exp Start" },
                'Q1': { t: "s", v: "Act/Exp Finish" },
                'R1': { t: "s", v: "Start Variance" },
                'S1': { t: "s", v: "Finish Variance" },
                'T1': { t: "s", v: "Assigned" },
                'U1': { t: "s", v: "Hold/UnHold" },
                'V1': { t: "s", v: "Comments(If Hold)" },
                'W1': { t: "s", v: "Complete (%)" },
                'X1': { t: "s", v: "Task Status" },
                'Y1': { t: "s", v: "Task Progress" }
            }


            for (let i = 0; i < this.state.data.length; i++) {
                const element = this.state.data[i];
                sheetData["Report"]["A" + (i + 2)] = { t: "s", v: lead_discipline_lookup[element.project_data.tenderbid_data.lead_dicipline] }
                sheetData["Report"]["B" + (i + 2)] = { t: "s", v: element.project_data.tenderbid_data.client_name }
                sheetData["Report"]["C" + (i + 2)] = { t: "s", v: element.project_data.tenderbid_data.region_name }
                sheetData["Report"]["D" + (i + 2)] = { t: "s", v: element.project_data.tenderbid_data.pdm_data.first_name + ' ' + element.project_data.tenderbid_data.pdm_data.last_name }
                sheetData["Report"]["E" + (i + 2)] = { t: "s", v: element.project_data.tender_name }
                sheetData["Report"]["F" + (i + 2)] = { t: "s", v: element.ds_data.name }
                sheetData["Report"]["G" + (i + 2)] = { t: "s", v: budget_change_lookup[element.ds_data.type] }// lookup
                sheetData["Report"]["H" + (i + 2)] = { t: "s", v: element.ds_data.type === 1 ? approval_status_lookup[element.ds_data.approval_status] : '' } //lookup
                sheetData["Report"]["I" + (i + 2)] = { t: "s", v: element.wbs_data.name }
                sheetData["Report"]["J" + (i + 2)] = { t: "s", v: element.id }
                sheetData["Report"]["K" + (i + 2)] = { t: "s", v: element.decs ? element.decs : element.name }
                sheetData["Report"]["L" + (i + 2)] = { t: "s", v: element.est_hours }
                sheetData["Report"]["M" + (i + 2)] = { t: "s", v: element.rev_hours }
                sheetData["Report"]["N" + (i + 2)] = { t: "s", v: element.from_date ? moment(element.from_date).format('DD/MM/YYYY') : '' }
                sheetData["Report"]["O" + (i + 2)] = { t: "s", v: element.to_date ? moment(element.to_date).format('DD/MM/YYYY') : '' }
                sheetData["Report"]["P" + (i + 2)] = { t: "s", v: element.act_exp_start ? moment(element.act_exp_start).format('DD/MM/YYYY') : '' }
                sheetData["Report"]["Q" + (i + 2)] = { t: "s", v: element.act_exp_finish ? moment(element.act_exp_finish).format('DD/MM/YYYY') : '' }
                sheetData["Report"]["R" + (i + 2)] = { t: "s", v: element.variance_start ? element.variance_start : '' }
                sheetData["Report"]["S" + (i + 2)] = { t: "s", v: element.variance_finish ? element.variance_finish : '' }
                sheetData["Report"]["T" + (i + 2)] = { t: "s", v: element.assigned ? element.assigned : '' }
                sheetData["Report"]["U" + (i + 2)] = { t: "s", v: element.task_hold ? element.task_hold : '' }
                sheetData["Report"]["V" + (i + 2)] = { t: "s", v: element.hold_comment ? element.hold_comment : '' }
                sheetData["Report"]["W" + (i + 2)] = { t: "s", v: comp_percent_lookup[element.comp_percnt] } // LOOKUP
                sheetData["Report"]["X" + (i + 2)] = { t: "s", v: task_status_lookup[element.comp_percnt] } // LOOKUP
                sheetData["Report"]["Y" + (i + 2)] = { t: "s", v: task_progress_lookup[element.comp_percnt] } // LOOKUP
            }

            exportData = {
                'SheetNames': sheets,
                'Sheets': sheetData
            }

            // console.log(exportData)

            var blob = new Blob([this.s2ab(XLSX.write(exportData, { bookType: 'xlsx', type: 'binary' }))], { type: "application/vnd.ms-excel" });
            saveAs(blob, "Lookahead Report " + moment().format() + '.xlsx');
        }
        catch (e) {
            console.log(e)
        }
    }

    render() {
        return (
            <>
                <div>
                    <AppBar color="primary">
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.props.closeReport} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                Lookahead Report
                            </Typography>
                            <IconButton edge="end" color="inherit" onClick={this.downloadReport} aria-label="close">
                                <DownloadIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <div style={{ marginTop: '70px' }}>
                        <MaterialTable
                            columns={this.state.columns}
                            data={this.state.data}
                            title={'Lookahead Report'}
                            options={{
                                toolbar: false,
                                pageSize: 20,
                                padding: 'dense',
                                doubleHorizontalScroll: true,
                                pageSizeOptions: [20, 50, 100, 500],
                                filtering: true,
                                // exportButton: { csv: true }
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default ResourceReportTable