import { Box, Card } from '@material-ui/core'
import React, { Component } from 'react'
import AppsIcon from '@material-ui/icons/Apps';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { getblueColor } from '../../../../ColorTheme/ColorTheme';
import { withSnackbar } from 'notistack';
import { screenSizeCheck } from '../../../../ScreensizeCheck/ScreensizeCheck';
import { newTabWithHashWithQuery } from '../../../../../Helper/CommonThings';
import WorkOffIcon from '@mui/icons-material/WorkOff';

class LevelResources extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <div>
                <Box style={{ width: '100%' }}>
                    <Card style={{ borderRadius: '20px 20px 20px 20px', backgroundColor: getblueColor() }}>
                        <Box display='flex' flexDirection={'column'} justifyContent={'left'} m={1}>
                            <Box fontSize={'18px'} fontWeight={'bold'} color='white' m={1}>TEAM WORKLOAD</Box>
                            <Box onClick={() => this.props.handleButton('3mla')} justifyContent="center" alignItems='center' display='flex' flexDirection='row' m={1} color='white' style={{ boxSizing: 'border-box', border: '4px solid #bebebe', borderRadius: '20px 20px 20px 20px', cursor: 'pointer' }}>
                                <Box justifyContent="flex-start" m={1} fontSize={screenSizeCheck() ? '16px' : '20px'} style={{ width: '100%' }}>
                                    3M - Look Ahead
                                </Box>
                                <Box justifyContent="flex-end" color='white' style={{ backgroundColor: '#EBB06E', boxSizing: 'border-box', border: '4px solid #bebebe', borderRadius: '10px 10px 10px 10px', }}>
                                    <VisibilityIcon style={{ fontSize: '40px', margin: '4px' }} />
                                </Box>
                            </Box>
                            <Box onClick={() => newTabWithHashWithQuery('Workload Report', '?workLoadReportTab=1')} justifyContent="center" alignItems='center' display='flex' flexDirection='row' m={1} color='white' style={{ boxSizing: 'border-box', border: '4px solid #bebebe', borderRadius: '20px 20px 20px 20px', cursor: 'pointer' }}>
                                <Box justifyContent="flex-start" m={1} fontSize={screenSizeCheck() ? '16px' : '20px'} style={{ width: '100%' }}>
                                    Workload
                                </Box>
                                <Box justifyContent="flex-end" color='white' style={{ backgroundColor: '#EBB06E', boxSizing: 'border-box', border: '4px solid #bebebe', borderRadius: '10px 10px 10px 10px', }}>
                                    <WatchLaterIcon style={{ fontSize: '40px', margin: '4px' }} />
                                </Box>
                            </Box>
                            <Box onClick={() => this.props.handleButton('allocatetasks')} justifyContent="center" alignItems='center' display='flex' flexDirection='row' m={1} color='white' style={{ boxSizing: 'border-box', border: '4px solid #bebebe', borderRadius: '20px 20px 20px 20px', cursor: 'pointer' }}>
                                <Box justifyContent="flex-start" m={1} fontSize={screenSizeCheck() ? '16px' : '20px'} style={{ width: '100%' }}>
                                    Allocate Tasks
                                </Box>
                                <Box justifyContent="flex-end" color='white' style={{ backgroundColor: '#EBB06E', boxSizing: 'border-box', border: '4px solid #bebebe', borderRadius: '10px 10px 10px 10px', }}>
                                    <CallSplitIcon style={{ fontSize: '40px', margin: '4px' }} />
                                </Box>
                            </Box>
                            <Box onClick={() => this.props.handleButton('teamsummary')} justifyContent="center" alignItems='center' display='flex' flexDirection='row' m={1} color='white' style={{ boxSizing: 'border-box', border: '4px solid #bebebe', borderRadius: '20px 20px 20px 20px', cursor: 'pointer' }}>
                                <Box justifyContent="flex-start" m={1} fontSize={screenSizeCheck() ? '16px' : '20px'} style={{ width: '100%' }}>
                                    Team Summary
                                </Box>
                                <Box justifyContent="flex-end" color='white' style={{ backgroundColor: '#EBB06E', boxSizing: 'border-box', border: '4px solid #bebebe', borderRadius: '10px 10px 10px 10px', }}>
                                    <AppsIcon style={{ fontSize: '40px', margin: '4px' }} />
                                </Box>
                            </Box>
                            <Box onClick={() => this.props.handleButton('overheads')} justifyContent="center" alignItems='center' display='flex' flexDirection='row' m={1} color='white' style={{ boxSizing: 'border-box', border: '4px solid #bebebe', borderRadius: '20px 20px 20px 20px', cursor: 'pointer' }}>
                                <Box justifyContent="flex-start" m={1} fontSize={screenSizeCheck() ? '16px' : '20px'} style={{ width: '100%' }}>
                                    Non Billable
                                </Box>
                                <Box justifyContent="flex-end" color='white' style={{ backgroundColor: '#EBB06E', boxSizing: 'border-box', border: '4px solid #bebebe', borderRadius: '10px 10px 10px 10px', }}>
                                    <WorkOffIcon style={{ fontSize: '40px', margin: '4px' }} />
                                </Box>
                            </Box>
                            <Box onClick={() => this.props.handleButton('newuserrequest')} justifyContent="center" alignItems='center' display='flex' flexDirection='row' m={1} color='white' style={{ boxSizing: 'border-box', border: '4px solid #bebebe', borderRadius: '20px 20px 20px 20px', cursor: 'pointer' }}>
                                <Box justifyContent="flex-start" m={1} fontSize={screenSizeCheck() ? '16px' : '20px'} style={{ width: '100%' }}>
                                    New User Request
                                </Box>
                                <Box justifyContent="flex-end" color='white' style={{ backgroundColor: '#EBB06E', boxSizing: 'border-box', border: '4px solid #bebebe', borderRadius: '10px 10px 10px 10px', }}>
                                    <PersonAddIcon style={{ fontSize: '40px', margin: '4px' }} />
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </div>
        )
    }
}

export default withSnackbar(LevelResources)