import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import MaterialTable from 'material-table';
import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import ManageTasks from './ManageTasks';
import Service from '../Networking/networkutils';
import { errorMessage } from '../Helper/ErrorMessage';
import LinkIcon from '@mui/icons-material/Link';
import CompanyCategoryTransferList from './CompanyCategoryTransferList';
import { withSnackbar } from 'notistack';

const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));
const token = getCookie("bb_ts_token");

class ManageTaskCategoryTables extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAdd: false,
            projName: '',
            save: false,
            linkAdd: false,
            rowData: {},
            isLoading: true,
            data: [],
        }
    }

    componentDidMount() {
        this.fetchOps()
    }

    fetchOps() {
        this.setState({ isLoading: true })
        let api = '/bb/api/task/category/company/get_company_list_by_category/'
        if ((access && access.access.l3a.l3a_e)) {
            api += '?type=all'
        }
        Service.get(api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    postData() {
        this.setState({ isLoading: true, });

        var json = {
            name: this.state.projName,
            created_by: user.id,
            pid: 11
        }

        Service.post("/bb/api/task/category/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: json,
        })
            .then(res => {
                this.setState({ openAdd: false, projName: '' });
                this.props.enqueueSnackbar('Category added successfully', {
                    variant: 'success'
                });
                this.fetchOps()
                if (this.props.refreshData) {
                    this.props.refreshData()
                }
            })
            .catch(error => {
                console.log(error)
                this.fetchOps()
                errorMessage(this.props, error)
            });
    }

    putData(asdata) {
        if (asdata.name) {
            this.setState({ isLoading: true })
            Service.put('/bb/api/task/category/' + asdata.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: asdata,
            })
                .then(res => {
                    this.props.enqueueSnackbar('Edited Successfully!', {
                        variant: 'success'
                    });
                    this.fetchOps()
                    if (this.props.refreshData) {
                        this.props.refreshData()
                    }
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                    this.fetchOps()
                });
        } else {
            this.props.enqueueSnackbar('Category name is empty!', {
                variant: 'warning'
            });
        }

    }

    render() {
        const handleClose = (e) => {
            this.setState({ openAdd: false, openImport: false, })
        };

        const handleName = (e) => {
            this.setState({ projName: e.target.value })
        };

        const handleLinkDialog = (e) => {
            this.setState({ linkAdd: false }, () => {
                this.fetchOps();
                if (this.props.refreshData) {
                    this.props.refreshData()
                }
            })
        }

        return (
            <div>
                <Box marginTop={3}>
                    <MaterialTable
                        title={(access && access.access && access.access.l3a.l3a_e) ? "Non Billable Categories" : 'Default Non Billable Categories'}
                        columns={[
                            { title: 'ID', field: 'id', editable: 'never' },
                            { title: 'Name', field: 'name' },
                            {
                                title: 'Linked SBU', field: 'company', editable: 'never',
                                hidden: (access && access.access && access.access.l3a.l3a_e) ? false : true,
                            },
                            {
                                title: 'Default Category', field: 'default_company_category', type: 'boolean',
                                headerStyle: { textAlign: 'right' },
                                cellStyle: { textAlign: 'right' },
                            },
                        ]}
                        data={this.state.data}
                        isLoading={this.state.isLoading}
                        options={{
                            padding: "dense",
                            paging: false,
                            actionsColumnIndex: -1,
                        }}

                        actions={
                            ((access && access.access && access.access.l3a.l3a_e) && (access && access.access && access.access.l3a.l3a_c) && (access && access.access && access.access.l3a.l3a_b)) ? ([
                                (rowData) => ({
                                    icon: () => <LinkIcon onClick={() => this.setState({ linkAdd: true, rowData: rowData })} />,
                                    disabled: rowData.default_company_category,
                                    tooltip: 'Link SBU'
                                }),
                                {
                                    icon: () => <Button variant="contained" color="primary" onClick={e => { this.setState({ openAdd: true }) }} style={{ margin: '5px' }}>Add</Button>,
                                    isFreeAction: true,
                                }
                            ]) :
                                ((access && access.access && access.access.l3a.l3a_e) && (access && access.access && access.access.l3a.l3a_c)) ? ([
                                    (rowData) => ({
                                        icon: () => <LinkIcon onClick={() => this.setState({ linkAdd: true, rowData: rowData })} />,
                                        disabled: rowData.default_company_category,
                                        tooltip: 'Link SBU'
                                    }),
                                ])
                                    :
                                    ((access && access.access && access.access.l3a.l3a_e) && (access && access.access && access.access.l3a.l3a_b)) ? ([
                                        {
                                            icon: () => <Button variant="contained" color="primary" onClick={e => { this.setState({ openAdd: true }) }} style={{ margin: '5px' }}>Add</Button>,
                                            isFreeAction: true,
                                        }
                                    ]) : (null)
                        }
                        editable={
                            ((access && access.access && access.access.l3a.l3a_e) && (access && access.access && access.access.l3a.l3a_c)) ? (
                                {
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    this.setState({ save: false });
                                                    this.setState(prevState => {
                                                        const data = this.state.data;
                                                        data[data.indexOf(oldData)] = newData;
                                                        this.putData(newData, data);
                                                        return { ...prevState, data };
                                                    });
                                                }
                                            }, 60);
                                        }),
                                }
                            ) : (null)}

                        detailPanel={rowData => {
                            return (
                                <ManageTasks value={rowData} editable={true} />
                            )
                        }}
                    />
                </Box>

                <Dialog open={this.state.openAdd} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add Category</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Warning : This can't be changed later. Please double check before submitting.
                        </DialogContentText>
                        <TextField id="outlined-basic" label="Name" variant="outlined"
                            value={this.state.projName}
                            onChange={handleName}
                            fullWidth
                            style={{ margin: '5px' }}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={e => this.postData()} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.linkAdd} fullWidth onClose={handleLinkDialog} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Category vs SBU - {(this.state.rowData.name)}</DialogTitle>
                    <DialogContent>
                        <Box margin={2}>
                            <CompanyCategoryTransferList category={this.state.rowData} company={this.props.data} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleLinkDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(ManageTaskCategoryTables)
