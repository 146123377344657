import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import Service from "../../Networking/networkutils";
import { getCookie } from '../../Helper/CookieHelper';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
// import RoleMapping from '../Estimate/Tender/RoleMapping';
// import SecuredProjectRoles from '../SecuredWorks/SecuredProjectRoles';
// import TaskLevelAllocation from './TaskLevelAllocation/TaskLevelAllocation';
import TaskLevelTabs from '../../Teams/TaskLevelAllocation/TaskLevelTabs';
// import ReactTooltip from 'react-tooltip';
import { errorMessage } from '../../Helper/ErrorMessage';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import { getPrimaryColor } from '../../Helper/CommonThings';


const findDuplicates = (arr) => {
    let sorted_arr = arr.slice().sort(); // You can define the comparing function here. 
    // JS by default uses a crappy string compare.
    // (we use slice to clone the array so the
    // original array won't be modified)
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] === sorted_arr[i]) {
            results.push(sorted_arr[i]);
        }
    }
    return results;
}

const token = getCookie("bb_ts_token");
// const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class TaskAllocationHelper extends Component {

    constructor(props) {
        super(props)

        this.state = {
            roles: [],
            deliSch: [],
            isLoading: false,
            isLoading1: true,
            tasks: [],
            wbs: [],
            mapped: [],
            rolesTemplate: [],
            columns: [],
            open: false,
            mapRoles: {},
            mapTask: {},
            projectLook: {},
            checkedB: false,
            proj_wbs_name: '',
            wbs_task_name: '',
            taskPercent: 0,
            openTaskUpdate: false,
            perTaskData: {},
            perTaskLoading: false,
            allRoles: {},
            selectedProject: [],
            projects: [],
            userPdm: [],
            openTaskLevelAllocation: false,
            userRolesInfo: [],
            searchUserRoles: false,
            findUser: {
                name: '',
                primary_role: '',
                secondary_role: ''
            },
            lead_diciplines: [
                { id: 'ohl', name: 'OHL' },
                { id: 'cabling', name: 'Cabling' },
                { id: 'substation', name: 'Substation' },
                { id: 'multi_utilities', name: 'Multi Utilities' },
                { id: 'technical', name: 'Technical' },
                { id: 'edc', name: 'EDC' }
            ],
            selectedLead: []
        }
    }

    componentDidMount() {
        Service.get('/bb/api/tender/pdm/?uid=' + user.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(respro => {
                this.setState({
                    userPdm: respro.data
                })

                Service.get(`/bb/api/user/with_sec_roles/?uid=` + this.props.alluid, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res13 => {
                        for (let i = 0; i < res13.data.length; i++) {
                            const element = res13.data[i];
                            element.name = element.first_name + " " + element.last_name;
                        }
                        this.setState({
                            userRolesInfo: res13.data,
                        })
                        this.fetchOps()
                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                        this.setState({ isLoading1: false })
                    });
            })
            .catch(error => {
                errorMessage(this.props, error)
                this.setState({ isLoading1: false })
            });
        this.getRoles()
    }

    getRoles() {
        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var allRoles = {}
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    allRoles[element.id] = element.name
                }
                this.setState({ allRoles: allRoles })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchOps() {
        Service.get("/bb/api/tender/project/?subproj=" + this.props.value.project_id + "&audit_status=10,11", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                // var projectLook = {};
                var rolesParam = '0';
                var indevwork = '0'
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    // rolesParam = rolesParam + ',' + element.id;

                    if (element.is_subproject && element.audit_status === 11) {

                        var goon = true;
                        for (let i = 0; i < res.data.length; i++) {
                            const element1 = res.data[i];
                            if (element.subproject_pid === element1.id) {
                                goon = false;
                                break;
                            }
                        }
                        if (goon) {
                            indevwork = indevwork + "," + element.subproject_pid
                        }

                    }

                }

                indevwork = Array.from(new Set(indevwork.split(','))).toString();

                var deliData = res.data;
                // console.log(deliData)

                for (let i = 0; i < deliData.length; i++) {
                    const element = deliData[i];
                    rolesParam = rolesParam + ',' + element.id;
                }

                this.setState({ deliSch: deliData, })

                Service.get(`/bb/api/tender/tasks/rolestasks/?tid=` + this.props.value.id + '&rid=' + this.props.roleid, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res2 => {

                        Service.get(`/bb/api/tender/ohlwbs/?id=` + this.props.value.wbs_id, {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res0 => {
                                // console.log(res0.data)

                                var taskArr = []
                                // var roles1 = res.data;
                                for (let i = 0; i < res2.data.length; i++) {
                                    const element = res2.data[i];
                                    element.req_hours = element.hours + element.req_hours
                                    taskArr.push(element.t_tasks)
                                }

                                taskArr = taskArr.filter(onlyUnique)

                                for (let i = 0; i < taskArr.length; i++) {
                                    const element = taskArr[i];
                                    var roleArr = []
                                    var roleArrIndex = [];
                                    for (let j = 0; j < res2.data.length; j++) {
                                        const element1 = res2.data[j];
                                        // console.log(element1)
                                        if (element1 && element === element1.t_tasks) {
                                            roleArr.push(element1.t_role)
                                            roleArrIndex.push(j)
                                        }
                                    }
                                    var roleArr1 = findDuplicates(roleArr)
                                    // var found1index = -1;
                                    for (let k = 0; k < roleArr1.length; k++) {
                                        const element2 = roleArr1[k];
                                        var hoursinAllRole = 0
                                        var found1index = -1;
                                        for (let j = 0; j < roleArrIndex.length; j++) {
                                            const element1 = res2.data[roleArrIndex[j]];

                                            if (element1 && element === element1.t_tasks && element2 === element1.t_role && found1index !== -1) {
                                                hoursinAllRole = hoursinAllRole + element1.req_hours
                                                delete res2.data[roleArrIndex[j]]
                                            } else if (element1 && element === element1.t_tasks && element2 === element1.t_role && found1index === -1) {
                                                hoursinAllRole = hoursinAllRole + element1.req_hours
                                                found1index = roleArrIndex[j]
                                            }
                                        }
                                        res2.data[found1index].all_hours_to_this_role = hoursinAllRole
                                    }

                                    for (let m = 0; m < roleArrIndex.length; m++) {
                                        const element2 = roleArrIndex[m];
                                        if (res2.data[element2] && !(res2.data[element2].all_hours_to_this_role || res2.data[element2].all_hours_to_this_role === 0)) {
                                            res2.data[element2].all_hours_to_this_role = res2.data[element2].req_hours
                                        }
                                    }

                                }

                                this.setState({ roles: res2.data, wbs: res0.data, })

                                Service.get('/bb/api/roles/?id=' + this.props.roleid, {
                                    headers: {
                                        Authorization: "Token " + token,
                                    },
                                })
                                    .then(res4 => {
                                        // console.log(res.data)

                                        this.setState({ rolesTemplate: res4.data }, () => {
                                            this.compileData()
                                        })
                                    })
                                    .catch(error => {
                                        errorMessage(this.props, error)
                                    });

                                // Service.get(`/bb/api/tender/user/task/?pid=` + rolesParam, {
                                //     headers: {
                                //         Authorization: "Token " + token,
                                //     },
                                // })
                                //     .then(res3 => {

                                //         Service.get('/bb/api/roles/', {
                                //             headers: {
                                //                 Authorization: "Token " + token,
                                //             },
                                //         })
                                //             .then(res4 => {
                                //                 // console.log(res.data)
                                //                 this.setState({ rolesTemplate: res4.data }, () => {
                                //                     this.compileData()
                                //                 })
                                //             })
                                //             .catch(error => {
                                //                 this.props.enqueueSnackbar('Something went wrong!', {
                                //                     variant: 'error'
                                //                 });
                                //             });

                                //         this.setState({ mapped: res3.data, })

                                //     })
                                //     .catch(error => {
                                //         this.props.enqueueSnackbar('Something went wrong!', {
                                //             variant: 'error'
                                //         });
                                //     });


                            })
                            .catch(error => {
                                errorMessage(this.props, error)
                            });



                    })
                    .catch(error => {

                        console.log(error)
                        errorMessage(this.props, error)
                    });



                // Service.get('/bb/api/tender/project/?id=' + indevwork, {
                //     headers: {
                //         Authorization: "Token " + token,
                //     },
                // })
                //     .then(respro => {

                //         deliData = deliData.concat(respro.data)

                //         for (let i = 0; i < deliData.length; i++) {
                //             const element = deliData[i];
                //             rolesParam = rolesParam + ',' + element.id;

                //             if (!element.is_subproject) {
                //                 if (element.tender_name) {
                //                     projectLook[element.id] = element.tender_name;
                //                 } else {
                //                     projectLook[element.id] = element.name;
                //                 }

                //             }

                //         }

                //         this.setState({ deliSch: deliData, projectLook: projectLook })


                //     })
                //     .catch(error => {
                //         this.props.enqueueSnackbar('Something went wrong!', {
                //             variant: 'error'
                //         });
                //     });

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    compileData() {

        var tasks = [];
        var onRole = [];
        var columnRoles = [];
        var tasksId = '0'

        for (let i = 0; i < this.state.roles.length; i++) {
            const element = this.state.roles[i];

            var taskPro = true;
            for (let j = 0; j < tasks.length; j++) {
                const element1 = tasks[j];
                if (element && element.t_tasks === element1.id) {
                    taskPro = false;
                }
            }

            if (element && taskPro) {
                if (!element.t_tasks_data.act_exp_start) {
                    element.t_tasks_data.act_exp_start = element.t_tasks_data.from_date
                }
                if (!element.t_tasks_data.act_exp_finish) {
                    element.t_tasks_data.act_exp_finish = element.t_tasks_data.to_date
                }
                tasks.push(element.t_tasks_data)
                tasksId = tasksId + ',' + element.t_tasks_data.id;
            }

            var rolesPro = true;
            for (let j = 0; j < onRole.length; j++) {
                const element1 = onRole[j];
                if (element && element.t_role === element1) {
                    rolesPro = false;
                }
            }

            if (element && rolesPro) {
                onRole.push(element.t_role)
            }

        }

        for (let i = 0; i < onRole.length; i++) {
            const element = onRole[i];
            for (let j = 0; j < this.state.rolesTemplate.length; j++) {
                const element1 = this.state.rolesTemplate[j];
                if (element === element1.id) {
                    columnRoles.push(element1)
                }
            }
        }

        // var columns = [];

        // columns.push({
        //     title: 'Project',
        //     field: 'ds.subproject_pid',

        //     lookup: this.state.projectLook,
        // })

        // columns.push({
        //     title: 'Deliverables Schedule',
        //     field: 'ds.name',

        // })

        // columns.push({
        //     title: 'WBS',
        //     field: 'wbs.name',

        // })
        // columns.push({
        //     title: 'Task ID', field: 'id', editable: 'never',

        // })
        // columns.push({
        //     title: 'Task',
        //     field: 'name',
        //     render: rowData => rowData.decs ? rowData.decs : rowData.name,
        // })

        // columns.push({
        //     title: 'Required Start Date',
        //     field: 'act_exp_start',
        //     type: 'date',

        // })
        // columns.push({
        //     title: 'Required End Date',
        //     field: 'act_exp_finish',
        //     type: 'date',

        // })
        // columns.push({
        //     title: '%', field: 'comp_percnt',
        //     lookup: { 0: '0%', 5: '5%', 10: '10%', 20: '20%', 30: '30%', 40: '40%', 50: '50%', 60: '60%', 65: '65%', 70: '70%', 80: '80%', 90: '90%', 100: '100%' },
        // })

        // // columns.push({
        // //     title: 'Allocated Hours',
        // //     field: 'pld_hrs',
        // //     defaultSort: (this.state.checkedB) ? ('desc') : (null),
        // //     render: rowData => (rowData.pld_hrs || rowData.pld_hrs === 0) ? (<Typography>{rowData.pld_hrs}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)
        // // })

        // // columns.push({
        // //     title: 'Assigned',
        // //     field: 'assign_users',
        // //     render: rowData => (rowData.assign_users || rowData.assign_users === '') ? (<Box fontSize={10}>{rowData.assign_users}</Box>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)
        // // })

        // // columns.push({
        // //     title: 'Actual Hours',
        // //     field: 'actual_hours',
        // //     render: rowData => (rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{rowData.actual_hours}</Typography>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)
        // // })

        // columns.push({
        //     title: 'Estimated Hours',
        //     field: 'est_hrs',
        //     // type: 'date',

        // })

        // columns.push({
        //     title: 'Task Level Allocation',
        //     field: 'dumm',
        //     // tyfiltering: false,
        //     render: rowData =>
        //         <div>
        //             <IconButton data-tip data-for={'happyFace'+rowData.id} onClick={() => { rowData.t_wbs_data = rowData.wbs; this.setState({ allData: rowData, openTaskLevelAllocation: true, }) }}><WorkIcon /></IconButton>
        //             <ReactTooltip id={'happyFace'+rowData.id} type='error'>
        //                 <spam>{(rowData.assign_users || rowData.assign_users === '') ? (<Box fontSize={10}>{rowData.assign_users}</Box>) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)}</spam>
        //             </ReactTooltip>
        //         </div>
        // })

        // var rolehere = '0'
        // for (let i = 0; i < columnRoles.length; i++) {
        //     const element = columnRoles[i];
        //     rolehere = rolehere + ',' + element.id;
        //     columns.push({
        //         title: element.name,
        //         field: element.id + ".name",
        //         filtering: false,
        //         render: rowData => (rowData[(element.id).toString()]) ? (<IconButton
        //             onClick={() => {
        //                 rowData[(element.id).toString()]['t_tasks_data']['wbs'] = rowData.wbs;
        //                 rowData[(element.id).toString()]['t_tasks_data']['ds'] = rowData.ds;
        //                 // console.log(rowData)
        //                 this.setState({
        //                     open: true, mapRoles: rowData[(element.id).toString()], mapTask: rowData, proj_wbs_name: this.state.projectLook[rowData.ds.subproject_pid.toString()] + " - " + rowData.ds.name,
        //                     wbs_task_name: rowData.wbs.name + " - " + rowData.decs ? rowData.decs : rowData.name,
        //                 })
        //             }}><AddIcon /></IconButton>) : (null)
        //     })
        // }

        var viewPid = []
        // console.log(res.data)
        for (let i = 0; i < tasks.length; i++) {
            const element = tasks[i];
            viewPid.push({ 'ttid': element.id })
            for (let j = 0; j < this.state.roles.length; j++) {
                const element1 = this.state.roles[j];
                if (element1 && element.id === element1.t_tasks) {
                    var json_key = element1.t_role.toString();
                    element[json_key] = element1;
                }
            }

            for (let j = 0; j < this.state.wbs.length; j++) {
                const element1 = this.state.wbs[j];
                if (element.t_ohlwbs === element1.id) {
                    element.wbs = element1;
                    // element.wbs = element1;
                }
            }

            for (let j = 0; j < this.state.deliSch.length; j++) {
                const element1 = this.state.deliSch[j];
                if (element.t_pid === element1.id) {
                    element.ds = element1;
                }
            }

            var est_hrs = 0;
            for (let j = 0; j < this.state.roles.length; j++) {
                const element1 = this.state.roles[j];
                // console.log(element1.t_tasks + "   " + element.id)
                if (element1 && element1.t_tasks === element.id) {
                    est_hrs = est_hrs + element1.all_hours_to_this_role;
                }
            }

            // var pld_hrs = 0;
            // for (let j = 0; j < res.data.length; j++) {
            //     const element1 = res.data[j];
            //     if (element1.task_id === element.id) {
            //         pld_hrs = pld_hrs + element1.planed_hours;
            //     }
            // }

            element.est_hrs = est_hrs;
            // element.pld_hrs = pld_hrs;

        }

        // if (this.state.checkedB) {
        //     tasks.sort(sortByProperty("pld_hrs"));
        // }

        // console.log(tasks)
        // rowData.t_wbs_data = rowData.wbs; this.setState({ allData: rowData, openTaskLevelAllocation: true, })
        // console.log(tasks)
        tasks[0].t_wbs_data = tasks[0].wbs
        this.setState({ tasks: tasks, isLoading: false, isLoading1: false, allData: tasks[0], openTaskLevelAllocation: true, }, () => {
            if (viewPid.length !== 0) {
                Service.patch("/bb/api/team/tasks/get_acthours/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: viewPid,
                })
                    .then(res12 => {

                        for (let j = 0; j < res12.data.length; j++) {
                            const element13 = res12.data[j];
                            for (let i = 0; i < tasks.length; i++) {
                                const element12 = tasks[i];
                                if (element12.id === element13.id) {
                                    element12.actual_hours = timeToDecimal(element13.hours_actual)
                                    element12.pld_hrs = element13.allocated
                                    break;
                                }
                            }
                        }
                        this.setState({
                            tasks: tasks,
                        })
                        Service.patch("/bb/api/tender/wbs/tasks/get_assignusers/", {
                            headers: {
                                Authorization: "Token " + token,
                            },
                            data: viewPid,
                        })
                            .then(res21 => {
                                for (let j = 0; j < tasks.length; j++) {
                                    const element13 = tasks[j];
                                    var names = ''
                                    for (let i = 0; i < res21.data.length; i++) {
                                        const element12 = res21.data[i];
                                        if (element12.id === element13.id) {
                                            for (let k = 0; k < element12.users.length; k++) {
                                                const element14 = element12.users[k];
                                                if (names !== '') {
                                                    names = names + ", " + element14.first_name + "(" + element14.hours + ")"
                                                } else {
                                                    names = element14.first_name + "(" + element14.hours + ")"
                                                }
                                            }
                                        }
                                    }
                                    element13.assign_users = names
                                }
                                this.setState({
                                    tasks: tasks,
                                })
                            })
                            .catch(error => {
                                this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                                    variant: 'error'
                                });
                            });
                    })
                    .catch(error => {
                        this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                            variant: 'error'
                        });
                    });
            }
        })
    }

    isProjPdm(pid, rowData) {
        for (let i = 0; i < this.state.userPdm.length; i++) {
            const element = this.state.userPdm[i];
            if (element.pid === pid && (element.allocation_access === 2 || (element.allocation_access === 1 && rowData.wbs.type === element.pdm_type))) {
                return true
            }
        }
        return false
    }

    projectPdm(rowData) {
        try {
            for (let i = 0; i < this.state.selectedProject.length; i++) {
                const element = this.state.selectedProject[i];
                if (rowData.ds.subproject_pid === element.id && (element.tenderbid_data.pdm === user.id || this.isProjPdm(element.id, rowData))) {
                    return true
                }
            }
            return false
        } catch (err) {
            return false
        }

    }

    render() {

        const handleClose = () => {
            this.setState({ open: false, openTaskLevelAllocation: false, })
        };

        return (

            <>
                {!this.state.isLoading1 ?
                    <Dialog
                        open={this.state.openTaskLevelAllocation}
                        fullWidth={true}
                        maxWidth={'xl'}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description">
                        <DialogTitle id="customized-dialog-title2" >
                            {this.state.allData ? this.props.value.project_name + " -> " + this.state.allData.id + " " + this.state.allData.name : null}
                        </DialogTitle>
                        <DialogContent>
                            <TaskLevelTabs value={this.state.allData} task={this.state.allData} team={this.props.team} pdm={this.projectPdm(this.state.allData)} rolesLookUp={this.state.allRoles} projectLook={{ [this.props.value.project_id]: this.props.value.project_name }} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Close
                            </Button>

                        </DialogActions>
                    </Dialog>

                    : <LoopCircleLoading color={getPrimaryColor()} />}
            </>
        )
    }
}

// function sortByProperty(property) {
//     return function (a, b) {
//         if (a[property] > b[property])
//             return 1;
//         else if (a[property] < b[property])
//             return -1;

//         return 0;
//     }
// }

function addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec ? 1 : null
    if (len) {
        return parseFloat(Number(num).toFixed(len))
    } else {
        return parseFloat(Number(num))
    }
}

function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export default withSnackbar(TaskAllocationHelper);