import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import moment from 'moment'
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { withStyles, Box, Button } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { LoopCircleLoading } from 'react-loadingg';
import MaterialTable from 'material-table'
// import BalfourBeattyHeader from '../CommonTips/BalfourBeattyHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getCookie } from '../Helper/CookieHelper';
import ReactGA from 'react-ga';
import BalfourBeattyHeader from '../CommonTips/BalfourBeattyHeader';
import { errorMessage } from '../Helper/ErrorMessage';
import { getDarkMode, getPrimaryColor, tenderTaskStatus } from '../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#00b300',
            contrastText: '#fff',
        },
        secondary: {
            main: '#cca300',
            contrastText: '#fff',
        },
        error: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        type: getDarkMode(),
    },
})

// const bbColorThemered = createTheme({
//     palette: {
//         primary: {
//             main: "#ff0000",
//             contrastText: '#fff',
//         },
//         secondary: {
//             main: '#cca300',
//             contrastText: '#fff',
//         },
//         error: {
//             main: "#ff0000",
//             contrastText: '#fff',
//         },
//     },
// })

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const useStyles = (theme) => ({


});

var mini_table_data_start = 2

class TimeSheetOpsView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            data1: [],
            tabledata: [],

            columns: [
                { title: 'Emp Name', field: 'uname' },
                { title: 'Team', field: 'gname' },
                { title: 'Projects', field: 'proj_name' },

            ],

            openCal: false,
            toDia: [[], []],
            toDia2: [[], []],
            isLoading: true,
            selectDate: new Date(),
            openSub: false,
            adddata: [],
            postComment: '',
            is_approved: true,
            is_reject: true,
            enableDelete: false,
        }

    }


    componentDidMount() {

        var pageView = window.location.pathname;
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!token) {
            window.location.href = '/'
        }
        this.fetchOps();
    }

    fetchOps() {
        Service.get("/bb/api/task/approve/" + this.props.match.params.appid + "/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                mini_table_data_start = res.data.comment ? 2 : 1
                var toId = "";
                for (let i = 0; i < res.data.list_tasks[1][1].length; i++) {
                    const element = res.data.list_tasks[1][1][i];
                    if (i === 0) {
                        toId = "" + element.id;
                    } else {
                        toId = toId + "," + element.id;
                    }
                }
                var dataFr = res.data;

                if (res.data.uemail !== user.email) {
                    alert("User not authenticated")
                    window.location.href = '/'
                }

                if (res.data.approve_status === 0) {
                    Service.get("/bb/api/team/tasks/?id=" + toId, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res => {
                            var datapull = [];
                            for (let i = 0; i < res.data.length; i++) {
                                const element = res.data[i];
                                var date = moment(element.start).format('DD/MM/YYYY')
                                var stime = moment(element.start).format('HH mm A');
                                var etime = moment(element.end).format('HH mm A');
                                var whours = moment.duration(moment(element.end).diff(moment(element.start)));
                                var whours1 = ''
                                //console.log(whours.asHours())
                                if (parseFloat(whours.asHours()) < 0.9) {
                                    whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                                } else {
                                    whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                                }

                                if (element.is_tender === 1) {
                                    datapull.push({
                                        id: element.id,
                                        uid: element.uid,
                                        gid: element.gid,
                                        tid: element.tid,
                                        pid: element.pid,
                                        ttid: element.ttid,
                                        tpid: element.tpid,
                                        twbsid: element.twbsid,
                                        trolesid: element.trolesid,
                                        tmapid: element.tmapid,
                                        uemail: element.uemail,
                                        uname: element.uname_first + " " + element.uname_last,
                                        gname: element.gname,
                                        tname: element.tname,
                                        tproj_name: element.tproj_name,
                                        ttname: element.ttdecs ? element.ttdecs : element.ttname,
                                        tuemail: element.tuemail,
                                        twbsname: element.twbsname,
                                        trolename: element.trolename,
                                        ttender_name: element.ttender_name,
                                        title: element.tproj_name + "(" + element.ttdecs ? element.ttdecs : element.ttname + " - " + element.ttdecs + ")",
                                        proj_name: element.proj_name,
                                        work_detail: element.work_detail,
                                        comments: element.comments,
                                        date: date,
                                        start: new Date(element.start),
                                        end: new Date(element.end),
                                        is_locked: element.is_locked,
                                        is_submitted: element.is_submitted,
                                        is_approved: element.is_approved,
                                        strstart: element.start,
                                        strend: element.end,
                                        stime: stime,
                                        etime: etime,
                                        estimated_hours: element.estimated_hours,
                                        created_by: element.created_by,
                                        whours: whours1,
                                        is_tender: element.is_tender,
                                        actual_hours: element.actual_hours,
                                        task_hold: element.task_hold,
                                        ttstatus: element.ttstatus,
                                    });
                                } else if (element.is_tender === 0) {
                                    datapull.push({
                                        id: element.id,
                                        uid: element.uid,
                                        gid: element.gid,
                                        tid: element.tid,
                                        pid: element.pid,
                                        ttid: element.ttid,
                                        tpid: element.tpid,
                                        twbsid: element.twbsid,
                                        trolesid: element.trolesid,
                                        tmapid: element.tmapid,
                                        uemail: element.uemail,
                                        uname: element.uname_first + " " + element.uname_last,
                                        gname: element.gname,
                                        tname: element.tname,
                                        tproj_name: element.tproj_name,
                                        ttname: element.ttdecs ? element.ttdecs : element.ttname,
                                        tuemail: element.tuemail,
                                        twbsname: element.twbsname,
                                        trolename: element.trolename,
                                        ttender_name: element.ttender_name,
                                        title: element.proj_name + "(" + element.tname + ")",
                                        proj_name: element.proj_name,
                                        work_detail: element.work_detail,
                                        comments: element.comments,
                                        date: date,
                                        start: new Date(element.start),
                                        end: new Date(element.end),
                                        is_locked: element.is_locked,
                                        is_submitted: element.is_submitted,
                                        is_approved: element.is_approved,
                                        strstart: element.start,
                                        strend: element.end,
                                        stime: stime,
                                        etime: etime,
                                        estimated_hours: element.estimated_hours,
                                        created_by: element.created_by,
                                        whours: whours1,
                                        is_tender: element.is_tender,
                                        actual_hours: element.actual_hours,
                                        task_hold: element.task_hold,
                                        ttstatus: element.ttstatus,
                                    });
                                }
                            }

                            var enableDelete = false
                            if (datapull.length === 0 && (dataFr.request_to_id === user.id || dataFr.uid === user.id)) {
                                enableDelete = true
                            }

                            //console.log(res.data)
                            this.setState({ data: dataFr, data1: datapull, enableDelete: enableDelete }, () => {
                                this.handleColumns()
                                this.handleColumns2()
                            })
                        })
                        .catch(error => {
                            // console.log(error)
                            errorMessage(this.props, error)
                        });
                } else {
                    this.setState({ data: dataFr, data1: dataFr.list_tasks[1][1] }, () => {
                        this.handleColumns()
                        this.handleColumns2()
                    })
                }

            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    deleteItem() {
        Service.delete("/bb/api/task/approve/" + this.props.match.params.appid + "/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Deleted Successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {

                errorMessage(this.props, error)

            });
    }

    handleColumns() {

        var columns = [
            { title: 'Emp Name', field: 'uname' },

            // { title: 'Projects', field: 'proj_name' },
            // { title: 'Tasks', field: 'tname' },
        ];

        if (this.state.data.list_tasks[0][0][1].field !== 'comment' && this.state.data.comment) {
            columns.push({ title: 'Comment', field: 'comment' });
        }

        for (let i = 1; i < this.state.data.list_tasks[0][0].length; i++) {
            const element = this.state.data.list_tasks[0][0][i];
            columns.push(
                {
                    title: element.title, field: element.field, filtering: element.filtering,
                    render: rowData => <ThemeProvider theme={bbColorTheme}><Typography color={(parseInt(rowData[element.field]) < 4) ? ("error") : (parseInt(rowData[element.field]) > 7) ? ("primary") : ("secondary")} >{rowData[element.field]}</Typography></ThemeProvider>
                }
            )
        }

        var statedata = this.state.data1;
        var doneuid = [];
        var toTData = [];
        var userelement = [];

        for (let i = 0; i < statedata.length; i++) {
            var userelement2 = [];
            const element = statedata[i];

            element[element.date] = element.whours;

            if (i === 0) {
                doneuid.push(element.uid);
                for (let j = 0; j < statedata.length; j++) {
                    const element1 = statedata[j];
                    if (element.uid === element1.uid) {

                        userelement2.push(element1)
                    }
                }
            } else {
                var goodtogo = true;
                for (let k = 0; k < doneuid.length; k++) {
                    const element3 = doneuid[k];

                    if (element3 === element.uid) {
                        goodtogo = false;
                    }
                }

                if (goodtogo) {
                    doneuid.push(element.uid);
                    for (let j = 0; j < statedata.length; j++) {
                        const element1 = statedata[j];
                        if (element.uid === element1.uid) {

                            userelement2.push(element1)
                        }

                    }
                }
            }

            if (userelement2.length !== 0) {
                userelement.push(userelement2)
            }

        }

        //date
        for (let i = 0; i < userelement.length; i++) {
            var whdate = [];
            var wh = [];
            var teams = [];
            var proj = [];
            var tasks = [];
            const element = userelement[i];
            for (let j = 0; j < element.length; j++) {
                const element1 = element[j];
                var goodtogo1 = true;
                for (let k = 0; k < whdate.length; k++) {
                    const element2 = whdate[k];
                    if (element2 === element1.date) {
                        goodtogo1 = false;
                    }

                }

                if (goodtogo1) {
                    // console.log('Total 111 time is')
                    whdate.push(element1.date);
                    var hours = []
                    for (let m = 0; m < element.length; m++) {
                        const element3 = element[m];
                        if (element3.date === element1.date) {
                            hours.push(element3[element3.date])
                        }
                        var teamgo = true;
                        for (let n = 0; n < teams.length; n++) {
                            const element4 = teams[n];
                            if (element4 === element3.gname) {
                                teamgo = false;
                            }
                        }
                        if (teamgo) {
                            teams.push(element3.gname)
                        }
                        var projgo = true;
                        for (let n = 0; n < proj.length; n++) {
                            const element4 = proj[n];
                            if (element3.is_tender === 0) {
                                if (element4 === element3.proj_name) {
                                    projgo = false;
                                }
                            } else {
                                if (element4 === element3.ttender_name + " - " + element3.tproj_name) {
                                    projgo = false;
                                }
                            }

                        }
                        //console.log(element3.proj_name)
                        if (projgo) {
                            if (element3.is_tender === 0) {
                                proj.push(element3.proj_name)
                            } else {
                                proj.push(element3.ttender_name + " - " + element3.tproj_name)
                            }

                        }
                        var taskgo = true;
                        for (let n = 0; n < tasks.length; n++) {
                            const element4 = tasks[n];
                            if (element3.is_tender === 0) {
                                if (element4 === element3.tname) {
                                    taskgo = false;
                                }
                            } else {
                                if (element4 === element3.ttname) {
                                    taskgo = false;
                                }
                            }

                        }
                        //console.log(element3.proj_name)
                        if (taskgo) {
                            if (element3.is_tender === 0) {
                                tasks.push(element3.tname)
                            } else {
                                tasks.push(element3.ttname)
                            }

                        }
                    }
                    // console.log(hours)
                    const totalDurations = hours.slice(1)
                        .reduce((prev, cur) => moment.duration(cur).add(prev),
                            moment.duration(hours[0]))
                    wh.push(moment.utc(totalDurations.asMilliseconds()).format("HH:mm"))
                }

            }
            var jsoncons = {
                id: element[0].id,
                uid: element[0].uid,
                gid: element[0].gid,
                pid: element[0].pid,
                tid: element[0].tid,
                ttid: element[0].ttid,
                tpid: element[0].tpid,
                twbsid: element[0].twbsid,
                trolesid: element[0].trolesid,
                tmapid: element[0].tmapid,
                tname: tasks.join(),
                tproj_name: element[0].tproj_name,
                ttname: element[0].ttname,
                tuemail: element[0].tuemail,
                twbsname: element[0].twbsname,
                trolename: element[0].trolename,
                uemail: element[0].uemail,
                uname: element[0].uname,
                gname: teams.join(),
                title: element[0].title,
                proj_name: proj.join(),
                work_detail: element[0].work_detail,
                start: element[0].start,
                end: element[0].end,
                date: element[0].date,
                stime: element[0].stime,
                etime: element[0].etime,
                whours: element[0].whours,
                created_by: element[0].created_by,
                is_tender: element.is_tender,
            }
            for (let k = 0; k < whdate.length; k++) {
                const element = whdate[k];
                jsoncons[element] = wh[k];
            }
            const totalDurations11 = wh.slice(1)
                .reduce((prev, cur) => moment.duration(cur).add(prev),
                    moment.duration(wh[0]));
            var minin = (moment.duration(totalDurations11.asMilliseconds()));
            var minin2 = moment.utc(totalDurations11.asMilliseconds()).format("mm")
            jsoncons["total"] = parseInt(minin.asHours()) + ":" + minin2;
            toTData.push(jsoncons);
        }

        var todata = [];
        // console.log(columns)
        todata.push(columns)
        todata.push(toTData)
        //console.log(todata)
        this.setState({
            toDia: todata,
        })

    }

    handleColumns2() {
        var columns = [
            {
                title: 'Projects', field: 'ttender_name', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                render: rowData => (rowData.proj_name ? rowData.proj_name : rowData.ttender_name + " - " + rowData.tproj_name + " - " + rowData.twbsname)
            },
            {
                title: 'Tasks', field: 'tname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                render: rowData => (rowData.tname ? rowData.tname : (rowData.ttdecs ? rowData.ttdecs : rowData.ttname))
            },
            {
                title: 'Status', field: 'task_hold',
                render: rowData => (!rowData.task_hold && rowData.task_hold !== 0) ? "-" : rowData.task_hold ? <h4 style={{ color: 'red' }}>On Hold</h4> : tenderTaskStatus(rowData.ttstatus)
            },
            // { title: 'Tasks', field: 'tname', headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }, },
            { title: 'Comment', field: 'comments', headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }, },
            { title: 'Hours Expended', field: 'actual_hours' },
        ];
        var i_temp = 6
        for (let i = 0; i < this.state.data.list_tasks[1][0].length; i++) {
            const element = this.state.data.list_tasks[1][0][i];
            if (element.field === 'actual_hours') {
                i_temp = i + 1
            }
        }
        for (let i = i_temp; i < this.state.data.list_tasks[1][0].length; i++) {
            const element = this.state.data.list_tasks[1][0][i];
            columns.push(
                {
                    title: element.title, field: element.field, filtering: element.filtering,
                    render: rowData => <Typography color={("primary")}>{rowData[element.field]}</Typography>
                }
            )
        }

        var todata = [];
        todata.push(columns);
        todata.push(this.state.data1)

        var is_approve = true;
        var is_reject = true;
        for (let i = 0; i < this.state.data1.length; i++) {
            const element = this.state.data1[i];

            element[element.date] = element.whours;
            //console.log(element.is_approved)
            if (element.is_approved !== 1) {
                is_approve = false;
            }
            if (element.is_approved !== 2) {
                is_reject = false;
            }
        }

        this.setState({
            toDia2: todata,
            is_approved: is_approve,
            is_reject: is_reject,
            isLoading: false
        })

        // console.log(todata[1].length)

    }

    render() {

        return (
            <div>
                <BalfourBeattyHeader />
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>

                        <MaterialTable
                            key={1}
                            title={"Timesheet booked by " + (this.state.toDia[1][0] ? this.state.toDia[1][0].uname : "") + " for week [" + (this.state.toDia[0][1] ? this.state.toDia[0][2].title : "") + " - " + (this.state.toDia[0][7] ? this.state.toDia[0][8].title : "") + "]"}
                            columns={this.state.toDia2[0]}
                            data={this.state.toDia2[1]}
                            options={{
                                padding: "dense",
                                filtering: true,
                                pageSize: this.state.toDia2[1].length < 11 ? this.state.toDia2[1].length : 10,
                                pageSizeOptions: [10, 20, 50],
                                exportButton: { csv: true },
                            }}
                        />
                        <ThemeProvider theme={bbColorTheme} >
                            <TableContainer style={{ marginTop: '25px' }} component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            {this.state.toDia[0].map((row) => (
                                                <StyledTableCell key={row.title} align="center">{row.title}</StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.toDia[1].map((row) => (
                                            <StyledTableRow key={row.uname}>
                                                <StyledTableCell component="th" align="center" scope="row">
                                                    {row.uname}
                                                </StyledTableCell>
                                                {this.state.data.comment ? <StyledTableCell component="th" align="center" scope="row">
                                                    {this.state.data.comment}
                                                </StyledTableCell> : null}
                                                <StyledTableCell align="center">
                                                    <Typography >{row[this.state.toDia[0][mini_table_data_start].field]}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Typography >{row[this.state.toDia[0][mini_table_data_start + 1].field]}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Typography >{row[this.state.toDia[0][mini_table_data_start + 2].field]}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Typography >{row[this.state.toDia[0][mini_table_data_start + 3].field]}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Typography >{row[this.state.toDia[0][mini_table_data_start + 4].field]}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Typography >{row[this.state.toDia[0][mini_table_data_start + 5].field]}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Typography >{row[this.state.toDia[0][mini_table_data_start + 6].field]}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Typography >{row["total"]}</Typography>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </ThemeProvider>
                        <Box display="flex" justifyContent="center" m={2} p={2} bgcolor="background.paper">
                            {(this.state.data.approve_status === 1) ? (
                                <ThemeProvider theme={bbColorTheme} ><Typography component={'span'} style={{ marginTop: '25px', marginLeft: '10px', marginRight: '10px' }} color="primary" ><Box fontWeight="fontWeightBold">{"Time Sheet is approved" + ((this.state.data.replied_by) ? (" by " + this.state.data.replied_by_data.last_name + ", " + this.state.data.replied_by_data.first_name + " on " + moment(this.state.data.replied_on).format('DD/MM/YYYY HH:mm a')) : ".")}</Box></Typography></ThemeProvider>
                            ) : (this.state.data.approve_status === 2) ? (
                                <ThemeProvider theme={bbColorTheme} ><Typography component={'span'} style={{ marginTop: '25px', marginLeft: '10px', marginRight: '10px' }} color="error" ><Box fontWeight="fontWeightBold">{"Time Sheet is rejected" + ((this.state.data.replied_by) ? (" by " + this.state.data.replied_by_data.last_name + ", " + this.state.data.replied_by_data.first_name + " on " + moment(this.state.data.replied_on).format('DD/MM/YYYY HH:mm a')) : ".")}</Box></Typography></ThemeProvider>
                            ) : (
                                <ThemeProvider theme={bbColorTheme} ><Typography component={'span'} style={{ marginTop: '25px', marginLeft: '10px', marginRight: '10px' }} color="secondary" ><Box fontWeight="fontWeightBold">Time Sheet is not approved yet.</Box></Typography></ThemeProvider>
                            )}
                        </Box>

                        {(this.state.enableDelete) ? (
                            <ThemeProvider theme={bbColorTheme} >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ marginLeft: '10px', marginRight: '10px' }}
                                    onClick={() => { this.deleteItem() }}
                                >
                                    Delete Time Sheet
                                </Button>


                            </ThemeProvider>
                        ) : (null)}

                    </div>
                )}
            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(TimeSheetOpsView));