import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import { LoopCircleLoading } from 'react-loadingg';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { blue } from '@material-ui/core/colors';
import AddTender from './AddTender'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Summary from './Summary';
import { errorMessage } from '../../Helper/ErrorMessage';
// import ReactGA from 'react-ga';
// import Moment from 'react-moment';
import moment from "moment";
import { getCookie } from '../../Helper/CookieHelper';
import { getDarkMode, getPrimaryColor } from '../../Helper/CommonThings';
import AllRateTabs from '../Forms/AllRateTabs';

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
// const user = JSON.parse(getCookie("bb_ts_user"));

// var dialogmsg = "";
// var submitdata = null;
class TenderProjects extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            rates: this.props.rates,
            data2: this.props.roles,
            open: false,
            isLoading: true,
            isLoading1: true,
            allData: 'null',
            openEdit: false,
            openCalc: false,
            key: 0,
            subproj: [],
            actions: [],
        }
    }


    componentDidMount() {
        // console.log("twice")
       this.fetchOps()
        
    }

    putOps(newData) {
        Service.put('/bb/api/tender/project/' + newData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                id: newData.id,
                name: newData.name,
                audit_status: newData.audit_status,
            }
        })
            .then(res => {
                this.props.enqueueSnackbar('Updated Successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    fetchOps() {
        

        if (!token) {
            window.location.href = '/'
        }
        Service.get('/bb/api/tender/project/estimates_ds/?pid=' + this.props.value.id , {
            headers: {
                Authorization: "Token " + token,
            },
            
        })
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoading: false,
                    isLoading1: false
                });
        
                this.setAccessIcons();
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });


    }

    setAccessIcons() {

        var actions = [];
        if ((access && access.access && access.access.l6a && access.access.l6a.l6a_l) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
            actions.push(rowData => ({
                icon: 'functions',
                tooltip: 'Summary',
                onClick: (event, rowData) => { this.setState({ allData: rowData, openCalc: true, }) },
                disabled: (rowData.audit_status === 0 || rowData.audit_status === 4)
            }))
        }
        if ((access && access.access && access.access.l6a && access.access.l6a.l6a_f) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
            actions.push(rowData => ({
                icon: 'book',
                tooltip: 'Estimate',
                onClick: (event, rowData) => { this.setState({ allData: rowData, key: 2, open: true, }) },
                disabled: !(rowData.audit_status === 0 || rowData.audit_status === 4 || rowData.audit_status === 10 || (rowData.audit_status === 11 && (access && access.access && access.access.l6a && access.access.l6a.l6a_n)))
            }))
        }
        if ((access && access.access && access.access.l6a && access.access.l6a.l6a_i) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
            actions.push(rowData => ({
                icon: 'assessment',
                tooltip: 'Mini Report',
                onClick: (event, rowData) => { window.open('/ds/mini/report/' + rowData.id + "/" + this.props.value.id) },

            }))
        }

        this.setState({ actions })
    }

    // giveaccess() {

    //     var json = {
    //         email: submitdata.email,
    //         is_staff: true,
    //         is_lead: true,
    //         is_admin: !submitdata.is_admin,
    //     }

    //     Service.put("/bb/api/tender/project/" + submitdata.id + "/", {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //         data: json,
    //     })
    //         .then(res => {

    //             this.setState({ open: false, });
    //             // console.log(res.data)
    //             this.props.enqueueSnackbar('Permission changed for ' + submitdata.email, {
    //                 variant: 'success'
    //             });
    //             this.fetchOps();
    //         })
    //         .catch(error => {
    //             // console.log(error)
    //             this.props.enqueueSnackbar('Something went wrong!', {
    //                 variant: 'error'
    //             });
    //         });
    // }
    costCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_pid === rowData.id) {
                cost = cost + this.costCalc2(element)
            }
        }
        return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
    }

    costCalc2(data1) {
        // console.log(data1)
        for (let j = 0; j < this.state.rates.length; j++) {
            const element1 = this.state.rates[j];
            if (element1.t_pid === data1.t_pid) {
                if (data1.country === 'in' || data1.country === 'sl') {
                    for (let i = 0; i < this.state.rates[j].t_rates.length; i++) {
                        const element = this.state.rates[j].t_rates[i];
                        if (data1.grade.toUpperCase() === element.grade) {
                            // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                            return data1.hours * element.costin;
                        }
                    }
                } else {
                    for (let i = 0; i < this.state.rates[j].t_rates.length; i++) {
                        const element = this.state.rates[j].t_rates[i];
                        if (data1.grade.toUpperCase() === element.grade) {
                            return data1.hours * element.costuk;
                        }
                    }
                }
                break;
            }
        }

        return 0;
    }

    hourCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_pid === rowData.id) {
                cost = cost + parseFloat(element.hours);
            }
        }
        return cost.toFixed(2);
    }

    dateParse(date) {
        var formatDate = moment(date).format('DD/MM/YYYY');
        // console.log(formatDate)
        if (formatDate === 'Invalid date') {
            return 'NA';
        } else {
            return formatDate;
        }
    }


    render() {
        const { classes } = this.props;
        const handleClose = () => {
            this.setState({ open: false, isLoading1: true });
            this.fetchOps();
            // this.props.onReload();
        };

        const handleClose1 = () => {
            this.setState({ openEdit: false, });
        };

        const handleClose2 = () => {
            this.setState({ openCalc: false, });
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <MaterialTable
                            title="Deliverables Schedule"

                            columns={[
                                {
                                    title: 'id', field: 'id',

                                },
                                {
                                    title: 'Budget / Change', field: 'type', editable: 'never', headerStyle: { minWidth: 30 }, cellStyle: { minWidth: 30 },
                                    lookup: { 0: 'Budget', 1: 'Change', },
                                },
                                { title: 'Deliverable Schedule', field: 'name', headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 }, },
                                {
                                    title: 'Start Date', field: 'from_date', editable: 'never',
                                    render: rowData => this.dateParse(rowData.from_date)
                                },
                                {
                                    title: 'Finish date', field: 'to_date', editable: 'never',
                                    render: rowData => this.dateParse(rowData.to_date)
                                },
                                {
                                    title: 'Hours', field: 'budget_hours', editable: 'never',
                                    render: rowData => rowData.budget_hours
                                },
                                {
                                    title: 'Amount', field: 'budget_cost', editable: 'never',
                                    render: rowData => new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.budget_cost)
                                },
                                {
                                    title: 'Stage', field: 'audit_status', editable: (access && access.access && access.access.l6a && access.access.l6a.l6a_m) ? ('onUpdate') : ('never'),
                                    lookup: {
                                        0: 'Stage 2 - Tender Development', 1: 'Stage 2 - Awaiting Approval', 2: 'Stage 2 - Submission Approved', 3: 'Stage 3 - Tender Submitted', 4: 'Stage 3a - Tender Repricing', 5: 'Stage 3a - Awaiting Approval',
                                        6: 'Stage 3a - Approved', 7: 'Stage 3a - Re-Submission', 8: 'Stage 4 - Awarded', 9: 'Stage 4 - Lost', 10: 'Stage 5 - Mobilising', 13: 'Stage 6a - Design Completed', 11: 'Stage 1a - Tender Mobilising', 12: 'Stage 1b - Tender Mobilised', 100: ''
                                    },
                                },
                            ]}
                            style={{ backgroundColor: getDarkMode() === 'dark' ? '#4f7e76' : '#AFD6CB' }}
                            data={this.state.data}
                            actions={this.state.actions

                                // rowData => ({
                                //     icon: 'check_circle',
                                //     tooltip: 'Activate',
                                //     onClick: (event, rowData) => this.handleDialog(true, rowData),
                                //     disabled: rowData.is_active
                                // }),
                                // rowData => ({
                                //     icon: 'cancel',
                                //     tooltip: 'Inactivate',
                                //     onClick: (event, rowData) => this.handleDialog(false, rowData),
                                //     disabled: !rowData.is_active
                                // })
                            }
                            options={{
                                padding: "dense",
                                pageSize: this.state.data.length,
                                paging: false,
                                pageSizeOptions: [10, 20, 50],

                                actionsColumnIndex: 6,
                                headerStyle: {
                                    backgroundColor: getDarkMode() === 'dark' ? '#4f7e76' : '#AFD6CB',

                                }
                            }}
                            editable={
                                ((access && access.access && access.access.l6a && access.access.l6a.l6a_m)) ? (
                                    {

                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            this.putOps(newData)
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 600);
                                            }),
                                    }
                                ) : (null)}
                        />
                        <Dialog fullScreen open={this.state.open} onClose={handleClose} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        {(this.state.allData === 'null' && this.state.subproj.length === 1) ? (
                                            "Add Deliverables Schedule"
                                        ) : (this.state.allData === 'null' && this.state.subproj.length === 0) ? (
                                            "Add Tender"
                                        ) : (
                                            this.state.allData.name
                                        )}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            {(this.state.allData.audit_status === 10) ? (
                                <AllRateTabs value={this.state.allData} />
                            ) : (
                                <AddTender page={this.state.key} onprojedit={false} tender={this.props.value} value={this.state.allData} subproj={this.state.subproj} />

                            )}
                        </Dialog>
                        <Dialog onClose={handleClose1} aria-labelledby="simple-dialog-title" open={this.state.openEdit}>
                            <DialogTitle id="simple-dialog-title">Choose your edit</DialogTitle>
                            <List>
                                <ListItem autoFocus button onClick={() => this.setState({ key: 0, open: true, openEdit: false })}>

                                    <ListItemText primary="Project Edit" />

                                </ListItem>
                                <ListItem autoFocus button onClick={() => this.setState({ key: 1, open: true, openEdit: false })}>

                                    <ListItemText primary="Rates" />

                                </ListItem>

                                <ListItem autoFocus button onClick={() => this.setState({ key: 2, open: true, openEdit: false })}>

                                    <ListItemText primary="Deliverables" />

                                </ListItem>
                            </List>
                        </Dialog>
                        <Dialog fullScreen open={this.state.openCalc} onClose={handleClose2} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleClose2} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Summary for {this.state.allData.name}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Summary value={this.state.allData} />
                        </Dialog>
                    </div>
                )}
                {(this.state.isLoading1) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (null)}
            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(TenderProjects));