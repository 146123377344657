import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../Helper/CommonThings';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReactPlayer from 'react-player';
import { getCookie } from '../Helper/CookieHelper';
import Service from '../Networking/networkutils';
import { errorMessage } from '../Helper/ErrorMessage';
import { Box, } from '@material-ui/core'
import { withSnackbar } from 'notistack';

var token = getCookie("bb_ts_token");

class SelectedTutorials extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: null,
            isLoading: true
        }
    }

    componentDidMount() {
        if (this.props.match.params.itemid) {
            Service.get(`/bb/api/tutorials/${this.props.match.params.itemid}/`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({
                        data: res.data,
                        isLoading: false
                    })
                })
                .catch(error => {
                    if (error && error.status === 404) {
                        // this.props.enqueueSnackbar('No Videos found !', {
                        //     variant: 'warning'
                        // });
                        this.setState({ isLoading: false })
                    } else {
                        console.log(error)
                        errorMessage(this.props, error)
                    }
                });
        }
    }

    render() {
        return (
            <div>
                <AppBar position="fixed" color='primary'>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => window.close()}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" >
                            {this.state.data ? this.state.data.name : 'Tutorials'}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) :
                    <div style={{ marginTop: "70px" }}>
                        <Box display='flex' flexDirection='row' justifyContent={'center'} alignItems={'center'} height={(window.innerHeight - 115) + "px"}>
                            {this.state.data?.url ?
                                <ReactPlayer height={'100%'} width='100%' url={this.state.data.url} controls={true} pip={true} stopOnUnmount={false}
                                    // light={value.thumbnail}
                                    playing
                                    playIcon={<IconButton><PlayArrowIcon color="primary" sx={{ fontSize: 120 }} /></IconButton>}
                                    config={{
                                        file: {
                                            attributes: {
                                                controlsList: 'nodownload',
                                                onContextMenu: e => e.preventDefault()
                                            }
                                        }
                                    }}
                                /> : <b>No Videos Found!</b>}
                        </Box>
                    </div>
                }
            </div>
        );
    }
}

export default withSnackbar(SelectedTutorials);