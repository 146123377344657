import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LeadHoursRequest from './LeadHoursRequest';
import UserHoursRequestManage from './UserHoursRequestManage';
import { Button, Toolbar } from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        flexGrow: 1,
    },
}));

export default function TeamHoursRequestTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Tabs indicatorColor='secondary' textColor='inherit' value={value} onChange={handleChange} className={classes.title} aria-label="simple tabs example">
                        <Tab label="Member Requests" {...a11yProps(0)} />
                        <Tab label="Your Requests" {...a11yProps(1)} />
                        {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                    </Tabs>
                    <Button color='inherit' onClick={() => props.onCloseCommand()}>Close</Button>
                </Toolbar>
            </AppBar>
            <TabPanel value={value} index={0}>
                <LeadHoursRequest value={props.value} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <UserHoursRequestManage value={props.value} noClose={true} />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
        </div>
    );
}
