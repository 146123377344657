import React from 'react';
import MaterialTable from 'material-table';
import TaskLevelRoleUsers from './TaskLevelRoleUsers';
import Service from '../Networking/networkutils';
import { getCookie } from '../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import { errorMessage } from '../Helper/ErrorMessage';
// import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../Helper/CommonThings';
import { DialogContent, Typography } from '@mui/material';
import AdditionalHourRequest2 from '../Teams/AssignRoles/AdditionalHourRequest2';
import { BootstrapDialogTitleV4, BootstrapDialogV4, Transition } from '../Helper/DialogTitleM5';
import { Button, Dialog, DialogActions, DialogContentText, DialogTitle } from '@material-ui/core';

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

const country = { uk: 'UK', in: 'IN/SL' };
const grade = { a: 'A', b: 'B', c: 'C', d: 'D', e: 'E', f: 'F', g: 'G', h: 'H', I: 'I', J: 'J', k: 'K' };

// function addZeroes(num) {
//     const dec = num.toString().split('.')[1]
//     const len = dec ? 1 : null
//     if (len) {
//         return parseFloat(Number(num).toFixed(len))
//     } else {
//         return parseFloat(Number(num))
//     }
// }


// function timeToDecimal(t) {
//     try {
//         var arr = t.split(':');
//         var dec = parseInt((arr[1] / 6) * 10, 10);

//         return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
//     } catch (error) {
//         return 0
//     }

// }

var toOpenIndexs = []

var refsCollection = {}

class TaskLevelDetails extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [
                // { role: 'Application Developer(IN-B)', bud_hrs: 100, hrs_cmp: 200 }
            ],
            taskUsers: [],
            isLoading: true,
            task: null,
            allUsers: [],
            allUid: null,
            openRequest: false,
            selectedeRowData: null,
            openRequestAlert: false,
            requestAlertMessage: ""
        }
        this.fetchData = this.fetchData.bind(this)
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData(this.props.task, this.props.allUsers)
    }

    fetchData(task, allUsers, newUser) {
        try {
            toOpenIndexs = []
            const dataOld = this.state.data

            this.setState({ task: task, allUsers: allUsers }, () =>
                Service.get('/bb/api/tender/tasks/rolesrevhistory/?tid=' + this.state.task.id, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res => {
                        var taskArr = []
                        // var roles1 = res.data;
                        // console.log(res.data)
                        for (let i = 0; i < res.data.length; i++) {
                            const element = res.data[i];
                            element.req_hours = element.hours + element.req_hours
                            taskArr.push(element.t_tasks)
                            element.rev_hours = element.req_hours
                            for (let j = 0; j < dataOld.length; j++) {
                                const element1 = dataOld[j];
                                if (element.id === element1.id && element1.tableData && element1.tableData.showDetailPanel) {
                                    toOpenIndexs.push(i)
                                }
                            }
                        }
                        this.taskUserData(newUser, res.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            )
        }
        catch (e) {
            console.log(e)
        }
    }

    handleShowDetailPanel = (indexs) => {
        console.log(indexs)
        if (!this.tableRef.current) return;

        const { detailPanel } = this.tableRef.current.props;
        let handleShowDetailPanel = detailPanel;
        const _data = [...this.state.data]; // Make copy of data

        for (let i = 0; i < indexs.length; i++) {
            const element = indexs[i];
            if (typeof detailPanel === 'object') {
                // ~ I AM USING INDEX 0 HERE - CHANGE TO WHATEVER YOU NEED ~
                handleShowDetailPanel = detailPanel[element].render
            }

            // If `showDetailPanel` already exists, remove it to close the detailPanel..
            if (_data[element].tableData.showDetailPanel) {
                _data[element].tableData.showDetailPanel = '';
            } else {
                // If `showDetailPanel` does not exist, add it to open detailPanel..
                _data[element].tableData = {
                    ..._data[element].tableData,
                    showDetailPanel: handleShowDetailPanel
                }
            }
        }
        // If props.detailPanel is an array not a func


        this.setState({ data: _data })
    }


    taskUserData(newUser, resData) {

        let users = this.state.allUsers;
        // users.push(newUser)
        let uid = []
        if (newUser) {
            uid.push(newUser.user_id);
            this.props.userAdded(newUser.user_data)
        }
        for (let i = 0; i < users.length; i++) {
            uid.push(users[i].id)
        }

        Service.get('/bb/api/tender/user/task/get_task_allocations/?task_id=' + this.state.task.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                let currentRoleUsers = res.data;

                for (let j = 0; j < resData.length; j++) {
                    const element = resData[j];
                    element.total_alloc_in_role = 0
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].task_role_id === element.id) {
                            element.total_alloc_in_role += res.data[i].planed_hours
                        }
                    }
                }

                // for (let i = 0; i < currentRoleUsers.length; i++) {
                //     for (let j = 0; j < currentRoleUsers.length; j++) {
                //         // if (currentRoleUsers[i].user_id === currentRoleUsers[j].user_id) {
                //         currentRoleUsers[i].actualAndInprogressHours = currentRoleUsers[i].actual_hours + currentRoleUsers[i].inprogress_hours
                //         // }
                //     }
                //     for (let k = 0; k < currentRoleUsers.length; k++) {
                //         if (currentRoleUsers[i].user_id === currentRoleUsers[k].user_id) {
                //             currentRoleUsers[i].balance_hours = currentRoleUsers[i].total_alloc_hours - currentRoleUsers[i].actualAndInprogressHours
                //         }
                //     }
                // }
                for (let roleIndex = 0; roleIndex < resData.length; roleIndex++) {
                    const element = resData[roleIndex];
                    if (refsCollection[element.id]) {
                        refsCollection[element.id].initSetUp(currentRoleUsers)
                    }
                }
                this.setState({ data: resData, taskUsers: currentRoleUsers, allUid: uid, isLoading: false, isLoadingTable: false, }, () => {
                    this.handleShowDetailPanel(toOpenIndexs)
                })

                // this.setState({ taskUsers: currentRoleUsers, }, () => {
                //     var viewPid = []
                //     for (let i1 = 0; i1 < currentRoleUsers.length; i1++) {
                //         const element = currentRoleUsers[i1];
                //         viewPid.push({ 'com_ttid': this.state.task.id, 'com_uid': element.user_id })
                //     }
                //     if (viewPid.length !== 0) {
                //         Service.patch("/bb/api/team/tasks/get_acthours/", {
                //             headers: {
                //                 Authorization: "Token " + token,
                //             },
                //             data: viewPid,
                //         })
                //             .then(res12 => {

                //                 for (let j = 0; j < res12.data.length; j++) {
                //                     const element13 = res12.data[j];
                //                     for (let i = 0; i < currentRoleUsers.length; i++) {
                //                         const element12 = currentRoleUsers[i];
                //                         if (element12.user_id === element13.id2) {
                //                             element12.actual_hours = timeToDecimal(element13.hours_actual)
                //                             element12.inprogress_hours = timeToDecimal(element13.hours_ongoing)
                //                             element12.total_alloc_hours = element13.allocated

                //                         }
                //                     }
                //                 }

                //                 for (let i = 0; i < currentRoleUsers.length; i++) {
                //                     for (let j = 0; j < currentRoleUsers.length; j++) {
                //                         // if (currentRoleUsers[i].user_id === currentRoleUsers[j].user_id) {
                //                         currentRoleUsers[i].actualAndInprogressHours = currentRoleUsers[i].actual_hours + currentRoleUsers[i].inprogress_hours
                //                         // }
                //                     }
                //                     for (let k = 0; k < currentRoleUsers.length; k++) {
                //                         if (currentRoleUsers[i].user_id === currentRoleUsers[k].user_id) {
                //                             currentRoleUsers[i].balance_hours = currentRoleUsers[i].total_alloc_hours - currentRoleUsers[i].actualAndInprogressHours
                //                         }
                //                     }
                //                 }
                //                 this.setState({ data: resData, taskUsers: currentRoleUsers, allUid: uid, isLoading: false, isLoadingTable: false, }, () => {
                //                     this.handleShowDetailPanel(toOpenIndexs)
                //                 })
                //             })
                //             .catch(error => {
                //                 this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                //                     variant: 'error'
                //                 });

                //             });
                //     }

                // })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    initRequestHours(data, error) {
        this.setState({ selectedeRowData: data, openRequestAlert: true, requestAlertMessage: error })
    }


    render() {

        const handleRequestClose = (e) => {
            this.setState({ openRequest: false })
        };

        const handleRequestAlertClose = () => {
            this.fetchData(this.props.task, this.props.allUsers)
            this.setState({ openRequestAlert: false, })
        };

        const initiateHourRequest = () => {
            this.setState({ openRequestAlert: false, openRequest: true })
        };

        return (
            <div>

                <MaterialTable
                    tableRef={this.tableRef}
                    columns={[
                        {
                            title: 'Role (Grade/Country)', field: 'name', editable: 'never',
                            render: (rowdata) => rowdata.name + ' (' + country[rowdata.country] + '/' + grade[rowdata.grade] + ')'
                        },
                        // { title: 'Bud Hrs', field: 'rev_hours', editable: 'never' },
                        { title: 'Hrs Comp.', field: 'rev_hours', },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        showTitle: false,
                        padding: 'dense',
                        paging: false,
                        toolbar: false,
                        headerStyle: {
                            backgroundColor: getPrimaryColor(),
                            color: '#FFF',
                            fontSize: '12px',
                            padding: '10px'
                        },
                        rowStyle: rowData => {
                            return {
                                //   backgroundColor: '#eaeaea'
                                fontSize: '12px',
                                padding: '10px'
                            };
                        }
                    }}

                    detailPanel={rowData => {
                        return (
                            <TaskLevelRoleUsers
                                ref={(instance) => {refsCollection[rowData.id] = instance;}}
                                task={this.state.task}
                                role={rowData}
                                taskUsers={this.state.taskUsers}
                                allUsers={this.state.allUsers}
                                allUid={this.state.allUid}
                                fetchData={this.fetchData.bind(this)}
                                initRequestHours={this.initRequestHours.bind(this)}
                            />
                        )
                    }}
                    actions={[
                        rowData => ({
                            icon: 'post_add',
                            tooltip: 'Request Hours for this role',
                            onClick: (event, rowData) => { this.setState({ openRequest: true, selectedeRowData: rowData }) },
                        }),
                    ]}
                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                />
                <BootstrapDialogV4 onClose={handleRequestClose} fullwidth maxWidth="xl" aria-labelledby="customized-dialog-title" open={this.state.openRequest}>
                    <BootstrapDialogTitleV4 id="customized-dialog-title" onClose={handleRequestClose}>
                        {(this.state.selectedeRowData) ? this.state.selectedeRowData.name + " [ " + this.props.task.project.tender_name + " - " + this.props.task.ds.name + " ] Available hours for allocation : " + (this.state.selectedeRowData.req_hours) + " hours" : null}
                    </BootstrapDialogTitleV4>
                    <DialogContent dividers>
                        {(this.state.selectedeRowData) ? (
                            <div>
                                <Typography>{this.props.task.wbs.name + "   --->  Revised Hours: " + (this.state.selectedeRowData.rev_hours) + ", Allocated Hours: " + (this.state.selectedeRowData.total_alloc_in_role) + ". Please contact EM to increase available hours for allocation."}</Typography>
                                <AdditionalHourRequest2 value={{
                                    id: this.state.selectedeRowData.id,
                                    name: this.state.selectedeRowData.name,
                                    country: this.state.selectedeRowData.country,
                                    t_tasks: this.props.task.id,
                                    t_tasks_data: this.props.task,
                                    t_role: this.state.selectedeRowData.t_role,
                                    all_hours_to_this_role: this.state.selectedeRowData.req_hours,
                                }} task={this.props.task} team={this.props.team} rolesLookUp={this.props.allRoles ? this.props.allRoles : this.state.allRoles} projectLook={this.props.projectLook} />
                            </div>
                        ) : null}

                    </DialogContent>
                </BootstrapDialogV4>
                <Dialog
                    open={this.state.openRequestAlert}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleRequestAlertClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Hours unavailable"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {this.state.selectedeRowData ? "Revised Hours: " + (this.state.selectedeRowData.rev_hours) + ", Allocated Hours: " + (this.state.selectedeRowData.total_alloc_in_role) : null}
                            <br />
                            {this.state.requestAlertMessage}
                            <br /><br />
                            You can request additional hours to be approved by the EM. Do you want to proceed?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={handleRequestAlertClose}>No</Button>
                        <Button color="primary" onClick={initiateHourRequest}>Yes</Button>
                    </DialogActions>
                </Dialog>
            </div>


        )
    }
}

export default withSnackbar(TaskLevelDetails)