import React, { Component } from 'react';
import Service from "../Networking/networkutils"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { getCookie } from '../Helper/CookieHelper';
import { CircularProgress } from '@mui/material';

const token = getCookie("bb_ts_token");

class FindUserByRole extends Component {

    constructor(props) {
        super()

        this.state = {

            userRolesInfo: [],

        }
    }

    componentDidMount() {
        Service.get(`/bb/api/user/with_sec_roles/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res13 => {
                // console.log(res13.data)
                for (let i = 0; i < res13.data.length; i++) {
                    const element = res13.data[i];
                    element.name = element.first_name + " " + element.last_name;

                }
                this.setState({
                    userRolesInfo: res13.data
                })
            })
            .catch(error => {

            });
    }

    fetchUserRoles = (event, values) => {

        if (values) {
            var secondary_roles = ''
            // console.log(values.secondary_roles)
            if (values.secondary_roles) {
                for (let i = 0; i < values.secondary_roles.length; i++) {
                    const element = values.secondary_roles[i];
                    if (secondary_roles !== '') {
                        secondary_roles = secondary_roles + ", " + element.role_name
                    } else {
                        secondary_roles = element.role_name
                    }
                }
            }

            alert("Name : " + values.name + "\nPrimary Role : " + values.role_name + "\nSecondary Roles : " + secondary_roles)
        }

    }

    render() {
        return (
            <div>
                {this.state.userRolesInfo.length ?
                    <Autocomplete
                        id="combo-box-demo"
                        options={this.state.userRolesInfo}
                        fullWidth
                        getOptionLabel={(option) => option.name}
                        onChange={this.fetchUserRoles}
                        renderInput={(params) => <TextField {...params} label="Type User Name" variant="outlined" />}
                    />
                    : <CircularProgress />}

            </div>
        );
    }
}

export default FindUserByRole;