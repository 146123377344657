import React, { Component } from 'react'
import CommentIcon from '@mui/icons-material/Comment';
import Popover from '@material-ui/core/Popover';
import { IconButton, Tooltip } from '@mui/material';
import { Avatar, Box, TextField } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { getMode } from '../MainDashBoard/ColorTheme/ColorTheme';
import { getPrimaryColor, getSecondaryColor } from '../Helper/CommonThings';
import { withStyles } from '@material-ui/styles';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import Service from '../Networking/networkutils';
import SendIcon from '@mui/icons-material/Send';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

const useStyles = (theme) => ({
    small: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        backgroundColor: getMode() ? getSecondaryColor() : getPrimaryColor(),
        color: getMode() ? getPrimaryColor() : '#fff'
    },
});

class HoeComments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            comments: [],
            comment: '',
            anchorEl: null,
            isLoading: true
        }
    }

    fetchComments() {
        Service.get(`/bb/api/gate6/comments/?tender=${this.props.data.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ comments: res.data }, () => {
                    if (this.state.comments.length !== 0) {
                        setTimeout(() => {
                            this.messagesEnd.scrollIntoView({ behavior: "smooth" })
                        }, 200);
                    }
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    handleTextChange(e) {
        if (e.target.id === 'comments') {
            this.setState({ comment: e.target.value })
        }
    }

    postComments() {
        if (!this.state.comment) {
            this.props.enqueueSnackbar('Comments should not be Empty!', {
                variant: 'warning'
            });
        } else {
            Service.post('/bb/api/gate6/comments/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    tender: this.props.data.id,
                    comment: this.state.comment,
                    created_by: user.id
                },
            })
                .then(res => {
                    this.setState({ comment: '', })
                    this.fetchComments();
                    this.props.enqueueSnackbar('Comment added successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    render() {

        const classes = this.props.classes

        const handleClick = (event) => {
            this.setState({ anchorEl: event.currentTarget }, () => {
                if (this.state.anchorEl) {
                    this.fetchComments()
                }
            })
        };

        const handleClose = () => {
            this.setState({ anchorEl: null })
        };

        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <div>
                <Tooltip title={'Comments'}>
                    <IconButton size='medium' onClick={handleClick}>
                        <CommentIcon fontSize='medium' />
                    </IconButton>
                </Tooltip>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box display='flex' flexDirection='row' style={{ width: 600, maxHeight: 500 }} m={2}>
                        <Box display='flex' flexDirection='column' style={{ width: '100%' }} >
                            <Box fontWeight={'bold'}>
                                Comments
                            </Box>
                            <Box m={1} style={{ width: '100%', overflowY: 'scroll', maxHeight: '100%' }}>
                                {this.state.comments.length !== 0 ? this.state.comments.map((value, i) =>
                                    <Box ref={(el) => { this.messagesEnd = el; }} key={i} marginTop='10px' marginBottom={'5px'} display={'flex'} flexDirection='column'>
                                        <Box display={'flex'} flexDirection='row' alignItems={'center'}>
                                            <Box display={'flex'} flexDirection='row' flexGrow={1} alignItems={'center'}>
                                                <Box>
                                                    <Avatar sx={{ bgcolor: '#99CFBB' }} className={classes.small}><Box fontWeight={'bold'} fontSize={'9px'} >{value.first_name.charAt(0) + value.last_name.charAt(0)}</Box></Avatar>
                                                </Box>
                                                <Box marginLeft="10px" fontWeight={500}>{value.first_name + ", " + value.last_name}</Box>
                                            </Box>
                                            <Box marginRight={'2px'}>{moment(value.date_created).format(' DD MMM YYYY, h:mm a')}</Box>
                                        </Box>
                                        <Box marginLeft='37px'>
                                            {value.comment}
                                        </Box>
                                    </Box>
                                ) : null
                                }
                            </Box>
                            <Box display='flex' flexDirection={'row'} style={{ width: '100%' }} justifyContent='center' alignItems={'center'} mt={1}>
                                <Box flexGrow={1}>
                                    <TextField
                                        id="comments"
                                        label="Type a comment..."
                                        multiline
                                        rows={2}
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        value={this.state.comment}
                                        onChange={(e) => this.handleTextChange(e)}
                                    />
                                </Box>
                                <Box>
                                    <IconButton color='primary' onClick={() => this.postComments()}>
                                        <SendIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Popover>
            </div>
        )
    }
}

export default withStyles(useStyles)(withSnackbar(HoeComments))
