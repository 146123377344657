import { Badge, Box, FormControl, InputLabel, MenuItem, Select, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@material-ui/core'
import { InputAdornment, TextField, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import React, { Component } from 'react'
// import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
// import InputBase from '@mui/material/InputBase';
// import { getMode } from '../../../ColorTheme/ColorTheme';
import { screenSizeCheck } from '../../../ScreensizeCheck/ScreensizeCheck';
import Service from '../../../../Networking/networkutils'
import { errorMessage } from '../../../../Helper/ErrorMessage';
import { getCookie } from '../../../../Helper/CookieHelper';
import ProjectCardList from './ProjectCardList';
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../../../../Helper/CommonThings';
import FindUserByRole from '../../../../FindUserByRole/FindUserByRole';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const token = getCookie("bb_ts_token");

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -17,
        top: screenSizeCheck() ? 6 : 9,
        backgroundColor: '#66b6f3'
    },
}));

const StartSoonBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -17,
        top: screenSizeCheck() ? 6 : 9,
        backgroundColor: '#FFF176'
    },
}));

const ReadtToStartBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -17,
        top: screenSizeCheck() ? 6 : 9,
        backgroundColor: '#DCE775'
    },
}));

const LaterDueBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -17,
        top: screenSizeCheck() ? 6 : 9,
        backgroundColor: '#da8bc5'
    },
}));

const SoonDueBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -17,
        top: screenSizeCheck() ? 6 : 9,
        backgroundColor: '#FFB74D'
    },
}));

const OverDueBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -17,
        top: screenSizeCheck() ? 6 : 9,
        backgroundColor: '#EF9A9A'
    },
}));

// const Search = styled('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: getMode() ? alpha(theme.palette.common.white, 0.15) : '#e6e6e6',
//     '&:hover': {
//         backgroundColor: getMode() ? alpha(theme.palette.common.white, 0.25) : '#f2f2f2',
//     },
//     marginRight: theme.spacing(1),
//     marginLeft: 0,
//     width: '100%',
//     [theme.breakpoints.up('lg')]: {
//         marginLeft: theme.spacing(4),
//         width: 'auto',
//     },
//     [theme.breakpoints.down('lg')]: {
//         marginLeft: theme.spacing(2),
//         width: 'auto',
//     },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: 'inherit',
//     '& .MuiInputBase-input': {
//         // fontSize: '18px',
//         padding: theme.spacing(1, 1, 1, 0),
//         // vertical padding + font size from searchIcon
//         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
//         transition: theme.transitions.create('width'),
//         width: '100%',
//         [theme.breakpoints.up('lg')]: {
//             width: '25ch',
//         },
//         [theme.breakpoints.down('lg')]: {
//             width: '15ch',
//         },
//     },
// }));

var searchInput = ""

class ProjectTaskCards extends Component {
    constructor(props) {
        super(props)

        this.state = {
            taskstatus: -1,
            isLoading: false,
            data: [],
            allTask: 0,
            startSoon: 0,
            readyToStart: 0,
            soonDue: 0,
            overDue: 0,
            laterDue: 0,
            emptyStatus: 'this selection',
            allData: [],
            childHeight: null,
            currentProj: null,
            usersData: [],
            filter_type: 'limited',
        }
        this.child = React.createRef();
        // this.boxHeight = this.boxHeight.bind(this)
        this.childHeight = this.childHeight.bind(this)
        this.handleDataChange = this.handleDataChange.bind(this)
        this.refreshData = this.refreshData.bind(this)
    }

    componentDidMount() {
        searchInput = ''
        if (this.props.tender && this.props.filter_type) {
            this.fetchData(this.props.tender, this.props.filter_type)
            Service.get(`/bb/api/users/`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res5 => {
                    this.setState({ usersData: res5.data, })
                    if (this.child && this.child.current) {
                        this.child.current.setUsers(res5.data)
                    }
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    loadData(element, filter_type) {
        this.setState({
            isLoading: true
        })
        this.fetchData(element, filter_type)
    }

    fetchData(element, filter_type) {
        localStorage.setItem('ProjectTasks_Last_Selected_Id', element.id)
        Service.get(`/bb/api/tender/project/project_task/?project=${element.id}&filter_type=${filter_type}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    currentProj: element, allData: res.data, filter_type: filter_type
                })
                this.handleData(res.data)
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    handleData(datas) {
        var startSoon = []
        var readyToStart = []
        var soonDue = []
        var overDue = []
        var laterDue = []

        for (let i = 0; i < datas.length; i++) {
            const element = datas[i];
            if (element.task_stand === 1) {
                startSoon.push(element)
            } else if (element.task_stand === 2) {
                readyToStart.push(element)
            }
            else if (element.task_stand === 3) {
                soonDue.push(element)
            }
            else if (element.task_stand === 4) {
                overDue.push(element)
            }
            else if (element.task_stand === 6) {
                laterDue.push(element)
            }
        }
        this.setState({ allTask: datas.length, startSoon: startSoon.length, readyToStart: readyToStart.length, soonDue: soonDue.length, overDue: overDue.length, laterDue: laterDue.length, isLoading: false })
        this.handleDataChange(this.state.taskstatus)
    }

    // boxHeight(height) {
    //     this.setState({ boxHeight: height + 6 })
    // }

    handleDataChange(data) {
        if (data === -1) {
            var datasAll = this.state.allData;
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datasAll.length === 0 ? 'all tasks' : null, isLoading: false, data: datasAll, taskstatus: data })
            })
        } else if (data === 1) {
            var datas = [];
            for (let i = 0; i < this.state.allData.length; i++) {
                const element = this.state.allData[i];
                if (element.task_stand === 1) {
                    datas.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas.length === 0 ? 'Start Soon' : null, isLoading: false, data: datas, taskstatus: data })
            })
        } else if (data === 2) {

            var datas1 = [];
            for (let i = 0; i < this.state.allData.length; i++) {
                const element = this.state.allData[i];
                if (element.task_stand === 2) {
                    datas1.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas1.length === 0 ? 'Ready To Start' : null, isLoading: false, data: datas1, taskstatus: data })
            })

        } else if (data === 3) {

            var datas2 = [];
            for (let i = 0; i < this.state.allData.length; i++) {
                const element = this.state.allData[i];
                if (element.task_stand === 3) {
                    datas2.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas2.length === 0 ? 'Soon Due' : null, isLoading: false, data: datas2, taskstatus: data })
            })
        } else if (data === 4) {
            var datas3 = [];
            for (let i = 0; i < this.state.allData.length; i++) {
                const element = this.state.allData[i];
                if (element.task_stand === 4) {
                    datas3.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas3.length === 0 ? 'Over Due' : null, isLoading: false, data: datas3, taskstatus: data })
            })

        } else if (data === 6) {

            var datas4 = [];
            for (let i = 0; i < this.state.allData.length; i++) {
                const element = this.state.allData[i];
                if (element.task_stand === 6) {
                    datas4.push(element)
                }
            }
            this.setState({ isLoading: true }, () => {
                this.setState({ emptyStatus: datas4.length === 0 ? 'Later Due' : null, isLoading: false, data: datas4, taskstatus: data })
            })

        }
    }

    refreshData() {
        this.fetchData(this.state.currentProj, this.state.filter_type)
    }

    handleSearchChange(data) {
        searchInput = data
        if (this.child && this.child.current) {
            var filtered_data = this.child.current.taskSearch(searchInput)
            this.handleData(filtered_data)
        }
    }

    childHeight(height) {
        this.setState({ childHeight: height })
    }

    setUsers(data) {
        this.setState({ usersData: data })
        if (this.child && this.child.current) {
            this.child.current.setUsers(data)
        }
    }

    downloadExcel() {
        if (this.child && this.child.current) {
            this.child.current.openTaskDownload()
        }
    }

    render() {

        const handleChange = (event) => {
            this.setState({ taskstatus: event.target.value });
            this.handleDataChange(event.target.value)
        };

        const handleSearchUserClose = (e) => {
            this.setState({ searchUserRoles: false })
        };

        return (
            <div>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }} alignItems="center" >
                        <Box fontWeight="fontWeightBold" fontSize={16}>
                            {this.state.currentProj ? this.state.currentProj.tender_name : null}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <IconButton size="small" style={{ marginRight: '10px' }} aria-label="delete" color="primary" onClick={() => this.refreshData()}>
                            <RefreshIcon />
                        </IconButton>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => { this.setState({ searchUserRoles: true }) }}
                            style={{ marginRight: '15px' }}
                            endIcon={<SearchIcon />}
                        >Find User Role</Button>
                        <FormControl variant="outlined" style={{ minWidth: 180, marginRight: '15px' }} size="small">
                            <InputLabel id="demo-simple-select-outlined-label">Task Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.taskstatus}
                                onChange={handleChange}
                                label="Task Status"
                            >
                                <MenuItem value={-1}><FilterMenuItem badgeCount={this.state.allTask} statusName="To Do" styleComponent={StyledBadge} /></MenuItem>
                                <MenuItem value={1}><FilterMenuItem badgeCount={this.state.startSoon} statusName="Start Soon" styleComponent={StartSoonBadge} /></MenuItem>
                                <MenuItem value={2}><FilterMenuItem badgeCount={this.state.readyToStart} statusName="Ready to Start" styleComponent={ReadtToStartBadge} /></MenuItem>
                                <MenuItem value={6}><FilterMenuItem badgeCount={this.state.laterDue} statusName="Later Due" styleComponent={LaterDueBadge} /></MenuItem>
                                <MenuItem value={3}><FilterMenuItem badgeCount={this.state.soonDue} statusName="Soon Due" styleComponent={SoonDueBadge} /></MenuItem>
                                <MenuItem value={4}><FilterMenuItem badgeCount={this.state.overDue} statusName="Over Due" styleComponent={OverDueBadge} /></MenuItem>
                            </Select>
                        </FormControl>
                        <Tooltip placement="top" title={<div>You can use multiple keyword searches<br />Ex: David OHL Wireclearance</div>}>
                            {/* <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    autoFocus
                                    placeholder="Search"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e) => this.handleSearchChange(e.target.value)}
                                />
                            </Search> */}
                            <TextField
                                autoComplete='off'
                                autoFocus
                                size='small'
                                placeholder="Search"
                                id="search"
                                onChange={(e) => this.handleSearchChange(e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                }}
                            />
                        </Tooltip>
                        <Box sx={{ display: "flex", justifyContent: 'flex-start' }}>
                            <Tooltip title='Download'>
                                <IconButton size='small' onClick={() => {
                                    if (this.state.data.length !== 0) {
                                        this.downloadExcel()
                                    } else {
                                        this.props.enqueueSnackbar('No Tasks to download!', {
                                            variant: 'warning'
                                        });
                                    }
                                }}>
                                    <SaveAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>

                {
                    this.state.isLoading ?
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 1, height: this.state.boxHeight }}>
                            <LoopCircleLoading color={getPrimaryColor()} />
                        </Box>
                        :
                        this.state.data.length === 0 ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 1, fontWeight: 'bold', height: this.state.childHeight ? this.state.childHeight : 250 }}>
                                No Tasks in {this.state.emptyStatus}
                            </Box> :
                            <div style={{ marginTop: '10px' }}>
                                <ProjectCardList ref={this.child} data={this.state.data ? this.state.data : null} handleChange={this.handleDataChange} refreshData={this.refreshData} presentSearch={searchInput} childHeight={this.childHeight} usersData={this.state.usersData} currentProject={this.state.currentProj} maxCards={this.props.maxCards} />
                            </div>
                }
                <Dialog
                    open={this.state.searchUserRoles}
                    fullWidth
                    maxWidth={'sm'}
                    onClose={handleSearchUserClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="customized-dialog-title2" >
                        Find Role of a user
                    </DialogTitle>
                    <DialogContent>
                        <FindUserByRole />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSearchUserClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}

function FilterMenuItem(props) {
    var StyledBadge = props.styleComponent
    return <Box>
        <StyledBadge badgeContent={props.badgeCount} color="primary" max={999} >
            <Box fontSize={14}>{props.statusName}</Box>
        </StyledBadge>
    </Box>
}

export default withSnackbar(ProjectTaskCards);