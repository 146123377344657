import { Avatar, Button, Card, Grid, ListItem, ListItemAvatar, ListItemText, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { CardActionArea } from '@mui/material';
import { screenSizeCheck } from '../../../../ScreensizeCheck/ScreensizeCheck';
import GroupIcon from '@material-ui/icons/Group';
import { getPrimaryColor } from '../../../../../Helper/CommonThings';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class TeamInfo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openDialogue: false
        }
    }

    moreContent() {
        if (this.props.data.teams.length <= 3) {
            return true
        } else {
            return false
        }
    }

    showContent() {
        var items = Array.from(this.props.data.teams).slice(0, window.innerWidth <= 1366 ? 2 : window.innerWidth <= 1650 ? 3 : 3);
        if (items) {
            return <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems="center" style={{ width: '100%' }}>
                {items.map((value, i) =>
                    <Card elevation={7} key={i} style={{ width: '100%', marginBottom: '5px', borderRadius: '10px 10px 10px 10px', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>
                        <CardActionArea onClick={() => this.props.handleClick(value)}>
                            <Box margin='2px' fontSize={screenSizeCheck() ? '12px' : '14px'}>
                                {value.name}
                            </Box>
                        </CardActionArea>
                    </Card>
                )}
                <Box textAlign={'right'} marginTop={'5px'}>
                    <Tooltip title="see more">
                        <Button color='primary' onClick={() => this.setState({ openDialogue: true })}>More</Button>
                    </Tooltip>
                </Box>
            </Box>
        }
    }

    render() {

        const handleDialogueClose = () => {
            this.setState({ openDialogue: false })
        };

        return (
            <div style={{ height: '100%' }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} style={{ width: '100%', height: '100%' }}>

                        <Box flexDirection={'row'} justifyContent={'center'} fontSize={screenSizeCheck() ? '16px' : '22px'} color={getPrimaryColor()} fontWeight={'bold'} >
                            Teams
                        </Box>

                        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} m={1} >
                            {this.moreContent() ?
                                this.props.data.teams.map((value, i) =>
                                    <Card key={i} elevation={7} style={{ width: '100%', marginBottom: '5px', borderRadius: '10px 10px 10px 10px', textAlign: 'center', marginLeft: '10px', marginRight: '10px' }}>
                                        <CardActionArea onClick={() => this.props.handleClick(value)}>
                                            <Box margin='2px' fontSize={screenSizeCheck() ? '12px' : '14px'}>
                                                {value.name}
                                            </Box>
                                        </CardActionArea>
                                    </Card>
                                )
                                :
                                this.showContent()
                            }
                        </Box>
                    </Box>
                </Grid>

                <div>
                    <Dialog fullWidth={true} maxWidth='sm' onClose={handleDialogueClose} aria-labelledby="customized-dialog-title" open={this.state.openDialogue} TransitionComponent={Transition}>
                        <DialogTitle id="customized-dialog-title" onClose={handleDialogueClose}>
                            Team List
                        </DialogTitle>
                        <DialogContent dividers>
                            <Box flexDirection="column" display="flex" marginLeft='10px' alignItems={'center'} >
                                {this.props.data.teams.map((value, i) =>
                                    <ListItem key={i} button onClick={() => this.props.handleClick(value)} >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <GroupIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={value.name} />
                                    </ListItem>
                                )}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Box display="flex" justifyContent="center" marginTop="5px" marginBottom="10px">
                                <Button style={{ marginLeft: '5px', marginRight: '5px', }} onClick={handleDialogueClose}>close</Button>
                            </Box>
                        </DialogActions>
                    </Dialog>
                </div>

            </div >
        )
    }
}

export default TeamInfo