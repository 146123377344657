import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import MaterialTable from 'material-table'
import { getDarkMode } from '../Helper/CommonThings'

class FutureBudgetOtherTeamData extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             budgetData: []
        }
    }
    

    componentDidMount() {
        this.compileData()
    }

    compileData() {
        // console.log(this.props.selectedUser)
        var teamMembersData = []
        for (let i = 0; i < this.props.allTeamMembers.length; i++) {
            const element = this.props.allTeamMembers[i];
            // console.log(this.props.team.id !== element.gid && this.props.selectedUser.uid === element.uid)
            if (this.props.team.id !== element.gid && this.props.selectedUser.uid === element.uid) {
                element.full_name = element.uid_data.last_name + ", " + element.uid_data.first_name
                if (element.tableData) {
                    delete element.tableData
                }
                teamMembersData.push(element)
            }
        }

        for (let i = 0; i < teamMembersData.length; i++) {
            const element = teamMembersData[i];
            element.userOtherTeamData = 0
            for (let j = 0; j < this.props.allTeamMembers.length; j++) {
                const element1 = this.props.allTeamMembers[j];
                if (element.uid === element1.uid && element.gid !== element1.gid) {
                    ++element.userOtherTeamData
                }
            }

            for (let m = 0; m < this.props.futureBudget.length; m++) {
                const element1 = this.props.futureBudget[m];
                if (element.uid === element1.uid && element.gid === element1.gid) {
                    element[element1.month.toString()] = element1.percent
                }
            }

            for (let k = 0; k < this.props.rateCard.length; k++) {
                const element1 = this.props.rateCard[k];
                if (element.uid_data.role_grade.toLowerCase() === element1.grade.toLowerCase()) {
                    if (element.uid_data.office_loc === 0) {
                        element.rate = element1.costuk
                    } else if (element.uid_data.office_loc === 1 || element.uid_data.office_loc === 2) {
                        element.rate = element1.costin
                    }
                }
            }
        }
        // console.log(teamMembersData)
        this.setState({
            budgetData: teamMembersData
        })
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title="Other Team Data"
                    columns={[
                        {
                            title: "Team",
                            field: "team_data.name",
                            editable: 'never'
                        },
                        {
                            title: "Role",
                            field: "uid_data.role_name",
                            editable: 'never'
                        },
                        {
                            title: "Rate (£/hour)",
                            field: "rate",
                            editable: 'never'
                        },

                        {
                            title: "Jan (%)",
                            field: "1",
                            render: rowData => rowData["1"] ? rowData["1"] + "%" : null
                        },
                        
                        {
                            title: "Feb (%)",
                            field: "2",
                            // type: "numeric",
                            // hidden: this.props.typeOfDisplay === 'amount',
                            // editable: this.props.typeOfDisplay === 'percent' || !this.props.typeOfDisplay ? "always" : "never",
                            render: rowData => rowData["2"] ? rowData["2"] + "%": null
                        },
                       
                        {
                            title: "Mar (%)",
                            field: "3",
                            // type: "numeric",
                            // hidden: this.props.typeOfDisplay === 'amount',
                            // editable: this.props.typeOfDisplay === 'percent' || !this.props.typeOfDisplay ? "always" : "never",
                            render: rowData => rowData["3"] ? rowData["3"] + "%" : null
                        },
                        
                        {
                            title: "Apr (%)",
                            field: "4",
                            // type: "numeric",
                            // hidden: this.props.typeOfDisplay === 'amount',
                            // editable: this.props.typeOfDisplay === 'percent' || !this.props.typeOfDisplay ? "always" : "never",
                            render: rowData => rowData["4"] ? rowData["4"] + "%" : null
                        },
                        
                        {
                            title: "May (%)",
                            field: "5",
                            // type: "numeric",
                            // hidden: this.props.typeOfDisplay === 'amount',
                            // editable: this.props.typeOfDisplay === 'percent' || !this.props.typeOfDisplay ? "always" : "never",
                            render: rowData => rowData["5"] ? rowData["5"] + "%" : null
                        },
                        
                        {
                            title: "Jun (%)",
                            field: "6",
                            // type: "numeric",
                            // hidden: this.props.typeOfDisplay === 'amount',
                            // editable: this.props.typeOfDisplay === 'percent' || !this.props.typeOfDisplay ? "always" : "never",
                            render: rowData => rowData["6"] ? rowData["6"] + "%" : null
                        },
                        
                        {
                            title: "Jul (%)",
                            field: "7",
                            // type: "numeric",
                            // hidden: this.props.typeOfDisplay === 'amount',
                            // editable: this.props.typeOfDisplay === 'percent' || !this.props.typeOfDisplay ? "always" : "never",
                            render: rowData => rowData["7"] ? rowData["7"] + "%" : null
                        },
                       
                        {
                            title: "Aug (%)",
                            field: "8",
                            // type: "numeric",
                            // hidden: this.props.typeOfDisplay === 'amount',
                            // editable: this.props.typeOfDisplay === 'percent' || !this.props.typeOfDisplay ? "always" : "never",
                            render: rowData => rowData["8"] ? rowData["8"] + "%" : null
                        },
                        
                        {
                            title: "Sep (%)",
                            field: "9",
                            // type: "numeric",
                            // hidden: this.props.typeOfDisplay === 'amount',
                            // editable: this.props.typeOfDisplay === 'percent' || !this.props.typeOfDisplay ? "always" : "never",
                            render: rowData => rowData["9"] ? rowData["9"] + "%" : null
                        },
                       
                        {
                            title: "Oct (%)",
                            field: '10',
                            // type: "numeric",
                            // hidden: this.props.typeOfDisplay === 'amount',
                            // editable: this.props.typeOfDisplay === 'percent' || !this.props.typeOfDisplay ? "always" : "never",
                            render: rowData => rowData["10"] ? rowData["10"] + "%" : null
                        },
                       
                        {
                            title: "Nov (%)",
                            field: "11",
                            // type: "numeric",
                            // hidden: this.props.typeOfDisplay === 'amount',
                            // editable: this.props.typeOfDisplay === 'percent' || !this.props.typeOfDisplay ? "always" : "never",
                            render: rowData => rowData["11"] ? rowData["11"] + "%" : null
                        },
                       
                        {
                            title: "Dec (%)",
                            field: "12",
                            // type: "numeric",
                            // hidden: this.props.typeOfDisplay === 'amount',
                            // editable: this.props.typeOfDisplay === 'percent' || !this.props.typeOfDisplay ? "always" : "never",
                            render: rowData => rowData["12"] ? rowData["12"] + "%" : null
                        },
                       
                    ]}
                    style={{ backgroundColor: getDarkMode() === 'dark' ? '#4f7e76' : '#AFD6CB' }}
                    data={this.state.budgetData}
                    options={{
                        padding: "dense",
                        paging: false,
                    }}
                    />
            </div>
        )
    }
}

export default withSnackbar(FutureBudgetOtherTeamData)