import React, { Component } from 'react'
import MultiProgress from "react-multi-progress";
import { getGreencolor, getOrangecolor, getProgBgColor } from '../../MainDashBoard/ColorTheme/ColorTheme';

class StackedCostToDate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            costToDate: 0,
            costAtComp: 0,
        }
    }
    componentDidMount() {
        this.calcCost()
    }

    calcCost() {
        var converted_cost = 0
        var costToDate = 0
        var costAtComp = 0
        converted_cost = 100 / this.props.data.cost_at_completion
        costToDate = converted_cost * this.props.data.cost_to_date
        costAtComp = converted_cost * this.props.data.cost_at_completion
        this.setState({ costToDate: costToDate, costAtComp: costAtComp })
    }
    render() {
        return (
            <MultiProgress
                transitionTime={1.2}
                elements={[
                    {
                        value: this.state.costToDate,
                        color: getGreencolor(),
                    },
                    {
                        value: this.state.costAtComp,
                        color: getOrangecolor(),
                    },
                ]}
                height={5}
                backgroundColor={getProgBgColor()}
            />
        )
    }
}

export default StackedCostToDate