import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import moment from 'moment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Box } from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import Moment from 'react-moment';
import { blue } from '@material-ui/core/colors';
import { getCookie } from '../Helper/CookieHelper';
import { withStyles } from '@material-ui/styles';
import { errorMessage } from '../Helper/ErrorMessage';
import BulkApproval from './BulkApproval';
import { getPrimaryColor } from '../Helper/CommonThings';

var momentRange = require('moment-range');
momentRange.extendMoment(moment);

const user = JSON.parse(getCookie("bb_ts_user"));
// const access = JSON.parse(getCookie("bb_ts_access"));
const token = getCookie("bb_ts_token");

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

class ManageTimeSheet extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            open: false,
            isLoading: true,
            isLoading2: true,
            isLoading3: true,
            selectDate: new Date(),
            checkedB: true,
            tabledata: [],
            openCalc: false,
            timesheetname: "",
            tsid: 0,
            tabledata2: [],
            tabledata3: [],
            usersLookUp: {},
            viewOnly: false,
            startApproval: false,
            ApprovalList: [],
        }
        this.refreshCount = this.refreshCount.bind(this)
        this.closeBulkApproval = this.closeBulkApproval.bind(this)
    }


    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        this.setState({ isLoading: true })
        Service.get(`/bb/api/users/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var contact = {};
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    contact[element.id] = element.last_name + ", " + element.first_name;
                }
                this.setState({ usersLookUp: contact })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });


        Service.get('/bb/api/task/approve/limited/?request_to_id=' + user.id + "&date_created__gte=" + moment().subtract(6, 'month').format('YYYY-MM-DD HH:mm:ss') + "&approve_status=0,3", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    element.user_name = element.uid_data.last_name + ", " + element.uid_data.first_name
                }
                this.setState({
                    data: res.data,
                    tabledata: res.data,
                    isLoading: false,
                });


            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    handleData(date) {
        var dataTo = [];
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if (moment().range(moment(element.from_date), moment(element.to_date)).contains(date)) {
                dataTo.push(element);
            }
        }
        this.setState({ tabledata: dataTo })
    }

    refreshCount() {
        if (this.props.refreshCount) {
            this.props.refreshCount()
        }
    }

    closeBulkApproval() {
        this.setState({ startApproval: false, ApprovalList: [] })
        this.fetchOps()
    }

    render() {

        // const { classes } = this.props;

        // const handleClose2 = () => {
        //     this.fetchOps();
        //     this.setState({ openCalc: false });
        // };

        const handleDateChange = (date) => {
            // console.log(this.state.selectTime1)
            this.setState({ selectDate: date, checkedB: false, });

            this.handleData(date);

        };

        const handleChange = (event) => {
            this.setState({ checkedB: event.target.checked });
            if (event.target.checked) {
                this.setState({ tabledata: this.state.data })
            } else {
                this.handleData(this.state.selectDate);
            }
        };

        return (

            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <Box display={'flex'} flexDirection={'row'} justifyContent="space-evenly" alignItems={'center'} marginBottom={'5px'}>
                            <Box>
                                <FormGroup row >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.checkedB}
                                                onChange={handleChange}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Show All Request"
                                    />
                                </FormGroup>
                            </Box>
                            <Box>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Select date of a Week"
                                        format="dd/MM/yyyy"
                                        style={{ marginLeft: '10px' }}
                                        value={this.state.selectDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                            <Box>

                                <Button disabled={this.state.ApprovalList.length === 0} variant="contained" color="primary" onClick={() => {
                                    this.setState({
                                        startApproval: true
                                    })
                                }}>Approve</Button>
                            </Box>
                        </Box>

                        <MaterialTable
                            title="Bulk Approval"
                            columns={[
                                {
                                    title: 'Emp name', field: 'user_name', headerStyle: { minWidth: 230 }, cellStyle: { minWidth: 230 },
                                    // render: rowData => rowData.uid_data.last_name + ", " + rowData.uid_data.first_name
                                },
                                { title: 'Team', field: 'gname', headerStyle: { minWidth: 225 }, cellStyle: { minWidth: 225 }, },
                                // { title: 'Comment', field: 'comment', headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 }, },
                                // {
                                //     title: 'Date Requested', field: 'date_created',
                                //     render: rowData => <Moment local format="DD/MM/YYYY hh:mm A">{rowData.date_created}</Moment>
                                // },
                                // {
                                //     title: 'Request Type', field: 'request_type',
                                //     lookup: { 0: 'Initial Data', 1: 'Edited Data' },
                                // },
                                {
                                    title: 'Task From', field: 'from_date', type: 'date'
                                    // render: rowData => <Typography>{moment(rowData.from_date).format('DD/MM/YYYY')}</Typography>
                                },
                                {
                                    title: 'Task To', field: 'to_date', type: 'date'
                                    // render: rowData => <Typography>{moment(rowData.to_date).format('DD/MM/YYYY')}</Typography>
                                },
                                {
                                    title: 'Status', field: 'approve_status',
                                    lookup: { 0: 'Pending', 1: 'Approved', 2: 'Rejected', 3: 'Edit Requested' },
                                },
                            ]}
                            data={this.state.tabledata}

                            options={{
                                padding: "dense",
                                pageSize: this.state.tabledata.length,
                                pageSizeOptions: [10, 20, 50],
                                paging: false,
                                filtering: true,
                                actionsColumnIndex: -1,
                                selection: true,
                                selectionProps: rowData => ({
                                    disabled: rowData.approve_status === 1 || rowData.approve_status === 2,
                                    color: 'primary'
                                })

                            }}
                            onSelectionChange={(rows) => this.setState({
                                ApprovalList: rows.filter(row => (row.approve_status !== 1 && row.approve_status !== 2))
                            })}
                        />
                        <Dialog
                            open={this.state.startApproval}
                            // onClose={handleClose}
                            // fullWidth={true}
                            maxWidth="sm"
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent>
                                <BulkApproval value={this.state.ApprovalList} refreshCount={this.refreshCount} closeBulkApproval={this.closeBulkApproval} />
                            </DialogContent>
                        </Dialog>

                    </div>
                )}
            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(ManageTimeSheet));