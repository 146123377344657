import React, { Component } from 'react';
import { withStyles, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Service from '../Networking/networkutils';
import { errorMessage } from '../Helper/ErrorMessage';
import { getCookie } from '../Helper/CookieHelper';
import { CircularProgress } from '@material-ui/core';
import { withSnackbar } from 'notistack';

const token = getCookie("bb_ts_token");

const useStyles = (theme) => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 200,
        height: 230,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
});

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}
//     const classes = useStyles();
//     const [checked, setChecked] = React.useState([]);
//     const [left, setLeft] = React.useState([]);
//     const [right, setRight] = React.useState([]);
//     const [loading, setLoading] = React.useState(true);
//     const [companyLookup, setCompanyLookup] = React.useState({});

//     const leftChecked = intersection(checked, left);
//     const rightChecked = intersection(checked, right);

//     const handleToggle = (value) => () => {
//         const currentIndex = checked.indexOf(value);
//         const newChecked = [...checked];

//         if (currentIndex === -1) {
//             newChecked.push(value);
//         } else {
//             newChecked.splice(currentIndex, 1);
//         }

//         setChecked(newChecked);
//     };

//     const handleAllRight = () => {
//         setRight(right.concat(left));
//         setLeft([]);
//     };

//     const handleCheckedRight = () => {
//         setRight(right.concat(leftChecked));
//         setLeft(not(left, leftChecked));
//         setChecked(not(checked, leftChecked));
//     };

//     const handleCheckedLeft = () => {
//         setLeft(left.concat(rightChecked));
//         setRight(not(right, rightChecked));
//         setChecked(not(checked, rightChecked));
//     };

//     const handleAllLeft = () => {
//         setLeft(left.concat(right));
//         setRight([]);
//     };

//     const customList = (items) => (
//         <Paper className={classes.paper}>
//             <List dense component="div" role="list">
//                 {items.map((value) => {
//                     const labelId = `transfer-list-item-${value}-label`;
//                     return (
//                         <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
//                             <ListItemIcon>
//                                 <Checkbox
//                                     checked={checked.indexOf(value) !== -1}
//                                     tabIndex={-1}
//                                     disableRipple
//                                     inputProps={{ 'aria-labelledby': labelId }}
//                                 />
//                             </ListItemIcon>
//                             <ListItemText id={labelId} primary={companyLookup[value.id]} />
//                         </ListItem>
//                     );
//                 })}
//                 <ListItem />
//             </List>
//         </Paper>
//     );

//     function getCompanies() {
//         Service.get(`/bb/api/company/category_access_for_company/?category=${props.category.id}&company=${props.company.id}`, {
//             headers: {
//                 Authorization: "Token " + token,
//             },
//         })
//             .then(res => {
//                 var left = []
//                 var right = []

//                 setLoading(false)
//                 // console.log(res.data)
//                 // setLeft(res.data)
//             })
//             .catch(error => {
//                 console.log(error)
//                 errorMessage(props, error)
//             });
//     }

//     function getAllCompanies() {
//         setLoading(true)
//         Service.get(`/bb/api/company/`, {
//             headers: {
//                 Authorization: "Token " + token,
//             },
//         })
//             .then(res => {
//                 var companyLookup = {}
//                 res.data.forEach(company => {
//                     companyLookup[company.id] = company
//                 });
//                 setCompanyLookup(companyLookup);
//                 setLoading(false)
//             })
//             .catch(error => {
//                 console.log(error)
//                 errorMessage(props, error)
//             });
//     }

//     useEffect(() => {
//         getCompanies();
//         getAllCompanies();
//     })

//     return (

//     );
// }

// export default CompanyCategoryTransferList;

class CompanyCategoryTransferList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            checked: [],
            left: [],
            right: [],
            loading: true,
            companyLookup: {}
        }
    }


    componentDidMount() {
        // this.getCompanies();
        this.getAllCompanies();
    }

    getCompanies() {
        Service.get(`/bb/api/company/category_access_for_company/?category=${this.props.category.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var left = []
                var right = []
                var keys = Object.keys(res.data.access_data)
                // debugger
                for (let index = 0; index < keys.length; index++) {
                    const element = parseInt(keys[index]);
                    if (res.data.access_data[element]) {
                        right.push(parseInt(element))
                    } else {
                        left.push(parseInt(element))
                    }
                }
                this.setState({ loading: false, left: left, right: right }, () => { this.setState({ loading: false }) })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    getAllCompanies() {
        this.setState({ loading: true })
        Service.get(`/bb/api/company/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var companyLookup = {}
                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    companyLookup[element.id] = element.name
                }
                this.setState({ companyLookup: companyLookup, loading: false }, () => {
                    this.getCompanies();
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    linkAndUnlinkCompany = (action, data) => {
        Service.post(`/bb/api/task/category/company/link_and_delink_category/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                task_category: this.props.category.id,
                company_id: data,
                action: action,
            }
        })
            .then(res => {
                this.props.enqueueSnackbar("SBU linked successfully", { variant: "success" });
                this.getAllCompanies()
            })
            .catch(error => {
                console.log(error)
                this.getAllCompanies()
                errorMessage(this.props, error)
            });
    }

    render() {

        const { classes } = this.props;

        const leftChecked = intersection(this.state.checked, this.state.left);
        const rightChecked = intersection(this.state.checked, this.state.right);

        const handleToggle = (value) => () => {
            const currentIndex = this.state.checked.indexOf(value);
            const newChecked = [...this.state.checked];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            this.setState({ checked: newChecked });
        };

        const handleAllRight = () => {
            this.linkAndUnlinkCompany("link", this.state.left)
            this.setState({ right: this.state.right.concat(this.state.left), left: [] });
        };

        const handleCheckedRight = () => {

            this.setState({
                right: this.state.right.concat(leftChecked),
                left: not(this.state.left, leftChecked),
                checked: not(this.state.checked, leftChecked)
            }, () => {
                this.linkAndUnlinkCompany("link", leftChecked)
            });

        };

        const handleCheckedLeft = () => {
            this.setState({
                left: this.state.left.concat(rightChecked),
                right: not(this.state.right, rightChecked),
                checked: not(this.state.checked, rightChecked)
            }, () => {
                this.linkAndUnlinkCompany("delink", rightChecked)
            });
        };

        const handleAllLeft = () => {
            this.linkAndUnlinkCompany("delink", this.state.right)
            this.setState({ left: this.state.left.concat(this.state.right), right: [] });
        };

        const customList = (items) => (
            <Paper className={classes.paper}>
                <List dense component="div" role="list">
                    {items.map((value) => {
                        const labelId = `transfer-list-item-${value}-label`;
                        return (
                            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={this.state.checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={this.state.companyLookup[value]} />
                            </ListItem>
                        );
                    })}
                    <ListItem />
                </List>
            </Paper>
        );
        return (
            <div>
                {
                    this.state.loading ? <div><Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                        <CircularProgress color='primary' />
                    </Box></div> :
                        <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            className={classes.root}
                        >
                            <Grid item>{customList(this.state.left)}</Grid>
                            <Grid item>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleAllRight}
                                        disabled={this.state.left.length === 0}
                                        aria-label="move all right"
                                    >
                                        ≫
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        &gt;
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                        aria-label="move selected left"
                                    >
                                        &lt;
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleAllLeft}
                                        disabled={this.state.right.length === 0}
                                        aria-label="move all left"
                                    >
                                        ≪
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item>{customList(this.state.right)}</Grid>
                        </Grid>
                }
            </div >
        );
    }
}

export default withStyles(useStyles)(withSnackbar(CompanyCategoryTransferList));
