import { Box, Button, Dialog, DialogContent } from '@material-ui/core'
import { withStyles } from '@material-ui/styles';
import React from 'react'
import Toolbar from 'react-big-calendar/lib/Toolbar';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import UserTasks from '../WorkHours/UserTasks';
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import { screenSizeCheck } from '../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import WorkPlanDialog from './WorkPlans/WorkPlanDialog';
import SettingsIcon from '@material-ui/icons/Settings';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@mui/material/Tooltip';
// import Toolbar from 'react-big-calendar';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

const navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE'
};

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    toolButtons: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        // color: theme.palette.grey[500],
    },

    closeButton: {
        // position: 'absolute',
        // right: theme.spacing(1),
        // top: theme.spacing(1),
        color: 'red',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            <Box className={classes.toolButtons}>
                <IconButton aria-label="newtab" color="primary" onClick={() => window.open("/home/#Workload%20Report")}>
                    <OpenInNewIcon />
                </IconButton>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </Box>
        </MuiDialogTitle>
    );
});

class CalenderCustomToolbar extends Toolbar {

    constructor(props) {
        super(props)

        this.state = {
            open: false,
            teamLeadData: null,
            workPlanName: 'Select WorkPlan',
            currentPlan: null,
            showPlanButton: false,
            date: moment(this.props.date).format('YYYY-MM-DD')
        }
        this.workPlan = React.createRef()
        this.selectedPlan = this.selectedPlan.bind(this)
    }


    componentDidMount() {
        if (this.props.user) {
            this.getWeekPlan()
        }
        // const view = this.props.view;
        // console.log(view)
        setTimeout(() => {
            Service.get("/bb/api/team/access/?uid=" + user.id + "&team_role=1,2", {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({
                        teamLeadData: res.data
                    })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }, 750);

    }

    navigate = (action) => {
        this.props.onNavigate(action);
        // console.log(action)
        if (action === 'NEXT') {
            this.setState({ date: moment(this.state.date).add(7, 'days').format('YYYY-MM-DD') }, () => {
                if (this.state.showPlanButton && this.workPlan && this.workPlan.current) {
                    this.workPlan.current.weekPlan(false)
                }
            })
        } else if (action === 'PREV') {
            this.setState({ date: moment(this.state.date).subtract(7, 'days').format('YYYY-MM-DD') }, () => {
                if (this.state.showPlanButton && this.workPlan && this.workPlan.current) {
                    this.workPlan.current.weekPlan(false)
                }
            })
        } else if (action === 'TODAY') {
            this.setState({ date: moment(this.props.date).format('YYYY-MM-DD') }, () => {
                if (this.state.showPlanButton && this.workPlan && this.workPlan.current) {
                    this.workPlan.current.weekPlan(false)
                }
            })
        }
    };

    onPlanChange = (event, values) => {
        this.setState({ plan_name: values })
    }

    selectedPlan(plan) {
        this.setState({ workPlanName: plan })
    }

    getWeekPlan() {
        Service.get(`/bb/api/user/work/plan/user_plan_for_week/?user_id=${this.props.user.uid}&date=${moment(this.state.date).format('YYYY-MM-DD')}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ workPlanName: res.data.work_plan_data.name, showPlanButton: true })
            })
            .catch(error => {
                if (error && error.status === 404) {
                    this.setState({ workPlanName: "Select Workplan", showPlanButton: true })
                } else if (error && error.status === 451) {
                    this.setState({ showPlanButton: false })
                } else {
                    errorMessage(this.props, error)
                    console.log(error)
                }
            })
    }

    openWorkPlanDialog() {
        if (this.workPlan && this.workPlan.current) {
            this.workPlan.current.weekPlan(true)
        }
    }

    getDateForCalendar(propsDate) {
        var leave_element = null
        for (let i = 0; i < this.props.holiday.length; i++) {
            const element = this.props.holiday[i];
            if (moment(propsDate).format('YYYY-MM-DD') === element.leave_date) {
                leave_element = element
            }
        }
        return leave_element
    }

    viewChange = (view) => {
        this.props.onView(view);
        localStorage.setItem('default_view', view)
    }

    triggerCopy = () => {
        this.viewChange("week");
        setTimeout(() => {
            if (this.props.copyFromLastWeek) this.props.copyFromLastWeek()
        }, 200);
    }

    triggerSettings = () => {
        this.viewChange("work_week");
        setTimeout(() => {
            if (this.props.openTimesheetSettings) this.props.openTimesheetSettings()
        }, 200);
    }

    render() {

        const handleClose = () => {
            this.setState({
                open: false
            })
        };

        // const view = this.props.view;
        return (
            <div>
                <Box display="flex" alignSelf="center" style={{ marginBottom: '5px' }} alignItems={'center'}>

                    <Box display="flex" width="100%" alignItems={'center'} flexDirection={'row'}>
                        <Box p={.3}>
                            <Button variant="outlined" size={screenSizeCheck() ? 'small' : 'medium'} onClick={() => this.navigate(navigate.TODAY)}>Today</Button>
                        </Box>
                        <Box p={.3}>
                            <Button variant="outlined" size={screenSizeCheck() ? 'small' : 'medium'} onClick={() => this.navigate(navigate.PREVIOUS)}>Back</Button>
                        </Box>
                        <Box p={.3}>
                            <Button variant="outlined" size={screenSizeCheck() ? 'small' : 'medium'} onClick={() => this.navigate(navigate.NEXT)}>Next</Button>
                        </Box>

                        {this.props.noPlans ? null :
                            <Box p={.3} style={{ marginLeft: '10px' }}>
                                <Button variant="contained" size={screenSizeCheck() ? 'small' : 'medium'} color="secondary" onClick={() => { this.setState({ open: true }) }}>Workload</Button>
                            </Box>}
                        {(this.props.view === 'work_week' || this.props.view === 'week') ?
                            <Box p={.3}>
                                <Tooltip title={'Copy from Previous Week'} >
                                    <IconButton size={screenSizeCheck() ? 'small' : 'medium'} onClick={() => this.triggerCopy()}>
                                        <FileCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box> : null}
                        {/* {(this.state.showPlanButton && ((this.props.view === 'work_week') || (this.props.view === 'week'))) ?
                            <Box p={.2} style={{ marginLeft: '10px' }}>
                                <Button variant="contained" size={screenSizeCheck() ? 'small' : 'medium'} color="secondary" onClick={() => { this.openWorkPlanDialog() }}>{this.state.workPlanName}</Button>
                            </Box> : null} */}

                        {/* <Button variant="outlined" onClick={this.view.bind(null, 'agenda')}>Agenda</Button> */}
                    </Box>

                    <Box display="flex" width="100%" justifyContent={screenSizeCheck() ? "flex-end" : "center"}>
                        <Box p={.3} fontWeight={500} fontSize={screenSizeCheck() ? 16 : 18} style={{ marginRight: '10%' }}>
                            {this.props.view === 'work_week' || this.props.view === 'week' ? (this.props.label + " (Week " + moment(this.props.date, "MM-DD-YYYY").day(3).isoWeek() + ")") : this.props.view === 'day' ? this.props.label + (this.getDateForCalendar(this.props.date) ? " (" + this.getDateForCalendar(this.props.date).name + ")" : "") : this.props.label}
                        </Box>

                    </Box>
                    <Box display="flex" width="100%" justifyContent="flex-end" alignItems={'center'}>
                        {/* <Box p={.3}>
                            <Button variant="outlined" color="secondary" onClick={() => this.props.copyFromLastWeek()}>Copy from Previous Week</Button>
                        </Box> */}
                        {this.props.showTimesheetSetting ?
                            <Box p={.3}>
                                <Tooltip title={'Timesheet Settings'} >
                                    <IconButton size={screenSizeCheck() ? 'small' : 'medium'} onClick={() => this.triggerSettings()}>
                                        <SettingsIcon />
                                    </IconButton>
                                </Tooltip>
                                {/* <Button variant="outlined" size={screenSizeCheck() ? 'small' : 'medium'} color="secondary" onClick={() => this.props.openTimesheetSettings()}>Settings</Button> */}
                            </Box>
                            : null}
                        <Box p={.3}>
                            <Button variant={this.props.view === "month" ? "contained" : "outlined"} size={screenSizeCheck() ? 'small' : 'medium'} onClick={() => { this.viewChange('month') }}>Month</Button>
                        </Box>
                        <Box p={.3}>
                            <Button variant={this.props.view === "work_week" ? "contained" : "outlined"} size={screenSizeCheck() ? 'small' : 'medium'} onClick={() => { this.viewChange('work_week') }}>Work Week</Button>
                        </Box>
                        <Box p={.3}>
                            <Button variant={this.props.view === "week" ? "contained" : "outlined"} size={screenSizeCheck() ? 'small' : 'medium'} onClick={() => { this.viewChange('week') }}>Week</Button>
                        </Box>
                        <Box p={.3}>
                            <Button variant={this.props.view === "day" ? "contained" : "outlined"} size={screenSizeCheck() ? 'small' : 'medium'} onClick={() => { this.viewChange('day') }}>Day</Button>
                        </Box>
                    </Box>
                </Box>
                <Dialog fullWidth maxWidth="xl" onClose={handleClose} aria-labelledby="customized-dialog-title" open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Workload
                    </DialogTitle>
                    <DialogContent>
                        <UserTasks teamLeadData={this.state.teamLeadData} uid={this.props.user ? this.props.user.uid : null} />
                    </DialogContent>
                </Dialog>

                <WorkPlanDialog ref={this.workPlan} autoCheck={false} uid={this.props.user ? this.props.user.uid : null} date={this.state.date} selectedPlan={this.selectedPlan} />

            </div >
        )
    }
}

export default withSnackbar(CalenderCustomToolbar)
