import React, { Component } from 'react';
import { errorMessage } from '../Helper/ErrorMessage';
import Service from '../Networking/networkutils';
import { getCookie } from '../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import ManageTasks from './ManageTasks';

const token = getCookie("bb_ts_token");
// const access = JSON.parse(getCookie("bb_ts_access"));
// const user = JSON.parse(getCookie("bb_ts_user"));

class CompanyCategory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true
        }
    }

    componentDidMount() {
        this.fetchOpsByCompany()
    }

    fetchOpsByCompany() {
        this.setState({ isLoading: true })
        let api = `/bb/api/task/category/company/companies_category_list/`
        if (this.props.data) {
            api += `?company=${this.props.data.id}`
        }
        Service.get(api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title={this.props.data ? (this.props.data.name + `'s Categories`) : "Your SBU Categories"}
                    columns={[
                        { title: 'ID', field: 'id',editable: 'never'},
                        { title: 'Name', field: 'name' },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        padding: "dense",
                        paging: false,
                        actionsColumnIndex: -1,
                    }}
                    detailPanel={rowData => {
                        return (
                            <ManageTasks value={rowData} editable={false} />
                        )
                    }}
                />
            </div>
        );
    }
}

export default withSnackbar(CompanyCategory);