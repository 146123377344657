import React, { Component } from 'react'
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, InputLabel, Select, MenuItem, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading'
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import ReactGA from 'react-ga';
// import AddIcon from '@material-ui/icons/Add';
// import { withStyles } from '@material-ui/styles';
// import TeamRoleMapping from '../Teams/TeamRoleMapping';
import AdditionalHourRequest2 from '../Teams/AssignRoles/AdditionalHourRequest2';
import { errorMessage } from '../Helper/ErrorMessage';
import { getPrimaryColor } from '../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
var autocompdata = '';

// const RedTextTypography = withStyles({
//     root: {
//         color: "#FF0000"
//     }
// })(Typography);

// const findDuplicates = (arr) => {
//     let sorted_arr = arr.slice().sort(); // You can define the comparing function here. 
//     // JS by default uses a crappy string compare.
//     // (we use slice to clone the array so the
//     // original array won't be modified)
//     let results = [];
//     for (let i = 0; i < sorted_arr.length - 1; i++) {
//       if (sorted_arr[i + 1] === sorted_arr[i]) {
//         results.push(sorted_arr[i]);
//       }
//     }
//     return results;
//   }

const access = JSON.parse(getCookie("bb_ts_access"));
var task_alloc_hours = 0
var task_rev_hours = 0


class SecuredProjectRoles extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            openAdd: false,
            isLoading: true,
            isLoading2: false,
            alloc_data: [],
            addHours: 0,
            addEmp: 0,
            area: 'in',
            roles: {},
            saved: true,
            template_data: false,
            isLoadingDum: true,
            selectedeRowData: null,
            historyData: [],
            rates: [],
            actual: [],
            allRoles: null,
            addRoleDisabled: false,
            // taskData: props.value
        }
    }

    componentDidMount() {
        // console.log(this.state.taskData)
        if (this.props.value) {
            this.setState({ taskData: this.props.value }, () => {
                this.fetchOps();
            })
        } else {
            this.getTask()
        }


        if (this.props.rolesLookUp) {
            this.getRoles()
        }
        // tender/wbs/tasks

    }

    getTask() {
        Service.get('/bb/api/tender/wbs/tasks/' + this.props.tid + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                res.data.ds = res.data.t_wbs_data.pid_data
                res.data.wbs = res.data.t_wbs_data
                this.setState({ taskData: res.data }, () => {
                    this.fetchOps();
                })


            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    getRoles() {
        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var allRoles = {}
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    allRoles[element.id] = element.name
                }
                this.setState({ allRoles: allRoles })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchOps() {

        var pageView = window.location.pathname + "/#TaskLevelAllocation";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }
        Service.get('/bb/api/tender/tasks/rolesrevhistory/?tid=' + this.state.taskData.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var taskArr = []
                // var roles1 = res.data;
                // console.log(res.data)
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    element.req_hours = element.hours + element.req_hours
                    taskArr.push(element.t_tasks)
                    element.rev_hours = element.req_hours
                    task_rev_hours += element.rev_hours
                }



                // taskArr = taskArr.filter(onlyUnique)

                // for (let i = 0; i < taskArr.length; i++) {
                //     const element = taskArr[i];
                //     var roleArr = []
                //     var roleArrIndex = [];
                //     for (let j = 0; j < res.data.length; j++) {
                //         const element1 = res.data[j];
                //         if (element === element1.t_tasks) {
                //             roleArr.push(element1.t_role)
                //             roleArrIndex.push(j)
                //         }
                //     }
                //     roleArr = findDuplicates(roleArr)
                //     var found1index = -1;
                //     for (let k = 0; k < roleArr.length; k++) {
                //         const element2 = roleArr[k];
                //         var hoursinAllRole = 0
                //         for (let j = 0; j < roleArrIndex.length; j++) {
                //             const element1 = res.data[roleArrIndex[j]];
                //             if (element === element1.t_tasks && element2 === element1.t_role && found1index !== -1) {
                //                 hoursinAllRole = hoursinAllRole + element1.req_hours
                //                 delete res.data[j]
                //             } else if (element === element1.t_tasks && element2 === element1.t_role && found1index === -1) {
                //                 found1index = j
                //             } 
                //         }
                //         res.data[found1index].all_hours_to_this_role =  hoursinAllRole
                //     }

                // }

                if (this.props.ds && this.props.ds.contract_type) {
                    Service.get('/bb/api/tender/roles/subcontract/?t_tasks=' + this.state.taskData.id, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res1 => {
                            // console.log(res1.data)
                            this.setState({
                                actual: res1.data
                            })

                            for (let i = 0; i < res.data.length; i++) {
                                const element = res.data[i];
                                element.act_avail = false
                                for (let j = 0; j < res1.data.length; j++) {
                                    const element1 = res1.data[j];
                                    if (element.id === element1.t_role) {
                                        element.act_avail = true
                                        element.act_data = element1
                                    }
                                }
                            }

                            this.setState({
                                rates: this.props.rates,
                                data: res.data,
                                saved: true,
                                isLoading: false,
                                isLoadingDum: false,
                                template_data: false,
                            }, () => {
                                if (!(this.props.ds && this.props.ds.contract_type)) {
                                    Service.get(`/bb/api/tender/user/task/?tid=` + this.state.taskData.id, {
                                        headers: {
                                            Authorization: "Token " + token,
                                        },
                                    })
                                        .then(res31 => {

                                            for (let i = 0; i < res.data.length; i++) {
                                                const element = res.data[i];
                                                var plan_hours = 0
                                                for (let j = 0; j < res31.data.length; j++) {
                                                    const element1 = res31.data[j];
                                                    if (element.id === element1.task_role_id) {
                                                        plan_hours = plan_hours + element1.planed_hours
                                                    }
                                                }
                                                element.alloc_plan = plan_hours
                                                task_alloc_hours += plan_hours
                                            }
                                            this.setState({
                                                data: res.data,
                                            })

                                        })
                                        .catch(error => {
                                            errorMessage(this.props, error)
                                        });
                                }

                            })
                        })
                        .catch(error => {
                            console.log(error)
                            errorMessage(this.props, error)
                        });
                } else {
                    this.setState({

                        data: res.data,
                        saved: true,
                        isLoading: false,
                        isLoadingDum: false,
                        template_data: false,
                    }, () => {
                        if (!(this.props.ds && this.props.ds.contract_type)) {
                            Service.get(`/bb/api/tender/user/task/?tid=` + this.state.taskData.id, {
                                headers: {
                                    Authorization: "Token " + token,
                                },
                            })
                                .then(res31 => {

                                    for (let i = 0; i < res.data.length; i++) {
                                        const element = res.data[i];
                                        var plan_hours = 0
                                        for (let j = 0; j < res31.data.length; j++) {
                                            const element1 = res31.data[j];
                                            if (element.id === element1.task_role_id) {
                                                plan_hours = plan_hours + element1.planed_hours
                                            }
                                        }
                                        element.alloc_plan = plan_hours
                                        task_alloc_hours += plan_hours
                                    }
                                    this.setState({
                                        data: res.data,
                                    })

                                })
                                .catch(error => {
                                    console.log(error)
                                    errorMessage(this.props, error)
                                });
                        }

                    })
                }


            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });

        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                if (res.data.length === 0) {
                    this.props.enqueueSnackbar('Goto Roles tab to initiate default roles!', {
                        variant: 'warning'
                    });
                } else {
                    this.setState({
                        roles: res.data,
                    });
                }

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }


    deleteData(data1) {
        if (data1.id) {
            Service.delete('/bb/api/tender/tasks/rolesrevhistory/' + data1.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.props.enqueueSnackbar('Deleted Successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }

    }

    // costCalc(data1) {
    //     if (data1.country === 'in') {
    //         for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
    //             const element = this.props.rates[0].t_rates[i];
    //             if (data1.grade.toUpperCase() === element.grade) {
    //                 // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
    //                 return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.hours * element.costin);
    //             }
    //         }
    //     } else {
    //         for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
    //             const element = this.props.rates[0].t_rates[i];
    //             if (data1.grade.toUpperCase() === element.grade) {
    //                 return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.hours * element.costuk);
    //             }
    //         }
    //     }
    //     return 'null';
    // }

    rateCalc(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return element.costin;
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    return element.costuk;
                }
            }
        }
        return 'null';
    }

    costCalc(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.hours * element.costin);
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.hours * element.costuk);
                }
            }
        }
        return 'null';
    }

    revCostCalc(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.rev_hours * element.costin);
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.rev_hours * element.costuk);
                }
            }
        }
        return 'null';
    }

    actCostCalc(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.act_data.act_hours * element.costin);
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.act_data.act_hours * element.costuk);
                }
            }
        }
        return 'null';
    }

    actCostCalc2(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return data1.act_data.act_hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    return data1.act_data.act_hours * element.costuk;
                }
            }
        }
        return 'null';
    }

    onTagsChange = (event, values) => {
        // console.log(values)
        autocompdata = values;
    }

    addData() {
        if (this.state.addHours && this.state.addHours > 0) {
            this.setState({
                isLoadingDum: true
            })

            var pageView = window.location.pathname + "/#TenderAddAndEdit";
            ReactGA.set({ page: pageView, });
            ReactGA.pageview(pageView);

            if (!getCookie("bb_ts_token")) {
                window.location.href = '/'
            }

            var roleExist = false;

            if (autocompdata !== '') {

                for (let i = 0; i < this.state.data.length; i++) {
                    const element = this.state.data[i];
                    if (element.t_role === autocompdata.id && element.country === this.state.area) {
                        roleExist = true;
                        break;
                    }
                }

                if (!roleExist) {
                    var postData = {};
                    // var data1 = this.state.taskData;
                    postData.t_pid = this.state.taskData.t_pid;
                    postData.t_wbs = this.state.taskData.t_ohlwbs;
                    postData.t_tasks = this.state.taskData.id;
                    postData.t_role = autocompdata.id;
                    postData.remark = '';
                    postData.created_by = user.email;
                    postData.name = autocompdata.name;
                    postData.grade = autocompdata.grade.toLowerCase();
                    postData.hours = 0;
                    postData.req_hours = this.state.addHours;
                    postData.country = this.state.area;
                    postData.goon = 1;
                    postData.hours_rev_history = [{
                        user: user.id,
                        user_name: user.first_name + " " + user.last_name,
                        start: this.state.addHours,
                        date_modified: new Date(),
                        rev_new: this.state.addHours,
                    }]

                    // console.log(postData)
                    // 
                    this.setState({ addRoleDisabled: true })
                    Service.post("/bb/api/tender/tasks/rolesrevhistory/", {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: postData,
                    })
                        .then(res => {
                            this.setState({
                                isLoading: false,
                                openAdd: false,
                                addRoleDisabled: false
                            })
                            // console.log(res.data)
                            this.props.enqueueSnackbar('Roles updated successfully', {
                                variant: 'success'
                            });
                            this.fetchOps();
                        })
                        .catch(error => {
                            this.setState({ addRoleDisabled: false })
                            console.log(error)
                            errorMessage(this.props, error)
                        });
                } else {
                    this.setState({
                        isLoading: false,
                        openAdd: false,

                    })
                    this.fetchOps()
                    this.props.enqueueSnackbar('Role with same country configuration already exist!', {
                        variant: 'warning'
                    });
                }

            } else {
                this.props.enqueueSnackbar('Select Role to Add', {
                    variant: 'warning'
                });
            }
        } else {
            this.props.enqueueSnackbar('Hours must be greater than 0!', {
                variant: 'warning'
            });
        }
    }

    postData(data321, newData, oldData) {

        var pageView = window.location.pathname + "/#TenderAddAndEdit";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }

        this.setState({ isLoadingDum: true })
        var goon = true

        if (newData.act_avail) {
            if (!(newData.rev_cost >= this.actCostCalc2(newData))) {
                this.props.enqueueSnackbar("Cost can't go less than the actual cost.", {
                    variant: 'warning'
                });
                goon = false
            } else {
                newData.req_hours = (newData.rev_cost / this.rateCalc(newData));
            }
        }
        if (goon) {
            Service.put("/bb/api/tender/tasks/roles/revised_hours_update/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    role: newData.id,
                    hours_need: newData.req_hours,
                    remark: newData.remark
                },
            })
                .then(res1 => {
                    // console.log(res.data)
                    this.props.enqueueSnackbar('Hours updated.', {
                        variant: 'success'
                    });
                    this.fetchOps();
                })
                .catch(error => {
                    this.setState({
                        isLoadingDum: false
                    })
                    if (error && error.status === 406) {
                        this.props.enqueueSnackbar(error.data, {
                            variant: 'warning'
                        });
                    } else {
                        errorMessage(this.props, error)
                    }
                });

        }

        // Service.get(`/bb/api/tender/user/task/?tid=` + this.state.taskData.id, {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        // })
        //     .then(res21 => {
        //         Service.get('/bb/api/tender/tasks/rolesrevhistory/?tid=' + this.state.taskData.id, {
        //             headers: {
        //                 Authorization: "Token " + token,
        //             },
        //         })
        //             .then(res => {
        //                 var goon = true;


        //                 if (this.props.ds && this.props.ds.contract_type) {

        //                     for (let i = 0; i < dataDum.length; i++) {
        //                         const element = dataDum[i];

        //                         if (element.act_avail) {
        //                             if (!(element.rev_cost >= this.actCostCalc2(element))) {
        //                                 goon = false

        //                             } else {
        //                                 element.req_hours = (element.rev_cost / this.rateCalc(element)) - element.hours;
        //                             }
        //                         }

        //                     }

        //                 } else {
        //                     for (let i = 0; i < dataDum.length; i++) {
        //                         const element = dataDum[i];

        //                         var alloc_hours = 0
        //                         for (let j = 0; j < res21.data.length; j++) {
        //                             const element2 = res21.data[j];
        //                             if (element.id === element2.task_role_id) {
        //                                 alloc_hours = alloc_hours + element2.planed_hours
        //                             }
        //                         }

        //                         for (let j = 0; j < res.data.length; j++) {
        //                             const element1 = res.data[j];
        //                             if (element1.id === element.id && !(alloc_hours <= element.req_hours)) {
        //                                 // console.log(element1.hours, alloc_hours, element.req_hours)
        //                                 goon = false
        //                                 break;
        //                             }
        //                         }

        //                         if (!goon) {
        //                             break;
        //                         }

        //                         element.req_hours = element.req_hours - element.hours;

        //                     }
        //                 }


        //                 if (goon) {

        //                     for (let i = 0; i < dataDum.length; i++) {
        //                         const element = dataDum[i];
        //                         if (element.id === newData.id) {
        //                             if (element.hours_rev_history) {
        //                                 element.hours_rev_history.push({
        //                                     user: user.id,
        //                                     user_name: user.first_name + " " + user.last_name,
        //                                     rev_old: parseFloat(oldData.req_hours),
        //                                     rev_new: parseFloat(element.req_hours) + element.hours,
        //                                     hours: newData.hours,
        //                                     date_modified: new Date()
        //                                 })
        //                             } else {
        //                                 element.hours_rev_history = [{
        //                                     user: user.id,
        //                                     user_name: user.first_name + " " + user.last_name,
        //                                     rev_old: parseFloat(oldData.req_hours),
        //                                     rev_new: parseFloat(element.req_hours) + element.hours,
        //                                     hours: newData.hours,
        //                                     date_modified: new Date()
        //                                 }]
        //                             }
        //                             break
        //                         }
        //                     }
        //                     Service.patch("/bb/api/tender/tasks/rolesrevhistory/bulk_update_freemove/", {
        //                         headers: {
        //                             Authorization: "Token " + token,
        //                         },
        //                         data: dataDum,
        //                     })
        //                         .then(res1 => {
        //                             // console.log(res.data)
        //                             this.props.enqueueSnackbar('Roles updated successfully', {
        //                                 variant: 'success'
        //                             });
        //                             this.fetchOps();
        //                         })
        //                         .catch(error => {

        //                             console.log(error)
        //                             errorMessage(this.props, error)
        //                         });


        //                 } else {
        //                     this.setState({ isLoadingDum: false })
        //                     this.props.enqueueSnackbar("Requested hours should not be less than Allocated/Planned Hours or Estimated Hours.", {
        //                         variant: 'warning'
        //                     });
        //                 }
        //             })
        //             .catch(error => {
        //                 console.log(error)
        //                 errorMessage(this.props, error)
        //             });


        //     })
        //     .catch(error => {
        //         errorMessage(this.props, error)
        //     });


    }

    render() {
        const handleClose = (e) => {
            this.setState({ openAdd: false, open: false, addHours: 0 })
        };
        const handleHistoryClose = (e) => {
            this.setState({ openHistory: false })
        };
        const handleAddHours = (e) => {
            this.setState({ addHours: e.target.value })
        };

        return (
            <div>
                {(this.state.isLoading || this.state.isLoading2) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>

                        <Box>
                            <MaterialTable
                                key={3}
                                title={this.state.taskData.t_wbs_data.name + " - " + this.state.taskData.name}
                                columns={[
                                    {
                                        title: 'Roles', field: 'name', editable: 'never',
                                    },
                                    {
                                        title: 'Grade', field: 'grade', editable: 'never',
                                        lookup: { 'a': 'A', 'b': 'B', 'c': 'C', 'd': 'D', 'e': 'E', 'f': 'F', 'g': 'G' },
                                    },
                                    {
                                        title: 'Country', field: 'country', editable: 'never',
                                        lookup: { 'in': 'India', 'uk': 'UK', 'sl': 'Srilanka' },
                                    },
                                    {
                                        title: 'Budget Cost', field: 'cost', editable: 'never',
                                        hidden: !(this.props.ds && this.props.ds.contract_type),
                                        render: rowData => this.costCalc(rowData)
                                    },
                                    {
                                        title: 'Cost at completion', field: 'rev_cost',
                                        hidden: !(this.props.ds && this.props.ds.contract_type),
                                        render: rowData => this.revCostCalc(rowData)
                                    },
                                    {
                                        title: 'Current Actual Cost', field: 'act_cost', editable: 'never',
                                        hidden: !(this.props.ds && this.props.ds.contract_type),
                                        render: rowData => rowData.act_avail ? this.actCostCalc(rowData) : "NA"
                                    },
                                    {
                                        title: 'Budget Hours', field: 'hours',
                                        type: 'numeric', editable: 'never',
                                        hidden: (this.props.ds && this.props.ds.contract_type),
                                    },

                                    {
                                        title: 'Hours at completion', field: 'req_hours',
                                        type: 'numeric',
                                        hidden: (this.props.ds && this.props.ds.contract_type),
                                    },
                                    // { title: 'No of employees', field: 'no_off', type: 'numeric' },
                                    {
                                        title: 'Allocated/Planned Hours', field: 'alloc_plan',
                                        type: 'numeric', editable: 'never',
                                        hidden: (this.props.ds && this.props.ds.contract_type),
                                    },


                                    // {
                                    //     title: 'Amount', field: 'cost', editable: 'never',
                                    //     render: rowData => <Typography>{this.costCalc(rowData)}</Typography>
                                    // },
                                    { title: 'Remarks', field: 'remark', },
                                ]}
                                data={this.state.data}
                                isLoading={this.state.isLoadingDum}
                                actions={[
                                    // {
                                    //     icon: 'save',
                                    //     tooltip: 'Save Data',
                                    //     isFreeAction: true,
                                    //     disabled: this.state.save,
                                    //     onClick: () => { this.postData(); },
                                    // },

                                    {
                                        icon: 'add_box',
                                        tooltip: 'Add Role',
                                        isFreeAction: true,
                                        disabled: (this.state.save || this.props.requestOption || this.state.taskData.t_wbs_data.pid_data.audit_status === 13),
                                        onClick: () => this.setState({ openAdd: true }),
                                    },

                                    (this.props.requestOption) ? (
                                        {
                                            icon: 'report',
                                            tooltip: 'Request Hours',
                                            // isFreeAction: true,
                                            disabled: !this.props.requestOption,
                                            onClick: (event, rowData) => { this.setState({ open: true, selectedeRowData: rowData }) },
                                        }

                                    ) : (null),

                                    {
                                        icon: 'change_history',
                                        tooltip: 'Change History',
                                        // isFreeAction: true,
                                        disabled: !((access && access.access && access.access.l14a && access.access.l14a.l14a_a) || !this.props.nonEditable),
                                        onClick: (event, rowData) => { this.setState({ openHistory: true, historyData: rowData.hours_rev_history }) },
                                    },

                                ]}
                                options={{
                                    padding: "dense",
                                    actionsColumnIndex: -1,
                                    search: false,
                                    paging: false,
                                    // filtering: true
                                }}
                                icons={{

                                    // Edit: () => <Tooltip title={"Add Extra Hours"}><AddIcon /></Tooltip>
                                }}
                                editable={{
                                    isDeletable: rowData => rowData.hours === 0 && rowData.req_hours === 0 && ((access && access.access && access.access.l14a && access.access.l14a.l14a_a) || !this.props.nonEditable),
                                    isEditable: rowData => ((access && access.access && access.access.l14a && access.access.l14a.l14a_a) || !this.props.nonEditable) && this.state.taskData.t_wbs_data.pid_data.audit_status !== 13,
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                // newData.hours = newData.hours;
                                                // this.setState({ saved: false, })
                                                this.setState(prevState => {

                                                    const data = [...prevState.data];
                                                    const oldInfo = JSON.parse(JSON.stringify(oldData))
                                                    data[data.indexOf(oldData)] = newData;
                                                    this.postData(data, newData, oldInfo)
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                // this.setState({ saved: false });
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    this.deleteData(oldData)
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                }}
                            />
                        </Box>
                        <Dialog open={this.state.openAdd} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Add Roles</DialogTitle>
                            <DialogContent>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={this.state.roles}
                                    getOptionLabel={(option) => option.name}
                                    onChange={this.onTagsChange}
                                    style={{ marginTop: '15px' }}
                                    renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
                                />
                                {/* <TextField id="outlined-basic22" label="Hours" variant="outlined"
                                        type="numeric" value={this.state.addHours} fullWidth style={{ marginTop: '15px' }}
                                        onChange={handleAddHours} /> */}
                                <FormControl variant="outlined" style={{ width: '100%', marginTop: '15px' }}>
                                    <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.area}
                                        onChange={e => { this.setState({ area: e.target.value }) }}
                                        label="Country"
                                    >
                                        <MenuItem value="in">India</MenuItem>
                                        <MenuItem value="uk">UK</MenuItem>
                                        <MenuItem value="sl">Srilanka</MenuItem>
                                    </Select>
                                </FormControl>

                                <TextField
                                    id="hours"
                                    type={'number'}
                                    label="Hours"
                                    variant="outlined"
                                    style={{ width: '100%', marginTop: '15px' }}
                                    value={this.state.addHours}
                                    onChange={handleAddHours}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button disabled={this.state.addRoleDisabled} onClick={e => { this.addData() }} color="primary">
                                    Add Role
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog onClose={handleClose} fullwidth maxWidth="xl" aria-labelledby="customized-dialog-title" open={this.state.open}>
                            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                                {(this.state.selectedeRowData) ? this.state.selectedeRowData.name + " [ " + this.props.projectLook[this.state.taskData.t_wbs_data.pid_data.subproject_pid] + " - " + this.state.taskData.ds.name + " ] Available hours for allocation : " + (this.state.selectedeRowData.req_hours) + " hours" : null}
                            </DialogTitle>
                            <DialogContent dividers>
                                {(this.state.selectedeRowData) ? (
                                    <div>
                                        <Typography>{this.state.taskData.wbs.name + "   --->  Revised Hours: " + (this.state.taskData.est_hrs ? this.state.taskData.est_hrs : task_rev_hours) + ", Allocated Hours: " + (this.state.taskData.pld_hrs ? this.state.taskData.pld_hrs : task_alloc_hours) + ". Please contact EM to increase available hours for allocation."}</Typography>
                                        <AdditionalHourRequest2 value={{
                                            id: this.state.selectedeRowData.id,
                                            name: this.state.selectedeRowData.name,
                                            country: this.state.selectedeRowData.country,
                                            t_tasks: this.state.taskData.id,
                                            t_tasks_data: this.state.taskData,
                                            t_role: this.state.selectedeRowData.t_role,
                                            all_hours_to_this_role: this.state.selectedeRowData.req_hours,
                                        }} task={this.state.taskData} team={this.props.team} rolesLookUp={this.props.allRoles ? this.props.allRoles : this.state.allRoles} projectLook={this.props.projectLook} />
                                    </div>
                                ) : null}

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.openHistory} maxWidth={"lg"} fullWidth onClose={handleHistoryClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Revised Hours Modified History</DialogTitle>
                            <DialogContent>
                                {(this.state.historyData) ? (
                                    <MaterialTable
                                        key={3}
                                        title={""}
                                        columns={[
                                            {
                                                title: 'User', field: 'user_name', editable: 'never',
                                            },
                                            {
                                                title: 'Old Revised Hours', field: 'rev_old', editable: 'never',
                                            },
                                            {
                                                title: 'New Revised Hours', field: 'rev_new', editable: 'never',
                                            },
                                            {
                                                title: 'Modified On', field: 'date_modified', type: 'datetime', editable: 'never', defaultSort: 'desc',
                                            },

                                        ]}
                                        data={this.state.historyData}
                                        options={{
                                            padding: "dense",
                                            // actionsColumnIndex: -1
                                        }}
                                    />
                                ) : ("No data available")}

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleHistoryClose} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>
                    </div>
                )}
                {(this.state.isLoadingDum) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (null)}
            </div>

        )
    }
}

// function onlyUnique(value, index, self) {
//     return self.indexOf(value) === index;
//   }

export default withSnackbar(SecuredProjectRoles)