import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getDarkMode, getDiscipline, getGBPFormat } from '../../Helper/CommonThings';
import { Typography } from '@mui/material';
import { DialogActions, DialogContent, Link } from '@material-ui/core';
import CMRiskDSTable from './CMRiskDSTable';
import { BootstrapDialogTitleV4, BootstrapDialogV4 } from '../../Helper/DialogTitleM5';

class CMRiskInfoView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            openDialog: false,
            emData: null
        }
    }

    componentDidMount() {

    }

    openDialog(item) {
        this.setState({ openDialog: true, emData: item })
    }

    render() {
        const handleDialogClose = () => {
            this.setState({
                openDialog: false,
            })
        };
        return (
            <div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="right">This week (T)</TableCell>
                                <TableCell align="right">T-1</TableCell>
                                <TableCell align="right">T-2</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                key={"risk_value"}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    Change Value
                                </TableCell>
                                <TableCell align="right">{getGBPFormat(this.props.value.risk_value.t)}</TableCell>
                                <TableCell align="right">{getGBPFormat(this.props.value.risk_value['t-1'])}</TableCell>
                                <TableCell align="right">{getGBPFormat(this.props.value.risk_value['t-2'])}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography style={{ marginTop: '20px' }}>Trend between {this.props.value.decipline_trend.length ? this.props.value.decipline_trend[0].old_cost_date : null} and Today</Typography>

                <TableContainer component={Paper} style={{ marginTop: '7px' }}>
                    <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Discipline</TableCell>
                                <TableCell align="right">+</TableCell>
                                <TableCell align="right">-</TableCell>
                                <TableCell align="right">Today</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.value.decipline_trend.map(item =>
                                <TableRow
                                    key={"risk_value"}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {getDiscipline()[item.decipline]}
                                    </TableCell>
                                    <TableCell align="right">{(item.change_cost > 0) ? getGBPFormat(item.change_cost) : null}</TableCell>
                                    <TableCell align="right">{(item.change_cost < 0) ? getGBPFormat(item.change_cost) : null}</TableCell>
                                    <TableCell align="right">{getGBPFormat(item.new_cost)}</TableCell>
                                </TableRow>
                            )}

                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography style={{ marginTop: '20px' }}>Total unapproved changes value by EM (Top 7)</Typography>

                <TableContainer component={Paper} style={{ marginTop: '7px' }}>
                    <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>EM</TableCell>
                                <TableCell align="right">Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.value.pdm_data.slice(0, this.props.value.pdm_data.length < 7 ? this.props.value.pdm_data.length : 7).map(item =>
                                <TableRow
                                    key={"risk_value"}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Link underline='always' color={getDarkMode() === 'dark' ? 'secondary' : 'primary'} onClick={() => this.openDialog(item)}>{item.last_name + ", " + item.first_name}</Link>
                                    </TableCell>
                                    <TableCell align="right">{getGBPFormat(item.unapproved_cost)}</TableCell>
                                </TableRow>
                            )}

                        </TableBody>
                    </Table>
                </TableContainer>

                <BootstrapDialogV4 open={this.state.openDialog} maxWidth='xl' fullWidth aria-labelledby="form-dialog-title" onClose={handleDialogClose}>
                    <BootstrapDialogTitleV4 id="form-dialog-title" onClose={handleDialogClose}></BootstrapDialogTitleV4>
                    <DialogContent>
                        <CMRiskDSTable value={this.props.value} user={this.state.emData} />
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </BootstrapDialogV4>

            </div>
        );
    }
}

export default CMRiskInfoView;