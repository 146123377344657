import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack'
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../../../Helper/CommonThings';
import { getCookie } from '../../../Helper/CookieHelper';
import Service from '../../../Networking/networkutils'
import { errorMessage } from '../../../Helper/ErrorMessage';
import TaskAndRole from './TaskAndRole'
import SetDateToTask from './SetDateToTask'
import TaskEstimate from './TaskEstimate'
import OverAllSummary from './OverAllSummary'
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { BootstrapDialogTitleV4, BootstrapDialogV4 } from '../../../Helper/DialogTitleM5';
import Alert from '@material-ui/lab/Alert';

const token = getCookie("bb_ts_token");

var steps = ['Select WBS and Template roles', 'Add and Edit Tasks', 'Task Estimate', 'Summary'];

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: '95%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
});

class MainStepper extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            activeStep: 0,
            data: [],
            isLoading: true,
            rates: [],
            roles: [],
            isLoading1: true,
            summary: [],
            wbs: null,
            disabled: false,
            editSave: false,
            openAlert: false,
            alertData: null,
            openModification: false,
            modifiedItem: null,
            openComments: false,
            comments: ''
        }
        this.selectedWbs = this.selectedWbs.bind(this)
        this.getRoles = this.getRoles.bind(this)
        this.editSave = this.editSave.bind(this)
        this.selectedRoles = this.selectedRoles.bind(this)
        this.openEdit = this.openEdit.bind(this)
        this.cartExistItems = this.cartExistItems.bind(this)
        this.resEdit = React.createRef()
    }

    componentDidMount() {
        steps = ['Select WBS and Template roles', 'Add and Edit Tasks', 'Task Estimate', 'Summary'];
        this.fetchOps()
        this.setState({ data: this.props.value })
        if (this.props.editData !== null) {
            this.selectedWbs(this.props.editData)
            this.forceUpdate()
        }
        if (this.props.cardData) {
            this.cartUpdateCheck()
        }
    }

    fetchOps() {
        Service.get('/bb/api/tender/rate/?pid=' + this.props.pid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.length === 0) {
                    this.props.enqueueSnackbar('Goto Rates tab to initiate default rates!', {
                        variant: 'warning'
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        rates: res.data,
                    });
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    cartUpdateCheck() {
        if (this.props.cardData) {
            this.updateCheck = setInterval(() => {
                if (this.props.cardData) {
                    Service.get('/bb/api/wbs/estimate/cart/get_updated_cart_data/?cart=' + this.props.cardData.id, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res => {
                            if (moment(res.data.updated_on) > moment(this.props.cardData.updated_on)) {
                                this.setState({ alertData: res.data, openAlert: true })
                            }
                        })
                        .catch(error => {
                            if (error && (error.status === 404 || (error.response && error.response.status === 404))) {
                                // ignore
                            } else {
                                errorMessage(this.props, error)
                            }
                        });
                }
            }, 6000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.updateCheck)
        this.setState({ alertData: null })
    }

    selectedWbs(wbs) {
        this.setState({ wbs }, () => {
            this.props.selectedWbs(wbs)
        })
    }

    selectedRoles(role_ids) {
        if (this.state.wbs !== null) {
            let wbs = this.state.wbs
            wbs['selected_roles'] = role_ids
            this.forceUpdate()
        }
    }

    getRoles(roles) {
        this.setState({ roles: roles })
    }

    getSelectedRoles(id) {
        Service.get(`/bb/api/tender/tasks/roles/frequently_used_role/?template_id=${id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.length === 0) {
                    this.props.enqueueSnackbar('Goto Roles tab to initiate default roles!', {
                        variant: 'warning'
                    });
                } else {
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        element.fm_checked = false
                    }
                    let selected_roles = this.props.editData.selected_roles
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        if (selected_roles.includes(element.id)) {
                            element.fm_checked = true
                        }
                    }
                    this.setState({ roles: res.data, activeStep: 1 })
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    editSave(value) {
        this.setState({ editSave: value })
    }

    openEdit(value) {
        this.props.openEdit(value)
    }

    respoEdit(value) {
        if (this.resEdit && this.resEdit.current) {
            this.resEdit.current.selectedWbs(value)
        }
    }

    getCartItem() {
        this.setState({ openModification: true })
        Service.get('/bb/api/wbs/estimate/cart/' + this.props.cardData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ modifiedItem: res.data });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    cartExistItems(cartExistItems) {
        this.props.cartExistItems(cartExistItems)
    }

    handleTextChange(e) {
        this.setState({ comments: e.target.value })
    }

    saveComments() {
        if (this.state.comments === "" || this.state.comments === '.' || this.state.comments === " ") {
            this.props.enqueueSnackbar('Comments should be reasonable!', {
                variant: 'warning'
            });
        } else {
            this.setState({ openComments: false }, () => {
                this.props.addCart(this.state.wbs, this.state.comments)
            })
        }


    }

    skipComments() {
        this.setState({ openComments: false }, () => {
            this.props.addCart(this.state.wbs, '')
        })
    }

    render() {

        const { classes } = this.props;

        const handleNext = () => {
            if (this.state.activeStep === 0) {
                if (this.state.wbs) {
                    // var proceed = false
                    var rolesChecked = false

                    // for (let i = 0; i < this.state.wbs.tasks.length; i++) {
                    //     const element = this.state.wbs.tasks[i];
                    //     if (element.fm_checked) {
                    //         proceed = true
                    //         break
                    //     }
                    // }

                    for (let i = 0; i < this.state.roles.length; i++) {
                        const element = this.state.roles[i];
                        if (element.fm_checked) {
                            rolesChecked = true
                            break
                        }
                    }
                    if (rolesChecked) {
                        this.setState({ activeStep: (this.state.activeStep + 1) });
                    } else {
                        this.props.enqueueSnackbar('Select atleast One WBS and Roles!', {
                            variant: 'warning'
                        });
                    }
                } else {
                    this.props.enqueueSnackbar('Select atleast One WBS and Roles!', {
                        variant: 'warning'
                    });
                }
            } else if (this.state.activeStep === 1) {
                if (!this.state.editSave) {
                    this.setState({ activeStep: (this.state.activeStep + 1) });
                } else {
                    this.props.enqueueSnackbar('Make sure the datas are saved!', {
                        variant: 'warning'
                    });
                }
            } else if (this.state.activeStep === steps.length - 1) {
                if (this.props.editData !== null && !this.props.isEstimateEdit) {
                    this.setState({ openComments: true })
                } else {
                    this.props.addCart(this.state.wbs, '')
                }
            } else {
                this.setState({ activeStep: (this.state.activeStep + 1) });
            }
        };

        const getStepContent = (step) => {
            switch (step) {
                case 0:
                    return <TaskAndRole ref={this.resEdit} openEdit={this.openEdit} selectedRoles={this.selectedRoles}
                    wbs={this.props.wbs} roles={this.state.roles} data={this.props.data} dataT={this.props.dataT}
                    selectedWbs={this.selectedWbs} value={this.state.wbs} addList={this.props.addList}
                    editData={this.props.editData} getRoles={this.getRoles} cartExistItems={this.cartExistItems}
                    isEstimateEdit={this.props.isEstimateEdit} />;
                case 1:
                    return <SetDateToTask value={this.state.wbs} roles={this.state.roles} rates={this.state.rates} editSave={this.editSave} />;
                case 2:
                    return <TaskEstimate value={this.state.wbs} roles={this.state.roles} rates={this.state.rates} />;
                case 3:
                    return <OverAllSummary value={this.state.wbs} rates={this.state.rates} showAll={this.props.isEstimateEdit} />;
                default:
                    return 'Unknown step';
            }
        }

        const handleBack = () => {
            if (this.state.activeStep === 1) {
                if (!this.state.editSave) {
                    this.setState({ activeStep: (this.state.activeStep - 1) });
                } else {
                    this.props.enqueueSnackbar('Make sure the datas are saved!', {
                        variant: 'warning'
                    });
                }
            } else {
                this.setState({ activeStep: this.state.activeStep - 1 });
            }
        };

        const handleAlertClose = (e) => {
            this.setState({ openAlert: false, alertData: null })
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <>
                        <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
                            {steps.map((label, index) => (
                                <Step key={label} onClick={() => {
                                    if (this.state.activeStep === 0) {
                                        if (this.state.wbs) {
                                            // var proceed = false
                                            var rolesChecked = false

                                            // for (let i = 0; i < this.state.wbs.tasks.length; i++) {
                                            //     const element = this.state.wbs.tasks[i];
                                            //     if (element.fm_checked) {
                                            //         proceed = true
                                            //         break
                                            //     }
                                            // }

                                            for (let i = 0; i < this.state.roles.length; i++) {
                                                const element = this.state.roles[i];
                                                if (element.fm_checked) {
                                                    rolesChecked = true
                                                    break
                                                }
                                            }
                                            if (rolesChecked) {
                                                this.setState({ activeStep: index });
                                            } else {
                                                this.props.enqueueSnackbar('Select atleast One WBS and Roles!', {
                                                    variant: 'warning'
                                                });
                                            }
                                        } else {
                                            this.props.enqueueSnackbar('Select atleast One WBS and Roles!', {
                                                variant: 'warning'
                                            });
                                        }
                                    } else if (this.state.activeStep === 1) {
                                        if (!this.state.editSave) {
                                            this.setState({ activeStep: index });
                                        } else {
                                            this.props.enqueueSnackbar('Make sure the datas are saved!', {
                                                variant: 'warning'
                                            });
                                        }
                                    } else {
                                        this.setState({ activeStep: index });
                                    }
                                }}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <React.Fragment>
                            {this.state.activeStep === steps.length ? (
                                <React.Fragment>

                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {getStepContent(this.state.activeStep)}
                                    {(this.state.activeStep === steps.length - 1) && this.props.isEstimateEdit ?
                                        <Alert style={{ marginTop: '15px' }} severity="info">
                                            Please note that Saving this Item will impact the hours and cost in Estimate.
                                        </Alert>
                                        : null}
                                    <div className={classes.buttons}>

                                        {this.state.activeStep !== 0 && (
                                            <Button onClick={handleBack} className={classes.button} >
                                                Back
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}>
                                            {this.state.activeStep === steps.length - 1 ? this.props.isEstimateEdit ? "Save to Estimate" : this.props.editData ? "Save in Cart" : "Add to Cart" : 'Next'}
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </>
                )
                }
                <Dialog open={this.state.openAlert} fullWidth maxWidth='md' onClose={handleAlertClose} aria-labelledby="customized-dialog-title">
                    <DialogTitle>
                        This Cart Item is modified!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {this.state.alertData ?
                                this.state.alertData.updated_by.last_name + ", " + this.state.alertData.updated_by.first_name + " modified the data in the cart " + moment(this.state.alertData.updated_on).fromNow() + ". Do you still want to continue and edit?"
                                : null}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' onClick={() => this.props.closeStepper()}>
                            Discard Changes
                        </Button>
                        <Button color='primary' onClick={() => this.getCartItem()}>
                            View Modification
                        </Button>
                        <Button color='primary' onClick={() => this.setState({ openAlert: false, alertData: null })}>
                            Continue & Edit
                        </Button>
                    </DialogActions>
                </Dialog>

                <BootstrapDialogV4 open={this.state.openModification} fullWidth maxWidth='xl' aria-labelledby="customized-dialog-title">
                    <BootstrapDialogTitleV4 id="form-dialog-title" onClose={() => this.setState({ openModification: false })}>
                        {this.state.modifiedItem ? this.state.modifiedItem.cart_data.name : null}
                    </BootstrapDialogTitleV4>
                    <DialogContent>
                        {this.state.modifiedItem ? <OverAllSummary cardData={this.props.cardData} value={this.state.modifiedItem.cart_data} roles={this.state.roles} rates={this.state.rates} /> : <LoopCircleLoading color={getPrimaryColor()} />}
                    </DialogContent>
                </BootstrapDialogV4>

                <Dialog open={this.state.openComments} fullWidth maxWidth='md' aria-labelledby="customized-dialog-title">
                    <DialogTitle>
                        Post the Comments What you have Edited in this Item
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            id="comments"
                            label="Comments"
                            type="name"
                            onChange={e => this.handleTextChange(e)}
                            fullWidth
                            variant='outlined'
                            multiline
                            rows={5}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' onClick={() => this.skipComments()}>
                            Skip
                        </Button>
                        <Button color='primary' onClick={() => this.saveComments()}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}

export default withSnackbar(withStyles(useStyles)(MainStepper))