import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import WBSTemplate from './WBSTemplate'
// import { getCookie } from '../../Helper/CookieHelper';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginBottom: '20px'
  },
});

export default function Template() {
  // const user = JSON.parse(getCookie("bb_ts_user"));
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [load, setLoad] = React.useState(false);

  const handleChange = (event, newValue) => {
    setLoad(true);
    setValue(newValue);
    setTimeout(function(){ setLoad(false); }, 200);
  };

  return (
    <div>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="OHL" />
          <Tab label="Cabling" />
          <Tab label="SUBSTATION (PRIMARY & COMMON)" />
          <Tab label="SUBSTATION ( P&C and SUBS COMMON)" />
          <Tab label="SUBSTATION CIVIL" />
          <Tab label="Multi Utilities" />
          <Tab label="Technical" />
          <Tab label="EDC" />
        </Tabs>
      </Paper>
      {(!load) ? (
        (value === 0) ? (
          <WBSTemplate value="ohl" />
        ) : (value === 1) ? (
          <WBSTemplate value="cabling" />
        ) : (value === 2) ? (
          <WBSTemplate value="sub_struc" />
        ) : (value === 3) ? (
          <WBSTemplate value="ohl_civ_struc" />
        ) : (value === 4) ? (
          <WBSTemplate value="sub_civ_struct" />
        ) : (value === 5) ? (
          <WBSTemplate value="multi_utilities" />
        ) : (value === 6) ? (
          <WBSTemplate value="technical" />
        ) : (value === 7) ? (
          <WBSTemplate value="edc" />
        ) : (null)
      ) : (null)}

    </div>

  );
}